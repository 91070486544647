import React, { useEffect, useState } from 'react'
import {
  Form,
  Steps,
  Row,
  Col,
  Button,
  Input,
  notification,
  Popover
} from 'antd'
import { useDispatch } from 'react-redux'
import {
  qualificationOptions,
  DocumentTypes,
  fcmtUniversity,
  EnglishExamTypes,
  DirectUniversityCheck
} from '../../../components/_utils/appUtils'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import { listAllCountries } from '../../countries/actions/countries'
import { addStudent, checkBranchUserExists, eduBoardAndUniversity } from '../../student/actions/student'
import _ from 'lodash'
import moment from 'moment'
import { InputBox } from '../../../components/_utils/appUtils'
import { CountryJson, LoadState } from '../../../components/_utils/countryUtil'
import ChooseCourse from './chooseCourses'
import { listCourseCampus } from '../../campus/actions/campus'
import { loadCourseIntakesFxn } from '../../course/actions/courseAction'
import { RowTable } from '../../../components/_utils/RowTable'
import { Debounce } from '../../../components/_utils/debounce'

const { Step } = Steps

let initialState = {
  currentStep: 0,
  fileList: [],
  uploadKey: moment(),
  allCountries: CountryJson,
  allStates: [],
  allCities: [],
  countryName: '',
  stateName: '',
  cityName: ''
}

const AddStudent = (props) => {
  const {
    form: { getFieldDecorator, setFieldsValue, getFieldValue, resetFields },
    callBackFxn,
    pageType,
    selectedCourse
  } = props
  const dispatch = useDispatch()

  _.each(DocumentTypes, (item) => {
    initialState[item] = ''
  })
  const [state, setState] = useState(initialState)
  const [visibleChooseCourse, setVisibleChooseCourse] = useState(false)
  const [application, setApplication] = useState([])
  const { allCountries, allStates, allCities } = state
  let [intakesList, setIntakesList] = useState([])
  let [intake, setIntake] = useState({})
  let [campusList, setCampusList] = useState([])
  let [eduList, setEduList] = useState([])
  let [isDirectUniversity, setIsDirectUniversity] = useState(false)

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }

  const setDefaultCountry = () => {
    let defaultCountry = 'India'
    props.form.setFieldsValue({
      countryName: defaultCountry
    })
    events.chooseCountry(defaultCountry)
  }

  const loadBoard = async (x) => {
    let obj = {
      qualification: getFieldValue('education.qualification'),
      board: x
    }
    let { data } = await eduBoardAndUniversity(obj)
    setEduList(data)
  }
  const ExamConditions = (!getFieldValue('englishRequirments.examType') || (getFieldValue('englishRequirments.examType') && getFieldValue('englishRequirments.examType') == 'I don\'t have this'))
  const Exam12ThConditions = (!getFieldValue('englishRequirments.examType') || (getFieldValue('englishRequirments.examType') && getFieldValue('englishRequirments.examType') !== 'I don\'t have this'))


  let formFields = {
    generateInputs: [
      { key: 'name', label: 'Student Name', required: true, placeholder: 'Enter Name' },
      {
        key: 'dateOfBirth', label: 'Date Of Birth',
        required: true, type: 'date',
        placeholder: 'Choose Date of Birth'
      },
      {
        key: 'countryOfCitizenShip',
        label: 'Country of Citizenship',
        required: true,
        type: 'select',
        showSearch: true,
        options: allCountries,
        keyAccessor: x => x.name,
        valueAccessor: x => `${x.name}`,
        onChange: x => {
          props.form.setFieldsValue({
            countryOfCitizenShip: x
          })
        }
      },
      { key: 'passportNumber', label: 'Passport Number', placeholder: 'Enter Passport Number' },
      {
        key: 'gender', label: 'Gender',
        type: 'select',
        options: ['Male', 'Female'],
        required: true, onChange: x => {
          props.form.setFieldsValue({
            gender: x
          })
        }
      },
      {
        key: 'maritalStatus', label: 'Marital Status',
        type: 'select', options: ['Single', 'Married'],
        required: true, onChange: x => {
          props.form.setFieldsValue({
            maritalStatus: x
          })
        }
      }
    ],
    addressInput: [
      { key: 'address', label: 'Address', required: true, span: 16, placeholder: 'Enter Address' },
      {
        key: 'countryName',
        label: 'Country',
        required: true,
        type: 'select',
        showSearch: true,
        options: allCountries,
        keyAccessor: x => x.name,
        valueAccessor: x => `${x.name}`,
        onChange: x => {
          props.form.setFieldsValue({
            countryName: x
          })
          events.chooseCountry(x)
        }
      },
      {
        key: 'stateName',
        label: 'Province/State',
        required: true,
        type: 'select',
        showSearch: true,
        options: allStates,
        keyAccessor: x => x.name,
        valueAccessor: x => `${x.name}`,
        onChange: x => {
          props.form.setFieldsValue({
            stateName: x
          })
          events.chooseState(x)
        }
      },
      {
        key: 'cityName',
        label: 'City/Town',
        required: true,

        onChange: x => {
          props.form.setFieldsValue({
            cityName: x.target.value
          })
          events.chooseCity(x.target.value)
        }
      },
      { key: 'postalCode', label: 'Postal/Zip Code', type: 'number', placeholder: 'Postal/Zip Code', required: true },
      { key: 'email', label: 'Email', type: 'email', placeholder: 'Ener Email' },
      { key: 'mobile', label: 'Phone', required: true, type: 'number', placeholder: 'Enter Phone No' }
    ],

    applicationInputField: [
      {
        key: 'campusId',
        label: 'Campus',
        type: 'select',
        required: DirectUniversityCheck(selectedCourse.courseUniversity),
        options: campusList,
        keyAccessor: x => x._id,
        valueAccessor: x => `${x.campusName}`,
        onChange: x => {
          props.form.setFieldsValue({
            campusId: x
          })
        }
      },
      {
        key: 'intakeTemp',
        label: 'Intake',
        type: 'select',
        required: DirectUniversityCheck(selectedCourse.courseUniversity),
        options: intakesList,
        keyAccessor: x => x.value,
        valueAccessor: x => `${x.label}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            intakeTemp: x
          })
          if (x) {
            let intakeVal = x.split('-')
            let intake = {
              month: intakeVal[0],
              year: intakeVal[1]
            }
            setIntake(intake)

          } else {
            setIntake({})
          }
        }
      }
    ],
    educationInput: [
      {
        key: 'education.qualification',
        label: 'Qualification',
        type: 'select',
        required: true,
        options: qualificationOptions,
        onChange: x => {
          let obj = {
            qualification: x,
            passingYear: '',
            percentage: '',
            maths: '',
            english: '',
            board: ''
          }
          props.form.setFieldsValue({
            education: obj
          }, () => {
            loadBoard()
          })
        },
        span: 6
      },

      {
        key: 'education.board',
        label: 'Board/University',
        showSearch: true,
        type: 'select',
        onSearch: (v) => {
          Debounce(() => loadBoard(v), 500)
        },
        required: getFieldValue('education.qualification') && getFieldValue('education.qualification') !== 'X + 3 Year Diploma',
        hidden: !(getFieldValue('education.qualification') && getFieldValue('education.qualification') !== 'X + 3 Year Diploma'),
        options: eduList,
        onChange: x => {
          props.form.setFieldsValue({
            'education.board': x
          })
        },
        span: 10
      }
    ],
    educationInputField: [
      {
        key: 'education.passingYear',
        label: 'Passing Year',
        type: 'number',
        span: 6,
        required: true
      },
      {
        key: 'education.percentage',
        label: 'Percentage',
        type: 'number',
        span: 6,
        required: true
      },
      {
        key: 'education.maths',
        label: 'Math Score',
        type: 'number',
        span: 6,
        hidden: getFieldValue('education.qualification') !== 'XII',
        required: getFieldValue('education.qualification') == 'XII'
      },
      {
        key: 'education.english',
        label: 'English Score',
        type: 'number',
        span: 6,
        hidden: getFieldValue('education.qualification') !== 'XII',
        required: getFieldValue('education.qualification') == 'XII'
      }


    ],
    testInput: [
      {
        key: 'englishRequirments.examType',
        label: 'English Exam Type',
        type: 'select',
        allowClear: true,
        showSearch: true,
        required: true,
        options: EnglishExamTypes,
        onChange: x => {
          let obj = {
            examType: x,
            overall: '',
            listening: '',
            reading: '',
            writing: '',
            speaking: ''
          }
          props.form.setFieldsValue({
            englishRequirments: obj
          })
        },
        span: 8
      },
      {
        key: 'englishRequirments.english12ThMarks',
        label: 'English 12th Marks',
        type: 'number',
        placeholder: 'English 12th Marks',
        hidden: Exam12ThConditions,
        required: !Exam12ThConditions,
        span: 6
      },
      {
        key: 'englishRequirments.examinationDate',
        label: 'Examination Date',
        placeholder: 'Examination Date',
        type: 'date',
        hidden: ExamConditions,
        required: !ExamConditions,
        span: 6
      },
      {
        key: 'englishRequirments.overall',
        label: 'Overall',
        type: 'number',
        span: 8,
        hidden: ExamConditions,
        required: !ExamConditions,
        placeholder: 'Overall'
      }
    ],
    testInputFields: [
      {
        key: 'englishRequirments.listening',
        label: 'Listening',
        type: 'number',
        span: 6,
        hidden: ExamConditions,
        required: !ExamConditions,
        placeholder: 'Listening'
      },
      {
        key: 'englishRequirments.reading',
        label: 'Reading',
        type: 'number',
        span: 6,
        hidden: ExamConditions,
        required: !ExamConditions,
        placeholder: 'Reading'
      },
      {
        key: 'englishRequirments.writing',
        label: 'Writing',
        type: 'number',
        span: 6,
        hidden: ExamConditions,
        required: !ExamConditions,
        placeholder: 'Writing'
      },
      {
        key: 'englishRequirments.speaking',
        label: 'Speaking',
        type: 'number',
        span: 6,
        hidden: ExamConditions,
        required: !ExamConditions,
        placeholder: 'Speaking'
      }
    ],
    backgroundInfoInput: [
      {
        key: 'refusedVisa',
        label: 'Have you been refused a visa from Canada, the USA, the United Kingdom, New Zealand or Australia?',
        type: 'radioGroup',
        options: ['Yes', 'No'],
        span: 24,
        onChange: x => {
          props.form.setFieldsValue({
            refusedVisa: x.target.value
          })
        }
      },
      {
        key: 'details',
        label: 'If you answered "Yes" to any of the following questions above, please provide more details below:',
        placeholder: 'Provide details... ',
        type: 'textArea',
        span: 24,
        rows: 7
      }
    ]
  }

  useEffect(() => {
    dispatch({ type: 'STUDENT_HIDE_LOADER' })
    dispatch(listAllCountries({ results: 1000000 }))
    setDefaultCountry()
    events.loadIntakes()
    events.loadCampus()
  }, [])

  const events = {
    stepChange: (val = 0) => {
      let activeId = ''
      setState({
        ...state
        // currentStep: val
      })
      switch (val) {
        case 0:
          activeId = 'generalInformation'
          break
        case 1:
          activeId = 'educationHistory'
          break
        case 2:
          activeId = 'testScore'
          break
        case 3:
          activeId = 'backgroundInformation'
          break
        case 4:
          activeId = 'applications'
          break
        case 5:
          activeId = 'uploadDocument'
          break
        default:
          activeId = 'generalInformation'
      }
      // console.log(activeId)
      document.getElementById(activeId).scrollIntoView({
        behavior: 'smooth'
      })
    },
    chooseDocument: (e) => {
      let { name, files } = e.target
      if (files && files.length) {
        setState({
          ...state,
          [name]: files[0]
        })
      }
    },

    getApplication: async (data) => {
      return new Promise(async (resolve, reject) => {
        let { selectedCourse } = props
        let valData = {}
        valData.courseUniversity = selectedCourse.courseUniversity
        valData.applicationFee = selectedCourse.applicationFee
        valData.universityCountry = selectedCourse.universityCountry
        valData.universityCity = selectedCourse.universityCity
        valData.universityState = selectedCourse.universityState
        valData.tuitionFee = selectedCourse.tuitionFee
        valData.courseId = selectedCourse.courseId
        valData.courseName = selectedCourse.courseName
        valData.campusId = data.campusId
        valData.intake = intake
        let { success, message } = await dispatch(checkBranchUserExists(valData))
        if (success) {
          resolve({ type: true, application: valData })
        } else {
          resolve({ type: false, message })
        }
      })
    },

    handleSubmit: (e) => {
      const { form } = props
      e.preventDefault()
      form.validateFieldsAndScroll(async (err, valData) => {
        if (!err) {
          let { countryName, stateName, cityName } = state
          let { education, englishRequirments } = valData
          if (education) {
            if (education.percentage < 1) {
              notification.warning({
                message: 'Enter greater then 0 Percentage'
              })
              return
            }
            if (education.qualification == 'XII') {
              if (education.maths < 1) {
                notification.warning({
                  message: 'Enter greater then 0 Math score'
                })
                return
              }
              if (education.english < 1) {
                notification.warning({
                  message: 'Enter greater then 0 English score'
                })
                return
              }
            }
          }
          if (englishRequirments && englishRequirments.examType) {
            if (englishRequirments.examType == 'I don\'t have this') {
              if (englishRequirments.english12ThMarks <= 0) {
                notification.warning({
                  message: 'Enter greater then 0 English 12Th marks'
                })
                return
              }
            } else {
              if (englishRequirments.overall <= 0) {
                notification.warning({
                  message: 'Enter greater then 0 Overall Rank'
                })
                return
              }
              if (englishRequirments.listening <= 0) {
                notification.warning({
                  message: 'Enter greater then 0 Listening Rank'
                })
                return
              }
              if (englishRequirments.reading <= 0) {
                notification.warning({
                  message: 'Enter greater then 0 Reading Rank'
                })
                return
              }
              if (englishRequirments.writing <= 0) {
                notification.warning({
                  message: 'Enter greater then 0 Writing Rank'
                })
                return
              }
              if (englishRequirments.speaking <= 0) {
                notification.warning({
                  message: 'Enter greater then 0 Speaking Rank'
                })
                return
              }
            }
          }

          events.getApplication(valData).then(async (resp) => {
            if (resp && resp.type) {

              valData.application = [resp.application]
              if (state.fileList && state.fileList.length) {
                valData.document = state.fileList
              }
              valData = { ...valData, countryName, stateName, cityName }

              console.log(valData)
              let fd = new FormData()

              fd.append('obj', JSON.stringify(valData))

              _.each(DocumentTypes, (item) => {
                if (state[item]) {
                  fd.append([item], state[item])
                }
              })

              let data = await dispatch(addStudent(fd, true))
              if (data && !data.error) {
                form.resetFields()
                _.each(DocumentTypes, (item) => {
                  initialState[item] = ''
                })
                setState({
                  ...initialState,
                  uploadKey: moment()
                })
                setApplication([])
              }

              callBackFxn(data.data)
            } else {
              notification.warn({ message: resp.message })
            }
          })
        } else {
          notification.warning({
            message: 'Fill All Required Fields'
          })
        }
      })
    },
    uploadDocument: (v) => {
      setState({
        ...state,
        fileList: v.fileList
      })
    },
    chooseCountry: (name) => {
      let countryName = _.find(allCountries, (item) => {
        return item.name == name
      })
      if (countryName) {
        setState({
          ...state,
          countryName: countryName.name ? countryName.name : '',
          allStates: countryName.id ? LoadState(countryName.id) : []
        })
      }
    },
    chooseState: (name) => {
      let stateName = _.find(allStates, (item) => {
        return item.name == name
      })
      if (stateName) {
        setState({
          ...state,
          stateName: stateName.name ? stateName.name : ''
          // allCities: stateName.id ? LoadCity(stateName.id) : []
        })
      }
    },
    chooseCity: (name) => {
      setState({
        ...state,
        cityName: name ? name : ''
      })
    },
    addCourse: (course) => {
      let applicationClone = _.clone(application)
      /*if (applicationClone.length < 3) {

      } else {
        return notification.warning({
          message: 'You can choose maximum 3 application'
        })
      }*/
      let findCourse = _.find(applicationClone, (item) => {
        return item._id == course._id
      })
      if (findCourse) {
        return notification.warning({
          message: 'Application already exists'
        })
      } else {
        applicationClone.push(course)
        setApplication(applicationClone)
        setVisibleChooseCourse(false)
      }

    },
    showAddApp: () => {
      setVisibleChooseCourse(true)
    },
    removeApplication: (course) => {
      let applicationClone = _.clone(application)
      applicationClone = _.reject(applicationClone, (item) => {
        return item._id == course._id
      })
      setApplication(applicationClone)
      setVisibleChooseCourse(false)
    },
    loadCampus: async () => {
      let { courseUniversity, courseId } = selectedCourse
      let isDirectUniversity = DirectUniversityCheck(courseUniversity)
      if (courseId) {
        let obj = {
          results: 1000000,
          courseId
        }
        let { data } = await dispatch(listCourseCampus(obj))
        setCampusList(data)
        if (isDirectUniversity && data && data.length) {
          setFieldsValue({
            campusId: data[0]._id
          })
        }
      } else {
        setFieldsValue({
          campusId: undefined
        })
        setCampusList([])
      }
    },
    loadIntakes: async () => {
      let { courseUniversity, courseId } = selectedCourse
      let isDirectUniversity = DirectUniversityCheck(courseUniversity)
      if (courseId) {
        let resp = await dispatch(loadCourseIntakesFxn({ courseId }))
        let data = []
        _.each(resp, (item) => {
          if (item) {
            item.value = `${item.intake}-${item.year}`
            item.label = `${item.intake}, ${item.year}`
            data.push(item)
          }
        })
        setIntakesList(data)
        if (isDirectUniversity && resp && resp.length) {
          setFieldsValue({
            intakeTemp: data[0].value
          })
          let intake = {
            month: resp[0].intake,
            year: resp[0].year
          }
          setIntake(intake)
        }
      } else {
        setIntakesList([])
        setIntake({})
        setFieldsValue({
          intakeTemp: undefined
        })

      }
    }
  }


  const customDot = (dot, { status, index }) => (
    <Popover
      content={
        <span>
          step {index} status: {status}
        </span>
      }>
      {dot}
    </Popover>
  )

  useEffect(() => {

    let header = document.getElementById('header')

  })

  const scrollToIds = (element) => {
    document.getElementById(element).scrollIntoView({
      behavior: 'smooth'
    })
  }

  const columns = [
    {
      title: 'Course',
      key: 'courseName',
      dataIndex: 'courseName'
    },
    {
      title: 'University',
      key: 'university',
      dataIndex: 'university'
    },
    {
      title: 'Country',
      key: 'country',
      dataIndex: 'country'
    },
    {
      title: 'Intake',
      key: 'intake',
      dataIndex: 'intake',
      render: (item) => {
        return (
          <div>
            {item && item.year ? `${item.month}, ${item.year}` : null}
          </div>
        )
      }
    },
    {
      title: 'Action',
      key: '_id',
      dataIndex: '_id',
      render: (item, record) => {
        return (
          <Button shape={'circle'} icon={'delete'} onClick={() => events.removeApplication(record)}></Button>
        )
      }
    }

  ]
  return (
    <div>
      {visibleChooseCourse ? <ChooseCourse
        visible={visibleChooseCourse}
        addCourse={events.addCourse}
        onClose={() => setVisibleChooseCourse(false)}/> : null}
      <div className="row">
        <div className="col-lg-12">
          <Form className={'vertical-form'} autoComplete="off">

            <div className="form-box mt-4">

              <div className="heading-form stuHeader  d-flex align-items-center mb-3" id={'generalInformation'}>
                <h5><span className="img"><img src="/dist/img/check.svg" alt=""/></span> general information</h5>
              </div>

              <div className="card unizportal">
                {/*general*/}
                <Row gutter={24} className={'rowWrap'}>

                  {formFields.generateInputs.map((item, key) => {
                    return (
                      <Col span={8} md={8} sm={12} xs={24} key={key} className={'mb10'}>
                        <GetEachFormFields
                          item={item}
                          getFieldDecorator={getFieldDecorator}
                          formItemLayout={formItemLayout}/>
                      </Col>
                    )
                  })}
                  {formFields.addressInput.map((item, key) => {
                    return (
                      <Col span={item.span ? item.span : 8} md={item.span ? item.span : 8} sm={12} xs={24} key={key}
                           className={'mb10'}>
                        <GetEachFormFields
                          item={item}
                          getFieldDecorator={getFieldDecorator}
                          formItemLayout={formItemLayout}/>
                      </Col>
                    )
                  })}

                </Row>
              </div>

              <div>
                <div className="heading-form stuHeader  d-flex align-items-center mt-4 mb-3" id={'application'}>
                  <h5><span className="img"><img src="/dist/img/check.svg" alt=""/></span> application</h5>
                </div>
                <div className="card unizportal">
                  <div className="inner-form">
                    <Row gutter={24}>
                      <Col span={12} style={{ paddingTop: 30 }}>
                        <div className={'infoUni tableBox striped'}>
                          <RowTable title={'Country'} value={<span>{selectedCourse.countryName}</span>}/>
                          <RowTable title={'University'} value={<span>{selectedCourse.universityName}</span>}/>
                          <RowTable title={'Course'} value={<span>{selectedCourse.courseName}</span>}/>
                        </div>
                      </Col>
                      <Col span={12}>
                        <Row gutter={24}>
                          {formFields.applicationInputField.map((item, key) => {
                            return (
                              <Col span={24} md={24} sm={12} xs={24}
                                   key={key}
                                   className={'mb10'}>
                                <GetEachFormFields
                                  item={item}
                                  getFieldDecorator={getFieldDecorator}
                                  formItemLayout={formItemLayout}/>
                              </Col>
                            )
                          })}
                        </Row>
                      </Col>
                    </Row>


                  </div>
                </div>
              </div>
              <div className="heading-form stuHeader  d-flex align-items-center mt-4 mb-3" id={'education'}>
                <h5><span className="img"><img src="/dist/img/check.svg" alt=""/></span> education</h5>
              </div>

              <div className="card unizportal">
                <div className="inner-form">
                  {/*education*/}
                  <Row gutter={24}>
                    {formFields.educationInput.map((item, key) => {
                      return (
                        <Col span={item.span ? item.span : 8} md={item.span ? item.span : 8} sm={12} xs={24} key={key}
                             className={'mb10'}>
                          <GetEachFormFields
                            item={item}
                            getFieldDecorator={getFieldDecorator}
                            formItemLayout={formItemLayout}/>
                        </Col>
                      )
                    })}
                  </Row>
                  <Row gutter={24}>
                    {formFields.educationInputField.map((item, key) => {
                      return (
                        <Col span={item.span ? item.span : 8} md={item.span ? item.span : 8} sm={12} xs={24} key={key}
                             className={'mb10'}>
                          <GetEachFormFields
                            item={item}
                            getFieldDecorator={getFieldDecorator}
                            formItemLayout={formItemLayout}/>
                        </Col>
                      )
                    })}
                  </Row>
                </div>
              </div>

              <div className="heading-form stuHeader  d-flex align-items-center mt-4 mb-3" id={'testScore'}>
                <h5><span className="img"><img src="/dist/img/check.svg" alt=""/></span> test scores</h5>
              </div>

              <div className="card unizportal">
                <div className="inner-form">
                  <Row gutter={24}>
                    {formFields.testInput.map((item, key) => {
                      return (
                        !item.hidden &&
                        <Col span={item.span ? item.span : 8} md={item.span ? item.span : 8} sm={12} xs={24} key={key}
                             className={'mb10'}>
                          <GetEachFormFields
                            item={item}
                            getFieldDecorator={getFieldDecorator}
                            formItemLayout={formItemLayout}/>
                        </Col>
                      )
                    })}
                  </Row>
                  <Row gutter={24}>
                    {formFields.testInputFields.map((item, key) => {
                      return (
                        <Col span={item.span ? item.span : 8} md={item.span ? item.span : 8} sm={12} xs={24} key={key}
                             className={'mb10'}>
                          <GetEachFormFields
                            item={item}
                            getFieldDecorator={getFieldDecorator}
                            formItemLayout={formItemLayout}/>
                        </Col>
                      )
                    })}
                  </Row>
                </div>
              </div>

              <div className="heading-form stuHeader  d-flex align-items-center mt-4 mb-3" id={'backgroundInformation'}>
                <h5><span className="img"><img src="/dist/img/check.svg" alt=""/></span> background information</h5>
              </div>

              <div className="card unizportal">
                <div className="inner-form">
                  {/*background information*/}
                  <Row gutter={24}>
                    {formFields.backgroundInfoInput.map((item, key) => {
                      return (
                        <Col span={item.span ? item.span : 8} md={item.span ? item.span : 8} sm={12} xs={24} key={key}
                             className={'mb10'}>
                          <GetEachFormFields
                            item={item}
                            getFieldDecorator={getFieldDecorator}
                            formItemLayout={formItemLayout}/>
                        </Col>
                      )
                    })}
                  </Row>
                </div>
              </div>


              <div className="heading-form stuHeader  d-flex align-items-center mt-4 mb-3" id={'uploadDocuments'}>
                <h5><span className="img"><img src="/dist/img/check.svg" alt=""/></span> upload documents</h5>
              </div>

              <div className="card unizportal">
                <div className="inner-form">
                  {/*upload documents*/}
                  <Row gutter={24} key={state.uploadKey}>
                    {DocumentTypes.map((item, key) => {
                      return (
                        <Col span={8} md={8} sm={12} xs={24} key={key}>
                          <InputBox title={`${item} Document`}>
                            <Input type={'file'} name={item} id={item} className={'form-control'}
                                   onChange={(e) => {
                                     events.chooseDocument(e)
                                   }}/>
                          </InputBox>
                        </Col>
                      )
                    })}
                  </Row>

                  <button className="btn" type="submit" onClick={events.handleSubmit}>save</button>
                </div>
              </div>


            </div>
          </Form>
        </div>
      </div>

    </div>
  )
}


const WrappedAddStudent = Form.create()(AddStudent)
export default WrappedAddStudent


