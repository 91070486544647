import React, { useState, useEffect, useRef } from 'react'
import { CustomRowTable } from '../../../components/_utils/RowTable'
import { Drawer, Card, Skeleton, Tooltip, Icon, Table } from 'antd'
import { useDispatch } from 'react-redux'
import { countryIds, displayDate, statusColors } from '../../../components/_utils/appUtils'
import { getReferralList } from '../actions'

const ReferralsDrawer = (props) => {
  let dispatch = useDispatch()
  let tableRef = useRef()

  let { universityId, visible, onClose } = props
  let [universityData, setUniversityData] = useState({})
  let [countryId, setCountryId] = useState('')
  let [loading, setLoading] = useState(true)
  let [listData, setListData] = useState([])

  useEffect(() => {
    apiRequest()
  }, [])


  const apiRequest = async () => {
    // let params = {
    //   ...pageCount(100, listData)
    // }
    let { data } = await dispatch(getReferralList())
    console.log('referrals list', data)
    setListData(data)
    setLoading(false)
  }


  const getStatusColor = (status) => {
    if (status === 'Pending') {
      return 'orange'
    }
    if (status === 'Paid') {
      return 'green'
    }
  }

  const columns = [
    {
      title: 'Name',
      key: 'name',
      render: (val) => {
        return (
          <div>{val.name}</div>
        )
      }
    },
    {
      title: 'Date',
      key: 'createdAt',
      render: (val) => {
        return (
          <div>{displayDate(val.createdAt)}</div>
        )
      }
    },
    {
      title: 'Email',
      key: 'email',
      render: (item, record) => {
        return (
          <React.Fragment>
            {item.email}
          </React.Fragment>
        )
      }
    }

  ]

  return (
    <Drawer visible={visible}
            title={'Referrals'} width={'50%'}
            onClose={onClose}>
      <Card>
        {loading ? <Skeleton loading={loading} /> :
          <div className={'infoUni tableBox striped'}>
            <Table columns={columns}
                   bordered={true}
                   dataSource={listData}
                   pagination={false}
                   ref={tableRef}
            />
          </div>}
      </Card>
    </Drawer>
  )
}


export { ReferralsDrawer }
