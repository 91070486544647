import React, { useEffect, useState } from 'react'
import { Drawer, Form, Button, Row, Col } from 'antd'
import { BooleanOptions, countryIds, departmentList } from '../../../components/_utils/appUtils'
import { InputBox } from '../../../components/_utils/appUtils'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import { updateUserRights } from '../actions/user'
import { useDispatch } from 'react-redux'
import moment from 'moment'

const RightsList = (props) => {
  let dispatch = useDispatch()
  let [userId, setUserId] = useState('')
  let { visible, onClose, user, closeAndUpdate, form: { getFieldDecorator, getFieldValue, setFieldsValue } } = props
  console.log('branch user uer', user)
  useEffect(() => {
    setDefaultValues()
  }, [user])
  let inputTypes = {
    fields: [
      {
        label: 'Approved Agent',
        key: 'approvedAgent',
        required: true,
        type: 'select',
        keyAccessor: x => x.value,
        valueAccessor: x => `${x.name}`,
        options: BooleanOptions,
        onChange: v => {
          setFieldsValue({
            approvedAgent: v
          })
        }
      },
      {
        label: 'Leads',
        key: 'allowLeads',
        required: true,
        type: 'select',
        keyAccessor: x => x.value,
        valueAccessor: x => `${x.name}`,
        options: BooleanOptions,
        onChange: v => {
          setFieldsValue({
            allowLeads: v
          })
        }
      },
      {
        label: 'News',
        key: 'newsRight',
        required: true,
        type: 'select',
        keyAccessor: x => x.value,
        valueAccessor: x => `${x.name}`,
        options: BooleanOptions,
        onChange: v => {
          setFieldsValue({
            newsRight: v
          })
        }
      },
     /* {
        label: 'Monthly Report',
        key: 'monthlyReportRight',
        required: true,
        type: 'select',
        keyAccessor: x => x.value,
        valueAccessor: x => `${x.name}`,
        options: BooleanOptions,
        onChange: v => {
          setFieldsValue({
            monthlyReportRight: v
          })
        }
      },*/
      {
        key: 'showAgent',
        label: 'Show All Agent',
        type: 'select',
        required: true,
        keyAccessor: x => x.value,
        valueAccessor: x => `${x.name}`,
        options: BooleanOptions,
        onChange: x => {
          setFieldsValue({
            showAgent: x
          })
        }
      }, ,
      {
        key: 'feedback',
        label: 'Feedback',
        type: 'select',
        required: true,
        keyAccessor: x => x.value,
        valueAccessor: x => `${x.name}`,
        options: BooleanOptions,
        onChange: x => {
          setFieldsValue({
            feedback: x
          })
        }
      },

      {
        key: 'allowAddingUser',
        label: 'Add Team',
        type: 'select',
        required: true,
        hidden: !(user && user.userType == 'agent'),
        keyAccessor: x => x.value,
        valueAccessor: x => `${x.name}`,
        options: BooleanOptions,
        onChange: x => {
          setFieldsValue({
            allowAddingUser: x
          })
        }
      },
      {
        key: 'webinarRight',
        label: 'Webinar',
        type: 'select',
        keyAccessor: x => x.value,
        valueAccessor: x => `${x.name}`,
        options: BooleanOptions,
        onChange: x => {
          setFieldsValue({
            webinarRight: x
          })
        }
      },
      /*   {
           key: 'enableChanelPartner',
           label: 'Show Channel Partner',
           type: 'select',
           required: true,
           hidden: !(user && user.userType == 'agent'),
           keyAccessor: x => x.value,
           valueAccessor: x => `${x.name}`,
           options: BooleanOptions,
           onChange: x => {
             setFieldsValue({
               enableChanelPartner: x
             })
           }
         },
         {
           key: 'showAustraliaUniversity',
           label: 'Show Australia University',
           type: 'select',
           required: true,
           hidden: !(user && user.userType == 'agent'),
           keyAccessor: x => x.value,
           valueAccessor: x => `${x.name}`,
           options: BooleanOptions,
           onChange: x => {
             setFieldsValue({
               showAustraliaUniversity: x
             })
           }
         },*/

      {
        key: 'showCommission',
        label: 'Show Commission',
        type: 'select',
        hidden: !(user && user.userType == 'agent'),
        required: true,
        keyAccessor: x => x.value,
        valueAccessor: x => `${x.name}`,
        options: BooleanOptions,
        onChange: x => {
          setFieldsValue({
            showCommission: x
          })
        }
      },
      {
        key: 'showOnShoreAustralia',
        label: 'Show OnShore Australia',
        type: 'select',
        hidden: !(user && user.userType == 'branchUser' && user.countryId && user.countryId._id == countryIds.australia),
        required: true,
        keyAccessor: x => x.value,
        valueAccessor: x => `${x.name}`,
        options: BooleanOptions,
        onChange: x => {
          setFieldsValue({
            showOnShoreAustralia: x
          })
        }
      },
      {
        key: 'showCanadaLoa',
        label: 'Show Canada LOA',
        type: 'select',
        required: true,
        hidden: !(user && user.userType == 'branchUser' && user.countryId && user.countryId._id == countryIds.canada),
        keyAccessor: x => x.value,
        valueAccessor: x => `${x.name}`,
        options: BooleanOptions,
        onChange: x => {
          setFieldsValue({
            showCanadaLoa: x
          })
        }
      }

    ]
  }
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }
  const submitFormLayout = {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 10, offset: 7 },
      md: { span: 12, offset: 8 }
    }
  }
  const setDefaultValues = () => {
    setUserId(user._id)
    setTimeout(() => {
      setFieldsValue({
        approvedAgent: user.approvedAgent ? user.approvedAgent : false,
        allowLeads: user.allowLeads ? user.allowLeads : false,
        newsRight: user.newsRight ? user.newsRight : false,
        monthlyReportRight: user.monthlyReportRight ? user.monthlyReportRight : false,
        showAgent: user.showAgent ? user.showAgent : false,
        feedback: user.feedback ? user.feedback : false,
        webinarRight: user.webinarRight ? user.webinarRight : false,

        allowAddingUser: user.allowAddingUser ? user.allowAddingUser : false, // for agent
        enableChanelPartner: user.enableChanelPartner ? user.enableChanelPartner : false, // for agent
        showAustraliaUniversity: user.showAustraliaUniversity ? user.showAustraliaUniversity : false, // for agent
        showCommission: user.showCommission ? user.showCommission : false,// for agent
        showOnShoreAustralia: user.showOnShoreAustralia ? user.showOnShoreAustralia : false, // for branchUser
        showCanadaLoa: user.showCanadaLoa ? user.showCanadaLoa : false // for branchUser
      })
    }, 500)
  }
  const handleSubmit = (e) => {
    const { form } = props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        valData.userId = userId
        let data = await dispatch(updateUserRights(valData))
        if (data && !data.error) {
          closeAndUpdate()
        }
      }
    })
  }
  return (
    <Drawer
      title={'Update User'}
      visible={visible}
      placement="right"
      closable={true}
      onClose={onClose}
      width={'80%'}>
      <div className="form-box">
        <div className="card unizportal">
          <Form onSubmit={handleSubmit}>
            <Row gutter={16}>
              {inputTypes.fields.map((item, key) => {
                return (
                  !item.hidden && <React.Fragment key={key}>
                    <Col span={8} key={key}>
                      <InputBox title={item.label1 ? item.label1 : ''}>
                        <GetEachFormFields
                          item={item}
                          getFieldDecorator={getFieldDecorator}
                          formItemLayout={formItemLayout}/>
                      </InputBox>
                    </Col>
                  </React.Fragment>
                )
              })}
            </Row>


            <Form.Item>
              <Button type="primary" htmlType="submit" className={'btn'}>
                UPDATE
              </Button>
            </Form.Item>

          </Form>
        </div>
      </div>

    </Drawer>

  )
}

const WrappedRightsList = Form.create()(RightsList)
export default WrappedRightsList
