import {
    Button,
    Col,
    Form,
    Icon,
    Popconfirm,
    Row,
    Select,
    Tag,
    Tooltip,
    notification
} from 'antd'
import lodash from 'lodash'
import React, {useEffect, useRef, useState} from 'react'
import {connect, useDispatch, useSelector} from 'react-redux'
import {TableComp} from 'sz-react-utils-lite'
import {
    Intakes,
    countryIds,
    filterOption,
    newFormatDisplayDate,
    oxfordUniversity,
    showEnrollmentAction
} from '../../../../components/_utils/appUtils'
import {getUrlPushWrapper} from '../../../../routes'
import {
    AllowComponentRightsWise,
    CheckBranchUserFxn
} from '../../../WebComponent/allowComponentRightsWise'
import {listAllCampus} from '../../../campus/actions/campus'
import {CheckUserRight} from '../../../dashboard/views/DashboardUserWise'
import {
    applicationCloseDirect,
    moveToDraftApplication,
    reCalculateCommission,
    updateApplicationTuitionFees
} from '../../../student/actions/student'
import ApplyCourse from '../../../student/views/applyForApplicationDrawer'
import {listAllUsers} from '../../../users/actions/user'
import {
    ReCreateFcmtLoaLetter,
    ReCreateFcmtOfferLetter,
    ReCreateFcmtPaymentReceipt,
    fcmtSendMailFxn,
    fcmtSendOfferMailFxn,
    fcmtSendReceiptMailFxn,
    oxfordSignedVisaSendForApproval
} from '../../actions/chooseStudent'
import ApprovedLoaRequest from '../../drawers/approvedLoaRequest'
import ChangeNameDrawer from '../../drawers/changeNameDrawer'
import ChangeUniversityComponent from '../../drawers/changeUniversity'
import CopyOfDraft from '../../drawers/copyOfDraft'
import AddNote from '../../drawers/createNoteComponent'
import Enrollment from '../../drawers/enrollment'
import FcmtStatusComponent from '../../drawers/fcmtStatus'
import ReActivateApplication from '../../drawers/reActivateApplicationDrawer'
import DocumentDrawer from '../../drawers/studentDocumentDrawer'
import SignedVisaLetter from '../../drawers/uploadSignedVisa'
import {ShowFcmtNote} from '../../fcmt/fcmtNotesComponent'
import TransferApplicationDrawer from '../transferApplication'
import StudentOtherApp from '../StudentOtherApplication'
import {oxfordInterviewApplicationsFxn} from "../../actions/oxfordAction";
import {oxfordStatusList} from "../../../../components/_utils/ApplicationStatusSteps";

const {Option} = Select
const initialState = {
    applicationList: [],
    applicationObj: {}
}
const commissionObj = {
    commissionAmount: 0,
    tuitionFee: 0,
    studentId: '',
    applicationId: ''
}
let viewOnlyRight = CheckBranchUserFxn() // check branch user right

const RenderComment = props => {
    let {item} = props
    let [hideV, setHideV] = useState(true)
    return (
        item && (
            <div>
                {item.addedByUserId && item.addedByUserId.name ? (
                    <div className={'alignRight colorPrimary font12'}>
                        (Added By : {item.addedByUserId.name} )
                    </div>
                ) : null}
                <div className={hideV ? 'appCommentDotsReplace' : ''}>
                    {item && item.text ? item.text : ''}
                </div>
                {item && item.text.length && item.text.length > 50 ? (
                    <div className={'alignRight'}>
                        {hideV ? (
                            <a
                                onClick={() => {
                                    setHideV(false)
                                }}>
                                Show More
                            </a>
                        ) : (
                            <a
                                onClick={() => {
                                    setHideV(true)
                                }}>
                                Show Less
                            </a>
                        )}
                    </div>
                ) : (
                    ''
                )}
            </div>
        )
    )
}
const OfferActionComponent = props => {
    let {file, reCreateOfferLetter, record, events} = props
    let {applications} = record
    return (
        <div>
            {file && file.path ? (
                <Tooltip title={'Download Offer Letter'}>
                    <a download={file.fileName} href={file.path}>
                        <img src={'/assets/icons/cloud-computing-download.png'}/>
                    </a>
                </Tooltip>
            ) : (
                ''
            )}

            <div className={'sprBox'}>
                {viewOnlyRight ? (
                    <>
                        <Popconfirm
                            title={'Are your sure, you want to Re-Create Offer Letter?'}
                            onConfirm={() => events.reCreateOfferLetter(record)}
                            okText="Yes"
                            cancelText="No">
                            <a className={'bs-link bs-xs'}>Re-Create Offer Letter</a>
                        </Popconfirm>
                        <div>
                            <AllowComponentRightsWise
                                rightUserType={['admin', 'branchManager', 'branchUser']}>
                                {applications.offerSent != false ? null : (
                                    <Popconfirm
                                        title={'Are your sure, you want to Send Offer Letter?'}
                                        onConfirm={() => events.sendFcmtMail(record, 'offer')}
                                        okText="Yes"
                                        cancelText="No">
                                        <button className={`bs_btn bs-default mt10 bs-xs`}>
                                            Send Offer
                                        </button>
                                    </Popconfirm>
                                )}
                            </AllowComponentRightsWise>
                        </div>
                    </>
                ) : null}
                <div className={'mt10'}>
                    {applications && applications.offerSendDate
                        ? `Send On : ${newFormatDisplayDate(
                            applications.offerReSendDate
                                ? applications.offerReSendDate
                                : applications.offerSendDate
                        )}`
                        : null}
                </div>
            </div>
        </div>
    )
}
const LoaRequestActionComponent = props => {
    let {record, val, events} = props
    return (
        <div>
            {viewOnlyRight ? (
                <>
                    {record.applications &&
                    record.applications.status == 'Offer Received' ? (
                        <Tooltip title={'Copy of Draft'}>
                            <a onClick={() => events.openLoaDrawer(record, false)}>
                                <img src={'/assets/icons/cloud-computing-upload.png'}/>
                            </a>
                        </Tooltip>
                    ) : null}
                </>
            ) : null}

            {val ? (
                <Tooltip title={val}>
                    <a>
                        <img
                            src={`${
                                val == 'Pending'
                                    ? '/assets/icons/pendingCheck.png'
                                    : '/assets/icons/doneCheck.png'
                            }`}
                            className={'sm'}
                        />
                    </a>
                </Tooltip>
            ) : null}

            {viewOnlyRight ? (
                <>
                    {record.applications &&
                    record.applications.status !== 'Offer Received' ? (
                        <div className={'mt10'}>
                            <a
                                className={'bs-link bs-xs'}
                                onClick={() => events.openLoaDrawer(record, true)}>
                                Re-Upload
                            </a>
                        </div>
                    ) : (
                        ''
                    )}

                    <AllowComponentRightsWise
                        rightUserType={['admin', 'branchManager', 'branchUser']}>
                        {val == 'Pending' ? (
                            <button
                                className={`bs_btn bs-default mt10 bs-xs`}
                                onClick={() => events.approveRequest(record)}>
                                Approve
                            </button>
                        ) : null}
                    </AllowComponentRightsWise>
                </>
            ) : null}
        </div>
    )
}
const ActionComponentSignedVisa = props => {
    let {record, val, events} = props;
    let {applications} = record;
    return (
        <div>

            {val ? (
                <Tooltip title={val}>
                    <a>
                        <img
                            src={`${
                                val == 'Pending'
                                    ? '/assets/icons/pendingCheck.png'
                                    : '/assets/icons/doneCheck.png'
                            }`}
                            className={'sm'}
                        />
                    </a>
                </Tooltip>
            ) : null}

            {viewOnlyRight ? (
                <>
                    {applications && applications.status == 'Upload Signed Visa Draft' && val == 'Approved' ? (
                        <div className={'mt10'}>
                            <a
                                className={'bs-link bs-xs'}
                                onClick={() => events.openSignedVisaDrawer(record, true)}>
                                Re-Upload
                            </a>
                        </div>
                    ) : (
                        ''
                    )}

                    <AllowComponentRightsWise
                        rightUserType={['admin', 'branchManager', 'branchUser']}>
                        {val == 'Pending' && applications.status == 'Upload Signed Visa Draft' && applications.sendVisaToNextUser ? (
                            <div>
                                <Popconfirm
                                    title={'Are your sure, you want to Approve Signed Visa Letter?'}
                                    onConfirm={() => events.approveVisaLetterRequest(record)}
                                    okText="Yes"
                                    cancelText="No">
                                    <button className={`bs_btn bs-default mt10 bs-xs`}>
                                        Approve
                                    </button>
                                </Popconfirm>
                            </div>
                        ) : null}
                    </AllowComponentRightsWise>
                </>
            ) : null}
        </div>
    )
}
const LoaActionComponent = props => {
    let {record, val, events} = props
    let {applications} = record
    return val && val.path ? (
        <>
            <Tooltip title={'Download LOA'}>
                <a download={val.fileName} href={val.path}>
                    <img src={'/assets/icons/cloud-computing-download.png'}/>
                </a>
            </Tooltip>
        </>
    ) : (
        ''
    )
}

const FinalVisaActionComponent = props => {
    let {record, val, events} = props
    let {applications} = record
    return val && val.path ? (
        <>
            <AllowComponentRightsWise rightUserType={['admin', 'branchManager', 'branchUser']}>
                <Tooltip title={'Download Final Visa Letter'}>
                    <a download={val.fileName} href={val.path}>
                        <img src={'/assets/icons/cloud-computing-download.png'}/>
                    </a>
                </Tooltip>
            </AllowComponentRightsWise>

        </>
    ) : (
        ''
    )
}

const ActionComponentForSignedVisa = props => {
    let {record, val, events} = props
    let {applications} = record
    return val && val.path ? (
        <>
            <Tooltip title={'Download Signed VisaDraft'}>
                <a download={val.fileName} href={val.path}>
                    <img src={'/assets/icons/cloud-computing-download.png'}/>
                </a>
            </Tooltip>
        </>
    ) : (
        ''
    )
}

const ViewStudentOxfordApplication = props => {
    let {onClose, studentObj = {}, reloadTable} = props

    const [state, setState] = useState(initialState)
    const [transferAppVisible, setTransferAppVisible] = useState(false)
    const [studentData, setStudentData] = useState({})
    const [visibleAddCourseDrawer, setVisibleAddCourseDrawer] = useState(false)
    const [name, setName] = useState('')
    const [dateOfBirth, setDateOfBirth] = useState('')
    const [allAgent, setAllAgent] = useState([])
    const [agentId, setAgentId] = useState('')
    const [visibleOtherAppDrawer, setVisibleOtherAppDrawer] = useState(false)
    const [visibleAddCommentDrawer, setVisibleAddCommentDrawer] = useState(false)
    const [totalApplication, setTotalApplication] = useState(0)
    const [visibleFeesModal, setVisibleFeesModal] = useState(false)
    const [feesObj, setFeesObj] = useState(commissionObj)
    const [currentStatus, setCurrentStatus] = useState('')
    const [status, setStatus] = useState('')
    let {applicationObj} = state
    let [tableSearch, setTableSearch] = useState(false)
    let [filters, setFilters] = useState({})
    let [visibleLoaReq, setVisibleLoaReq] = useState(false)
    let [visibleFcmtStatus, setVisibleFcmtStatus] = useState(false)
    let [visibleApprovalDrawer, setVisibleApprovalDrawer] = useState(false)
    let [visibleDocumentDrawer, setVisibleDocumentDrawer] = useState(false)
    let [visibleChangeNameDrawer, setVisibleChangeNameDrawer] = useState(false)
    let [isEditAble, setIsEditAble] = useState(false)
    let [campusList, setCampusList] = useState([])
    const [campusId, setCampusId] = useState('')
    const [branchUserList, setBranchUserList] = useState([])
    const [branchUserId, setBranchUserId] = useState('')
    const [intake, setIntake] = useState('')
    let [courseName, setCourseName] = useState('')
    let [visibleSignedVisaLetter, setVisibleSignedVisaLetter] = useState(false)

    let [selectedApplication, setSelectedApplication] = useState({
        visible: false,
        studentId: '',
        application: '',
        studentData: {}
    })
    let [enrollmentObj, setEnrollmentObj] = useState({
        visible: false,
        studentId: '',
        application: '',
        studentData: {}
    })
    let [changeUniversity, setChangeUniversity] = useState({
        visible: false,
        studentId: '',
        application: '',
        studentData: {}
    })
    const dispatch = useDispatch()
    let user = localStorage.getItem('user')
        ? JSON.parse(localStorage.getItem('user'))
        : null
    let {email} = user
    let showFcmtNoteField = ShowFcmtNote(email)

    const tableAppRef = useRef()

    let apiRequest = params => {
        return new Promise(async resolve => {
            let resp = await getParamsForApplicationList()
            params = {...params, ...resp}
            params.appUniversityId = oxfordUniversity;
            params.oxfordInterviewStatus = "Pass"
            if (!params.results) {
                params.results = 50
                params.count = 50
            }
            if (tableSearch) {
                params.page = 1
            }
            try {
                setFilters(params)
                let {data: resp} = await dispatch(oxfordInterviewApplicationsFxn({...params}))
                setTotalApplication(resp.total)
                setTableSearch(false)
                resolve(resp)
            } catch (e) {
            }
        })
    }


    useEffect(() => {
        // loadAgentData()
        loadCampusList()
        setFieldByParams()
        loadFcmtCourse()
        // loadCurrentUserData()
    }, [])

    const setFieldByParams = async () => {
        let resp = await getParamsForApplicationList()
        // setCurrentStatus(resp.status)

        if (resp.campusId) {
            setCampusId(resp.campusId)
        }
        if (resp.status) {
            setStatus(resp.status)
        }
        if (resp.agentId) {
            setAgentId(resp.agentId)
        }
        if (resp.intake) {
            setIntake(resp.intake)
        }
        if (resp.courseName) {
            setCourseName(resp.courseName)
        }
        if (resp.branchUserId) {
            setBranchUserId(resp.branchUserId)
        }
    }

    const loadAgentData = async () => {
        let params = {
            results: 15000,
            userType: 'agent',
            sortField: 'companyName',
            sortOrder: 'ascend',
            select: ['name', 'companyName']
        }
        let {data} = await dispatch(listAllUsers(params))
        if (data && data.length) {
            setAllAgent(data)
        }
    }
    const loadCampusList = async () => {
        let params = {}
        params.sortField = 'campusName'
        params.sortOrder = 'ascend'
        params.campusUniversity = oxfordUniversity
        params.results = 50
        params.count = 50
        params.select = ['campusName']
        let {data} = await dispatch(
            listAllCampus({
                ...params,
                regExFilters: ['campusAddress', 'campusName']
            })
        )
        setCampusList(data)
    }

    const loadFcmtCourse = async () => {
        let params = {}
        params.select = ['campusName']
        let {data} = await dispatch(
            listAllCampus({
                ...params,
                regExFilters: ['campusAddress', 'campusName']
            })
        )
    }
    const loadCurrentUserData = async () => {
        let params = {
            results: 1000,
            userType: 'branchUser',
            sortField: 'name',
            sortOrder: 'ascend',
            select: ['name', 'userType', 'mobile', 'email'],
            customQuery: {
                $or: [
                    {countryId: countryIds.canada},
                    {department: 'Visa Approved Department'}
                ]
            }
        }
        let {data} = await dispatch(listAllUsers(params))
        if (data && data.length) {
            setBranchUserList(data)
        }
    }

    const events = {
        reloadFxn: () => {
            tableAppRef.current.reload()
        },
        showTransferApp: () => {
            setTransferAppVisible(true)
        },
        hideTransferApp: () => {
            setTransferAppVisible(false)
            tableAppRef.current.reload()
        },
        showAddCourseDrawer: () => {
            setVisibleAddCourseDrawer(true)
        },
        hideAddCourseDrawer: () => {
            setVisibleAddCourseDrawer(false)
            tableAppRef.current.reload()
        },
        refreshApplication: () => {
            tableAppRef.current.reload()
        },

        closeDirectApp: async record => {
            let obj = {
                studentId: record._id,
                applicationId: record.applications._id
            }
            let resp = await dispatch(applicationCloseDirect(obj))
            tableAppRef.current.reload()
        },

        enterName: value => {
            setName(value)
        },
        moveToDraftApplicationFxn: async record => {
            let obj = {
                studentId: record._id,
                applicationId: record.applications._id
            }
            let resp = await dispatch(moveToDraftApplication(obj))
            tableAppRef.current.reload()
        },

        openOtherAppDrawer: value => {
            setState({
                ...state,
                applicationObj: value
            })
            setVisibleOtherAppDrawer(true)
        },
        closeOtherAppDrawer: () => {
            setState({
                ...state,
                applicationObj: {}
            })
            setVisibleOtherAppDrawer(false)
        },
        openAddCommentDrawer: value => {
            setState({
                ...state,
                applicationObj: value
            })
            setVisibleAddCommentDrawer(true)
        },
        closeAddCommentDrawer: () => {
            setState({
                ...state,
                applicationObj: {}
            })
            setVisibleAddCommentDrawer(false)
        },
        hideVisibleFeesModal: () => {
            setVisibleFeesModal(false)
        },
        setCommissionLocal: data => {
            setFeesObj({
                ...feesObj,
                ...data
            })
        },
        reCalculateCommissionFxn: async () => {
            let {commissionAmount} = await dispatch(reCalculateCommission(feesObj))
            if (commissionAmount) {
                events.setCommissionLocal({commissionAmount})
            }
        },
        updateApplicationTuitionFeesFxn: async () => {
            let {success, message} = await dispatch(
                updateApplicationTuitionFees(feesObj)
            )
            if (success) {
                setFeesObj(commissionObj)
                setVisibleFeesModal(false)
                events.reloadFxn()
            }
        },
        sendFcmtMail: async (record, type) => {
            let obj = {
                studentId: record._id,
                applicationId: record.applications._id
            }
            if (type == 'receipt') {
                let {success, message} = await dispatch(fcmtSendReceiptMailFxn(obj))
                if (success) {
                    events.reloadFxn()
                }
            } else if (type == 'offer') {
                let {success, message} = await dispatch(fcmtSendOfferMailFxn(obj))
                if (success) {
                    events.reloadFxn()
                }
            } else {
                let {success, message} = await dispatch(fcmtSendMailFxn(obj))
                if (success) {
                    events.reloadFxn()
                }
            }
        },
        openLoaDrawer: (record, editVal) => {
            setStudentData(record)
            setVisibleLoaReq(true)
            setIsEditAble(editVal)
        },
        closeLoaDrawer: () => {
            setStudentData({})
            setVisibleLoaReq(false)
        },
        approveRequest: async record => {
            setStudentData(record)
            setVisibleApprovalDrawer(true)
        },
        closeApproveRequest: async record => {
            setStudentData({})
            setVisibleApprovalDrawer(false)
        },
        openDocumentDrawer: async record => {
            setStudentData(record)
            setVisibleDocumentDrawer(true)
        },
        closeDocumentDrawer: async record => {
            setStudentData({})
            setVisibleDocumentDrawer(false)
        },
        changeNameOpen: async record => {
            setStudentData(record)
            setVisibleChangeNameDrawer(true)
        },
        closeChangeName: async record => {
            setStudentData({})
            setVisibleChangeNameDrawer(false)
        },
        openFcmtStatusDrawer: record => {
            setStudentData(record)
            setVisibleFcmtStatus(true)
        },
        closeFcmtStatusDrawer: () => {
            setStudentData({})
            setVisibleFcmtStatus(false)
        },
        openSignedVisaDrawer: (record, editVal) => {
            setStudentData(record)
            setVisibleSignedVisaLetter(true)
            setIsEditAble(editVal)
        },
        closeSignedVisaDrawer: () => {
            setStudentData({})
            setVisibleSignedVisaLetter(false)
        },
        reCreateOfferLetter: async record => {
            let obj = {
                studentId: record._id,
                applicationId: record.applications._id
            }
            let resp = await dispatch(ReCreateFcmtOfferLetter(obj))
            if (resp && resp.success) {
                tableAppRef.current.reload()
            }
        },
        reCreateLoaLetter: async record => {
            let obj = {
                studentId: record._id,
                applicationId: record.applications._id
            }
            let resp = await dispatch(ReCreateFcmtLoaLetter(obj))
            if (resp && resp.success) {
                tableAppRef.current.reload()
            }
        },
        reCreatePaymentReceipt: async record => {
            let obj = {
                studentId: record._id,
                applicationId: record.applications._id
            }
            let resp = await dispatch(ReCreateFcmtPaymentReceipt(obj))
            if (resp && resp.success) {
                tableAppRef.current.reload()
            }
        },
        approveVisaLetterRequest: async record => {
            let obj = {
                studentId: record._id,
                applicationId: record.applications._id
            }
            let resp = await dispatch(oxfordSignedVisaSendForApproval(obj))
            if (resp && resp.success) {
                tableAppRef.current.reload()
            }
        }
    }
    const reActivateApp = record => {
        setSelectedApplication({
            visible: true,
            studentId: record._id,
            studentData: record,
            application: record.applications
        })
    }
    const onCloseReActivate = () => {
        setSelectedApplication({
            visible: false,
            studentId: '',
            studentData: {},
            application: {}
        })
        tableAppRef.current.reload()
    }

    const enrollmentApp = record => {
        setEnrollmentObj({
            visible: true,
            studentId: record._id,
            studentData: record,
            application: record.applications
        })
    }
    const onCloseEnrollmentApp = () => {
        setEnrollmentObj({
            visible: false,
            studentId: '',
            studentData: {},
            application: {}
        })
        tableAppRef.current.reload()
    }

    const changeUniversityFxn = record => {
        setChangeUniversity({
            visible: true,
            studentId: record._id,
            studentData: record,
            application: record.applications
        })
    }
    const onCloseChangeUniversityFxn = () => {
        setChangeUniversity({
            visible: false,
            studentId: '',
            studentData: {},
            application: {}
        })
        tableAppRef.current.reload()
    }

    const enrollmentBtn = record => {
        let application = record.applications
        return !application.enrollment ? (
            showEnrollmentAction(application.statusList) ? (
                <button className="btn" onClick={() => enrollmentApp(record)}>
                    <img src={'/assets/enrollment.png'}/>
                </button>
            ) : null
        ) : null
    }

    const getParamsForApplicationList = () => {
        return new Promise(resolve => {
            let searchParams = new URLSearchParams(window.location.search)
            let appUniversityId = searchParams.get('appUniversityId')
            let name = searchParams.get('name')
            let campusId = searchParams.get('campusId')
            let status = searchParams.get('status')
            let agentId = searchParams.get('agentId')
            let branchUserId = searchParams.get('branchUserId')
            let intake = searchParams.get('intake')
            let courseName = searchParams.get('courseName')
            let obj = {}

            if (appUniversityId) {
                obj.appUniversityId = appUniversityId
            }
            if (name) {
                obj.name = name
            }

            if (campusId) {
                obj.campusId = campusId
            }
            if (status) {
                obj.status = status
            }
            if (agentId) {
                obj.agentId = agentId
            }
            if (intake) {
                obj.intake = intake
            }
            if (courseName) {
                obj.courseName = courseName
            }

            if (branchUserId) {
                obj.branchUserId = branchUserId
            }

            resolve(obj)
        })
    }

    const searchFxn = async () => {
        let resp = await getParamsForApplicationList()
        let obj = {}
        if (name) {
            obj.name = name
        }
        if (campusId) {
            obj.campusId = campusId
        }
        if (status) {
            obj.status = status
        }
        if (agentId) {
            obj.agentId = agentId
        }
        if (intake) {
            obj.intake = intake
        }
        if (courseName) {
            obj.courseName = courseName
        }
        if (branchUserId) {
            obj.branchUserId = branchUserId
        }
        dispatch(
            getUrlPushWrapper('oxfordPendingApplicationsList', {
                ...obj
            })
        )
        setTableSearch(true)
        setTimeout(() => {
            if (tableAppRef && tableAppRef.current) {
                tableAppRef.current.reload()
            }
        }, 200)
    }
    const clearFxn = async () => {
        let resp = await getParamsForApplicationList()
        let obj = {}
        setName('')
        setCampusId('')
        setStatus('')
        setAgentId('')
        setIntake('')
        setCourseName('')
        setBranchUserId('')
        dispatch(
            getUrlPushWrapper('oxfordPendingApplicationsList', {
                ...obj
            })
        )
        setTableSearch(true)
        setTimeout(() => {
            tableAppRef.current.reload()
        }, 200)
    }

    const columns = [
        {
            title: 'App#',
            key: 'applications.id',
            dataIndex: 'applications.id',
            width: 60,
            render: item => {
                return <div style={{width: 60}}>{item}</div>
            }
        },
        {
            title: 'Name',
            key: 'name',
            dataIndex: 'name',
            width: 120,
            render: (item, record) => {
                return (
                    <div style={{width: 120}}>
                        {/* <Tooltip title={'Edit Student'}>
              <a className={'linkAA'}
                 onClick={() => dispatch(
                   getPushPathWrapper('student.editStudent', { id: record._id }))
                 }>
                {item} <Icon type={'edit'} />
              </a>
            </Tooltip>*/}
                        {item}
                        <br/>
                        {newFormatDisplayDate(record.dateOfBirth)}
                    </div>
                )
            }
        },
        {
            title: 'Program',
            key: 'applications.courseName',
            dataIndex: 'applications.courseName',
            width: 150,
            render: item => {
                return <div style={{width: 150}}>{item}</div>
            }
        },
        {
            title: 'Intake',
            key: 'applications.intake',
            dataIndex: 'applications.intake',
            width: 80,
            render: (item, record) => {
                return (
                    <div style={{width: 80}}>
                        {item && item.month && item.year
                            ? `${item.month}, ${item.year}`
                            : ''}
                    </div>
                )
            }
        },
        {
            title: 'Campus',
            key: 'applications.campusId',
            dataIndex: 'applications.campusId',
            width: 85,
            render: (item, record) => {
                return (
                    <div style={{width: 85}}>
                        {item && item.campusName ? item.campusName : ''}
                    </div>
                )
            }
        },

        {
            title: 'Family / Given Name',
            key: 'applications.familyName',
            dataIndex: 'applications.familyName',
            width: 100,
            render: (item, record) => {
                return (
                    <div style={{width: 100}}>
                        {item && item ? (
                            <p>
                                Family Name : <p style={{fontWeight: 'bold'}}>{item}</p>
                            </p>
                        ) : (
                            ''
                        )}
                        {record && record.applications && record.applications.givenName ? (
                            <p>
                                Given Name :{' '}
                                <p style={{fontWeight: 'bold'}}>
                                    {record.applications.givenName}
                                </p>
                            </p>
                        ) : (
                            ''
                        )}
                        {record &&
                        record.applications &&
                        record.applications.loaRequested === 'Approved' ? (
                            <Icon
                                type={'edit'}
                                onClick={() => events.changeNameOpen(record)}
                            />
                        ) : null}
                    </div>
                )
            }
        },

        {
            title: 'Conditional Offer Letter',
            key: 'applications.offerReceiveLetter',
            dataIndex: 'applications.offerReceiveLetter',
            width: 100,
            render: (val, record) => {
                return (
                    <div style={{width: 100}} className={'fcmtDiv'}>
                        {/*{val && val.path ?
              <Tooltip title={'Download Offer Letter'}>
                <a download={val.fileName} href={val.path}>
                  <img src={'/assets/icons/cloud-computing-download.png'}/>
                </a></Tooltip> : ''}
            <br/>
            <Popconfirm title={'Are your sure, you want to Re-Create Offer Letter?'}
                        onConfirm={() => events.reCreateOfferLetter(record)}
                        okText="Yes" cancelText="No">
              <a className={'bs-link bs-xs'}>Re-Create Offer Letter</a>
            </Popconfirm>*/}
                        <OfferActionComponent file={val} record={record} events={events}/>
                    </div>
                )
            }
        },
        {
            title: 'Copy of Draft',
            key: 'applications.loaRequested',
            dataIndex: 'applications.loaRequested',
            width: 100,
            render: (val, record) => {
                return (
                    <div style={{width: 100}} className={'fcmtDiv'}>
                        <LoaRequestActionComponent
                            val={val}
                            record={record}
                            events={events}
                        />
                    </div>
                )
            }
        },
        {
            title: 'Download VLD',
            key: 'applications.loaLetter',
            dataIndex: 'applications.loaLetter',
            width: 100,
            render: (val, record) => {
                return (
                    <div style={{width: 100}} className={'fcmtDiv'}>
                        <LoaActionComponent val={val} record={record} events={events}/>
                    </div>
                )
            }
        },
        {
            title: 'Signed Visa Letter',
            key: 'applications.visaDraftVerify',
            dataIndex: 'applications.visaDraftVerify',
            width: 100,
            render: (val, record) => {
                let {sendToNextUser, applications} = record
                return (
                    <div style={{width: 100}} className={'fcmtDiv'}>
                        <ActionComponentSignedVisa
                            val={val}
                            record={record}
                            events={events}
                            user={user}
                        />
                    </div>
                )
            }
        },
        /*  {
              title: 'Download Signed Visa Ltr. ',
              key: 'applications.signedVisaDraft',
              dataIndex: 'applications.signedVisaDraft',
              width: 100,
              render: (val, record) => {
                  return (
                      <div style={{width: 100}} className={'fcmtDiv'}>
                          <ActionComponentForSignedVisa
                              val={val}
                              record={record}
                              events={events}
                          />
                      </div>
                  )
              }
          },*/
        {
            title: 'Final Visa Letter',
            key: 'applications.finalVisaLetter',
            dataIndex: 'applications.finalVisaLetter',
            width: 120,
            render: (val, record) => {
                return (
                    <div className={'fcmtDiv'}>
                        <FinalVisaActionComponent val={val} record={record} events={events}/>
                    </div>
                )
            }
        },
        /*{
            title: 'Note',
            key: 'applications.latestFcmtNote',
            dataIndex: 'applications.latestFcmtNote',
            width: 200,
            render: (val, record) => {
                let {applications, _id} = record
                return (
                    <div style={{width: 200}}>
                        {val && val.text ? <RenderComment item={val}/> : null}
                        <br/>
                        <FcmtNotesComponent
                            universityName={'Oxford'}
                            application={applications}
                            studentId={_id}
                            user={user}
                            reloadTable={events.reloadFxn}
                        />
                    </div>
                )
            }
        },*/
        {
            title: 'Documents',
            key: 'document',
            dataIndex: 'document',
            width: 120,
            render: (val, record) => {
                return (
                    <div className={'fcmtDiv'}>
                        <button
                            className={`bs_btn bs-default mt10 bs-xs`}
                            onClick={() => events.openDocumentDrawer(record)}>
                            View Documents
                        </button>
                    </div>
                )
            }
        }
    ]

    const resp = useSelector(state => {
        let {loadNoteRedux} = state.chooseApplication
        if (loadNoteRedux) {
            if (tableAppRef && tableAppRef.current) {
                tableAppRef.current.reload()
            }
            dispatch({type: 'END_LOAD_NOTE'})
        }
    })

    const loadColumns = () => {
        let columnArr = []
        lodash.each(columns, item => {
            if (item.hidden == undefined) {
                columnArr.push(item)
            } else {
                if (!item.hidden) {
                    columnArr.push(item)
                }
            }
        })
        return columnArr
    }

    const extra = (
        <div>
            <Row gutter={12}>
                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className="search-box-table round">
                        <input
                            className="form-control form-control"
                            type="search"
                            placeholder="Search by name..."
                            value={name}
                            onChange={e => {
                                events.enterName(e.target.value)
                            }}
                        />
                        <img src="/dist/img/search.png" alt=""/>
                    </div>
                </Col>
                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className="sort-box-table custom-sort-box-new">
                        <Select
                            name="Status"
                            onChange={item => setStatus(item)}
                            filterOption={filterOption}
                            className={'antSelect'}
                            placeholder={'Status'}
                            allowClear={true}
                            value={status || undefined}>
                            {oxfordStatusList && oxfordStatusList.length
                                ? oxfordStatusList.map((item, key) => {
                                    return (
                                        <Option value={item.name} key={key}>
                                            {item.name}
                                        </Option>
                                    )
                                })
                                : null}
                        </Select>
                    </div>
                </Col>

                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className="sort-box-table custom-sort-box-new">
                        <Select
                            name="Status"
                            onChange={item => setCampusId(item)}
                            className={'antSelect'}
                            placeholder={'Campus'}
                            allowClear={true}
                            value={campusId || undefined}>
                            {campusList && campusList.length
                                ? campusList.map((item, key) => {
                                    return (
                                        <Option value={item._id} key={key}>
                                            {item.campusName}
                                        </Option>
                                    )
                                })
                                : null}
                        </Select>
                    </div>
                </Col>
                {/*<Col md={4} sm={4} xs={8} lg={4}>
          <div className="sort-box-table custom-sort-box-new">
            <Select name="Current User" onChange={(item) => setBranchUserId(item)}
                    filterOption={filterOption}
                    className={'antSelect'}
                    allowClear={true}
                    showSearch={true}
                    placeholder={'Current User'}
                    value={branchUserId || undefined}>
              {branchUserList && branchUserList.length ? branchUserList.map((item, key) => {
                return (
                  <Option value={item._id} key={key}>{item.name}</Option>
                )
              }) : null}
            </Select>
          </div>
        </Col>*/}
                {/*     <CheckUserRight user={user} rightUserType={['admin', 'branchManager', 'branchUser']}>
          <Col md={4} sm={4} xs={8} lg={4}>
            <div className='sort-box-table custom-sort-box-new'>
              <Select name='Search By Agent'
                      filterOption={filterOption}
                      className={'antSelect'}
                      allowClear={true}
                      placeholder={'Agent'}
                      showSearch={true}
                      onChange={(item) => setAgentId(item)} value={agentId || undefined}>
                {allAgent && allAgent.length ? allAgent.map((item, key) => {
                  return (
                    <Option value={item._id} key={key}>{item.companyName}</Option>
                  )
                }) : null}
              </Select>
            </div>
          </Col>
        </CheckUserRight>*/}
                <CheckUserRight
                    user={user}
                    rightUserType={['admin', 'branchManager', 'branchUser']}>
                    <Col md={4} sm={4} xs={8} lg={4}>
                        <div className="sort-box-table custom-sort-box-new">
                            <Select
                                name="Intake"
                                onChange={item => setIntake(item)}
                                filterOption={filterOption}
                                className={'antSelect'}
                                allowClear={true}
                                showSearch={true}
                                placeholder={'Intake'}
                                value={intake || undefined}>
                                {Intakes && Intakes.length
                                    ? Intakes.map((item, key) => {
                                        return (
                                            <Option value={item} key={key}>
                                                {item}
                                            </Option>
                                        )
                                    })
                                    : null}
                            </Select>
                        </div>
                    </Col>
                    <Col span={5}>
                        <div className="sort-box-table custom-sort-box-new">
                            <div className="search-box-table round">
                                <input
                                    className="form-control form-control"
                                    type="search"
                                    placeholder="course name..."
                                    value={courseName}
                                    onChange={e => {
                                        setCourseName(e.target.value)
                                    }}
                                />
                                <img src="/dist/img/search.png" alt=""/>
                            </div>
                        </div>
                    </Col>
                </CheckUserRight>

                <Col md={4} sm={4} xs={12} lg={4}>
                    <Button onClick={() => searchFxn()} className={'roundBtn'}>
                        Search
                    </Button>
                    <Button onClick={() => clearFxn()} className={'roundBtn'}>
                        Clear
                    </Button>
                </Col>
            </Row>
        </div>
    )

    return (
        <React.Fragment>
            <div>
                <div className="row mt-4">
                    <div className="col-lg-12">
                        <div className="card">
                            <div
                                className="table-head d-flex align-items-center all-student-search"
                                style={{height: 60}}>
                                <Button
                                    onClick={() => dispatch(getUrlPushWrapper('dashboard'))}
                                    className={'roundGoBackBtn'}
                                    icon={'arrow-left'}>
                                    Back
                                </Button>
                                <h5>Oxford All Pending Application </h5>
                                <div
                                    className="search-box-table"
                                    style={{backgroundColor: 'transparent'}}>
                                    {currentStatus} (Total - {totalApplication})
                                </div>
                                <div className="sort-box-table mark-btn">
                                    {/*  <CheckUserRight
                    user={user}
                    rightUserType={['admin', 'branchManager', 'branchUser']}>
                    <span className={'sort-box-table-right'}>
                      <Tooltip title={'Export Excel'}>
                        {' '}
                        <Button
                          onClick={() => exportExcel()}
                          className={'roundBtn'}>
                          Export Excel
                        </Button>
                      </Tooltip>
                    </span>
                  </CheckUserRight>*/}
                                </div>
                            </div>
                            <div className="card-body table-responsive">
                                {extra}
                                <TableComp
                                    columns={loadColumns()}
                                    rowKey={(item, index) => {
                                        return index
                                    }}
                                    apiRequest={apiRequest}
                                    pagination={{
                                        position: 'top',
                                        showSizeChanger: true,
                                        pageSizeOptions: ['50', '100', '200', '500'],
                                        defaultPageSize: 50,
                                        current: filters.page ? filters.page : 1
                                    }}
                                    ref={tableAppRef}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {transferAppVisible ? (
                <TransferApplicationDrawer
                    visible={transferAppVisible}
                    closeTransferDrawer={() => events.hideTransferApp()}
                    studentId={studentData._id}
                    studentData={studentData}
                    application={studentData.applications}
                />
            ) : null}

            {visibleAddCourseDrawer ? (
                <ApplyCourse
                    visible={visibleAddCourseDrawer}
                    onClose={() => events.hideAddCourseDrawer()}
                    refreshApplication={() => events.refreshApplication()}
                    studentId={studentObj._id}
                />
            ) : null}

            {visibleOtherAppDrawer ? (
                <StudentOtherApp
                    visible={visibleOtherAppDrawer}
                    applicationObj={state.applicationObj}
                    onStudentOtherAppClose={() => events.closeOtherAppDrawer()}
                    reloadFxn={events.reloadFxn}
                />
            ) : null}

            {visibleAddCommentDrawer && applicationObj && applicationObj._id ? (
                <AddNote
                    visible={visibleAddCommentDrawer}
                    studentData={applicationObj}
                    addedType={'direct'}
                    onClose={() => events.closeAddCommentDrawer()}
                    applicationId={applicationObj.applications._id}
                />
            ) : null}

            {selectedApplication.visible ? (
                <ReActivateApplication
                    {...selectedApplication}
                    onClose={onCloseReActivate}
                />
            ) : null}

            {enrollmentObj.visible ? (
                <Enrollment {...enrollmentObj} onClose={onCloseEnrollmentApp}/>
            ) : null}

            {visibleLoaReq &&
            studentData.applications &&
            studentData.applications._id ? (
                <CopyOfDraft
                    onClose={events.closeLoaDrawer}
                    reloadTable={events.reloadFxn}
                    editAble={isEditAble}
                    applicationId={studentData.applications._id}
                    studentId={studentData._id}
                    studentData={studentData}
                    visible={visibleLoaReq}
                />
            ) : null}

            {visibleFcmtStatus &&
            studentData.applications &&
            studentData.applications._id ? (
                <FcmtStatusComponent
                    onClose={events.closeFcmtStatusDrawer}
                    reloadTable={events.reloadFxn}
                    applicationId={studentData.applications._id}
                    studentId={studentData._id}
                    studentData={studentData}
                    visible={visibleFcmtStatus}
                />
            ) : null}

            {visibleApprovalDrawer &&
            studentData.applications &&
            studentData.applications._id ? (
                <ApprovedLoaRequest
                    title={'Approve Copy of Draft'}
                    onClose={events.closeApproveRequest}
                    reloadTable={events.reloadFxn}
                    studentData={studentData}
                    applicationId={studentData.applications._id}
                    studentId={studentData._id}
                    visible={visibleApprovalDrawer}
                />
            ) : null}

            {visibleChangeNameDrawer ? (
                <ChangeNameDrawer
                    onClose={events.closeChangeName}
                    reloadTable={events.reloadFxn}
                    studentData={studentData}
                    applicationId={
                        studentData.applications ? studentData.applications._id : null
                    }
                    studentId={studentData._id}
                    visible={visibleChangeNameDrawer}
                />
            ) : null}

            {changeUniversity.visible ? (
                <ChangeUniversityComponent
                    {...changeUniversity}
                    onClose={onCloseChangeUniversityFxn}
                />
            ) : null}

            {visibleDocumentDrawer &&
            studentData.applications &&
            studentData.applications._id ? (
                <DocumentDrawer
                    onClose={events.closeDocumentDrawer}
                    reloadTable={events.reloadFxn}
                    studentData={studentData}
                    applicationId={studentData.applications._id}
                    studentId={studentData._id}
                    visible={visibleDocumentDrawer}
                />
            ) : null}
            {visibleSignedVisaLetter &&
            studentData.applications &&
            studentData.applications._id ? (
                <SignedVisaLetter
                    onClose={events.closeSignedVisaDrawer}
                    reloadTable={events.reloadFxn}
                    editAble={isEditAble}
                    applicationId={studentData.applications._id}
                    studentId={studentData._id}
                    studentData={studentData}
                    visible={visibleSignedVisaLetter}
                />
            ) : null}
        </React.Fragment>
    )
}
const mapStateToProps = ({global, router, chooseApplication}) => ({})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}
const WrappedComponent = Form.create()(ViewStudentOxfordApplication)
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WrappedComponent)
