import React, {useState, useEffect} from 'react'
import {Button, Card, Drawer, Form, Input} from 'antd'
import {InputBox} from '../../../components/_utils/appUtils'
import {useDispatch} from 'react-redux'
import {updateGcKeyFxn} from "../actions/chooseStudent";

const {TextArea} = Input
const GrKeyDrawer = (props) => {
    let {studentData, applicationId, visible, onClose} = props
    let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
    let dispatch = useDispatch()
    let [gcKey, setGcKey] = useState("")
    useEffect(() => {
        if (studentData && studentData.applications && studentData.applications.gcKey) {
            setGcKey(studentData.applications.gcKey)
        }
    }, [])
    const events = {
        handleSubmit: async () => {
            let obj = {
                studentId: studentData._id,
                gcKey: gcKey,
                applicationId: applicationId
            }
            let data = await dispatch(updateGcKeyFxn(obj))
            if (data && !data.error) {
                setGcKey('')
                onClose()
            }
        }
    }

    return (
        <Drawer width={700}
                title={'Add GCKey'}
                placement="left"
                visible={visible}
                onClose={() => {
                    onClose()
                }}>
            <Card bordered={false}>
                <Form>
                    <InputBox title={'GC Key'}>
                        <TextArea rows={7}
                                  onChange={(e) => {
                                      let {value} = e.target;
                                      setGcKey(value)
                                  }}
                                  name={'gcKey'}
                                  className={'form-control gcKey'}
                                  placeholder={'Note'} value={gcKey}/>
                    </InputBox>
                    <Form.Item className={'mt20 alignRight'}>
                        <Button type='primary' onClick={events.handleSubmit}>
                            SUBMIT
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </Drawer>
    )
}
export default GrKeyDrawer
