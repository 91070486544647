import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { universityListForMarketing } from '../../../components/_utils/appUtils'

const ListComponent = props => {
  let { directUniversityCount, openDirectUni } = props

  return (
    <>
      <div className="mt-4" style={{ border: 'none' }}>
        <ul className={'count'}>
          {universityListForMarketing.map(item => {
            return (
              <li
                key={item.id}
                className={'directUniCount'}
                onClick={() => {
                  openDirectUni(item.id)
                }}>
                <div className={'uniCountBox'}>
                  <LazyLoadImage src={item.image} />
                  <h5>
                    {directUniversityCount[item.id]
                      ? directUniversityCount[item.id]
                      : 0}
                  </h5>
                </div>
              </li>
            )
          })}
        </ul>
      </div>
    </>
  )
}
export default ListComponent
