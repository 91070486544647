import React, {useEffect, useState} from 'react'
import lodash from 'lodash'

const AgentBranchManagerComponent = (props) => {
    let {branchManagers, userId, studentName, countryId} = props
    let [user, setUser] = useState([])
    useEffect(() => {
        getBranchManager()
    }, [userId])

    const getBranchManager = () => {
        let managers = []
        lodash.each(branchManagers, (item, index) => {
            if (item.agents.includes(userId)) {
                if (item && item.countryId && item.countryId._id && item.countryId._id == countryId) {
                    managers.push({
                        name: item.name,
                        email: item.email,
                        mobile: item.mobile
                    })
                }
            }
        })
        setUser(managers)
    }
    return (
        <ul className={'countList'}>
            {user && user.length ? user.map((item, key) => {
                return (
                    item && item.name ? <li>{item.name}</li> : null
                )
            }) : null}
        </ul>
    )
}
const AgentListBranchManager = (props) => {
    let {branchManagers, userId, studentName} = props
    let [user, setUser] = useState([])
    useEffect(() => {
        getBranchManager()
    }, [userId])

    const getBranchManager = () => {
        let managers = []
        lodash.each(branchManagers, (item, index) => {
            if (item.agents.includes(userId)) {
                managers.push({
                    name: item.name,
                    email: item.email,
                    mobile: item.mobile
                })
            }
        })
        setUser(managers)
    }
    return (
        <ul className={'countList'}>
            {user && user.length ? user.map((item, key) => {
                return (
                    item && item.name ? <li>{item.name}</li> : null
                )
            }) : null}
        </ul>
    )
}
const UniversityBranchUserComponent = (props) => {
    let {branchUsers, universityId} = props
    let [user, setUser] = useState([])
    useEffect(() => {
        getBranchManager()
    }, [])

    const getBranchManager = () => {
        let managers = []
        lodash.each(branchUsers, (item, index) => {
            let universities = lodash.find(item.universities, (item1) => {
                return item1 && item1._id == universityId
            })
            if (universities) {
                managers.push({
                    name: item.name,
                    email: item.email,
                    mobile: item.mobile
                })
            }
        })
        setUser(managers)
    }
    return (
        <ul className={'countList'}>
            {user.map((item, key) => {
                return (
                    item && item.name ? <li>{item.name}</li> : null
                )
            })}
        </ul>
    )
}

const StudentMarketingUserComponent = (props) => {
    let {branchManagers, userId, countryId} = props
    let [user, setUser] = useState([])
    useEffect(() => {
        getBranchManager()
    }, [userId])

    const getBranchManager = () => {
        let managers = []
        lodash.each(branchManagers, (eachUser, index) => {
            let {assignStateCountry, agents} = eachUser;// get assigned state country list
            if (agents.includes(userId)) {
                if (assignStateCountry && assignStateCountry.length) {
                    let countryFilter = []
                    let findCountryExists = lodash.find(assignStateCountry, (item) => {
                        return item.countryId == countryId
                    })
                    if (findCountryExists) {
                        managers.push({
                            name: eachUser.name,
                            email: eachUser.email,
                            mobile: eachUser.mobile
                        })
                    }
                } else {
                    managers.push({
                        name: eachUser.name,
                        email: eachUser.email,
                        mobile: eachUser.mobile
                    })
                }
            }
        })
        setUser(managers)
    }
    return (
        <ul className={'countList'}>
            {user && user.length ? user.map((item, key) => {
                return (
                    item && item.name ? <li key={key}>{item.name}</li> : null
                )
            }) : null}
        </ul>
    )
}


const MarketingUsersComponent = (props) => {
    let {branchManagers, userId} = props
    let [user, setUser] = useState([])
    useEffect(() => {
        getBranchManager()
    }, [userId])

    const getBranchManager = () => {
        let managers = []
        lodash.each(branchManagers, (eachUser, index) => {
            let {agents} = eachUser;// get assigned state country list
            if (agents.includes(userId)) {
                managers.push({
                    name: eachUser.name,
                    email: eachUser.email,
                    mobile: eachUser.mobile
                })
            }
        })
        setUser(managers)
    }
    return (
        <ul className={'countList'}>
            {user && user.length ? user.map((item, key) => {
                return (
                    item && item.name ? <li>{item.name}</li> : null
                )
            }) : null}
        </ul>
    )
}


export {
    AgentBranchManagerComponent,
    UniversityBranchUserComponent,
    AgentListBranchManager,
    StudentMarketingUserComponent,
    MarketingUsersComponent,
}
