import {
  Button,
  Col,
  DatePicker,
  Drawer,
  Form,
  InputNumber,
  Row,
  Select,
  Switch,
  notification
} from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import style from '../views/styles.less'
const Option = Select
const initialState = {
  month: '',
  year: '',
  startDate: {},
  endDate: {},
  active: false,
  secondYearStartDate: {},
  secondYearEndDate: {}
}

const Intake = props => {
  let { onClose, visible, addIntakeFxn, data, pathway } = props
  let [state, setState] = useState(initialState)

  let setIntakeState = data => {
    setState({ ...state, ...data })
  }

  useEffect(() => {
    setIntakeData()
  }, [])

  const setIntakeData = () => {
    setState({ ...data })
  }

  let addIntake = () => {
    let {month, year, startDate, endDate, secondYearStartDate, secondYearEndDate} = state
    if (!month || !year) {
      return notification.warning({message: 'Choose Month and Year'})
    }
    if (!startDate) {
      return notification.warning({message: 'Choose start date.'})
    }
    if (!endDate) {
      return notification.warning({message: 'Choose end date.'})
    }
    if (pathway) {
      if (!secondYearStartDate) {
        return notification.warning({message: 'Choose Second year start date.'})
      }
      if (!secondYearEndDate) {
        return notification.warning({message: 'Choose Second year end date.'})
      }
    }
    addIntakeFxn(state)
  }

  return (
    <Drawer
      width={800}
      title={'Intake List'}
      placement="right"
      visible={visible}
      onClose={onClose}>
      <div className="row">
        <div className="col-lg-12">
          <Form noValidate>
            <div className="form-box mt-4">
              <div className="card unizportal">
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label={'Activation Status'}>
                      <Select
                        placeholder={'Choose Month'}
                        value={state.month}
                        onChange={value => setIntakeState({ month: value })}>
                        <Option value={'January'}>January</Option>
                        <Option value={'February'}>February</Option>
                        <Option value={'March'}>March</Option>
                        <Option value={'April'}>April</Option>
                        <Option value={'May'}>May</Option>
                        <Option value={'June'}>June</Option>
                        <Option value={'July'}>July</Option>
                        <Option value={'August'}>August</Option>
                        <Option value={'September'}>September</Option>
                        <Option value={'October'}>October</Option>
                        <Option value={'November'}>November</Option>
                        <Option value={'December'}>December</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  {/* <Col span={12}>
                    <Form.Item label={'Month'}>
                      <InputNumber
                        placeholder={'Month'}
                        type={'number'}
                        value={state.month}
                        onChange={value => setIntakeState({ month: value })}
                      />
                    </Form.Item>
                  </Col> */}
                  {/* <Col span={12}>
                    <Form.Item label={'Year'}>
                      <InputNumber
                        placeholder={'Year'}
                        type={'number'}
                        value={state.year}
                        onChange={value => setIntakeState({ year: value })}
                      />
                    </Form.Item>
                  </Col> */}
                  <Col span={12}>
                    <Form.Item label={'Year'}>
                      <Select
                        placeholder={'Choose Year'}
                        value={state.year}
                        onChange={value => setIntakeState({ year: value })}>
                        <Option value={2023}>2023</Option>
                        <Option value={2024}>2024</Option>
                        <Option value={2025}>2025</Option>
                        <Option value={2026}>2026</Option>
                        <Option value={2027}>2027</Option>
                        <Option value={2028}>2028</Option>
                        <Option value={2029}>2029</Option>
                        <Option value={2030}>2030</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label={'Start Date'}>
                      <DatePicker
                        placeholder={'Start Date'}
                        value={
                          state.startDate
                            ? moment(state.startDate._d)
                            : undefined
                        }
                        onChange={date => setIntakeState({ startDate: date })}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label={'End Date'}>
                      <DatePicker
                        placeholder={'End Date'}
                        value={
                          state.endDate ? moment(state.endDate._d) : undefined
                        }
                        onChange={date => setIntakeState({ endDate: date })}
                      />
                    </Form.Item>
                  </Col>
                  {pathway ? (
                    <>
                      <Col span={12}>
                        <Form.Item label={'2nd Year Start Date'}>
                          <DatePicker
                            placeholder={'2nd Year Start Date'}
                            value={
                              state.secondYearStartDate
                                ? moment(state.secondYearStartDate._d)
                                : undefined
                            }
                            onChange={date =>
                              setIntakeState({ secondYearStartDate: date })
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label={'2nd Year End Date'}>
                          <DatePicker
                            placeholder={'2nd Year End Date'}
                            value={
                              state.secondYearEndDate
                                ? moment(state.secondYearEndDate._d)
                                : undefined
                            }
                            onChange={date =>
                              setIntakeState({ secondYearEndDate: date })
                            }
                          />
                        </Form.Item>
                      </Col>
                    </>
                  ) : null}

                  <Col span={12}>
                    <Form.Item label={'Active'}>
                      <Switch
                        checked={state.active}
                        onChange={checked =>
                          setIntakeState({ active: checked })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="btn"
                        onClick={() => addIntake()}>
                        Add
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </Drawer>
  )
}

export default Intake
