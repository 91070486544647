import React, { useState, useEffect } from 'react'
import { Form, Button, Card, notification, Spin, Row, Col, Drawer } from 'antd'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import { listAllCountries } from '../../countries/actions/countries'
import { listQuickSearchUniversities } from '../../university/actions/university'
import { listAllCourse, loadCourseIntakesFxn } from '../../course/actions/courseAction'
import { addSelectedCourse } from '../../applications/actions/chooseStudent'
import { showPageLoad, hidePageLoad } from '../../../modules/actions'
import { listCourseCampus } from '../../campus/actions/campus'
import { fcmtUniversity, DirectUniversityCheck } from '../../../components/_utils/appUtils'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import { EligibilityIcon } from '../../university/drawers/universityEligibility'

const ApplyForApplicationDrawer = (props) => {
  const dispatch = useDispatch()
  const {
    form: { getFieldDecorator, setFieldsValue, getFieldValue, resetFields },
    onClose,
    studentId,
    visible,
    refreshApplication
  } = props
  let [allCountry, setAllCountry] = useState([])
  let [countryUniversity, setCountryUniversity] = useState([])
  let [universityCourse, setUniversityCourse] = useState([])
  let [universityCampus, setUniversityCampus] = useState([])
  let [selectedCourse, setSelectedCourse] = useState({})
  let [intakesList, setIntakesList] = useState([])
  let [intake, setIntake] = useState({})
  let [isDirectUniversity, setIsDirectUniversity] = useState(false)
  useEffect(() => {
    events.loadCountry()
  }, [])

  const events = {
    loadCountry: async () => {
      let filters = {
        sortField: 'countryName',
        sortOrder: 'ascend'
      }
      let { data } = await dispatch(listAllCountries(filters))
      setAllCountry(data)
    },
    loadUniversity: async (item) => {
      let obj = {
        results: 1000000,
        universityCountry: item,
        sortField: 'universityName',
        sortOrder: 'ascend'
      }
      let data = await dispatch(listQuickSearchUniversities(obj))
      setCountryUniversity(data)
    },
    loadCourse: async (item) => {
      let obj = {
        results: 1000000,
        courseUniversity: item,
        sortField: 'courseName',
        sortOrder: 'ascend'
      }
      let { data } = await dispatch(listAllCourse(obj))
      setUniversityCourse(data)
    },
    loadCampus: async (item) => {
      let obj = {
        results: 1000000,
        courseId: item
      }
      let { data } = await dispatch(listCourseCampus(obj))
      setUniversityCampus(data)
      if (isDirectUniversity && data && data.length) {
        setFieldsValue({
          campusId: data[0]._id
        })
      }
    },
    loadIntakes: async (courseId) => {
      let resp = await dispatch(loadCourseIntakesFxn({ courseId }))
      let data = []
      _.each(resp, (item) => {
        if (item) {
          item.value = `${item.intake}-${item.year}`
          item.label = `${item.intake}, ${item.year}`
          data.push(item)
        }
      })
      setIntakesList(data)
      if (isDirectUniversity && resp && resp.length) {
        setFieldsValue({
          intakeTemp: data[0].value
        })
        let intake = {
          month: resp[0].intake,
          year: resp[0].year
        }
        setIntake(intake)
      }
    }
  }
  let inputTypes = {
    fields: [
      {
        key: 'countryId',
        label: 'Country',
        required: true,
        type: 'select',
        showSearch: true,
        placeholder: 'Country',
        options: allCountry,
        keyAccessor: x => x._id,
        valueAccessor: x => `${x.countryName}`,
        onChange: x => {
          props.form.setFieldsValue({
            countryId: x,
            universityId: undefined,
            courseId: undefined,
            intakeTemp: undefined,
            campusId: undefined
          })
          events.loadUniversity(x)
          setIntake({})
        }
      },
      {
        key: 'universityId',
        label: 'University',
        required: true,
        type: 'select',
        placeholder: 'University',
        showSearch: true,
        customExtra: (
          <span className={'customExtra'}>
            <EligibilityIcon universityId={getFieldValue('universityId')} countryId={getFieldValue('countryId')}/>
          </span>
        ),
        options: countryUniversity,
        keyAccessor: x => x._id,
        valueAccessor: x => `${x.universityName}`,
        onChange: x => {
          props.form.setFieldsValue({
            universityId: x,
            courseId: undefined,
            intakeTemp: undefined,
            campusId: undefined
          })
          setIntake({})
          events.loadCourse(x)
          setIsDirectUniversity(DirectUniversityCheck(x))
        }
      },
      {
        key: 'courseId' || undefined,
        label: 'Course',
        required: true,
        type: 'select',
        showSearch: true,
        placeholder: 'Course',
        options: universityCourse,
        keyAccessor: x => x._id,
        valueAccessor: x => `${x.courseName}`,
        onChange: x => {
          props.form.setFieldsValue({
            courseId: x,
            intakeTemp: undefined,
            campusId: undefined
          })
          events.loadCampus(x)
          let selectedCourse = _.find(universityCourse, (item) => {
            return item._id == x
          })
          setSelectedCourse(selectedCourse)
          events.loadIntakes(x)
          setIntake({})

        }
      },
      {
        key: 'campusId',
        label: 'Campus',
        span: 12,
        type: 'select',
        placeholder: 'Campus',
        // showSearch: true,
        required: isDirectUniversity,
        options: universityCampus,
        keyAccessor: x => x._id,
        valueAccessor: x => `${x.campusName}`,
        onChange: x => {
          props.form.setFieldsValue({
            campusId: x
          })
        }
      },
      {
        key: 'intakeTemp',
        label: 'Intake',
        type: 'select',
        placeholder: 'Intake' || undefined,
        span: 12,
        // showSearch: true,
        required: isDirectUniversity,
        options: intakesList,
        keyAccessor: x => x.value,
        valueAccessor: x => `${x.label}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            intakeTemp: x
          })
          if (x) {
            let intakeVal = x.split('-')
            let intake = {
              month: intakeVal[0],
              year: intakeVal[1]
            }
            setIntake(intake)
          } else {
            setIntake({})
          }
        }
      }
    ]
  }

  const submitFormLayout = {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 10, offset: 7 },
      md: { span: 12, offset: 8 }
    }
  }
  const formItemLayout = {
    labelCol: {
      xs: { span: 0 },
      sm: { span: 0 },
      md: { span: 0 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }

  const handleSubmit = (e) => {
    const { form } = props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        dispatch(showPageLoad())

        let findUniversity = _.find(countryUniversity, (item) => {
          return item._id == valData.universityId
        })
        if (findUniversity) {
          valData.applicationFee = findUniversity.applicationFee
        }
        valData = { ...valData, ...selectedCourse }
        valData.courseUniversity = valData.universityId
        valData.intake = intake
        let data = await dispatch(addSelectedCourse({ ...valData, studentId, showNotification: true }))
        if (data && !data.error) {
          form.resetFields()
          setSelectedCourse({})
          setIntake({})
          dispatch(hidePageLoad())
          refreshApplication()
        } else {
          dispatch(hidePageLoad())
        }

      } else {
        notification.warning({
          message: 'Fill All Required Fields'
        })
      }
    })
  }

  return (
    <React.Fragment>
      <Drawer visible={visible}
              title={`Add New Application`}
              width={'50%'}
              closable={true}
              maskClosable={true}
              onClose={onClose}>
        <Card>
          <Form onSubmit={handleSubmit} style={{ marginTop: 8, textAlign: 'left' }}>
            <Row gutter={24}>

              {inputTypes.fields.map((item, key) => {
                return (
                  <Col span={item.span ? item.span : 24} md={item.span ? item.span : 24} sm={24} xs={24} key={key}
                       className={'mb10'}>
                    <GetEachFormFields
                      item={item}
                      getFieldDecorator={getFieldDecorator}
                      formItemLayout={formItemLayout}/>
                  </Col>
                )
              })}
            </Row>

            <Form.Item style={{ marginTop: 25 }}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Drawer>
    </React.Fragment>
  )
}


const WrappedApplyForApplicationDrawer = Form.create()(ApplyForApplicationDrawer)
export default WrappedApplyForApplicationDrawer
