import React, {useEffect, useRef, useState} from 'react'
import {connect, useDispatch, useSelector} from 'react-redux'
import {TableComp} from 'sz-react-utils-lite'
import {DefaultTablePagination} from '../../../components/_utils/appUtils'
import {ColumnWidth} from '../../WebComponent/columnWidth'
import {
    loadDistinctStateOfOnlineAgentsFxn,
    loadUserListFxn
} from '../../users/actions/user'
import {Tag} from "antd"

const OnlineAgentList = props => {
    let dispatch = useDispatch()
    let tableRef = useRef()
    let [total, setTotal] = useState(0)
    let [stateWiseCount, setStateWiseCount] = useState([])
    let [selectedState, setSelectedState] = useState([])

    useEffect(() => {
        loadDistinctState()
    }, [])


    useEffect(() => {
        tableRef.current.reload()
    }, [selectedState])
    const handleBoxClick = state => {
        const isSelected = selectedState.includes(state._id)
        if (isSelected) {
            setSelectedState(selectedState.filter(selected => selected !== state._id))
        } else {
            setSelectedState([...selectedState, state._id])
        }
    }
    const loadDistinctState = async () => {
        let {data, success} = await dispatch(loadDistinctStateOfOnlineAgentsFxn({}))
        if (success) {
            console.log(data)
            setStateWiseCount(data)
        }
    }
    let apiRequest = async params => {
        return new Promise(async resolve => {
            params.sortField = 'lastSeen'
            params.sortOrder = 'descend'
            params.userType = ['agent']
            params.isOnline = true
            params.results = 50
            params.count = 50
            let resp = await dispatch(
                loadUserListFxn({
                    ...params,
                    stateName: selectedState,
                    regExFilters: ['name', 'address', 'mobile', 'email']
                })
            )
            setTotal(resp.total)

            resolve(resp)
        })
    }

    const resp = useSelector(state => {
        let {reloadOnlineAgent} = state.global
        if (reloadOnlineAgent) {
            dispatch({type: 'RELOAD_ONLINE_AGENT', value: false})
            setTimeout(() => {
                if (tableRef && tableRef.current) {
                    tableRef.current.reload()
                }
            }, 500)
        }
    })

    const columns = [
        {
            title: '#',
            key: '_id',
            dataIndex: '_id',
            width: 50,
            render: (ite, record, index) => {
                return <React.Fragment>{index + 1}</React.Fragment>
            }
        },
        {
            title: 'Name',
            key: 'name',
            sorter: true,
            dataIndex: 'name',
            searchTextName: 'name',
            width: 100,
            render: (item, record) => {
                return (
                    <ColumnWidth width={100}>
                        <div className={'agentName'}>
                            {item}
                            <div className={'agentOnline'}/>
                        </div>
                    </ColumnWidth>
                )
            }
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            searchTextName: 'email',
            width: 120,
            render: item => {
                return <ColumnWidth width={120}>{item}</ColumnWidth>
            }
        },
        {
            title: 'Mobile No',
            dataIndex: 'mobile',
            key: 'mobile',
            searchTextName: 'mobile',
            width: 80,
            render: item => {
                return <ColumnWidth width={80}>{item}</ColumnWidth>
            }
        },
        {
            title: 'State',
            dataIndex: 'stateName',
            key: 'stateName',
            width: 120,
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            searchTextName: 'address',
            width: 150,
            render: item => {
                return <ColumnWidth width={150}>{item}</ColumnWidth>
            }
        },
        {
            title: 'Action',
            key: 'action',
            dataIndex: 'action',
            width: 150,
            render: (val, record) => {
                return <React.Fragment/>
            }
        }
    ]
    return (
        <>

            <div className="row  mt-4">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="table-head d-flex align-items-center">
                            <h5>All Online Agents ( Total : {total})</h5>
                            <div className="search-box-table"/>
                        </div>
                        <div className="card-body table-responsive">
                            <div className={'row'}>
                                <div className={'col-md-12 mt10'}>
                                    <label style={{fontWeight: 500}}>Filter by state</label>

                                    <div className='input-group input-group-sm'>

                                        <div>
                                            {stateWiseCount &&
                                            stateWiseCount.length ?
                                                stateWiseCount.map((state, index) =>
                                                    state._id ? (
                                                        <Tag
                                                            key={index}
                                                            className={`selectable-box ${
                                                                selectedState.includes(state._id) ? 'selected' : ''
                                                            }`}
                                                            onClick={() => handleBoxClick(state)}>
                                                            <span className="box-text">{state._id}</span>
                                                            <div className="countCircle">{state.count}</div>
                                                        </Tag>
                                                    ) : null
                                                ) : null}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <TableComp
                                columns={columns}
                                ref={tableRef}
                                pagination={DefaultTablePagination()}
                                apiRequest={apiRequest}
                                extraProps={{scroll: {x: 1000}}}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default OnlineAgentList
