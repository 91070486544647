import React, { useState, useEffect } from 'react'
import PageHeaderWrapper from '../../components/PageHeaderWrapper'
import { Form, Select, Button, Card, notification, Spin, Row, Col } from 'antd'
import _ from 'lodash'
import { FormUtils as GetAllFormFields } from 'sz-react-utils-lite'
import { useDispatch, useSelector } from 'react-redux'
import { listAllCountries } from '../countries/actions/countries'
import { listAllStates } from '../state/actions/state'
import { addCity } from '../city/actions/city'
import GetEachFormFields from '../../components/_utils/appFormUtils'
// import { addState } from '../actions/state'
import { Intakes, IntakesYears } from '../../components/_utils/appUtils'
import { addIntake } from './actions'


const AddIntake = (props) => {
  const { form: { getFieldDecorator, setFieldsValue, getFieldValue, resetFields } } = props
  const dispatch = useDispatch()
  const { loader, allCountries, allStates } = useSelector(state => ({
    loader: state.cityReducers.loader,
    allCountries: state.countryReducers.allCountries,
    allStates: state.stateReducers.allStates
  }))

  const handleCountrySelect = (x) => {
    // console.log('inside handle counry', x)
    dispatch(listAllStates({
      results: 1000000, customQuery: { country: x },
      sortField: 'stateName', sortOrder: 'ascend'
    }))
  }


  let inputTypes = {
    fields: [
      {
        key: 'month', label: 'Month', type: 'select', span: 8,
        required: true,
        options: Intakes,
        onChange: x => {
          props.form.setFieldsValue({
            month: x
          })
        }
      },
      {
        key: 'year', label: 'Year', type: 'select', span: 8,
        required: true,
        options: IntakesYears,
        onChange: x => {
          props.form.setFieldsValue({
            year: x
          })
        }
      }
    ]
  }

  const submitFormLayout = {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 10, offset: 7 },
      md: { span: 12, offset: 8 }
    }
  }
  const formItemLayout = {
    labelCol: {
      xs: { span: 0 },
      sm: { span: 0 },
      md: { span: 0 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }

  const handleSubmit = e => {
    const { form } = props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      console.log(valData)
      if (!err) {
        let data = await dispatch(addIntake(valData))
        if (data && !data.error) {
          form.resetFields()
        }
      } else {
        notification.warning({
          message: 'Fill All Required Fields'
        })
      }
    })
  }

  useEffect(() => {
    dispatch({ type: 'CITY_HIDE_LOADER' })
    dispatch(listAllCountries({ results: 1000000, sortField: 'countryName', sortOrder: 'ascend' }))
    dispatch({ type: 'EMPTY_STATE_LIST' })
  }, [])


  return (
    <div className='row'>
      <div className='col-lg-12'>
        <Form onSubmit={handleSubmit}>
          <div className='form-box mt-4'>

            <div className='d-flex align-items-center mb-3 heading-form'>
              <h5>Add New City</h5>
            </div>

            <div className='card unizportal'>
              <Row gutter={24}>
                {inputTypes.fields.map((item, key) => {
                  return (
                    <Col span={8} key={key}>
                      <GetEachFormFields
                        item={item}
                        getFieldDecorator={getFieldDecorator}
                        formItemLayout={formItemLayout} />
                    </Col>
                  )
                })}
              </Row>
              <Form.Item>
                <Button type='primary' htmlType='submit' className='btn'>
                  SAVE
                </Button>
              </Form.Item>

            </div>
          </div>

        </Form>
      </div>
    </div>
  )
}
const WrappedIntake = Form.create()(AddIntake)
export default WrappedIntake
