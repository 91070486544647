import React, {Component, useState, useEffect, useRef} from 'react'
import {TableComp} from 'sz-react-utils-lite'
import {
    Card, Tag,
    Tooltip, Icon, Drawer, List, Popconfirm, Checkbox, notification, Button, Row, Col, Select,
} from 'antd'
import {connect} from 'react-redux'
import {listAllUsers, blockAgent, updateInsuranceRight} from '../actions/user'
import {
    ConditionOptions,
    DefaultTablePagination,
    BooleanOptions, filterOption, Intakes, departmentObj, getDirectUniversityName
} from '../../../components/_utils/appUtils'
import UpdatePassword from '../views/changePassword'
import EditUser from '../views/edit'
import EditSubAgent from '../views/editSubAgent'
import {ColumnWidth} from '../../WebComponent/columnWidth'
import ViewApplication from '../../applications/views/viewApplication'
import {CheckUserRight} from '../../dashboard/views/DashboardUserWise'
import UserRightList from './rightsList'
import CheckActiveUser from './activeCheck'
import {longDisplayDate} from '../../../components/_utils/appUtils'
import LogsComponents from '../drawer/logsComponents'
import {getExportAgent} from '../actions/user'
import {AgentListBranchManager} from '../../../components/_utils/AgentBranchManagerComponent'

import {getUrlPushWrapper} from '../../../routes'
import {LoadAllCountry, LoadCity, LoadState} from '../../../components/_utils/countryUtil'
import _ from 'lodash'
import moment from 'moment'
import MarketingDrawer from '../drawer/marketingDrawer'
import {listAllCountries} from "../../countries/actions/countries";

const userTypeOptions = [
    {value: 'agent', text: 'Agent'},
    {value: 'subAgent', text: 'Sub Agent'}
]
const commissionType = [
    {text: 'Silver', value: 'silver'},
    {text: 'Gold', value: 'gold'},
    {text: 'Platinum', value: 'platinum'}
]

const {Option} = Select

class AllUsers extends Component {

    events = {
        hideApplicationDrawer: () => {
            this.setState({
                visibleApplicationDrawer: false
            })
        },
        showApplicationDrawer: (data) => {
            this.setState({
                visibleApplicationDrawer: true,
                queryData: {
                    agentId: data._id
                }
            })
        },
        showUserRightDrawer: (data) => {
            this.setState({
                visibleUserRightDrawer: true,
                selectedUser: data
            })
        },
        showMarketingDrawer: (data) => {
            this.setState({
                marketingState: {
                    agentMarketing: data.agentMarketing,
                    armUserData: data.armUsers,
                    visible: true,
                    agentId: data._id
                }
            })
        },

        hideMarketingDrawer: () => {
            this.setState({
                marketingState: {
                    agentMarketing: {},
                    armUserData: [],
                    visible: false,
                    agentId: ""
                }
            })
        },
        hideUserRightDrawer: () => {
            this.setState({
                visibleUserRightDrawer: false,
                selectedUser: {}
            })
        },
        closeAndUpdateUserRightDrawer: () => {
            this.setState({
                visibleUserRightDrawer: false,
                selectedUser: {}
            }, () => {
                this.tableRef.current.reload()
            })
        },
        chooseCountry: (name) => {
            let countryName = _.find(this.state.allCountries, (item) => {
                return item.name == name
            })
            if (countryName) {
                this.setState({
                    countryName: countryName.name ? countryName.name : '',
                    stateName: undefined,
                    cityName: undefined,
                    pincode: undefined,
                    allStates: countryName.id ? LoadState(countryName.id) : []
                })
            }
        },
        chooseState: (name) => {
            let stateName = _.find(this.state.allStates, (item) => {
                return item.name == name
            })
            if (stateName) {
                this.setState({
                    stateName: stateName.name ? stateName.name : '',
                    cityName: undefined,
                    pincode: undefined,
                    allCities: stateName.id ? LoadCity(stateName.id) : []
                })
            }
        },
        chooseCity: (name) => {
            this.setState({
                cityName: name ? name : ''
            })
        }
    }

    constructor(props) {
        super(props)
        this.state = {
            viewInfoDrawer: false,
            selectedRow: {},
            allAgents: [],
            branchManager: [],
            selectedUser: {},
            visibleUpdatePassword: false,
            visibleUpdateUser: false,
            visibleUpdateSubAgent: false,
            visibleApplicationDrawer: false,
            queryData: {},
            visibleUserRightDrawer: false,
            approvedAgentRightsUser: [],
            zeroRecordAgent: false,
            filters: {},
            total: 0,
            countryName: '',
            stateName: '',
            cityName: '',
            pincode: '',
            allCountries: LoadAllCountry(),
            allStates: [],
            allCities: [],
            allMarketingUser: [],
            keyMoment: moment(),
            countryList: [],
            armUserData: [],
            marketingState: {
                visible: false,
                agentId: "",
                agentMarketing: {}
            }
        }
        this.tableRef = React.createRef()
    }

    componentDidMount() {
        // this.loadAllAgent()
        this.loadRightUser()
        this.loadBranchManager()
        this.loadMarketingUser()
        this.setFieldByParams()
        this.loadCountry()
    }

    loadCountry = async () => {
        let {dispatch} = this.props;
        let countryFilter = {
            results: 10,
            sortField: 'countryName',
            sortOrder: 'ascend'
        }
        let {data} = await dispatch(listAllCountries(countryFilter))
        this.setState({
            countryList: data
        })
    }


    async loadBranchManager() {
        let {dispatch} = this.props
        let obj = {
            userType: 'branchManager',
            customQuery: {
                'agents.0': {$exists: true}
            },
            results: 100,
            select: ['name', 'email', 'mobile']
        }
        let {data} = await dispatch(listAllUsers(obj))
        this.setState({branchManager: data})
    }


    async loadAllAgent() {
        let {dispatch} = this.props
        let {data} = await dispatch(listAllUsers({userType: 'agent'}))
        this.setState({allAgents: data})
    }

    async loadMarketingUser() {
        let {dispatch} = this.props
        let obj = {
            userType: 'branchUser',
            select: ['name', 'mobile'],
            customQuery: {
                department: departmentObj.marketing
            },
            results: 100
        }
        let {data} = await dispatch(listAllUsers(obj))
        this.setState({allMarketingUser: data})
    }

    loadRightUser = async () => {
        let params = {
            results: 200,
            approvedAgent: true,
            select: ['name', 'userType']
        }
        let {data} = await this.props.dispatch(listAllUsers(params))
        this.setState({approvedAgentRightsUser: data})
    }

    viewInfo(record) {
        this.setState({
            selectedRow: record,
            viewInfoDrawer: true
        })
    }

    onClose = () => {
        this.setState({
            selectedRow: {},
            viewInfoDrawer: false
        })
    }

    closeUpdatePassword = () => {
        this.setState({
            selectedUser: {},
            visibleUpdatePassword: false
        }, () => {
            this.tableRef.current.reload()
        })
    }

    closeEditUser = () => {
        this.setState({
            selectedUser: {},
            visibleUpdateUser: false
        }, () => {
            this.tableRef.current.reload()
        })
    }

    closeEditSubAgent = () => {
        this.setState({
            selectedUser: {},
            visibleUpdateSubAgent: false
        }, () => {
            this.tableRef.current.reload()
        })
    }
    updateAgentBlockStatus = async (id) => {
        let {dispatch} = this.props
        let resp = await dispatch(blockAgent({agentId: id}))
        if (resp && resp.success) {
            this.tableRef.current.reload()
        }
    }

    apiRequest = (params) => {
        let {dispatch} = this.props
        return new Promise(async (resolve) => {
            params.sortField = 'name'
            params.sortOrder = 'ascend'

            if (!params.userType || (params.userType && params.userType.length == 0)) {
                params.userType = ['agent']
            }

            let resp1 = await this.getParamsForApplicationList()

            if (resp1.countryName) {
                params.countryName = resp1.countryName
            }
            if (resp1.stateName) {
                params.stateName = resp1.stateName
            }
            if (resp1.cityName) {
                params.cityName = resp1.cityName
            }
            if (resp1.pincode) {
                params.pincode = resp1.pincode
            }

            if (resp1.agentWiseManagerId) {
                params.agentWiseManagerId = resp1.agentWiseManagerId
            }
            if (resp1.marketingManagerId) {
                params.marketingManagerId = resp1.marketingManagerId
            }
            if (resp1.email) {
                params.email = resp1.email
            }
            if (resp1.mobile) {
                params.mobile = resp1.mobile
            }
            if (resp1.companyName) {
                params.companyName = resp1.companyName
            }
            if (resp1.name) {
                params.name = resp1.name
            }

            let finalFilter = {
                ...params, showAll: true, zeroRecordAgent: this.state.zeroRecordAgent,
                regExFilters: ['name', 'address', 'mobile', 'email', 'cityName', 'companyName']
            }
            this.setState({filters: finalFilter})
            let resp = await dispatch(listAllUsers({...finalFilter}))
            this.setState({total: resp.total})
            resolve(resp)
        })
    }

    exportAgentsFxn = async () => {
        let {filters, total} = this.state
        let {dispatch} = this.props
        let {success, message} = await dispatch(getExportAgent({...filters, results: total}))
        if (success) {
            notification.success({message: message})
        } else {
            notification.warning({message: 'Error, please try again'})
            return
        }
    }

    getParamsForApplicationList = () => {
        return new Promise((resolve) => {
            let searchParams = new URLSearchParams(window.location.search)
            let countryName = searchParams.get('countryName')
            let stateName = searchParams.get('stateName')
            let cityName = searchParams.get('cityName')
            let pincode = searchParams.get('pincode')
            let agentWiseManagerId = searchParams.get('agentWiseManagerId')
            let name = searchParams.get('name')
            let email = searchParams.get('email')
            let companyName = searchParams.get('companyName')
            let mobile = searchParams.get('mobile')
            let marketingManagerId = searchParams.get('marketingManagerId')

            let obj = {}
            if (countryName) {
                obj.countryName = countryName
            }
            if (stateName) {
                obj.stateName = stateName
            }
            if (cityName) {
                obj.cityName = cityName
            }
            if (pincode) {
                obj.pincode = pincode
            }
            if (agentWiseManagerId) {
                obj.agentWiseManagerId = agentWiseManagerId
            }
            if (marketingManagerId) {
                obj.marketingManagerId = marketingManagerId
            }
            if (name) {
                obj.name = name
            }
            if (companyName) {
                obj.companyName = companyName
            }
            if (mobile) {
                obj.mobile = mobile
            }
            if (email) {
                obj.email = email
            }
            resolve(obj)
        })
    }

    setFieldByParams = async () => {
        let resp = await this.getParamsForApplicationList()
        let obj = {}
        if (resp.countryName) {
            obj.countryName = resp.countryName
        }
        if (resp.stateName) {
            obj.stateName = resp.stateName
        }
        if (resp.cityName) {
            obj.cityName = resp.cityName
        }
        if (resp.pincode) {
            obj.pincode = resp.pincode
        }
        if (resp.agentWiseManagerId) {
            obj.agentWiseManagerId = resp.agentWiseManagerId
        }
        if (resp.marketingManagerId) {
            obj.marketingManagerId = resp.marketingManagerId
        }
        if (resp.name) {
            obj.name = resp.name
        }
        if (resp.email) {
            obj.email = resp.email
        }
        if (resp.companyName) {
            obj.companyName = resp.companyName
        }
        if (resp.mobile) {
            obj.mobile = resp.mobile
        }
        this.setState({...obj})
    }


    searchFxn = async () => {
        let {dispatch} = this.props
        // let resp = await this.getParamsForApplicationList()
        let obj = {}
        if (this.state.countryName) {
            obj.countryName = this.state.countryName
        }
        if (this.state.stateName) {
            obj.stateName = this.state.stateName
        }
        if (this.state.cityName) {
            obj.cityName = this.state.cityName
        }
        if (this.state.pincode) {
            obj.pincode = this.state.pincode
        }
        if (this.state.agentWiseManagerId) {
            obj.agentWiseManagerId = this.state.agentWiseManagerId
        }
        if (this.state.marketingManagerId) {
            obj.marketingManagerId = this.state.marketingManagerId
        }
        if (this.state.name) {
            obj.name = this.state.name
        }
        if (this.state.email) {
            obj.email = this.state.email
        }
        if (this.state.companyName) {
            obj.companyName = this.state.companyName
        }
        if (this.state.mobile) {
            obj.mobile = this.state.mobile
        }
        dispatch(
            getUrlPushWrapper('user.allAgents', {
                ...obj
            }))
        // setTableSearch(true)
        setTimeout(() => {
            if (this.tableRef && this.tableRef.current) {
                this.tableRef.current.reload()
            }
        }, 200)
    }
    clearFxn = async () => {
        let {dispatch} = this.props
        // let resp = await getParamsForApplicationList()
        let obj = {}
        this.setState({
            countryName: '',
            stateName: '',
            cityName: '',
            pincode: '',
            agentWiseManagerId: "",
            marketingManagerId: "",
            name: "",
            email: "",
            companyName: "",
            mobile: "",
        })
        dispatch(
            getUrlPushWrapper('user.allAgents', {
                ...obj
            }))
        setTimeout(() => {
            this.tableRef.current.reload()
        }, 200)
    }

    UpdateInsuranceRight = async (id) => {
        let {dispatch} = this.props
        let resp = await dispatch(updateInsuranceRight({userId: id}))
        if (resp && resp.success) {
            this.tableRef.current.reload()
        }
    }

    render() {
        let {
            selectedRow,
            countryList,
            visibleUpdatePassword,
            selectedUser,
            visibleUpdateUser,
            visibleUpdateSubAgent,
            visibleApplicationDrawer,
            queryData,
            visibleUserRightDrawer,
            marketingState,
            approvedAgentRightsUser,
            zeroRecordAgent,
            branchManager,
            allMarketingUser,
            countryName
        } = this.state
        let {dispatch} = this.props

        const columns = [
            {
                title: '#',
                key: '_id',
                dataIndex: '_id',
                width: 50,
                render: (ite, record, index) => {
                    return (
                        <React.Fragment>
                            {index + 1}
                        </React.Fragment>
                    )
                }
            },
            {
                title: 'Name',
                key: 'name',
                sorter: true,
                dataIndex: 'name',
                // searchTextName: 'name',
                width: 100,
                render: (item, record) => {
                    return (
                        <ColumnWidth width={100}>
                            {item}
                            <CheckActiveUser userId={record._id} {...this.props} />
                        </ColumnWidth>
                    )
                }
            },
            {
                title: 'Company Name',
                key: 'companyName',
                sorter: true,
                dataIndex: 'companyName',
                // searchTextName: 'companyName',
                width: 150
            },
            {
                title: 'Email',
                dataIndex: 'email',
                key: 'email',
                // searchTextName: 'email',
                width: 120,
                render: (item) => {
                    return (
                        <ColumnWidth width={120}>{item}</ColumnWidth>
                    )
                }
            },
            {
                title: 'Mobile No',
                dataIndex: 'mobile',
                key: 'mobile',
                // searchTextName: 'mobile',
                width: 80,
                render: (item) => {
                    return (
                        <ColumnWidth width={80}>{item}</ColumnWidth>
                    )
                }
            },
            {
                title: 'Address',
                dataIndex: 'address',
                key: 'address',
                searchTextName: 'address',
                width: 150,
                render: (item) => {
                    return (
                        <ColumnWidth width={150}>{item}</ColumnWidth>
                    )
                }
            },
            {
                title: 'Show Commission',
                dataIndex: 'showCommission',
                key: 'showCommission',
                filters: ConditionOptions.map(x => ({value: x.value, text: x.name})),
                width: 120,
                render: (item) => {
                    return (
                        <ColumnWidth width={120}>{item != false ? 'Yes' : 'No'}</ColumnWidth>
                    )
                }
            },
            {
                title: 'Add Team',
                dataIndex: 'allowAddingUser',
                key: 'allowAddingUser',
                filters: BooleanOptions.map(x => ({value: x.value, text: x.name})),
                width: 100,
                render: (item) => {
                    return (
                        <ColumnWidth width={100}>{item != false ? 'Enable' : 'Disable'}</ColumnWidth>
                    )
                }
            },

            {
                title: 'City Name',
                dataIndex: 'cityName',
                key: 'cityName',
                // searchTextName: 'address',
                // filters: CityJson.map(x => ({ value: x.name, text: x.name })),
                width: 100,
                render: (item) => {
                    return (
                        <ColumnWidth width={80}>{item}</ColumnWidth>
                    )
                }
            },

            {
                title: 'State Name',
                dataIndex: 'stateName',
                key: 'stateName',
                width: 100,
                // filters: StateJson.map(x => ({ value: x.name, text: x.name })),
                render: (item) => {
                    return (
                        <ColumnWidth width={80}>{item}</ColumnWidth>
                    )
                }
            },
            {
                title: 'Country Name',
                dataIndex: 'countryName',
                key: 'countryName',
                width: 100,
                // filters: CountryJson.map(x => ({ value: x.name, text: x.name })),
                render: (item) => {
                    return (
                        <ColumnWidth width={80}>{item}</ColumnWidth>
                    )
                }
            },
            {
                title: 'Pincode',
                dataIndex: 'pincode',
                key: 'pincode',
                // searchTextName: 'address',
                width: 100,
                render: (item) => {
                    return (
                        <ColumnWidth width={80}>{item}</ColumnWidth>
                    )
                }
            },

            {
                title: 'Logo',
                dataIndex: 'logo',
                key: 'logo',
                width: 100,
                render: (val) => {
                    return (
                        <ColumnWidth width={100}>  {val && val.url ?
                            <img src={val.url} className={'imgSm'}/> : null}</ColumnWidth>
                    )
                }
            },
            {
                title: 'Alternate Email',
                dataIndex: 'alternateEmails',
                key: 'alternateEmails',
                width: 200,
                render: (val) => {
                    let emails = ''
                    if (val) {
                        emails = val.split(',')
                    }
                    return (
                        <div>
                            {emails && emails.length ?
                                <ul className={'countList'}>
                                    {emails.map((ite, key) => {
                                        return <li key={key}>{ite}</li>
                                    })}
                                </ul>
                                : null}
                        </div>
                    )
                }
            },
            {
                title: 'Approved By',
                dataIndex: 'createdByUser.userId',
                key: 'createdByUser.userId',
                width: 150,
                filters: approvedAgentRightsUser.map(x => ({value: x._id, text: x.name})),
                render: (item, record) => {
                    let val = record.createdByUser
                    return (
                        <ColumnWidth width={150}>
                            {val && val.userId ? <div>
                                User : {val.name}
                                {val.time ? <div>
                                    Date : {longDisplayDate(val.time)}
                                </div> : null}

                            </div> : null}
                        </ColumnWidth>
                    )
                }
            },
            {
                title: 'Block',
                dataIndex: 'block',
                width: 80,
                key: 'block',
                filters: ConditionOptions.map(x => ({value: x.value, text: x.name})),
                render: (item, record) => {
                    return (
                        <React.Fragment>
                            {record && record.userType && (record.userType !== 'admin') ?
                                <div>
                                    <Popconfirm
                                        title={`Are your sure, you want to ${item ? 'Un-Block' : 'Block'} this User?`}
                                        onConfirm={() => {
                                            this.updateAgentBlockStatus(record._id)
                                        }}
                                        okText='Yes' cancelText='No'>
                                        {item ? <div className={'label label-success labelNew'}>Yes</div> :
                                            <div className={'label label-warning labelNew'}>No</div>}
                                    </Popconfirm>
                                </div> : null}
                        </React.Fragment>
                    )
                }
            },
            {
                title: 'Managers',
                dataIndex: 'managers',
                width: 120,
                key: 'managers',
                render: (item, record) => {
                    let {armUsers} = record;
                    return (
                        <div key={this.state.keyMoment}>
                            {armUsers && armUsers.length ? <ul>
                                {armUsers.map((item, index) => {
                                    return (
                                        <li key={item._id}>{index + 1}. {item && item.name ? item.name : ""}</li>
                                    )
                                })}
                            </ul> : null}
                            {/* {branchManager && branchManager.length ?
                                <AgentListBranchManager
                                    userId={record._id}
                                    branchManagers={this.state.branchManager}/> : null}*/}
                        </div>
                    )
                }
            },
            {
                title: 'Marketing User',
                dataIndex: 'marketingUser',
                width: 150,
                key: 'marketingUser',
                render: (item, record) => {
                    let {agentMarketing} = record;
                    return (
                        <div key={this.state.keyMoment}>
                            {agentMarketing && agentMarketing.length ?
                                <ul>
                                    {agentMarketing.map((item, index) => {
                                        let {marketingUserId, countryId} = item
                                        return (
                                            <li key={item._id}>
                                                {index + 1}. {marketingUserId && marketingUserId.name ? marketingUserId.name : null}
                                                {/*({countryId && countryId.countryName ? countryId.countryName : ""})*/}
                                                ({getDirectUniversityName(item.university)})
                                            </li>
                                        )
                                    })}
                                </ul> :
                                null}
                            {/* {allMarketingUser && allMarketingUser.length ?
                                <AgentListBranchManager
                                    userId={record._id}
                                    branchManagers={allMarketingUser}/> : null}*/}
                        </div>
                    )
                }
            },
            {
                title: 'Certificate',
                dataIndex: 'certificate',
                width: 150,
                key: 'certificate',
                render: (item, record) => {
                    let {agentCambieCertificate, agentFCMTCertificate, agentKASACertificate} = record
                    return (
                        <div className={'certificate_box'}>
                            {item && item.url ?
                                <Tag><a className={'btn btn-link btn-xs'} href={item.url} target={'_blank'}>
                                    View Certificate
                                </a> </Tag> : null}

                        </div>
                    )
                }
            },

            /*    {
                    title: 'Insurance Right',
                    dataIndex: 'insuranceRight',
                    key: 'insuranceRight',
                    width: 90,
                    render: (item, record) => {
                        let {showInsurance} = record
                        return (
                            <div>
                                <div>
                                    <Popconfirm title={`Are your sure, you want make change Insurance right?`}
                                                onConfirm={() => {
                                                    this.UpdateInsuranceRight(record._id)
                                                }}
                                                okText='Yes' cancelText='No'>
                                        <Switch
                                            checkedChildren={'Yes'}
                                            unCheckedChildren={'No'}
                                            checked={showInsurance}
                                        />
                                    </Popconfirm>
                                </div>
                            </div>
                        )
                    }
                },
    */
            {
                title: 'Action',
                key: '_id',
                dataIndex: '_id',
                fixed: "right",
                width: 120,
                render: (val, record) => {
                    return (
                        <div className={'tableAction'}>
                            <React.Fragment>
                                {record.userType !== 'master' ?
                                    <Tooltip title='Edit'>
                                        <button
                                            className={'btn'}
                                            onClick={() => {
                                                {
                                                    record.userType == 'subAgent' ?
                                                        this.setState({
                                                            visibleUpdateSubAgent: true,
                                                            selectedUser: record
                                                        }) :
                                                        this.setState({
                                                            visibleUpdateUser: true,
                                                            selectedUser: record
                                                        })
                                                }
                                            }}>
                                            <Icon type={'edit'}/>
                                        </button>
                                    </Tooltip> : null}
                                <Tooltip title='Change Password'>
                                    <button
                                        className={'btn'}
                                        onClick={() => {
                                            this.setState({
                                                selectedUser: record,
                                                visibleUpdatePassword: true
                                            })
                                        }}>
                                        <Icon type={'key'}/>
                                    </button>
                                </Tooltip>
                                <Tooltip title='Applications'>
                                    <button
                                        className={'btn'}
                                        onClick={() => {
                                            this.events.showApplicationDrawer(record)
                                        }}
                                    >
                                        <Icon type={'eye'}/>
                                    </button>
                                </Tooltip>
                                <br/>
                                <CheckUserRight user={this.props.currentUser}
                                                rightUserType={['admin', 'branchManager']}>
                                    <Tooltip title='Rights'>
                                        <button
                                            className={'btn'}
                                            onClick={() => {
                                                this.events.showUserRightDrawer(record)
                                            }}
                                        ><Icon type='trophy'/>
                                        </button>
                                    </Tooltip>
                                </CheckUserRight>

                                <LogsComponents user={record} {...this.props} currentUser={this.props.currentUser}/>
                                <Tooltip title='Assign Marketing User'>
                                    <button
                                        className={'btn'}
                                        onClick={() => {
                                            this.events.showMarketingDrawer(record)
                                        }}>
                                        <Icon type="solution"/>
                                    </button>
                                </Tooltip>
                            </React.Fragment>
                        </div>
                    )
                }
            }
        ]

        const filterBlock = (
            <Row gutter={16} className={'mt10'}>
                {/*<AllowComponentRightsWise rightUserType={['admin']}>*/}
                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className='sort-box-table custom-sort-box-new'>
                        <Select name='Search By Country'
                                onChange={(item) => this.events.chooseCountry(item)}
                                filterOption={filterOption}
                                className={'antSelect'}
                                placeholder='Country'
                                allowClear={true}
                                showSearch={true}
                                value={this.state.countryName || undefined}>
                            {this.state.allCountries && this.state.allCountries.length ? this.state.allCountries.map((item, key) => {
                                return (
                                    <Option value={item.name} key={key}>{item.name}</Option>
                                )
                            }) : null}
                        </Select>
                    </div>
                </Col>
                {/*</AllowComponentRightsWise>*/}
                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className='sort-box-table custom-sort-box-new'>
                        <Select name='Search By State'
                                onChange={(item) => this.events.chooseState(item)}
                                filterOption={filterOption}
                                className={'antSelect'}
                                placeholder='State'
                                allowClear={true}
                                showSearch={true}
                                value={this.state.stateName || undefined}>
                            {this.state.allStates && this.state.allStates.length ? this.state.allStates.map((item, key) => {
                                return (
                                    <Option value={item.name} key={key}>{item.name}</Option>
                                )
                            }) : null}
                        </Select>
                    </div>
                </Col>
                <Col md={4} sm={4} xs={8} lg={4}>
                    {!countryName || countryName == 'India' ?
                        <div className='sort-box-table custom-sort-box-new'>
                            <Select name='Search By City'
                                    onChange={(item) => this.events.chooseCity(item)}
                                    filterOption={filterOption}
                                    className={'antSelect'}
                                    placeholder='City'
                                    allowClear={true}
                                    showSearch={true}
                                    value={this.state.cityName || undefined}>
                                {this.state.allCities && this.state.allCities.length ? this.state.allCities.map((item, key) => {
                                    return (
                                        <Option value={item.name} key={key}>{item.name}</Option>
                                    )
                                }) : null}
                            </Select>
                        </div> :
                        <div className='search-box-table round ml0'>
                            <input name='Search By City' className='form-control'
                                   onChange={(e) => {
                                       this.setState({cityName: e.target.value})
                                   }}
                                   placeholder='City'
                                   value={this.state.cityName}/>
                            <img src='/dist/img/search.png' alt=''/>

                        </div>}
                </Col>
                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className='sort-box-table custom-sort-box-new'>
                        <Select name='Search By Branch Manager'
                                onChange={(item) => {
                                    this.setState({agentWiseManagerId: item})
                                }}
                                filterOption={filterOption}
                                className={'antSelect'}
                                placeholder='Branch Manager'
                                allowClear={true}
                                showSearch={false}
                                value={this.state.agentWiseManagerId || undefined}>
                            {this.state.branchManager && this.state.branchManager.length ? this.state.branchManager.map((item, key) => {
                                return (
                                    <Option value={item._id} key={key}>{item.name}</Option>
                                )
                            }) : null}
                        </Select>
                    </div>
                </Col>
                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className='sort-box-table custom-sort-box-new'>
                        <Select name='Search By Marketing Manager'
                                onChange={(item) => {
                                    this.setState({marketingManagerId: item})
                                }}
                                filterOption={filterOption}
                                className={'antSelect'}
                                placeholder='Marketing Manager'
                                allowClear={true}
                                showSearch={false}
                                value={this.state.marketingManagerId || undefined}>
                            {this.state.allMarketingUser && this.state.allMarketingUser.length ? this.state.allMarketingUser.map((item, key) => {
                                return (
                                    <Option value={item._id} key={key}>{item.name}</Option>
                                )
                            }) : null}
                        </Select>
                    </div>
                </Col>

                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className='search-box-table round ml0'>
                        <input className='form-control form-control' type='search'
                               placeholder='Pincode' value={this.state.pincode} onChange={(e) => {
                            this.setState({pincode: e.target.value})
                        }}/>
                        <img src='/dist/img/search.png' alt=''/>
                    </div>
                </Col>


                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className='search-box-table round ml0'>
                        <input className='form-control form-control' type='search'
                               placeholder='Name' value={this.state.name} onChange={(e) => {
                            this.setState({name: e.target.value})
                        }}/>
                        <img src='/dist/img/search.png' alt=''/>
                    </div>
                </Col>


                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className='search-box-table round ml0'>
                        <input className='form-control form-control' type='search'
                               placeholder='Company Name' value={this.state.companyName} onChange={(e) => {
                            this.setState({companyName: e.target.value})
                        }}/>
                        <img src='/dist/img/search.png' alt=''/>
                    </div>
                </Col>


                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className='search-box-table round ml0'>
                        <input className='form-control form-control' type='search'
                               placeholder='Email' value={this.state.email} onChange={(e) => {
                            this.setState({email: e.target.value})
                        }}/>
                        <img src='/dist/img/search.png' alt=''/>
                    </div>
                </Col>
                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className='search-box-table round ml0'>
                        <input className='form-control form-control' type='search'
                               placeholder='Mobile No' value={this.state.mobile} onChange={(e) => {
                            this.setState({mobile: e.target.value})
                        }}/>
                        <img src='/dist/img/search.png' alt=''/>
                    </div>
                </Col>


                <Col md={8} sm={8} xs={12} lg={4}>
                    <Button onClick={() => this.searchFxn()} className={'roundBtn'}>Search</Button>
                    <Button onClick={() => this.clearFxn()} className={'roundBtn'}>
                        Clear
                    </Button>
                </Col>


            </Row>
        )
        return (
            <div>
                <div className='row  mt-4'>
                    <div className='col-lg-12'>
                        <div className='card'>
                            <div className='table-head d-flex align-items-center'>
                                <h5>All Agents ({this.state.total})</h5>
                                <div className='search-box-table'>

                                </div>

                                <div className='sort-box-table mark-btn'>
                                    <Checkbox
                                        value={zeroRecordAgent}
                                        checked={zeroRecordAgent}
                                        onChange={(e) => this.setState({zeroRecordAgent: !zeroRecordAgent}, () => {
                                            this.tableRef.current.reload()
                                        })}
                                        style={{float: 'right', marginBottom: 10}}>
                                        Show Zero Record Agents
                                    </Checkbox>
                                </div>
                                <CheckUserRight user={this.props.currentUser}
                                                rightUserType={['admin', 'branchManager']}>
                      <span className={'sort-box-table-right'}>
                        <Tooltip title={'Export Excel'}>
                          {' '} <Button onClick={() => this.exportAgentsFxn()}
                                        className={'roundBtn'}>Export Excel</Button>
                        </Tooltip>
                      </span>
                                </CheckUserRight>
                            </div>

                            <div className='card-body table-responsive'>
                                {filterBlock}
                                <TableComp columns={columns}
                                           ref={this.tableRef}
                                           pagination={DefaultTablePagination()}
                                           apiRequest={this.apiRequest}
                                           extraProps={{scroll: {x: 1000}}}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <Drawer visible={this.state.viewInfoDrawer}
                        width={520}
                        onClose={this.onClose}>
                    <Card title={'Universities'}>
                        {selectedRow && selectedRow.universities && selectedRow.universities.length ?
                            <List
                                size='small'
                                bordered
                                dataSource={selectedRow.universities}
                                renderItem={item =>
                                    <List.Item>{item && item.universityName ? item.universityName : null}</List.Item>}
                            /> : null
                        }

                    </Card>
                </Drawer>
                {visibleUpdatePassword ? <UpdatePassword
                    visible={visibleUpdatePassword}
                    onClose={() => this.closeUpdatePassword()}
                    userData={selectedUser}/> : ''}

                {visibleUpdateUser ? <EditUser
                    visible={visibleUpdateUser}
                    reloadTable={() => {
                        this.loadBranchManager()
                        this.loadMarketingUser()
                        setTimeout(() => {
                            this.tableRef.current.reload()
                            this.setState({keyMoment: moment()})
                        }, 2000)
                    }}
                    onClose={() => this.closeEditUser()}
                    userData={selectedUser}/> : ''}
                {visibleUpdateSubAgent ? <EditSubAgent
                    visible={visibleUpdateSubAgent}
                    onClose={() => this.closeEditSubAgent()}
                    userData={selectedUser}/> : ''}

                {visibleApplicationDrawer ? <ViewApplication
                    visible={visibleApplicationDrawer}
                    pageType={'allApplication'}
                    onClose={() => this.events.hideApplicationDrawer()}
                    queryData={queryData}
                /> : null}

                {visibleUserRightDrawer ? <UserRightList
                    user={selectedUser}
                    visible={visibleUserRightDrawer}
                    onClose={() => this.events.hideUserRightDrawer()}
                    closeAndUpdate={() => this.events.closeAndUpdateUserRightDrawer()}/> : ''}


                {marketingState.visible ?
                    <MarketingDrawer
                        {...marketingState}
                        countryList={countryList}
                        onclose={() => this.events.hideMarketingDrawer()}
                        onSubmit={() => {
                            this.events.hideMarketingDrawer()
                            this.tableRef.current.reload()
                        }}/>
                    : ''}

            </div>
        )

    }
}


const mapStateToProps = ({global}) => ({
    categories: global.categories,
    currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AllUsers)
