export const ukApplicationStatusSteps = [
    {name: 'New Student'},
    {name: 'Waiting To Apply'},
    // { name: 'Draft Applications' },
    {name: 'Offer Applied'},
    {name: 'Conditional Offer Received'},
    {name: 'Unconditional Offer Received'},
    {name: 'Provide CAS Documents'},
    {name: 'CAS Applied'},
    {name: 'CAS Issued'},
    {name: 'Visa File Submitted'},
    {name: 'Visa Approved'},
    {name: 'Visa Rejected'},
    {name: 'Visa Refund Applied'},
    {name: 'Case Approved'},
    {name: 'Case Close'}
]

export const canadaApplicationStatusSteps = [
    {name: 'New Student'},
    {name: 'Waiting To Apply'},
    {name: 'Draft Applications'},
    {name: 'Offer Applied'},
    {name: 'Offer Received'},
    {name: 'Tuition Fee Paid & TT Received'}, // change department
    {name: 'LOA/Fee Receipt Issued'}, // change department
    {name: 'Visa File Submitted'},
    {name: 'Visa Approved'},
    {name: 'Visa Rejected'},
    {name: 'Visa Refund Applied'},
    {name: 'Case Approved'},
    {name: 'Case Close'}
]

export const australiaApplicationStatusSteps = [
    {name: 'New Student'},
    {name: 'Waiting To Apply'},
    {name: 'Draft Applications'},
    {name: 'Offer Applied'},
    {name: 'Conditional Offer Received'},
    {name: 'GTE'},
    {name: 'Unconditional Offer Received'},
    {name: 'Tuition Fee Paid & TT Received'},
    {name: 'eCOE Applied'}, // change user
    {name: 'eCOE Issued'},
    {name: 'Visa File Submitted'},
    {name: 'Visa Approved'},
    {name: 'Visa Rejected'},
    {name: 'Visa Refund Applied'},
    {name: 'Case Approved'},
    {name: 'Case Close'}
]

export const australiaApplicationL1StatusSteps = [
    {name: 'New Student'},
    {name: 'Waiting To Apply'},
    // { name: 'Draft Applications' },
    {name: 'Offer Applied'},
    {name: 'Conditional Offer Received'},
    {name: 'GTE'},
    {name: 'Unconditional Offer Received'},
    {name: 'Tuition Fee Paid & TT Received'},
    {name: 'eCOE Applied'}, // change user
    {name: 'eCOE Issued'},
    {name: 'Visa File Submitted'},
    {name: 'Visa Approved'},
    {name: 'Visa Rejected'},
    {name: 'Visa Refund Applied'},
    {name: 'Case Approved'},
    {name: 'Case Close'}
]

export const usaApplicationStatusSteps = [
    {name: 'New Student'},
    {name: 'Waiting To Apply'},
    {name: 'Draft Applications'},
    {name: 'Offer Applied'},
    {name: 'Offer Received'},
    {name: '120 Applied'},
    {name: '120 Received'},
    {name: 'Visa File Submitted'},
    {name: 'Visa Approved'},
    {name: 'Visa Rejected'},
    {name: 'Visa Refund Applied'},
    {name: 'Case Approved'},
    {name: 'Case Close'}
]

export const germanyApplicationSteps = [
    {name: 'New Student'},
    {name: 'Waiting To Apply'},
    {name: 'Draft Applications'},
    {name: 'Offer Applied'},
    {name: 'Conditional Offer Received', requiredDoc: true},
    {name: 'Unconditional Offer Received', requiredDoc: true, feeRequired: true},
    {name: 'Tuition Fee Paid & TT Received'},
    {name: 'LOA/Fee Receipt Issued', requiredDoc: true},
    {name: 'Visa File Submitted'},
    {name: 'Visa Approved', requiredDoc: true},
    {name: 'Visa Rejected', requiredDoc: true},
    {name: 'Visa Refund Applied'},
    {name: 'Case Approved'},
    {name: 'Case Close'}
]

export const singaporeApplicationSteps = [
    {name: 'New Student'},
    {name: 'Waiting To Apply'},
    {name: 'Draft Applications'},
    {name: 'Offer Applied'},
    {name: 'Offer Received', requiredDoc: true},
    {name: 'Visa File Submitted'},
    {name: 'Tuition fee', requiredDoc: true},
    {name: 'Visa Approved', requiredDoc: true},
    {name: 'Visa Rejected'},
    {name: 'Visa Refund Applied'},
    {name: 'Case Approved'},
    {name: 'Case Close'}
]


export const canadaReportingStatus = [
    {name: 'Waiting To Apply', label: 'Enrolled Applications', marketing: true},
    {name: 'Offer Received', label: 'Offer Received', marketing: true},
    {name: 'Tuition Fee Paid & TT Received', label: 'Tuition Fee Paid', marketing: false},
    {name: 'LOA/Fee Receipt Issued', label: 'LOA/Fee Issued', marketing: true}, // change department
    {name: 'Visa File Submitted', label: 'Visa Applied', marketing: false},
    {name: 'Visa Approved', label: 'Visa Approved', marketing: true},
    {name: 'Visa Rejected', label: 'Visa Refused', marketing: true},
    {name: 'Visa Refund Applied', label: 'Visa Refund Applied', marketing: false}
]
export const usaReportingStatus = [
    {name: 'Waiting To Apply', label: 'Enrolled Applications', marketing: true},
    {name: 'Offer Received', label: 'Offer Received', marketing: true},
    {name: '120 Applied', label: '120 Applied', marketing: false},
    {name: '120 Received', label: '120 Received', marketing: true},
    {name: 'Visa File Submitted', label: 'Visa Applied', marketing: false},
    {name: 'Visa Approved', label: 'Visa Approved', marketing: true},
    {name: 'Visa Rejected', label: 'Visa Refused', marketing: true},
    {name: 'Visa Refund Applied', label: 'Visa Refund Applied', marketing: false}

]

export const australiaReportingStatus = [
    {name: 'Waiting To Apply', label: 'Enrolled Applications', marketing: true},
    {name: 'Conditional Offer Received', label: 'Conditional', marketing: true, onlyMarketing: true},
    {name: 'Unconditional Offer Received', label: 'Unconditional', marketing: false},
    {name: 'eCOE Applied', label: 'ECOE Applied', marketing: false},
    {name: 'eCOE Issued', label: 'ECOE Issued', marketing: true}, // change department
    {name: 'Visa File Submitted', label: 'Visa Applied', marketing: false},
    {name: 'Visa Approved', label: 'Visa Approved', marketing: true},
    {name: 'Visa Rejected', label: 'Visa Refused', marketing: true},
    {name: 'Visa Refund Applied', label: 'Visa Refund Applied', marketing: false}

]

export const ukReportingStatus = [
    {name: 'Waiting To Apply', label: 'Enrolled Applications', marketing: true},
    {name: 'Conditional Offer Received', label: 'Conditional', marketing: true, onlyMarketing: true},
    {name: 'Unconditional Offer Received', label: 'Unconditional', marketing: false},
    {name: 'CAS Applied', label: 'CAS Applied', marketing: false},
    {name: 'CAS Issued', label: 'CAS Issued', marketing: true},
    {name: 'Visa File Submitted', label: 'Visa Applied', marketing: false},
    {name: 'Visa Approved', label: 'Visa Approved', marketing: true},
    {name: 'Visa Rejected', label: 'Visa Refused', marketing: true},
    {name: 'Visa Refund Applied', label: 'Visa Refund Applied', marketing: false}

]
export const australia_onshore_Steps = [
    {name: 'Offer Received'},
    {name: 'Fee Payment / Receipt'},
    {name: 'ECOE Uploaded'},
    {name: 'Commission'},
]

export const canada_onshore_Steps = [
    // {name: 'New Student'},
    {name: 'Waiting To Apply'},
    {name: 'Draft Applications'},
    {name: 'Offer Applied'},
    {name: 'Offer Received'},
    {name: 'Tuition Fee Paid & TT Received'},
    {name: 'Case Close'}
]

export const fcmtStatusList = [
    {name: 'Offer Received'},
    {name: 'Rqst LOA'},
    {name: 'LOA Generated'},
    {name: 'File pending for submission'},
    {name: 'File Submitted'},
    {name: 'Visa Approved'},
    {name: 'Visa Rejected'}
]
export const lesterStatusList = [
    {name: 'Offer Received'},
    {name: 'Rqst LOA'},
    {name: 'LOA Generated'},
    {name: 'CAQ Applied'},
    {name: 'File pending for submission'},
    {name: 'File Submitted'},
    {name: 'Visa Approved'},
    {name: 'Visa Rejected'}
]
export const oxfordStatusList = [
    {name: 'Offer Received'},
    {name: 'Copy of Draft'},
    {name: 'Upload Signed Visa Draft'},
    {name: 'File pending for submission'},
    {name: 'File Submitted'},
    {name: 'Visa Approved'},
    {name: 'Visa Rejected'}
]
