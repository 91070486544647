import { apiUrl } from '../../../settings'

export const expenseUrl = () => {
  return apiUrl + '/expense'
}
export const calcDistanceUrl = () => {
  return apiUrl + '/api/calcDistance'
}
export const updateExpenseWithdrawalUrl = () => {
  return apiUrl + '/updateExpenseWithdrawal'
}
export const updateExpenseUrl = () => {
  return apiUrl + '/updateExpenseStatus'
}
export const expenseWithdrawalUrl = () => {
  return apiUrl + '/expenseWithdrawal'
}

export const expenseAmountRecordUrl = () => {
  return apiUrl + '/expenseAmountRecord'
}
