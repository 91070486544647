import React, { useEffect, useRef, useState } from 'react'
import {
  Drawer,
  Select,
  Card,
  Button,
  Table,
  Tooltip,
  Avatar,
  Icon,
  Modal,
  Popconfirm,
  Row,
  Col,
  Input,
  DatePicker, notification, Form, Tag
} from 'antd'
import { connect, useDispatch, useSelector } from 'react-redux'
import { getSelectedCountryApplicationsApi, exportApplicationsList } from '../actions/chooseStudent'
import {
  displayDate,
  statusColors,
  DefaultTablePagination,
  InputBox,
  getStatusDate, filterOption, FilterOption, newFormatDisplayDate, defaultLogoList, longDisplayDate, fcmtUniversity
} from '../../../components/_utils/appUtils'
import { getPushPathWrapper, getUrlPushWrapper } from '../../../routes'
import { TableComp } from 'sz-react-utils-lite'
import lodash from 'lodash'
import { AllowComponentRightsWise, CheckManagerWiseRight } from '../../WebComponent/allowComponentRightsWise'
import TransferApplicationDrawer from '../views/transferApplication'
import ApplyCourse from '../../student/views/applyForApplicationDrawer'
import {CheckUserRight, ShowExportOption} from '../../dashboard/views/DashboardUserWise'
import {
  listAllStudents,
  withdrawalStudent,
  applicationCloseDirect,
  reCalculateCommission, updateApplicationTuitionFees
} from '../../student/actions/student'
import moment from 'moment'
import { userTypeWiseFxn } from '../../users/actions/user'
import StudentOtherApp from './StudentOtherApplication'
import AddAppComment from './addComment'
import { listAllUniversities } from '../../university/actions/university'
import { apiUrl } from '../../../settings'
import { listAllUsers } from '../../users/actions/user'
import ReActivateApplication from '../drawers/reActivateApplicationDrawer'
import ChangeUniversityComponent from '../drawers/changeUniversity'
import { showEnrollmentAction } from '../../../components/_utils/appUtils'
import Enrollment from '../drawers/enrollment'
import { CheckMyRights } from '../../WebComponent/CheckUserRights'
import { listAllCountries } from '../../countries/actions/countries'
import AddNote from '../drawers/createNoteComponent'
import LoaRequest from '../drawers/loaRequest'
import FcmtStatusComponent from '../drawers/fcmtStatus'
import ApprovedLoaRequest from '../drawers/approvedLoaRequest'
import {
  RequestLoaApproved,
  ReCreateFcmtOfferLetter,
  ReCreateFcmtLoaLetter,
  ReCreateFcmtPaymentReceipt,
  fcmtSendMailFxn
} from '../actions/chooseStudent'

const { Option } = Select
const initialState = {
  applicationList: [],
  applicationObj: {}
}
const commissionObj = {
  commissionAmount: 0,
  tuitionFee: 0,
  studentId: '',
  applicationId: ''
}
const RenderComment = (props) => {
  let { item } = props
  let [hideV, setHideV] = useState(true)
  return (
    item && <div>
      {item.addedByUserId && item.addedByUserId.name ?
        <div className={'alignRight colorPrimary font12'}>
          (Added By : {item.addedByUserId.name} )
        </div> : null}
      <div className={hideV ? 'appCommentDotsReplace' : ''}>
        {item && item.text ? item.text : ''}
      </div>
      {item && item.text.length && item.text.length > 50 ? <div className={'alignRight'}>
        {hideV ? <a onClick={() => {
          setHideV(false)
        }}>Show More</a> : <a onClick={() => {
          setHideV(true)
        }}>Show Less</a>}
      </div> : ''}

    </div>
  )
}
const OfferActionComponent = (props) => {
  let { file, reCreateOfferLetter } = props
  return (
    <div>
      {file && file.path ?
        <Tooltip title={'Download Offer Letter'}>
          <a download={file.fileName} href={file.path}>
            <img src={'/assets/icons/cloud-computing-download.png'}/>
          </a></Tooltip> : ''}
      <br/>
      <Popconfirm title={'Are your sure, you want to Re-Create Offer Letter?'}
                  onConfirm={() => reCreateOfferLetter()}
                  okText="Yes" cancelText="No">
        <a className={'bs-link bs-xs'}>Re-Create Offer Letter</a>
      </Popconfirm>
    </div>
  )
}
const LoaActionComponent = (props) => {
  let { record, val, events } = props
  return (
    <div>
      <div>
        {record.applications && record.applications.status == 'Offer Received' ?
          <Tooltip title={'Rqst LOA'}>
            <a onClick={() => events.openLoaDrawer(record, false)}>
              <img src={'/assets/icons/cloud-computing-upload.png'}/>
            </a></Tooltip> : null}

        {val ?
          <Tooltip title={val}><a>
            <img src={`${val == 'Pending' ? '/assets/icons/pendingCheck.png' : '/assets/icons/doneCheck.png'}`}
                 className={'sm'}/>
          </a></Tooltip> : null}
      </div>

      {record.applications && record.applications.status !== 'Offer Received' ?
        <div className={'mt10'}>
          <a className={'bs-link bs-xs'} onClick={() => events.openLoaDrawer(record, true)}>Re-Upload LOA</a>
        </div>
        : ''}

      {val == 'Pending' ?
        <AllowComponentRightsWise rightUserType={['admin', 'branchManager', 'branchUser']}>
          <button className={`bs_btn bs-default mt10 bs-xs`} onClick={() => events.approveRequest(record)}>
            Approve
          </button>
        </AllowComponentRightsWise> : null}

    </div>
  )
}
const ReceiptActionComponent = (props) => {

}

const ViewStudentApplication = (props) => {
  let { onClose, studentObj = {}, reloadTable } = props

  const [state, setState] = useState(initialState)
  const [transferAppVisible, setTransferAppVisible] = useState(false)
  const [studentData, setStudentData] = useState({})
  const [visibleAddCourseDrawer, setVisibleAddCourseDrawer] = useState(false)
  const [name, setName] = useState('')
  const [dateOfBirth, setDateOfBirth] = useState('')
  const [allAgent, setAllAgent] = useState([])
  const [agentId, setAgentId] = useState('')
  const [appUniversityId, setAppUniversityId] = useState('')
  const [visibleOtherAppDrawer, setVisibleOtherAppDrawer] = useState(false)
  const [visibleAddCommentDrawer, setVisibleAddCommentDrawer] = useState(false)
  const [totalApplication, setTotalApplication] = useState(0)
  const [visibleFeesModal, setVisibleFeesModal] = useState(false)
  const [feesObj, setFeesObj] = useState(commissionObj)
  const [currentStatus, setCurrentStatus] = useState('')
  const [status, setStatus] = useState('')
  let { applicationObj } = state
  let [tableSearch, setTableSearch] = useState(false)
  let [filters, setFilters] = useState({})
  let [visibleLoaReq, setVisibleLoaReq] = useState(false)
  let [visibleFcmtStatus, setVisibleFcmtStatus] = useState(false)
  let [visibleApprovalDrawer, setVisibleApprovalDrawer] = useState(false)
  let [isEditAble, setIsEditAble] = useState(false)


  let [selectedApplication, setSelectedApplication] = useState({
    visible: false,
    studentId: '',
    application: '',
    studentData: {}
  })
  let [enrollmentObj, setEnrollmentObj] = useState({
    visible: false,
    studentId: '',
    application: '',
    studentData: {}
  })
  let [changeUniversity, setChangeUniversity] = useState({
    visible: false,
    studentId: '',
    application: '',
    studentData: {}
  })
  const dispatch = useDispatch()
  let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
  const tableAppRef = useRef()

  let apiRequest = (params) => {
    return new Promise(async (resolve) => {
      let resp = await getParamsForApplicationList()
      params = { ...params, ...resp }
      if (!params.appUniversityId) {
        params.appUniversityId = fcmtUniversity
      }

      if (!params.results) {
        params.results = 50
        params.count = 50
      }


      if (tableSearch) {
        params.page = 1
      }
      params.type = 'all'
      // params.fcmtCourse = true
      try {
        setFilters(params)
        let resp = await dispatch(getSelectedCountryApplicationsApi({ ...params }))
        setTotalApplication(resp.total)
        setTableSearch(false)
        resolve(resp)
      } catch (e) {
      }

    })
  }
  let exportExcel = async () => {
    let params = {}
    let resp = await getParamsForApplicationList()
    params = { ...params, ...resp }
    if (name) {
      params.name = name
    }
    if (dateOfBirth) {
      params.dateOfBirth = moment(dateOfBirth)
    }

    params.count = totalApplication
    let { success, filePath, message } = await dispatch(exportApplicationsList(params))
    if (success) {
      notification.success({ message: message })
      // window.open(`${apiUrl}${filePath}`, 'Download')
    } else {
      notification.error({ message: message })
    }
  }


  useEffect(() => {
    loadAgentData()
    setFieldByParams()
  }, [])

  const setFieldByParams = async () => {
    let resp = await getParamsForApplicationList()
    // setCurrentStatus(resp.status)

    if (resp.appUniversityId) {
      setAppUniversityId(resp.appUniversityId)
    }
  }

  const loadAgentData = async () => {
    let params = {
      results: 15000,
      userType: 'agent',
      sortField: 'companyName',
      sortOrder: 'ascend',
      select: ['name', 'companyName']
    }
    let {data} = await dispatch(listAllUsers(params))
    if (data && data.length) {
      setAllAgent(data)
    }
  }


  const events = {

    reloadFxn: () => {
      tableAppRef.current.reload()
    },
    showTransferApp: () => {
      setTransferAppVisible(true)
    },
    hideTransferApp: () => {
      setTransferAppVisible(false)
      tableAppRef.current.reload()
    },
    showAddCourseDrawer: () => {
      setVisibleAddCourseDrawer(true)
    },
    hideAddCourseDrawer: () => {
      setVisibleAddCourseDrawer(false)
      tableAppRef.current.reload()
    },
    refreshApplication: () => {
      tableAppRef.current.reload()
    },

    closeDirectApp: async (record) => {
      let obj = {
        studentId: record._id,
        applicationId: record.applications._id
      }
      let resp = await dispatch(applicationCloseDirect(obj))
      tableAppRef.current.reload()
    },

    enterName: (value) => {
      setName(value)

    },
    openOtherAppDrawer: (value) => {
      setState({
        ...state,
        applicationObj: value
      })
      setVisibleOtherAppDrawer(true)
    },
    closeOtherAppDrawer: () => {
      setState({
        ...state,
        applicationObj: {}
      })
      setVisibleOtherAppDrawer(false)
    },
    openAddCommentDrawer: (value) => {
      setState({
        ...state,
        applicationObj: value
      })
      setVisibleAddCommentDrawer(true)
    },
    closeAddCommentDrawer: () => {
      setState({
        ...state,
        applicationObj: {}
      })
      setVisibleAddCommentDrawer(false)
    },
    hideVisibleFeesModal: () => {
      setVisibleFeesModal(false)
    },
    setCommissionLocal: (data) => {
      setFeesObj({
        ...feesObj,
        ...data
      })
    },
    reCalculateCommissionFxn: async () => {
      let { commissionAmount } = await dispatch(reCalculateCommission(feesObj))
      if (commissionAmount) {
        events.setCommissionLocal({ commissionAmount })
      }

    },
    updateApplicationTuitionFeesFxn: async () => {
      let { success, message } = await dispatch(updateApplicationTuitionFees(feesObj))
      if (success) {
        setFeesObj(commissionObj)
        setVisibleFeesModal(false)
        events.reloadFxn()
      }
    },
    sendFcmtMail: async (record) => {
      let obj = {
        studentId: record._id,
        applicationId: record.applications._id
      }
      let { success, message } = await dispatch(fcmtSendMailFxn(obj))
      if (success) {
        events.reloadFxn()
      }
    },
    openLoaDrawer: (record, editVal) => {
      setStudentData(record)
      setVisibleLoaReq(true)
      setIsEditAble(editVal)
    },
    closeLoaDrawer: () => {
      setStudentData({})
      setVisibleLoaReq(false)
    },
    approveRequest: async (record) => {
      setStudentData(record)
      setVisibleApprovalDrawer(true)
    },
    closeApproveRequest: async (record) => {
      setStudentData({})
      setVisibleApprovalDrawer(false)
    },
    openFcmtStatusDrawer: (record) => {
      setStudentData(record)
      setVisibleFcmtStatus(true)
    },
    closeFcmtStatusDrawer: () => {
      setStudentData({})
      setVisibleFcmtStatus(false)
    },
    reCreateOfferLetter: async (record) => {
      let obj = {
        studentId: record._id,
        applicationId: record.applications._id
      }
      let resp = await dispatch(ReCreateFcmtOfferLetter(obj))
      if (resp && resp.success) {
        tableAppRef.current.reload()
      }
    },
    reCreateLoaLetter: async (record) => {
      let obj = {
        studentId: record._id,
        applicationId: record.applications._id
      }
      let resp = await dispatch(ReCreateFcmtLoaLetter(obj))
      if (resp && resp.success) {
        tableAppRef.current.reload()
      }
    },
    reCreatePaymentReceipt: async (record) => {
      let obj = {
        studentId: record._id,
        applicationId: record.applications._id
      }
      let resp = await dispatch(ReCreateFcmtPaymentReceipt(obj))
      if (resp && resp.success) {
        tableAppRef.current.reload()
      }
    }
  }


  const reActivateApp = (record) => {
    setSelectedApplication({
      visible: true,
      studentId: record._id,
      studentData: record,
      application: record.applications
    })
  }
  const onCloseReActivate = () => {
    setSelectedApplication({
      visible: false,
      studentId: '',
      studentData: {},
      application: {}
    })
    tableAppRef.current.reload()
  }

  const enrollmentApp = (record) => {
    setEnrollmentObj({
      visible: true,
      studentId: record._id,
      studentData: record,
      application: record.applications
    })
  }
  const onCloseEnrollmentApp = () => {
    setEnrollmentObj({
      visible: false,
      studentId: '',
      studentData: {},
      application: {}
    })
    tableAppRef.current.reload()
  }

  const changeUniversityFxn = (record) => {
    setChangeUniversity({
      visible: true,
      studentId: record._id,
      studentData: record,
      application: record.applications
    })
  }
  const onCloseChangeUniversityFxn = () => {
    setChangeUniversity({
      visible: false,
      studentId: '',
      studentData: {},
      application: {}
    })
    tableAppRef.current.reload()
  }

  const enrollmentBtn = (record) => {
      /*let application = record.applications
      return (
        !application.enrollment ? showEnrollmentAction(application.statusList) ?
          <button className="btn" onClick={() => enrollmentApp(record)}>
            <img src={'/assets/enrollment.png'}/>
          </button> : null : null
      )*/
      return null
  }

  const getParamsForApplicationList = () => {
    return new Promise((resolve) => {
      let searchParams = new URLSearchParams(window.location.search)
      let appUniversityId = searchParams.get('appUniversityId')
      let name = searchParams.get('name')
      let obj = {}

      if (appUniversityId) {
        obj.appUniversityId = appUniversityId
      }
      if (name) {
        obj.name = name
      }

      resolve(obj)
    })
  }

  const searchFxn = async () => {
    let resp = await getParamsForApplicationList()
    let obj = {}

    if (name) {
      obj.name = name
    }
    if (appUniversityId) {
      obj.appUniversityId = appUniversityId
    }
    dispatch(
      getUrlPushWrapper('application.fcmtApplicationsList', {
        ...obj
      }))
    setTableSearch(true)
    setTimeout(() => {
      if (tableAppRef && tableAppRef.current) {
        tableAppRef.current.reload()
      }
    }, 300)
  }
  const clearFxn = async () => {
    let resp = await getParamsForApplicationList()
    let obj = {}
    setAppUniversityId('')
    setName('')
    dispatch(
      getUrlPushWrapper('application.fcmtApplicationsList', {
        ...obj
      }))
    setTableSearch(true)
    setTimeout(() => {
      tableAppRef.current.reload()
    }, 200)
  }


  const columns = [

    {
      title: 'App#',
      key: 'applications.id',
      dataIndex: 'applications.id',
      width: 60,
      render: (item) => {
        return (
          <div style={{ width: 60 }}>
            {item}
          </div>
        )
      }
    },
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      width: 120,
      render: (item, record) => {
        return (
          <div style={{ width: 120 }}>
            <Tooltip title={'Edit Student'}>
              <a className={'linkAA'}
                 onClick={() => dispatch(
                   getPushPathWrapper('student.editStudent', { id: record._id }))
                 }>
                {item} <Icon type={'edit'}/>
              </a>
            </Tooltip>
            <br/>
            {newFormatDisplayDate(record.dateOfBirth)}
          </div>
        )
      }
    },
    {
      title: 'Program',
      key: 'applications.courseName',
      dataIndex: 'applications.courseName',
      width: 150,
      render: (item) => {
        return (
          <div style={{ width: 150 }}>
            {item}
          </div>
        )
      }
    },
    {
      title: 'Intake',
      key: 'applications.intake',
      dataIndex: 'applications.intake',
      width: 80,
      render: (item, record) => {
        return (
          <div style={{ width: 80 }}>
            {item && item.month && item.year ? `${item.month}, ${item.year}` : ''}
          </div>
        )
      }
    },
    {
      title: 'Campus',
      key: 'applications.campusId',
      dataIndex: 'applications.campusId',
      width: 80,
      render: (item, record) => {
        return (
          <div style={{ width: 80 }}>
            {item && item.campusName ? item.campusName : ''}
          </div>
        )
      }
    },

    {
      title: 'Current User',
      key: 'applications.assignedUserId',
      dataIndex: 'applications.assignedUserId',
      width: 100,
      render: (val, record) => {
        let { agentId, statusList, status } = record.applications
        return (
          <div style={{ width: 100 }}>
            {val && val.name ? val.name : ''}<br />
            <div>Agent : {agentId && agentId.companyName ? agentId.companyName : ''}</div>
            <div>{longDisplayDate(statusList.date)}</div>
          </div>
        )
      }
    },
    {
      title: 'Conditional Offer Letter',
      key: 'applications.offerReceiveLetter',
      dataIndex: 'applications.offerReceiveLetter',
      width: 100,
      render: (val, record) => {
        return (
          <div style={{ width: 100 }} className={'fcmtDiv'}>
            {/*{val && val.path ?
              <Tooltip title={'Download Offer Letter'}>
                <a download={val.fileName} href={val.path}>
                  <img src={'/assets/icons/cloud-computing-download.png'}/>
                </a></Tooltip> : ''}
            <br/>
            <Popconfirm title={'Are your sure, you want to Re-Create Offer Letter?'}
                        onConfirm={() => events.reCreateOfferLetter(record)}
                        okText="Yes" cancelText="No">
              <a className={'bs-link bs-xs'}>Re-Create Offer Letter</a>
            </Popconfirm>*/}
            <OfferActionComponent file={val} reCreateOfferLetter={() => events.reCreateOfferLetter(record)}/>
          </div>
        )
      }
    },
    {
      title: 'Rqst LOA',
      key: 'applications.loaRequested',
      dataIndex: 'applications.loaRequested',
      width: 100,
      render: (val, record) => {
        return (
          <div style={{ width: 100 }} className={'fcmtDiv'}>
            <div>
              {record.applications && record.applications.status == 'Offer Received' ?
                <Tooltip title={'Rqst LOA'}>
                  <a onClick={() => events.openLoaDrawer(record, false)}>
                    <img src={'/assets/icons/cloud-computing-upload.png'}/>
                  </a></Tooltip> : null}

              {val ?
                <Tooltip title={val}><a>
                  <img src={`${val == 'Pending' ? '/assets/icons/pendingCheck.png' : '/assets/icons/doneCheck.png'}`}
                       className={'sm'}/>
                </a></Tooltip> : null}
            </div>

            {record.applications && record.applications.status !== 'Offer Received' ?
              <div className={'mt10'}>
                <a className={'bs-link bs-xs'} onClick={() => events.openLoaDrawer(record, true)}>Re-Upload LOA</a>
              </div>
              : ''}

            {val == 'Pending' ?
              <AllowComponentRightsWise rightUserType={['admin', 'branchManager', 'branchUser']}>
                <button className={`bs_btn bs-default mt10 bs-xs`} onClick={() => events.approveRequest(record)}>
                  Approve
                </button>
              </AllowComponentRightsWise> : null}


          </div>
        )
      }
    },
    {
      title: 'Download LOA',
      key: 'applications.loaLetter',
      dataIndex: 'applications.loaLetter',
      width: 80,
      render: (val, record) => {
        return (
          <div style={{ width: 80 }} className={'fcmtDiv'}>
            <div style={{ width: 80 }} className={'fcmtDiv'}>
              {val && val.path ?
                <div>
                  <Tooltip title={'Download LOA'}>
                    <a download={val.fileName} href={val.path}>
                      <img src={'/assets/icons/cloud-computing-download.png'}/>
                    </a></Tooltip>
                  <br/>
                  <Popconfirm title={'Are your sure, you want to Re-Create LOA?'}
                              onConfirm={() => events.reCreateLoaLetter(record)}
                              okText="Yes" cancelText="No">
                    <a className={'bs-link bs-xs'}>Re-Create LOA</a>
                  </Popconfirm>
                  {record.applications && record.applications.mailSent ? '' :
                    <div>
                      <AllowComponentRightsWise rightUserType={['admin', 'branchManager', 'branchUser']}>
                        <Popconfirm title={'Are your sure, you want to Send Mail?'}
                                    onConfirm={() => events.sendFcmtMail(record)}
                                    okText="Yes" cancelText="No">
                          <button className={`bs_btn bs-default mt10 bs-xs`}>
                            Send Mail
                          </button>
                        </Popconfirm>
                      </AllowComponentRightsWise>

                    </div>
                  }
                  <br/>
                  {record.applications && record.applications.mailDate ? `Mail Date : ${newFormatDisplayDate(record.applications.mailDate)}` : null}
                </div> : ''}
            </div>

          </div>
        )
      }
    },

    {
      title: 'Receipt',
      key: 'applications.receipt',
      dataIndex: 'applications.receipt',
      width: 80,
      render: (val, record) => {
        return (
          <div style={{ width: 80 }} className={'fcmtDiv'}>
            {val && val.path ?
              <> <Tooltip title={'Download Receipt'}>
                <a download={val.fileName} href={val.path}>
                  <img src={'/assets/icons/cloud-computing-download.png'}/>
                </a></Tooltip>

                <div className={'mt5'}>
                  <Popconfirm title={'Are your sure, you want to Re-Create Receipt?'}
                              onConfirm={() => events.reCreatePaymentReceipt(record)}
                              okText="Yes" cancelText="No">
                    <a className={'bs-link bs-xs'}>Re-Create Receipt</a>
                  </Popconfirm>
                </div>

              </> : ''}
          </div>
        )
      }
    },
    {
      title: 'Status',
      key: 'applications.status',
      dataIndex: 'applications.status',
      width: 120,
      render: (item, record) => {
        let { applications } = record
        return (
          <div className={'fcmtDiv'} style={{ width: 120 }}>
            {item.includes('Visa') ? <>{item}<br/></> : null}
            <AllowComponentRightsWise rightUserType={['admin', 'branchManager', 'branchUser']}>
              {applications && applications.loaRequested && applications.loaRequested == 'Approved' ?
                <Tooltip title={'Update Status'}>
                  <a onClick={() => events.openFcmtStatusDrawer(record)}>
                    <img src={'/assets/icons/updated.png'} className={'sm'} />
                  </a>
                </Tooltip>
                : null}
            </AllowComponentRightsWise>
          </div>
        )
      }
    },

    {
      title: 'Latest Note',
      key: 'applications.latestComment',
      dataIndex: 'applications.latestComment',
      width: 150,
      render: (val) => {
        return (
          <div style={{ width: 150 }}>
            {val && val.text ? <RenderComment item={val} /> : null}
          </div>
        )
      }
    }
    ,
    {
      title: 'Action',
      key: 'action',
      dataIndex: 'action',
      width: 100,
      render: (val, record) => {
        return (
          <div className={'actionBtnGroup'}>
            {!record.applications.directClosed &&
            <AllowComponentRightsWise rightUserType={['agent', 'subAgent']}>
              <Tooltip title={'View Application'}>
                <a className="btn"
                   href={`/application/view?appId=${record.applications._id}&studentId=${record._id}`}
                ><img src="../dist/img/view.svg" alt="" className={'eyeBtn'}/></a>
              </Tooltip>{' '}
            </AllowComponentRightsWise>}


            <div>
              <AllowComponentRightsWise rightUserType={['admin', 'branchUser', 'branchManager']}>
                <Tooltip title={'View Application'}>
                  <a className="btn"
                     href={`/student/application?studentId=${record._id}&appId=${record.applications._id}`}
                  ><img src="../dist/img/view.svg" alt=""/></a>
                </Tooltip>

                <CheckManagerWiseRight>
                  {record.applications.status !== 'Case Close' &&
                  <Tooltip title={'Case Closed'}>
                    <Popconfirm title={'Are your sure, you want to Close Case?'}
                                onConfirm={() => {
                                  events.closeDirectApp(record)
                                }}
                                okText='Yes' cancelText='No'>
                      <button className='btn'><img src='../dist/img/cross.svg' alt='' className={'crossBtn'} /></button>
                    </Popconfirm>
                  </Tooltip>}
                </CheckManagerWiseRight>


                <Tooltip title={'View Other Application'}>
                  <button className="btn" onClick={() => {
                    events.openOtherAppDrawer(record)
                  }}><Icon type={'ordered-list'}/></button>

                </Tooltip>

                <Tooltip title={'Add Note'}>
                  <button className="btn" onClick={() => {
                    events.openAddCommentDrawer(record)
                  }}><Icon type={'plus'}/></button>
                </Tooltip>
              </AllowComponentRightsWise>
            </div>

            {/* <AllowComponentRightsWise rightUserType={['admin', 'branchUser', 'branchManager']}>
              <Tooltip title={'Transfer'}>
                <button className="btn" onClick={() => {
                  setStudentData(record)
                  events.showTransferApp()
                }}>
                  <img src={'../dist/icons/forwardArrow.png'}/>

                </button>
              </Tooltip>{' '}
            </AllowComponentRightsWise>*/}

            <AllowComponentRightsWise rightUserType={['admin', 'branchManager']}>
              {
                record.applications.status == 'Case Close' ?
                  <Tooltip title={'Re-Open Case'}>
                    <button className="btn" onClick={() => reActivateApp(record)}>
                      <img src={'/assets/power-button.png'}/>
                    </button>
                  </Tooltip> : null
              }

              {/* <Tooltip title={'Re-Create Offer Letter'}>
                <Popconfirm title={'Are your sure, you want to Re-Create Offer Letter?'}
                            onConfirm={() => events.reCreateOfferLetter(record)}
                            okText="Yes" cancelText="No">
                  <button className="btn">
                    <Icon type={'redo'}/>
                  </button>
                </Popconfirm>
              </Tooltip>*/}


            </AllowComponentRightsWise>

            <AllowComponentRightsWise rightUserType={['admin']}>
              {enrollmentBtn(record)}
            </AllowComponentRightsWise>


          </div>
        )
      }
    }

  ]

  const resp = useSelector(state => {
      let { loadNoteRedux } = state.chooseApplication
      if (loadNoteRedux) {
        if (tableAppRef && tableAppRef.current) {
          tableAppRef.current.reload()
        }
        dispatch({ type: 'END_LOAD_NOTE' })
      }
    }
  )

  const loadColumns = () => {
    let columnArr = []
    lodash.each(columns, (item) => {
      if (item.hidden == undefined) {
        columnArr.push(item)
      } else {
        if (!item.hidden) {
          columnArr.push(item)
        }
      }
    })
    return columnArr
  }

  const extra = (
    <Row gutter={16}>
      <Row gutter={12}>
        <Col md={6} sm={6} xs={8} lg={6}>
          <div className="search-box-table round">
            <input className="form-control form-control" type="search"
                   placeholder="Search by name..." value={name} onChange={(e) => {
              events.enterName(e.target.value)
            }}/>
            <img src="/dist/img/search.png" alt=""/>
          </div>
        </Col>

        <Col md={8} sm={8} xs={12} lg={6}>
          <Button onClick={() => searchFxn()} className={'roundBtn'}>Search</Button>
          <Button onClick={() => clearFxn()} className={'roundBtn'}>
            Clear
          </Button>


        </Col>


      </Row>

    </Row>
  )

  return (
    <React.Fragment>
      <div>
        <div className="row mt-4">
          <div className="col-lg-12">
            <div className="card">
              <div className="table-head d-flex align-items-center all-student-search" style={{ height: 60 }}>
                <Button onClick={() => dispatch(getUrlPushWrapper('dashboard'))}
                        className={'roundGoBackBtn'} icon={'arrow-left'}>
                  Back
                </Button>
                <h5>FCMT All Application </h5>
                <div className="search-box-table" style={{backgroundColor: 'transparent'}}>
                  {currentStatus} (Total - {totalApplication})
                </div>
                <div className="sort-box-table mark-btn">
                  <ShowExportOption user={user} rightUserType={['admin', 'branchManager']}>
                    <span className={'sort-box-table-right'}>
                      <Tooltip title={'Export Excel'}>
                        {' '} <Button onClick={() => exportExcel()} className={'roundBtn'}>Export Excel</Button>
                      </Tooltip>
                    </span>
                  </ShowExportOption>
                </div>
              </div>
              <div className="card-body table-responsive">
                {extra}
                <TableComp columns={loadColumns()}
                           rowKey={(item, index) => {
                             return index
                           }}
                           apiRequest={apiRequest}
                           pagination={{
                             showSizeChanger: true,
                             pageSizeOptions: ['50', '100', '200', '500'],
                             defaultPageSize: 50,
                             current: filters.page ? filters.page : 1
                           }}
                           ref={tableAppRef}
                />
              </div>
            </div>
          </div>
        </div>


      </div>
      {transferAppVisible ?
        <TransferApplicationDrawer visible={transferAppVisible}
                                   closeTransferDrawer={() => events.hideTransferApp()}
                                   studentId={studentData._id}
                                   studentData={studentData}
                                   application={studentData.applications}
        /> : null}

      {visibleAddCourseDrawer ? <ApplyCourse
        visible={visibleAddCourseDrawer}
        onClose={() => events.hideAddCourseDrawer()}
        refreshApplication={() => events.refreshApplication()}
        studentId={studentObj._id}/> : null}


      {visibleOtherAppDrawer ?
        <StudentOtherApp visible={visibleOtherAppDrawer}
                         applicationObj={state.applicationObj}
                         onStudentOtherAppClose={() => events.closeOtherAppDrawer()}
                         reloadFxn={events.reloadFxn}
        /> : null}


      {visibleAddCommentDrawer && applicationObj && applicationObj._id ?
        <AddNote visible={visibleAddCommentDrawer} studentData={applicationObj}
                 addedType={'direct'}
                 onClose={() => events.closeAddCommentDrawer()}
                 applicationId={applicationObj.applications._id}/> : null}


      {selectedApplication.visible ?
        <ReActivateApplication {...selectedApplication} onClose={onCloseReActivate}/> : null}

      {enrollmentObj.visible ?
        <Enrollment {...enrollmentObj} onClose={onCloseEnrollmentApp}/> : null}

      {visibleLoaReq && studentData.applications && studentData.applications._id ?
        <LoaRequest onClose={events.closeLoaDrawer}
                    reloadTable={events.reloadFxn}
                    editAble={isEditAble}
                    applicationId={studentData.applications._id}
                    studentId={studentData._id}
                    visible={visibleLoaReq}/> : null}

      {visibleFcmtStatus && studentData.applications && studentData.applications._id ?
        <FcmtStatusComponent onClose={events.closeFcmtStatusDrawer}
                             reloadTable={events.reloadFxn}
                             applicationId={studentData.applications._id}
                             studentId={studentData._id}
                             visible={visibleFcmtStatus}/> : null}

      {visibleApprovalDrawer && studentData.applications && studentData.applications._id ?
        <ApprovedLoaRequest onClose={events.closeApproveRequest}
                            reloadTable={events.reloadFxn}
                            applicationId={studentData.applications._id}
                            studentId={studentData._id}
                            visible={visibleApprovalDrawer}/> : null}


    </React.Fragment>
  )
}
const mapStateToProps = ({ global, router, chooseApplication }) => ({})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
const WrappedComponent = Form.create()(ViewStudentApplication)
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedComponent)
