import {
    Drawer,
    notification
} from 'antd'
import React, {useEffect, useRef, useState} from 'react'
import {useDispatch} from 'react-redux'
import {TableComp} from 'sz-react-utils-lite'
import {
    eventListExport
} from '../../event/action/event'
import {singleUserEnquiryFxn} from '../action'
import Status from './status'

const SingleStudentEnquiryList = props => {
    let {visible, onClose, studentId} = props
    const [filter, setFIlter] = useState({})
    const [total, setTotal] = useState(0)
    const [visibleStatus, setVisible] = useState(false)

    const [approved, setApproved] = useState(false)
    let user = localStorage.getItem('user')
        ? JSON.parse(localStorage.getItem('user'))
        : null
    const tableRef = useRef()
    const dispatch = useDispatch()
    const apiRequest = params => {
        return new Promise(async resolve => {
            params.sortField = 'countryName'
            params.sortOrder = 'ascend'
            let resp = await dispatch(
                singleUserEnquiryFxn({
                    ...params,
                    userId: studentId ? studentId : null,
                    regExFilters: [
                        'name',
                        'enrollmentBasic',
                        'enrollmentProcedure',
                        'fundSponsors'
                    ]
                })
            )
            setFIlter(params)
            setTotal(resp.total)
            resolve(resp)
        })
    }
    const openStatus = id => {
        setVisible(true)
    }
    const onCloseStatus = () => {
        setVisible(false)
    }
    //
    const events = {
        reloadTable: () => {
            if (tableRef && tableRef.current) {
                tableRef.current.reload()
            }
        },
        searchData: async () => {
        }
    }

    const columns = [
        // {
        //   title: 'Name',
        //   dataIndex: 'name',
        //   key: 'name',
        //   searchTextName: 'name',
        //   render: (val, record) => {
        //     return (
        //       <p>
        //         {record.title} {record.firstName} {record.lastName}
        //       </p>
        //     )
        //   }
        // },

        // {
        //   title: 'Nationality',
        //   dataIndex: 'nationality',
        //   key: 'nationality',
        //   searchTextName: 'nationality'
        // },
        // {
        //   title: 'Email',
        //   dataIndex: 'email',
        //   key: 'email'
        // },

        // {
        //   title: 'Phone Number',
        //   dataIndex: 'phoneNumber',
        //   key: 'phoneNumber',
        //   render: (val, record) => {
        //     return (
        //       <p>
        //         {record.dailCode} {record.phoneNumber}
        //       </p>
        //     )
        //   }
        // },

        // {
        //   title: 'University',
        //   dataIndex: 'university',
        //   key: 'university',
        //   // filters: statusFilter.map(x => ({value: x.value, text: x.name})),
        //   render: item => {
        //     return item
        //   }
        // },
        {
            title: 'Property',
            dataIndex: 'property',
            key: 'property',
            render: (item, record) => {
                return <>{item.address}</>
            }
        },
        {
            title: 'Room Details',
            dataIndex: 'roomDetails',
            key: 'roomDetails',
            render: (item, record) => {
                let {property} = record
                let {room} = property
                return (
                    <>
                        Room : {room.room_name}
                        <br/>
                        Start : {item.start_date}
                        <br/>
                        End : {item.end_date}
                        <br/>
                        Price : {item.price}
                        <br/>
                    </>
                )
            }
        },

        {
            title: 'Additional Message',
            dataIndex: 'additionalMessage',
            key: 'additionalMessage',
            // filters: statusFilter.map(x => ({value: x.value, text: x.name})),
            render: item => {
                return item
            }
        },
        {
            title: 'Offer Code',
            dataIndex: 'offerCode',
            key: 'offerCode',
            // filters: statusFilter.map(x => ({value: x.value, text: x.name})),
            render: item => {
                return item
            }
        },
        {
            title: 'Property',
            dataIndex: 'property',
            key: 'property',
            render: (item, record) => {
                return <>{item.address}</>
            }
        },
        // {
        //   title: 'Communication Methods',
        //   dataIndex: 'communicationMethod',
        //   key: 'communicationMethod',
        //   render: (item, record) => {
        //     return <>{item.address}</>
        //   }
        // },
        // {
        //   title: 'How Did You Find Us?',
        //   dataIndex: 'findUs',
        //   key: 'findUs',
        //   render: (item, record) => {
        //     return <>{item.address}</>
        //   }
        // },

        {
            title: 'Status',
            key: 'status',
            dataIndex: 'status',
            width: 150,
            render: (val, record) => {
                return (
                    <React.Fragment>
                        <label
                            className={
                                val == 'Pending'
                                    ? 'label label-warning'
                                    : val === 'Processed'
                                        ? 'label label-info'
                                        : val === 'Pending Property Confirmation'
                  ? 'label label-primary'
                  : val === 'Property Confirmed'
                  ? 'label label-success'
                  : val === 'Waiting for Payment'
                  ? 'label label-warning'
                  : val === 'Payment Confirmed'
                  ? 'label label-success'
                  : val === 'Booking Confirmed'
                  ? 'label label-success'
                  : 'label'
              }>
              {val}
            </label>
            <br />
                        <div className={'mt10'}>
                            <a className={'btn btn-xs btn-default'}
                               onClick={() => {
                                   // openStatus()
                               }}>
                                <button
                className="btn"
                style={{
                  backgroundColor: '#d9d9d9',
                  color: 'black',
                  marginTop: '8px',
                  fontSize: '12px'
                }}>
                <span>Status Change</span>
              </button> Change Status
                            </a>
                        </div>

                    </React.Fragment>
                )
            }
        }
    ]

    const handleExport = async () => {
        let obj = {
            ...filter,
            count: total,
            regExFilters: [
                'Title',
                'Name',
                'Nationality',
                'Email',
                'Phone Number',
                'University',
                'Room Details'
            ]
        }
        let {success, filePath, message} = await dispatch(eventListExport(obj))
        if (success) {
            notification.success({message: message})
            // window.open(`${apiUrl}${filePath}`, 'Download')
        } else {
            notification.error({message: message})
        }
    }

    return (
        <Drawer
            visible={visible}
            width={'80%'}
            closable={true}
            maskClosable={true}
            title={'Enquiry List'}
            onClose={onClose}>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="table-head  align-items-center all-student-search">
                            {/* <h6>All Enquiries: {total || 0}</h6> */}
                        </div>
                        <div className="card-body table-responsive">
                            <TableComp
                                columns={columns}
                                apiRequest={apiRequest}
                                ref={tableRef}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {visibleStatus ? (
                <Status visible={visible} setVisible={setVisible} onClose={onClose}/>
            ) : null}
        </Drawer>
    )
}

export default SingleStudentEnquiryList
