import React, {useState, useEffect, useRef} from 'react'
import {useDispatch, connect} from 'react-redux'
import {TableComp} from 'sz-react-utils-lite'
import {Col, DatePicker, Row, Select, Tooltip} from "antd";
import {getAgentCommissionListFxn} from "../actions";
import {
    displayDate,
    filterOption,
    longDisplayDate,
    InputBox,
    dateFilterRange, expenseTypes
} from "../../../components/_utils/appUtils";
import {listAllCountries} from "../../countries/actions/countries";
import {listAllUniversities} from "../../university/actions/university";
import {getUrlPushWrapper} from "../../../routes";
import {getBranchManagerAgentList, listAllUsers} from "../../users/actions/user";
import AgentCommStudentDrawer from "../drawers/agentCommStudent";
import PayCommission from '../drawers/payCommission'
import EditAgentInvoiceComponent from '../RaiseAgentInvoice/EditAgentInvoiceDrawer';
import _ from "lodash"

const {Option} = Select
const {RangePicker} = DatePicker

let initState = {
    status: "",
    visible: false,
    commissionId: ""
}
let initFilter = {
    countryId: "",
    status: "",
    universityId: "",
    intake: "",
    countryList: [],
    universityList: [],
    agentList: [],
    courseName: "",
    semester: "",
}
let agentCommissionInit = {
    visible: false,
    record: {}
}
let payCommissionInit = {
    visible: false,
    record: {}
}

let raiseInvoiceInit = {
    visible: false,
    record: {},
}
let initFilters = {
    agentId: "",
    invoiceNo: "",
    fromDate: "",
    toDate: "",
}
const FilterComponent = (props) => {
    let dispatch = useDispatch()
    let {filterData, events} = props;
    let [agentList, setAgentList] = useState([])
    const loadMarketingUsers = async () => {
        let obj = {
            userType: 'agent',
            results: 5000,
            count: 5000,
            select: ['name', 'companyName']
        }
        let {data} = await dispatch(listAllUsers(obj))
        setAgentList(data)
    }
    useEffect(() => {
        loadMarketingUsers()
    }, [])

    return (
        <Row gutter={12} className={'filter_box'}>
            <Col span={4}>
                <InputBox title={'Search by invoice no'}>
                    <input
                        value={filterData.invoiceNo}
                        placeholder={'Invoice No'}
                        onChange={({target}) => {
                            events.updateFilter({invoiceNo: target.value})
                        }}/>
                </InputBox>
            </Col>
            <Col span={4}>
                <InputBox title={'Search by date'}>
                    <RangePicker
                        defaultValue={[filterData.fromDate, filterData.toDate]}
                        onChange={val => {
                            events.updateFilter({fromDate: val[0], toDate: val[1]})
                        }}
                        ranges={dateFilterRange}
                    />
                </InputBox>
            </Col>
            <Col span={4}>
                <InputBox title={'Search by agent'}>
                    <Select
                        name="Agent User"
                        filterOption={filterOption}
                        allowClear={true}
                        placeholder={'Agent'}
                        showSearch={true}
                        onChange={item => {
                            events.updateFilter({agentId: item})
                        }}
                        value={filterData.agentId || undefined}>
                        {agentList && agentList.length
                            ? agentList.map((item, key) => {
                                return (
                                    <Option value={item._id} key={key}>
                                        {item.companyName}
                                    </Option>
                                )
                            })
                            : null}
                    </Select>
                </InputBox>
            </Col>


            <Col span={24}>
                <div className={'btn_group'}>
                    <a className={'default_btn'} onClick={events.searchFxn}>Search</a>
                    <a className={'default_btn'} onClick={events.clearFxn}>Clear</a>
                </div>
            </Col>
        </Row>
    )
}

const AgentCommissionList = (props) => {
    let [totalRecord, setTotalRecord] = useState(0)
    const [state, setState] = useState(initState)
    const [agentCommissionState, setAgentCommissionState] = useState(agentCommissionInit)
    const [payCommissionState, setPayCommissionState] = useState(payCommissionInit)
    const [raiseInvoiceState, setRaiseInvoiceState] = useState(raiseInvoiceInit)
    let [filterData, setFilterData] = useState(initFilters)

    const tableRef = useRef()
    const dispatch = useDispatch()
    useEffect(() => {
        filterEvents.setFieldByParams()
    }, [])
    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            let resp = await filterEvents.getParamsForApplicationList()
            params = {...params, ...resp}
            let {toDate, fromDate,} = params;
            if (fromDate) {
                let date = {}
                date.$gte = new Date(fromDate)
                date.$lte = new Date(toDate)
                params.invoiceDate = date;
                delete params['fromDate']
                delete params['toDate']
            }

            params.transactionType = "Credit"
            params.sortField = '_id'
            params.sortOrder = 'descend'

            let respData = await dispatch(getAgentCommissionListFxn({
                ...params,
                regExFilters: ['semester', 'courseName', 'name']
            }))
            setTotalRecord(respData.total)
            resolve(respData)
        })
    }
    const reload = () => {
        if (tableRef && tableRef.current) {
            tableRef.current.reload()
        }
    }

    const events = {
        showRaiseInvoiceState: (record) => {
            setRaiseInvoiceState({
                visible: true,
                record: record
            })
        },
        hideRaiseInvoiceState: () => {
            setRaiseInvoiceState({
                visible: false,
                record: {}
            })
            reload()
        },


        closeDrawer: () => {
            setState({
                ...initState
            })
        },
        showAgentCommissionDrawer: (record) => {
            record.agentCommissionId = record._id;
            setAgentCommissionState({
                visible: true,
                record: record
            })
        },
        hideAgentCommissionDrawer: () => {
            setAgentCommissionState({
                visible: false,
                record: {}
            })
        },
        showPayCommissionDrawer: (record) => {
            record.agentCommissionId = record._id;
            setPayCommissionState({
                visible: true,
                record: record
            })
        },
        hidePayCommissionDrawer: () => {
            setPayCommissionState({
                visible: false,
                record: {}
            })
        },
    }

    const columns = [

        /*{
            title: 'S.No',
            dataIndex: 'sno',
            width: 50,
            key: 'sno',
            render: (item, record, index) => {
                return (
                    <a>
                        {(itemNo - 1) * 10 + index + 1}
                    </a>
                )
            }
        },*/
        {
            title: 'Invoice No',
            key: 'invoiceNo',
            dataIndex: 'invoiceNo',
            width: 80,
            render: (item) => {
                return (
                    <div style={{width: 80}}>
                        {item}
                    </div>
                )
            }
        },
        {
            title: 'Invoice Date',
            dataIndex: 'invoiceDate',
            key: 'invoiceDate',
            width: 120,
            render: (item, record) => {
                return (
                    <div style={{width: 150}}>
                        {item ? displayDate(item) : ""}
                    </div>
                )
            }
        },
        {
            title: 'Agent',
            key: 'agentId',
            dataIndex: 'agentId',
            width: 220,
            render: (item, record) => {
                return (
                    <div style={{width: 180}}>
                        {item && item.name ? item.name : null}
                        {item && item.companyName ? `(${item.companyName})` : null}
                    </div>
                )
            }
        },
        {
            title: 'Invoice Address',
            key: 'invoiceAddress',
            dataIndex: 'invoiceAddress',
            width: 220,
            render: (item, record) => {
                return (
                    <div style={{width: 180}}>
                        {item}
                    </div>
                )
            }
        },

        {
            title: 'Agent Share',
            dataIndex: 'totalCommission',
            key: 'totalCommission',
            width: 120,
            render: (item, record) => {
                let {totalApprovedByAdminCommission, countryId, bonus, totalCommissionBonus} = record;
                return (
                    <div style={{width: 150}}>
                        {countryId && countryId.currency ? countryId.currency : ""}
                        {totalApprovedByAdminCommission ? totalApprovedByAdminCommission : item} &nbsp;
                        {countryId && countryId.currencyName ? countryId.currencyName : ""}
                        {bonus && totalCommissionBonus ? <div>
                            Bonus :
                            {countryId && countryId.currency ? countryId.currency : ""}
                            {totalCommissionBonus ? totalCommissionBonus : ""}&nbsp;
                            {countryId && countryId.currencyName ? countryId.currencyName : ""}
                        </div> : null}
                    </div>
                )
            }
        },
        /*{
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 150,
            render: (item, record) => {
                return (
                    <div style={{width: 150}}>
                        {item == 'Paid' ?
                            <label className={'label label-success'}>Paid</label> :
                            <label className={'label label-danger'}>Pending</label>}
                    </div>
                )
            }
        },*/

        {
            title: 'Added By',
            key: 'userId',
            dataIndex: 'userId',
            width: 160,
            render: (item, record) => {
                return (
                    <div style={{width: 160}}>
                        <div className={'colorPrimary font12 textCap'}>
                            {item && item.name ? item.name : null}
                        </div>
                        <div>{longDisplayDate(record.createdAt)}</div>
                    </div>
                )
            }
        },

        {
            title: 'Action',
            key: 'action',
            dataIndex: 'action',
            width: 160,
            render: (item, record) => {
                let {status, transactionType, invoiceStatus} = record
                return (
                    <div className={'view-btn-box'}>

                        {transactionType == 'Credit' ? <>


                            <Tooltip title={'View Student List'}>
                                <a className='btn-view' onClick={() => {
                                    events.showAgentCommissionDrawer(record)
                                }}>
                                    <img src='../uniBtn/eye.png' alt='' className={'eyeBtn'}/> View
                                </a>
                            </Tooltip>
                        </> : ""}

                        {(invoiceStatus == 'Pending' && transactionType == 'Credit') ?
                            <>
                                <a className='btn-view'
                                   onClick={() => {
                                       events.showRaiseInvoiceState(record)
                                   }}><img src='/dist/img/edit.svg' alt='edit' style={{height: 12}}/> Edit
                                </a>


                            </> : ''}


                    </div>
                )
            }
        },

    ]

    let filterEvents = {
        getParams: (field) => {
            let searchParams = new URLSearchParams(window.location.search)
            return searchParams.get(field) ? searchParams.get(field) : ""
        },
        updateFilter: (data) => {
            setFilterData((prevState) => {
                return {
                    ...prevState,
                    ...data
                }
            })
        },
        setFieldByParams: async () => {
            let {getParams} = filterEvents
            let obj = {}
            _.each(initFilters, (item, key) => {
                if (getParams(key)) {
                    obj[key] = getParams(key);
                }
            })
            setFilterData((prevState) => {
                return {
                    ...prevState,
                    ...obj
                }
            })
            setTimeout(() => {
                if (tableRef && tableRef.current) {
                    tableRef.current.reload()
                }
            }, 100)
        },
        getParamsForApplicationList: () => {
            return new Promise(resolve => {
                let obj = {}
                _.each(initFilters, (item, key) => {
                    if (filterData[key]) {
                        obj[key] = filterData[key];
                    }
                })
                resolve(obj)
            })
        },
        searchFxn: async () => {
            let obj = {}
            _.each(initFilters, (item, key) => {
                if (filterData[key]) {
                    obj[key] = filterData[key];
                }
            })
            dispatch(getUrlPushWrapper('commission.agentCommissionList', {...obj}))
            setTimeout(() => {
                if (tableRef && tableRef.current) {
                    tableRef.current.reload()
                }
            }, 200)
        },
        clearFxn: () => {
            let obj = {}
            dispatch(getUrlPushWrapper('commission.agentCommissionList', {...obj}))
            setFilterData((prevState) => {
                return {
                    ...prevState,
                    ...initFilters
                }
            })
            setTimeout(() => {
                tableRef.current.reload()
            }, 200)
        }
    }

    return (
        <div className='row mt-4'>
            <div className='col-lg-12'>
                <div className='card'>
                    <div className='table-head d-flex align-items-center'>
                        <h5>All Agent Commission: {totalRecord || 0}</h5>

                        <div className='sort-box-table mark-btn mark-btn40'>

                        </div>
                    </div>
                    <div className='card-body table-responsive'>
                        {/*{FilterComponent}*/}
                        <div className='card-body table-responsive'>
                            <FilterComponent filterData={filterData} events={filterEvents}/>

                            <TableComp columns={columns}
                                       apiRequest={apiRequest}
                                       pagination={{
                                           position: 'top',
                                           pageSizeOptions: ['50', '100', '200', '500'],
                                           defaultPageSize: 50
                                       }}
                                       ref={tableRef}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {raiseInvoiceState.visible ?
                <EditAgentInvoiceComponent
                    visible={raiseInvoiceState.visible}
                    onClose={events.hideRaiseInvoiceState}
                    onSubmit={reload}
                    record={raiseInvoiceState.record}
                /> : null}

            {agentCommissionState.visible ?
                <AgentCommStudentDrawer
                    visible={agentCommissionState.visible}
                    onClose={events.hideAgentCommissionDrawer}
                    onSubmit={reload}
                    record={agentCommissionState.record}
                /> : null}
            {payCommissionState.visible ?
                <PayCommission
                    visible={payCommissionState.visible}
                    onClose={events.hidePayCommissionDrawer}
                    onSubmit={reload}
                    record={payCommissionState.record}
                /> : null}
        </div>
    )
}

const mapStateToProps = ({global, router}) => ({
    currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AgentCommissionList)
