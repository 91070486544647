import { Button, Col, DatePicker, Popconfirm, Row, Select } from 'antd'
import { push } from 'connected-react-router'
import moment from 'moment'
import React, { Suspense, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'

import {
  DefaultTablePagination,
  InputBox,
  departmentObj,
  filterOption,
  newFormatDisplayDate
} from '../../../components/_utils/appUtils'
import { listAllUsers } from '../../users/actions/user'
import {
  expenseWithdrawal,
  listDailyExpense,
  updateStatusExpense
} from '../actions/dailyExpense'
import UpdateStatus from './takeAction'
import UpdateWithDrawalStatus from './updateStatus'

const ExpenseWithdrawalList = props => {
  const { Option } = Select

  const { RangePicker } = DatePicker
  let tableRef = useRef()
  const { events } = props
  const dispatch = useDispatch()
  const [fromDate, setFromDate] = useState()
  const [visible, setVisible] = useState(false)
  const [toDate, setToDate] = useState()
  let [marketingUserList, setMarketingUserList] = useState([])
  let [expense, setExpense] = useState('')
  let [updateType, setUpdateType] = useState('')
  let [marketingUserId, setMarketingUserId] = useState('')

  useEffect(() => {
    loadMarketingUsers()
  }, [])
  const ranges = {
    Today: [moment().startOf('day'), moment().endOf('day')],
    'Last Week': [
      moment()
        .subtract(1, 'week')
        .startOf('week'),
      moment()
        .subtract(1, 'week')
        .endOf('week')
    ],
    'Last 15 Days': [moment().subtract(14, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')]
  }

  useEffect(
    () => {
      tableRef.current.reload()
    },
    [fromDate, marketingUserId]
  )

  const loadMarketingUsers = async () => {
    let obj = {
      userType: 'branchUser',
      department: departmentObj.marketing,
      results: 100,
      select: ['name', 'email', 'mobile', 'agents', 'assignStateCountry']
    }
    let { data } = await dispatch(listAllUsers(obj))
    setMarketingUserList(data)
  }

  const updateStatus = async (_id, status) => {
    let resp = await dispatch(
      updateStatusExpense({ expenseId: _id, status: status })
    )
    if (resp && resp.success) {
      tableRef.current.reload()
    }
  }

  const apiRequest = params => {
    return new Promise(async resolve => {
      if (fromDate) {
        let date = {}
        date.$gte = new Date(fromDate)
        date.$lte = new Date(toDate)
        params.date = date
      }

      let respData = await dispatch(
        expenseWithdrawal({
          ...params
        })
      )
      resolve(respData)
    })
  }

  const columns = [
    {
      title: 'Sr .No',
      key: '_id',
      dataIndex: '_id',
      width: 80,
      render: (item, record, index) => {
        return <div style={{ width: 50 }}>{index + 1}</div>
      }
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      searchDateName: 'date',
      render: val => {
        return (
          <div
          // style={{ width: 90 }}
          >
            {newFormatDisplayDate(val)}
          </div>
        )
      }
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
      key: 'reason',
      searchTextName: 'reason',
      render: (val, record) => {
        return <p>{val}</p>
      }
    },


    {
      title: 'Account  Details',
      dataIndex: 'account',
      key: 'account',
      searchTextName: 'account',
      render: (val, record) => {
        return val ? (
          <p>
            BankName - {val && val.bankName ? val.bankName : ''} <br />
            Account Number - {val && val.accountNo ? val.accountNo : ''}
          </p>
        ) : null
      }
    },

    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      searchTextName: 'amount',
      render: (val, record) => {
        return val ? <p>Rs. {val}</p> : null
      }
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      width: 150,
      render: (val, record) => {
        return (
          <React.Fragment>
            <label
              className={
                val == 'Cancel'
                  ? 'label label-danger'
                  : val == 'Pending'
                  ? 'label label-warning'
                  : val == 'Approved'
                  ? 'label label-success'
                  : 'label'
              }>
              {val}
            </label>
            <br />
          </React.Fragment>
        )
      }
    },
    {
      title: 'Action',
      dataIndex: 'status',
      key: 'status',
      render: (item, record) => {
        return (
          <>
            {item == 'Approved' ? (
              ''
            ) : item == 'Cancel' ? (
              ''
            ) : (
              <>
                <Button
                  onClick={() => {
                    setExpense(record._id)
                    setVisible(true)
                    setUpdateType('Cancel')
                  }}
                  icon={<span className="cancel-icon" />}>
                  <img
                    src="../dist/img/cross.svg"
                    alt=""
                    className={'crossBtn'}
                    style={{ height: '11px', marginRight: '4px' }}
                  />{' '}
                  Cancel
                </Button>
                {/* <button className='btn' ></button> */}
                <Button
                  onClick={() => {
                    setExpense(record._id)
                    setVisible(true)
                    setUpdateType('Approved')
                  }}
                  style={{ marginLeft: '10px' }}
                  icon={<span className="cancel-icon" />}>
                  <img
                    src="../dist/img/check-solid.svg"
                    alt=""
                    className={'crossBtn'}
                    style={{ height: '11px', marginRight: '4px' }}
                  />
                  Approve
                </Button>
              </>
            )}
          </>
        )
      }
    }
  ]

  return (
    <>
      <div className="row mt-4">
        <div className="col-lg-12">
          <div className="card">
            <div className="table-head d-flex align-items-center">
              <h5>Expense Withdrawal List</h5>
              <div className="search-box-table"/>

            </div>

            <div className="card-body table-responsive ">
              {/* {filterBlock} */}
              <Row gutter={16}>
                <Col md={4} sm={4} xs={12} lg={5} key={fromDate}>
                  <RangePicker
                    defaultValue={[fromDate, toDate]}
                    onChange={val => {
                      setFromDate(val[0])
                      setToDate(val[1])
                    }}
                    ranges={ranges}
                  />
                </Col>

              </Row>
              <TableComp
                columns={columns}
                apiRequest={apiRequest}
                ref={tableRef}
                pagination={DefaultTablePagination()}
              />
            </div>
            {visible ? (
              <UpdateWithDrawalStatus
                // updateType={updateType}
                withDrawlId={expense}
                visible={visible}
                onClose={() => {
                  setVisible(false)
                  tableRef.current.reload()
                }}
              />
            ) : null}
          </div>
        </div>
      </div>
    </>
  )
}

export default ExpenseWithdrawalList
