import { notification } from 'antd'
import { getToken, customAxios as axios } from '../../request'
import { hidePageLoad, showPageLoad } from '../../modules/actions'
import {
    deleteIntakesUrl,
    IntakesUrl,
    singleIntakesUrl,
    updateIntakeUrl,
    addUniversityIntakeUrl,
    addCourseIntakeUrl, addIntakeInCourseUrl
} from './apis'
import { listCityUrl } from '../city/api/city'


export const addIntake = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(IntakesUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}


export const listIntakes = (filters) => async (dispatch) => {
  dispatch(showPageLoad())
  let config = {
    params: { ...filters },
    headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
  }
  let { data } = await axios.get(IntakesUrl(), config)
  console.log('ggg', data)
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data.data
}
export const getSingleIntake = (id) => async (dispatch) => {
  dispatch(showPageLoad())
  let config = {
    headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
  }
  let { data } = await axios.get(singleIntakesUrl(id), config)
  console.log('ggg', data)
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data.data
}


export const updateIntake = (valData, id) => async (dispatch) => {
  console.log('ididididid', id)
  dispatch(showPageLoad())
  let { data } = await axios.put(updateIntakeUrl(id), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || `Updated Successfully`
    })
  } else {
    notification.error({
      message: data.message || 'Error updating'
    })
  }
  return data
}

export const addCourseIntake = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(addCourseIntakeUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}


export const deleteIntake = (id) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.delete(deleteIntakesUrl(id), getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || `Deleted Successfully`
    })
  } else {
    notification.error({
      message: data.message || 'Error in deleting intake'
    })
  }
  return data
}


export const addIntakeInCourseFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(addIntakeInCourseUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}
