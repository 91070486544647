import React, {useState, useEffect, useRef} from 'react'
import {
    Button, Col,
    Popconfirm, Select,
    Row, Tag
} from 'antd'
import {connect, useDispatch} from 'react-redux'
import {notification, Table} from 'antd'
import {
    countryIds,
    filterOption, Intakes, IntakesYears, MonthIndex,
} from '../../components/_utils/appUtils'
import {listAllCountries} from '../countries/actions/countries'
import {addIntakeInCourseFxn, addCourseIntake} from './actions'
import {listAllUniversities} from '../university/actions/university'
import {listAllCourse} from '../course/actions/courseAction'

const {Option} = Select
const AssignIntakes = (props) => {
    let [allCountries, setAllCountries] = useState([])
    let [countryId, setCountryId] = useState('')
    let [selectedIntake, setSelectedIntake] = useState({})
    let [intakeId, setIntakeId] = useState('')
    let [selectedCourse, setSelectedCourse] = useState([])
    const [allUniversities, setAllUniversities] = useState([])
    const [intakeStatus, setIntakeStatus] = useState('')
    const [intakeCanadaStatus, setIntakeCanadaStatus] = useState('')
    const [pickUniversity, setPickUniversity] = useState('')
    const [intakeMonth, setIntakeMonth] = useState('')
    const [intakeYear, setIntakeYear] = useState('')
    const [intakeMonthList, setIntakeMonthList] = useState([])
    const [intakesYearList, setIntakesYearList] = useState([])
    const [allCourse, setAllCourse] = useState([])
    const [selectedRowKeys, setSelectedRowKeys] = useState([])

    const tableRef = useRef()
    const dispatch = useDispatch()


    useEffect(() => {
        loadAllCountry()
        getAllIntake()

    }, [])
    useEffect(() => {
        universityList()
    }, [countryId])

    const getAllIntake = async () => {
        setIntakesYearList(IntakesYears)
        setIntakeMonthList(Intakes)
    }

    const loadAllCountry = async () => {
        let {dispatch} = props
        let {data} = await dispatch(listAllCountries())
        if (data && data.length) {
            setCountryId(data[0]._id)
        }
        setAllCountries(data)
    }

    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            params.sortField = 'universityName'
            params.sortOrder = 'ascend'
            let resp = await getParamsForFilter()
            if (countryId) {
                params.universityCountry = resp.countryId
            }

            let page = params.page
            params.page = 1
            if (!params.results) {
                params.results = 50
                params.count = 50
            }

            // setFilters(params)
            let respData = await dispatch(listAllUniversities({
                ...params,
                page: page,
                regExFilters: ['universityName']
            }))
            setAllUniversities(respData.data)
            resolve(respData)
        })
    }


    const universityList = async (id) => {
        let obj = {
            results: 1000,
            // select: ['universityName', 'intakes', 'universityCountry'],
            universityCountry: id ? id : countryId,
            regExFilters: ['universityName']
        }
        let respData = await dispatch(listAllUniversities(obj))
        let UniData = respData.data
        let data = []
        if (UniData && UniData.length) {
            UniData.map((item) => {
                let obj = {
                    name: item.universityName,
                    key: item._id,
                    _id: item._id,
                    intakes: item.intakes,
                    country: item.universityCountry.countryName
                }
                data.push(obj)
            })
        }

        setAllUniversities(data)
    }
    const CourseList = async (id) => {
        let params = {
            results: 1000000,
            courseUniversity: id,
            sortField: 'courseName',
            sortOrder: 'ascend',
            select: ['courseName', 'tuitionFee', 'intakes', 'courseIntakes']
        }
        let {data} = await dispatch(listAllCourse(params))
        let courseList = []
        data.map((item) => {
            let obj = {
                name: item.courseName,
                key: item._id,
                _id: item._id,
                courseIntakes: item.courseIntakes,
                country: item.universityCountry.countryName
            }
            courseList.push(obj)
        })
        setAllCourse(courseList)
    }

    const searchData = async (id) => {
        universityList(id)
    }
    const searchCourseData = async (id) => {
        CourseList(id)
    }

    const chooseIntake = (intakeValue) => {
        // let findIntake = _.find(intakes, (item) => {
        //   return item._id == intakeValue
        // })
        if (intakeYear && intakeMonth) {
            setSelectedIntake({
                // intakeId: findIntake._id,/
                month: intakeMonth,
                year: intakeYear
            })
        }
        setIntakeId(intakeValue)
    }

    const getParamsForFilter = () => {
        return new Promise((resolve) => {
            let searchParams = new URLSearchParams(window.location.search)
            let countryId = searchParams.get('countryId')
            let obj = {}
            if (countryId) {
                obj.countryId = countryId
            }
            resolve(obj)
        })
    }

    // const FilterByNameInput = (
    //   <Input
    //     placeholder="Search Name"
    //     value={value}
    //     onChange={e => {
    //       const currValue = e.target.value;
    //       setValue(currValue);
    //       const filteredData = data.filter(entry =>
    //         entry.name.includes(currValue)
    //       );
    //       setDataSource(filteredData);
    //     }}
    //   />
    // );


    const columns = [

        {
            title: 'name',
            width: 400,
            key: 'name',
            dataIndex: 'name'
        },
        {
            title: 'Intakes',
            key: 'courseIntakes',
            // width: 600,
            dataIndex: 'courseIntakes',
            render: (item, record) => (
                <div>
                    {
                        record && record.courseIntakes && record.courseIntakes.map((itr, index) => {
                            if (itr.status) {
                                if (itr.status == 'open') {
                                    return <Tag
                                        style={{backgroundColor: 'white', marginBottom: 5, verticalAlign: 'middle'}}>
                                        <span style={{verticalAlign: 'middle'}}>{itr.month},{itr.year}</span>
                                        <img src='/dist/img/check.png' alt='' width={14}
                                             style={{marginLeft: 4, verticalAlign: 'middle'}}
                                             height={14} className='mr-1'/>
                                        {/*{index + 1 !== record.courseIntakes.length ? <a> | </a> : null}*/}
                                    </Tag>
                                } else {
                                    return <Tag
                                        style={{backgroundColor: 'white', marginBottom: 5, verticalAlign: 'middle'}}>
                    <span style={{verticalAlign: 'middle'}}>{itr.month},{itr.year}
                        {itr.closeStatus ? ` (${itr.closeStatus})` : null}</span>
                                        <img src='/dist/img/delete.png' style={{marginLeft: 4, verticalAlign: 'middle'}}
                                             alt=''
                                             height={14} width={14} className='mr-1'/>
                                        {/*{index + 1 !== record.courseIntakes.length ? <a> | </a> : null}*/}
                                    </Tag>
                                }
                            }
                        })
                    }
                </div>
            )
        }
    ]


    const rowSelection = {
        selectedRowKeys: selectedRowKeys,
        // onSelectAll: (selected, selectedRows, changeRows) => {
        //   if (selectedRowKeys.length !== 0) {
        //     setSelectedRowKeys([]);
        //     setSelectedCourse([]);
        //   }
        // },

        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
            setSelectedRowKeys(selectedRowKeys)
            setSelectedCourse(selectedRowKeys)
        }
    }

    const submit = async () => {
        // if (!intakeId) {
        //   notification.error({ message: 'Please select Intake' })
        //   return
        // }
        if (!intakeMonth) {
            notification.error({message: 'Please select Intake Month'})
            return
        }
        if (!intakeYear) {
            notification.error({message: 'Please select Intake Year'})
            return
        }
        if (!intakeStatus) {
            notification.error({message: 'Please select Intake status'})
            return
        }
        if (!selectedCourse.length) {
            notification.error({message: 'Please select Course'})
            return
        }
        if (!pickUniversity) {
            notification.error({message: 'Please select University'})
            return
        }
        let obj = {
            intake: {
                month: intakeMonth,
                year: intakeYear,
                status: intakeStatus,
                closeStatus: intakeCanadaStatus,
                index: MonthIndex[intakeMonth]
            },
            universityId: pickUniversity,
            selectedCourse: selectedCourse
        }
        let resp = await dispatch(addCourseIntake(obj))
        if (resp) {
            setIntakeCanadaStatus('')
            setSelectedRowKeys([])
            searchCourseData(pickUniversity)
        }
    }

    return (
        <div className='row  mt-4'>
            <div className='col-lg-12'>
                <div className='card'>
                    <div className='table-head d-flex align-items-center'>
                        <h5>Assign Intake</h5>
                        <div className='search-box-table'>

                        </div>

                        <div className='sort-box-table mark-btn'>

                        </div>
                    </div>
                    <Row gutter={16} style={{paddingLeft: 10, paddingTop: 10}}>
                        <Col md={8} sm={8} xs={8} lg={6}>
                            <div className='sort-box-table custom-sort-box-new'>
                                <Select name='Search By Country'
                                        onChange={(item) => {
                                            // searchData(item)
                                        }}
                                        onSelect={(item) => {
                                            setCountryId(item)
                                        }}
                                        filterOption={filterOption}
                                        className={'antSelect'}
                                        placeholder='Country'
                                        allowClear={true}
                                        showSearch={true}
                                        value={countryId || undefined}>
                                    {allCountries && allCountries.length ? allCountries.map((item, key) => {
                                        return (
                                            <Option value={item._id} key={key}>{item.countryName}</Option>
                                        )
                                    }) : null}
                                </Select>
                            </div>
                        </Col>
                        <Col md={8} sm={8} xs={8} lg={6}>
                            <div className='sort-box-table custom-sort-box-new'>
                                <Select name='Search By University'
                                        onChange={(item) => {
                                            searchCourseData(item)
                                        }}
                                        onSelect={(item) => {
                                            setPickUniversity(item)
                                        }}
                                        filterOption={filterOption}
                                        className={'antSelect'}
                                        placeholder='University'
                                        allowClear={true}
                                        showSearch={true}
                                        value={pickUniversity || undefined}>
                                    {allUniversities && allUniversities.length ? allUniversities.map((item, key) => {
                                        return (
                                            <Option value={item._id} key={key}>{item.name}</Option>
                                        )
                                    }) : null}
                                </Select>
                            </div>
                        </Col>

                    </Row>
                    <Row gutter={16} style={{paddingLeft: 10}}>
                        <Col md={6} sm={6} xs={6} lg={4}>
                            <div className='sort-box-table custom-sort-box-new'>
                                <Select name='Intakes'
                                    // onChange={(item) => chooseIntake(item)}
                                        onChange={(item) => {
                                            setIntakeMonth(item)
                                        }}
                                        filterOption={filterOption}
                                        className={'antSelect'}
                                        placeholder='Intake Month'
                                        allowClear={true}
                                        showSearch={true}
                                        value={intakeMonth || undefined}>
                                    {intakeMonthList && intakeMonthList.length ? intakeMonthList.map((item, key) => {
                                        return (
                                            <Option value={item} key={key}>{item}</Option>
                                        )
                                    }) : null}
                                </Select>
                            </div>
                        </Col>
                        <Col md={6} sm={6} xs={6} lg={4}>
                            <div className='sort-box-table custom-sort-box-new'>
                                <Select name='Intakes'
                                    // onChange={(item) => chooseIntake(item)}
                                        onChange={(item) => setIntakeYear(item)}
                                        filterOption={filterOption}
                                        className={'antSelect'}
                                        placeholder='Intake Year'
                                        allowClear={true}
                                        showSearch={true}
                                        value={intakeYear || undefined}>
                                    {intakesYearList && intakesYearList.length ? intakesYearList.map((item, key) => {
                                        return (
                                            <Option value={item} key={key}>{item}</Option>
                                        )
                                    }) : null}
                                </Select>
                            </div>
                        </Col>
                        <Col md={6} sm={6} xs={6} lg={4}>
                            <div className='sort-box-table custom-sort-box-new'>
                                <Select name='Intakes Status'
                                        onChange={(item) => {
                                            setIntakeStatus(item)
                                            setIntakeCanadaStatus('')
                                        }}
                                        className={'antSelect'}
                                        placeholder='Intake Status'
                                        allowClear={true}
                                        showSearch={true}
                                        value={intakeStatus || undefined}>
                                    <Option value={'open'} key={'open'}>Open</Option>
                                    <Option value={'close'} key={'close'}>Close</Option>
                                    <Option value={'delete'} key={'delete'}>Delete</Option>
                                </Select>
                            </div>
                        </Col>
                        {countryId && intakeStatus && countryId === countryIds.canada && intakeStatus === 'close' ?
                            <Col md={6} sm={6} xs={6} lg={4}>
                                <div className='sort-box-table custom-sort-box-new'>
                                    <Select name='Canada Intakes Status'
                                            onChange={(item) => setIntakeCanadaStatus(item)}
                                            className={'antSelect'}
                                            placeholder='Canada Intake Status'
                                            allowClear={true}
                                            showSearch={true}
                                            value={intakeCanadaStatus || undefined}>
                                        <Option value={'All'} key={'Offshore'}>All</Option>
                                        <Option value={'Offshore'} key={'Offshore'}>Offshore</Option>
                                        <Option value={'Onshore'} key={'Onshore'}>Onshore</Option>
                                    </Select>
                                </div>
                            </Col> : null}
                        <Col span={6}>
                            <Popconfirm title={'Are your sure, you want to Add Intake?'}
                                        onConfirm={() => {
                                            submit()
                                        }}
                                        okText='Yes' cancelText='No'>
                                <Button className={'roundBtn'}>Submit</Button>
                            </Popconfirm>
                        </Col>
                    </Row>


                    {allCourse && allCourse.length ?
                        <Table
                            rowSelection={{
                                type: 'checkbox',
                                ...rowSelection
                            }}
                            ref={tableRef}
                            columns={columns}
                            pagination={false}
                            dataSource={allCourse}
                        /> : null}

                </div>
            </div>
        </div>
    )
}


const mapStateToProps = ({global}) => ({
    categories: global.categories,
    activeUser: global.activeUser
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AssignIntakes)


