import { notification } from 'antd'
import { getToken, customAxios as axios } from '../../../request'
import { hidePageLoad, showPageLoad } from '../../../modules/actions'
import { addFeedbackUrl, listFeedbackUrl, singleFeedbackUrl } from '../../feedback/api/feedBackApis'


export const addFeedback = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(addFeedbackUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}


export const listFeedback = (filters) => async (dispatch) => {
  dispatch(showPageLoad())
  let config = {
    params: { ...filters },
    headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
  }
  let { data } = await axios.get(listFeedbackUrl(), config)
  dispatch(hidePageLoad())
  dispatch({ type: 'LIST_ALL_FEEDBACK', payload: data.data })
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data.data
}


export const deleteFeedback = (id) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.delete(singleFeedbackUrl(id), getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || `Deleted Successfully`
    })
  } else {
    notification.error({
      message: data.message || 'Error updating'
    })
  }
  return data
}


