import React, { useEffect, useState } from 'react'
import { InputBox } from '../../../components/_utils/appUtils'
import { Button, Card, Drawer, Input, notification } from 'antd'
import { addAppComment } from '../actions/chooseStudent'
import { useDispatch } from 'react-redux'

let { TextArea } = Input
const AddComment = (props) => {
  const dispatch = useDispatch()
  let { visible, onClose, applicationObj, reloadFxn } = props
  let [comment, setComment] = useState('')
  const handleSubmit = async () => {
    if (!comment) {
      return notification.warn({ message: 'Please enter comment.' })
    }
    let obj = {
      comment,
      studentId: applicationObj._id,
      applicationId: applicationObj.applications._id
    }
    let { success } = await dispatch(addAppComment(obj))
    if (success) {
      setComment('')
      onClose()
      reloadFxn()
    }
  }
  return (
    <Drawer visible={visible}
            onClose={onClose}
            width={'40%'}
            closable={true}
            maskClosable={true}
            title={'Add Comment'}>
      <Card>
        <InputBox title={'Comment'}>
          <TextArea onChange={(e) => setComment(e.target.value)} value={comment} rows={4} className={'form-control'}/>
        </InputBox>
        <Button type="primary" onClick={handleSubmit}>
          SUBMIT
        </Button>
      </Card>
    </Drawer>
  )
}

export default AddComment
