import { apiUrl } from '../../../settings'
import { UserRating } from '../actions/user'

export const addUserUrl = () => {
  return apiUrl + '/user'
}

export const listUserUrl = () => {
  return apiUrl + '/user'
}
export const listEmployeeUrl = () => {
  return apiUrl + '/employee'
}
export const addEmployeeUrl = () => {
  return apiUrl + '/employee'
}
export const branchManagerAgentListBySearchUrl = () => {
  return apiUrl + '/branchManagerAgentListBySearch'
}
export const branchManagerAgentListUrl = () => {
  return apiUrl + '/branchManagerAgentList'
}
export const marketingUserAgentListUrl = () => {
  return apiUrl + '/marketingUserAgentList'
}

export const singleUserUrl = id => {
  return apiUrl + '/user/' + id
}
export const singleEmployeeUrl = id => {
  return apiUrl + '/employee/' + id
}

export const updateUserExtraInfoUrl = id => {
  return apiUrl + '/updateUserExtraInfo/' + id
}
export const updateEmployeeExtraInfoUrl = id => {
  return apiUrl + '/updateEmployeeExtraInfo/' + id
}

export const userTypeWiseUrl = () => {
  return apiUrl + '/userTypeWise'
}

export const agentAppCountUrl = () => {
  return apiUrl + '/getAgentAppCount'
}
export const getAgentAppCountByParamsIdUrl = () => {
  return apiUrl + '/getAgentAppCountByParamsId'
}

export const subAgentAppCountByAgentUrl = () => {
  return apiUrl + '/getSubAgentAppCountByAgent'
}

export const updateUserRightsUrl = () => {
  return apiUrl + '/api/updateUserRights'
}

export const getBranchUserForAssignApplicationUrl = () => {
  return apiUrl + '/api/getBranchUserForAssignApplication'
}

export const getBranchUserForReActivateApplicationUrl = () => {
  return apiUrl + '/api/getBranchUserForReActivateApplication'
}

export const loadNotificationCountUrl = () => {
  return apiUrl + '/api/loadNotificationCount'
}

export const resetNotificationCountUrl = () => {
  return apiUrl + '/api/resetNotificationCount'
}
export const resetPrivateMessageCountUrl = () => {
  return apiUrl + '/api/resetPrivateMessagesCount'
}

export const notificationReadyUrl = () => {
  return apiUrl + '/api/notificationReady'
}

export const privateMessageReadyUrl = () => {
  return apiUrl + '/api/privateMessageReady'
}

export const searchUser = () => {
  return apiUrl + '/search/user'
}

export const updatePasswordUrl = () => {
  return apiUrl + '/api/updatePassword'
}

export const UserRatingUrl = () => {
  return apiUrl + '/api/userRating'
}

export const allNotificationUrl = () => {
  return apiUrl + '/api/allNotification'
}
export const blockAgentUrl = () => {
  return apiUrl + '/api/blockAgent'
}
export const checkCertificationTokenUrl = () => {
  return apiUrl + '/checkCertificationToken'
}
export const uploadCertificateUrl = () => {
  return apiUrl + '/api/uploadCertificate'
}

export const contactUsUrl = () => {
  return apiUrl + '/api/contactUs'
}

export const UserLogsUrl = () => {
  return apiUrl + '/api/logs'
}

export const getAssignedUserForAccessmentDepartmentUrl = () => {
  return apiUrl + '/api/assignedUserForAccessmentDepartment'
}

export const exportAgentUrl = () => {
  return apiUrl + '/api/export_agent'
}
export const signeAgreementUrl = () => {
  return apiUrl + '/api/signeAgreement'
}

export const enquiryUrl = () => {
  return apiUrl + '/api/enquiry'
}
export const agentListUrl = () => {
  return apiUrl + '/agentsWithoutAuth'
}

export const educatorFeedbackUrl = () => {
  return apiUrl + '/api/educatorFeedback'
}

export const checkEnquiryFeedbackUrl = () => {
  return apiUrl + '/api/checkEnquiryFeedback'
}
export const enquiryFeedbackUrl = () => {
  return apiUrl + '/api/enquiryFeedback'
}

export const getAgentCertificateUrl = () => {
  return apiUrl + '/agentCertificate'
}

export const updateShareReferralAllowUrl = () => {
  return apiUrl + '/api/updateShareReferralAllow'
}
export const updateDefaultReferralUrl = () => {
  return apiUrl + '/api/updateDefaultReferral'
}
export const updateInsuranceRightUrl = () => {
  return apiUrl + '/api/updateInsuranceRight'
}
export const getRatingLogsUrl = () => {
  return apiUrl + '/ratingLogs'
}
export const getUserBirthdayListUrl = () => {
  return apiUrl + '/userBirthdayList'
}
export const broadcastingUrl = () => {
  return apiUrl + '/send-broadcast'
}
export const checkBroadcastingStatusUrl = () => {
  return apiUrl + '/checkBroadcastingStatus'
}

export const downloadCertificateUrl = () => {
  return apiUrl + '/api/download-certificate'
}

export const getOnlineAgentCountUrl = () => {
  return apiUrl + '/getOnlineAgentCount'
}
export const footFallUrl = () => {
    return apiUrl + '/footfall'
}
export const loadDistinctStateOfFootFallUrl = () => {
  return apiUrl + '/distinctStateOfFootFall'
}
export const getDistinctStateOfOnlineAgentsUrl = () => {
  return apiUrl + '/getDistinctStateOfOnlineAgents'
}

export const getMarketingUserByUniversityUrl = () => {
    return apiUrl + '/getMarketingUserByUniversity'
}

export const updateUserUrl = () => {
    return apiUrl + '/updateUser'
}

export const singleUserProfileUrl = () => {
    return apiUrl + '/singleUserProfile'
}
