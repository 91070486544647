import React, { useEffect, useState } from 'react'
import {
  Card,
  Row,
  Col,
  Input,
  Button,
  Form,
  Tabs,
  Collapse,
  Select,
  Slider,
  Checkbox,
  Spin,
  Skeleton,
  Empty,
  Icon
} from 'antd'
import PageHeaderWrapper from '../../components/PageHeaderWrapper'
// import { FormUtils as GetAllFormFields } from 'sz-react-utils-lite'
import GetAllFormFields from '../../components/_utils/formUtils'

import { useDispatch, useSelector } from 'react-redux'
import { listAllCountries } from '../countries/actions/countries'
import { listAllStates, indiaAllStates } from '../state/actions/state'
import { listQuickSearchUniversities } from '../university/actions/university'
import { listQuickSearchCourse, listQuickSearchMoreCourse } from '../course/actions/courseAction'
import {
  qualificationOptions, Intakes,
  InputBox, ProgramLevel, UniversityTypes, FilterOption, defaultLogoList
} from '../../components/_utils/appUtils'
import _ from 'lodash'
import async from 'async'
import AddApplications from '../applications/views/add'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { LoadState } from '../../components/_utils/countryUtil'


const { TabPane } = Tabs
const { Panel } = Collapse
const { Option } = Select
const EnglishExamTypes = [
  'TOEFL', 'IELTS', 'Duolingo English Test', 'PTE'
]

const initialState = {
  tuitionFee: [0, 100],
  searchedUniversity: [],
  searchedCourse: [],
  reading: null,
  listening: null,
  writing: null,
  speaking: null,
  overall: null,
  selectedCourse: {},
  chooseStudentDrawer: false,
  search: '',
  eligibile: {},
  viewAllLoad: false
}

const universityField = {
  university: [],
  country: [],
  state: [],
  universityType: [],
  applicationFee: [0, 500]
}

let defaultCourseLength = 3

const CourseInnerList = ({ echCou, item, courseEvents, showCommission }) => {
  let [visibleInfo, setVisibleInfo] = useState(false)
  let imagePath = item._id.logo && item._id.logo.url ? item._id.logo.url : defaultLogoList.defaultUniversityLogo
  const selectStudent = (echCou, item) => {
    let obj = {
      courseUniversity: item._id._id,
      universityCountry: item.universityCountry._id,
      universityState: item.universityState._id,
      universityCity: item.universityCity._id,
      ...echCou
    }
    courseEvents.selectStudent(obj)
  }

  return (
    <div className="box">
      <div className={'courseBox'}>
        <span>
          <img src={imagePath} alt=""/>
        </span>
        <h5>{echCou.courseName}</h5>
        <div className={'courseFeeInfo'}>
          <p>
            tuition fee {' '}
            {echCou.tuitionFee ? `${item.universityCountry.currency ? item.universityCountry.currency : ''} ${echCou.tuitionFee}` :
              'Free'}
          </p>
          {visibleInfo ? <React.Fragment>
            <p>Application fee {' '}
              {item._id && item._id.applicationFee ? `${item.universityCountry.currency ? item.universityCountry.currency : ''} ${item._id.applicationFee}` :
                'Free'}
            </p>
            {showCommission ?
              <p>Commission {' '}
                {item._id && item._id.commission ? `${item.universityCountry.currency ? item.universityCountry.currency : ''}  ${item._id.commission}` :
                  'Free'}
              </p> : null}
          </React.Fragment> : null}
        </div>
      </div>
      {visibleInfo ? <a onClick={() => setVisibleInfo(false)}>know less</a> :
        <a onClick={() => setVisibleInfo(true)}>know more</a>}

      <br/>
      <div className={'selectStudentBox'}>
        <button className={'btn apply selectStudentBtn'} onClick={() => selectStudent(echCou, item)}>
          <Icon type={'plus'}/> Select Student
        </button>
      </div>

      {/*<Button size={'small'} onClick={() => selectStudent(echCou, item)}>SELECT STUDENT</Button>*/}
    </div>
  )
}

const CourseFlatList = (searchedCourse, state, courseEvents, showCommission) => {
  const [localState, setState] = useState({ visibleCourseCount: defaultCourseLength })

  return (
    <div className={'courseBox courseList-box'}>
      {searchedCourse && searchedCourse.length ? searchedCourse.map((item, key) => {
          let cloneCourse = _.clone(item.courses)
          if (!!item.hideCourse) {
            cloneCourse.splice(defaultCourseLength)
          }
          let imagePath = item._id.logo && item._id.logo.url ? item._id.logo.url : defaultLogoList.defaultUniversityLogo
          return (
            <div className="academy-box filter-box">
              <div className="heading-form d-flex align-items-center mb-3">
                <h5><b>{item._id && item._id.universityName ? item._id.universityName : ''},</b>

                  {item.universityState && item.universityState.stateName ? item.universityState.stateName : null},
                  {item.universityCountry && item.universityCountry.countryName ? item.universityCountry.countryName : ''}
                </h5>


                {item.moreDataAvaliable ?
                  <React.Fragment>
                    {item.courses && item.courses.length && item.courses.length < item.totalCourse ?
                      <React.Fragment>
                        <button className={'btn ml-auto'} onClick={() => courseEvents.loadMoreCourse(item)}>
                          LOAD {item.totalCourse - item.courses.length} MORE PROGRAMS
                        </button>
                      </React.Fragment> : null}

                    {item.courses.length == item.totalCourse ?
                      <React.Fragment>
                        {item.hideCourse &&
                        <button className={'btn ml-auto'} onClick={() => courseEvents.seeMore(item._id)}>
                          SEE {item.totalCourse - defaultCourseLength} MORE PROGRAMS
                        </button>}
                        {!item.hideCourse &&
                        <button className={'btn ml-auto'} onClick={() => courseEvents.hideMore(item._id)}>SEE LESS
                        </button>}
                      </React.Fragment> : null}
                  </React.Fragment> : null}

              </div>
              <div className="card">
                <div className="row">
                  {cloneCourse && cloneCourse.length && cloneCourse.map((echCou, echKey) => {
                    return (
                      <div key={echKey} className="col-lg-4">
                        <CourseInnerList echCou={echCou} item={item} courseEvents={courseEvents}
                                         showCommission={showCommission}/>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          )
        }) :
        <React.Fragment>
          {state.viewAllLoad ?
            <Empty description={'No courses found.'}/> :
            <div style={{ position: 'relative', fontSize: 30, textAlign: 'center', marginTop: 100 }}>
              Loading...
            </div>}
        </React.Fragment>
      }
    </div>
  )
}

const UniversityFlatList = (searchedUniversity, state) => {
  return (
    <div className={'universityBox mb10'}>
      <Row gutter={16}>
        {searchedUniversity && searchedUniversity.length ? searchedUniversity.map((item, key) => {
          let randomValue = (Math.random() * 5)
          randomValue = parseInt(randomValue)
          let imagePath = item.logo && item.logo.url ? item.logo.url : defaultLogoList.defaultUniversityLogo
          return (
            <Col span={12} key={key}>
              <Card key={key}>
                <div className={'uniInfo'}>
                  <LazyLoadImage src={imagePath}/>
                  <div className={'uniName'}>
                    <a>{item.universityName}</a>
                    <div className={'uniAdd'}>
                      {item.universityCountry && item.universityCountry.countryName ?
                        <span>
                            {item.universityCountry.countryName}
                          </span> : null}
                      {item.universityState && item.universityState.stateName ?
                        <span>,
                          {item.universityState.stateName}
                          </span> : null}
                    </div>
                  </div>
                </div>
              </Card>
            </Col>
          )
        }) : null}

      </Row>
    </div>
  )
}

const SearchCourse = (props) => {
  const { form: { getFieldDecorator, setFieldsValue, getFieldValue, resetFields } } = props
  const dispatch = useDispatch()
  const [state, setState] = useState(initialState)
  const [uState, setUState] = useState(universityField)
  const [allCountries, setAllCountries] = useState([])
  const [allStates, setAllStates] = useState([])
  const [quickSearchUniversities, setQuickSearchUniversities] = useState([])
  const [indiaStates, setIndiaStates] = useState([])
  const [showCommission, setShowCommission] = useState(false)

  const { loader } = useSelector(state => ({
    loader: state.stateReducers.loader
  }))


  const IndiaStates = async () => {
    let listOfState = await LoadState(101)
    setIndiaStates(listOfState)
  }


  const updateRangeField = (name, value) => {
    setState({
      ...state,
      [name]: value
    })
  }

  const updateAppRangeField = (name, value) => {
    setUState({
      ...uState,
      [name]: value
    })
  }

  let eligibilityFilter = {
    fields: [
      {
        key: 'eligibleState',
        label: 'States',
        type: 'select',
        showSearch: true,
        options: indiaStates,
        allowClear: true,
        keyAccessor: x => x.name,
        valueAccessor: x => `${x.name}`,
        onChange: x => {
          props.form.setFieldsValue({
            eligibleState: x
          })
        }
      },
      {
        key: 'educationLevel', label: 'Education Level', type: 'select',
        options: qualificationOptions,
        allowClear: true,
        keyAccessor: x => x,
        valueAccessor: x => `${x}`,
        onChange: x => {
          props.form.setFieldsValue({
            educationLevel: x
          })

        }
      },
      { key: 'percent', label: 'Percent', type: 'number', placeholder: 'Percent', allowClear: true },
      {
        key: 'testStatus',
        label: 'Did you give any test?',
        type: 'radioGroup',
        options: ['Yes', 'No'],
        onChange: x => {
          props.form.setFieldsValue({
            testStatus: x.target.value
          })
        }
      },
      {
        key: 'examType', label: 'English Exam Type', type: 'select',
        options: EnglishExamTypes,
        hidden: !(getFieldValue('testStatus') && getFieldValue('testStatus') == 'Yes'),
        keyAccessor: x => x,
        valueAccessor: x => `${x}`,
        onChange: x => {
          props.form.setFieldsValue({
            examType: x
          })
          setState({
            ...state,
            reading: null,
            listening: null,
            writing: null,
            speaking: null,
            overall: null
          })
        }
      }
    ]
  }

  let programFilter = {
    fields: [
      {
        key: 'programLevel',
        label: 'Program Level',
        showSearch: true,
        type: 'select',
        mode: 'multiple',
        options: ProgramLevel,
        onChange: (programLevel) => {
          props.form.setFieldsValue({ programLevel })
        }
      },
      {
        key: 'intakes',
        label: 'Intakes',
        type: 'select',
        mode: 'multiple',
        options: Intakes,
        keyAccessor: x => x,
        valueAccessor: x => `${x}`,
        onChange: x => {
          props.form.setFieldsValue({
            intakes: x
          })

        }
      }
    ]
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 0 },
      sm: { span: 0 },
      md: { span: 0 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }

  const clearFilter = () => {
    let dInitialState = {
      tuitionFee: [0, 100],
      reading: null,
      listening: null,
      writing: null,
      speaking: null,
      overall: null,
      search: ''
    }

    let dUniversityField = {
      university: [],
      country: [],
      state: [],
      universityType: [],
      applicationFee: [0, 500]
    }

    setState({
      ...state,
      ...dInitialState
    })
    setUState({
      ...uState,
      ...dUniversityField
    })
    setShowCommission(false)
    setTimeout(() => {
      setFieldsValue({
        programLevel: [],
        intakes: [],
        eligibleState: '',
        educationLevel: '',
        percent: '',
        testStatus: '',
        examType: ''
      })
    }, 500)
  }

  useEffect(() => {
    IndiaStates()

    courseEvents.loadAllCourse()
  }, [])

  useEffect(() => {
    loadCountry()

  }, [state])

  useEffect(() => {
    loadState()
    events.loadQuickUniversity()
  }, [state.country])


  const loadCountry = async () => {
    let countryFilter = {
      results: 10
    }
    let { data } = await dispatch(listAllCountries(countryFilter))
    setAllCountries(data)
  }

  const loadState = async () => {
    let filter = {
      results: 1000000
    }
    if (state.country) {
      filter.country = state.country
    }
    let { data } = await dispatch(listAllStates(filter))
    setAllStates(data)
  }

  const setLocalState = (data) => {
    return new Promise((resolve) => {
      setState({
        ...state,
        ...data
      })
      resolve(state)
    })
  }

  useEffect(() => {
    events.loadQuickUniversity()
  }, [uState])


  const events = {
    setLocalUState: (data) => {
      setUState({
        ...uState,
        ...data
      })
    },
    setLocalState: (data) => {
      setState({
        ...state,
        ...data
      })
    },
    chooseCountry: (x) => {
      events.setLocalUState({
        country: x,
        state: undefined
      })
    },
    chooseState: (x) => {
      events.setLocalUState({
        state: x
      })
    },
    chooseUniversityType: async (x) => {
      events.setLocalUState({
        universityType: x
      })
    },
    chooseUniversity: (x) => {
      events.setLocalUState({
        university: x
      })

    },
    eligibleFilter: async () => {
      let filter = {
        results: 1000000,
        search: state.search,
        universityName: state.university,
        universityCountry: state.country,
        universityState: state.state,
        regExFilters: ['universityName'],
        universityType: state.universityType,
        eligibleState: getFieldValue('eligibleState'),
        educationLevel: getFieldValue('educationLevel'),
        percent: getFieldValue('percent'),
        examType: getFieldValue('examType'),
        reading: state.reading && state.reading >= 0 ? state.reading : undefined,
        listening: state.listening && state.listening >= 0 ? state.listening : undefined,
        writing: state.writing && state.writing >= 0 ? state.writing : undefined,
        speaking: state.speaking && state.speaking >= 0 ? state.speaking : undefined,
        overall: state.overall && state.overall >= 0 ? state.overall : undefined,
        programLevel: getFieldValue('programLevel'),
        intakes: getFieldValue('intakes'),
        tuitionFee: state.tuitionFee,
        applicationFee: state.applicationFee

      }

      let couResp = await listQuickSearchCourse(filter)(dispatch)
      setState({
        ...state,
        searchedCourse: couResp,
        viewAllLoad: true
      })
    },
    applyFilter: async () => {
      let filter = {
        results: 1000000,
        search: state.search,
        universityName: uState.university,
        universityCountry: uState.country,
        universityState: uState.state,
        regExFilters: ['universityName'],
        universityType: uState.universityType,
        eligibleState: getFieldValue('eligibleState'),
        educationLevel: getFieldValue('educationLevel'),
        percent: getFieldValue('percent'),
        examType: getFieldValue('examType'),
        reading: state.reading && state.reading >= 0 ? state.reading : undefined,
        listening: state.listening && state.listening >= 0 ? state.listening : undefined,
        writing: state.writing && state.writing >= 0 ? state.writing : undefined,
        speaking: state.speaking && state.speaking >= 0 ? state.speaking : undefined,
        overall: state.overall && state.overall >= 0 ? state.overall : undefined,
        programLevel: getFieldValue('programLevel'),
        intakes: getFieldValue('intakes'),
        tuitionFee: state.tuitionFee,
        applicationFee: uState.applicationFee

      }

      let couResp = await listQuickSearchCourse(filter)(dispatch)
      setState({
        ...state,
        searchedCourse: couResp,
        viewAllLoad: true
      })
    },
    searchCourse: async () => {
      let couResp = await listQuickSearchCourse({ search: state.search })(dispatch)
      setState({
        ...state,
        searchedCourse: couResp
      })
    },
    loadQuickUniversity: async () => {
      let filter = {
        results: 1000000,
        // universityName: uState.university,
        universityCountry: uState.country,
        universityState: uState.state,
        regExFilters: ['universityName'],
        universityType: uState.universityType
        /*
        applicationFee: uState.applicationFee*/
      }
      let uniResp = await dispatch(listQuickSearchUniversities(filter))
      /* setState({
         ...state,
         searchedUniversity: uniResp
       })*/
      setQuickSearchUniversities(uniResp)
    }
  }

  const courseEvents = {
    loadMoreCourse: async (university) => {
      let filter = {
        results: 1000000,
        search: state.search,
        universityName: state.university,
        universityCountry: state.country,
        universityState: state.state,
        regExFilters: ['universityName'],
        universityType: state.universityType,
        universityId: university._id._id,
        skip: university.courses.length,

        eligibleState: getFieldValue('eligibleState'),
        educationLevel: getFieldValue('educationLevel'),
        percent: getFieldValue('percent'),
        examType: getFieldValue('examType'),
        reading: state.reading && state.reading >= 0 ? state.reading : undefined,
        listening: state.listening && state.listening >= 0 ? state.listening : undefined,
        writing: state.writing && state.writing >= 0 ? state.writing : undefined,
        speaking: state.speaking && state.speaking >= 0 ? state.speaking : undefined,
        overall: state.overall && state.overall >= 0 ? state.overall : undefined,
        programLevel: getFieldValue('programLevel'),
        intakes: getFieldValue('intakes'),
        tuitionFee: state.tuitionFee,
        applicationFee: state.applicationFee

      }
      let couResp = await listQuickSearchMoreCourse(filter)(dispatch)
      let cloneCourse = _.clone(state.searchedCourse)
      let findUni = _.find(cloneCourse, (item) => {
        return item._id._id == university._id._id
      })
      if (findUni) {
        findUni.courses = [...findUni.courses, ...couResp]
        findUni.hideCourse = false
        setState({
          ...state,
          searchedCourse: cloneCourse
        })
      }
    },
    seeMore: (university) => {
      let cloneCourse = _.clone(state.searchedCourse)
      let findUni = _.find(cloneCourse, (item) => {
        return item._id._id == university._id
      })
      if (findUni) {
        findUni.hideCourse = false
        setState({
          ...state,
          searchedCourse: cloneCourse
        })
      }

    },
    hideMore: (university) => {
      let cloneCourse = _.clone(state.searchedCourse)
      let findUni = _.find(cloneCourse, (item) => {
        return item._id._id == university._id
      })
      if (findUni) {
        findUni.hideCourse = true
        setState({
          ...state,
          searchedCourse: cloneCourse
        })
      }
    },
    selectStudent: (courseData) => {
      events.setLocalState({
        selectedCourse: courseData,
        chooseStudentDrawer: true,
        eligibile: {
          eligibleState: getFieldValue('eligibleState'),
          educationLevel: getFieldValue('educationLevel'),
          percent: getFieldValue('percent'),
          examType: getFieldValue('examType'),
          reading: state.reading && state.reading >= 0 ? state.reading : undefined,
          listening: state.listening && state.listening >= 0 ? state.listening : undefined,
          writing: state.writing && state.writing >= 0 ? state.writing : undefined,
          speaking: state.speaking && state.speaking >= 0 ? state.speaking : undefined,
          overall: state.overall && state.overall >= 0 ? state.overall : undefined
        }
      })
    },
    closeStudentDrawer: () => {
      events.setLocalState({
        selectedCourse: {},
        chooseStudentDrawer: false,
        eligibile: {}
      })
    },
    loadAllCourse: async () => {
      let couResp = await listQuickSearchCourse({})(dispatch)
      // let uniResp = await listQuickSearchUniversities({})(dispatch)
      setState({
        ...state,
        searchedCourse: couResp,
        // searchedUniversity: uniResp,
        viewAllLoad: true
      })
    }
  }


  const marks = {
    1: {
      label: <strong>1k</strong>
    },
    100: {
      label: <strong>100k</strong>
    }
  }
  const formatter = (value) => {
    return `${value}K`
  }
  const AppFormatter = (value) => {
    return `${value}`
  }

  const appMark = {
    0: '0',
    500: '500'
  }

  return (
    <div>
      <div className="mt-4">

        <div className="evaluate-section">

          <div className="banner">
            <div className="row">
              <div className="col-lg-6">
                <div className="content">
                  <h2>Your Gateway to an <br/> <span>International Career</span></h2>
                  {/* <div className="search-bar">
                    <img src="/dist/img/search.png" alt=""/><input type="text"
                                                                   placeholder="Search College or University..."/>
                  </div>*/}
                </div>
              </div>
              <div className="col-lg-4 mr-auto alignRight">
                <div className="img">
                  <img src="/dist/img/gateway-banner.jpg" alt=""/>
                </div>
              </div>
            </div>
          </div>

          <div className="evaluate-head mt-4">
            <div className="row">
              <div className="col-lg-6">
                <div className="search-box">
                  <div className="box">
                    <img src="/dist/img/search.png" alt=""/>
                    <input type="text" placeholder="What would you like to study?" value={state.search}
                           onChange={(e) => events.setLocalState({ search: e.target.value })}/>
                  </div>
                  <button type="button" className="btn mob-view" onClick={events.searchCourse}><img
                    src="/dist/img/search-white.svg" alt=""/></button>
                  {/*<button type="button" className="btn desk-view" onClick={events.searchCourse}>search</button>*/}

                </div>
              </div>
              <div className="col-lg-4 ml-auto col-sm-6">
                <div className="switch-box">
                  <ul>
                    <li><input type="radio" name="pro" checked/><span>program</span></li>
                    {/*<li><input type="radio" name="pro"/><span>university / college</span></li>*/}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4">
              <div className="filter-box">
                <div className="accordion" id="accordionExample">
                  <div className="card">
                    <div className="card-header" id="headingOne">
                      <h2 className="mb-0">
                        <button className="btn btn-link d-flex" type="button" data-toggle="collapse"
                                data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                          eligibility <img src="/dist/img/arrow-down-white.svg" alt="" className="ml-auto"/>
                        </button>
                      </h2>
                    </div>

                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne"
                         data-parent="#accordionExample">
                      <div className="card card-body">

                        <GetAllFormFields
                          inputSchema={eligibilityFilter}
                          formItemLayout={formItemLayout}
                          getFieldDecorator={getFieldDecorator}
                        />
                        {props.form.getFieldValue('examType') && props.form.getFieldValue('examType') !== 'I don\'t have this' ?
                          <div>
                            <div className={'englishTypeBox'}>
                              <InputBox title={'Overall'}>
                                <Input onChange={(e) => events.setLocalState({ overall: e.target.value })}
                                       className={'form-control'}
                                       value={state.overall}/>
                              </InputBox>
                              <InputBox title={'Reading'}>
                                <Input onChange={(e) => events.setLocalState({ reading: e.target.value })}
                                       className={'form-control'}
                                       value={state.reading}/>
                              </InputBox>
                              <InputBox title={'Listening'}>
                                <Input onChange={(e) => events.setLocalState({ listening: e.target.value })}
                                       className={'form-control'}
                                       value={state.listening}/>
                              </InputBox>
                              <InputBox title={'Speaking'}>
                                <Input onChange={(e) => events.setLocalState({ speaking: e.target.value })}
                                       className={'form-control'}
                                       value={state.speaking}/>
                              </InputBox>
                              <InputBox title={'Writing'}>
                                <Input onChange={(e) => events.setLocalState({ writing: e.target.value })}
                                       className={'form-control'}
                                       value={state.writing}/>
                              </InputBox>
                            </div>

                          </div> : null}


                        <div className="d-flex">
                          <div className="ml-auto">
                            <button className="btn apply" type="submit" onClick={events.eligibleFilter}>apply</button>
                          </div>
                        </div>

                      </div>
                    </div>

                    <div className="card-header" id="headingTwo">
                      <h2 className="mb-0">
                        <button className="btn btn-link d-flex" type="button" data-toggle="collapse"
                                data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                          university filter <img src="/dist/img/arrow-down-white.svg" alt="" className="ml-auto"/>
                        </button>
                      </h2>
                    </div>

                    <div id="collapseTwo" className="collapse show" aria-labelledby="headingTwo"
                         data-parent="#accordionExample">
                      <div className="card card-body">
                        <InputBox title={'Countries'}>
                          <Select showSearch={true} mode={'multiple'} value={uState.country} filterOption={FilterOption}
                                  allowClear={true}
                                  placeholder={'Countries'} onChange={events.chooseCountry}>
                            {allCountries && allCountries.length ? allCountries.map((item, index) => {
                              return (
                                <Option key={item._id} value={item._id}>{item.countryName}</Option>
                              )
                            }) : null}
                          </Select>
                        </InputBox>

                        <InputBox title={'States'}>
                          <Select showSearch={true} placeholder={'States'} allowClear={true}
                                  mode={'multiple'} value={uState.state} filterOption={FilterOption}
                                  onChange={events.chooseState}>
                            {allStates && allStates.length ? allStates.map((item, index) => {
                              return (
                                <Option key={item._id} value={item._id}>{item.stateName}</Option>
                              )
                            }) : null}
                          </Select>
                        </InputBox>

                        <InputBox title={'University Type'} className={'uniTypeCheckbox'}>
                          <Checkbox.Group options={UniversityTypes} onChange={events.chooseUniversityType}
                                          value={uState.universityType}/>
                        </InputBox>

                        <InputBox title={'Universities'}>
                          <Select showSearch={true} placeholder={'Universities'} allowClear={true}
                                  mode={'multiple'} value={uState.university}
                                  filterOption={FilterOption}
                                  onChange={events.chooseUniversity}>
                            {quickSearchUniversities && quickSearchUniversities.length ? quickSearchUniversities.map((item, index) => {
                              return (
                                <Option key={item._id} value={item._id}>{item.universityName}</Option>
                              )
                            }) : null}
                          </Select>
                        </InputBox>

                        <div className="d-flex">
                          <div className="ml-auto">
                            <button className="btn apply" type="submit" onClick={events.applyFilter}>apply</button>
                          </div>
                        </div>

                      </div>
                    </div>


                    <div className="card-header" id="headingThree">
                      <h2 className="mb-0">
                        <button className="btn btn-link d-flex" type="button" data-toggle="collapse"
                                data-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                          program filter <img src="/dist/img/arrow-down-white.svg" alt="" className="ml-auto"/>
                        </button>
                      </h2>
                    </div>

                    <div id="collapseThree" className="collapse show" aria-labelledby="headingThree"
                         data-parent="#accordionExample">
                      <div className="card card-body">

                        <GetAllFormFields
                          inputSchema={programFilter}
                          formItemLayout={formItemLayout}
                          getFieldDecorator={getFieldDecorator}
                        />
                        <InputBox title={''} className={'mt10 mb10'}>
                          <Checkbox
                            checked={showCommission}
                            onChange={() => {
                              setShowCommission(!showCommission)
                            }} value={showCommission}>
                            Show Commission</Checkbox>
                        </InputBox>


                        <div>
                          <label>
                            Tuition Fee
                          </label>
                          <Slider range defaultValue={state.tuitionFee} value={state.tuitionFee}
                                  tipFormatter={formatter} min={0} max={100}
                                  marks={marks}
                                  onChange={(value) => {
                                    updateRangeField('tuitionFee', value)
                                  }}/>
                        </div>

                        <div className={'mt20'}>
                          <label>
                            Application Fee
                          </label>
                          <Slider range defaultValue={uState.applicationFee} value={uState.applicationFee}
                                  min={0} max={500} tipFormatter={AppFormatter}
                                  marks={appMark}
                                  onChange={(value) => {
                                    updateAppRangeField('applicationFee', value)
                                  }}/>
                        </div>

                        <div className="d-flex">
                          <div className="ml-auto">
                            <button className="btn clear" type="button" onClick={clearFilter}>clear</button>
                            <button className="btn apply" type="submit" onClick={events.applyFilter}>apply</button>
                          </div>
                        </div>

                      </div>
                    </div>


                  </div>

                </div>
              </div>
            </div>
            <div className="col-lg-8">
              {CourseFlatList(state.searchedCourse, state, courseEvents, showCommission)}
            </div>
          </div>

        </div>


      </div>

      {state.chooseStudentDrawer ? <AddApplications
        visible={state.chooseStudentDrawer}
        selectedCourse={state.selectedCourse}
        eligibile={state.eligibile}
        closeStudentDrawer={courseEvents.closeStudentDrawer}/> : null}
    </div>
  )
}


const WrappedSearchCourse = Form.create()(SearchCourse)
export default WrappedSearchCourse

