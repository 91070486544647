import { combineReducers } from 'redux'
import counter from './counter'
import theme from './theme'
import global from './global'
import countryReducers from '../containers/countries/reducers/countries'
import addCourse from '../containers/course/reducers/courseReducers'
import branchReducers from '../containers/branch/reducers/branchReducers'
import universityReducers from '../containers/university/reducers/university'
import stateReducers from '../containers/state/reducers/state'
import cityReducers from '../containers/city/reducers/city'
import campusReducers from '../containers/campus/reducers/campus'
import studentReducers from '../containers/student/reducers/student'
import dashboardReducers from '../containers/dashboard/reducers/dashboardReducers'
import chooseApplication from '../containers/applications/reducers/chooseStudent'
import pendencyReducers from '../containers/pendency/reducers/pendency'
import sampleDocumentReducers from '../containers/sampleDocument/reducers/sampleDocument'
import feedbackReducers from '../containers/feedback/reducers/feedbackReducers'
import privateMessageReducers from '../containers/privateMessage/reducers/privateMessage'
import companyReducers from '../containers/company/reducers/company'
import scheduleMeetingReducers from '../containers/scheduleMetting/reducers/scheduleMeeting'
import NewsReducers from '../containers/news/reducers/news'
import EvaluateReducers from '../containers/evaluateProfile/reducers'
import ReceptionReducers from '../containers/ReceptionDashboard/reducers'

import { connectRouter } from 'connected-react-router'
import { createBrowserHistory as createHistory } from 'history'

export const history = createHistory()

export default combineReducers({
  counter,
  theme,
  global,
  countryReducers,
  universityReducers,
  stateReducers,
  cityReducers,
  addCourse,
  branchReducers,
  campusReducers,
  studentReducers,
  dashboardReducers,
  chooseApplication,
  pendencyReducers,
  sampleDocumentReducers,
  feedbackReducers,
  privateMessageReducers,
  companyReducers,
  scheduleMeetingReducers,
  NewsReducers,
  EvaluateReducers,
  ReceptionReducers,
  router: connectRouter(history)

})
