import { apiUrl } from '../../../settings'

export const getDashboardCountryWise = () => {
  return apiUrl + '/dashboard/countryWise'
}

export const getDashboardUserRecord = () => {
  return apiUrl + '/dashboard/userCount'
}

export const getDashboardUniversityCountUrl = () => {
  return apiUrl + '/dashboard/universityCount'
}

export const getDashboardUCountryCountUrl = () => {
  return apiUrl + '/dashboard/countryCount'
}

export const getUniversityPieChartCountUrl = () => {
  return apiUrl + '/dashboard/universityPieChartCount'
}
export const getDashboardAustraliaOnShoreCount = () => {
  return apiUrl + '/dashboard/australiaOnShore'
}

export const showUpdatePasswordApi = () => {
  return apiUrl + '/api/showUpdatePassword'
}
export const getDashboardCanadaOnShoreCountUrl = () => {
  return apiUrl + '/dashboard/canadaOnShore'
}
export const getUserTargetUrl = () => {
  return apiUrl + '/getUserTarget'
}
export const studentCountYearWiseUrl = () => {
    return apiUrl + '/studentCountYearWise'
}
export const studentRecordCountAgentWiseUrl = () => {
    return apiUrl + '/studentRecordCountAgentWise'
}
export const footFallRecordForBarChartUrl = () => {
    return apiUrl + '/footFallRecordForBarChart'
}
export const marketingUserForAgentsUrl = () => {
    return apiUrl + '/api/marketingUserForAgent'
}
