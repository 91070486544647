import { notification } from 'antd'
import { hidePageLoad, showPageLoad } from '../../../modules/actions'
import { customAxios as axios, getToken } from '../../../request'
import {
  addExpenseWithdrawalUrl,
  addWithdrawalSendOtpUrl,
  addWithdrawalUrl,
  listWithdrawalUrl,
  sendExpenseWithdrawalOtpUrl,
  updateWithdrawalUrl
} from '../api/withdrawalApi'

export const addWithdrawalSendOtpFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(
    addWithdrawalSendOtpUrl(),
    valData,
    getToken()
  )
  dispatch(hidePageLoad())

  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
export const sendExpenseWithdrawalOtp = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(
    sendExpenseWithdrawalOtpUrl(),
    valData,
    getToken()
  )
  dispatch(hidePageLoad())

  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
export const addWithdrawalFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(addWithdrawalUrl(), valData, getToken())
  dispatch(hidePageLoad())

  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
export const addExpenseWithdrawalFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(
    addExpenseWithdrawalUrl(),
    valData,
    getToken()
  )
  dispatch(hidePageLoad())

  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const listWithdrawalFxn = filters => async dispatch => {
  // dispatch(showPageLoad())
  let config = {
    params: { ...filters },
    ...(await getToken())
  }
  let { data } = await axios.get(listWithdrawalUrl(), config)
  dispatch(hidePageLoad())

  return data.data
}
export const updateWithdrawalFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(updateWithdrawalUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
