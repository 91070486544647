import {notification} from 'antd'
import {hidePageLoad, showPageLoad} from '../../../modules/actions'
import {customAxios as axios, getToken} from '../../../request'
import {
    footFallRecordForBarChartUrl,
    getDashboardAustraliaOnShoreCount,
    getDashboardCanadaOnShoreCountUrl,
    getDashboardCountryWise,
    getDashboardUCountryCountUrl,
    getDashboardUniversityCountUrl,
    getDashboardUserRecord,
    getUniversityPieChartCountUrl,
    getUserTargetUrl, marketingUserForAgentsUrl,
    showUpdatePasswordApi,
    studentCountYearWiseUrl,
    studentRecordCountAgentWiseUrl
} from '../api/dashboardApi'

export const countryWiseDashboard = valData => async dispatch => {
    let {data} = await axios.post(
        getDashboardCountryWise(),
        valData,
        getToken()
    )
    // dispatch({ type: 'DASHBOARD_COUNTRY_WISE_DATA', payload: data })
    return data
}

export const dashboardUserRecord = async valData => {
    let {data} = await axios.post(getDashboardUserRecord(), valData, getToken())
    return data
}

export const dashboardUniversityCount = valData => async dispatch => {
    let {data} = await axios.post(
        getDashboardUniversityCountUrl(),
        valData,
        getToken()
    )
    return data
}

export const dashboardCountryCount = valData => async dispatch => {
    let {data} = await axios.post(
        getDashboardUCountryCountUrl(),
        valData,
        getToken()
    )
    return data
}
export const universityPieChartCount = valData => async dispatch => {
    let {data} = await axios.post(
        getUniversityPieChartCountUrl(),
        valData,
        getToken()
    )
    return data
}
export const dashboardAustraliaOnShoreFxn = async valData => {
    let {data} = await axios.post(
        getDashboardAustraliaOnShoreCount(),
        valData,
        getToken()
    )
    return data
}

export const showUpdatePasswordFxn = async () => {
    let {data} = await axios.post(showUpdatePasswordApi(), null, getToken())
    return data
}

export const dashboardCanadaOnShoreFxn = async valData => {
    let {data} = await axios.post(
        getDashboardCanadaOnShoreCountUrl(),
        valData,
        getToken()
    )
    return data
}
export const getUserTargetFxn = async valData => {
    let {data} = await axios.post(getUserTargetUrl(), valData, getToken())
   /* if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }*/
    return data
}
export const studentCountYearWiseFxn = async valData => {
    let {data} = await axios.post(
        studentCountYearWiseUrl(),
        valData,
        getToken()
    )
    return data
}

export const studentRecordCountAgentWiseFxn = async () => {
    let {data} = await axios.post(
        studentRecordCountAgentWiseUrl(),
        {},
        getToken()
    )
    return data
}

export const footFallRecordForBarChartFxn = valData => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(
        footFallRecordForBarChartUrl(),
        valData,
        getToken()
    )
    dispatch(hidePageLoad())
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}

export const marketingUserForAgentsFxn = (valData) => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(marketingUserForAgentsUrl(), valData, getToken())
    dispatch(hidePageLoad())
    return data
}
