import { log } from '@craco/craco/lib/logger'
import { Button, Col, Drawer, Form, Input, notification, Row } from 'antd'
import _ from 'lodash'
import moment from 'moment'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import { DocumentTypes, InputBox } from '../../../components/_utils/appUtils'
import { RowTable } from '../../../components/_utils/RowTable'
import { UploadSignedVisaFxn } from '../actions/chooseStudent'
const DocumentList = ['signedVisaDraft']
const SignedVisaLetter = props => {
  let dispatch = useDispatch()
  let {
    visible,
    onClose,
    applicationId,
    studentId,
    reloadTable,
  } = props
  let [state, setState] = useState([])
  let [signedVisaDraft, setSignedVisaDraft] = useState({})
  const events = {
    chooseDocument: e => {
      let {name, files} = e.target
      if (files && files.length) {
        setSignedVisaDraft(files[0])
      }
    },
    handleSubmit: async () => {
      let fd = new FormData()
      let obj = {
        studentId,
        applicationId
      }

      if (!signedVisaDraft || (signedVisaDraft && !signedVisaDraft.name)) {
        return notification.warn({message: 'Please choose Signed VisaLetter'})
      }

      fd.append('obj', JSON.stringify(obj))
      fd.append('signedVisaDraft', signedVisaDraft)

      let data = await dispatch(UploadSignedVisaFxn(fd))
      if (data && data.success) {
        onClose()
        reloadTable()
        setSignedVisaDraft({})
      }
    }
  }
  return (
    <Drawer
        visible={visible}
        title={'Upload Signed Visa Draft'}
        onClose={onClose}
        width={'40%'}>
      <div className="row">
        <div className="col-lg-12">
          <Form className={'vertical-form'} autoComplete="off">
            <div className="form-box">
              <div className="card unizportal">
                {/* {studentData &&
                studentData.applications &&
                studentData.applications.id ? (
                  <div className={'tableBox striped'}>
                    <RowTable
                      title={'Name (ID)'}
                      value={
                        <span>
                          {studentData.name} ({studentData.studentId})
                        </span>
                      }
                    />
                    <RowTable
                      title={'Application ID'}
                      value={<span>{studentData.applications.id}</span>}
                    />
                    <RowTable
                      title={'Application'}
                      value={<span>{studentData.applications.courseName}</span>}
                    />
                  </div>
                ) : null}
                <br /> */}

                <div className="inner-form">
                  <Row gutter={24} key={state.uploadKey}>
                    <Col span={24}>
                      <InputBox title={`Signed Visa Draft Document`}>
                        <Input
                          type={'file'}
                          name={'signedVisaDraft'}
                          id={'signedVisaDraft'}
                          className={'form-control'}
                          onChange={e => {
                            events.chooseDocument(e)
                          }}
                        />
                      </InputBox>
                    </Col>
                  </Row>

                  <button
                    className="btn"
                    type="submit"
                    onClick={events.handleSubmit}>
                    save
                  </button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </Drawer>
  )
}
export default SignedVisaLetter
