import {
    Button,
    Col,
    DatePicker,
    Icon, notification,
    Row,
    Select,
    Tooltip,
} from 'antd'
import moment from 'moment'
import React, {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {TableComp} from 'sz-react-utils-lite'
import {
    Intakes,
    filterOption,
    newFormatDisplayDate,
    oxfordUniversity, oxfordInterviewStatusList, departmentObj, longDisplayDate,
} from '../../../../components/_utils/appUtils'
import {getPushPathWrapper, getUrlPushWrapper} from '../../../../routes'
import {
    CheckBranchUserFxn,
} from '../../../WebComponent/allowComponentRightsWise'
import {listAllCampus} from '../../../campus/actions/campus'
import {
    getStudentOxFordCourseFxn,
} from '../../actions/chooseStudent'
import CopyOFDraft from '../../drawers/copyOfDraft'
import OxfordUpdateStudent from "./drawers/oxfordUpdateStudent";
import UpdateScheduleDrawer from "./drawers/updateScheduleDrawer";
import {listAllUsers} from "../../../users/actions/user";
import {StudentMarketingUserComponent} from "../../../../components/_utils/AgentBranchManagerComponent";
import AddNote from "../../drawers/applicationAddNoteComponent"
import UpdateScheduleChooseDate from "./drawers/updateScheduleChooseDate";

const {RangePicker} = DatePicker
const {Option} = Select
const initialState = {
    applicationList: [],
    applicationObj: {}
}

let viewOnlyRight = CheckBranchUserFxn() // check branch user right

const RenderComment = props => {
    let {item} = props
    let [hideV, setHideV] = useState(true)
    return (
        item && (
            <div>
                {item.addedByUserId && item.addedByUserId.name ? (
                    <div className={'alignRight colorPrimary font12'}>
                        (Added By : {item.addedByUserId.name} )
                    </div>
                ) : null}
                <div className={hideV ? 'appCommentDotsReplace' : ''}>
                    {item && item.text ? item.text : ''}
                </div>
                {item && item.text.length && item.text.length > 50 ? (
                    <div className={'alignRight'}>
                        {hideV ? (
                            <a
                                onClick={() => {
                                    setHideV(false)
                                }}>
                                Show More
                            </a>
                        ) : (
                            <a
                                onClick={() => {
                                    setHideV(true)
                                }}>
                                Show Less
                            </a>
                        )}
                    </div>
                ) : (
                    ''
                )}
            </div>
        )
    )
}
const OfferActionComponent = props => {
    let {file, record, events, user} = props;
    let {applications} = record
    return (
        <div>
            {file && file.path ? (
                <>
                    <Tooltip title={'Download Offer Letter'}>
                        <a download={file.fileName} href={file.path}>
                            <img src={'/assets/icons/cloud-computing-download.png'}/>
                        </a>
                    </Tooltip>


                </>

            ) : (
                ''
            )}

        </div>
    )
}
const LoaRequestActionComponent = props => {
    let {record, val, events, user} = props
    let {userType: loginUserType, email} = user
    let {applications} = record
    return (
        <div>
            {viewOnlyRight ? (
                <>
                    {record.applications &&
                    record.applications.status == 'Offer Received' ? (
                        <Tooltip title={'Copy of Draft'}>
                            <a onClick={() => events.openLoaDrawer(record, false)}>
                                <img src={'/assets/icons/cloud-computing-upload.png'}/>
                            </a>
                        </Tooltip>
                    ) : null}
                </>
            ) : null}

            {val ? (
                <>
                    <Tooltip title={val}>
                        <a>
                            <img
                                src={`${
                                    val == 'Pending'
                                        ? '/assets/icons/pendingCheck.png'
                                        : '/assets/icons/doneCheck.png'
                                }`}
                                className={'sm'}
                            />
                        </a>
                    </Tooltip>

                </>
            ) : null}
        </div>
    )
}

const OxfordInterviewApplicationsComponent = props => {
    const [state, setState] = useState(initialState)
    const [name, setName] = useState('')
    const [agentId, setAgentId] = useState('')
    const [visibleAddCommentDrawer, setVisibleAddCommentDrawer] = useState(false)
    const [totalApplication, setTotalApplication] = useState(0)
    const [currentStatus, setCurrentStatus] = useState('')
    const [status, setStatus] = useState('')
    let {applicationObj} = state
    let [tableSearch, setTableSearch] = useState(false)
    let [filters, setFilters] = useState({})
    let [campusList, setCampusList] = useState([])
    const [campusId, setCampusId] = useState('')
    const [branchUserId, setBranchUserId] = useState('')
    const [intake, setIntake] = useState('')
    let [courseName, setCourseName] = useState('')
    let [studentShore, setStudentShore] = useState('')
    const [studentData, setStudentData] = useState({})
    let [id, setId] = useState('')
    let [marketingUserId, setMarketingUserId] = useState('')
    const [toDate, setToDate] = useState('')
    const [fromDate, setFromDate] = useState('')
    let [visibleLoaReq, setVisibleLoaReq] = useState(false)
    let [isEditAble, setIsEditAble] = useState(false);
    const [oxfordInterviewStatus, setOxfordInterviewStatus] = useState('')
    let [marketingUserList, setMarketingUserList] = useState([])
    const [allAgent, setAllAgent] = useState([])

    let [updateStudent, setUpdateStudent] = useState({
        student: {},
        visible: false
    })
    let [scheduleState, setScheduleState] = useState({
        student: {},
        visible: false
    })

    const dispatch = useDispatch()
    let user = localStorage.getItem('user')
        ? JSON.parse(localStorage.getItem('user'))
        : null
    let {userType: loginUserType, email, branchManagerType} = user
    const tableAppRef = useRef()

    let apiRequest = params => {
        return new Promise(async resolve => {
            let resp = await getParamsForApplicationList()
            if (resp && resp.id) {
                resp['applications.id'] = [resp.id]
                delete resp['id']
            }
            params = {...params, ...resp}
            params.appUniversityId = oxfordUniversity
            if (!params.results) {
                params.results = 50
                params.count = 50
            }
            if (tableSearch) {
                params.page = 1
            }
            try {
                setFilters(params)
                let resp = await dispatch(getStudentOxFordCourseFxn({...params}))
                setTotalApplication(resp.total)
                setTableSearch(false)
                resolve(resp)
            } catch (e) {
            }
        })
    }

    useEffect(() => {
        loadCampusList()
        setFieldByParams()
        loadFcmtCourse()
        loadMarketingUsers()
        loadAgentData()

        // loadCurrentUserData()
    }, [])

    const loadMarketingUsers = async () => {
        let obj = {
            userType: 'branchUser',
            department: departmentObj.marketing,
            results: 100,
            select: ['name', 'email', 'mobile', 'agents', 'assignStateCountry']
        }
        let {data} = await dispatch(listAllUsers(obj))
        setMarketingUserList(data)
    }
    const loadAgentData = async () => {
        let params = {
            results: 15000,
            userType: 'agent',
            sortField: 'companyName',
            sortOrder: 'ascend',
            select: ['name', 'companyName']
        }
        let {data} = await dispatch(listAllUsers(params))
        if (data && data.length) {
            setAllAgent(data)
        }
    }

    const setFieldByParams = async () => {
        let resp = await getParamsForApplicationList()

        if (resp.campusId) {
            setCampusId(resp.campusId)
        }
        if (resp.status) {
            setStatus(resp.status)
        }
        if (resp.oxfordInterviewStatus) {
            setOxfordInterviewStatus(resp.oxfordInterviewStatus)
        }
        if (resp.agentId) {
            setAgentId(resp.agentId)
        }
        if (resp.intake) {
            setIntake(resp.intake)
        }
        if (resp.courseName) {
            setCourseName(resp.courseName)
        }
        if (resp.studentShore) {
            setStudentShore(resp.studentShore)
        }
        if (resp.branchUserId) {
            setBranchUserId(resp.branchUserId)
        }
        if (resp.id) {
            setId(resp.id)
        }
        if (resp.marketingUserId) {
            setMarketingUserId(resp.marketingUserId)
        }
        if (resp.toDate) {
            setToDate(moment(resp.toDate, 'MM-DD-YYYY'))
        }
        if (resp.fromDate) {
            setFromDate(moment(resp.fromDate, 'MM-DD-YYYY'))
        }
    }


    const loadCampusList = async () => {
        let params = {}
        params.sortField = 'campusName'
        params.sortOrder = 'ascend'
        params.campusUniversity = oxfordUniversity
        params.results = 50
        params.count = 50
        params.select = ['campusName']
        let {data} = await dispatch(
            listAllCampus({
                ...params,
                regExFilters: ['campusAddress', 'campusName']
            })
        )
        setCampusList(data)
    }

    const loadFcmtCourse = async () => {
        let params = {}
        params.select = ['campusName']
        let {data} = await dispatch(
            listAllCampus({
                ...params,
                regExFilters: ['campusAddress', 'campusName']
            })
        )
    }

    const events = {
        reloadFxn: () => {
            tableAppRef.current.reload()
        },
        refreshApplication: () => {
            tableAppRef.current.reload()
        },
        enterName: value => {
            setName(value)
        },

        openLoaDrawer: (record, editVal) => {
            setStudentData(record)
            setVisibleLoaReq(true)
            setIsEditAble(editVal)
        },
        closeLoaDrawer: () => {
            setStudentData({})
            setVisibleLoaReq(false)
        },
        showUpdateStudent: (record) => {
            setUpdateStudent({
                student: record,
                visible: true
            })
        },
        hideUpdateStudent: () => {
            setUpdateStudent({
                student: {},
                visible: false
            })
        },
        openAddCommentDrawer: value => {
            setState({
                ...state,
                applicationObj: value
            })
            setVisibleAddCommentDrawer(true)
        },
        closeAddCommentDrawer: () => {
            setState({
                ...state,
                applicationObj: {}
            })
            setVisibleAddCommentDrawer(false)
        },
        submitAddCommentDrawer: () => {
            setState({
                ...state,
                applicationObj: {}
            })
            setVisibleAddCommentDrawer(false)
            events.reloadFxn()
        },

        showUpdateSchedule: (record) => {
            if (!record.whatsappNumber && !record.skypeId) {
                notification.warning({message: "Please update student Whatsapp No & Skype ID"})
                setTimeout(() => {
                    events.showUpdateStudent(record);
                }, 500)
                return
            }
            setScheduleState({
                student: record,
                visible: true
            })
        },
        hideUpdateSchedule: () => {
            setScheduleState({
                student: {},
                visible: false
            })
        },
    }

    const getParamsForApplicationList = () => {
        return new Promise(resolve => {
            let searchParams = new URLSearchParams(window.location.search)
            let appUniversityId = searchParams.get('appUniversityId')
            let name = searchParams.get('name')
            let campusId = searchParams.get('campusId')
            let status = searchParams.get('status')
            let oxfordInterviewStatus = searchParams.get('oxfordInterviewStatus')
            let agentId = searchParams.get('agentId')
            let branchUserId = searchParams.get('branchUserId')
            let intake = searchParams.get('intake')
            let courseName = searchParams.get('courseName')
            let studentShore = searchParams.get('studentShore')
            let marketingUserId = searchParams.get('marketingUserId')
            let id = searchParams.get('id')
            let fromDate = searchParams.get('fromDate')
            let toDate = searchParams.get('toDate')
            let obj = {}

            if (appUniversityId) {
                obj.appUniversityId = appUniversityId
            }
            if (name) {
                obj.name = name
            }
            if (id) {
                obj.id = id
            }

            if (campusId) {
                obj.campusId = campusId
            }
            if (status) {
                obj.status = status
            }
            if (oxfordInterviewStatus) {
                obj.oxfordInterviewStatus = oxfordInterviewStatus
            }
            if (agentId) {
                obj.agentId = agentId
            }
            if (intake) {
                obj.intake = intake
            }
            if (courseName) {
                obj.courseName = courseName
            }
            if (studentShore) {
                obj.studentShore = studentShore
            }

            if (branchUserId) {
                obj.branchUserId = branchUserId
            }
            if (marketingUserId) {
                obj.marketingUserId = marketingUserId
            }
            if (fromDate) {
                obj.fromDate = fromDate
            }
            if (toDate) {
                obj.toDate = toDate
            }

            resolve(obj)
        })
    }

    const searchFxn = async () => {
        let resp = await getParamsForApplicationList()
        let obj = {}
        if (name) {
            obj.name = name
        }
        if (campusId) {
            obj.campusId = campusId
        }
        if (status) {
            obj.status = status
        }
        if (oxfordInterviewStatus) {
            obj.oxfordInterviewStatus = oxfordInterviewStatus
        }
        if (agentId) {
            obj.agentId = agentId
        }
        if (intake) {
            obj.intake = intake
        }
        if (courseName) {
            obj.courseName = courseName
        }
        if (studentShore) {
            obj.studentShore = studentShore
        }
        if (branchUserId) {
            obj.branchUserId = branchUserId
        }
        if (marketingUserId) {
            obj.marketingUserId = marketingUserId
        }

        if (id) {
            obj['applications.id'] = id
        }
        if (toDate) {
            obj.toDate = moment(toDate).format('MM-DD-YYYY')
        }
        if (fromDate) {
            obj.fromDate = moment(fromDate).format('MM-DD-YYYY')
        }
        dispatch(
            getUrlPushWrapper('oxfordInterviewApplications', {
                ...obj
            })
        )
        setTableSearch(true)
        setTimeout(() => {
            if (tableAppRef && tableAppRef.current) {
                tableAppRef.current.reload()
            }
        }, 200)
    }
    const clearFxn = async () => {
        let resp = await getParamsForApplicationList()
        let obj = {}
        setName('')
        setCampusId('')
        setStatus('')
        setOxfordInterviewStatus('')
        setAgentId('')
        setIntake('')
        setCourseName('')
        setStudentShore('')
        setBranchUserId('')
        setMarketingUserId('')
        setId('')
        setToDate('')
        setFromDate('')
        dispatch(
            getUrlPushWrapper('oxfordInterviewApplications', {
                ...obj
            })
        )
        setTableSearch(true)
        setTimeout(() => {
            tableAppRef.current.reload()
        }, 200)
    }

    const columns = [
        {
            title: 'App#',
            key: 'applications.id',
            dataIndex: 'applications.id',
            // searchTextName: 'applications.id',
            width: 60,
            render: item => {
                return <div style={{width: 60}}>{item}</div>
            }
        },
        {
            title: 'Name',
            key: 'name',
            dataIndex: 'name',
            width: 120,
            render: (item, record) => {
                return (
                    <div style={{width: 120}}>
                        <Tooltip title={'Edit Student'}>
                            <a
                                className={'linkAA'}
                                onClick={() =>
                                    dispatch(
                                        getPushPathWrapper('student.editStudent', {
                                            id: record._id
                                        })
                                    )
                                }>
                                {item} <Icon type={'edit'}/>
                            </a>
                        </Tooltip>
                        <br/>
                        {newFormatDisplayDate(record.dateOfBirth)}
                        <label className={'label label-tag'}>
                            {record.studentShore ? `(${record.studentShore})` : null}
                        </label>
                    </div>
                )
            }
        },
        {
            title: 'Program',
            key: 'applications.courseName',
            dataIndex: 'applications.courseName',
            width: 150,
            render: item => {
                return <div style={{width: 150}}>{item}</div>
            }
        },
        {
            title: 'Intake',
            key: 'applications.intake',
            dataIndex: 'applications.intake',
            width: 80,
            render: (item, record) => {
                return (
                    <div style={{width: 80}}>
                        {item && item.month && item.year
                            ? `${item.month}, ${item.year}`
                            : ''}
                    </div>
                )
            }
        },
        {
            title: 'Campus',
            key: 'applications.campusId',
            dataIndex: 'applications.campusId',
            width: 85,
            render: (item, record) => {
                return (
                    <div style={{width: 85}}>
                        {item && item.campusName ? item.campusName : ''}
                    </div>
                )
            }
        },

        {
            title: 'Current User',
            key: 'applications.assignedUserId',
            dataIndex: 'applications.assignedUserId',
            width: 100,
            render: (val, record) => {
                let {agentId, statusList, status} = record.applications
                return (
                    <div style={{width: 100}}>
                        {val && val.name ? val.name : ''}
                        <br/>
                        <div>
                            Agent :{' '}
                            {agentId && agentId.companyName ? agentId.companyName : ''}
                            <br/>
                            {agentId && agentId.mobile ? (
                                <>
                                    {' '}
                                    <Icon
                                        type={'phone'}
                                        style={{
                                            position: 'relative',
                                            bottom: 3
                                        }}
                                    />{' '}
                                    {agentId.mobile}
                                </>
                            ) : (
                                ''
                            )}
                        </div>
                        <div>{longDisplayDate(statusList.date)}</div>
                    </div>
                )
            }
        },
        {
            title: 'Family / Given Name',
            key: 'applications.familyName',
            dataIndex: 'applications.familyName',
            width: 100,
            render: (item, record) => {
                return (
                    <div style={{width: 100}}>
                        {item && item ? (
                            <p>
                                Family Name : <p style={{fontWeight: 'bold'}}>{item}</p>
                            </p>
                        ) : (
                            ''
                        )}
                        {record && record.applications && record.applications.givenName ? (
                            <p>
                                Given Name :{' '}
                                <p style={{fontWeight: 'bold'}}>
                                    {record.applications.givenName}
                                </p>
                            </p>
                        ) : (
                            ''
                        )}
                        {record &&
                        record.applications &&
                        record.applications.loaRequested === 'Approved' ? (
                            <Icon
                                type={'edit'}
                                onClick={() => events.changeNameOpen(record)}
                            />
                        ) : null}
                    </div>
                )
            }
        },

        {
            title: 'Conditional Offer Letter',
            key: 'applications.offerReceiveLetter',
            dataIndex: 'applications.offerReceiveLetter',
            width: 100,
            render: (val, record) => {
                return (
                    <div style={{width: 100}} className={'fcmtDiv'}>
                        <OfferActionComponent file={val} record={record} events={events} user={user}/>
                    </div>
                )
            }
        },
        {
            title: 'Copy Of Draft',
            key: 'sendToNextUser',
            dataIndex: 'sendToNextUser',
            width: 100,
            filters: [{value: true, text: 'Show Pending send for Approval'}],
            render: (val, record) => {
                let {applications} = record
                let {loaRequested} = applications
                return (
                    <div style={{width: 100}} className={'fcmtDiv'}>
                        <LoaRequestActionComponent
                            val={loaRequested}
                            record={record}
                            events={events}
                            user={user}
                        />
                    </div>
                )
            }
        },


        {
            title: 'Interview Details',
            key: 'applications.oxfordInterviewStatus',
            dataIndex: 'applications.oxfordInterviewStatus',
            width: 150,
            render: (val, record) => {
                let {oxfordInterviewDate} = record.applications;
                return (
                    <>

                        {oxfordInterviewDate ? <>
                            <div style={{width: 150}}>
                                {val == 'Pending' ? <label className={'label label-warning'}>{val}</label> : ""}
                                {val == 'Pass' ? <label className={'label label-success'}>{val}</label> : ""}
                                {val == 'Fail' ? <label className={'label label-danger'}>{val}</label> : ""}
                                {val == 'Reappear' ? <label className={'label label-black'}>{val}</label> : ""}
                            </div>
                            <div className={'mt10'}>
                                Date : {moment(oxfordInterviewDate).format('dddd, DD MMMM YYYY')}
                            </div>

                        </> : null}
                    </>
                )
            }
        },

        {
            title: 'Marketing',
            dataIndex: 'marketingUser',
            width: 120,
            key: 'marketingUser',
            render: (item, record) => {
                let {agentId, universityCountry} = record.applications
                return (
                    <React.Fragment>
                        {marketingUserList && marketingUserList.length ? (
                            <StudentMarketingUserComponent
                                userId={agentId._id}
                                countryId={universityCountry._id}
                                branchManagers={marketingUserList}
                            />
                        ) : null}
                    </React.Fragment>
                )
            }
        },
        {
            title: 'Latest Note',
            key: 'applications.latestComment',
            dataIndex: 'applications.latestComment',
            width: 150,
            render: val => {
                return (
                    <div style={{width: 150}}>
                        {val && val.text ? <RenderComment item={val}/> : null}
                    </div>
                )
            }
        },
        {
            title: 'Action',
            key: 'action',
            dataIndex: 'action',
            width: 140,
            render: (val, record) => {
                let {oxfordInterviewStatus, oxfordInterviewDate} = record.applications;

                return (
                    <div className={'actionBtnGroup'}>
                        <Tooltip title={'View Application'}>
                            <a
                                className="btn"
                                href={`/student/application?studentId=${
                                    record._id
                                }&appId=${record.applications._id}`}>
                                <img src="../dist/img/view.svg" alt=""/>
                            </a>
                        </Tooltip>

                        <Tooltip title={'Add Note'}>
                            <button
                                className="btn"
                                onClick={() => {
                                    events.openAddCommentDrawer(record)
                                }}>
                                <Icon type={'plus'}/>
                            </button>
                        </Tooltip>

                        <Tooltip title={'Update Student'}>
                            <a className="btn" onClick={() => {
                                events.showUpdateStudent(record)
                            }}>
                                <img src="../dist/icons/user-profile.png" alt="" style={{height: 20}}/>
                            </a>
                        </Tooltip>

                        {(oxfordInterviewStatus !== 'Pass' && oxfordInterviewStatus !== 'Fail') ?
                            <Tooltip title={'Schedule Interview'}>
                                <a className="btn" onClick={() => {
                                    events.showUpdateSchedule(record)
                                }}>
                                    <img src="../dist/icons/scheduler.png" alt="" style={{height: 20}}/>
                                </a>
                            </Tooltip> : null}
                    </div>
                )
            }
        }
    ]

    const resp = useSelector(state => {
        let {loadNoteRedux} = state.chooseApplication
        if (loadNoteRedux) {
            if (tableAppRef && tableAppRef.current) {
                tableAppRef.current.reload()
            }
            dispatch({type: 'END_LOAD_NOTE'})
        }
    })


    const extra = (
        <div className={'mt10'}>
            <Row gutter={16} className={'filterBox'}>
                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className="search-box-table round">
                        <input
                            className="form-control form-control"
                            type="search"
                            placeholder="Search by name..."
                            value={name}
                            onChange={e => {
                                events.enterName(e.target.value)
                            }}
                        />
                        <img src="/dist/img/search.png" alt=""/>
                    </div>
                </Col>
                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className="sort-box-table custom-sort-box-new">
                        <Select
                            name="Status"
                            onChange={item => setCampusId(item)}
                            className={'antSelect'}
                            placeholder={'Campus'}
                            allowClear={true}
                            value={campusId || undefined}>
                            {campusList && campusList.length
                                ? campusList.map((item, key) => {
                                    return (
                                        <Option value={item._id} key={key}>
                                            {item.campusName}
                                        </Option>
                                    )
                                })
                                : null}
                        </Select>
                    </div>
                </Col>
                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className="sort-box-table custom-sort-box-new">
                        <Select
                            name="Intake"
                            onChange={item => setIntake(item)}
                            filterOption={filterOption}
                            className={'antSelect'}
                            allowClear={true}
                            showSearch={true}
                            placeholder={'Intake'}
                            value={intake || undefined}>
                            {Intakes && Intakes.length
                                ? Intakes.map((item, key) => {
                                    return (
                                        <Option value={item} key={key}>
                                            {item}
                                        </Option>
                                    )
                                })
                                : null}
                        </Select>
                    </div>
                </Col>
                <Col span={4}>
                    <div className="sort-box-table custom-sort-box-new">
                        <div className="search-box-table round">
                            <input
                                className="form-control form-control"
                                type="search"
                                placeholder="course name..."
                                value={courseName}
                                onChange={e => {
                                    setCourseName(e.target.value)
                                }}
                            />
                            <img src="/dist/img/search.png" alt=""/>
                        </div>
                    </div>
                </Col>
                <Col span={4}>
                    <div className="sort-box-table custom-sort-box-new">
                        <Select
                            name="Interview Status"
                            onChange={item => setOxfordInterviewStatus(item)}
                            filterOption={filterOption}
                            className={'antSelect'}
                            placeholder={'Interview Status'}
                            allowClear={true}
                            value={oxfordInterviewStatus || undefined}>
                            {oxfordInterviewStatusList && oxfordInterviewStatusList.length
                                ? oxfordInterviewStatusList.map((item, key) => {
                                    return (
                                        <Option value={item} key={key}>
                                            {item}
                                        </Option>
                                    )
                                })
                                : null}
                        </Select>
                    </div>
                </Col>
                <Col md={4} sm={4} xs={12} lg={4} key={fromDate}>
                    <RangePicker
                        defaultValue={[fromDate, toDate]}
                        onChange={val => {
                            setFromDate(val[0])
                            setToDate(val[1])
                        }}
                    />
                </Col>
            </Row>

            <Row gutter={16} className={'filterBox'}>
                <Col md={4} sm={4} xs={8} lg={4}>
                    <Select
                        name="Search By Agent"
                        filterOption={filterOption}
                        className={'antSelect'}
                        allowClear={true}
                        placeholder={'Agent'}
                        showSearch={true}
                        onChange={item => setAgentId(item)}
                        value={agentId || undefined}>
                        {allAgent && allAgent.length
                            ? allAgent.map((item, key) => {
                                return (
                                    <Option value={item._id} key={key}>
                                        {item.companyName}
                                    </Option>
                                )
                            })
                            : null}
                    </Select>
                </Col>
                <Col md={4} sm={4} xs={8} lg={4}>
                    <Select
                        name="Search By Marketing User"
                        filterOption={filterOption}
                        className={'antSelect'}
                        allowClear={true}
                        placeholder={'Marketing User'}
                        onChange={item => setMarketingUserId(item)}
                        value={marketingUserId || undefined}>
                        {marketingUserList && marketingUserList.length
                            ? marketingUserList.map((item, key) => {
                                return (
                                    <Option value={item._id} key={key}>
                                        {item.name}
                                    </Option>
                                )
                            })
                            : null}
                    </Select>
                </Col>
                <Col md={4} sm={4} xs={12} lg={4} className={'ml10'}>
                    <Button onClick={() => searchFxn()} className={'roundBtn'}>
                        Search
                    </Button>
                    <Button onClick={() => clearFxn()} className={'roundBtn'}>
                        Clear
                    </Button>
                </Col>
            </Row>
        </div>
    )

    return (
        <React.Fragment>

            <div>
                <div className="row mt-4">
                    <div className="col-lg-12">
                        <div className="card">
                            <div
                                className="table-head d-flex align-items-center all-student-search"
                                style={{height: 60}}>
                                <Button
                                    onClick={() => dispatch(getUrlPushWrapper('dashboard'))}
                                    className={'roundGoBackBtn'}
                                    icon={'arrow-left'}>
                                    Back
                                </Button>
                                <h5>Oxford Interview Application </h5>
                                <div
                                    className="search-box-table"
                                    style={{backgroundColor: 'transparent'}}>
                                    {currentStatus} (Total - {totalApplication})
                                </div>
                                <div className="sort-box-table mark-btn">

                                </div>
                            </div>

                            <div className="card-body table-responsive">
                                {extra}
                                <TableComp
                                    columns={columns}
                                    rowKey={(item, index) => {
                                        return index
                                    }}
                                    apiRequest={apiRequest}
                                    pagination={{
                                        position: 'top',
                                        showSizeChanger: true,
                                        pageSizeOptions: ['50', '100', '200', '500'],
                                        defaultPageSize: 50,
                                        current: filters.page ? filters.page : 1
                                    }}
                                    ref={tableAppRef}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {visibleAddCommentDrawer && applicationObj && applicationObj._id ? (
                <AddNote
                    visible={visibleAddCommentDrawer}
                    studentData={applicationObj}
                    onClose={() => events.closeAddCommentDrawer()}
                    reloadFxn={() => events.reloadFxn()}
                    applicationId={applicationObj.applications._id}
                />
            ) : null}


            {visibleLoaReq &&
            studentData.applications &&
            studentData.applications._id ? (
                <CopyOFDraft
                    onClose={events.closeLoaDrawer}
                    reloadTable={events.reloadFxn}
                    editAble={isEditAble}
                    applicationId={studentData.applications._id}
                    studentId={studentData._id}
                    studentData={studentData}
                    visible={visibleLoaReq}
                />
            ) : null}

            {updateStudent.visible ?
                <OxfordUpdateStudent
                    visible={updateStudent.visible}
                    studentData={updateStudent.student}
                    onClose={events.hideUpdateStudent}
                    onSubmit={events.refreshApplication}
                />
                : null}

            {scheduleState.visible ?
                <>
                    {user && user.userType == 'userManager' ?
                        <UpdateScheduleChooseDate
                            visible={scheduleState.visible}
                            studentData={scheduleState.student}
                            onClose={events.hideUpdateSchedule}
                            onSubmit={() => {
                                events.hideUpdateSchedule()
                                events.reloadFxn()
                            }}
                        /> :
                        <UpdateScheduleDrawer
                            visible={scheduleState.visible}
                            studentData={scheduleState.student}
                            onClose={events.hideUpdateSchedule}
                            onSubmit={() => {
                                events.hideUpdateSchedule()
                                events.reloadFxn()
                            }}
                        />}

                </>
                : null}


        </React.Fragment>
    )
}
export default OxfordInterviewApplicationsComponent
