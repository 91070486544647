import React, {useEffect, useRef, useState} from 'react'
import {
    Select,
    Button,
    Tooltip,
    Icon,
    Popconfirm,
    Row,
    Col, notification, Form, Tag
} from 'antd'
import {connect, useDispatch, useSelector} from 'react-redux'
// import { exportApplicationsList, exportFcmtApplicationsList } from '../actions/chooseStudent'
import {
    newFormatDisplayDate,
    longDisplayDate,
    fcmtUniversity,
    filterOption,
    StudentTypesFilter,
    Intakes,
    statusColors,
    displayDate
} from '../../../components/_utils/appUtils'
import {getPushPathWrapper, getUrlPushWrapper} from '../../../routes'
import {TableComp} from 'sz-react-utils-lite'
import lodash from 'lodash'
import {
    AllowComponentRightsWise,
    CheckBranchUserFxn,
    CheckManagerWiseRight, ShowEnrollmentComponent
} from '../../WebComponent/allowComponentRightsWise'
import TransferApplicationDrawer from '../../applications/views/transferApplication'
import ApplyCourse from '../../student/views/applyForApplicationDrawer'
import {
    applicationCloseDirect,
    reCalculateCommission, updateApplicationTuitionFees, moveToDraftApplication
} from '../../student/actions/student'
import moment from 'moment'
import StudentOtherApp from '../../applications/views/StudentOtherApplication'
import {listAllUsers} from '../../users/actions/user'
import ReActivateApplication from '../../applications/drawers/reActivateApplicationDrawer'
import TransferToAstL1University from '../../applications/TempDrawers/TransferToAstL1University'
import {showEnrollmentAction, countryIds} from '../../../components/_utils/appUtils'
import Enrollment from '../../applications/drawers/enrollment'
import AddNote from '../../applications/drawers/createNoteComponent'
import LoaRequest from '../../applications/drawers/loaRequest'
import FcmtStatusComponent from '../../applications/drawers/fcmtStatus'
import ApprovedLoaRequest from '../../applications/drawers/approvedLoaRequest'
import ChangeNameDrawer from '../../applications/drawers/changeNameDrawer'
import UploadOfferDrawer from './uploadOfferDrawer'
import UploadReceiptDrawer from './UploadReceiptDrawer'
import UploadEcoeDrawer from './UploadEcoeDrawer'
import AustraliaCommissionDrawer from './commissionDrawer'
import AdvancePayDrawer from './AdvancePayDrawer'
import StatusComponent from './statusComponent'
import {
    ReCreateFcmtOfferLetter,
    ReCreateFcmtLoaLetter,
    ReCreateFcmtPaymentReceipt,
    fcmtSendMailFxn,
    fcmtSendOfferMailFxn,
    fcmtSendReceiptMailFxn,
    getStudentFcmtCourseFxn, getSelectedCountryApplicationsApi
} from '../../applications/actions/chooseStudent'
import {listAllCampus} from '../../campus/actions/campus'
import {CheckUserRight} from '../../dashboard/views/DashboardUserWise'
import AddCommissionDrawer from "../../universityCommission/drawers/addCommission";
import {uploadAppDocumentAndStatus} from "../actions";


/*const statusList = [
    'Offer Received',
    'Rqst LOA',
    'LOA Generated',
    'File pending for submission',
    'File Submitted',
    'Visa Approved',
    'Visa Rejected'
]*/
const statusList = [
    'Offer Received',
    'Fee Payment / Receipt',
    'ECOE Uploaded',
    'Commission',
]
const {Option} = Select
const initialState = {
    applicationList: [],
    applicationObj: {}
}
const commissionObj = {
    commissionAmount: 0,
    tuitionFee: 0,
    studentId: '',
    applicationId: ''
}
let viewOnlyRight = CheckBranchUserFxn() // check branch user right

const RenderComment = (props) => {
    let {item} = props
    let [hideV, setHideV] = useState(true)
    return (
        item && <div>
            {item.addedByUserId && item.addedByUserId.name ?
                <div className={'alignRight colorPrimary font12'}>
                    (Added By : {item.addedByUserId.name} )
                </div> : null}
            <div className={hideV ? 'appCommentDotsReplace' : ''}>
                {item && item.text ? item.text : ''}
            </div>
            {item && item.text.length && item.text.length > 50 ? <div className={'alignRight'}>
                {hideV ? <a onClick={() => {
                    setHideV(false)
                }}>Show More</a> : <a onClick={() => {
                    setHideV(true)
                }}>Show Less</a>}
            </div> : ''}

        </div>
    )
}
const OfferActionComponent = (props) => {
    let {file, reCreateOfferLetter, record, events} = props
    let {applications} = record
    return (
        <div>
            {file && file.path ?
                <Tooltip title={'Download Offer Letter'}>
                    <a download={file.fileName} href={file.path}>
                        <img src={'/assets/icons/cloud-computing-download.png'}/>
                    </a></Tooltip> : ''}


            <div className={'sprBox'}>
                {viewOnlyRight ? <>
                    <Popconfirm title={'Are your sure, you want to Re-Create Offer Letter?'}
                                onConfirm={() => events.reCreateOfferLetter(record)}
                                okText='Yes' cancelText='No'>
                        <a className={'bs-link bs-xs'}>Re-Create Offer Letter</a>
                    </Popconfirm>
                    <div>
                        <AllowComponentRightsWise rightUserType={['admin', 'branchManager', 'branchUser']}>
                            {applications.offerSent != false ?
                                null :
                                <Popconfirm title={'Are your sure, you want to Send Offer Letter?'}
                                            onConfirm={() => events.sendFcmtMail(record, 'offer')}
                                            okText='Yes' cancelText='No'>
                                    <button className={`bs_btn bs-default mt10 bs-xs`}>
                                        Send Offer
                                    </button>
                                </Popconfirm>
                            }
                        </AllowComponentRightsWise>
                    </div>
                </> : null}
                <div className={'mt10'}>
                    {applications && applications.offerSendDate ?
                        `Send On : ${newFormatDisplayDate(applications.offerReSendDate ? applications.offerReSendDate : applications.offerSendDate)}`
                        : null}
                </div>
            </div>


        </div>
    )
}
const LoaRequestActionComponent = (props) => {
    let {record, val, events} = props
    return (
        <div>
            {viewOnlyRight ? <>
                {record.applications && record.applications.status == 'Offer Received' ?
                    <Tooltip title={'Rqst LOA'}>
                        <a onClick={() => events.openLoaDrawer(record, false)}>
                            <img src={'/assets/icons/cloud-computing-upload.png'}/>
                        </a></Tooltip> : null}
            </> : null}


            {val ?
                <Tooltip title={val}><a>
                    <img src={`${val == 'Pending' ? '/assets/icons/pendingCheck.png' : '/assets/icons/doneCheck.png'}`}
                         className={'sm'}/>
                </a></Tooltip> : null}


            {viewOnlyRight ? <>
                {record.applications && record.applications.status !== 'Offer Received' ?
                    <div className={'mt10'}>
                        <a className={'bs-link bs-xs'} onClick={() => events.openLoaDrawer(record, true)}>Re-Upload
                            LOA</a>
                    </div>
                    : ''}

                <AllowComponentRightsWise rightUserType={['admin', 'branchManager', 'branchUser']}>
                    {val == 'Pending' ?
                        <button className={`bs_btn bs-default mt10 bs-xs`}
                                onClick={() => events.approveRequest(record)}>
                            Approve
                        </button>
                        : null}
                </AllowComponentRightsWise>
            </> : null}
        </div>
    )
}
const LoaActionComponent = (props) => {
    let {record, val, events} = props
    let {applications} = record
    return (
        val && val.path ?
            <>
                <Tooltip title={'Download LOA'}>
                    <a download={val.fileName} href={val.path}>
                        <img src={'/assets/icons/cloud-computing-download.png'}/>
                    </a></Tooltip>
                {viewOnlyRight ? <div className={'sprBox'}>
                    <AllowComponentRightsWise rightUserType={['admin', 'branchManager', 'branchUser']}>
                        <Popconfirm title={'Are your sure, you want to Re-Create LOA?'}
                                    onConfirm={() => events.reCreateLoaLetter(record)}
                                    okText='Yes' cancelText='No'>
                            <a className={'bs-link bs-xs'}>Re-Create LOA</a>
                        </Popconfirm>

                        {applications && applications.mailSent ?
                            null :
                            <Popconfirm title={'Are your sure, you want to Send LOA?'}
                                        onConfirm={() => events.sendFcmtMail(record, 'loa')}
                                        okText='Yes' cancelText='No'>
                                <button className={`bs_btn bs-default mt10 bs-xs`}>
                                    Send LOA
                                </button>
                            </Popconfirm>
                        }
                        <br/>
                    </AllowComponentRightsWise>
                    {applications && applications.mailDate ?
                        `Mail Date : ${newFormatDisplayDate(applications.mailReSendDate ? applications.mailReSendDate : applications.mailDate)}`
                        : null}
                </div> : null}
            </> : ''
    )
}
const ReceiptActionComponent = (props) => {
    let {record, val, events} = props
    let {applications} = record

    return (
        val && val.path ?
            <div>
                {viewOnlyRight ?
                    <AllowComponentRightsWise rightUserType={['admin', 'branchManager', 'branchUser']}>
                        <Tooltip title={'Download Receipt'}>
                            <a download={val.fileName} href={val.path}>
                                <img src={'/assets/icons/cloud-computing-download.png'}/>
                            </a></Tooltip>
                        <div className={'sprBox'}>
                            <Popconfirm title={'Are your sure, you want to Re-Create Receipt?'}
                                        onConfirm={() => events.reCreatePaymentReceipt(record)}
                                        okText='Yes' cancelText='No'>
                                <a className={'bs-link bs-xs'}>Re-Create Receipt</a>
                            </Popconfirm>

                            {applications && applications.receiptSent ?
                                null :
                                <Popconfirm title={'Are your sure, you want to Send Receipt?'}
                                            onConfirm={() => events.sendFcmtMail(record, 'receipt')}
                                            okText='Yes' cancelText='No'>
                                    <button className={`bs_btn bs-default mt10 bs-xs`}>
                                        Send Receipt
                                    </button>
                                </Popconfirm>
                            }
                        </div>
                    </AllowComponentRightsWise> : null}
                <AllowComponentRightsWise rightUserType={['agent', 'subAgent']}>
                    <div>
                        Receipt Generated
                    </div>
                </AllowComponentRightsWise>
                {applications && applications.receiptSendDate ?
                    applications.receiptReSendDate ? `Mail Date : ${newFormatDisplayDate(applications.receiptReSendDate)}` : `Mail Date : ${newFormatDisplayDate(applications.receiptSendDate)}`
                    : null}
            </div> : ''
    )
}

const AustraliaStudentApplication = (props) => {
    let {onClose, studentObj = {}, reloadTable} = props

    const [state, setState] = useState(initialState)
    const [transferAppVisible, setTransferAppVisible] = useState(false)
    const [studentData, setStudentData] = useState({})
    const [applicationData, setApplicationData] = useState({})
    const [visibleAddCourseDrawer, setVisibleAddCourseDrawer] = useState(false)
    const [name, setName] = useState('')
    const [dateOfBirth, setDateOfBirth] = useState('')
    const [allAgent, setAllAgent] = useState([])
    const [agentId, setAgentId] = useState('')
    const [visibleOtherAppDrawer, setVisibleOtherAppDrawer] = useState(false)
    const [visibleAddCommentDrawer, setVisibleAddCommentDrawer] = useState(false)
    const [totalApplication, setTotalApplication] = useState(0)
    const [visibleFeesModal, setVisibleFeesModal] = useState(false)
    const [feesObj, setFeesObj] = useState(commissionObj)
    const [currentStatus, setCurrentStatus] = useState('')
    const [status, setStatus] = useState('')
    let {applicationObj} = state
    let [tableSearch, setTableSearch] = useState(false)
    let [filters, setFilters] = useState({})
    let [visibleLoaReq, setVisibleLoaReq] = useState(false)
    let [visibleFcmtStatus, setVisibleFcmtStatus] = useState(false)
    let [visibleApprovalDrawer, setVisibleApprovalDrawer] = useState(false)
    let [visibleChangeNameDrawer, setVisibleChangeNameDrawer] = useState(false)
    let [isEditAble, setIsEditAble] = useState(false)
    let [campusList, setCampusList] = useState([])
    const [campusId, setCampusId] = useState('')
    const [branchUserList, setBranchUserList] = useState([])
    const [branchUserId, setBranchUserId] = useState('')
    const [intake, setIntake] = useState('')
    let [courseName, setCourseName] = useState('')

    let [visibleOfferUpload, setVisibleOfferUpload] = useState(false)
    let [visibleReceiptUpload, setVisibleReceiptUpload] = useState(false)
    let [visibleEcoeUpload, setVisibleEcoeUpload] = useState(false)
    let [visibleAusCommission, setVisibleAusCommission] = useState(false)
    let [visibleAdvancePayDrawer, setVisibleAdvancePayDrawer] = useState(false)
    let [visibleStatusComponent, setVisibleStatusComponent] = useState(false)


    let [selectedApplication, setSelectedApplication] = useState({
        visible: false,
        studentId: '',
        application: '',
        studentData: {}
    })
    let [enrollmentObj, setEnrollmentObj] = useState({
        visible: false,
        studentId: '',
        application: '',
        studentData: {}
    })
    let [changeUniversity, setChangeUniversity] = useState({
        visible: false,
        studentId: '',
        application: '',
        studentData: {}
    })
    const [visibleUniversityCommission, setVisibleUniversityCommission] = useState(false)

    const dispatch = useDispatch()
    let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
    const tableAppRef = useRef()

    let apiRequest = (params) => {
        return new Promise(async (resolve) => {
            let resp = await getParamsForApplicationList()
            params = {...params, ...resp}
            // if (!params.countryId) {
            //   params.countryId = ''
            // }
            // params.countryId = countryIds.australiaL1
            params.countryId = countryIds.australia
            params.australiaOnshore = true
            if (!params.results) {
                params.results = 50
                params.count = 50
            }
            if (tableSearch) {
                params.page = 1
            }
            // params.type = 'all'
            try {
                setFilters(params)
                let resp = await dispatch(getSelectedCountryApplicationsApi({...params, regExFilters: ['name']}))
                setTotalApplication(resp.total)
                setTableSearch(false)
                resolve(resp)
            } catch (e) {
            }

        })
    }


    let exportExcel = async () => {
        let params = {}
        let resp = await getParamsForApplicationList()
        params = {...params, ...resp}
        if (name) {
            params.name = name
        }
        if (dateOfBirth) {
            params.dateOfBirth = moment(dateOfBirth)
        }

        params.count = totalApplication
        // let { success, filePath, message } = await dispatch(exportFcmtApplicationsList(params))
        // if (success) {
        //   notification.success({ message: message })
        // } else {
        //   notification.error({ message: message })
        // }
    }

    useEffect(() => {
        loadAgentData()
        loadCampusList()
        setFieldByParams()
        loadFcmtCourse()
        // loadCurrentUserData()
    }, [])

    const setFieldByParams = async () => {
        let resp = await getParamsForApplicationList()
        // setCurrentStatus(resp.status)

        if (resp.campusId) {
            setCampusId(resp.campusId)
        }
        if (resp.status) {
            setStatus(resp.status)
        }
        if (resp.agentId) {
            setAgentId(resp.agentId)
        }
        if (resp.intake) {
            setIntake(resp.intake)
        }
        if (resp.courseName) {
            setCourseName(resp.courseName)
        }
        if (resp.branchUserId) {
            setBranchUserId(resp.branchUserId)
        }
    }

    const loadAgentData = async () => {
        let params = {
            results: 10000,
            userType: 'agent',
            sortField: 'companyName',
            sortOrder: 'ascend',
            select: ['name', 'companyName']
        }
        let {data} = await dispatch(listAllUsers(params))
        if (data && data.length) {
            setAllAgent(data)
        }
    }
    const loadCampusList = async () => {
        let params = {}
        params.sortField = 'campusName'
        params.sortOrder = 'ascend'
        params.campusUniversity = fcmtUniversity
        params.results = 50
        params.count = 50
        params.select = ['campusName']
        let {data} = await dispatch(listAllCampus({...params, regExFilters: ['campusAddress', 'campusName']}))
        setCampusList(data)
    }

    const loadFcmtCourse = async () => {
        let params = {}
        params.select = ['campusName']
        let {data} = await dispatch(listAllCampus({...params, regExFilters: ['campusAddress', 'campusName']}))
    }
    const loadCurrentUserData = async () => {
        let params = {
            results: 1000,
            userType: 'branchUser',
            sortField: 'name',
            sortOrder: 'ascend',
            select: ['name', 'userType', 'mobile', 'email'],
            customQuery: {
                $or: [{countryId: countryIds.canada}, {'department': 'Visa Approved Department'}]
            }
        }
        let {data} = await dispatch(listAllUsers(params))
        if (data && data.length) {
            setBranchUserList(data)
        }
    }

    const events = {

        reloadFxn: () => {
            tableAppRef.current.reload()
        },
        showTransferApp: () => {
            setTransferAppVisible(true)
        },
        hideTransferApp: () => {
            setTransferAppVisible(false)
            tableAppRef.current.reload()
        },
        showAddCourseDrawer: () => {
            setVisibleAddCourseDrawer(true)
        },
        hideAddCourseDrawer: () => {
            setVisibleAddCourseDrawer(false)
            tableAppRef.current.reload()
        },
        refreshApplication: () => {
            tableAppRef.current.reload()
        },

        closeDirectApp: async (record) => {
            let obj = {
                studentId: record._id,
                applicationId: record.applications._id
            }
            let resp = await dispatch(applicationCloseDirect(obj))
            tableAppRef.current.reload()
        },

        enterName: (value) => {
            setName(value)

        },
        moveToDraftApplicationFxn: async (record) => {
            let obj = {
                studentId: record._id,
                applicationId: record.applications._id
            }
            let resp = await dispatch(moveToDraftApplication(obj))
            tableAppRef.current.reload()
        },

        openOtherAppDrawer: (value) => {
            setState({
                ...state,
                applicationObj: value
            })
            setVisibleOtherAppDrawer(true)
        },
        closeOtherAppDrawer: () => {
            setState({
                ...state,
                applicationObj: {}
            })
            setVisibleOtherAppDrawer(false)
        },
        openAddCommentDrawer: (value) => {
            setState({
                ...state,
                applicationObj: value
            })
            setVisibleAddCommentDrawer(true)
        },
        closeAddCommentDrawer: () => {
            setState({
                ...state,
                applicationObj: {}
            })
            setVisibleAddCommentDrawer(false)
        },
        hideVisibleFeesModal: () => {
            setVisibleFeesModal(false)
        },
        setCommissionLocal: (data) => {
            setFeesObj({
                ...feesObj,
                ...data
            })
        },
        reCalculateCommissionFxn: async () => {
            let {commissionAmount} = await dispatch(reCalculateCommission(feesObj))
            if (commissionAmount) {
                events.setCommissionLocal({commissionAmount})
            }

        },
        updateApplicationTuitionFeesFxn: async () => {
            let {success, message} = await dispatch(updateApplicationTuitionFees(feesObj))
            if (success) {
                setFeesObj(commissionObj)
                setVisibleFeesModal(false)
                events.reloadFxn()
            }
        },
        sendFcmtMail: async (record, type) => {
            let obj = {
                studentId: record._id,
                applicationId: record.applications._id
            }
            if (type == 'receipt') {
                let {success, message} = await dispatch(fcmtSendReceiptMailFxn(obj))
                if (success) {
                    events.reloadFxn()
                }
            } else if (type == 'offer') {
                let {success, message} = await dispatch(fcmtSendOfferMailFxn(obj))
                if (success) {
                    events.reloadFxn()
                }
            } else {
                let {success, message} = await dispatch(fcmtSendMailFxn(obj))
                if (success) {
                    events.reloadFxn()
                }
            }

        },
        openLoaDrawer: (record, editVal) => {
            setStudentData(record)
            setVisibleLoaReq(true)
            setIsEditAble(editVal)
        },
        closeLoaDrawer: () => {
            setStudentData({})
            setVisibleLoaReq(false)
        },
        approveRequest: async (record) => {
            setStudentData(record)
            setVisibleApprovalDrawer(true)
        },
        closeApproveRequest: async (record) => {
            setStudentData({})
            setVisibleApprovalDrawer(false)
        },
        changeNameOpen: async (record) => {
            setStudentData(record)
            setVisibleChangeNameDrawer(true)
        },
        closeChangeName: async (record) => {
            setStudentData({})
            setVisibleChangeNameDrawer(false)
        },
        openFcmtStatusDrawer: (record) => {
            setStudentData(record)
            setVisibleFcmtStatus(true)
        },
        closeFcmtStatusDrawer: () => {
            setStudentData({})
            setVisibleFcmtStatus(false)
        },
        reCreateOfferLetter: async (record) => {
            let obj = {
                studentId: record._id,
                applicationId: record.applications._id
            }
            let resp = await dispatch(ReCreateFcmtOfferLetter(obj))
            if (resp && resp.success) {
                tableAppRef.current.reload()
            }
        },
        reCreateLoaLetter: async (record) => {
            let obj = {
                studentId: record._id,
                applicationId: record.applications._id
            }
            let resp = await dispatch(ReCreateFcmtLoaLetter(obj))
            if (resp && resp.success) {
                tableAppRef.current.reload()
            }
        },
        reCreatePaymentReceipt: async (record) => {
            let obj = {
                studentId: record._id,
                applicationId: record.applications._id
            }
            let resp = await dispatch(ReCreateFcmtPaymentReceipt(obj))
            if (resp && resp.success) {
                tableAppRef.current.reload()
            }
        },

        openOfferUploadDrawer: (record, editVal) => {
            setStudentData(record)
            setVisibleOfferUpload(true)
        },

        closeOfferUploadDrawer: () => {
            setStudentData({})
            setVisibleOfferUpload(false)
        },

        openReceiptUploadDrawer: (record, editVal) => {
            setStudentData(record)
            setVisibleReceiptUpload(true)
        },

        closeReceiptUploadDrawer: () => {
            setStudentData({})
            setVisibleOfferUpload(false)
        },

        openEcoeUploadDrawer: (record, editVal) => {
            setStudentData(record)
            setVisibleEcoeUpload(true)
        },

        closeEcoeUploadDrawer: () => {
            setStudentData({})
            setVisibleEcoeUpload(false)
        },
        openAusCommissionDrawer: (record, editVal) => {
            setStudentData(record)
            setVisibleAusCommission(true)
        },
        closeAusCommissionDrawer: () => {
            setStudentData({})
            setVisibleAusCommission(false)
        },
        openAdvancePayDrawer: (record, editVal) => {
            setStudentData(record)
            setVisibleAdvancePayDrawer(true)
        },

        closeAdvancePayDrawer: () => {
            setStudentData({})
            setVisibleAdvancePayDrawer(false)
        },

        showUniversityCommission: () => {
            setVisibleUniversityCommission(true)
        },
        hideUniversityCommission: () => {
            setVisibleUniversityCommission(false)
            tableAppRef.current.reload()
        },
    }

    const reActivateApp = (record) => {
        setSelectedApplication({
            visible: true,
            studentId: record._id,
            studentData: record,
            application: record.applications
        })
    }
    const onCloseReActivate = () => {
        setSelectedApplication({
            visible: false,
            studentId: '',
            studentData: {},
            application: {}
        })
        tableAppRef.current.reload()
    }

    const enrollmentApp = (record) => {
        setEnrollmentObj({
            visible: true,
            studentId: record._id,
            studentData: record,
            application: record.applications
        })
    }
    const onCloseEnrollmentApp = () => {
        setEnrollmentObj({
            visible: false,
            studentId: '',
            studentData: {},
            application: {}
        })
        tableAppRef.current.reload()
    }

    const changeUniversityFxn = (record) => {
        setChangeUniversity({
            visible: true,
            studentId: record._id,
            studentData: record,
            application: record.applications
        })
    }
    const onCloseChangeUniversityFxn = () => {
        setChangeUniversity({
            visible: false,
            studentId: '',
            studentData: {},
            application: {}
        })
        tableAppRef.current.reload()
    }

    const enrollmentBtn = (record) => {
        let application = record.applications
        /* return (
             !application.enrollment ? showEnrollmentAction(application.statusList) ?
                 <button className='btn' onClick={() => enrollmentApp(record)}>
                     <img src={'/assets/enrollment.png'}/>
                 </button> : null : null
         )*/
        return null
    }

    const getParamsForApplicationList = () => {
        return new Promise((resolve) => {
            let searchParams = new URLSearchParams(window.location.search)
            let appUniversityId = searchParams.get('appUniversityId')
            let name = searchParams.get('name')
            let campusId = searchParams.get('campusId')
            let status = searchParams.get('status')
            let agentId = searchParams.get('agentId')
            let branchUserId = searchParams.get('branchUserId')
            let intake = searchParams.get('intake')
            let courseName = searchParams.get('courseName')
            let obj = {}

            if (appUniversityId) {
                obj.appUniversityId = appUniversityId
            }
            if (name) {
                obj.name = name
            }

            if (campusId) {
                obj.campusId = campusId
            }
            if (status) {
                obj.status = status
            }
            if (agentId) {
                obj.agentId = agentId
            }
            if (intake) {
                obj.intake = intake
            }
            if (courseName) {
                obj.courseName = courseName
            }

            if (branchUserId) {
                obj.branchUserId = branchUserId
            }

            resolve(obj)
        })
    }

    const searchFxn = async () => {
        let resp = await getParamsForApplicationList()
        let obj = {}
        if (name) {
            obj.name = name
        }
        if (campusId) {
            obj.campusId = campusId
        }
        if (status) {
            obj.status = status
        }
        if (agentId) {
            obj.agentId = agentId
        }
        if (intake) {
            obj.intake = intake
        }
        if (courseName) {
            obj.courseName = courseName
        }
        if (branchUserId) {
            obj.branchUserId = branchUserId
        }
        dispatch(
            getUrlPushWrapper('australiaOnShoreList', {
                ...obj
            }))
        setTableSearch(true)
        setTimeout(() => {
            if (tableAppRef && tableAppRef.current) {
                tableAppRef.current.reload()
            }
        }, 200)
    }
    const clearFxn = async () => {
        let resp = await getParamsForApplicationList()
        let obj = {}
        setName('')
        setCampusId('')
        setStatus('')
        setAgentId('')
        setIntake('')
        setCourseName('')
        setBranchUserId('')
        dispatch(
            getUrlPushWrapper('australiaOnShoreList', {
                ...obj
            }))
        setTableSearch(true)
        setTimeout(() => {
            tableAppRef.current.reload()
        }, 200)
    }

    const handleCommissionStageSubmit = async (valData) => {
        let fd = new FormData()
        let obj = {
            _id: valData._id,
            status: 'Commission',
            studentId: valData._id,
            applicationId: valData.applications._id
        }
        fd.append('obj', JSON.stringify(obj))
        let data = await dispatch(uploadAppDocumentAndStatus(fd))
        if (data && data.success) {
            setVisibleStatusComponent(false)
            events.reloadFxn()
        }
    }


    const columns = [

        {
            title: 'App#',
            key: 'applications.id',
            dataIndex: 'applications.id',
            width: 60,
            render: (item) => {
                return (
                    <div style={{width: 60}}>
                        {item}
                    </div>
                )
            }
        },
        {
            title: 'Name',
            key: 'name',
            dataIndex: 'name',
            width: 120,
            render: (item, record) => {
                return (
                    <div style={{width: 120}}>
                        <Tooltip title={'Edit Student'}>
                            <a className={'linkAA'}
                               onClick={() => dispatch(
                                   getPushPathWrapper('student.editStudent', {id: record._id}))
                               }>
                                {item} <Icon type={'edit'}/>
                            </a>
                        </Tooltip>
                        <br/>
                        {newFormatDisplayDate(record.dateOfBirth)}
                    </div>
                )
            }
        },
        {
            title: 'Program',
            key: 'applications.courseName',
            dataIndex: 'applications.courseName',
            width: 150,
            render: (item) => {
                return (
                    <div style={{width: 150}}>
                        {item}
                    </div>
                )
            }
        },
        {
            title: 'Intake',
            key: 'applications.intake',
            dataIndex: 'applications.intake',
            width: 80,
            render: (item, record) => {
                return (
                    <div style={{width: 80}}>
                        {item && item.month && item.year ? `${item.month}, ${item.year}` : ''}
                    </div>
                )
            }
        },
        {
            title: 'Campus',
            key: 'applications.campusId',
            dataIndex: 'applications.campusId',
            width: 80,
            render: (item, record) => {
                return (
                    <div style={{width: 80}}>
                        {item && item.campusName ? item.campusName : ''}
                    </div>
                )
            }
        },
        {
            title: 'Current User',
            key: 'applications.assignedUserId',
            dataIndex: 'applications.assignedUserId',
            width: 100,
            render: (val, record) => {
                let {agentId, statusList, status} = record.applications
                return (
                    <div style={{width: 100}}>
                        {val && val.name ? val.name : ''}<br/>
                        <div>
                            Agent : {agentId && agentId.companyName ? agentId.companyName : ''}
                            <br/>
                            {agentId && agentId.mobile ? <> <Icon type={'phone'} style={{
                                position: 'relative',
                                bottom: 3
                            }}/> {agentId.mobile}</> : ''}
                        </div>
                        <div>{longDisplayDate(statusList.date)}</div>
                    </div>
                )
            }
        },
        {
            title: 'Status',
            key: 'applications.status',
            dataIndex: 'applications.status',
            width: 120,
            render: (item, record) => {
                let {applications} = record;
                let statusList = ['Waiting To Apply', 'Offer Received', 'Fee Payment / Receipt']
                return (
                    <div className={'fcmtDiv'} style={{width: 120}}>
                        <div>
                            <div className={'label label1'}
                                 style={{background: statusColors(item), width: 85, padding: 3}}>{item}</div>
                            <br/>
                            {applications.statusList && applications.statusList.date ?
                                <div>
                                    Date : {newFormatDisplayDate(applications.statusList.date)}<br/>
                                </div> : null}

                        </div>

                    </div>
                )
            }
        },


        {
            title: 'Action',
            key: 'action',
            dataIndex: 'action',
            width: 100,
            render: (val, record) => {
                let {applications} = record;
                return (
                    <div className={'actionBtnGroup'}>

                        {
                            record.applications.universityCountry && record.applications.universityCountry.countryName && record.applications.universityCountry.countryName == 'Australia' ?
                                <Tooltip title={'Change Country'}>
                                    <button className='btn' onClick={() => changeUniversityFxn(record)}>
                                        <img src={'/assets/shuffle-arrows.png'}/>
                                    </button>
                                </Tooltip> : null
                        }

                    </div>
                )
            }
        }

    ]

    const resp = useSelector(state => {
            let {loadNoteRedux} = state.chooseApplication
            if (loadNoteRedux) {
                if (tableAppRef && tableAppRef.current) {
                    tableAppRef.current.reload()
                }
                dispatch({type: 'END_LOAD_NOTE'})
            }
        }
    )

    const loadColumns = () => {
        let columnArr = []
        lodash.each(columns, (item) => {
            if (item.hidden == undefined) {
                columnArr.push(item)
            } else {
                if (!item.hidden) {
                    columnArr.push(item)
                }
            }
        })
        return columnArr
    }

    const extra = (
        <div>
            <Row gutter={12}>
                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className='search-box-table round'>
                        <input className='form-control form-control' type='search'
                               placeholder='Search by name...' value={name} onChange={(e) => {
                            events.enterName(e.target.value)
                        }}/>
                        <img src='/dist/img/search.png' alt=''/>
                    </div>
                </Col>
                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className='sort-box-table custom-sort-box-new'>
                        <Select name='Status' onChange={(item) => setStatus(item)}
                                filterOption={filterOption}
                                className={'antSelect'}
                                placeholder={'Status'}
                                allowClear={true}
                                value={status || undefined}>
                            {statusList && statusList.length ? statusList.map((item, key) => {
                                return (
                                    <Option value={item} key={key}>{item}</Option>
                                )
                            }) : null}
                        </Select>
                    </div>
                </Col>

                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className='sort-box-table custom-sort-box-new'>
                        <Select name='Status' onChange={(item) => setCampusId(item)}
                                className={'antSelect'}
                                placeholder={'Campus'}
                                allowClear={true}
                                value={campusId || undefined}>
                            {campusList && campusList.length ? campusList.map((item, key) => {
                                return (
                                    <Option value={item._id} key={key}>{item.campusName}</Option>
                                )
                            }) : null}
                        </Select>
                    </div>
                </Col>

                <CheckUserRight user={user} rightUserType={['admin', 'branchManager', 'branchUser']}>
                    <Col md={4} sm={4} xs={8} lg={4}>
                        <div className='sort-box-table custom-sort-box-new'>
                            <Select name='Search By Agent'
                                    filterOption={filterOption}
                                    className={'antSelect'}
                                    allowClear={true}
                                    placeholder={'Agent'}
                                    showSearch={true}
                                    onChange={(item) => setAgentId(item)} value={agentId || undefined}>
                                {allAgent && allAgent.length ? allAgent.map((item, key) => {
                                    return (
                                        <Option value={item._id} key={key}>{item.companyName}</Option>
                                    )
                                }) : null}
                            </Select>
                        </div>
                    </Col>
                </CheckUserRight>
                <CheckUserRight user={user} rightUserType={['admin', 'branchManager', 'branchUser']}>
                    <Col md={4} sm={4} xs={8} lg={4}>
                        <div className='sort-box-table custom-sort-box-new'>
                            <Select name='Intake' onChange={(item) => setIntake(item)}
                                    filterOption={filterOption}
                                    className={'antSelect'}
                                    allowClear={true}
                                    showSearch={true}
                                    placeholder={'Intake'}
                                    value={intake || undefined}>
                                {Intakes && Intakes.length ? Intakes.map((item, key) => {
                                    return (
                                        <Option value={item} key={key}>{item}</Option>
                                    )
                                }) : null}
                            </Select>
                        </div>
                    </Col>
                    <Col span={5}>
                        <div className='sort-box-table custom-sort-box-new'>
                            <div className='search-box-table round'>
                                <input className='form-control form-control' type='search'
                                       placeholder='course name...' value={courseName} onChange={(e) => {
                                    setCourseName(e.target.value)
                                }}/>
                                <img src='/dist/img/search.png' alt=''/>
                            </div>
                        </div>
                    </Col>
                </CheckUserRight>


                <Col md={4} sm={4} xs={12} lg={4}>
                    <Button onClick={() => searchFxn()} className={'roundBtn'}>Search</Button>
                    <Button onClick={() => clearFxn()} className={'roundBtn'}>
                        Clear
                    </Button>
                </Col>


            </Row>

        </div>
    )

    return (
        <React.Fragment>
            <div>
                <div className='row mt-4'>
                    <div className='col-lg-12'>
                        <div className='card'>
                            <div className='table-head d-flex align-items-center all-student-search'
                                 style={{height: 60}}>
                                <Button onClick={() => dispatch(getUrlPushWrapper('dashboard'))}
                                        className={'roundGoBackBtn'} icon={'arrow-left'}>
                                    Back
                                </Button>
                                <h5>All Australia OnShore Applications </h5>
                                <div className='search-box-table' style={{backgroundColor: 'transparent'}}>
                                    {currentStatus} (Total - {totalApplication})
                                </div>
                                <div className='sort-box-table mark-btn'>

                                </div>
                            </div>
                            <div className='card-body table-responsive'>
                                {extra}

                                <TableComp columns={loadColumns()}
                                           rowKey={(item, index) => {
                                               return index
                                           }}
                                           apiRequest={apiRequest}
                                           pagination={{
                                               position: 'top',
                                               showSizeChanger: true,
                                               pageSizeOptions: ['50', '100', '200', '500'],
                                               defaultPageSize: 50,
                                               current: filters.page ? filters.page : 1
                                           }}
                                           ref={tableAppRef}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {transferAppVisible ?
                <TransferApplicationDrawer visible={transferAppVisible}
                                           closeTransferDrawer={() => events.hideTransferApp()}
                                           studentId={studentData._id}
                                           studentData={studentData}
                                           application={studentData.applications}
                /> : null}

            {visibleAddCourseDrawer ? <ApplyCourse
                visible={visibleAddCourseDrawer}
                onClose={() => events.hideAddCourseDrawer()}
                refreshApplication={() => events.refreshApplication()}
                studentId={studentObj._id}/> : null}


            {visibleOtherAppDrawer ?
                <StudentOtherApp visible={visibleOtherAppDrawer}
                                 applicationObj={state.applicationObj}
                                 onStudentOtherAppClose={() => events.closeOtherAppDrawer()}
                                 reloadFxn={events.reloadFxn}
                /> : null}

            {visibleAddCommentDrawer && applicationObj && applicationObj._id ?
                <AddNote visible={visibleAddCommentDrawer} studentData={applicationObj}
                         addedType={'direct'}
                         onClose={() => events.closeAddCommentDrawer()}
                         applicationId={applicationObj.applications._id}/> : null}


            {selectedApplication.visible ?
                <ReActivateApplication {...selectedApplication} onClose={onCloseReActivate}/> : null}

            {enrollmentObj.visible ?
                <Enrollment {...enrollmentObj} onClose={onCloseEnrollmentApp}/> : null}

            {visibleLoaReq && studentData.applications && studentData.applications._id ?
                <LoaRequest onClose={events.closeLoaDrawer}
                            reloadTable={events.reloadFxn}
                            editAble={isEditAble}
                            applicationId={studentData.applications._id}
                            studentId={studentData._id}
                            studentData={studentData}
                            visible={visibleLoaReq}/> : null}

            {visibleFcmtStatus && studentData.applications && studentData.applications._id ?
                <FcmtStatusComponent onClose={events.closeFcmtStatusDrawer}
                                     reloadTable={events.reloadFxn}
                                     applicationId={studentData.applications._id}
                                     studentId={studentData._id}
                                     studentData={studentData}
                                     visible={visibleFcmtStatus}/> : null}

            {visibleApprovalDrawer && studentData.applications && studentData.applications._id ?
                <ApprovedLoaRequest onClose={events.closeApproveRequest}
                                    reloadTable={events.reloadFxn}
                                    studentData={studentData}
                                    applicationId={studentData.applications._id}
                                    studentId={studentData._id}
                                    visible={visibleApprovalDrawer}/> : null}

            {visibleChangeNameDrawer ?

                <ChangeNameDrawer onClose={events.closeChangeName}
                                  reloadTable={events.reloadFxn}
                                  studentData={studentData}
                                  applicationId={studentData.applications ? studentData.applications._id : null}
                                  studentId={studentData._id}
                                  visible={visibleChangeNameDrawer}/> : null}

            {visibleOfferUpload && studentData.applications && studentData.applications._id ?
                <UploadOfferDrawer onClose={events.closeOfferUploadDrawer}
                                   reloadTable={events.reloadFxn}
                                   editAble={isEditAble}
                                   applicationId={studentData.applications._id}
                                   studentId={studentData._id}
                                   studentData={studentData}
                                   visible={visibleOfferUpload}/> : null}

            {visibleReceiptUpload && studentData.applications && studentData.applications._id ?
                <UploadReceiptDrawer onClose={events.closeReceiptUploadDrawer}
                                     reloadTable={events.reloadFxn}
                                     editAble={isEditAble}
                                     applicationId={studentData.applications._id}
                                     studentId={studentData._id}
                                     studentData={studentData}
                                     visible={visibleReceiptUpload}/> : null}

            {visibleAusCommission && studentData.applications && studentData.applications._id ?
                <AustraliaCommissionDrawer onClose={events.closeAusCommissionDrawer}
                                           reloadTable={events.reloadFxn}
                                           editAble={isEditAble}
                                           applicationId={studentData.applications._id}
                                           studentId={studentData._id}
                                           studentData={studentData}
                                           visible={visibleAusCommission}/> : null}

            {visibleAdvancePayDrawer && studentData.applications && studentData.applications._id ?
                <AdvancePayDrawer onClose={events.closeAdvancePayDrawer}
                                  reloadTable={events.reloadFxn}
                                  editAble={isEditAble}
                                  applicationId={studentData.applications._id}
                                  studentId={studentData._id}
                                  studentData={studentData}
                                  visible={visibleAdvancePayDrawer}/> : null}

            {studentData && applicationData && visibleStatusComponent ?
                <StatusComponent
                    visible={visibleStatusComponent}
                    onClose={() => {
                        setVisibleStatusComponent(false)
                    }}
                    reloadTable={events.reloadFxn}
                    applicationId={studentData.applications._id}
                    studentId={studentData._id}
                    studentData={studentData}
                    appStatus={applicationData.status}/> : null}

            {changeUniversity.visible ?
                <TransferToAstL1University {...changeUniversity} onClose={onCloseChangeUniversityFxn}/> : null}

            {visibleUniversityCommission ? <AddCommissionDrawer
                visible={visibleUniversityCommission}
                studentData={studentData}
                onClose={() => events.hideUniversityCommission()}/> : ''}
        </React.Fragment>
    )
}
const mapStateToProps = (
    {
        global, router, chooseApplication
    }
) => (
    {}
)
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}
const WrappedComponent = Form.create()(AustraliaStudentApplication)
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WrappedComponent)
