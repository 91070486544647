import React, {useState, useEffect, useRef} from 'react'
import {
    Button,
    Icon,
    Popconfirm, Row, Select,
    Tooltip
} from 'antd'
import {useDispatch, useSelector} from 'react-redux'
import {TableComp} from 'sz-react-utils-lite'
import {listWithdrawalFxn} from "../../withdrawal/actions/withdrawalAction";
import UpdateWithdrawal from "../drawers/updateWithdrawal";
import {requestForCallFxn} from "../actions";

const {Option} = Select
const initState = {
    withdrawalId: "",
    visible: false,
    status: ""
}
const ListAllWithdrawal = () => {
    let [total, setTotal] = useState(0);
    let [state, setState] = useState(initState)
    const tableRef = useRef()
    const dispatch = useDispatch()
    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            let resp = await dispatch(listWithdrawalFxn({...params, regExFilters: []}));
            setTotal(resp.total)
            resolve(resp)
        })
    }

    const updateState = (data) => {
        setState((prevState) => {
            return {
                ...prevState,
                ...data
            }
        })
    }

    const events = {
        showDrawer: (withdrawalId, status) => {
            updateState({
                withdrawalId,
                visible: true,
                status: status,
            })
        },
        hideDrawer: () => {
            updateState({
                withdrawalId: "",
                visible: false,
                status: ""
            })
            tableRef.current.reload();
        },
        sendForCallRequest: async (withdrawalId) => {
            let resp = await dispatch(requestForCallFxn({withdrawalId}));
            if (resp && resp.success) {
                tableRef.current.reload();
            }
        }
    }

    const columns = [

        {
            title: 'S.No',
            dataIndex: 'sno',
            width: 50,
            key: 'sno',
            render: (item, record, index) => {
                return <a className={'linkPointer'}>
                    {index + 1}
                </a>
            }
        },
        {
            title: 'Agent',
            dataIndex: 'agentId',
            width: 150,
            key: 'agentId',
            render: (item, record) => {
                return (
                    <div>
                        {item && item.name ? item.name : ""}
                        {item && item.companyName ? `(${item.companyName})` : ""}
                    </div>
                )
            }
        },
        {
            title: 'Country',
            dataIndex: 'countryId',
            width: 60,
            key: 'countryId',
            render: (item, record) => {
                return (
                    <div>
                        {item && item.countryName ? item.countryName : ""}
                    </div>
                )
            }
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            width: 60,
            key: 'amount',
            render: (item, record) => {
                let {countryId} = record;
                return (
                    <div>
                        {countryId && countryId.currency ? countryId.currency : ""} {item}
                    </div>
                )
            }
        },
        {
            title: 'Details',
            dataIndex: 'account',
            width: 150,
            key: 'account',
            render: (item, record) => {
                return (
                    <div>
                        Acc no : {item.accountNo} ({item.bankName}) <br/>
                        IFSC: {item.ifsc} <br/>
                        Swift: {item.swiftCode} <br/>
                        Address: {item.address}
                    </div>
                )
            }
        },
        {
            title: 'Withdrawal Status',
            dataIndex: 'status',
            key: 'status',
            width: 80,
            render: (item, record) => {
                return (
                    <div>
                        {item == 'Approved' ? <label className={'label label-success-green'}>Approved</label> : null}
                        {item == 'Pending' ? <label className={'label label-default'}>Pending</label> : null}
                        {item == 'Cancel' ? <label className={'label label-danger'}>Cancel</label> : null}
                    </div>
                )
            }
        },
        {
            title: 'Call Request',
            dataIndex: 'requestForCall',
            key: 'requestForCall',
            width: 100,
            render: (item, record) => {
                let {verified} = record;
                return (
                    <div>
                        {!item ?
                            <>
                                <Popconfirm
                                    title={'Are you sure, you want to Send Request for Call?'}
                                    okText={'Yes'}
                                    cancelText={'No'}
                                    onConfirm={() => {
                                        events.sendForCallRequest(record._id)
                                    }}>
                                    <a className={'btn btn-default btn-sm font12'}>
                                        Send for Call
                                    </a>
                                </Popconfirm>
                            </> :
                            <>
                                {/* <a className={'btn btn-warning btn-sm font12'}>
                                    <i className={'fa fa-check-circle'}/> Request Pending
                                </a>*/}

                                <div className={'font12'}>
                                    {verified == 'Verified' ?
                                        <label className={'label label-success-green'}><i
                                            className={'fa fa-check-circle'}/> Verified</label> : null}
                                    {verified == 'Pending' ?
                                        <label className={'label label-default'}>Request Pending</label> : null}
                                    {verified == 'Not Verified' ?
                                        <label className={'label label-danger'}>Not Verified</label> : null}
                                </div>


                            </>}
                    </div>
                )
            }
        },
        /* {
             title: 'Call Verified',
             dataIndex: 'verified',
             key: 'verified',
             width: 100,
             render: (item, record) => {
                 let {requestForCall} = record;
                 return (
                     <div>
                         {requestForCall ?
                             <>
                                 <div>
                                     {item == 'Verified' ?
                                         <label className={'label label-success-green'}>Verified</label> : null}
                                     {item == 'Pending' ?
                                         <label className={'label label-default'}>Pending</label> : null}
                                     {item == 'Not Verified' ?
                                         <label className={'label label-danger'}>Not Verified</label> : null}
                                 </div>
                             </>
                             : null}
                     </div>
                 )
             }
         },*/
        {
            title: 'Receipt',
            dataIndex: 'receipt',
            key: 'receipt',
            width: 100,
            render: (item, record) => {
                let {invoice} = record;
                return (
                    <div>
                        {item && item.url ?
                            <>
                                <a href={item.url}>Download Receipt</a>
                            </>
                            : null}
                        <br/>
                        {invoice && invoice.path ?
                            <>
                                <a href={invoice.path} target={'_blank'}>Download Invoice</a>
                            </>
                            : null}
                    </div>
                )
            }
        },
        {
            key: 'actions',
            title: 'Actions',
            width: 80,
            render: (text, record) => {
                let {verified, status} = record;
                return (
                    (verified == 'Verified' && status == 'Pending') ?
                        <div className={'view-btn-box ml10'}>
                            <Tooltip title="Approve">
                                <a className={'btn btn-success btn-sm mr10'}
                                   onClick={() => {
                                       events.showDrawer(record._id, 'Approved')
                                   }}
                                >
                                    Approve
                                </a>
                            </Tooltip>
                            <Tooltip title="Cancel">
                                <a className={'btn btn-danger btn-sm'}
                                   onClick={() => {
                                       events.showDrawer(record._id, 'Cancel')
                                   }}>
                                    Cancel
                                </a>
                            </Tooltip>
                        </div> : null
                )
            }
        }
    ]


    return (
        <div className="row mt-4">
            <div className="col-lg-12">
                <div className="card">
                    <div className="table-head d-flex align-items-center">
                        <Button onClick={() => events.goBack()}
                                className={'roundGoBackBtn'} icon={'arrow-left'}>
                            Back
                        </Button>
                        <h5>All Withdrawal: {total}</h5>
                        <div className="search-box-table">
                        </div>
                        <div className="sort-box-table mark-btn">
                        </div>
                    </div>
                    <div className="card-body table-responsive">
                        <TableComp columns={columns}
                                   apiRequest={apiRequest}
                                   ref={tableRef}
                        />
                    </div>
                </div>
            </div>
            {state.visible ?
                <UpdateWithdrawal
                    visible={state.visible}
                    withdrawalId={state.withdrawalId}
                    status={state.status}
                    onClose={events.hideDrawer}
                /> : null}
        </div>
    )
}

export default ListAllWithdrawal
