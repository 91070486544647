import { apiUrl } from '../../../settings'

export const listWithdrawalUrl = () => {
  return apiUrl + '/withdrawal'
}

export const addWithdrawalUrl = () => {
  return apiUrl + '/addWithdrawal'
}

export const updateWithdrawalUrl = () => {
  return apiUrl + '/updateWithdrawal'
}

export const addWithdrawalSendOtpUrl = () => {
  return apiUrl + '/sendWithdrawalOtp'
}
export const sendExpenseWithdrawalOtpUrl = () => {
  return apiUrl + '/sendExpenseWithdrawalOtp'
}
export const addExpenseWithdrawalUrl = () => {
  return apiUrl + '/addExpenseWithdrawal'
}
