import { apiUrl } from '../../settings'

export const IntakesUrl = () => {
  return apiUrl + '/intake'
}
export const singleIntakesUrl = (id) => {
  return apiUrl + '/singleIntake/' + id
}
export const updateIntakeUrl = (id) => {
    return apiUrl + '/updateIntake/' + id
}
export const deleteIntakesUrl = (id) => {
    return apiUrl + '/deleteIntake/' + id
}
export const addCourseIntakeUrl = () => {
    return apiUrl + '/api/addCourseIntake'
}
export const addIntakeInCourseUrl = () => {  // 11 aug 2023 update
    return apiUrl + '/api/addIntakeInCourse'
}
