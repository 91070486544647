import React, {PureComponent, Suspense} from 'react'
import {Button, Col, Layout, Menu, Modal, Row, Tag} from 'antd'
import classNames from 'classnames'
import {Link} from 'react-router-dom'
import styles from './index.less'
import PageLoading from '../PageLoading'
import {getDefaultCollapsedSubMenus} from './SiderMenuUtils'
import {CheckUserRight} from '../../containers/dashboard/views/DashboardUserWise'
import {push} from 'connected-react-router'
import {
    getUser,
    getAgentCertificate,
    getSingleAgentCertificates,
    downloadCertificateFxn
} from '../../containers/users/actions/user'
import AddCounsellingComponent from "../../containers/ReceptionDashboard/view/addCounsellingComponent";
import {connect} from "react-redux";
import {LazyLoadImage} from 'react-lazy-load-image-component'

const BaseMenu = React.lazy(() => import('./BaseMenu'))
const {Sider} = Layout

class SiderMenu extends PureComponent {
    events = {
        showModal: async () => {
            let {user} = this.props;
            let {data} = await getSingleAgentCertificates(user._id);
            let certificates = {
                agentCertificate: data.agentCertificate,
                // agentCambieCertificate: data.agentCambieCertificate,
                agentFCMTCertificate: data.agentFCMTCertificate,
                agentKASACertificate: data.agentKASACertificate,
                agentCiraCertificate: data.agentCiraCertificate,
            }
            this.setState({visible: true, certificates})
        },
        handleCancel: () => {
            this.setState({visible: false, certificates: {}})
        }
    }

    static getDerivedStateFromProps(props, state) {
        const {pathname} = state
        if (props.location.pathname !== pathname) {
            return {
                pathname: props.location.pathname,
                openKeys: getDefaultCollapsedSubMenus(props)
            }
        }
        return null
    }

    isMainMenu = key => {
        const {menuData} = this.props
        return menuData.some(item => {
            if (key) {
                return item.key === key || item.path === key
            }
            return false
        })
    }


    handleOpenChange = openKeys => {
        const moreThanOne = openKeys.filter(openKey => this.isMainMenu(openKey)).length > 1
        this.setState({
            openKeys: moreThanOne ? [openKeys.pop()] : [...openKeys]
        })
    }

    componentDidMount() {
        // this.loadLoginUser()
    }

    openLink = (link) => {
        window.scrollTo(0, 0)
        let {dispatch} = this.props
        dispatch(push(link))
    }
    loadLoginUser = async () => {
        let {dispatch, user} = this.props
        let resp = await dispatch(getUser(user._id))
        this.setState({user: resp})
    }

    constructor(props) {
        super(props)
        this.state = {
            openKeys: getDefaultCollapsedSubMenus(props),
            user: {},
            visible: false,
            certificates: {}
        }
    }

    render() {
        const {logo, collapsed, onCollapse, fixSiderbar, theme, smallLogo, currentUser, currentUserRights} = this.props
        const {openKeys, certificates, visible} = this.state
        const defaultProps = collapsed ? {} : {openKeys}


        const siderClassName = classNames(styles.sider, {
            [styles.fixSiderbar]: fixSiderbar,
            [styles.light]: theme === 'light'
        })

        const agentCertificate = async () => {

            let {dispatch} = this.props
            let {data} = await dispatch(getAgentCertificate())
            if (data && data.url) {
                openPage(data)
            }
        }
        const downloadUserCertificateFxn = async (certificateName) => {
            let {dispatch} = this.props
            let {data} = await dispatch(downloadCertificateFxn({certificateName}))
            if (data && data.path) {
                window.open(`${data.path}`, '_blank')
            }
        }
        const openPage = (data) => {
            window.open(`${data.url}`, '_blank')
        }

        return (
            <Sider
                trigger={null}
                defaultCollapsed={true}
                collapsible
                collapsed={collapsed}
                breakpoint='lg'
                onCollapse={onCollapse}
                width={256}
                theme={theme}
                className={siderClassName}
            >
                <div className={styles.logo} id='logo'>
                    <Link to='/dashboard' style={collapsed ? {width: 110} : {width: 256}}>
                        {!collapsed ? <LazyLoadImage src={logo} alt='logo'/> : null}
                        {/*{collapsed ? null : <h1>{this.props.title}</h1>}*/}
                    </Link>
                </div>

                <div className='sidebar' key={currentUser.userType}>
                    <nav className='mt-4'>
                        {currentUser && currentUser.userType && currentUser.userType == 'reception' ?
                            <AddCounsellingComponent/> : null}
                        {currentUser && currentUser._id ?
                            <Suspense fallback={<PageLoading/>}>
                                <BaseMenu
                                    {...this.props}
                                    mode='inline'
                                    user={currentUser}
                                    currentUserRights={currentUserRights}
                                    handleOpenChange={this.handleOpenChange}
                                    onOpenChange={this.handleOpenChange}
                                    style={{width: '100%'}}
                                    {...defaultProps}
                                />
                            </Suspense> : null}


                        <ul className='nav nav-pills nav-sidebar flex-column' data-widget='treeview' role='menu'
                            data-accordion='false' style={{paddingTop: 0}}>
                            <hr className='divide'/>

                            <CheckUserRight user={currentUser} rightUserType={['agent']}>
                                <li className='nav-item'>
                                    <a onClick={() => this.openLink('/agreement')} className='nav-link'>
                                        <p>Agreement</p>
                                    </a>
                                </li>
                                {/*   <li className='nav-item'>
                                    <a onClick={() => this.openLink('/all-commission')} className='nav-link'>
                                        <p>Commissions</p>
                                    </a>
                                </li>*/}
                                <li className='nav-item'>
                                    <a className='nav-link' onClick={() => {
                                        this.events.showModal()
                                    }}>
                                        <p>Certificates</p>
                                    </a>
                                </li>
                            </CheckUserRight>


                            {currentUser && (currentUser.userType == "admin" || currentUser.email == 'accounts@ryanconsultants.com') ? <>
                                <li className='nav-item'>
                                    <a onClick={() => this.openLink('/agent-commission')} className='nav-link'>
                                        <p>Commissions</p>
                                    </a>
                                </li>
                            </> : null}

                            {currentUser && (currentUser.userType == "agent") ? <li className='nav-item'>
                                <a onClick={() => this.openLink('/supporting-team')} className='nav-link'>
                                    <p>Supporting Team</p>
                                </a>
                            </li> : null}
                        </ul>
                        <div className='box-side'>
                            <LazyLoadImage src='/dist/img/share-friend.png' alt=''
                                           style={{
                                               width: '100%',
                                               margin: ' 30px auto 0',
                                               display: 'block',
                                               padding: '0 45px'
                                           }}/>
                            <div className='social sideMenuSoical'>
                                <ul>
                                    <li><a href='https://www.linkedin.com/company/unizportal'><LazyLoadImage
                                        src='/dist/img/linkedin.svg' alt=''/></a></li>
                                    <li><a href='https://www.facebook.com/unizportal'><LazyLoadImage
                                        src='/dist/img/facebook.svg'
                                        alt=''/></a></li>
                                    <li><a href='https://www.instagram.com/unizportalofficial/'><LazyLoadImage
                                        src='/dist/img/instagram.png'
                                        alt=''/></a></li>
                                    <li><a
                                        href='https://www.youtube.com/channel/UCfBEmsjpc1ZkHjPBgrrhJlA'><LazyLoadImage
                                        src='/dist/img/youtube.png' alt=''/></a></li>
                                </ul>
                            </div>
                        </div>


                    </nav>
                </div>

                {visible ? <Modal
                    title="Certificates"
                    visible={visible}
                    footer={null}
                    onOk={this.events.handleOk}
                    onCancel={this.events.handleCancel}
                >
                    <div>
                        <div className={'certificate_box'}>
                            {certificates ? <>
                                <Row gutter={16}>
                                    {/*  {certificates.agentCertificate && certificates.agentCertificate.url ?
                                        <Col span={12}><a className={'btn btn-default btn-sm'}
                                                          href={certificates.agentCertificate.url}
                                                          target={'_blank'}>
                                            Download Certificate
                                        </a> </Col> : null}*/}

                                   {/* <Col span={12}>
                                        <a className={'btn btn-default btn-sm'}
                                           onClick={() => {
                                               downloadUserCertificateFxn('agentCertificate')
                                           }}
                                        >
                                            Download Certificate
                                        </a> </Col>*/}


                                    {/*<Col span={12}>
                                        <a className={'btn btn-default btn-sm'}
                                           onClick={() => {
                                               downloadUserCertificateFxn('agentCambieCertificate')
                                           }}>
                                            Cambie Certificate
                                        </a></Col>*/}

                                    <Col span={12}>
                                        <a className={'btn btn-default btn-sm'}
                                           onClick={() => {
                                               downloadUserCertificateFxn('agentFCMTCertificate')
                                           }}>
                                            FCMT Certificate
                                        </a>
                                    </Col>
                                    <Col span={12}>
                                        <a className={'btn btn-default btn-sm'}
                                           onClick={() => {
                                               downloadUserCertificateFxn('agentKASACertificate')
                                           }}>
                                            KASA Certificate
                                        </a>
                                    </Col>
                                    <Col span={12}>
                                        <a className={'btn btn-default btn-sm'}
                                           onClick={() => {
                                               downloadUserCertificateFxn('agentCiraCertificate')
                                           }}>
                                            Cira Certificate
                                        </a>
                                    </Col>
                                    <Col span={12}>
                                        <a className={'btn btn-default btn-sm'}
                                           onClick={() => {
                                               downloadUserCertificateFxn('agentOxfordCertificate')
                                           }}>
                                            Oxford Certificate
                                        </a>
                                    </Col>
                                    <Col span={12}>
                                        <a className={'btn btn-default btn-sm'}
                                           onClick={() => {
                                               downloadUserCertificateFxn('agentLesterCertificate')
                                           }}>
                                            Lester Certificate
                                        </a>
                                    </Col>
                                </Row>


                            </> : null}

                        </div>

                    </div>
                </Modal> : null}

            </Sider>
        )
    }
}

const mapStateToProps = ({counter, global}) => ({
    menuRefresh: global.menuRefresh
})
export default connect(
    mapStateToProps,
    null
)(SiderMenu)
