import React, { useState, useEffect } from 'react'
import { Drawer, Form, Button, notification, Card, Spin, Row, Col } from 'antd'
import { FormUtils as GetAllFormFields } from 'sz-react-utils-lite'
import { useDispatch, useSelector } from 'react-redux'
import { createMatchSelector, goBack } from 'connected-react-router'
import { Intakes, IntakesYears } from '../../components/_utils/appUtils'
import { updateIntake, getSingleIntake } from './actions'

import GetEachFormFields from '../../components/_utils/appFormUtils'
import { getUrlParams } from '../../routes'

const Edit = (props) => {
  const dispatch = useDispatch()
  const [month, setMonth] = useState({})
  const [year, setYear] = useState({})
  const [data, setData] = useState({})

  const { form: { getFieldDecorator, setFieldsValue, getFieldValue, resetFields } } = props

  useEffect(() => {
    getIntakeData()
  }, [])


  const getIntakeData = async () => {
    let searchParams = new URLSearchParams(window.location.search)
    let id = searchParams.get('id')
    let data = await dispatch(getSingleIntake(id.toString()))
    setData(data)
    console.log('DATATATA', data)
    setFieldsValue({
      year: data[0].year,
      month: data[0].month
    })
  }

  let inputTypes = {
    fields: [
      {
        key: 'month', label: 'Month', type: 'select', span: 8,
        required: true,
        options: Intakes,
        onChange: x => {
          props.form.setFieldsValue({
            month: x
          })
        }
      },
      {
        key: 'year', label: 'Year', type: 'select', span: 8,
        required: true,
        options: IntakesYears,
        onChange: x => {
          props.form.setFieldsValue({
            year: x
          })
        }
      }
    ]
  }

  const submitFormLayout = {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 10, offset: 7 },
      md: { span: 12, offset: 8 }
    }
  }
  const formItemLayout = {
    labelCol: {
      xs: { span: 0 },
      sm: { span: 0 },
      md: { span: 0 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }

  const handleSubmit = async (e) => {
    let searchParams = new URLSearchParams(window.location.search)
    let id = searchParams.get('id')
    const { form } = props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        await dispatch(updateIntake(valData, id))
        // dispatch(goBack())
      } else {
        notification.warning({
          message: 'Fill All Required Fields'
        })
      }
    })
  }

  useEffect(() => {
  }, [])


  return (
    <div className='row'>
      <div className='col-lg-12'>
        <Form onSubmit={handleSubmit}>
          <div className='form-box mt-4'>

            <div className='d-flex align-items-center mb-3 heading-form'>
              <h5>Update Intake</h5>
            </div>

            <div className='card unizportal'>
              <Row gutter={24}>
                {inputTypes.fields.map((item, key) => {
                  return (
                    <Col span={8} key={key}>
                      <GetEachFormFields
                        item={item}
                        getFieldDecorator={getFieldDecorator}
                        formItemLayout={formItemLayout} />
                    </Col>
                  )
                })}
              </Row>
              <Form.Item>
                <Button type='primary' htmlType='submit' className='btn'>
                  SAVE
                </Button>
              </Form.Item>

            </div>
          </div>

        </Form>
      </div>
    </div>
  )
}

const EditIntake = Form.create()(Edit)
export default EditIntake
