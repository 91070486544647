import { Button, Col, Drawer, Form, Row } from 'antd'
import React from 'react'
import { TableComp } from 'sz-react-utils-lite'
import { DefaultTablePagination } from '../../components/_utils/appUtils'
import { useRef } from 'react'
import { eventList } from '../event/action/event'
import { useDispatch } from 'react-redux'
import { Children } from 'react'
const ViewDrawer = (props) => {
    const { form: { getFieldDecorator, getFieldValue }, visible, events, eventId } = props;

    const tableRef = useRef();
    const dispatch = useDispatch()
    console.log(eventId)

    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            // params.sortField = 'countryName'
            // params.sortOrder = 'ascend'
            params.eventId = eventId

            let resp = await dispatch(eventList({

                ...params,
                regExFilters: ['name', 'enrollmentBasic', 'enrollmentProcedure', 'fundSponsors']
            }))
            // setFIlter(params)
            // setTotal(resp.total)
            resolve(resp)
        })
    }
    const columns = [

        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            searchTextName: 'name',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            searchTextName: 'email',
        },
        {
            title: 'Company Name',
            dataIndex: 'companyName',
            key: 'companyName',

        },
        {
            title: 'Company Address',
            dataIndex: 'address',
            key: 'address',
            searchTextName: 'address',
        },
        {
            title: 'Country',
            dataIndex: 'country',
            key: 'country'
        },
        {
            title: 'Mobile',
            dataIndex: 'mobile',
            key: 'mobile'
        },
        {
            title: 'Reference',
            dataIndex: 'reference',
            key: 'reference',
            render: (record, item) => {
                console.log(item)
                return (
                    <>
                        <div>
                            {item.reference&&item.reference.name?item.reference.name:null}
                        </div>
                    </>
                )
            },
        },
        {
            title: 'Additional Members',
            dataIndex: 'persons',
            key: 'persons'
        },
        {
            title: 'Second Person',
            dataIndex: 'secondPersonName',
            key: 'secondPersonName'
        },
        {
            title: 'Designation',
            dataIndex: 'designation',
            key: 'designation'
        },
        // {
        //     title: 'Status',
        //     dataIndex: 'status',
        //     key: 'status',
        //     filters: statusFilter.map(x => ({value: x.value, text: x.name}))
        // },
    ]
    return (
        <>
            <Drawer
                visible={visible}
                width={'80%'}
                placement="right"
                onClose={events.onClose}
                title={'View Lead'}>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='card'>
                            <div className='card-body table-responsive'>
                                <TableComp columns={columns}
                                    apiRequest={apiRequest}
                                    ref={tableRef}
                                    pagination={DefaultTablePagination()}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Drawer>
        </>
    )
}

export default Form.create()(ViewDrawer)
