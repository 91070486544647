import React, {useState, useEffect} from 'react'
import {
    Button, Col, Select,
    Row, DatePicker
} from 'antd'
import {connect, useDispatch} from 'react-redux'
import moment from 'moment'
import ListComponent from "./listComponent";
import {directUniversityRecordsCountFxn, directUniversityReportCountForMarketingFxn} from "../actions";
import {getUrlPushWrapper} from "../../../routes";

const {RangePicker} = DatePicker


const {Option} = Select
const MarketingDashboardComponent = (props) => {
    let {currentUser} = props;
    const [directUniversityCount, setDirectUniversityCount] = useState({})
    const [toDate, setToDate] = useState(moment().endOf('month').startOf('day'))
    const [fromDate, setFromDate] = useState(moment().subtract(3, 'month').startOf('month').endOf('day'))
    const dispatch = useDispatch()


    useEffect(() => {
        reloadData()
    }, [])

    let reloadData = async () => {
        let resp = await getParamsForApplicationList()
        loadUserReport()
    }

    const loadUserReport = async () => {
        let obj = {
            userId: currentUser._id,
            toDate,
            fromDate
        }
        let directUniversityCountData = await dispatch(directUniversityReportCountForMarketingFxn(obj))
        setDirectUniversityCount(directUniversityCountData)
    }


    const getParamsForApplicationList = () => {
        return new Promise((resolve) => {
            let searchParams = new URLSearchParams(window.location.search)
            let toDate = searchParams.get('toDate')
            let fromDate = searchParams.get('fromDate')
            let obj = {}
            if (toDate) {
                obj.toDate = toDate
            }
            if (fromDate) {
                obj.fromDate = fromDate
            }
            resolve(obj)
        })
    }

    const openPage = (id) => {
        let obj = {
            userId: currentUser._id,
            appUniversityId: id
        }
        if (fromDate) {
            obj.fromDate = moment(fromDate).format('MM-DD-YYYY')
            obj.toDate = moment(toDate).format('MM-DD-YYYY')
        }
        dispatch(getUrlPushWrapper('directUniversityMarketingReport', obj))
    }

    return (
        <div className='row  mt-4'>
            <div className='col-lg-12'>
                <div className='card'>
                    <div className='table-head d-flex align-items-center'>
                        <h5>Reporting List</h5>
                    </div>
                    <div className={'card-pane-right card-pane-new listing-section w-100'}>
                        <Row className={'mt10'}>
                            <Col md={6} sm={6} xs={6} lg={6} style={{paddingLeft: 8}}>
                                <RangePicker
                                    defaultValue={[fromDate, toDate]}
                                    onChange={(val) => {
                                        setFromDate(val[0])
                                        setToDate(val[1])
                                    }}
                                />
                            </Col>

                            <Col span={6} offset={1}>
                                <Button onClick={() => loadUserReport()} className={'roundBtn'}>Search</Button>
                            </Col>
                            <Col span={24}>
                                <ListComponent
                                    directUniversityCount={directUniversityCount}
                                    toDate={toDate ? moment(toDate) : null}
                                    fromDate={fromDate ? moment(fromDate) : null}
                                    openDirectUni={openPage}
                                />
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>

        </div>
    )
}


const mapStateToProps = ({global}) => ({
    currentUser: global.currentUser,
    activeUser: global.activeUser
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MarketingDashboardComponent)


