import React, {useEffect, useState} from "react"
import {Drawer, notification, Select} from "antd";
import {useDispatch} from "react-redux";
import {updateInterviewScheduleFxn, interviewScheduleDateFxn} from "../../../actions/oxfordAction";
import InputBox from "../../../../../components/_utils/InputBox";
import {filterOption, displayDate} from "../../../../../components/_utils/appUtils";
import moment from "moment";

const {Option} = Select

const UpdateScheduleDrawer = (props) => {
    let {onClose, visible, studentData, onSubmit} = props;
    let [oxfordInterviewDate, setOxfordInterviewDate] = useState("")
    let [dateList, setDateList] = useState([])
    let dispatch = useDispatch()
    useEffect(() => {
        loadScheduleDays()
    }, [])

    let loadScheduleDays = async () => {
        if (studentData && studentData.applications && studentData.applications.oxfordInterviewDate) {
            setOxfordInterviewDate(studentData.applications.oxfordInterviewDate)
        }
        let resp = await dispatch(interviewScheduleDateFxn());
        if (resp && resp.success) {
            setDateList(resp.data)
        }
    }
    let updateScheduleTime = async () => {
        if (!oxfordInterviewDate) {
            notification.warning({
                message: "Please choose Interview date."
            })
            return
        }
        let obj = {
            studentId: studentData._id,
            applicationId: studentData.applications._id,
            oxfordInterviewDate: oxfordInterviewDate
        }
        let resp = await dispatch(updateInterviewScheduleFxn(obj));
        if (resp && resp.success) {
            onSubmit()
        }
    }
    return (
        <Drawer title={'Update Interview Schedule'} width={'35%'} onClose={onClose} visible={visible}>
            <div className="card unizportal">
                <InputBox>
                    <Select name='Update Interview Date'
                            onChange={(item) => {
                                setOxfordInterviewDate(item)
                            }}
                            filterOption={filterOption}
                            placeholder='Interview Date'
                            allowClear={true}
                            showSearch={true}
                            value={oxfordInterviewDate || undefined}>
                        {dateList && dateList.length ? dateList.map((item, key) => {
                            return (
                                <Option value={item.date} key={key}>
                                    {moment(item.date).format('dddd, DD MMMM YYYY')} (<b>Slots : {item.slots}</b>)
                                </Option>
                            )
                        }) : null}
                    </Select>
                </InputBox>
                <button className='btn' type='submit' onClick={updateScheduleTime}>save
                </button>
            </div>
        </Drawer>
    )
}
export default UpdateScheduleDrawer
