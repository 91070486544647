import React, {useEffect, useRef, useState} from 'react'
import {
    Select,
    Button,
    Tooltip,
    Icon,
    Row,
    Col, notification, Form, DatePicker
} from 'antd'
import {connect, useDispatch} from 'react-redux'
import {
    getStudentListCountryWiseFxn,
    exportStudentListCountryWiseFxn
} from '../actions/chooseStudent'
import {
    filterOption,
    newFormatDisplayDate,
    Intakes, departmentObj, udaanArr,
} from '../../../components/_utils/appUtils'
import {getUrlPushWrapper} from '../../../routes'
import {TableComp} from 'sz-react-utils-lite'
import {
    AllowComponentUserWise,
    ShowEnrollmentComponent,
} from '../../WebComponent/allowComponentRightsWise'
import {CheckUserRight, ShowExportOption} from '../../dashboard/views/DashboardUserWise'
import moment from 'moment'
import {userWiseUniversityList} from '../../university/actions/university'
import {getBranchManagerAgentList, listAllUsers} from '../../users/actions/user'
import Styles from "../../student/views/styles.less";
import ViewApplication from "../views/viewApplication";
import TransferToOtherAgent from "../../student/views/transferToAgent";
import {AgentBranchManagerComponent} from "../../../components/_utils/AgentBranchManagerComponent";
import EditUser from "../../users/views/edit";
import {MarketingUsersComponent} from "../../../components/_utils/AgentBranchManagerComponent";

const {RangePicker} = DatePicker;


const {Option} = Select
const initialState = {
    applicationList: [],
    applicationObj: {}
}

const stringDate = (date) => {
    return date ? moment(date).format("YYYY-MM-DD") : null
}

const CountryWiseApplicationList = (props) => {
    let {onClose, studentObj = {}, reloadTable, currentUser} = props
    const [state, setState] = useState(initialState)
    const [transferAppVisible, setTransferAppVisible] = useState(false)
    const [addPendencyDrawer, setAddPendencyDrawer] = useState(false)
    const [name, setName] = useState('')
    const [dateOfBirth, setDateOfBirth] = useState('')
    const [allAgent, setAllAgent] = useState([])
    const [agentId, setAgentId] = useState('')
    const [universityList, setUniversityList] = useState([])
    const [appUniversityId, setAppUniversityId] = useState('')
    const [visibleOtherAppDrawer, setVisibleOtherAppDrawer] = useState(false)
    const [visibleAddCommentDrawer, setVisibleAddCommentDrawer] = useState(false)
    const [totalApplication, setTotalApplication] = useState(0)
    const [currentStatus, setCurrentStatus] = useState('')
    let [intake, setIntake] = useState('')
    let {applicationObj} = state
    let [tableSearch, setTableSearch] = useState(false)
    let [filters, setFilters] = useState({})
    let [branchManagerList, setBranchManagerList] = useState([])
    let [studentManagerList, setStudentManagerList] = useState([])
    let [branchManagerId, setBranchManagerId] = useState('')
    let [studentManagerId, setStudentManagerId] = useState('')
    const [branchUserList, setBranchUserList] = useState([])
    const [branchUserId, setBranchUserId] = useState('')
    const [draftApplication, setDraftApplication] = useState('')
    const [studentShore, setStudentShore] = useState('')
    const [defaultCountryId, setDefaultCountryId] = useState('')
    const [fromDate, setFromDate] = useState(null)
    const [toDate, setToDate] = useState(null)
    const [visibleTransferToOtherAgent, setVisibleTransferToOtherAgent] = useState(false)
    const [selectedUser, setSelectedUser] = useState({})
    const [visibleUpdateUser, setVisibleUpdateUser] = useState(false)
    let [marketingUserList, setMarketingUserList] = useState([])
    let [marketingUserId, setMarketingUserId] = useState("")
    let [udaan, setUdaan] = useState("")
    let [armManagerId, setArmManagerId] = useState("")

    const dispatch = useDispatch()
    let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
    const tableAppRef = useRef()

    const loadBranchManager = async () => {
        let obj = {
            userType: 'branchManager',
            customQuery: {
                'branchManagerType': "agentWise"
            },
            results: 100,
            select: ['name', 'email', 'mobile', 'agents', 'countryId']
        }
        let {data} = await dispatch(listAllUsers(obj))
        setBranchManagerList(data)
    }
    const loadStudentManager = async () => {
        let obj = {
            userType: 'branchManager',
            branchManagerType: 'studentWise',
            results: 100,
            select: ['name', 'email', 'mobile', 'agents', 'countryId']
        }
        let {data} = await dispatch(listAllUsers(obj))
        setStudentManagerList(data)
    }
    const loadCurrentUserData = async () => {
        let {countryId} = await getParamsForApplicationList()
        if (countryId) {
            let params = {
                results: 1000,
                userType: 'branchUser',
                sortField: 'name',
                sortOrder: 'ascend',
                select: ['name', 'userType', 'mobile', 'email'],
                customQuery: {
                    $or: [{countryId: countryId}, {'department': 'Visa Approved Department'}]
                }
            }
            let {data} = await dispatch(listAllUsers(params))
            if (data && data.length) {
                setBranchUserList(data)
            }
        } else {
            setBranchUserList([])
        }
    }

    let apiRequest = (params) => {
        return new Promise(async (resolve) => {
            let resp = await getParamsForApplicationList()
            params = {...params, ...resp}
            if (!params.countryId) {
                params.countryId = ''
            }
            if (!params.results) {
                params.results = 50
                params.count = 50
            }
            if (tableSearch) {
                params.page = 1
            }
            try {
                setFilters(params)
                let resp = await dispatch(getStudentListCountryWiseFxn({...params, regExFilters: ['name']}))

                setTotalApplication(resp.total)
                setTableSearch(false)
                resolve(resp)
            } catch (e) {
            }

        })
    }

    let exportExcel = async () => {
        let params = {}
        let resp = await getParamsForApplicationList()
        params = {...params, ...resp}
        if (name) {
            params.name = name
        }
        if (dateOfBirth) {
            params.dateOfBirth = moment(dateOfBirth)
        }

        params.count = totalApplication
        params.results = totalApplication
        let {success, filePath, message} = await dispatch(exportStudentListCountryWiseFxn(params))
        if (success) {
            notification.success({message: message})
            // window.open(`${apiUrl}${filePath}`, 'Download')
        } else {
            notification.error({message: message})
        }
    }


    useEffect(() => {
        loadAgentData()
        // loadUniversityNameList()
        LoadUserWiseUniversity()
        setFieldByParams()
        loadBranchManager()
        loadStudentManager()
        loadCurrentUserData()
        loadMarketingUsers()
    }, [])

    const loadMarketingUsers = async () => {
        let obj = {
            userType: 'branchUser',
            department: departmentObj.marketing,
            results: 100,
            select: ['name', 'email', 'mobile', 'agents', 'assignStateCountry']
        }
        let {data} = await dispatch(listAllUsers(obj))
        setMarketingUserList(data)
    }

    const setFieldByParams = async () => {
        let resp = await getParamsForApplicationList()
        setCurrentStatus(resp.status)
        if (resp.countryId) {
            setDefaultCountryId(resp.countryId)
        }
        if (resp.name) {
            setName(resp.name)
        }
        if (resp.intake) {
            setIntake(resp.intake)
        }
        if (resp.agentId) {
            setAgentId(resp.agentId)
        }
        if (resp.appUniversityId) {
            setAppUniversityId(resp.appUniversityId)
        }
        if (resp.branchManagerId) {
            setBranchManagerId(resp.branchManagerId)
        }
        if (resp.draftApplication) {
            setDraftApplication(resp.draftApplication)
        }
        if (resp.studentShore) {
            setStudentShore(resp.studentShore)
        }
        if (resp.studentManagerId) {
            setStudentManagerId(resp.studentManagerId)
        }
        if (resp.branchUserId) {
            setBranchUserId(resp.branchUserId)
        }
        if (resp.fromDate) {
            setFromDate(moment(resp.fromDate))
        }
        if (resp.toDate) {
            setToDate(moment(resp.toDate))
        }
        if (resp.udaan) {
            setUdaan(resp.udaan)
        }
        if (resp.marketingUserId) {
            setMarketingUserId(resp.marketingUserId)
        }
        if (resp.armManagerId) {
            setArmManagerId(resp.armManagerId)
        }
    }

    const loadAgentData = async () => {
        let params = {
            results: 10000,
            userType: 'agent',
            sortField: 'companyName',
            sortOrder: 'ascend',
            select: ['name', 'companyName'],
            regExFilters: ['companyName']
        }
        let {data} = await dispatch(getBranchManagerAgentList(params))
        if (data && data.length) {
            setAllAgent(data)
        }
    }

    const LoadUserWiseUniversity = async () => {
        let {countryId} = await getParamsForApplicationList()
        let data = await dispatch(userWiseUniversityList({countryId}))
        setAppUniversityId('')
        if (data && data.length) {
            setUniversityList(data)
        }
    }
    const events = {
        reloadAndClose: () => {
            tableAppRef.current.reload()
            setAddPendencyDrawer(false)
        },
        reloadFxn: () => {
            tableAppRef.current.reload()
        },
        showTransferApp: () => {
            setTransferAppVisible(true)
        },
        hideTransferApp: () => {
            setTransferAppVisible(false)
            tableAppRef.current.reload()
        },

        openOtherAppDrawer: (value) => {
            setState({
                ...state,
                applicationObj: value
            })
            setVisibleOtherAppDrawer(true)
        },
        closeOtherAppDrawer: () => {
            setState({
                ...state,
                applicationObj: {}
            })
            setVisibleOtherAppDrawer(false)
        },
        openAddCommentDrawer: (value) => {
            setState({
                ...state,
                applicationObj: value
            })
            setVisibleAddCommentDrawer(true)
        },


        viewCourse: async (record) => {
            let resp = await getParamsForApplicationList()

            setState({
                ...state,
                viewCourseDrawer: true,
                studentObj: {
                    ...record,
                    appUniversityId,
                    intake,
                    appAgentId: agentId,
                    branchUserId,
                    countryId: resp.countryId,
                    appStatus: resp.status
                }
            })
        },
        viewCourseClose: () => {
            setState({
                ...state,
                viewCourseDrawer: false,
                studentObj: {}
            })

        },
        reloadTable: () => {
            if (tableAppRef && tableAppRef.current) {
                tableAppRef.current.reload()
            }
        },

        showTransferDrawer: (data) => {
            setState({
                ...state,
                studentObj: data
            })
            setVisibleTransferToOtherAgent(true)
        },
        hideTransferDrawer: () => {
            setState({
                ...state,
                studentObj: {}
            })
            setVisibleTransferToOtherAgent(false)
        },
        enterName: (name) => {
            setName(name)
        },

        goBack: () => {
            dispatch(getUrlPushWrapper('dashboard'))
        }

    }


    const getParamsForApplicationList = () => {
        return new Promise((resolve) => {
            let searchParams = new URLSearchParams(window.location.search)
            let countryId = searchParams.get('countryId')
            let status = searchParams.get('status')
            let agentId = searchParams.get('agentId')
            let appUniversityId = searchParams.get('appUniversityId')
            let name = searchParams.get('name')
            let intake = searchParams.get('intake')
            let branchUserId = searchParams.get('branchUserId')

            let branchManagerId = searchParams.get('branchManagerId')
            let draftApplication = searchParams.get('draftApplication')
            let studentShore = searchParams.get('studentShore')
            let studentManagerId = searchParams.get('studentManagerId')
            let fromDate = searchParams.get('fromDate')
            let toDate = searchParams.get('toDate')
            let udaan = searchParams.get('udaan')
            let marketingUserId = searchParams.get('marketingUserId')
            let armManagerId = searchParams.get('armManagerId')
            let obj = {
                countryId, status
            }
            if (agentId) {
                obj.agentId = agentId
            }
            if (appUniversityId) {
                obj.appUniversityId = appUniversityId
            }
            if (name) {
                obj.name = name
            }
            if (intake) {
                obj.intake = intake
            }
            if (branchUserId) {
                obj.branchUserId = branchUserId
            }
            if (branchManagerId) {
                obj.branchManagerId = branchManagerId
            }
            if (studentManagerId) {
                obj.studentManagerId = studentManagerId
            }
            if (draftApplication) {
                obj.draftApplication = draftApplication
            }
            if (studentShore) {
                obj.studentShore = studentShore
            }
            if (fromDate) {
                obj.fromDate = fromDate
            }
            if (toDate) {
                obj.toDate = toDate
            }
            if (udaan) {
                obj.udaan = udaan
            }

            if (marketingUserId) {
                obj.marketingUserId = marketingUserId
            }
            if (armManagerId) {
                obj.armManagerId = armManagerId
            }
            resolve(obj)
        })
    }

    const searchFxn = async () => {
        let resp = await getParamsForApplicationList()
        let obj = {
            countryId: resp.countryId,
            status: resp.status
        }
        if (name) {
            obj.name = name
        }
        if (agentId) {
            obj.agentId = agentId
        }
        if (appUniversityId) {
            obj.appUniversityId = appUniversityId
        }
        if (intake) {
            obj.intake = intake
        }
        if (branchUserId) {
            obj.branchUserId = branchUserId
        }
        if (branchManagerId) {
            obj.branchManagerId = branchManagerId
        }
        if (studentManagerId) {
            obj.studentManagerId = studentManagerId
        }
        if (draftApplication) {
            obj.draftApplication = draftApplication
        }
        if (studentShore) {
            obj.studentShore = studentShore
        }
        if (fromDate) {
            obj.fromDate = fromDate
        }
        if (toDate) {
            obj.toDate = toDate
        }
        if (udaan) {
            obj.udaan = udaan
        }

        if (marketingUserId) {
            obj.marketingUserId = marketingUserId
        }
        if (armManagerId) {
            obj.armManagerId = armManagerId
        }
        dispatch(
            getUrlPushWrapper('application.applicationsStudentList', {
                ...obj
            }))
        setTableSearch(true)
        setTimeout(() => {
            tableAppRef.current.reload()
        }, 300)
    }
    const clearFxn = async () => {
        let resp = await getParamsForApplicationList()
        let obj = {
            countryId: resp.countryId,
            status: resp.status
        }
        if (resp.draftApplication) {
            obj.draftApplication = resp.draftApplication
        }
        setAppUniversityId('')
        setAgentId('')
        setName('')
        setIntake('')
        setBranchManagerId('')
        setStudentManagerId('')
        setDraftApplication('')
        setStudentShore('')
        setBranchUserId('')
        setFromDate(null)
        setToDate(null)
        setUdaan(null)
        setMarketingUserId('')
        setArmManagerId('')
        dispatch(
            getUrlPushWrapper('application.applicationsStudentList', {
                ...obj
            }))
        setTableSearch(true)
        setTimeout(() => {
            tableAppRef.current.reload()
        }, 200)
    }


    const columns = [

        {
            title: 'ID',
            dataIndex: 'studentId',
            searchTextName: 'studentId',
            width: 100,
        },

        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: 200,
            render: (item, record) => {
                return (
                    <div className={Styles.userNameBox}>
                        {item}
                    </div>
                )
            }
        },
        {
            title: 'DOB',
            dataIndex: 'dateOfBirth',
            key: 'dateOfBirth',
            width: 100,
            render: (val, record) => {
                return (
                    <div>
                        {newFormatDisplayDate(val)}
                    </div>
                )
            }
        },
        {
            title: 'Email',
            dataIndex: 'email',
            searchTextName: 'email',
            width: 250,
            render: (item, record) => {
                return (
                    <div style={{width: 250}}>
                        {item}
                    </div>
                )
            }
        },
        {
            title: 'Mobile Number',
            dataIndex: 'mobile',
            width: 150,
            render: (item, record) => {
                return (
                    <div style={{width: 150}}>
                        {item}
                    </div>
                )
            }
            // searchTextName: 'mobile'
        },

        {
            title: 'Address',
            key: 'address',
            width: 370,
            dataIndex: 'address',
            searchTextName: 'address',
            render: (item, record) => {
                return (
                    item ?
                        <div style={{width: 350, paddingRight: 8}}>
                            {item}, {record.cityName}, {record.stateName}, {record.countryName}
                        </div> : ''
                )
            }
        },
        {
            title: 'Agent',
            key: 'agentId',
            width: 300,
            dataIndex: 'agentId',
            render: (item, record) => {
                return (
                    item ?
                        <div style={{width: 280}}>
                            {/*{item.name},<br />*/}
                            <span style={{fontWeight: 'bold'}}>{item.companyName}</span><br/> ({item.name})

                            {currentStatus == 'Visa Approved' ?
                                <ShowEnrollmentComponent rightUserType={['admin', 'branchUser']}>
                                    &nbsp;   <a onClick={() => agentEvents.editAgent(item)}><Icon type={'edit'}/></a>
                                </ShowEnrollmentComponent> : null}
                        </div> : ''
                )
            }
        },
        {
            title: 'Managers',
            dataIndex: 'managers',
            width: 150,
            key: 'managers',
            render: (item, record) => {
                let {agentId} = record
                return (
                    <React.Fragment>
                        {branchManagerList && branchManagerList.length ?
                            <>
                                <AgentBranchManagerComponent
                                    userId={agentId._id}
                                    countryId={defaultCountryId}
                                    branchManagers={branchManagerList}/>
                            </> : null}
                    </React.Fragment>
                )
            }
        },
        {
            title: 'Marketing',
            dataIndex: 'marketingUser',
            width: 120,
            key: 'marketingUser',
            render: (item, record) => {
                let {agentId} = record;
                console.log(agentId)
                return (
                    <React.Fragment>
                        {marketingUserList && marketingUserList.length ?
                            <MarketingUsersComponent
                                userId={agentId._id}
                                branchManagers={marketingUserList}/> : null}
                    </React.Fragment>
                )
            }
        },
        {
            title: 'App',
            key: 'app',
            dataIndex: 'app',
            width: 100,
            // searchTextName: 'address',
            render: (item, record) => {
                return (
                    <div style={{width: 100}}>
                        {record && record.appCount ?
                            <span className='badge'>{record.appCount}</span> : ''}
                    </div>
                )
            }
        },

        {
            key: 'actions',
            title: 'Actions',
            // width: 70,
            fixed: 'right',
            height: 100,
            render: (text, record) => {
                return (
                    <div>
                        <Tooltip title={'View Application'}>
                            <button className='btn' onClick={() => events.viewCourse(record)}><img
                                src='/dist/img/view.svg' alt=''/>
                            </button>

                        </Tooltip>
                        <AllowComponentUserWise rightUserType={['admin', 'branchManager', 'branchUser']}>
                            <Tooltip title={'Transfer'}>
                                <button className='btn' onClick={() => {
                                    events.showTransferDrawer(record)
                                }}>
                                    <img src={'../dist/img/forwardArrow.png'}/>
                                </button>
                            </Tooltip>{' '}
                        </AllowComponentUserWise>
                    </div>

                )
            }
        }
    ]


    const extra = (
        <Row gutter={16}>
            <CheckUserRight user={user} rightUserType={['admin', 'branchManager', 'branchUser', 'userManager']}>
                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className='sort-box-table custom-sort-box-new'>
                        <Select name='Current User' onChange={(item) => setBranchUserId(item)}
                                filterOption={filterOption}
                                className={'antSelect'}
                                allowClear={true}
                                showSearch={true}
                                placeholder={'Current User'}
                                value={branchUserId || undefined}>
                            {branchUserList && branchUserList.length ? branchUserList.map((item, key) => {
                                return (
                                    <Option value={item._id} key={key}>{item.name}</Option>
                                )
                            }) : null}
                        </Select>
                    </div>
                </Col>
            </CheckUserRight>
            <Col md={4} sm={4} xs={8} lg={4}>
                <div className='sort-box-table custom-sort-box-new'>
                    <Select name='Search By University' onChange={(item) => setAppUniversityId(item)}
                            filterOption={filterOption}
                            className={'antSelect'}
                            placeholder={'University'}
                            allowClear={true}
                            showSearch={true}
                            value={appUniversityId || undefined}>
                        {universityList && universityList.length ? universityList.map((item, key) => {
                            return (
                                <Option value={item._id} key={key}>{item.universityName}</Option>
                            )
                        }) : null}
                    </Select>
                </div>
            </Col>
            <Col md={4} sm={4} xs={8} lg={4}>
                <div className='sort-box-table custom-sort-box-new'>
                    <Select name='Intake' onChange={(item) => setIntake(item)}
                            filterOption={filterOption}
                            className={'antSelect'}
                            allowClear={true}
                            showSearch={true}
                            placeholder={'Intake'}
                            value={intake || undefined}>
                        {Intakes && Intakes.length ? Intakes.map((item, key) => {
                            return (
                                <Option value={item} key={key}>{item}</Option>
                            )
                        }) : null}
                    </Select>
                </div>
            </Col>


            <CheckUserRight user={user} rightUserType={['admin', 'branchManager', 'branchUser', 'userManager']}>
                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className='sort-box-table custom-sort-box-new'>
                        <Select name='Search By Agent' onChange={(item) => setAgentId(item)}
                                value={agentId || undefined}
                                placeholder={'Agent'}
                                filterOption={filterOption}
                                className={'antSelect'}
                                allowClear={true}
                                showSearch={true}>
                            {allAgent && allAgent.length ? allAgent.map((item, key) => {
                                return (
                                    <Option value={item._id} key={key}>{item.companyName}</Option>
                                )
                            }) : null}
                        </Select>
                    </div>
                </Col>
                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className='sort-box-table custom-sort-box-new'>
                        <Select name='Search By Marketing User'
                                filterOption={filterOption}
                                className={'antSelect'}
                                allowClear={true}
                                placeholder={'Marketing User'}
                                onChange={(item) => setMarketingUserId(item)} value={marketingUserId || undefined}>
                            {marketingUserList && marketingUserList.length ? marketingUserList.map((item, key) => {
                                return (
                                    <Option value={item._id} key={key}>{item.name}</Option>
                                )
                            }) : null}
                        </Select>
                    </div>
                </Col>

                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className='sort-box-table custom-sort-box-new'>
                        <Select name='Search By ARM'
                                filterOption={filterOption}
                                className={'antSelect'}
                                allowClear={true}
                                placeholder={'ARM User'}
                                onChange={(item) => setArmManagerId(item)}
                                value={armManagerId || undefined}>
                            {branchManagerList && branchManagerList.length ? branchManagerList.map((item, key) => {
                                return (
                                    <Option value={item._id} key={key}>{item.name}</Option>
                                )
                            }) : null}
                        </Select>
                    </div>
                </Col>
            </CheckUserRight>

            <CheckUserRight user={user} rightUserType={['admin', 'branchUser', 'userManager']}>
                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className='sort-box-table custom-sort-box-new'>
                        <Select name='Search By Branch Manager'
                                filterOption={filterOption}
                                className={'antSelect'}
                                allowClear={true}
                                placeholder={'Branch Manager'}
                                showSearch={true}
                                onChange={(item) => setBranchManagerId(item)} value={branchManagerId || undefined}>
                            {branchManagerList && branchManagerList.length ? branchManagerList.map((item, key) => {
                                return (
                                    <Option value={item._id} key={key}>{item.name}</Option>
                                )
                            }) : null}
                        </Select>
                    </div>
                </Col>
            </CheckUserRight>
            {/*<CheckUserRight user={user} rightUserType={['admin', 'branchUser', 'userManager']}>
                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className='sort-box-table custom-sort-box-new'>
                        <Select name='Search By Student Manager'
                                filterOption={filterOption}
                                className={'antSelect'}
                                allowClear={true}
                                placeholder={'Student Manager'}
                                showSearch={true}
                                onChange={(item) => setStudentManagerId(item)}
                                value={studentManagerId || undefined}>
                            {studentManagerList && studentManagerList.length ? studentManagerList.map((item, key) => {
                                return (
                                    <Option value={item._id} key={key}>{item.name}</Option>
                                )
                            }) : null}
                        </Select>
                    </div>
                </Col>
            </CheckUserRight>*/}


            <Col md={4} sm={4} xs={8} lg={4}>
                <div className='search-box-table round ml0'>
                    <input className='form-control' type='search'
                           placeholder='Search by name...' value={name} onChange={(e) => {
                        events.enterName(e.target.value)
                    }}/>
                    <img src='/dist/img/search.png' alt=''/>
                </div>
            </Col>
            {currentStatus && (currentStatus == 'Visa Approved' || currentStatus == 'Case Approved') ?
                <>
                    <Col md={4} sm={4} xs={8} lg={4}>
                        <RangePicker
                            value={[fromDate ? moment(fromDate, 'YYYY-MM-DD') : null, toDate ? moment(toDate, 'YYYY-MM-DD') : null]}
                            className={'round-date-picker'}
                            onChange={events.chooseDate}/>
                    </Col>
                </>

                : null}
            {currentStatus && (currentStatus == 'Visa Approved' || currentStatus == 'Case Approved')
            && (currentUser.userType !== 'agent' && currentUser.userType !== 'subAgent') ?
                <>
                    <Col md={4} sm={4} xs={8} lg={4}>
                        <div className='sort-box-table custom-sort-box-new'>
                            <Select name='Choose Udaan'
                                    onChange={(item) => {
                                        setUdaan(item)
                                    }}
                                    value={udaan || undefined}
                                    placeholder={'Udaan'}
                                    className={'antSelect'}
                                    allowClear={true}>
                                {udaanArr && udaanArr.length ? udaanArr.map((item, key) => {
                                    return (
                                        <Option value={item.value} key={item.value}>{item.name}</Option>
                                    )
                                }) : null}
                            </Select>
                        </div>
                    </Col>
                </>

                : null}


            <Col md={24} sm={24} xs={24} lg={24} className={'alignRight'}>
                <Button onClick={() => searchFxn()} className={'roundBtn'}>Search</Button>
                <Button onClick={() => clearFxn()} className={'roundBtn'}>
                    Clear
                </Button>


            </Col>


        </Row>
    )


    const agentEvents = {
        closeEditUser: () => {
            setSelectedUser({})
            setVisibleUpdateUser(false)
        },
        editAgent: (agent) => {
            setSelectedUser(agent)
            setVisibleUpdateUser(true)
        }
    }

    return (
        <React.Fragment>
            <div>
                <div className='row mt-4'>
                    <div className='col-lg-12'>
                        <div className='card'>
                            <div className='table-head d-flex align-items-center all-student-search'
                                 style={{height: 60}}>
                                <Button onClick={() => dispatch(getUrlPushWrapper('dashboard'))}
                                        className={'roundGoBackBtn'} icon={'arrow-left'}>
                                    Back
                                </Button>
                                <h5>All Application </h5>
                                <div className='search-box-table' style={{backgroundColor: 'transparent'}}>
                                    {currentStatus} (Total - {totalApplication})
                                </div>
                                <div className='sort-box-table mark-btn'>
                                    <ShowExportOption user={user}
                                                      rightUserType={['admin', 'branchManager', 'branchUser', 'userManager']}>
                                              <span className={'sort-box-table-right'}>
                                                <Tooltip title={'Export Excel'}>
                                                  {' '} <Button onClick={() => exportExcel()} className={'roundBtn'}>Export Excel</Button>
                                                </Tooltip>
                                              </span>
                                    </ShowExportOption>
                                </div>
                            </div>
                            <div className='card-body table-responsive'>
                                {extra}
                                <TableComp columns={columns}
                                           rowKey={(item, index) => {
                                               return index
                                           }}
                                           apiRequest={apiRequest}
                                           pagination={{
                                               position: 'top',
                                               showSizeChanger: true,
                                               pageSizeOptions: ['50', '100', '200', '500'],
                                               defaultPageSize: 50,
                                               current: filters.page ? filters.page : 1
                                           }}
                                           extraProps={{scroll: {x: 1900}}}
                                           ref={tableAppRef}
                                />
                            </div>
                        </div>
                    </div>
                </div>


            </div>

            {state.viewCourseDrawer ? <ViewApplication
                visible={state.viewCourseDrawer}
                pageType={'student'}
                reloadTable={() => events.reloadTable()}
                onClose={() => events.viewCourseClose()}
                studentObj={state.studentObj}
            /> : null}

            {visibleTransferToOtherAgent ? <TransferToOtherAgent
                visible={visibleTransferToOtherAgent}
                pageType={'student'}
                reloadTable={() => events.reloadTable()}
                onClose={() => events.hideTransferDrawer()}
                studentObj={state.studentObj}/> : null}

            {visibleUpdateUser ? <EditUser
                visible={visibleUpdateUser}
                reloadTable={() => {
                    setTimeout(() => {
                        tableAppRef.current.reload()
                    }, 1000)
                }}
                onClose={() => agentEvents.closeEditUser()}
                userData={selectedUser}/> : ''}


        </React.Fragment>
    )
}
const mapStateToProps = ({global, router, chooseApplication}) => ({
    currentUser: global.currentUser

})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}
const WrappedCountryWiseApplicationList = Form.create()(CountryWiseApplicationList)
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WrappedCountryWiseApplicationList)

