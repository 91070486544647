import React, {Component, useEffect, useState} from 'react'
import {TableComp} from 'sz-react-utils-lite'
import {
    Card, Row, Col,
    Tooltip, Icon, Drawer, List, Popconfirm, Input, Select
} from 'antd'
import {connect} from 'react-redux'
import {listAllUsers, blockAgent, getUserRating} from '../actions/user'
import {
    ConditionOptions,
    DefaultTablePagination,
    departmentList,
    departmentObj, displayDate, filterOption, InputBox
} from '../../../components/_utils/appUtils'
import {listAllCountries} from '../../countries/actions/countries'
import UpdatePassword from '../views/changePassword'
import EditUser from '../views/edit'
import EditSubAgent from '../views/editSubAgent'
import {CheckMyRights} from '../../WebComponent/CheckUserRights'
import {ColumnWidth} from '../../WebComponent/columnWidth'
import {push} from 'connected-react-router'
import UserRightList from './rightsList'
import {CheckUserRight} from '../../dashboard/views/DashboardUserWise'
import LogsComponents from '../drawer/logsComponents'
import EditUserExtraInfo from '../views/editExtraInfo'
import lodash from 'lodash'
import RatingDrawer from '../views/ratingDrawer'
import {useDispatch} from "react-redux";
import {getUrlPushWrapper} from "../../../routes";

let initFilters = {
    name: "",
    email: "",
    mobile: "",
    branchMangerId: "",
    countryId: "",
    department: "",
}
const FilterComponent = (props) => {
    const {Option} = Select
    let dispatch = useDispatch()
    let {filterData, events} = props;
    let [allBranchManager, setAllBranchManager] = useState([])
    let [allCountry, setAllCountry] = useState([])
    useEffect(() => {
        loadAllBranchManager()
        loadAllCountry()
    }, [])
    const loadAllBranchManager = async () => {
        let {data} = await dispatch(listAllUsers({userType: 'branchManager'}))
        setAllBranchManager(data)
    }
    const loadAllCountry = async () => {
        let {data} = await dispatch(listAllCountries())
        setAllCountry(data)
    }


    return (
        <Row gutter={12} className={'filter_box'}>
            <Col span={4}>
                <InputBox title={'Search by name'}>
                    <input
                        value={filterData.name}
                        placeholder={'Name'}
                        onChange={({target}) => {
                            events.updateFilter({name: target.value})
                        }}/>
                </InputBox>
            </Col>
            <Col span={4}>
                <InputBox title={'Search by email'}>
                    <input
                        value={filterData.email}
                        placeholder={'Email'}
                        onChange={({target}) => {
                            events.updateFilter({email: target.value})
                        }}/>
                </InputBox>
            </Col>
            <Col span={4}>
                <InputBox title={'Search by mobile no'}>
                    <input
                        value={filterData.mobile}
                        placeholder={'Mobile no'}
                        onChange={({target}) => {
                            events.updateFilter({mobile: target.value})
                        }}/>
                </InputBox>
            </Col>
            <Col span={4}>
                <InputBox title={'Search by branch manager'}>
                    <Select
                        name="Branch Manager"
                        filterOption={filterOption}
                        allowClear={true}
                        placeholder={'Branch Manager'}
                        onChange={item => {
                            events.updateFilter({branchMangerId: item})
                        }}
                        value={filterData.branchMangerId || undefined}>
                        {allBranchManager && allBranchManager.length
                            ? allBranchManager.map((item, key) => {
                                return (
                                    <Option value={item._id} key={key}>
                                        {item.name}
                                    </Option>
                                )
                            })
                            : null}
                    </Select>
                </InputBox>
            </Col>
            <Col span={4}>
                <InputBox title={'Search by country'}>
                    <Select
                        name="Country"
                        filterOption={filterOption}
                        allowClear={true}
                        placeholder={'Country'}
                        onChange={item => {
                            events.updateFilter({countryId: item})
                        }}
                        value={filterData.countryId || undefined}>
                        {allCountry && allCountry.length
                            ? allCountry.map((item, key) => {
                                return (
                                    <Option value={item._id} key={key}>
                                        {item.countryName}
                                    </Option>
                                )
                            })
                            : null}
                    </Select>
                </InputBox>
            </Col>
            <Col span={4}>
                <InputBox title={'Search by department'}>
                    <Select
                        name="Department"
                        filterOption={filterOption}
                        allowClear={true}
                        placeholder={'Department'}
                        onChange={item => {
                            events.updateFilter({department: item})
                        }}
                        value={filterData.department || undefined}>
                        {departmentList && departmentList.length
                            ? departmentList.map((item, key) => {
                                return (
                                    <Option value={item} key={key}>
                                        {item}
                                    </Option>
                                )
                            })
                            : null}
                    </Select>
                </InputBox>
            </Col>
            <Col span={24}>
                <div className={'btn_group'}>
                    <a className={'default_btn'} onClick={events.searchFxn}>Search</a>
                    <a className={'default_btn'} onClick={events.clearFxn}>Clear</a>
                </div>
            </Col>
        </Row>
    )
}

class AllBranchUsers extends Component {
    filterEvents = {
        getParams: (field) => {
            let searchParams = new URLSearchParams(window.location.search)
            return searchParams.get(field) ? searchParams.get(field) : ""
        },
        updateFilter: (data) => {
            let {filterData} = this.state;
            this.setState({
                filterData: {...filterData, ...data}
            })
        },
        setFieldByParams: async () => {
            let {getParams} = this.filterEvents
            let obj = {}
            lodash.each(initFilters, (item, key) => {
                if (getParams(key)) {
                    obj[key] = getParams(key);
                }
            })
            this.setState({
                filterData: obj
            }, () => {
                setTimeout(() => {
                    if (this.tableRef && this.tableRef.current) {
                        this.tableRef.current.reload()
                    }
                }, 100)
            })
        },

        getParamsForApplicationList: () => {
            return new Promise(resolve => {
                let {filterData} = this.state;
                let obj = {}
                lodash.each(initFilters, (item, key) => {
                    if (filterData[key]) {
                        obj[key] = filterData[key];
                    }
                })
                resolve(obj)
            })
        },
        searchFxn: async () => {
            let {dispatch} = this.props;
            let {filterData} = this.state;
            let obj = {}
            lodash.each(initFilters, (item, key) => {
                if (filterData[key]) {
                    obj[key] = filterData[key];
                }
            })
            dispatch(getUrlPushWrapper('user.allBranchUsers', {...obj}))
            setTimeout(() => {
                if (this.tableRef && this.tableRef.current) {
                    this.tableRef.current.reload()
                }
            }, 200)
        },
        clearFxn: () => {
            let {dispatch} = this.props;
            let obj = {}
            dispatch(getUrlPushWrapper('user.allBranchUsers', {...obj}))
            this.setState({
                filterData: initFilters
            })
            setTimeout(() => {
                this.tableRef.current.reload()
            }, 200)
        }
    }

    constructor(props) {
        super(props)
        this.state = {
            viewInfoDrawer: false,
            selectedRow: {},
            allAgents: [],
            allBranchManager: [],
            allCountry: [],
            selectedUser: {},
            visibleUpdatePassword: false,
            visibleUpdateUser: false,
            visibleUserExtraInfo: false,
            visibleUpdateSubAgent: false,
            visibleUserRightDrawer: false,
            userPointsList: {},
            filterData: initFilters
        }
        this.tableRef = React.createRef()
    }

    events = {
        showUserRightDrawer: (data) => {
            this.setState({
                visibleUserRightDrawer: true,
                selectedUser: data
            })
        },
        hideUserRightDrawer: () => {
            this.setState({
                visibleUserRightDrawer: false,
                selectedUser: {}
            })
        },
        closeAndUpdateUserRightDrawer: () => {
            this.setState({
                visibleUserRightDrawer: false,
                selectedUser: {}
            }, () => {
                this.tableRef.current.reload()
            })
        }
    }

    componentDidMount() {
        this.loadAllAgent()
        this.loadAllBranchManager()
        this.loadAllCountry()
        this.getUserPointsById()
        this.filterEvents.setFieldByParams()
    }

    apiRequest = (params) => {
        let {dispatch} = this.props
        return new Promise(async (resolve) => {
            let filterResp = await this.filterEvents.getParamsForApplicationList()
            params = {...params, ...filterResp}
            params.sortField = 'name'
            params.sortOrder = 'ascend'
            if (!params.userType || (params.userType && params.userType.length == 0)) {
                // params.userType = { $ne: 'student' }
                params.userType = ['branchUser']
            }
            let resp = await dispatch(listAllUsers({
                ...params,
                regExFilters: ['name', 'address', 'mobile', 'email', 'department']
            }))
            this.setState({totalBranchUser: resp.total})
            resolve(resp)
        })
    }

    async loadAllAgent() {
        let {dispatch} = this.props
        let {data} = await dispatch(listAllUsers({userType: 'agent'}))
        this.setState({allAgents: data})
    }

    async loadAllBranchManager() {
        let {dispatch} = this.props
        let {data} = await dispatch(listAllUsers({userType: 'branchManager'}))
        this.setState({allBranchManager: data})
    }

    async loadAllCountry() {
        let {dispatch} = this.props
        let {data} = await dispatch(listAllCountries())
        this.setState({allCountry: data})
    }


    viewInfo(record) {
        this.setState({
            selectedRow: record,
            viewInfoDrawer: true
        })
    }

    onClose = () => {
        this.setState({
            selectedRow: {},
            viewInfoDrawer: false
        })
    }

    closeUpdatePassword = () => {
        this.setState({
            selectedUser: {},
            visibleUpdatePassword: false
        }, () => {
            this.tableRef.current.reload()
        })
    }

    closeEditUser = () => {
        this.setState({
            selectedUser: {},
            visibleUpdateUser: false
        }, () => {
            this.tableRef.current.reload()
        })
    }

    closeEditSubAgent = () => {
        this.setState({
            selectedUser: {},
            visibleUpdateSubAgent: false
        }, () => {
            this.tableRef.current.reload()
        })
    }

    async getUserPointsById() {
        let {dispatch} = this.props
        let data = await dispatch(getUserRating({}))
        let obj = {}
        lodash.each(data, (item) => {
            if (item && item.userId) {
                obj[item.userId] = item.totalPoints ? item.totalPoints : ''
            }
        })
        this.setState({userPointsList: obj})
    }

    updateAgentBlockStatus = async (id) => {
        let {dispatch} = this.props
        let resp = await dispatch(blockAgent({agentId: id}))
        if (resp && resp.success) {
            this.tableRef.current.reload()
        }
    }

    closeEditUserExtraInfo = () => {
        this.setState({
            selectedUser: {},
            visibleUserExtraInfo: false
        }, () => {
            this.tableRef.current.reload()
        })
    }

    closeRatingDrawer = () => {
        this.setState({
            selectedUser: {},
            visibleRatingDrawer: false
        }, () => {
            this.tableRef.current.reload()
            this.getUserPointsById()
        })
    }


    render() {
        let {
            selectedRow,
            allAgents,
            allBranchManager,
            allCountry,
            visibleUpdatePassword,
            selectedUser,
            visibleUpdateUser,
            visibleUserExtraInfo,
            visibleRatingDrawer,
            visibleUpdateSubAgent,
            visibleUserRightDrawer,
            totalBranchUser,
            filterData
        } = this.state
        let {dispatch} = this.props
        const columns = [
            {
                title: '#',
                key: '_id',
                dataIndex: '_id',
                width: 50,
                render: (ite, record, index) => {
                    return (
                        <React.Fragment>
                            {index + 1}
                        </React.Fragment>
                    )
                }
            },
            {
                title: 'Name',
                key: 'name',
                sorter: true,
                dataIndex: 'name',
                // searchTextName: 'name',
                width: 100,
                render: (item, record) => {
                    let {joiningDate} = record;
                    return (
                        <ColumnWidth width={100}>
                            {item}
                            {record.postName ? <div>(Post Name : {record.postName})</div> : null}
                            {joiningDate ? <div className={'joinDate'}>
                                Join : {displayDate(joiningDate)}
                            </div> : null}
                        </ColumnWidth>
                    )
                }
            },
            {
                title: 'Email',
                dataIndex: 'email',
                key: 'email',
                // searchTextName: 'email',
                width: 120,
                render: (item) => {
                    return (
                        <ColumnWidth width={120}>{item}</ColumnWidth>
                    )
                }
            },
            {
                title: 'Mobile No',
                dataIndex: 'mobile',
                key: 'mobile',
                // searchTextName: 'mobile',
                width: 80,
                render: (item) => {
                    return (
                        <ColumnWidth width={80}>{item}</ColumnWidth>
                    )
                }
            },
            /* {
               title: 'Address',
               dataIndex: 'address',
               key: 'address',
               searchTextName: 'address',
               width: 150,
               render: (item) => {
                 return (
                   <ColumnWidth width={150}>{item}</ColumnWidth>
                 )
               }
             },*/
            /* {
               title: 'User Type',
               dataIndex: 'userType',
               key: 'userType',
               filters: userTypeOptions,
               width: 100,
               render: (item) => {
                 return (
                   <ColumnWidth width={100}>{item}</ColumnWidth>
                 )
               }
             },*/
            {
                title: 'Branch Manager',
                dataIndex: 'branchMangerId',
                key: 'branchMangerId',
                width: 150,
                // filters: allBranchManager.map(x => ({value: x._id, text: x.name})),
                render: (item) => {
                    return (
                        item && item.name ? item.name : ''
                    )
                }
            },
            /* {
               title: 'Agent',
               dataIndex: 'agentId',
               key: 'agentId',
               filters: allAgents.map(x => ({ value: x._id, text: x.name })),
               width: 100,
               render: (item) => {
                 return (
                   <ColumnWidth width={100}>{item && item.name ? item.name : ''}</ColumnWidth>
                 )
               }

             },*/
            {
                title: 'Country',
                dataIndex: 'countryId',
                key: 'countryId',
                // filters: allCountry.map(x => ({value: x._id, text: x.countryName})),
                width: 100,
                render: (item, record) => {
                    return (
                        <ColumnWidth width={100}>
                            {item && item.countryName ? item.countryName : ''}

                            {record.department && (record.department == departmentObj.offerDepartment || record.department == departmentObj.loaDepartment || record.department == departmentObj.assDepartment) ?
                                <div className={'mt10'}>
                                    {record.showAllUniversity == 'All' ? 'All University' :
                                        <a className={'bs-link'} onClick={() => {
                                            this.viewInfo(record)
                                        }}>Selected University</a>
                                    }
                                </div>
                                : null}

                        </ColumnWidth>
                    )
                }

            },
            /* {
               title: 'Commission',
               dataIndex: 'commissionType',
               key: 'commissionType',
               filters: commissionType,
               width: 100,
               render: (item) => {
                 return (
                   <ColumnWidth width={100}>{item}</ColumnWidth>
                 )
               }
             },*/
            {
                title: 'Department',
                dataIndex: 'department',
                key: 'department',
                // filters: departmentList.map(x => ({value: x, text: x})),
                width: 100,
                render: (item, record) => {
                    let {counsellorType} = record;
                    return (
                        item == departmentObj.counselling ?
                            <ColumnWidth width={100}>
                                {item}
                                <div>
                                    ({counsellorType})
                                </div>
                            </ColumnWidth> :
                            <ColumnWidth width={100}>{item}</ColumnWidth>
                    )
                }
            },
            /* {
               title: 'Logo',
               dataIndex: 'logo',
               key: 'logo',
               width: 100,
               render: (val) => {
                 return (
                   <ColumnWidth width={100}>  {val && val.url ? <img src={val.url} className={'imgSm'}/> : null}</ColumnWidth>
                 )
               }
             },*/
            {
                title: 'User Rating',
                dataIndex: 'userRating',
                key: 'userRating',
                hidden: true,
                width: 100,
                render: (item, {_id}) => {
                    let {userPointsList} = this.state
                    // console.log(record._id,userPointsList)
                    return userPointsList[_id] ? `${userPointsList[_id]} points` : ''
                    // return null
                }
            },
            {
                title: 'Block',
                dataIndex: 'block',
                width: 80,
                key: 'block',
                filters: ConditionOptions.map(x => ({value: x.value, text: x.name})),
                render: (item, record) => {
                    return (
                        <React.Fragment>
                            {record && record.userType && (record.userType !== 'admin') ?
                                <div>
                                    <Popconfirm
                                        title={`Are your sure, you want to ${item ? 'Un-Block' : 'Block'} this User?`}
                                        onConfirm={() => {
                                            this.updateAgentBlockStatus(record._id)
                                        }}
                                        okText='Yes' cancelText='No'>
                                        {item ? <div className={'label label-success labelNew'}>Yes</div> :
                                            <div className={'label label-warning labelNew'}>No</div>}
                                    </Popconfirm>
                                </div> : null}
                        </React.Fragment>
                    )
                }
            },
            {
                title: 'Action',
                key: '_id',
                dataIndex: '_id',
                width: 150,
                render: (val, record) => {
                    return (
                        <React.Fragment>
                            {record.userType !== 'master' && this.props.currentUser.userType !== 'hr' ?
                                <>
                                    <Tooltip title='Edit'>
                                        {record.department === 'Marketing Department' ? <>
                                                <a className={'btn'} href={`/edit-markingManager/${record._id}`}>
                                                    <Icon type={'edit'}/>
                                                </a>
                                            </> :
                                            <button
                                                className={'btn'}
                                                style={{marginRight: 6}}
                                                onClick={() => {
                                                    let {dispatch} = this.props
                                                    if (record.userType == 'subAgent') {
                                                        this.setState({
                                                            visibleUpdateSubAgent: true,
                                                            selectedUser: record
                                                        })
                                                    } else if (record.department === 'Marketing Department') {
                                                        window.open(`/user/edit-markingManager/${record._id}`)
                                                        // dispatch(getPushPathWrapper('Users.editMarketingManager', { id: record._id }))
                                                    } else if (record.department === 'Counselling Department') {
                                                        console.log(record, 'record');
                                                        window.open(`/user/edit-counsellor/${record._id}`)
                                                    } else {
                                                        this.setState({
                                                            visibleUpdateUser: true,
                                                            selectedUser: record
                                                        })
                                                    }

                                                }}>
                                                <Icon type={'edit'}/>
                                            </button>}
                                    </Tooltip>
                                </> : null}

                            {this.props.currentUser.userType == 'hr' ?
                                <Tooltip title='Edit Extra info'>
                                    <button
                                        className={'btn'}
                                        style={{marginRight: 6}}
                                        onClick={() => {
                                            this.setState({
                                                visibleUserExtraInfo: true,
                                                selectedUser: record
                                            })
                                        }}>
                                        <Icon type={'edit'}/>
                                    </button>
                                </Tooltip> : null}

                            {this.props.currentUser.userType == 'hr' ?
                                <Tooltip title='Rating'>
                                    <button
                                        className={'btn'}
                                        style={{marginRight: 6}}
                                        onClick={() => {
                                            this.setState({
                                                visibleRatingDrawer: true,
                                                selectedUser: record
                                            })
                                        }}>
                                        <Icon type={'trophy'}/>
                                    </button>
                                </Tooltip> : null}

                            <Tooltip title='Change Password'>
                                <button
                                    className={'btn'}
                                    style={{marginRight: 6}}
                                    onClick={() => {
                                        this.setState({
                                            selectedUser: record,
                                            visibleUpdatePassword: true
                                        })
                                    }}>
                                    <Icon type={'key'}/>
                                </button>
                            </Tooltip>
                            {/*  {record.userType == 'branchUser' ?
                <Tooltip title="Universities">
                  <button
                    className={'btn'}
                    onClick={() => {
                      this.viewInfo(record)
                    }}
                  >
                    <Icon type={'eye'}/>
                  </button>
                </Tooltip> : ''}*/}

                            <CheckUserRight user={this.props.currentUser} rightUserType={['admin', 'branchManager']}>
                                <Tooltip title='Rights'>
                                    <button
                                        className={'btn'}
                                        onClick={() => {
                                            this.events.showUserRightDrawer(record)
                                        }}
                                    ><Icon type='trophy'/>
                                    </button>
                                </Tooltip>
                            </CheckUserRight>
                            <LogsComponents user={record} {...this.props} currentUser={this.props.currentUser}/>
                        </React.Fragment>
                    )
                }
            }
        ]
        return (
            <div>

                <div className='row  mt-4'>
                    <div className='col-lg-12'>
                        <div className='card'>
                            <div className='table-head d-flex align-items-center'>
                                <h5>All Branch User ({this.state.totalBranchUser})</h5>
                                <div className='search-box-table'>

                                </div>

                                <CheckMyRights rightList={['admin', 'userManager']}>
                                    <div className='sort-box-table mark-btn'>
                                        <div>
                                            <a className='btn' onClick={() => dispatch(push('/user/add-user'))}>
                                                <img src={'../dist/icons/plus.png'} className={'plus'}/> Add User</a>
                                        </div>
                                    </div>
                                </CheckMyRights>
                            </div>
                            <div className='card-body table-responsive'>
                                <FilterComponent filterData={filterData} events={this.filterEvents}/>
                                <TableComp columns={columns}
                                           ref={this.tableRef}
                                           pagination={DefaultTablePagination()}
                                           apiRequest={this.apiRequest}
                                           extraProps={{scroll: {x: 1000}}}
                                />
                            </div>
                        </div>
                    </div>
                </div>


                <Drawer visible={this.state.viewInfoDrawer}
                        width={520}
                        onClose={this.onClose}>
                    <Card title={'Universities'}>
                        {selectedRow && selectedRow.universities && selectedRow.universities.length ?
                            <List
                                size='small'
                                bordered
                                dataSource={selectedRow.universities}
                                renderItem={item =>
                                    <List.Item>{item && item.universityName ? item.universityName : null}</List.Item>}
                            /> : null
                        }

                    </Card>
                </Drawer>
                {visibleUpdatePassword ? <UpdatePassword
                    visible={visibleUpdatePassword}
                    onClose={() => this.closeUpdatePassword()}
                    userData={selectedUser}/> : ''}

                {visibleUpdateUser ? <EditUser
                    visible={visibleUpdateUser}
                    onClose={() => this.closeEditUser()}
                    userData={selectedUser}/> : ''}

                {visibleUserExtraInfo ? <EditUserExtraInfo
                    visible={visibleUserExtraInfo}
                    onClose={() => this.closeEditUserExtraInfo()}
                    userData={selectedUser}/> : ''}

                {visibleRatingDrawer ? <RatingDrawer
                    visible={visibleRatingDrawer}
                    onClose={() => this.closeRatingDrawer()}
                    userData={selectedUser}/> : ''}

                {visibleUpdateSubAgent ? <EditSubAgent
                    visible={visibleUpdateSubAgent}
                    onClose={() => this.closeEditSubAgent()}
                    userData={selectedUser}/> : ''}

                {visibleUserRightDrawer ? <UserRightList
                    user={selectedUser}
                    visible={visibleUserRightDrawer}
                    onClose={() => this.events.hideUserRightDrawer()}
                    closeAndUpdate={() => this.events.closeAndUpdateUserRightDrawer()}/> : ''}


            </div>)

    }
}


const mapStateToProps = ({global}) => ({
    categories: global.categories,
    currentUser: global.currentUser

})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AllBranchUsers)
