import React, { Component } from 'react'
import { Input, Button, Icon, Tooltip } from 'antd'
import { InputBox } from './appUtils'

class FileInput extends Component {

  render() {
    let { name, chooseDocument, file, label } = this.props
    return (
      <React.Fragment>
        <InputBox title={label} className={'rowFlex'}>
          <Input type={'file'} name={name} id={name} className={'form-control'}
                 onChange={(e) => {
                   chooseDocument(e)
                 }}/>
          {file && file.url ?
            <Tooltip title={file.name}> <a className={'linkBtn'} target={'_blank'} href={file.url}>
              <Icon type={'eye'}></Icon>
            </a> </Tooltip> : ''}
        </InputBox>

      </React.Fragment>
    )
  }
}

export default FileInput

