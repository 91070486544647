import {push} from 'connected-react-router'
import _ from 'lodash'
import Path from 'path-parser'
import Dashboard from './containers/dashboard/views/dashboard'
import Undercons from './containers/undercons'
import MarketingAgentList from './containers/users/views/MarketingAgentList'
import AddUsers from './containers/users/views/add'
import AddMarketingManager from './containers/users/views/addMarketingManager'
import AddSubAgent from './containers/users/views/addSubAgent'
import AgentsList from './containers/users/views/agentsList'
import AllUsers from './containers/users/views/all'
import AllAgentsForMarketingUsers from './containers/users/views/allAgentsForMarketingUsers'
import AllSubAgent from './containers/users/views/allSubAgent'
import ApprovedAgentsList from './containers/users/views/approvedAgentsList'
import EditUser from './containers/users/views/edit'
import EditMarketingManager from './containers/users/views/editMarketingManager'
import EditSubAgent from './containers/users/views/editSubAgent'
import SingleProperty from './unizHome/singleProperty'
import AddCountry from './containers/countries/views/add'
import AddCourse from './containers/course/views/add'
import AllCourse from './containers/course/views/all'
import EditCourse from './containers/course/views/edit'
import AddUniversity from './containers/university/views/add'

import AssignIntake from './containers/Intakes/AssignIntake'
import AddIntake from './containers/Intakes/add'
import AllIntakes from './containers/Intakes/all'
import EditIntake from './containers/Intakes/edit'
import ApplicationProfile from './containers/applications/views/applicationProfile/profile'
import SingleApplication from './containers/applications/views/singleApplication'
import AddCampus from './containers/campus/views/add'
import ListAllCampus from './containers/campus/views/all'
import EditCampus from './containers/campus/views/edit'
import AddCity from './containers/city/views/add'
import ListAllCities from './containers/city/views/all'
import EditCity from './containers/city/views/edit'
import AllCountries from './containers/countries/views/all'
import EditCountry from './containers/countries/views/edit'
import SearchCourse from './containers/searchCourse'
import AddState from './containers/state/views/add'
import ListAllStates from './containers/state/views/all'
import EditState from './containers/state/views/edit'
import AddStudent from './containers/student/views/add'
import AllStudent from './containers/student/views/all'
import EditStudent from './containers/student/views/edit'
import ListAllUniversities from './containers/university/views/all'
import EditUniversity from './containers/university/views/edit'

import AddFeedback from './containers/feedback/views/add'
import AllFeedback from './containers/feedback/views/all'
import ChangePassword from './containers/login/changePassword'
import LogoutComponent from './containers/login/logout'
import UpdatePassword from './containers/login/updatePassword'
import AllPendency from './containers/pendency/views/all'
import AddPrivateMessage from './containers/privateMessage/views/add'
import AllPrivateMessage from './containers/privateMessage/views/all'
import AllSampleDocument from './containers/sampleDocument/views/all'

import Event from '../src/containers/event/Event'
import ReferralComponent from '../src/containers/referrals/views/index'
import Reporting from '../src/containers/reporting/views/index'
import AgentProfile from './containers/AgentProfile'
import AllCourseCategory from './containers/CourseCategory/views/all'
import Leads from './containers/DailyReporting/views/leads'
import AustraliaOffShoreList from './containers/OffShoreAustralia/views/index'
import IndexAustralia from './containers/OffShoreAustralia/views/indexAustralia'
import InsuranceList from './containers/StudentInsurance/InsuranceList'
import StudentInsurance from './containers/StudentInsurance/StudentInsurance'
import AddTarget from './containers/addtarget/views'
import EditTarget from './containers/addtarget/views/edit'
import TargetList from './containers/addtarget/views/targetList'
import AssessmentApplications from './containers/applications/assessment/assessmentApplications'
import CasaApplicationList from './containers/applications/directUniversities/casaApplicationList'
import ApplicationStudentList from './containers/applications/student/studentList'
import AllCambieApplicationsList from './containers/applications/views/AllCambieApplicationsList'
import AllFcmtApplicationsList from './containers/applications/views/AllFcmtApplicationsList'
import AllApplicationsList from './containers/applications/views/allApplicationsList'
import ApplicationsList from './containers/applications/views/applicationsList'
import CambieApplicationsList from './containers/applications/views/cambieApplicationsList'
import CambiePendingApplicationList from './containers/applications/views/cambiePendingApplicationList'
import DirectUniversityApplication from './containers/applications/views/directUniversityApplication'
import FcmtApplicationsList from './containers/applications/views/fcmtApplicationsList'
import FcmtApplicationsListNew from './containers/applications/views/fcmtApplicationsList2'
import FcmtPendingApplicationList from './containers/applications/views/fcmtPendingApplicationList'
import KasaApplicationList from './containers/applications/views/kasaApplicationList'
import LesterUniversityApplication from './containers/applications/views/lesterApplicationList'
import OshcApplications from './containers/applications/views/oshcApplications'
import UkTuitionApplications from './containers/applications/views/ukTuitionApplications'
import AddBanners from './containers/banners/view'
import Banners from './containers/banners/view/list'
import SendMailToAgents from './containers/broadCastMail/views/add'
import ChatBox from './containers/chatBox/views'
import CommissionStructure from './containers/commission/views/CommissionStructure'
import AllCommission from './containers/commission/views/all'
import {
    default as CommissionAgentList,
    default as commissionAgentList
} from './containers/commission/views/commissionAgentList'
import CompanyProfile from './containers/company/companyProfile'
import AddCompany from './containers/company/views/add'
import AllCompany from './containers/company/views/all'
import EditCompany from './containers/company/views/edit'
import CounsellorDashboard from './containers/counselling/counsellerDashboard'
import Counselling from './containers/counselling/counselling'
import counsellorDashboard from './containers/counsellorDashboard/view/profile'
import enrolledDashboard from './containers/enrolledDashboard/profile'
import EvaluateProfile from './containers/evaluateProfile'
import DownloadReport from './containers/home/downloadReport'
import AllNewsEnquiryList from './containers/news/views/enquiryList'
import ReportingApplicationList from './containers/reporting/views/reportingApplicationList'
import ReportingList from './containers/reporting/views/reportingList'
import AddScheduleMeeting from './containers/scheduleMetting/views/add'
import AllScheduleMeeting from './containers/scheduleMetting/views/all'
import AddMeetings from './containers/scheduleMetting/views/meetings'
import QuickAddStudent from './containers/student/quick/add'
import QuickAddAllStudent from './containers/student/quick/all'
import studentAddNewApplication from './containers/student/views/studentAddNewApplication'
import WithdrawalStudent from './containers/student/views/withdrawalStudent'
import AddTrustedStory from './containers/trustedStory/views'
import TrustedStoryList from './containers/trustedStory/views/list'
import AllUniversityCommission from './containers/universityCommission/views'
import AgentCommissionList from './containers/universityCommission/views/agentCommissionList'
import OldAgentCommissionList from './containers/universityCommission/views/oldAgentCommissionList'
import ReceivedCommissionList from './containers/universityCommission/views/receivedCommissionList'
import AddUpdates from './containers/updates/views'
import UpdatesList from './containers/updates/views/list'
import AllAgents from './containers/users/list/allAgents'
import AllBranchManagers from './containers/users/list/allBranchManagers'
import AllBranchUsers from './containers/users/list/allBranchUsers'
import AllMarketingManagers from './containers/users/list/allMarketingManagers'
import AllMasters from './containers/users/list/allMasters'
import AllSubAgents from './containers/users/list/allSubAgents'
import AddMaster from './containers/users/masters/addMaster'
import Agreement from './containers/users/views/agreement'
import AllNotifications from './containers/users/views/allNotifications'
import AllManagersAndUsers from './containers/users/views/allUsers'
import UserBirthdayList from './containers/users/views/birthdayList'
import AddCounsellor from './containers/users/views/counsellor/addCounsellor'
import EditCounsellor from './containers/users/views/counsellor/editCounsellor'
import UserProfile from './containers/users/views/userProfile'
import AssessmentsWarnings from './containers/warningDepartment/views/assessments'
import WaitingToApplyWarnings from './containers/warningDepartment/views/waitingToApply'

import AgentFootFall from './containers/FootFall/views/agentFootFall'
import RequestForTrainingList from './containers/FrontendComponents/trainingRequest/list'
import OnlineAgentsList from './containers/OnlineAgents/views/onlineList'
import UserTarget from './containers/UserTarget/view/userTarget'
import WhatsNew from './containers/WhatsNew/view/list'
import AllAgentCommissionList from './containers/agentCommission/views/allAgentCommissionList'
import AllRoyalArtsApplicationsList from './containers/applications/views/RoyalArtsCollege/AllRoyalArtsApplicationsList'
import RoyalArtsApplicationsList from './containers/applications/views/RoyalArtsCollege/royalArtsApplicationsList'
import RoyalArtsPendingApplicationList
    from './containers/applications/views/RoyalArtsCollege/royalArtsPendingApplicationList'
import CanadaLoaApplicationList from './containers/applications/views/canadaLoaApplicationList'
import AllLesterApplicationsList from './containers/applications/views/lester/AllLesterApplicationsList'
import oxfordApplicationList from './containers/applications/views/oxfordUniversity/oxfordApplicationList'
import OxfordPendingApplicationList from './containers/applications/views/oxfordUniversity/oxfordPendingApplicationList'
import OxfordPendingInterviewList from './containers/applications/views/oxfordUniversity/oxfordPendingInterviewList'
import OxfordInterviewApplications from './containers/applications/views/oxfordUniversity/interviewApplications'
import BankAccountList from './containers/bankAccount/views/list'
import ApprovedWithdrawals from './containers/commissionWithdrawal/view/approvedWithdrawals'
import CommissionWithdrawal from './containers/commissionWithdrawal/view/list'
import CounsellingList from './containers/counsellingList/view/list'
import DailyAgentReport from './containers/dailyAgentReport/view/list'
import {listDailyExpense} from './containers/dailyExpense/actions/dailyExpense'
import DailyExpenseList from './containers/dailyExpense/views/all'
import ExpenseWithdrawalList from './containers/dailyExpense/views/expenseWithdrawlList'
import DailyExpenseListForUser from './containers/dailyExpense/views/listForMarketingUser'
import DirectReportingComponent from './containers/dashboard/marketingDashboard/directReportingComponent'
import {AdminDashboard} from './containers/dashboard/views/adminDashboard'
import adminGraph from './containers/dashboard/views/adminGraph'
import WeeklyReport from './containers/dashboard/weeklyReport'
import CanadaOnShoreList from './containers/onShoreCanada/views/index'
import MarketingReportsApplicationList from './containers/reporting/marketingReports/list'
import SelfReport from './containers/reporting/selfReport'
import ReferredAgentCommission from './containers/universityCommission/views/referredAgentCommission'
import UnizHomeEnquiry from './containers/unizHomeEnquiry/view'
import AgentEnquiryList from './containers/unizHomeEnquiry/view/agentEnquiryList'
import StudentEnquiryList from './containers/unizHomeEnquiry/view/studentEnquiry'
import StudentList from './containers/unizHomeEnquiry/view/studentEnquiryList'
import HelpComponent from './containers/dashboard/help'
import EventList from './containers/Events/EventList'
import CanadaDirectUniApp from './containers/applications/views/canadaDirectUniApp'
import AddExpense from './containers/dailyExpense/views/add'
import AddIndianCity from './containers/indianCity/view/add'

let menu = [
    {
        path: '/dashboard',
        title: 'Dashboard',
        name: 'Dashboard',
        icon: 'dashboard',
        key: 'dashboard',
        homepage: true,
        component: Dashboard,
        authority: [
            'student',
            'admin',
            'agent',
            'subAgent',
            'branchManager',
            'branchUser',
            'master',
            'interviewer',
            'university',
            'userManager',
            'hr',
            'reception',
            'marketingManager'
        ]
    },

    {
        path: '/subAgent',
        name: 'Our Users',
        title: 'Our Users',
        icon: 'user',
        key: 'subAgent',
        dontShowOnMenu: true,
        authority: ['agent'],
        children: [
            {
                path: '/subAgent/add',
                name: 'Add User',
                title: 'Add User',
                key: 'addSubAgent',
                component: AddSubAgent,
                authority: ['agent']
            },
            {
                path: '/subAgent/all',
                name: 'All User',
                title: 'All User',
                key: 'allSubAgent',
                component: AllSubAgent,
                authority: ['agent']
            },
            {
                path: '/subAgent/edit/:id',
                name: 'Edit User',
                title: 'Edit User',
                key: 'editSubAgent',
                dontShowOnMenu: true,
                component: EditSubAgent,
                authority: ['admin', 'agent']
            }
        ]
    },

    {
        path: '/users',
        title: 'Users',
        name: 'Users',
        icon: 'user',
        key: 'user',
        homepage: true,
        authority: [
            'student',
            'admin',
            'agent',
            'subAgent',
            'branchManager',
            'branchUser',
            'master',
            'userManager'
        ],
        children: [
            {
                path: '/users/all-branch-manager',
                name: 'All Branch Manager',
                title: 'All Branch Manager',
                key: 'allBranchManagers',
                component: AllBranchManagers,
                authority: ['admin', 'userManager']
            },
            {
                path: '/users/all-marketing-manager',
                name: 'All Marketing Manager',
                title: 'All Marketing Manager',
                key: 'allMarketingManagers',
                component: AllMarketingManagers,
                authority: ['admin', 'userManager']
            },
            {
                path: '/users/all-branch-user',
                name: 'All Branch User',
                title: 'All Branch User',
                key: 'allBranchUsers',
                component: AllBranchUsers,
                authority: ['admin', 'userManager']
            },
            {
                path: '/users/all-masters',
                name: 'All Masters',
                title: 'All Masters',
                key: 'allMasters',
                component: AllMasters,
                authority: ['admin', 'userManager']
            },
            {
                path: '/users/all-agents',
                name: 'All Agents',
                title: 'All Agents',
                key: 'allAgents',
                component: AllAgents,
                restrict: true,
                authority: [
                    'admin',
                    'agent',
                    'subAgent',
                    'branchManager',
                    'branchUser',
                    'master',
                    'userManager'
                ]
            },
            {
                path: '/users/all-sub-agents',
                name: 'All Sub Agents',
                title: 'All Sub Agents',
                key: 'allSubAgents',
                restrict: true,
                component: AllSubAgents,
                authority: [
                    'admin',
                    'agent',
                    'subAgent',
                    'branchManager',
                    'branchUser',
                    'master',
                    'userManager'
                ]
            }
        ]
    },
    {
        path: '/allSubAgent',
        title: 'Partner Team',
        name: 'Partner Team',
        icon: 'user',
        key: 'allSubAgent',
        homepage: true,
        component: AllSubAgent,
        authority: ['agent']
    },
    {
        dontShowOnMenu: true,
        path: '/user/add-counsellor',
        name: 'Add Counsellor',
        title: 'Add Counsellor',
        icon: 'user',
        key: 'addCounsellor',
        component: AddCounsellor,
        authority: ['admin', 'userManager']
    },

    {
        homepage: true,
        icon: 'user',
        path: '/add-student',
        name: 'Add Student',
        title: 'Add Student',
        component: AddStudent,
        restrict: true,
        key: 'addStudent',
        authority: [
            'agent',
            'subAgent',
            'admin',
            'branchManager',
            'branchUser',
            'marketingManager'
        ]
    },
    {
        homepage: true,
        path: '/students',
        name: 'Students',
        title: 'Students',
        component: AllStudent,
        icon: 'user',
        key: 'allStudent',
        restrict: true,
        authority: ['admin', 'agent', 'subAgent', 'branchManager', 'branchUser']
    },
    {
        path: '/user',
        name: 'Users',
        icon: 'user',
        title: 'Users',
        dontShowOnMenu: true,
        key: 'Users',
        authority: ['admin', 'branchManager', 'userManager', 'hr'],
        children: [
            {
                path: '/user/add-user',
                name: 'Add User',
                title: 'Add User',
                key: 'addUser',
                component: AddUsers,
                authority: ['admin', 'branchManager', 'userManager', 'hr']
            },
            {
                path: '/user/add-markingManager',
                name: 'Add Marketing Manager',
                title: 'Add Marketing Manager',
                key: 'addMarketingManager',
                component: AddMarketingManager,
                authority: ['admin', 'userManager']
            },
            {
                path: '/user/add-master',
                name: 'Add Master',
                title: 'Add Master',
                key: 'addMaster',
                component: AddMaster,
                authority: ['admin', 'userManager']
            },
            {
                path: '/edit-markingManager/:id',
                name: 'Edit Marketing Manager',
                title: 'Edit Marketing Manager',
                key: 'editMarketingManager',
                component: EditMarketingManager,
                authority: ['admin', 'userManager']
            },
            /* {
                                       'path': '/user/all-user',
                                       'name': 'All Users',
                                       'title': 'All Users',
                                       'key': 'allUser',
                                       'component': AllUsers,
                                       'authority': [
                                         'admin'
                                       ]
                                     },*/
            {
                path: '/user/edit-user/:id',
                name: 'Edit User',
                title: 'Edit User',
                dontShowOnMenu: true,
                key: 'editUser',
                component: EditUser,
                authority: ['admin', 'branchManager']
            },
            {
                path: '/user/edit-counsellor/:id',
                name: 'Edit Counselling',
                title: 'Edit Counselling',
                dontShowOnMenu: true,
                key: 'editCounsellor',
                component: EditCounsellor,
                authority: ['admin', 'userManager']
            }
        ]
    },

    {
        path: '/feedback',
        name: 'Feedback & Complaints',
        icon: 'user',
        title: 'Feedback & Complaints',
        dontShowOnMenu: true,
        key: 'feedback',
        authority: [
            'admin',
            'branchManager',
            'branchUser',
            'agent',
            'subAgent',
            'userManager'
        ],
        children: [
            {
                path: '/feedback/add',
                name: 'Add Feedback',
                title: 'Add Feedback & Complaints',
                key: 'addFeedback',
                component: AddFeedback,
                authority: [
                    'admin',
                    'branchManager',
                    'branchUser',
                    'agent',
                    'subAgent',
                    'userManager'
                ]
            },
            {
                path: '/feedback/all',
                name: 'All Feedback & Complaints',
                title: 'All Feedback & Complaints',
                key: 'allFeedback',
                component: AllFeedback,
                authority: [
                    'admin',
                    'branchManager',
                    'branchUser',
                    'agent',
                    'subAgent',
                    'userManager'
                ]
            }
        ]
    },
    {
        path: '/notification/all',
        name: `AllNotifications`,
        icon: 'bell',
        key: 'allNotifications',
        homepage: true,
        dontShowOnMenu: true,
        component: AllNotifications,
        authority: ['admin', 'agent', 'subAgent', 'branchManager', 'branchUser']
    },
    {
        path: '/student',
        name: 'Students',
        icon: 'home',
        key: 'student',
        dontShowOnMenu: true,
        authority: [
            'admin',
            'agent',
            'subAgent',
            'branchManager',
            'branchUser',
            'marketingManager'
        ],
        children: [
            /*{
                                            'path': '/student/add',
                                            'name': 'Add Student',
                                            'title': 'Add Student',
                                            'component': AddStudent,
                                            'key': 'addStudent',
                                            'authority': [
                                              'agent',
                                              'subAgent'
                                            ]
                                          },*/
            {
                path: '/student/quick_add',
                name: 'Add Quick Student',
                title: 'Add Quick Student',
                dontShowOnMenu: true,
                component: QuickAddStudent,
                key: 'quickAddStudent',
                authority: ['admin']
            },
            /*{
                                            'path': '/student/all',
                                            'name': 'All Students',
                                            'title': 'All Students',
                                            'component': AllStudent,
                                            'key': 'allStudent'
                                          },*/
            {
                path: '/student/all-quick-added',
                name: 'Quick Added All Students',
                title: 'Quick Added All Students',
                dontShowOnMenu: true,
                component: QuickAddAllStudent,
                key: 'quickAddAllStudent',
                authority: ['admin', 'branchManager']
            },
            {
                path: '/app/edit-student/:id',
                name: 'Edit Student',
                title: 'Edit Student',
                key: 'editStudent',
                dontShowOnMenu: true,
                component: EditStudent,
                authority: ['admin', 'branchManager', 'branchUser', 'agent', 'subAgent']
            },
            {
                path: '/student/application',
                name: 'Application Profile',
                title: 'Application Profile',
                dontShowOnMenu: true,
                component: ApplicationProfile,
                key: 'applicationProfile',
                authority: ['admin', 'branchManager', 'branchUser', 'userManager']
            },
            {
                path: '/student/studentAddApplication',
                name: 'Add Application',
                title: 'Add Application',
                dontShowOnMenu: true,
                component: studentAddNewApplication,
                key: 'studentAddNewApplication',
                authority: ['student']
            },
            {
                path: '/student/referrals',
                name: 'Refer & Earn',
                title: 'Refer & Earn',
                dontShowOnMenu: true,
                component: ReferralComponent,
                key: 'referralComponent',
                authority: ['student']
            }
        ]
    },
    {
        path: '/marketingAgent',
        name: 'Agents',
        icon: 'user',
        title: 'Agents',
        key: 'marketingAgent',
        restrict: true,
        component: MarketingAgentList,
        authority: ['branchManager', 'branchUser', 'marketingManager']
    },
    {
        path: '/allAgentForMarketingUsers',
        name: 'All Agents',
        icon: 'user',
        title: 'All Agents',
        key: 'allAgentForMarketingUsers',
        restrict: true,
        component: AllAgentsForMarketingUsers,
        authority: ['branchManager', 'branchUser', 'marketingManager']
    },
    {
        path: '/company',
        name: 'Company',
        icon: 'home',
        key: 'company',
        dontShowOnMenu: true,
        authority: ['admin'],
        children: [
            {
                path: '/company/add',
                name: 'Add Company',
                title: 'Add Company',
                component: AddCompany,
                key: 'addCompany',
                authority: ['admin']
            },
            {
                path: '/company/all',
                name: 'All Company',
                title: 'All Company',
                component: AllCompany,
                key: 'allCompany',
                authority: ['admin']
            },
            {
                path: '/app/edit-company/:id',
                name: 'Edit Company',
                title: 'Edit Company',
                key: 'editCompany',
                dontShowOnMenu: true,
                component: EditCompany,
                authority: ['admin']
            },
            {
                path: '/company/profile',
                name: 'Company Profile',
                title: 'Company Profile',
                dontShowOnMenu: true,
                component: CompanyProfile,
                key: 'companyProfile',
                authority: ['admin']
            }
        ]
    },
    {
        path: '/all-applications-list',
        name: 'All Applications List',
        title: 'All Applications List',
        icon: 'home',
        key: 'allApplicationsList',
        restrict: true,
        component: AllApplicationsList,
        authority: ['admin', 'branchManager', 'userManager']
    },
    {
        path: '/application',
        name: 'Application',
        icon: 'home',
        key: 'application',
        dontShowOnMenu: true,
        authority: [
            'admin',
            'agent',
            'subAgent',
            'userManager',
            'branchUser',
            'marketingManager'
        ],
        children: [
            {
                path: '/application/view',
                name: 'View Application',
                title: 'View Application',
                key: 'singleApplication',
                component: SingleApplication
            },
            {
                path: '/applicationsList',
                name: 'Applications List',
                title: 'Applications List',
                key: 'applicationsList',
                component: ApplicationsList,
                authority: [
                    'admin',
                    'branchManager',
                    'branchUser',
                    'agent',
                    'subAgent',
                    'userManager',
                    'hr'
                ]
            },

            {
                path: '/application-students',
                name: 'Application Student List',
                title: 'Application Student List',
                key: 'applicationsStudentList',
                component: ApplicationStudentList,
                authority: [
                    'admin',
                    'branchManager',
                    'branchUser',
                    'agent',
                    'subAgent',
                    'userManager',
                    'hr'
                ]
            },

            {
                path: '/fcmt-applications-list',
                name: 'FCMT Applications List',
                title: 'FCMT Applications List',
                key: 'fcmtApplicationsList',
                component: FcmtApplicationsList,
                authority: [
                    'admin',
                    'branchManager',
                    'branchUser',
                    'agent',
                    'subAgent',
                    'userManager'
                ]
            },
            {
                path: '/oxford-applications-list',
                name: 'Oxford Applications List',
                title: 'Oxford Applications List',
                key: 'oxfordApplicationsList',
                component: oxfordApplicationList,
                authority: [
                    'admin',
                    'branchManager',
                    'branchUser',
                    'agent',
                    'subAgent',
                    'userManager'
                ]
            },
            {
                path: '/cambie-applications-list',
                name: 'cambie Applications List',
                title: 'cambie Applications List',
                key: 'cambieApplicationsList',
                component: CambieApplicationsList,
                authority: [
                    'admin',
                    'branchManager',
                    'branchUser',
                    'agent',
                    'subAgent',
                    'userManager'
                ]
            },
            {
                path: '/fcmt-applications',
                name: 'FCMT Applations List',
                title: 'FCMT Applicications List',
                key: 'fcmtApplicationsListNew',
                dontShowOnMenu: true,
                component: FcmtApplicationsListNew,
                authority: [
                    'admin',
                    'branchManager',
                    'branchUser',
                    'agent',
                    'subAgent',
                    'userManager'
                ]
            },
            {
                path: '/directUniversity-applications-list',
                name: 'Direct University Applications List',
                title: 'Direct University List',
                key: 'directUniversityApplicationList',
                component: DirectUniversityApplication,
                authority: [
                    'admin',
                    'branchManager',
                    'branchUser',
                    'agent',
                    'subAgent',
                    'userManager'
                ]
            },
            {
                path: '/lesterUniversity-applications-list',
                name: 'Lester University Applications List',
                title: 'Lester University List',
                key: 'lesterUniversityApplicationList',
                component: LesterUniversityApplication,
                authority: [
                    'admin',
                    'branchManager',
                    'branchUser',
                    'agent',
                    'subAgent',
                    'userManager'
                ]
            },
            {
                path: '/kasa-applications-list',
                name: 'Kasa University Applications List',
                title: 'Kasa University List',
                key: 'kasaApplicationList',
                component: KasaApplicationList,
                authority: [
                    'admin',
                    'branchManager',
                    'branchUser',
                    'agent',
                    'subAgent',
                    'userManager'
                ]
            },
            {
                path: '/casa-applications-list',
                name: 'Casa University Applications List',
                title: 'Casa University List',
                key: 'casaApplicationList',
                component: CasaApplicationList,
                authority: [
                    'admin',
                    'branchManager',
                    'branchUser',
                    'agent',
                    'subAgent',
                    'userManager'
                ]
            },
            {
                path: '/royal-arts-applications-list',
                name: 'Canada International Royal Arts College Applications List',
                title: 'Canada International Royal Arts College Applications List',
                key: 'royalArtsApplicationsList',
                component: RoyalArtsApplicationsList,
                authority: [
                    'admin',
                    'branchManager',
                    'branchUser',
                    'agent',
                    'subAgent',
                    'userManager'
                ]
            }
        ]
    },
    {
        path: '/fcmt-pendingLOA-applications-list',
        name: 'FCMT Approve-LOA',
        title: 'FCMT PendingLOA',
        key: 'fcmtPendingApplicationsList',
        component: FcmtPendingApplicationList,
        icon: 'unordered-list',
        restrict: true,
        authority: ['admin', 'branchUser']
    },

    {
        path: '/oxford-pending-interview-list',
        name: 'Oxford Pending Interviews',
        title: 'Oxford Pending Interviews',
        key: 'oxfordPendingInterviewList',
        component: OxfordPendingInterviewList,
        icon: 'unordered-list',
        restrict: true,
        authority: ['admin', 'branchUser']
    },
    {
        path: '/oxford-draft-applications-list',
        name: 'Oxford Approve-Draft',
        title: 'Oxford PendingDraft',
        key: 'oxfordPendingApplicationsList',
        component: OxfordPendingApplicationList,
        icon: 'unordered-list',
        restrict: true,
        authority: ['admin', 'branchUser']
    },
    {
        path: '/oxford-interview-applications-list',
        name: 'Oxford Interview Applications',
        title: 'Oxford Interview Applications',
        key: 'oxfordInterviewApplications',
        component: OxfordInterviewApplications,
        icon: 'unordered-list',
        restrict: true,
        authority: ['admin', 'branchUser', 'userManager']
    },
    {
        path: '/cambie-pendingLOA-applications-list',
        name: 'Cambie Approve-LOA',
        title: 'Cambie PendingLOA',
        key: 'cambiePendingApplicationsList',
        component: CambiePendingApplicationList,
        icon: 'unordered-list',
        restrict: true,
        authority: ['admin', 'branchUser']
    },
    {
        path: '/royal-arts-pendingLOA-applications-list',
        name: 'Royal Arts Approve-LOA',
        title: 'Royal Arts Pending LOA',
        key: 'royalArtsPendingApplicationsList',
        component: RoyalArtsPendingApplicationList,
        icon: 'unordered-list',
        restrict: true,
        authority: ['admin', 'branchUser']
    },
    {
        path: '/all-fcmt-applications-list',
        name: 'All FCMT Applications',
        title: 'All FCMT Applications',
        key: 'allFcmtApplicationsList',
        component: AllFcmtApplicationsList,
        icon: 'unordered-list',

        // 'dontShowOnMenu': true,
        restrict: true,
        authority: ['admin', 'branchUser']
    },
    {
        path: '/all-lester-applications-list',
        name: 'All Lester Applications',
        title: 'All Lester Applications',
        key: 'allLesterApplicationsList',
        component: AllLesterApplicationsList,
        icon: 'unordered-list',
        restrict: true,
        authority: ['admin', 'branchUser']
    },
    {
        path: '/all-cambie-applications-list',
        name: 'All Cambie Applications',
        title: 'All Cambie Applications',
        key: 'allCambieApplicationsList',
        component: AllCambieApplicationsList,
        icon: 'unordered-list',
        restrict: true,
        authority: ['admin', 'branchUser']
    },
    {
        path: '/all-royal-arts-applications-list',
        name: 'CIRA All Applications',
        title: 'CIRA All Applications',
        key: 'allRoyalArtsApplicationsList',
        component: AllRoyalArtsApplicationsList,
        icon: 'unordered-list',
        restrict: true,
        authority: ['admin', 'branchUser']
    },
    {
        path: '/all-oshc-applications-list',
        name: 'All OSHC Applications',
        title: 'All OSHC Applications',
        key: 'oshcApplications',
        component: OshcApplications,
        icon: 'unordered-list',
        dontShowOnMenu: true,
        restrict: true,
        authority: ['admin', 'branchUser']
    },
    {
        path: '/uk-tuition-fees-applications',
        name: 'UK Tuition Applications',
        title: 'UK Tuition Applications',
        key: 'ukTuitionFeeApplications',
        component: UkTuitionApplications,
        icon: 'unordered-list',
        dontShowOnMenu: true
    },
    {
        path: '/withdrawal',
        name: `Withdrawal`,
        icon: 'unordered-list',
        key: 'withdrawal',
        dontShowOnMenu: true,
        homepage: true,
        component: WithdrawalStudent,
        authority: ['admin', 'agent', 'subAgent']
    },
    {
        path: '/message',
        name: 'Message',
        title: 'Message',
        icon: 'message',
        key: 'message',
        dontShowOnMenu: true,
        authority: ['admin', 'branchManager', 'branchUser'],
        children: [
            {
                path: '/message/send',
                name: 'Send Message',
                title: 'Send Message',
                component: AddPrivateMessage,
                key: 'sendMessage',
                authority: ['admin', 'branchManager', 'branchUser']
            },
            {
                path: '/message/all',
                name: 'All Message',
                title: 'All Message',
                component: AllPrivateMessage,
                key: 'allMessage',
                authority: ['admin', 'branchManager', 'branchUser']
            }
        ]
    },
    {
        path: '/scheduleMeeting',
        name: 'Schedule Meeting',
        title: 'Schedule Meeting',
        icon: 'message',
        dontShowOnMenu: true,
        key: 'scheduleMeeting',
        authority: [
            'admin',
            'branchManager',
            'branchUser',
            'agent',
            'student',
            'subAgent'
        ],
        children: [
            {
                path: '/scheduleMeeting/add',
                name: 'Add Schedule Meeting',
                title: 'Add Schedule Meeting',
                component: AddScheduleMeeting,
                key: 'addScheduleMeeting',
                authority: ['admin', 'branchManager', 'branchUser', 'agent']
            },
            {
                path: '/scheduleMeeting/all',
                name: 'All Schedule Meeting',
                title: 'All Schedule Meeting',
                component: AllScheduleMeeting,
                key: 'allScheduleMeeting',
                authority: [
                    'admin',
                    'branchManager',
                    'branchUser',
                    'agent',
                    'student',
                    'subAgent'
                ]
            }
        ]
    },
    {
        path: '/users/all-employees',
        name: 'All Users',
        title: 'All Users',
        icon: 'profile',
        key: 'allUsers',
        component: AllManagersAndUsers,
        authority: ['hr']
    },
    {
        path: '/evaluate_profile',
        name: `Evaluate Profile`,
        icon: 'search',
        key: 'searchCourse',
        homepage: true,
        restrict: true,
        component: EvaluateProfile,
        authority: [
            'admin',
            'agent',
            'subAgent',
            'branchManager',
            'branchUser',
            'userManager',
            'marketingManager',
            'hr'
            // 'student',
        ]
    },
    {
        path: '/assessment-department',
        name: `Assessment Department`,
        icon: 'search',
        key: 'assessmentDepartment',
        restrict: true,
        component: AssessmentApplications,
        authority: ['admin', 'branchManager', 'branchUser']
    },
    {
        path: '/approved-agents',
        name: `Approved Agent`,
        icon: 'user',
        key: 'approvedAgents',
        homepage: true,
        restrict: true,
        component: ApprovedAgentsList,
        authority: [
            'admin',
            'branchManager',
            'branchUser',
            'userManager',
            'marketingManager',
            'hr'
        ]
    },
    {
        path: '/broadcast-mail',
        name: `Broadcast Mail`,
        icon: 'search',
        key: 'sendMailToAgent',
        homepage: true,
        restrict: true,
        component: SendMailToAgents,
        authority: ['admin', 'branchManager', 'branchUser', 'hr']
    },

    {
        path: '/commission',
        name: 'Commission',
        icon: 'database',
        key: 'commission',
        restrict: true,
        authority: ['admin', 'branchManager', 'branchUser'],
        children: [
            {
                path: '/commission/university-commission',
                name: 'University Commission',
                title: 'University Commission',
                component: AllUniversityCommission,
                key: 'universityCommission',
                restrict: true,
                authority: ['admin', 'branchManager', 'branchUser']
            },
            {
                path: '/commission/received-commission',
                name: 'Received Commission',
                title: 'Received Commission',
                component: ReceivedCommissionList,
                key: 'receivedCommissionList',
                restrict: true,
                authority: ['admin', 'branchManager', 'branchUser']
            },
            {
                path: '/commission/agent-old-commission',
                name: 'Agent Old Commission',
                title: 'Agent Old Commission',
                component: OldAgentCommissionList,
                key: 'agentOldCommissionList',
                restrict: true,
                authority: ['admin', 'branchManager', 'branchUser']
            },
            {
                path: '/commission/agent-commission',
                name: 'Agent Commission',
                title: 'Agent Commission',
                component: AgentCommissionList,
                key: 'agentCommissionList',
                restrict: true,
                authority: ['admin', 'branchManager', 'branchUser']
            }
        ]
    },
    {
        path: '/all-agent-commission',
        name: 'All Commissions',
        title: 'All Agent Commission',
        component: AllAgentCommissionList,
        key: 'allAgentCommissionList',
        icon: 'user',
        restrict: true,
        authority: ['admin', 'branchUser']
    },
    {
        path: '/commission-withdrawal-list',
        name: 'Commission Withdrawal',
        title: 'Commission Withdrawal',
        component: CommissionWithdrawal,
        key: 'commissionWithdrawal',
        restrict: true,
        icon: 'user',
        authority: ['admin', 'branchUser']
    },
    {
        path: '/referred-agent-commission',
        name: 'Referred Commission',
        title: 'Referred Commission',
        component: ReferredAgentCommission,
        key: 'referredAgentCommission',
        icon: 'user',
        restrict: true
    },

    {
        path: '/approved-withdrawals',
        title: 'Approved Withdrawals',
        name: 'Approved Withdrawals',
        icon: 'user',
        key: 'approvedWithdrawals',
        restrict: true,
        component: ApprovedWithdrawals,
        authority: ['branchUser']
    },

    {
        path: '/masters',
        name: 'Masters',
        icon: 'database',
        key: 'masters',
        homepage: true,
        restrict: true,
        authority: ['admin', 'master', 'branchManager', 'userManager'],
        children: [
            {
                path: '/masters/all-Pendencies',
                name: 'All Pendencies',
                title: 'All Pendencies',
                key: 'allPendencies',
                component: AllPendency,
                authority: ['admin', 'userManager']
            },
            {
                path: '/masters/all-sample-document',
                name: 'All Sample Document',
                title: 'All Sample Document',
                key: 'allSampleDocument',
                component: AllSampleDocument,
                authority: ['admin', 'userManager']
            },
            {
                path: '/masters/add-country',
                name: 'Add Country',
                title: 'Add Country',
                dontShowOnMenu: true,
                component: AddCountry,
                authority: ['admin', 'userManager']
            },
            {
                path: '/masters/edit-country',
                name: 'Update Country',
                title: 'Update Country',
                key: 'editCountry',
                dontShowOnMenu: true,
                component: EditCountry,
                authority: ['admin', 'userManager']
            },
            {
                path: '/masters/all-country',
                name: 'All Countries',
                title: 'All Countries',
                key: 'allCountries',
                component: AllCountries,
                restrict: true,
                authority: ['admin', 'master', 'userManager']
            },
            {
                path: '/masters/add-state',
                name: 'Add State',
                title: 'Add State',
                dontShowOnMenu: true,
                component: AddState,
                authority: ['admin', 'master', 'userManager']
            },
            {
                path: '/masters/edit-state',
                name: 'Update State',
                title: 'Update State',
                key: 'editState',
                dontShowOnMenu: true,
                component: EditState,
                authority: ['admin', 'master', 'userManager']
            },
            {
                path: '/masters/all-state',
                name: 'All States',
                title: 'All States',
                component: ListAllStates,
                authority: ['admin', 'master', 'userManager']
            },
            {
                path: '/masters/add-city',
                name: 'Add city',
                title: 'Add city',
                dontShowOnMenu: true,
                component: AddCity,
                prefixComp: 'masters',
                authority: ['admin', 'master', 'userManager']
            },
            {
                path: '/masters/edit-city',
                name: 'Update city',
                title: 'Update city',
                key: 'editCity',
                dontShowOnMenu: true,
                component: EditCity,
                authority: ['admin', 'master', 'userManager']
            },
            {
                path: '/masters/all-city',
                name: 'All cities',
                title: 'All cities',
                component: ListAllCities,
                authority: ['admin', 'master', 'userManager']
            },

            {
                path: '/masters/add-university',
                name: 'Add University',
                title: 'Add University',
                dontShowOnMenu: true,
                component: AddUniversity,
                authority: ['admin', 'master', 'userManager']
            },
            {
                path: '/masters/edit-university',
                name: 'Update University',
                title: 'Update University',
                key: 'editUniversity',
                dontShowOnMenu: true,
                restrict: true,
                component: EditUniversity,
                authority: [
                    'admin',
                    'master',
                    'branchManager',
                    'userManager',
                    'branchUser'
                ]
            },
            {
                path: '/masters/all-university',
                name: 'All Universities',
                title: 'All Universities',
                key: 'allUniversities',
                component: ListAllUniversities,
                restrict: true,
                authority: ['admin', 'master', 'branchManager', 'userManager']
            },
            {
                path: '/masters/add-course',
                name: 'Add Course',
                title: 'Add Course',
                dontShowOnMenu: true,
                component: AddCourse,
                authority: ['admin', 'master', 'userManager']
            },
            {
                path: '/masters/all-course',
                name: 'All Course',
                title: 'All Course',
                key: 'allCourse',
                component: AllCourse,
                authority: ['admin', 'master', 'userManager']
            },
            {
                path: '/masters/edit-course/:id',
                name: 'Edit Course',
                title: 'Edit Course',
                key: 'editCourse',
                dontShowOnMenu: true,
                component: EditCourse,
                authority: ['admin', 'master', 'userManager']
            },
            {
                path: '/masters/add-campus',
                name: 'Add campus',
                title: 'Add campus',
                dontShowOnMenu: true,
                component: AddCampus,
                authority: ['admin', 'master', 'userManager']
            },
            {
                path: '/masters/edit-campus',
                name: 'Update campus',
                title: 'Update campus',
                key: 'editCampus',
                dontShowOnMenu: true,
                component: EditCampus,
                authority: ['admin', 'master', 'userManager']
            },
            {
                path: '/masters/all-campus',
                name: 'All campus',
                title: 'All campus',
                key: 'allCampus',
                component: ListAllCampus,
                authority: ['admin', 'master', 'userManager']
            },
            {
                path: '/masters/assign-intake',
                name: 'All Assign Intake',
                title: 'All Assign Intake',
                key: 'assignIntake',
                component: AssignIntake,
                restrict: true
                // 'authority': [
                //   'admin',
                //   'master'
                // ]
            },

            {
                path: '/masters/all-course-category',
                name: 'All Course Category',
                title: 'All Course Category',
                key: 'allCourseCategory',
                component: AllCourseCategory,
                authority: ['admin', 'userManager']
            },
            // {
            //   'path': '/masters/all-intake',
            //   'name': 'All Intake',
            //   'title': 'All Intake',
            //   'key': 'allIntake',
            //   'component': AllIntakes,
            //   'authority': [
            //     'admin',
            //     'master'
            //   ]
            // },
            // {
            //   'path': '/masters/add-intake',
            //   'name': 'Add intake',
            //   'title': 'Add Intake',
            //   'dontShowOnMenu': true,
            //   'component': AddIntake,
            //   'authority': [
            //     'admin',
            //     'master'
            //   ]
            // },
            // {
            //   'path': '/masters/edit-intake',
            //   'name': 'Edit intake',
            //   'key': 'edit-intake',
            //   'title': 'Edit Intake',
            //   'dontShowOnMenu': true,
            //   'component': EditIntake,
            //   'authority': [
            //     'admin',
            //     'master'
            //   ]
            // }

            {
                path: '/masters/add-banner',
                dontShowOnMenu: true,
                name: 'Add Banners',
                title: 'Add Banners',
                key: 'addBanners',
                component: AddBanners,
                authority: ['admin', 'userManager']
            },
            {
                path: '/masters/banners',
                name: 'All Banners',
                title: 'All Banners',
                key: 'banners',
                component: Banners,
                authority: ['admin', 'userManager']
            },

            {
                dontShowOnMenu: true,
                path: '/masters/add-target',
                name: 'Add Target',
                title: 'Add Target',
                component: AddTarget,
                key: 'addTarget',
                authority: ['admin', 'userManager']
            },
            {
                // 'dontShowOnMenu': true,
                // 'icon': 'user',
                path: '/masters/target-list',
                name: 'All Target List',
                title: 'All Target List',
                component: TargetList,
                key: 'targetList',
                authority: ['admin', 'userManager']
            },
            {
                dontShowOnMenu: true,
                // 'icon': 'user',
                path: '/masters/edit-target/:id',
                name: 'Edit Target',
                title: 'Edit Target',
                component: EditTarget,
                key: 'editTarget',
                authority: ['admin', 'userManager']
            },
            {
                dontShowOnMenu: true,
                // 'icon': 'user',
                path: '/masters/add-trustedStory',
                name: 'Add Trusted Story',
                title: 'Add Trusted Story',
                component: AddTrustedStory,
                key: 'addTrustedStory',
                authority: ['admin', 'userManager']
            },
            {
                // 'dontShowOnMenu': true,
                // 'icon': 'user',
                path: '/masters/trustedStory',
                name: 'All Trusted Story',
                title: 'All Trusted Story',
                component: TrustedStoryList,
                key: 'TrustedStoryList',
                authority: ['admin', 'userManager']
            },

            {
                dontShowOnMenu: true,
                // 'icon': 'user',
                path: '/masters/add-updates',
                name: 'Add Updates',
                title: 'Add Updates',
                component: AddUpdates,
                key: 'addUpdates',
                authority: ['admin', 'userManager']
            },
            {
                // 'dontShowOnMenu': true,
                // 'icon': 'user',
                path: '/masters/updates-list',
                name: 'All Updates List',
                title: 'All Updates List',
                component: UpdatesList,
                key: 'UpdatesList',
                authority: ['admin', 'userManager']
            },
            {
                path: '/masters/add-indian-city',
                name: 'Add Indian City',
                key: 'addIndianCity',
                authority: ['admin', 'userManager'],
                component: AddIndianCity
            },
        ]
    },

    {
        path: '/all-university',
        name: 'All Universities',
        title: 'All Universities',
        key: 'allUniversitiesForRyanAccounts', // created to show all universities in ryan accounts
        icon: 'profile',
        component: ListAllUniversities,
        restrict: true,
        authority: ['branchUser']
    },

    {
        path: '/reporting',
        name: `Reporting`,
        icon: 'profile',
        key: 'Reporting',
        component: Reporting,
        authority: ['admin', 'userManager', 'hr']
    },

    {
        path: '/reportingList',
        name: 'Reporting List',
        title: 'Reporting List',
        key: 'reportingList',
        dontShowOnMenu: true,
        component: ReportingList,
        authority: ['admin', 'userManager', 'hr']
    },
    {
        path: '/reportingApplicationList',
        name: 'Reporting List',
        title: 'Reporting List',
        key: 'reportingApplicationList',
        dontShowOnMenu: true,
        component: ReportingApplicationList,
        authority: ['admin', 'userManager', 'hr']
    },
    {
        path: '/marketingReportingApplicationList',
        name: 'Reporting List',
        title: 'Reporting List',
        key: 'marketingReportingApplicationList',
        dontShowOnMenu: true,
        component: MarketingReportsApplicationList,
        authority: ['admin', 'marketingManager']
    },
    {
        path: '/directUniversityMarketingReport',
        name: 'Direct University Marketing Report',
        title: 'Direct University Marketing Report',
        key: 'directUniversityMarketingReport',
        dontShowOnMenu: true,
        component: DirectReportingComponent,
        authority: ['admin', 'marketingManager', 'branchUser']
    },
   /*  {
         // dontShowOnMenu: true,
         path: '/event/list',
         name: 'Event Leads',
         title: 'Event Leads',
         icon: 'user',
         key: 'eventLeads',
         component: Event,
         authority: ['admin'],
         restrict: true
     },*/
    {

        path: '/eventList',
        name: 'Event List',
        title: 'Event List',
        icon: 'user',
        key: 'eventList',
        component: EventList,
        authority: ['admin', 'userManager']
    },
    {
        path: '/change-password',
        name: `Change Password`,
        icon: 'key',
        key: 'changePassword',
        dontShowOnMenu: true,
        homepage: true,
        component: ChangePassword,
        authority: [
            'admin',
            'agent',
            'subAgent',
            'branchManager',
            'branchUser',
            'student',
            'userManager'
        ]
    },
    {
        path: '/update-password',
        name: `Update Password`,
        icon: 'key',
        key: 'updatePassword',
        dontShowOnMenu: true,
        homepage: true,
        component: UpdatePassword,
        authority: ['agent', 'subAgent']
    },
    {
        path: '/logout',
        name: `Logout`,
        icon: 'logout',
        key: 'logout',
        homepage: true,
        dontShowOnMenu: true,
        component: LogoutComponent,
        authority: ['student']
    },
    {
        path: '/download_report/:id',
        name: `downloadReport`,
        icon: 'logout',
        key: 'downloadReport',
        dontShowOnMenu: true,
        component: DownloadReport,
        restrict: true,
        authority: ['admin', 'branchManager', 'branchUser', 'marketingManager']
    },
    {
        path: '/news-enquiry',
        title: 'News Enquiry',
        name: 'News Enquiry',
        icon: 'user',
        dontShowOnMenu: true,
        key: 'newsEnquiry',
        homepage: true,
        component: AllNewsEnquiryList,
        authority: ['admin', 'studentInsurance']
    },
    {
        path: '/all-commission',
        title: 'All Commission',
        name: 'All Commission',
        icon: 'user',
        dontShowOnMenu: true,
        key: 'allCommission',
        homepage: true,
        component: AllCommission,
        authority: ['agent']
    },
    {
        path: '/birthdayList',
        title: 'Birthday List',
        name: 'Birthday List',
        icon: 'user',
        key: 'birthdayList',
        homepage: true,
        component: UserBirthdayList,
        authority: ['hr']
    },
    {
        path: '/agent-commission',
        title: 'Agent Commission',
        name: 'Agent Commission',
        icon: 'user',
        dontShowOnMenu: true,
        key: 'agentCommission',
        restrict: true,
        homepage: true,
        component: commissionAgentList,
        authority: ['admin', 'userManager', 'branchUser']
    },
    {
        path: '/commission-structure',
        title: 'Commission Structure',
        name: 'Commission',
        icon: 'user',
        dontShowOnMenu: false,
        key: 'commissionStructure',
        homepage: true,
        component: CommissionStructure,
        restrict: true,
        authority: ['agent', 'admin', 'marketingManager', 'branchUser']
    },
    {
        path: '/agent-profile',
        title: 'Profile',
        name: 'Profile',
        icon: 'user',
        dontShowOnMenu: true,
        key: 'AgentProfile',
        homepage: true,
        component: AgentProfile,
        authority: ['agent']
    },
    {
        path: '/user-profile',
        title: 'Profile',
        name: 'Profile',
        icon: 'user',
        dontShowOnMenu: true,
        key: 'userProfile',
        homepage: true,
        component: UserProfile,
        authority: ['admin', 'branchUser', 'branchManager', 'userManager']
    },
    {
        path: '/daily-leads',
        title: 'Daily Leads',
        name: 'Daily Leads',
        icon: 'user',
        dontShowOnMenu: true,
        restrict: true,
        key: 'dailyLeads',
        homepage: true,
        component: Leads
    },
    {
        path: '/agreement',
        title: 'Agreement',
        name: 'Agreement',
        icon: 'user',
        dontShowOnMenu: true,
        key: 'agreement',
        homepage: true,
        component: Agreement
    },

    {
        path: '/chat-box',
        title: 'ChatBox',
        name: 'ChatBox',
        icon: 'user',
        dontShowOnMenu: true,
        key: 'chatBox',
        homepage: true,
        component: ChatBox
    },
    {
        homepage: true,
        icon: 'user',
        path: '/assessments-warning',
        name: 'Assessments Warnings',
        title: 'Assessments Warnings',
        component: AssessmentsWarnings,
        key: 'assessmentsWarnings',
        authority: ['branchUser']
    },
    {
        homepage: true,
        icon: 'user',
        path: '/waiting-to-apply-warnings',
        name: 'Waiting To Apply Warnings',
        title: 'Waiting To Apply Warnings',
        component: WaitingToApplyWarnings,
        key: 'waitingToApplyWarnings',
        authority: ['branchUser']
    },
    {
        dontShowOnMenu: true,
        icon: 'user',
        path: '/insurance',
        name: 'Insurance',
        title: 'Insurance',
        component: InsuranceList,
        key: 'insuranceList',
        authority: ['agent', 'admin', 'branchUser', 'branchManager']
    },
    {
        dontShowOnMenu: true,
        icon: 'user',
        path: '/australiaOnShoreStudentList',
        name: 'Australia Onshore',
        title: 'Australia Onshore',
        component: AustraliaOffShoreList,
        key: 'australiaOnShoreList',
        restrict: true,
        authority: ['agent', 'admin', 'branchUser', 'branchManager']
    },
    {
        dontShowOnMenu: true,
        icon: 'user',
        path: '/canadaOnShoreStudentList',
        name: 'Canada Onshore',
        title: 'Canada Onshore',
        component: CanadaOnShoreList,
        key: 'canadaOnShoreList',
        restrict: true,
        authority: ['agent', 'admin', 'branchUser', 'branchManager', 'subAgent']
    },
    {
        dontShowOnMenu: true,
        icon: 'user',
        path: '/australiaOnShoreStudentListTemp',
        name: 'Australia Onshore',
        title: 'Australia Onshore',
        component: IndexAustralia,
        key: 'australiaOnShoreListTemp',
        restrict: true,
        authority: ['agent', 'admin', 'branchUser', 'branchManager']
    },

    {
        dontShowOnMenu: true,
        icon: 'user',
        path: '/studentInsurance',
        name: 'Student Insurance',
        title: 'Student Insurance',
        component: StudentInsurance,
        key: 'studentInsurance',
        authority: ['agent', 'admin', 'branchUser', 'branchManager']
    },
    {
        dontShowOnMenu: true,
        icon: 'user',
        path: '/add-meetings',
        name: 'Add Meeting',
        title: 'Add Meeting',
        component: AddMeetings,
        key: 'addMeetings',
        authority: ['admin', 'branchUser']
    },
    // comment no use as of now
    {
        dontShowOnMenu: true,
        icon: 'user',
        path: '/counsellor-dashboard',
        name: 'Counsellor Dashboard',
        title: 'Counsellor Dashboard',
        component: counsellorDashboard,
        key: 'counsellorDashboard',
        authority: ['admin', 'branchUser']
    },
    {
        icon: 'user',
        path: '/marketing-report',
        name: 'Marketing Report',
        title: 'Marketing Report',
        component: SelfReport,
        key: 'selfReport',
        authority: ['branchUser']
    },

    {
        path: '/counselling-list',
        name: 'Counselling List',
        icon: 'user',
        key: 'counsellingList',
        authority: ['admin', 'branchUser'],
        component: CounsellingList
    },
    {
        path: '/daily-agent-report',
        name: 'Daily Agent Report',
        icon: 'user',
        key: 'dailyAgentReport',
        restrict: true,
        authority: ['admin', 'branchManager'],
        component: DailyAgentReport
    },
    {
        path: '/direct-uni-app-report',
        name: 'Direct University App Report',
        icon: 'user',
        key: 'canadaDirectUniApp',
        dontShowOnMenu: true,
        authority: ['admin', 'branchManager'],
        component: CanadaDirectUniApp
    },


    {
        dontShowOnMenu: true,
        homepage: true,
        icon: 'user',
        path: '/add-Expense',
        name: 'Add Expense',
        title: 'Add Expense',
        component: AddExpense,
        key: 'addExpense',
        authority: ['branchUser', 'marketingManager', 'branchManager']
    },

    {
        path: '/expense',
        name: 'Expense',
        icon: 'database',
        key: 'expense',
        homepage: true,
        restrict: true,
        authority: ['admin', 'branchUser'],
        children: [
            {
                path: '/expense/daily-Expense-List',
                name: 'Daily Expense List',
                key: 'dailyExpenseList',
                restrict: true,
                authority: ['admin', 'branchUser'],
                component: DailyExpenseList
            },
            {
                path: '/expense/withdrawal-List',
                name: 'Expense Withdraw List',
                key: 'expenseWithDrawList',
                restrict: true,
                authority: ['admin', 'branchUser'],
                component: ExpenseWithdrawalList
            },
        ]
    },
    {
        path: '/expense-List',
        name: 'Daily Expense List',
        icon: 'user',
        key: 'dailyExpenseListForMarketingUser',
        restrict: true,
        authority: ['branchUser', 'marketingManager', 'branchManager'],
        component: DailyExpenseListForUser
    },

    {
        path: '/training-list',
        name: 'Request For Trng List',
        icon: 'user',
        key: 'trainingList',
        restrict: true,
        authority: ['admin', 'userManager'],
        component: RequestForTrainingList
    },
    {
        path: '/whats-New',
        name: 'Whats New',
        icon: 'user',
        key: 'whatsNew',
        restrict: true,
        authority: ['admin'],
        component: WhatsNew
    },
    {
        path: '/user-target',
        name: 'User Target',
        icon: 'user',
        key: 'userTarget',
        authority: ['admin'],
        component: UserTarget
    },


    {
        path: '/unizHomeEnquiry',
        name: 'Uniz Home Enquiry',
        icon: 'unizHomeEnquiry',
        key: 'unizHomeEnquiry',
        restrict: true,
        // authority: ['admin'],
        children: [
            {
                path: '/unizHomeEnquiry/studentEnquiryList',
                name: 'Student Enquiry List',
                title: 'Student Enquiry List',

                restrict: true,
                key: 'studentEnquiryList',
                // authority: ['admin'],
                component: StudentList
            },
            {
                path: '/unizHomeEnquiry/agentEnquiryList',
                name: 'Agent Enquiry List',
                title: 'Agent Enquiry List',

                restrict: true,
                key: 'agentEnquiryList',
                // authority: ['admin'],
                component: AgentEnquiryList
            }
        ]
    },
    {
        path: '/agentFootFall',
        name: 'Agent FootFall',
        icon: 'user',
        key: 'agentFootFall',
        dontShowOnMenu: true,
        authority: ['admin'],
        component: AgentFootFall
    },
    {
        path: '/online-agents-list',
        name: 'AgentCountryWise',
        icon: 'user',
        key: 'onlineAgentsList',
        authority: ['admin'],
        dontShowOnMenu: true,
        component: OnlineAgentsList
    },
    {
        path: '/canadaApplicationsList',
        name: 'Canada Applications List',
        // title: 'Canada Applications List',
        icon: 'user',
        key: 'canadaApplicationList',
        authority: ['admin', 'branchManager', 'branchUser'],
        component: CanadaLoaApplicationList,
        restrict: true
    },
    {
        path: '/supporting-team',
        name: 'Supporting Team',
        icon: 'user',
        key: 'help',
        'dontShowOnMenu': true,
        authority: ['agent'],
        component: HelpComponent
    }
    // {//   homepage: true,
    //   icon: 'user',
    //   path: '/weeklyReports',
    //   name: 'Weekly Reports',
    //   title: 'Weekly Reports',
    //   key: 'weeklyReports',

    //   component: WeeklyReport,
    //   authority: ['admin', 'marketingManager']
    // }
    // {
    //   path: '/student-accommodation',
    //   name: 'StudentAccomodation',
    //   icon: 'user',
    //   key: 'singleProperty',
    //   authority: ['admin'],
    //   dontShowOnMenu: true,
    //   component: SingleProperty
    // }
    // comment no use as of now
    // {
    //     path: '/enrolledDashboard',
    //     name: 'Enrolled Dashboard',
    //     icon: 'user',
    //     key: 'enrolledDashboard',
    //     dontShowOnMenu: true,
    //     component: enrolledDashboard
    // },
    /*  {
                path: '/adminGraph',
                name: 'Admin Graph',
                icon: 'user',
                key: 'adminGraph',
                authority: ['admin', 'branchUser'],
                component: adminGraph
            },
            {
                path: '/bankAccount',
                name: 'Bank Accounts',
                icon: 'user',
                key: 'bankAccountList',
                component: BankAccountList,
                authority: ['agent']
            },*/
]

//<editor-fold desc="Functions Exports">
export const getUrlPushWrapper = (keyString, query) => {
    return push(getUrlPath(keyString, query))
}

export const getUrlPath = (keyString, params) => {
    if (!params) params = {}

    let keyArr = keyString.split('.')

    let val = _.find(menu, p => p.key === keyArr[0])

    if (!val) {
        return `/`
    }

    if (keyArr.length === 2) {
        val = _.find(val.children, p => p.key === keyArr[1])
    }

    if (!val) {
        return `/`
    }

    let queryString = Object.keys(params)
        .map(key => key + '=' + params[key])
        .join('&')

    return `${val.path}?${queryString}`
}

export const getPushPathWrapper = (keyString, params) => {
    let obj = getUrlObject(keyString)

    if (obj) {
        const path = new Path(obj.path)

        return push(path.build(params))
    }

    return 'error'
}

export const getUrlParams = (keyString, route) => {
    let obj = getUrlObject(keyString)

    if (obj) {
        const path = new Path(obj.path)

        return path.test(route)
    }

    return {error: true}
}

export const getUrlObject = keyString => {
    let keyArr = keyString.split('.')

    let val = _.find(menu, p => p.key === keyArr[0])

    if (!val) {
        return `/`
    }

    if (keyArr.length === 2) {
        val = _.find(val.children, p => p.key === keyArr[1])
    }

    if (!val) {
        return `/`
    }

    return val
}
//</editor-fold>

export default menu
