import React, {useEffect, useState} from "react"
import {Button, Card, Col, Drawer, Form, Input, notification, Row, Checkbox} from "antd";
import {countryIds, DocumentTypes, InputBox} from "../../../../../components/_utils/appUtils";
import {useDispatch} from "react-redux";
import {addStudent, getStudent, updateStudent} from "../../../../student/actions/student";
import GetEachFormFields from "../../../../../components/_utils/appFormUtils";
import moment from "moment";

const OxfordUpdateStudent = (props) => {
    let dispatch = useDispatch()
    let {visible, onClose, studentData, onSubmit} = props;
    const {form: {getFieldDecorator, setFieldsValue, getFieldValue}} = props
    useEffect(() => {
        events.setFieldDefaultValue()
    }, [studentData])
    let [state, setState] = useState({
        sameAsPermanentAddress: false,
        sameAsPhoneNo: false,
    })

    let events = {
        handleSubmit: (e) => {
            const {form} = props
            e.preventDefault()
            form.validateFieldsAndScroll(async (err, valData) => {
                if (!err) {
                    let resp = await dispatch(updateStudent({...valData, ...state}, studentData._id));
                    if (resp && resp.success) {
                        onSubmit()
                    }
                } else {
                    notification.warning({
                        message: 'Fill All Required Fields'
                    })
                }
            })
        },
        setFieldDefaultValue: async () => {
            if (studentData && studentData._id) {
                let student = await dispatch(getStudent(studentData._id));
                if (student && student._id) {
                    setState({
                        sameAsPermanentAddress: student.sameAsPermanentAddress ? student.sameAsPermanentAddress : false,
                        sameAsPhoneNo: student.sameAsPhoneNo ? student.sameAsPhoneNo : false,
                    })
                    setTimeout(() => {
                        setFieldsValue({
                            name: student.name,
                            studentPhoneNumber: student.studentPhoneNumber,
                            presentAddress: student.presentAddress,
                            skypeId: student.skypeId,
                            facebookId: student.facebookId,
                            instaId: student.instaId,
                            linkedinId: student.linkedinId,
                            landlineNo: student.landlineNo,
                            whatsappNumber: student.whatsappNumber,
                            address: student.address,
                        })
                    }, 500)
                }
            }
        }
    }


    const _updateState = (data) => {
        setState((prevState) => {
            return {
                ...prevState,
                ...data
            }
        })
    }
    const formItemLayout = {
        labelCol: {
            xs: {span: 24},
            sm: {span: 24},
            md: {span: 24}
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 24},
            md: {span: 24}
        }
    }

    let formFields = {
        generateInputs: [
            {key: 'name', label: 'Name', required: true, placeholder: 'Enter Name'},
            {
                key: 'studentPhoneNumber',
                label: 'Student Phone Number',
                required: true,
                placeholder: 'Enter Student Phone Number'
            },
            {key: 'skypeId', label: 'Skype ID', required: true, placeholder: 'Enter Skype ID'},
            {key: 'facebookId', label: 'Facebook ID', placeholder: 'Enter Facebook ID'},
            {key: 'instaId', label: 'Insta ID', placeholder: 'Enter Insta ID'},
            {key: 'linkedinId', label: 'Linkedin ID', placeholder: 'Enter Linkedin ID'},
            {key: 'landlineNo', label: 'Landline No', placeholder: 'Enter Landline No'},
            {
                key: 'address',
                label: 'Permanent Address',
                placeholder: 'Enter Permanent Address',
                type: "textarea",
                required: true
            },
            {
                key: 'sameAsPhoneNo',
                label: 'Whatsapp no',
                customField: (
                    <>
                        <label style={{marginTop: 20}}>
                            <Checkbox
                                onChange={() => {
                                    setState({
                                        ...state,
                                        sameAsPhoneNo: !state.sameAsPhoneNo
                                    })
                                    if (!state.sameAsPhoneNo) {
                                        setFieldsValue({whatsappNumber: getFieldValue('studentPhoneNumber')})
                                    }
                                }}
                                checked={state.sameAsPhoneNo}
                                name={'sameAsPhoneNo'}
                                value={state.sameAsPhoneNo}/>
                            <span className={'ml10'}>
                                Whatsapp Number same as Phone No
                            </span>
                        </label>
                    </>
                )
            },
            {
                key: 'sameAsPermanentAddress',
                label: 'Present Address',
                customField: (
                    <>
                        <label style={{marginTop: 20}}>
                            <Checkbox
                                onChange={() => {
                                    setState({
                                        ...state,
                                        sameAsPermanentAddress: !state.sameAsPermanentAddress
                                    })
                                    if (!state.sameAsPermanentAddress) {
                                        setFieldsValue({presentAddress: getFieldValue('address')})
                                    }
                                }}
                                name={'sameAsPermanentAddress'}
                                checked={state.sameAsPermanentAddress}
                                value={state.sameAsPermanentAddress}
                            />
                            <span className={'ml10'}>
                                Present Address same as Permanent Address
                            </span>
                        </label>
                    </>
                )
            },
            {
                key: 'whatsappNumber',
                label: 'Whatsapp No',
                type: 'number',
                required: true,
                placeholder: 'Enter Whatsapp No',
                disabled: state.sameAsPhoneNo
            },
            {
                key: 'presentAddress', label: 'Present Address', placeholder: 'Enter Preset Address',
                type: "textarea",
                disabled: state.sameAsPermanentAddress
            },

        ]
    }


    const updateStudentEvents = async () => {
        /* if (!state.name) {
             notification.warn({message: "Enter Student Name."})
             return
         }
         if (!state.studentPhoneNumber) {
             notification.warn({message: "Enter Student Phone No."})
             return
         }
         if (!state.skypeId) {
             notification.warn({message: "Enter Student Skype Id."})
             return
         }
         let resp = await dispatch(updateStudent(state, student._id));
         if (resp && resp.success) {
             onSubmit()
         }*/
    }

    return (
        <Drawer
            visible={visible}
            onClose={onClose}
            title={'Update Student'}
            width={'52%'}
        >
            <div className="card unizportal">
                <Form>
                    <Row gutter={16}>
                        {formFields.generateInputs.map((item, key) => {
                            return (
                                item.customField ?
                                    <Col span={item.span ? item.span : 12}>
                                        {item.customField}
                                    </Col> :
                                    <Col span={item.span ? item.span : 12} key={key}>
                                        <GetEachFormFields
                                            item={item}
                                            getFieldDecorator={getFieldDecorator}
                                            formItemLayout={formItemLayout}/>
                                    </Col>
                            )
                        })}
                    </Row>
                    <button className='btn' type='submit' onClick={events.handleSubmit}>save
                    </button>
                </Form>
            </div>
        </Drawer>
    )
}
const WrappedOxfordUpdateStudent = Form.create()(OxfordUpdateStudent)
export default WrappedOxfordUpdateStudent
