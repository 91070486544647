import {Icon, Menu} from 'antd'
import classNames from 'classnames'
import React, {PureComponent} from 'react'
import {Link} from 'react-router-dom'
import {departmentObj} from '../_utils/appUtils'
import {urlToList} from '../_utils/pathTools'
import {isUrl} from '../_utils/utils'
import {getMenuMatches} from './SiderMenuUtils'
import styles from './index.less'
// import Menu from "rc-menu"
const {SubMenu} = Menu

const getIcon = icon => {
    if (typeof icon === 'string' && isUrl(icon)) {
        return <img src={icon} alt="icon" className={styles.icon}/>
    }
    if (typeof icon === 'string') {
        return <Icon type={icon}/>
        // return <Icon type={icon} component={() => <div>
        //   {/* <Icon type="mail" /> */}
        //   <div style={{ width: '100px',height:'20px', overflow: 'hidden' }}> <img src="https://homepages.cae.wisc.edu/~ece533/images/airplane.png" alt="Flowers in Chania"></img></div>
        //   <span>neha</span>
        // </div>} />
    }
    return icon
}

export default class BaseMenu extends PureComponent {
    /**
     * @memberof SiderMenu
     */
    getNavMenuItems = (menusData, parent) => {
        const {
            user,
            currentUser,
            currentUserRights: {
                userType,
                allowAddingUser,
                showAgent,
                department,
                branchManagerType,
                countryRight,
                assignIntakesRight,
                approveFCMTLoa,
                approveOxfordDraft,
                showAllUniversities,
                showAllFcmtList,
                approveCambieLoa,
                showAllCambieList,
                approveRoyalArtsLoa,
                showAllRoyalArtsList,
                showOnShoreAustralia,
                approvedAgent,
                showApprovedCommission,
                showAllLesterList,
                showCommissionStructure,
                countryId,
                showCanadaLoa,
                showUnizHomeEnquiry,
                oxfordInterviewDepartment
            }
        } = this.props
        /*   console.log('uer  uer ue ru r this.propsthis.props', this.props)
               console.log(this.props.currentUserRights, this.props.user,'++')*/
        if (!menusData) {
            return []
        }
        return menusData
            .filter(item => item.name && !item.hideInMenu)
            .map(item => {
                let condition = true

                if (item.authority) {
                    if (!item.authority.includes(userType)) {
                        condition = false
                    }
                }

                if (userType == 'agent' && item.key == 'subAgent' && !allowAddingUser) {
                    condition = false
                }
                if (item.key == 'assessmentDepartment') {
                    if (department && department == departmentObj.assDepartment) {
                        condition = true
                    } else {
                        condition = false
                    }
                }
                if (item.key == 'searchCourse' && userType == 'branchUser') {
                    if (department && department == departmentObj.interviewer) {
                        condition = false
                    }
                }
                if (item.key == 'allAgents' || item.key == 'allSubAgents') {
                    if (!showAgent) {
                        condition = false
                    }
                }
                if (item.key == 'agentCommission') {
                    condition = false
                    if (
                        userType == 'admin' ||
                        (currentUser && currentUser.email == 'accounts@ryanconsultants.com')
                    ) {
                        condition = true
                    }
                }
                if (item.key == 'commission') {
                    condition = false
                    // (userType == 'branchManager' && !branchManagerType && branchManagerType !== null) ||
                    if (
                        userType == 'admin' ||
                        (userType == 'branchManager' &&
                            !branchManagerType &&
                            branchManagerType !== null) ||
                        (currentUser && currentUser.email == 'accounts@ryanconsultants.com')
                    ) {
                        condition = true
                    }
                }
                if (item.key == 'allStudent') {
                    if (
                        department &&
                        (department == departmentObj.interviewer ||
                            department == departmentObj.warningDepartment)
                    ) {
                        condition = false
                    }
                }
                if (item.key == 'searchCourse') {
                    if (department && department == departmentObj.warningDepartment) {
                        condition = false
                    }
                }

                if (
                    item.key == 'assessmentsWarnings' ||
                    item.key == 'waitingToApplyWarnings'
                ) {
                    if (department && department == departmentObj.warningDepartment) {
                        condition = true
                    } else {
                        condition = false
                    }
                }
                if (item.key == 'fcmtPendingApplicationsList') {
                    if (approveFCMTLoa && approveFCMTLoa == true) {
                        condition = true
                    } else {
                        condition = false
                    }
                }
                if (item.key == 'oxfordPendingApplicationsList') {
                    if (approveOxfordDraft && approveOxfordDraft == true) {
                        condition = true
                    } else {
                        condition = false
                    }
                }
                if (item.key == 'cambiePendingApplicationsList') {
                    if (approveCambieLoa && approveCambieLoa == true) {
                        condition = true
                    } else {
                        condition = false
                    }
                }
                if (item.key == 'royalArtsPendingApplicationsList') {
                    if (approveRoyalArtsLoa && approveRoyalArtsLoa == true) {
                        condition = true
                    } else {
                        condition = false
                    }
                }

                if (item.key == 'australiaOnShoreList') {
                    if (showOnShoreAustralia) {
                        condition = true
                    } else {
                        condition = false
                    }
                }

                if (item.key == 'allFcmtApplicationsList') {
                    if (showAllFcmtList) {
                        condition = true
                    } else {
                        condition = false
                    }
                }
                if (item.key == 'allLesterApplicationsList') {
                    if (showAllLesterList) {
                        condition = true
                    } else {
                        condition = false
                    }
                }

                if (item.key == 'allCambieApplicationsList') {
                    if (showAllCambieList) {
                        condition = true
                    } else {
                        condition = false
                    }
                }
                if (item.key == 'allRoyalArtsApplicationsList') {
                    if (showAllRoyalArtsList) {
                        condition = true
                    } else {
                        condition = false
                    }
                }

                if (item.key == 'allUniversitiesForRyanAccounts') {
                    if (showAllUniversities && showAllUniversities == true) {
                        condition = true
                    } else {
                        condition = false
                    }
                }

                if (item.key == 'user') {
                    if (
                        userType !== 'admin' &&
                        userType !== 'branchManager' &&
                        userType !== 'userManager' &&
                        userType !== 'hr'
                    ) {
                        // show branch manager and branch user to admin, branchManager, userManager
                        if (!showAgent) {
                            condition = false
                        }
                    }
                }
                if (item.key == 'masters') {
                    if (
                        !(
                            userType == 'admin' ||
                            userType == 'master' ||
                            userType == 'userManager' ||
                            (userType == 'branchManager' && !branchManagerType)
                        )
                    ) {
                        condition = false
                    }
                }
                if (item.key == 'allCountries') {
                    if (
                        !(
                            userType == 'admin' ||
                            (userType == 'master' && countryRight == 'All')
                        )
                    ) {
                        condition = false
                    }
                }
                if (item.key == 'allApplicationsList') {
                    if (
                        userType == 'branchManager' &&
                        branchManagerType == 'marketingManager'
                    ) {
                        condition = false
                    }
                }
                if (
                    item.key == 'searchCourse' ||
                    item.key == 'searchCourse' ||
                    item.key == 'addStudent' ||
                    item.key == 'user'
                ) {
                    if (
                        userType == 'branchManager' &&
                        branchManagerType &&
                        branchManagerType == 'universityWise'
                    ) {
                        condition = false
                    }
                }
                if (item.key == 'sendMailToAgent' || item.key == 'eventList') {
                    if (
                        userType == 'branchManager' &&
                        branchManagerType &&
                        branchManagerType == 'universityWise'
                    ) {
                        condition = false
                    } else if (
                        userType == 'branchUser' &&
                        department !== departmentObj.marketing
                    ) {
                        condition = false
                    }
                }
                if (item.key == 'marketingAgent') {
                    condition = false
                    if (branchManagerType) {
                        if (branchManagerType == 'agentWise') {
                            condition = true
                        }
                    } else if (department == departmentObj.marketing) {
                        condition = true
                    }
                }

                if (item.key == 'allAgentForMarketingUsers') {
                    condition = false
                    if (branchManagerType) {
                        if (branchManagerType == 'allAgentForMarketingUsers') {
                            condition = true
                        }
                    } else if (department == departmentObj.marketing || userType == 'marketingManager') {
                        condition = true
                    }
                }

                if (item.key === 'assignIntake') {
                    condition = false
                    if (assignIntakesRight) {
                        condition = true
                    }
                }
                if (item.key == 'canadaApplicationList') {
                    condition = false
                    if (showCanadaLoa) {
                        condition = true
                    }
                }

                if (
                    item.key == 'searchCourse' ||
                    item.key == 'addStudent' ||
                    item.key == 'allStudent'
                ) {
                    condition = true
                    if (
                        (userType == 'branchUser' &&
                            department == departmentObj.counselling) ||
                        userType == 'reception'
                    ) {
                        condition = false
                    }
                }
                if (item.key == 'allStudent') {
                    condition = true
                    if (userType == 'marketingManager') {
                        condition = false
                    }
                }

                if (item.key == 'allAgentCommissionList' || item.key == 'commissionWithdrawal') {
                    condition = false
                    if (showApprovedCommission) {
                        condition = true
                    }
                }

                if (item.key == 'approvedWithdrawals') {
                    condition = false
                    if ((currentUser && currentUser.email && currentUser.email == 'accounts@ryanconsultants.com')) {
                        condition = true
                    }
                }

                if (item.key == 'commissionStructure') {
                    condition = false
                    if (userType == 'agent' || userType == 'admin' || (showCommissionStructure == true) || (userType == 'branchUser' && department == departmentObj.marketing)) {
                        condition = true
                        if (userType == 'branchUser') {
                            item.dontShowOnMenu = false
                        }
                    }
                }
                /*if (item.key == 'approvedAgents') {
                            condition = false
                            if (userType == 'admin' || (userType == 'branchUser' && department !== departmentObj.counselling)) {
                                // condition = true
                            }
                        }*/

                if (item.key == 'approvedAgents') {
                    condition = false
                    if (
                        userType == 'admin' ||
                        userType == 'userManager' ||
                        approvedAgent
                    ) {
                        condition = true
                    }
                }

                if (item.key == 'counsellingList') {
                    condition = false
                    if (
                        userType == 'admin' ||
                        (userType == 'branchUser' &&
                            department == departmentObj.counselling)
                    ) {
                        condition = true
                    }
                }
                if (item.key == 'selfReport') {
                    condition = false
                    if (
                        userType == 'branchUser' &&
                        department == departmentObj.marketing
                    ) {
                        condition = true
                    }
                }
                if (item.key == 'dailyAgentReport') {
                    condition = false
                    if ((userType == 'branchManager' && branchManagerType && branchManagerType == 'agentWise') || userType == 'admin') {
                        condition = true
                    }
                }

                if (item.key == 'referredAgentCommission') {
                    condition = false
                    if ((userType == 'branchManager' && !branchManagerType && branchManagerType !== null)) {
                        condition = true
                    }
                }

                if (item.key == 'unizHomeEnquiry') {
                    condition = false
                    if (showUnizHomeEnquiry) {
                        condition = true
                    }
                }

                if (item.key == 'oxfordInterviewApplications') {
                    if (oxfordInterviewDepartment && oxfordInterviewDepartment == true) {
                        condition = true
                    } else {
                        condition = false
                    }
                }

                if (item.key == 'oxfordPendingApplicationsList' || item.key == 'oxfordPendingInterviewList') {
                    if (approveOxfordDraft && approveOxfordDraft == true) {
                        condition = true
                    } else {
                        condition = false
                    }
                }

                if (item.key == 'allStudent' || item.key == 'addStudent' || item.key == 'searchCourse') {
                    if (!approveFCMTLoa && !approveCambieLoa && !approveRoyalArtsLoa && !showOnShoreAustralia && !oxfordInterviewDepartment && !approveOxfordDraft) {
                        condition = true
                    } else {
                        condition = false
                    }
                }

                if (item.key == 'dailyExpenseListForMarketingUser' || item.key == 'addExpense') {
                    condition = false
                    if ((userType == 'branchUser' && department == departmentObj.marketing) ||
                        (userType == 'marketingManager') ||
                        (userType == 'branchManager' && !branchManagerType && branchManagerType !== null)
                    ) {
                        condition = true
                    }
                }

                if (item.key == 'expense') {
                    condition = false
                    if (userType == 'admin' || (currentUser && currentUser.email && currentUser.email == 'accounts@ryanconsultants.com')) {
                        condition = true
                    }
                }

                if (!item.dontShowOnMenu && condition) {
                    return this.getSubMenuOrItem(item, parent)
                }
            })
            .filter(item => item)
    }

    // Get the currently selected menu
    getSelectedMenuKeys = pathname => {
        const {flatMenuKeys} = this.props
        return urlToList(pathname).map(itemPath =>
            getMenuMatches(flatMenuKeys, itemPath).pop()
        )
    }

    /**
     * get SubMenu or Item
     */
    getSubMenuOrItem = item => {
        // doc: add hideChildrenInMenu
        if (
            item.children &&
            !item.hideChildrenInMenu &&
            item.children.some(child => child.name)
        ) {
            const {name} = item
            return (
                <SubMenu
                    title={
                        item.icon ? (
                            <span>
                {getIcon(item.icon)}
                                <span>{name}</span>
              </span>
                        ) : (
                            name
                        )
                    }
                    key={item.path}>
                    {this.getNavMenuItems(item.children)}
                </SubMenu>
            )
        }
        return <Menu.Item key={item.path}>{this.getMenuItemPath(item)}</Menu.Item>
    }

    /**
     * Judge whether it is http link.return a or Link
     * @memberof SiderMenu
     */
    getMenuItemPath = item => {
        const {name, prefixComp} = item
        const itemPath = this.conversionPath(item.path)
        const icon = getIcon(item.icon)
        const {target} = item
        // Is it a http link
        if (/^https?:\/\//.test(itemPath)) {
            return (
                <a href={itemPath} target={target}>
                    {icon}
                    <span>{name}</span>
                </a>
            )
        }
        const {location, isMobile, onCollapse} = this.props
        return (
            <Link
                to={itemPath}
                target={target}
                replace={itemPath === location.pathname}
                onClick={
                    isMobile
                        ? () => {
                            onCollapse(true)
                        }
                        : undefined
                }>
                {icon}
                {/* {prefixComp ?
          <React.Fragment>
            <h3>{prefixComp}</h3> <br/>
          </React.Fragment>
          : ''}*/}
                <span>{name}</span>
            </Link>
        )
    }

    conversionPath = path => {
        if (path && path.indexOf('http') === 0) {
            return path
        }
        return `/${path || ''}`.replace(/\/+/g, '/')
    }

    render() {
        const {
            openKeys,
            navTheme,
            mode,
            location: {pathname},
            className,
            collapsed
        } = this.props
        // if pathname can't match, use the nearest parent's key
        let selectedKeys = this.getSelectedMenuKeys(pathname)
        if (!selectedKeys.length && openKeys) {
            selectedKeys = [openKeys[openKeys.length - 1]]
        }
        let props = {}
        if (openKeys && !collapsed) {
            props = {
                openKeys: openKeys.length === 0 ? [...selectedKeys] : openKeys
            }
        }
        const {handleOpenChange, style, menuData} = this.props
        const cls = classNames(className, {
            'top-nav-menu': mode === 'horizontal'
        })

        return (
            <Menu
                key="Menu"
                mode={mode}
                theme={navTheme}
                onOpenChange={handleOpenChange}
                selectedKeys={selectedKeys}
                style={style}
                className={cls}
                {...props}>
                {' '}
                {this.getNavMenuItems(menuData)}
            </Menu>
        )
    }
}
