import React, { useEffect, useState, Suspense } from 'react'

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from 'recharts'
import { Avatar, Icon, Select } from 'antd'
import _ from 'lodash'

const { Option } = Select

const commissionData = {
  ukData: {
    total: 5000
  },

  canadaData: {
    total: 6000
  },

  australiaData: {
    total: 7000
  }
}

const CommissionInfoComponent = props => {
  let { data } = props
  return (
    <div
      class="card-pane-right listing-section w-100"
      style={{ paddingLeft: '2px', marginTop: '10px', border: 'none' }}>
      <ul class="count" style={{ borderTop: '1px solid lightGrey' }}>
        <li class="pointerCls">
          <h5 style={{ fontSize: '20px', margin: '10px' }}>{data.total}</h5>
          <p style={{ fontSize: '14px', padding: '10px' }}>Total Commission</p>
        </li>
        <li class="pointerCls">
          <h5 style={{ fontSize: '20px', margin: '10px' }}>0</h5>
          <p style={{ fontSize: '14px', padding: '10px' }}>
            Uniz Portal Commission
          </p>
        </li>
        <li class="pointerCls">
          <h5 style={{ fontSize: '20px', margin: '10px' }}>0</h5>
          <p style={{ fontSize: '14px', padding: '10px' }}>Agent Commission</p>
        </li>
        <li class="pointerCls">
          <h5 style={{ fontSize: '20px', margin: '10px' }}>0</h5>
          <p style={{ fontSize: '14px', padding: '10px' }}>
            Total Upcoming Commission
          </p>
        </li>
        <li class="pointerCls">
          <h5 style={{ fontSize: '20px', margin: '10px' }}>0</h5>
          <p style={{ fontSize: '14px', padding: '10px' }}>
            Uniz Portal Upcoming Commission
          </p>
        </li>
        <li class="pointerCls">
          <h5 style={{ fontSize: '20px', margin: '10px' }}>0</h5>
          <p style={{ fontSize: '14px', padding: '10px' }}>
            Agent Upcoming Commission
          </p>
        </li>
      </ul>
    </div>
  )
}

const adminGraph = props => {
  let [showMenu, setSHowMenu] = useState(false)
  let [country, setCountry] = useState('canada')
  let countryName = country.toLowerCase()
  let chooseCountry = value => {
    value = value.toLowerCase()
    if (value == 'kingdom') {
      value = 'uk'
    }
    setCountry(value)
  }

  const data = [
    {
      name: 'January',
      CND: 4000,
      UK: 2400,
      AUS: 2400
    },
    {
      name: 'February',
      CND: 3000,
      UK: 1398,
      AUS: 2210
    },
    {
      name: 'March',
      CND: 2000,
      UK: 9800,
      AUS: 2290
    },
    {
      name: 'April',
      CND: 2780,
      UK: 3908,
      AUS: 2000
    },
    {
      name: 'May',
      CND: 1890,
      UK: 4800,
      AUS: 2181
    },
    {
      name: 'June',
      CND: 2390,
      UK: 3800,
      AUS: 2500
    },
    {
      name: 'July',
      CND: 3490,
      UK: 4300,
      AUS: 2100
    }
  ]

  console.log(data, 'dataaaa')

  return (
    <React.Fragment>
      <div
        className="d-md-flex pattern-img align-items-center"
        style={{ marginTop: '34px' }}>
        <BarChart
          width={700}
          height={250}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5
          }}
          barSize={10}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="CND" fill="#8884d8" />
          <Bar dataKey="UK" fill="#82ca9d" />
          <Bar dataKey="AUS" fill="#F4A460" />
        </BarChart>
        <div
          className="card-pane-right listing-section"
          style={{ width: '35%', padding: '2rem', marginBottom: '55px' }}>
          <div
            className=" nav-pills w-100"
            id="v-pills-tab"
            role="tablist"
            aria-orientation="vertical">
            <></>
            <a
              className={`nav-link ${countryName == 'canada' ? 'active' : ''}`}
              id="v-pills-canada-tab"
              data-toggle="pill"
              href="#v-pills-canada"
              role="tab"
              onClick={() => chooseCountry('Canada')}
              aria-controls="v-pills-canada"
              aria-selected="true">
              canada
            </a>
            <a
              className={`nav-link ${countryName == 'uk' ? 'active' : ''}`}
              id="v-pills-kingdom-tab"
              data-toggle="pill"
              href="#v-pills-kingdom"
              role="tab"
              onClick={() => chooseCountry('kingdom')}
              aria-controls="v-pills-kingdom"
              aria-selected="false">
              united kingdom
            </a>
            <a
              className={`nav-link ${
                countryName == 'australia' ? 'active' : ''
              }`}
              id="v-pills-kingdom-tab"
              data-toggle="pill"
              href="#v-pills-kingdom"
              role="tab"
              onClick={() => chooseCountry('australia')}
              aria-controls="v-pills-kingdom"
              aria-selected="false">
              Australia
            </a>
          </div>
        </div>
      </div>

      <div>
        {country == 'uk' ? (
          <Suspense fallback={<></>}>
            <CommissionInfoComponent
              data={commissionData.ukData}
              userCountryName={country}
            />
          </Suspense>
        ) : (
          ''
        )}

        {country == 'canada' ? (
          <Suspense fallback={<></>}>
            <CommissionInfoComponent
              data={commissionData.canadaData}
              userCountryName={country}
            />
          </Suspense>
        ) : (
          ''
        )}

        {country == 'australia' ? (
          <Suspense fallback={<></>}>
            <CommissionInfoComponent data={commissionData.australiaData} />
          </Suspense>
        ) : (
          ''
        )}
      </div>
    </React.Fragment>
  )
}
export default adminGraph
