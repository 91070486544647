import lodash from 'lodash'
import moment from 'moment'

export {
    default as CheckRights
} from '../../containers/WebComponent/CheckRights'
export {default as CustomInput} from './CustomInput'
export {default as DownloadLink} from './DownloadLinkComponent'
export {default as InputBox} from './InputBox'
export {default as FileInput} from './fileInput'
export {default as CapText} from './CapText'

export const EligibleLevel = [
    '10th',
    '10th + 3 year diploma',
    '12th',
    'Bachelor Degree',
    'Master Degree'
]

export const EnglishExamTypes = [
    'IELTS',
    'PTE',
    'TOEFL',
    'Duolingo English Test',
    "I don't have this"
]

export const ProgramLevel = [
    /*'Grade 9',
      'Grade 10',
      'Grade 11',
      'Grade 12',
      'English as Second Language (ESL)',*/
    '1-Year Post-Secondary Certificate',
    '2-Year Undergraduate Diploma',
    '3-Year Undergraduate Advanced Diploma',
    "3-Year Bachelor's Degree",
    "4-Year Bachelor's Degree",
    "5-Year Bachelor's Degree",
    "Postgraduate Certificate / Master's Degree",
    'Doctoral Degree (Phd, M.D., ...)'
]

export const UniversityTypes = [
    'University',
    'College' /* 'English Institute', 'High School'*/
]
export const UniversityRefArr = [
    // 'Direct',
    'Direct with Ryan',
    'Direct with Unizportal',
    'Indirect'
    // 'Channel Partner'
]

export const DefaultTablePagination = (
    newParams,
    defaultPageSize = 50,
    pageSizeOptions = ['50', '100', '200', '500', '1000']
) => {
    let params = {
        defaultPageSize,
        pageSizeOptions,
        ...newParams,
        position: 'top'
    }
    return params
}

export const FilterOption = (input, options) => {
    return options.props.children.match(new RegExp(`^${input}`, 'ig'))
}

export const qualificationOptions = [
    'X + 3 Year Diploma',
    'XII',
    'Bachelor Degree',
    'Post Graduation',
    'XII + Diploma'
]

export const displayDate = date => {
    if (date) {
        return moment(date).format('DD.MM.YYYY')
    } else {
        return null
    }
}
export const displayTime = date => {
    if (date) {
        return moment(date).format('hh:mm a')
    } else {
        return null
    }
}
export const newFormatDisplayDate = date => {
    if (date) {
        return moment(date).format('DD.MM.YYYY')
    } else {
        return null
    }
}
export const longDisplayDate = date => {
    if (date) {
        return moment(date).format('DD.MM.YYYY,h:mm A')
    } else {
        return null
    }
}
export const longStringDate = date => {
    if (date) {
        return moment(date).format('DD/MMM/YYYY, h:mm A')
    } else {
        return null
    }
}

export const newStringDate = date => {
    if (date) {
        return moment(date).format('DD/MM/YYYY, h:mm A')
    } else {
        return null
    }
}

export const DocumentTypes = [
    'Passport',
    'IELTS',
    '10th',
    '12th',
    'Bachelor DMC',
    'Backlog',
    'Degree',
    'Experience',
    'Medium of Instruction',
    'Letter of Recommendation',
    'CV',
    'Diploma',
    'Study Permit',
    'Other'
]
export const DocumentTypesForFcmt = [
    {name: 'Passport', required: true},
    {name: 'IELTS', required: true, restrict: true},
    {name: '10th', required: true, restrict: true},
    {name: '12th', required: true, restrict: true},
    {name: 'Degree', required: false},
    {name: 'Experience', required: false},
    {name: 'Diploma', required: false},
    {name: 'Other', required: false}
]

export const DocumentTypesForCanadaOnShore = [
    {name: 'Study Permit', required: true, countryCode: 'CA_+1'},
    {name: 'Other', required: false}
]

export const DocumentTypesForRegister = [
    {name: 'Passport', required: true, countryCode: ''},
    {name: 'IELTS', required: true, countryCode: ''},
    {name: 'Study Permit', required: '', countryCode: 'CA_+1'},
    {name: '10th', required: true, countryCode: ''},
    {name: '12th', required: true, countryCode: ''},
    {name: 'Bachelor DMC', required: '', countryCode: ''},
    {name: 'Degree', required: '', countryCode: ''},
    {name: 'Other', required: '', countryCode: ''}
]
export const DocumentTypesRequiredForCanada = [
    'Passport',
    'IELTS',
    'Study Permit',
    '10th',
    '12th'
]

export const DocumentRequiredForOtherCountry = [
    'Passport',
    'IELTS',
    '10th',
    '12th'
]

export const departmentList = [
    'Offer Department',
    'Assessment Department',
    'LOA/CAS/120 Department',
    'Visa Submission Department',
    'Visa Approved Department',
    'Visa Rejected Department',
    'Interview Department',
    'Warning Department',
    'Marketing Department',
    'Account Department',
    'Counselling Department' //Changing
]

export const ukApplicationSteps = [
    {name: 'New Student'},
    {name: 'Waiting To Apply'},
    {name: 'Offer Applied'},
    {name: 'Conditional Offer Received', requiredDoc: true},
    {
        name: 'Unconditional Offer Received',
        requiredDoc: true,
        feeRequired: true
    },
    {name: 'Provide CAS Documents'},
    {name: 'CAS Applied'},
    {name: 'CAS Issued', requiredDoc: true},
    {name: 'Visa File Submitted'},
    {name: 'Visa Approved', requiredDoc: true},
    {name: 'Visa Rejected', requiredDoc: true},
    {name: 'Visa Refund Applied'},
    {name: 'Case Approved'},
    {name: 'Case Close'}
]

export const canadaApplicationSteps = [
    {name: 'New Student'},
    {name: 'Waiting To Apply'},
    {name: 'Offer Applied'},
    {name: 'Offer Received', requiredDoc: true, feeRequired: true},
    {name: 'Tuition Fee Paid & TT Received'}, // change department
    {name: 'LOA/Fee Receipt Issued', requiredDoc: true}, // change department
    {name: 'Visa File Submitted'},
    {name: 'Visa Approved', requiredDoc: true},
    {name: 'Visa Rejected', requiredDoc: true},
    {name: 'Visa Refund Applied'},
    {name: 'Case Approved'},
    {name: 'Case Close'}
]

export const australiaApplicationStepsBackup = [
    {name: 'New Student'},
    {name: 'Waiting To Apply'},
    {name: 'Offer Applied'},
    {name: 'Conditional Offer Received', requiredDoc: true},
    {name: 'GTE', requiredDoc: true},
    {
        name: 'Unconditional Offer Received',
        requiredDoc: true,
        feeRequired: true
    },
    {name: 'Tuition Fee Paid & TT Received'},
    {name: 'eCOE Applied', requiredDoc: true}, // change user
    {name: 'eCOE Issued', requiredDoc: true},
    {name: 'Visa File Submitted'},
    {name: 'Visa Approved', requiredDoc: true},
    {name: 'Visa Rejected', requiredDoc: true},
    {name: 'Visa Refund Applied'},
    {name: 'Case Close'}
]
export const australiaApplicationSteps = [
    {name: 'New Student'},
    {name: 'Waiting To Apply'},
    {name: 'Offer Applied'},
    {name: 'Conditional Offer Received', requiredDoc: true},
    {name: 'GTE', requiredDoc: true},
    {
        name: 'Unconditional Offer Received',
        requiredDoc: true,
        feeRequired: true
    },
    {name: 'Tuition Fee Paid & TT Received'},
    {name: 'eCOE Applied', requiredDoc: true}, // change user
    {name: 'eCOE Issued', requiredDoc: true},
    {name: 'Visa File Submitted'},
    {name: 'Visa Approved', requiredDoc: true},
    {name: 'Visa Rejected', requiredDoc: true},
    {name: 'Visa Refund Applied'},
    {name: 'Case Approved'},
    {name: 'Case Close'}
]

export const australia_L1ApplicationSteps = [
    {name: 'New Student'},
    {name: 'Waiting To Apply'},
    {name: 'Offer Applied'},
    {name: 'Conditional Offer Received'},
    {name: 'GTE'},
    {name: 'Unconditional Offer Received'},
    {name: 'Tuition Fee Paid & TT Received'},
    {name: 'eCOE Applied'}, // change user
    {name: 'eCOE Issued'},
    {name: 'Visa File Submitted'},
    {name: 'Visa Approved', requiredDoc: true},
    {name: 'Visa Rejected', requiredDoc: true},
    {name: 'Visa Refund Applied'},
    {name: 'Case Approved'},
    {name: 'Case Close'}
]

export const usaApplicationSteps = [
    {name: 'New Student'},
    {name: 'Waiting To Apply'},
    {name: 'Offer Applied'},
    {name: 'Offer Received', requiredDoc: true, feeRequired: true},
    {name: '120 Applied'},
    {name: '120 Received', requiredDoc: true},
    {name: 'Visa File Submitted'},
    {name: 'Visa Approved', requiredDoc: true},
    {name: 'Visa Rejected', requiredDoc: true},
    {name: 'Visa Refund Applied'},
    {name: 'Case Approved'},
    {name: 'Case Close'}
]

export const germanyApplicationSteps = [
    {name: 'New Student'},
    {name: 'Waiting To Apply'},
    {name: 'Offer Applied'},
    {name: 'Conditional Offer Received', requiredDoc: true},
    {
        name: 'Unconditional Offer Received',
        requiredDoc: true,
        feeRequired: true
    },
    {name: 'Tuition Fee Paid & TT Received'},
    {name: 'LOA/Fee Receipt Issued', requiredDoc: true, blockedAccount: true},
    {name: 'Visa File Submitted'},
    {name: 'Visa Approved', requiredDoc: true},
    {name: 'Visa Rejected', requiredDoc: true},
    {name: 'Visa Refund Applied'},
    {name: 'Case Approved'},
    {name: 'Case Close'}
]

export const singaporeApplicationSteps = [
    {name: 'New Student'},
    {name: 'Waiting To Apply'},
    {name: 'Offer Applied'},
    {name: 'Offer Received', requiredDoc: true},
    {name: 'Visa File Submitted'},
    {name: 'Tuition fee', requiredDoc: true},
    {name: 'Visa Approved', requiredDoc: true},
    {name: 'Visa Rejected'},
    {name: 'Visa Refund Applied'},
    {name: 'Case Approved'},
    {name: 'Case Close'}
]

export const defaultLogo = '../../smallLogo.png'

export const nameAvatar = name => {
    if (name) {
        let nameArr = name.split(' ')
        let avatar = ''
        lodash.each(nameArr, item => {
            avatar += item.charAt(0)
        })
        return avatar
    }
}

export const WavierYear = () => {
    let years = []
    let currentYear = moment().year()
    for (let i = currentYear; i >= 2005; i--) {
        years.push(i.toString())
    }
    return years
}

export const InterviewOptions = [
    {name: 'No', value: 'no'},
    {name: 'Audio', value: 'audio'},
    {name: 'Video', value: 'video'},
    {name: 'Both', value: 'both'}
]

export const ApplicationDeskList = (country, statusList) => {
    let desk = []
    if (country.toLowerCase() == 'uk') {
        desk = lodash.clone(ukApplicationSteps)
    } else if (country.toLowerCase() == 'australia_l1') {
        desk = lodash.clone(australia_L1ApplicationSteps)
    } else if (country.toLowerCase() == 'australia') {
        desk = lodash.clone(australiaApplicationSteps)
    } else if (country.toLowerCase() == 'usa') {
        desk = lodash.clone(usaApplicationSteps)
    } else if (country.toLowerCase() == 'germany') {
        desk = lodash.clone(germanyApplicationSteps)
    } else if (country.toLowerCase() == 'singapore') {
        desk = lodash.clone(singaporeApplicationSteps)
    } else {
        desk = lodash.clone(canadaApplicationSteps)
    }
    let findAppStatus = lodash.find(statusList, item => {
        if (item.status == 'Visa Approved') {
            return true
        }
    })
    if (findAppStatus) {
        desk = lodash.reject(desk, item => {
            if (
                item.name == 'Visa Rejected' ||
                item.name == 'Visa Refund Applied' ||
                item.name == 'Case Close'
            ) {
                return true
            }
        })
    } else {
        rejStatus()
    }

    function rejStatus() {
        let findRejStatus = lodash.find(statusList, item => {
            if (
                item.status == 'Visa Rejected' ||
                item.status == 'Visa Refund Applied'
            ) {
                return true
            }
        })
        if (findRejStatus) {
            desk = lodash.reject(desk, item => {
                if (item.name == 'Visa Approved' || item.name == 'Case Approved') {
                    return true
                }
            })
        }
    }

    return desk
}

export const RollBackDeskList = (country, currentStatus) => {
    let rollbackDesk = []
    if (country.toLowerCase() == 'uk') {
        rollbackDesk = lodash.clone(ukApplicationSteps)
    } else if (country.toLowerCase() == 'australia_l1') {
        rollbackDesk = lodash.clone(australia_L1ApplicationSteps)
    } else if (country.toLowerCase() == 'australia') {
        rollbackDesk = lodash.clone(australiaApplicationSteps)
    } else if (country.toLowerCase() == 'usa') {
        rollbackDesk = lodash.clone(usaApplicationSteps)
    } else if (country.toLowerCase() == 'germany') {
        rollbackDesk = lodash.clone(germanyApplicationSteps)
    } else if (country.toLowerCase() == 'singapore') {
        rollbackDesk = lodash.clone(singaporeApplicationSteps)
    } else {
        rollbackDesk = lodash.clone(canadaApplicationSteps)
    }
    let indexOfCurrentStatus = lodash.findIndex(rollbackDesk, item => {
        return item.name == currentStatus
    })
    rollbackDesk = rollbackDesk.splice(1, indexOfCurrentStatus - 1)
    if (currentStatus == 'Visa Approved') {
        rollbackDesk = lodash.reject(rollbackDesk, item => {
            if (item.name == 'Visa Rejected' || item.name == 'Case Close') {
                return true
            }
        })
        return rollbackDesk
    } else if (currentStatus == 'Visa Rejected') {
        rollbackDesk = lodash.reject(rollbackDesk, item => {
            if (item.name == 'Visa Approved' || item.name == 'Case Approved') {
                return true
            }
        })
        return rollbackDesk
    } else {
        return rollbackDesk
    }
}

export const statusColors = status => {
    let colorObj = [
        '#337ab7',
        '#5cb85c',
        '#5bc0de',
        '#f0ad4e',
        '#d9534f',
        '#0000FF',
        '#FF1493',
        '#F4A460',
        '#708090',
        '#808080',
        '#5F9EA0',
        '#1890ff',
        '#0872BC'
    ]
    let statusObj = {
        'New Student': '#5bc0de',
        'Waiting To Apply': '#f0ad4e',
        'Offer Applied': '#5cb85c',
        'Offer Received': '#5cb85c',
        'Conditional Offer Received': '#5cb85c',
        'Unconditional Offer Received': '#5cb85c',
        'Provide CAS Documents': '#0000FF',
        'CAS Applied': '#0000FF',
        'CAS Issued': '#0000FF',
        'eCOE Applied': '#0000FF',
        'eCOE Issued': '#0000FF',
        '120 Applied': '#0000FF',
        '120 Received': '#0000FF',
        'Tuition Fee Paid & TT Received': '#0000FF',
        'LOA/Fee Receipt Issued': '#0000FF',
        'Visa File Submitted': '#F4A460',
        'Visa Approved': '#1890ff',
        'Visa Rejected': '#d9534f',
        'Visa Refund Applied': '#F4A460',
        'Case Close': '#0872BC'
    }
    return statusObj[status] ? statusObj[status] : '#5cb85c'
}

export const filterOption = (input, options) => {
    //console.log(input, options)
    return options.props.children.match(new RegExp(`${input}`, 'ig'))
}

export const showRefundBtn = statusList => {
    let findAppStatus = lodash.find(statusList, item => {
        if (
            item.status == 'Offer Received' ||
            item.status == 'Conditional Offer Received'
        ) {
            return true
        }
    })
    if (findAppStatus) {
        return true
    } else {
        return false
    }
}

export const getStatusDate = (statusList, currentStatus) => {
    let findStatus = lodash.find(statusList, item => {
        return item.status == currentStatus
    })
    return findStatus && findStatus.date ? longDisplayDate(findStatus.date) : null
}

export const checkTuitionFeesRequired = (status, statusList) => {
    let currentStatusIndex = lodash.findIndex(statusList, item => {
        return item.name == status
    })
    if (
        statusList[currentStatusIndex] &&
        statusList[currentStatusIndex].feeRequired
    ) {
        let message = ''
        let nextStatus = statusList[currentStatusIndex + 1].name
        if (status == 'Offer Received') {
            message = 'offer letter'
        } else {
            message = 'Unconditional offer letter'
        }
        let newStatus = ''
        if (nextStatus == 'Provide CAS Documents') {
            newStatus = 'CAS'
        } else if (nextStatus == 'Tuition Fee Paid & TT Received') {
            newStatus = 'eCOE'
        } else {
            newStatus = 'LOA'
        }
        // return { show: true, nextStatus: statusList[currentStatusIndex + 1].name, infoMessage: message }
        return {show: true, nextStatus: newStatus, infoMessage: message}
    } else {
        return {show: false}
    }
}

export const ScheduleTypes = ['Audio', 'Video', 'Physical']

export const defaultLogoList = {
    defaultUniversityLogo: '../dist/img/defaultUniversity.jpg'
}

export const StatusNameObj = {
    'Conditional Offer Received': 'Conditional',
    'Unconditional Offer Received': 'Unconditional',
    'Provide CAS Documents': 'Provide CAS',
    'Tuition Fee Paid & TT Received': 'Tuition Fee',
    'LOA/Fee Receipt Issued': 'LOA/Fee Issued'
}

export const BooleanOptions = [
    {name: 'Enable', value: true},
    {name: 'Disable', value: false}
]

export const assignAgentTypeOptions = [
    {name: 'All Agents', value: 'all'},
    {name: 'Selected Agents', value: 'selected'},
    {name: 'Except Selected Agents', value: 'exceptSelected'}
]

export const ConditionOptions = [
    {name: 'Yes', value: true},
    {name: 'No', value: false}
]

export const StudentTypesFilter = [
    {name: 'Direct', value: 'direct'},
    {name: 'Quick', value: 'quickAdd'},
    {name: 'Register', value: 'registerUser'}
]

export const Intakes = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
]

export const IntakesYears = [
    '2020',
    '2021',
    '2022',
    '2023',
    '2024',
    '2025',
    '2026',
    '2027',
    '2028',
    '2029',
    '2030'
]
export const YearList = [2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030]

export const intakesFilter = [
    {
        label: 'May/Jun/Jul',
        value: 'May/June/July',
        season: 'Summer',
        index: 0,
        month: [5, 6, 7]
    },
    {
        label: 'Aug/Sep/Oct',
        value: 'August/September/October',
        season: 'Fall',
        index: 1,
        month: [8, 9, 10]
    },
    {
        label: 'Nov/Dev/Jan',
        value: 'November/December/January',
        season: 'Winter',
        index: 2,
        month: [11, 12, 1]
    },
    {
        label: 'Feb/Mar/April',
        value: 'February/March/April',
        season: 'Spring',
        index: 3,
        month: [2, 3, 4]
    }
]
export const MonthIndex = {
    January: 1,
    February: 2,
    March: 3,
    April: 4,
    May: 5,
    June: 6,
    July: 7,
    August: 8,
    September: 9,
    October: 10,
    November: 11,
    December: 12
}

export const MonthsName = [
    {name: 'January', key: 1},
    {name: 'February', key: 2},
    {name: 'March', key: 3},
    {name: 'April', key: 4},
    {name: 'May', key: 5},
    {name: 'June', key: 6},
    {name: 'July', key: 7},
    {name: 'August', key: 8},
    {name: 'September', key: 9},
    {name: 'October', key: 10},
    {name: 'November', key: 11},
    {name: 'December', key: 12}
]

export const ShowEnrollment = () => {
    let arr = ['Visa Approved', 'Case Close']
}

export const showCommissionCheck = user => {
    if (user && user.userType == 'agent') {
        return user.showCommission ? user.showCommission : false
    } else {
        return false
    }
}

export const boardList = [
    {name: 'CBSE - Central Board of Secondary Education', value: 'CBSE'},
    {name: 'ISC - Indian School Certificate', value: 'ISC'},
    {name: 'ICSE - Indian School Certificate Examinations', value: 'ICSE'},
    {name: 'NIOS – National Institute of Open Schooling', value: 'NIOS'},
    {
        name:
            'UP Board - Board of High School and Intermediate Education Uttar Pradesh',
        value: 'UP Board'
    },
    {
        name: 'JKBOSE - Jammu and Kashmir State Board of School Education',
        value: 'JKBOSE'
    },
    {name: 'RBSE - Board of Secondary Education Rajasthan', value: 'RBSE'},
    {
        name: 'HPBOSE - Himachal Pradesh Board of School Education',
        value: 'HPBOSE'
    },
    {
        name: 'MPBSE - Madhya Pradesh Board of Secondary Education',
        value: 'MPBSE'
    },
    {name: 'CGBSE - Chhattisgarh Board of Secondary Education', value: 'CGBSE'},
    {name: 'PSEB – Punjab School Education Board', value: 'PSEB'},
    {name: 'BSEH - Haryana Board of School Education (HBSE)', value: 'BSEH'},
    {name: 'BSEB - Bihar School Examination Board', value: 'BSEB'},
    {
        name: 'GSEB - Gujarat Secondary and Higher Secondary Education Board',
        value: 'GSEB'
    },
    {
        name:
            'MSBSHSE – Maharashtra State Board Of Secondary and Higher Secondary Education',
        value: 'MSBSHSE'
    },
    {
        name: 'BIEAP - Andhra Pradesh Board of Intermediate Education',
        value: 'BIEAP'
    },
    {
        name: 'BSEAP - Andhra Pradesh Board of Secondary Education',
        value: 'BSEAP'
    },
    {name: 'WBBSE - West Bengal Board of Secondary Education', value: 'WBBSE'},
    {
        name: 'WBCHSE - West Bengal Council of Higher Secondary Education',
        value: 'WBCHSE'
    }
]

export const getCurrentStatusForActivation = statusList => {
    statusList = lodash.reject(statusList, item => {
        return item.status == 'Case Close'
    })
    return statusList[statusList.length - 1]
}

export const showEnrollmentAction = statusList => {
    if (statusList && statusList.status == 'Visa Approved') {
        return true
    } else {
        return false
    }
}

export const durationList = [
    {label: '15 minutes', value: 15},
    {label: '30 minutes', value: 30},
    {label: '45 minutes', value: 45},
    {label: '1 hour', value: 60},
    {label: '1:15 hour', value: 75},
    {label: '1:30 hours', value: 90},
    {label: '1:45 hours', value: 105},
    {label: '2 hours', value: 120}
]

export const capitalize = word => {
    const lower = word.toLowerCase()
    return word.charAt(0).toUpperCase() + lower.slice(1)
}

export const checkEmailIsValid = value => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
        return true
    } else {
        return false
    }
}

export const UniversityOptions = [
    {name: 'All University', value: 'All'},
    {name: 'Selected University', value: 'Selected'}
]
export const CountryOptions = [
    {name: 'All Country', value: 'All'},
    {name: 'Selected Country', value: 'Selected'}
]

export const directUniversityList = [
    {name: 'FCMT College', id: '5ff6d1ac3fdb3f5fa68e9ff3'},
    {name: 'Cambie College', id: '6311d05b722d9e1377bf4c29'},
    /* { name: 'Avalon College', id: '5ff846d63fdb3f5fa68ea2f6' },
       { name: 'Granville College', id: '6173f94680ca7e59336696ab' },
       { name: 'Academy of Learning - Brampton', id: '5ff6e59d3fdb3f5fa68ea061' },*/
    {name: 'Campbell Institute', id: '61fcc4c562adc7405e7a31f4'},
    {name: 'Kasa High School', id: '6230730a56bb21119b2dfaef'},
    {name: 'CASA College', id: '627b476216fab67e40701e03'},
    {name: 'Lester B. Pearson', id: '5ff6e3783fdb3f5fa68ea060'},
    {
        // name: 'Canada International Royal Arts College',
        name: 'CIRA',
        id: '63cf85082b3f7a227856ec9e'
    },
    {
        name: 'Oxford College of Education',
        id: '6435042a59e0d77f81a49511'
    }
]
export const oxfordUniversity = '6435042a59e0d77f81a49511'
export const fcmtUniversity = '5ff6d1ac3fdb3f5fa68e9ff3'
export const cambieUniversity = '6311d05b722d9e1377bf4c29'
export const AvalonUniversity = '5ff846d63fdb3f5fa68ea2f6'
export const GranvilleUniversity = '6173f94680ca7e59336696ab'
export const AOLUniversity = '5ff6e59d3fdb3f5fa68ea061'
export const CampBellUniversity = '61fcc4c562adc7405e7a31f4'
export const KasaHighSchool = '6230730a56bb21119b2dfaef'
export const CasaHCollege = '627b476216fab67e40701e03'
export const CambieHCollege = '6311d05b722d9e1377bf4c29'
export const LesterCollege = '5ff6e3783fdb3f5fa68ea060'
export const RoyalArtsCollege = '63cf85082b3f7a227856ec9e'
export const KasaColleges = [
    {name: 'Kasa High School', id: '6230730a56bb21119b2dfaef'},
    {name: 'CASA College', id: '627b476216fab67e40701e03'}
]
export const CasaColleges = [
    {name: 'CASA College', id: '627b476216fab67e40701e03'},
    // {name: 'Kasa High School', id: '6230730a56bb21119b2dfaef'},
]
export const DirectUniversityCheck = universityId => {
    let findUniversity = lodash.find(directUniversityList, item => {
        return item.id == universityId
    })
    if (findUniversity) {
        return true
    } else {
        return false
    }
}

export const academicStatusList = [
    {name: 'Full Time', value: 'Full Time'},
    {name: 'Part Time', value: 'Part Time'}
]
export const typeOfProgramList = [
    {name: 'Vocational', value: 'Vocational'},
    {name: 'Academic', value: 'Academic'},
    {name: 'Professional', value: 'Professional'}
]

export const validateEmail = email => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
}

export const departmentObj = {
    offerDepartment: 'Offer Department',
    assDepartment: 'Assessment Department',
    loaDepartment: 'LOA/CAS/120 Department',
    visaSubmission: 'Visa Submission Department',
    visaApproved: 'Visa Approved Department',
    visaRejected: 'Visa Rejected Department',
    interviewer: 'Interview Department',
    warningDepartment: 'Warning Department',
    marketing: 'Marketing Department',
    counselling: 'Counselling Department',
    accountDepartment: 'Account Department',
}

export const countryIds = {
    canada: '5dea0a52e304ca03881dd208',
    uk: '5dea0a52e304ca03881dd22e',
    australiaL2: '62e3a5b01d5bfcd430de080a',
    australia: '641c247dece3d95d343538e5',
    australiaL1: '601e424bd7e547555f7925c1',
    usa: '612c8bc76efc5360647230ba',
    germany: '62a6d7f86e44873f385759dd'
}
export const currencySymbols = {
    '5dea0a52e304ca03881dd208': "$",
    '5dea0a52e304ca03881dd22e': "£",
    '641c247dece3d95d343538e5': "$",
    '62e3a5b01d5bfcd430de080a': "$",
    '601e424bd7e547555f7925c1': "$",
    '62a6d7f86e44873f385759dd': "$",
    '612c8bc76efc5360647230ba': "$"
}


export const interviewStatusColors = status => {
    let statusObj = {
        'Not Prepared': '#ff0000',
        'Partially Prepared': '#FFFF00',
        'Fully Prepared': '#008000'
    }
    let statusColor = {
        'Not Prepared': 'white',
        'Partially Prepared': 'black',
        'Fully Prepared': 'white'
    }
    return {
        backgroundColor: statusObj[status] ? statusObj[status] : '#ff0000',
        color: statusColor[status] ? statusColor[status] : 'white'
    }
}
export let EligibilityList = [
    {name: 'minimumCasDeposit', label: 'Minimum Cas Deposit'},
    {
        name: 'maximumCasDeposit',
        label: 'Maximum Cas Deposit',
        conditional: true
    },
    {name: 'ugMinimumRequired', label: 'UG Minimum Required'},
    {name: 'ugMaximumRequired', label: 'UG Maximum Required'},
    {name: 'pgMinimumRequired', label: 'PG Minimum Required'},
    {name: 'pgMaximumRequired', label: 'PG Maximum Required'},
    {name: 'iletsMinimumForUg', label: 'Ilets Minimum For UG'},
    {name: 'iletsMaximumForUg', label: 'Ilets Maximum For UG'},
    {name: 'iletsMinimumForPg', label: 'Ilets Minimum For PG'},
    {name: 'iletsMaximumForPg', label: 'Ilets Maximum For PG'},
    {name: 'pteMinimumForUg', label: 'PTE Minimum For UG'},
    {name: 'pteMaximumForUg', label: 'PTE Maximum For UG'},
    {name: 'pteMinimumForPg', label: 'PTE Minimum For PG'},
    {name: 'pteMaximumForPg', label: 'PTE Maximum For PG'},
    {name: 'minWaiverForCbsc', label: 'Min Waiver For Cbsc'},
    {name: 'maxWaiverForCbsc', label: 'Max WaiverFor Cbsc'},
    {name: 'timeMinimumDuration', label: 'Time Minimum Duration'},
    {name: 'timeMaximumDuration', label: 'Time Maximum Duration'},
    {name: 'casTimeMinimum', label: 'CAS Time Minimum'},
    {name: 'casTimeMaximum', label: 'CAS Time Maximum'}
]

export const StudentType = {
    direct: 'Web',
    quickAdd: 'Mobile',
    registerUser: 'Registered'
}

export const managerType = [
    {name: 'University Wise', value: 'universityWise'},
    {name: 'Agent Wise', value: 'agentWise'},
    {name: 'Student Wise', value: 'studentWise'}
]

export const PreferredLanguage = [
    'Arabic',
    'Bengali',
    'English',
    'French',
    'German',
    'Hindi_Urdu',
    'Indonesian',
    'Italian',
    'Japanese',
    'Korean',
    'Mandarin',
    'Malay',
    'Nepali',
    'OTHER',
    'Portuguese',
    'Russian',
    'Spanish',
    'Thai',
    'Tamil',
    'Vietnamese'
]

export const AustraliaState = ['QLD', 'VIC', 'ACT', 'SA', 'WA', 'NT', 'TAS']


export const UserTypeHumanizeName = {
    admin: 'Admin',
    branchManager: 'Branch Manager',
    marketingManager: 'Marketing Manager',
    branchUser: 'Branch User',
    master: 'Master',
    agent: 'Agent',
    subAgent: 'Sub Agent'
}

export const hidePassword = password => {
    let passwordField = ''
    for (let i = 0; i < password.length; i++) {
        passwordField = passwordField + '*'
    }
    return passwordField
}

export const InvoicePeriodCycle = [
    {name: 'Yearly', value: 12},
    {name: 'Half Yearly', value: 6},
    {name: 'Quarterly', value: 3},
    {name: 'Monthly', value: 1}
]
export const InvoiceMonthlyCycle = () => {
    let monthArr = []
    for (let i = 1; i <= 24; i++) {
        monthArr.push({
            name: `${i} ${i == 1 ? 'Month' : 'Months'}`,
            value: i
        })
    }
    return monthArr
}

export const getSemesterList = () => {
    let semesterList = []
    for (let i = 1; i <= 10; i++) {
        semesterList.push(`Semester ${i}`)
    }
    return semesterList
}
export const CommissionStatusList = [
    'Pending',
    'Commission Claimed',
    'Commission Received',
    'Commission Cancelled',
    'Agent Invoice Raised',
    'Agent Invoice Paid'
]

export const CommissionIntakeYear = () => {
    let years = []
    let currentYear = moment().year()
    for (let i = 2015; i <= currentYear; i++) {
        years.push(i.toString())
    }
    return years
}

export const CounsellorTypeList = ['Counsellor', 'Enroll']

export const getYearList = () => {
    let years = []
    for (let i = 0; i <= 10; i++) {
        years.push(
            moment()
                .add(i, 'year')
                .year()
        )
    }
    return years
}

export const universityListForMarketing = [
    {
        name: 'FCMT College',
        id: '5ff6d1ac3fdb3f5fa68e9ff3',
        image: '../assets/flairCollage.png'
    },
    /* {
         name: 'Cambie College',
         id: '6311d05b722d9e1377bf4c29',
         image: '../assets/cambie_college.png'
     },*/
    {
        name: 'Lester B. Pearson',
        id: '5ff6e3783fdb3f5fa68ea060',
        image: '../assets/lester.png'
    },
    {
        name: 'Canada International Royal Arts College',
        id: '63cf85082b3f7a227856ec9e',
        image:
            '../assets/directUniversities/canada_international_royal_arts_college.png'
    }
]

export const cambieForMarketing = {
    name: 'Cambie College',
    id: '6311d05b722d9e1377bf4c29',
    image: '../assets/cambie_college.png'
}


export const ukUniversityListForMarketing = [
    {
        name: 'Oxford College of Education',
        id: '6435042a59e0d77f81a49511',
        image: '../assets/oxford_lg_logo.png'
    }
]
export const udaanArr = [
    {name: 'All', value: 'all'},
    {name: 'Udaan 3', value: 'udaan3'},
    {name: 'Udaan 2', value: 'udaan2'},
    {name: 'Udaan 1', value: 'udaan1'}
]

export const streamsList = ['Commerce', 'Non Medical', 'Medical', 'Arts']

export const currencyAmt = (value, currency = 'USD') => {
    let lang = currency == 'EURO' ? "de-DE" : 'en-US'
    let currencyName = currency == 'EURO' ? "EUR" : 'USD'
    return value.toLocaleString(lang, {
        style: 'currency',
        currency: currencyName
    })
}
export const oxfordInterviewStatusList = [
    'Pending',
    'Pass',
    'Fail',
    'Reappear'
]

export const marketingUniversity1 = [
    {name: 'CanadaList', value: 'canada'},
    {name: 'UkList', value: 'uk'},
    {name: 'Others', value: 'other'},
]


export const marketingUniversity = {
    canadaList: [
        {label: "Public Colleges", value: "Public", status: "LOA/Fee Receipt Issued"},
        {label: "FCMT College", value: fcmtUniversity, status: "LOA Generated"},
        {label: "Cambie College", value: CambieHCollege, status: "LOA Generated"},
        {label: "Lester B. Pearson", value: LesterCollege, status: "LOA Generated"},
        {label: "CIRA College", value: RoyalArtsCollege, status: "LOA Generated"}
    ],
    ukList: [
        {label: "Public Colleges", value: "Public", status: "CAS Issued"},
        {label: "Oxford College", value: oxfordUniversity, status: "Upload Signed Visa Draft"}
    ],
    australiaList: [
        {label: "Public Colleges", value: "Public", status: "eCOE Applied"}
    ],
    others: [
        {label: "Public Colleges", value: "Public"}
    ]
}

export const dateFilterRange = {
    Today: [moment().startOf('day'), moment().endOf('day')],
    'Last Week': [
        moment()
            .subtract(1, 'week')
            .startOf('week'),
        moment()
            .subtract(1, 'week')
            .endOf('week')
    ],
    'Last 15 Days': [moment().subtract(14, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')]
}
export const expenseTypes = ['Hotel', 'Car', 'Food', 'Other Expense']

export const getDirectUniversityName = (universityId) => {
    if (universityId == 'Public') {
        return universityId;
    } else {
        let findUni = lodash.find(directUniversityList, (item) => {
            return item.id == universityId
        })
        return findUni && findUni.name ? findUni.name : ""
    }
}
export const cambieAgentId = "621a04adb320765cb12fb8bd"
