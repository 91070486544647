import {notification} from 'antd'
import {getToken, customAxios as axios} from '../../../request'
import {
    addCounsellingUrl,
    receptionDashboardUrl,
    getDashboardCounsellorUrl,
    getEnquiryByUserUrl,
    updateCounsellorAvailabilityUrl,
    counsellingHistoryUrl,
    updateEnquiryUrl,
    stopCounsellorWaitingUrl,
    counsellorCountryWiseUrl,
    receptionDataCountryWiseUrl, counsellingListUrl, allCounsellingListUrl
} from '../api/counselling'
import {hideLoader, hidePageLoad, showLoader, showPageLoad} from "../../../modules/actions";


export const addCounsellingFxn = (valData) => async (dispatch) => {
    let {data} = await axios.post(addCounsellingUrl(), valData, getToken());
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error',
        })
    }
    return data;
}

export const receptionDashboard = (valData) => async (dispatch) => {
    let {data} = await axios.post(receptionDashboardUrl(), valData, getToken());
    if (data.error) {
        notification.error({
            message: data.message || 'Error',
        })
    }
    return data;
}

export const getDashboardCounsellor = (valData) => async (dispatch) => {
    let {data} = await axios.post(getDashboardCounsellorUrl(), valData, getToken());
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error',
        })
    }
    return data;
}

export const getEnquiryByUser = (valData) => async (dispatch) => {
    let {data} = await axios.post(getEnquiryByUserUrl(), valData, getToken());
    if (data.error) {
        notification.error({
            message: data.message || 'Error',
        })
    }
    return data;
}

export const updateCounsellorAvailability = (valData) => async (dispatch) => {
    let {data} = await axios.post(updateCounsellorAvailabilityUrl(), {}, getToken());
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error',
        })
    }
    return data;
}

// History
export const counsellingHistory = (valData) => async (dispatch) => {
    let {data} = await axios.post(counsellingHistoryUrl(), valData, getToken());
    if (data.error) {
        notification.error({
            message: data.message || 'Error',
        })
    }
    return data;
}
export const updateEnquiry = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(updateEnquiryUrl(), valData, getToken());
    dispatch(hidePageLoad())
    if (data.error) {
        notification.error({
            message: data.message || 'Error',
        })
    }
    return data;
}

export const stopCounsellorWaitingFxn = () => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(stopCounsellorWaitingUrl(), {}, getToken());
    dispatch(hideLoader())

    if (data.error) {
        notification.error({
            message: data.message || 'Error',
        })
    }
    return data;
}

export const counsellorCountryWiseFxn = async (valData) => {
    let {data} = await axios.post(counsellorCountryWiseUrl(), valData, getToken())
    return data
}

export const receptionDataCountryWiseFxn = async (valData) => {
    let {data} = await axios.post(receptionDataCountryWiseUrl(), valData, getToken())
    return data
}
export const counsellingListFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let config = {
        params: {...valData},
        ...getToken()
    }
    let {data} = await axios.get(counsellingListUrl(), config)
    dispatch(hidePageLoad())
    return data
}
export const allCounsellingListFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let config = {
        params: {...valData},
        ...getToken()
    }
    let {data} = await axios.get(allCounsellingListUrl(), config)
    dispatch(hidePageLoad())
    return data.data
}
