import {
  Button,
  Card,
  Col,
  Icon,
  Popconfirm,
  Row,
  Select,
  Table,
  Tooltip,
  notification
} from 'antd'
import lodash from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import {
  DefaultTablePagination,
  newFormatDisplayDate
} from '../../../components/_utils/appUtils'
import {
  approveLeadFxn,
  eventList,
  eventListExport
} from '../../event/action/event'
import { singleUserEnquiryFxn, unizHomeEnquiryFxn } from '../action'
import SingleStudentEnquiryList from './studentInformationDrawer'

const StudentList = props => {
  const [filter, setFIlter] = useState({})
  const [total, setTotal] = useState(0)
  const [visible, setVisible] = useState(false)
  const [studentId, setStudentId] = useState('')
  const [approved, setApproved] = useState(false)
  let user = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : null
  const tableRef = useRef()
  const dispatch = useDispatch()

  const openStudentInfomation = id => {
    setStudentId(id)
    setVisible(true)
  }
  const onClose = () => {
    setStudentId('')
    setVisible(false)
  }
  const apiRequest = params => {
    return new Promise(async resolve => {
      params.sortField = 'countryName'
      params.sortOrder = 'ascend'
      let resp = await dispatch(
        unizHomeEnquiryFxn({
          ...params,
          regExFilters: [
            'name',
            'enrollmentBasic',
            'enrollmentProcedure',
            'fundSponsors'
          ]
        })
      )
      setFIlter(params)
      setTotal(resp.total)
      resolve(resp)
    })
  }
  const events = {
    reloadTable: () => {
      if (tableRef && tableRef.current) {
        tableRef.current.reload()
      }
    },
    searchData: async () => {
      //   let obj = {}
      //   if (name) {
      //     obj.name = name
      //   }
      //   if (countryId) {
      //     obj.countryId = countryId
      //   }
      //   if (status) {
      //     obj.status = status
      //   }
      //   if (branchUserId) {
      //     obj.branchUserId = branchUserId
      //   }
      //   if (intake) {
      //     obj.intake = intake
      //   }
      //   if (appUniversityId) {
      //     obj.appUniversityId = appUniversityId
      //   }
      //   if (agentId) {
      //     obj.agentId = agentId
      //   }
      //   if (studentShore) {
      //     obj.studentShore = studentShore
      //   }
      //   dispatch(
      //     getUrlPushWrapper('allStudent', {
      //       ...obj
      //     }))
      //   setTableSearch(true)
      //   setTimeout(() => {
      //     events.reloadTable()
      //   }, 200)
    }
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      searchTextName: 'name',
      render: (val, record) => {
        return (
          <p>
            {record.title} {record.firstName} {record.lastName}
          </p>
        )
      }
    },
    {
      title: 'DOB',
      dataIndex: 'dateOfBirth',
      key: 'dateOfBirth',
      width: 90,
      render: val => {
        return <div style={{ width: 90 }}>{newFormatDisplayDate(val)}</div>
      }
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    },

    {
      title: 'Phone Number',
      dataIndex: 'mobile',
      key: 'mobile',
      render: (val, record) => {
        return (
          <p>
            {/* {record.dailCode} {record.phoneNumber} */}
            {val}
          </p>
        )
      }
    },

    {
      title: 'Nationality',
      dataIndex: 'nationality',
      key: 'nationality',
      searchTextName: 'nationality'
    },
    {
      title: 'University',
      dataIndex: 'university',
      key: 'university',
      // filters: statusFilter.map(x => ({value: x.value, text: x.name})),
      render: item => {
        return item
      }
    },
    {
      title: 'Student Budget',
      dataIndex: 'studentBudget',
      key: 'studentBudget',
      searchTextName: 'studentBudget'
    },
    {
      title: 'Accommodation',
      dataIndex: 'accommodation',
      key: 'accommodation',
      searchTextName: 'accommodation'
    },
    {
      title: 'Expected Arrival Date',
      dataIndex: 'expectedArrivalDate',
      key: 'expectedArrivalDate',
      searchTextName: 'expectedArrivalDate',
      render: val => {
        return <div style={{ width: 90 }}>{newFormatDisplayDate(val)}</div>
      }
      //   {
    },
    {
      title: 'Action',
      key: '_id',
      dataIndex: '_id',
      width: 150,
      render: (val, record) => {
        return (
          <React.Fragment>
            <Tooltip title="View Details">
              <button
                className={'btn'}
                onClick={() => {
                  openStudentInfomation(record._id)
                }}>
                <Icon type={'eye'} />
              </button>
            </Tooltip>
          </React.Fragment>
          //   <>
          //     <a
          //       className={'btn btn-default btn-sm'}
          //       onClick={() => {
          //         openStudentInfomation(val._id)
          //       }}>
          //       View
          //     </a>
          //   </>
        )
      }
    }
    //   {
    //     title: 'University',
    //     dataIndex: 'university',
    //     key: 'university',
    //     // filters: statusFilter.map(x => ({value: x.value, text: x.name})),
    //     render: item => {
    //       return item
    //     }
    //   },
    //   {
    //     title: 'Additional Message',
    //     dataIndex: 'additionalMessage',
    //     key: 'additionalMessage',
    //     // filters: statusFilter.map(x => ({value: x.value, text: x.name})),
    //     render: item => {
    //       return item
    //     }
    //   },
    //   {
    //     title: 'Offer Code',
    //     dataIndex: 'offerCode',
    //     key: 'offerCode',
    //     // filters: statusFilter.map(x => ({value: x.value, text: x.name})),
    //     render: item => {
    //       return item
    //     }
    //   },
    //   {
    //     title: 'Property',
    //     dataIndex: 'property',
    //     key: 'property',
    //     render: (item, record) => {
    //       return <>{item.address}</>
    //     }
    //   },
    //   {
    //     title: 'Communication Methods',
    //     dataIndex: 'communicationMethod',
    //     key: 'communicationMethod',
    //     render: (item, record) => {
    //       return <>{item.address}</>
    //     }
    //   },
    //   {
    //     title: 'How Did You Find Us?',
    //     dataIndex: 'findUs',
    //     key: 'findUs',
    //     render: (item, record) => {
    //       return <>{item.address}</>
    //     }
    //   },
    //   {
    //     title: 'Room Details',
    //     dataIndex: 'roomDetails',
    //     key: 'roomDetails',
    //     render: (item, record) => {
    //       let { property } = record
    //       let { room } = property
    //       return (
    //         <>
    //           Room : {room.room_name}
    //           <br />
    //           Start : {item.start_date}
    //           <br />
    //           End : {item.end_date}
    //           <br />
    //           Price : {item.price}
    //           <br />
    //         </>
    //       )
    //     }
    //   }
  ]

  const handleExport = async () => {
    let obj = {
      ...filter,
      count: total,
      regExFilters: [
        'Title',
        'Name',
        'Nationality',
        'Email',
        'Phone Number',
        'University',
        'Room Details'
      ]
    }
    let { success, filePath, message } = await dispatch(eventListExport(obj))
    if (success) {
      notification.success({ message: message })
      // window.open(`${apiUrl}${filePath}`, 'Download')
    } else {
      notification.error({ message: message })
    }
  }

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="card">
          <div className="table-head  align-items-center all-student-search">
            <h6>Student Enquiries: {total || 0}</h6>
          </div>
          <div className="card-body table-responsive">
            <TableComp
              columns={columns}
              apiRequest={apiRequest}
              ref={tableRef}
              pagination={DefaultTablePagination()}
            />
          </div>
        </div>
      </div>
      {visible ? (
        <SingleStudentEnquiryList
          visible={visible}
          studentId={studentId}
          setVisible={setVisible}
          onClose={onClose}
        />
      ) : null}
    </div>
  )
}

export default StudentList
