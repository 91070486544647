import Home from '../../unizHome/home'
import PropertiesList from '../../unizHome/propertiesList'
import SingleProperty from '../../unizHome/singleProperty'
import TrainingRequest from "../FrontendComponents/trainingRequest";

const UnizHomeRoutesList = [
    {name: 'Home', path: '/unizhome', component: Home, key: 'home'},
    {
        name: 'Properties List',
        path: '/properties-list/:city',
        component: PropertiesList,
        key: 'property-list'
    },
    {
        name: 'Student Accommodation',

        path: `/student-accommodation/:property_slug`,
        component: SingleProperty,
        key: 'single-property'
    },
    {
        name: 'TrainingRequest',
        path: '/trainingRequest/:agentId',
        component: TrainingRequest,
        key: 'trainingRequest'
    }
]
export default UnizHomeRoutesList
