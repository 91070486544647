import {Icon, Tooltip} from 'antd'
import _ from 'lodash'
import React, {useEffect, useState} from 'react'
import {LazyLoadImage} from 'react-lazy-load-image-component'
import {useDispatch} from 'react-redux'
import {
    CambieHCollege,
    LesterCollege,
    RoyalArtsCollege,
    fcmtUniversity,
    universityListForMarketing, cambieForMarketing, cambieAgentId
} from '../../../components/_utils/appUtils'
import {getUrlPushWrapper} from '../../../routes'
import CambieUniversity from '../../evaluateProfile/cambie/cambieUniversity'
import FlairUniversity from '../../evaluateProfile/fcmt/flairUniversity'
import {canadaUniversityRecordsCountFxn} from '../../reporting/actions'
import {getUserWithoutLoader} from '../../users/actions/user'
import DirectUniversityComponent from '../drawers/directUniversityComponent'

const ButtonLink = props => {
    let {
        searchData,
        item,
        record,
        userCountryName,
        userData,
        applyForCourse,
        openReport
    } = props
    const btnA = (
        <li className={'canadaDirectUniCount'} key={item.id}>
            <div className={'innerBox'}>
                <div className={'uniCountBox'} onClick={() => searchData(item)}>
                    <LazyLoadImage src={item.image}/>
                    <h5>{record.total ? record.total : 0}</h5>
                </div>
                {(userData.userType == 'admin' || userData.email == 'tarun@unizportal.com') ? (
                    <div className={'squareContainer'}>
                        <Tooltip title={'Udaan 3'}>
                            <div
                                className={'squareStyle'}
                                onClick={() => openReport(item, 'udaan3')}>
                                {record.udaan ? record.udaan : 0}
                            </div>
                        </Tooltip>
                        <Tooltip title={'File pending for submission'}>
                            <div
                                className={'squareStyle'}
                                onClick={() => openReport(item, 'File pending for submission')}>
                                {record.pendingSubmission ? record.pendingSubmission : 0}
                            </div>
                        </Tooltip>
                        <Tooltip title={'Last 8 Days'}>
                            <div className={'squareStyle'}
                                 onClick={() => openReport(item, 'last8Days')}>
                                {record.last8Days ? record.last8Days : 0}
                            </div>
                        </Tooltip>

                    </div>
                ) : null}
                <div className={'btnBox'}>
                    <a
                        onClick={() => {
                            applyForCourse(item.id)
                        }}>
                        <Icon type={'plus-circle'} size={13}/> Add Student
                    </a>
                </div>
            </div>
        </li>
    )
    let branchUserCheck = () => {
        let checkUniversityExists = _.find(userData.universities, item1 => {
            return item1.toString() == item.id
        })
        return userData.department == 'Visa Approved Department'
            ? btnA
            : checkUniversityExists
                ? btnA
                : null
    }
    return userCountryName == 'Canada' ? (
        <>
            {userData.userType == 'admin' ||
            userData.userType == 'agent' ||
            userData.userType == 'subAgent'
                ? btnA
                : null}
            {userData.userType == 'branchManager' && userCountryName == 'Canada'
                ? btnA
                : null}
            {userData.userType == 'branchUser' ? branchUserCheck() : null}
        </>
    ) : null
}

const CanadaUniversityRecordCount = props => {
    let {user} = props
    let [countRecords, setCountRecords] = useState({})
    let [userData, setUserData] = useState({})
    let [cambieCountRecord, setCambieCountRecord] = useState({})
    let dispatch = useDispatch()

    let [fcmtApplicationDrawer, setFcmtApplicationDrawer] = useState({
        visible: false,
        universityId: ''
    })
    let [cambieApplicationDrawer, setCambieApplicationDrawer] = useState({
        visible: false,
        universityId: ''
    })

    let [directApplicationDrawer, setDirectApplicationDrawer] = useState({
        visible: false,
        universityId: ''
    })

    useEffect(() => {
        loadDirectCount()
        // loadUserCountry()
    }, [])

    const loadUserCountry = async () => {
        let resp = await dispatch(getUserWithoutLoader(user._id))
        setUserData(resp)
    }

    let loadDirectCount = async () => {
        let {data, user, cambieCollegeData} = await dispatch(canadaUniversityRecordsCountFxn())
        console.log(cambieCollegeData, 'cambieCollegeData')
        setCountRecords(data)
        setUserData(user)
        setCambieCountRecord(cambieCollegeData)
    }

    let searchData = (item, type = '') => {
        if (item.id == fcmtUniversity) {
            if (userData && userData.showAllFcmtList) {
                dispatch(
                    getUrlPushWrapper('allFcmtApplicationsList', {
                        appUniversityId: fcmtUniversity,
                        type
                    })
                )
            } else {
                dispatch(
                    getUrlPushWrapper('application.fcmtApplicationsList', {
                        appUniversityId: fcmtUniversity
                    })
                )
            }
        } else if (item.id == CambieHCollege) {
            dispatch(
                getUrlPushWrapper('application.cambieApplicationsList', {
                    appUniversityId: CambieHCollege,
                    agentId: cambieAgentId
                })
            )
        } else if (item.id == LesterCollege) {
            dispatch(
                // aa dekh ure
                getUrlPushWrapper('application.lesterUniversityApplicationList', {
                    appUniversityId: LesterCollege
                })
            )
        } else if (item.id == RoyalArtsCollege) {
            dispatch(
                getUrlPushWrapper('application.royalArtsApplicationsList', {
                    appUniversityId: RoyalArtsCollege
                })
            )
        }
    }

    let openReport = (item, type = '') => {
        dispatch(
            getUrlPushWrapper('canadaDirectUniApp', {
                appUniversityId: item.id,
                type
            })
        )
    }

    const events = {
        showFcmtApplicationDrawer: universityId => {
            setFcmtApplicationDrawer({
                visible: true,
                universityId: universityId
            })
        },
        hideFcmtApplicationDrawer: () => {
            setFcmtApplicationDrawer({
                visible: false,
                universityId: ''
            })
            loadDirectCount()
        },
        showCambieApplicationDrawer: universityId => {
            setCambieApplicationDrawer({
                visible: true,
                universityId: universityId
            })
        },
        hideCambieApplicationDrawer: () => {
            setCambieApplicationDrawer({
                visible: false,
                universityId: ''
            })
            loadDirectCount()
        },
        showDirectApplicationDrawer: universityId => {
            setDirectApplicationDrawer({
                visible: true,
                universityId: universityId
            })
        },
        hideDirectApplicationDrawer: () => {
            setDirectApplicationDrawer({
                visible: false,
                universityId: ''
            })
            loadDirectCount()
        },
        applyForCourse: universityId => {
            if (universityId == fcmtUniversity) {
                events.showFcmtApplicationDrawer(universityId)
            } else if (universityId == CambieUniversity) {
                events.showCambieApplicationDrawer(universityId)
            } else {
                events.showDirectApplicationDrawer(universityId)
            }
        }
    }

    return userData && userData._id ? (
        <>
            {/*  {user && user.userType == 'admin' ? <div className={'dirUniLabelBox card-pane-right listing-section w-100'}>
                <div>Udaan 3</div>
                <div>File pending for submission</div>
                <div>Last 8 days Applications</div>
            </div> : null}*/}
            <div
                className="card-pane-right card-pane-new listing-section w-100"
                style={{border: 'none'}}>
                <ul className={'count'}>
                    {universityListForMarketing.map(item => {
                        return (
                            <ButtonLink
                                key={item.id}
                                {...props}
                                item={item}
                                userData={userData}
                                record={countRecords[item.id] ? countRecords[item.id] : {}}
                                searchData={searchData}
                                openReport={openReport}
                                applyForCourse={events.applyForCourse}
                            />
                        )
                    })}

                    {user.userType == "admin" && cambieCountRecord && cambieCountRecord.total ? <>
                        <ButtonLink
                            key={cambieForMarketing.id}
                            {...props}
                            item={cambieForMarketing}
                            userData={userData}
                            record={cambieCountRecord}
                            searchData={searchData}
                            openReport={openReport}
                            applyForCourse={events.applyForCourse}
                        />
                    </> : null}

                </ul>
            </div>

            {fcmtApplicationDrawer && fcmtApplicationDrawer.visible ? (
                <FlairUniversity
                    visible={fcmtApplicationDrawer.visible}
                    selectedUniversity={fcmtApplicationDrawer.universityId}
                    onClose={events.hideFcmtApplicationDrawer}
                    {...props}
                />
            ) : null}

            {cambieApplicationDrawer && cambieApplicationDrawer.visible ? (
                <CambieUniversity
                    visible={cambieApplicationDrawer.visible}
                    selectedUniversity={cambieApplicationDrawer.universityId}
                    onClose={events.hideCambieApplicationDrawer}
                    {...props}
                />
            ) : null}

            {directApplicationDrawer &&
            directApplicationDrawer.visible &&
            directApplicationDrawer.universityId ? (
                <DirectUniversityComponent
                    visible={directApplicationDrawer.visible}
                    selectedUniversity={directApplicationDrawer.universityId}
                    onClose={events.hideDirectApplicationDrawer}
                    {...props}
                />
            ) : null}
        </>
    ) : null
}

export default CanadaUniversityRecordCount
