import React, {useState, useEffect} from 'react'
import {Form, Select, Button, notification, Row, Col, Table, Input, Tag} from 'antd'
import _ from 'lodash'
import {useDispatch} from 'react-redux'
import {NewAddScheduleMeeting, scheduleMeetingUsersList, NewMeetingList} from '../actions/scheduleMeeting'
import {
    InputBox,
    ScheduleTypes,
    durationList,
    countryIds,
    displayTime,
    displayDate, filterOption
} from '../../../components/_utils/appUtils'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import moment from 'moment'
import {LoadAllCountry, LoadCity, LoadState} from "../../../components/_utils/countryUtil";
import {listAllUsers} from "../../users/actions/user";
import {TableComp} from 'sz-react-utils-lite'

const {Option} = Select
const format = 'h:mm A'
const MeetingTypeList = ['In House', 'Phone Call', 'Office Visit', 'Zoom Call', 'Seminars']
const AgentTypeList = ['New', 'Existing']
const optionsList = ['Yes', 'No']
let agentFields = {
    companyName: "",
    stateName: "",
    cityName: "",
    name: "",
    countryName: "",
    email: "",
    contactNo: "",
    agentId: ""
}
const AddMeetingComponent = (props) => {
    const {form: {getFieldDecorator, setFieldsValue, getFieldValue, resetFields}} = props
    const dispatch = useDispatch()
    let [users, setUsers] = useState([])
    let [guestUsers, setGuestUsers] = useState([])
    let [time, setTime] = useState(null)
    let [email, setEmail] = useState(null)
    let [userList, setUserList] = useState([])
    let [selectedAgent, setSelectedAgent] = useState(agentFields)
    let [allCountries, setAllCountries] = useState([])
    let [allStates, setAllStates] = useState([])
    let [allCities, setAllCities] = useState([])
    const [agentList, setAgentList] = useState([])
    const [meetingList, setMeetingList] = useState([])
    const [photos, setPhotos] = useState([])
    const [uploadKey, setUploadKey] = useState(moment())
    const [workedToday, setWorkedToday] = useState("")

    useEffect(() => {
        loadUser()
        setAllCountries(LoadAllCountry())
        loadAllAgent()
        LoadMeeting()
    }, [])

    const events = {
        chooseCountry: (name) => {
            const {setFieldsValue} = props.form
            let countryName = _.find(allCountries, (item) => {
                return item.name == name
            })
            if (countryName) {
                setFieldsValue({
                    stateName: undefined,
                    cityName: undefined
                })
                setAllStates(countryName.id ? LoadState(countryName.id) : [])
            }
        },
        chooseState: (name) => {
            const {setFieldsValue} = props.form
            let stateName = _.find(allStates, (item) => {
                return item.name == name
            })
            setFieldsValue({
                cityName: undefined
            })
            if (stateName) {
                setAllCities(stateName.id ? LoadCity(stateName.id) : [])
            }
        },
    }
    const loadUser = async () => {
        let {data} = await dispatch(scheduleMeetingUsersList({}))
        setUserList(data)
    }
    const chooseDocument = (e) => {
        let {name, files} = e.target
        if (files && files.length) {
            setPhotos(files)
        }
    }


    const LoadMeeting = async () => {
        let data = await dispatch(NewMeetingList({date: moment()}))
        setMeetingList(data)
    }
    const loadAllAgent = async () => {
        let filters = {
            userType: 'agent',
            select: ['name', 'companyName', 'email', 'countryName', 'stateName', 'cityName', 'mobile'],
            block: false,
            results: 1000000
        }
        let {data} = await dispatch(listAllUsers(filters))
        setAgentList(data)
    }

    const chooseAgent = (x) => {
        let agent = _.find(agentList, (item) => {
            return item._id == x;
        })
        if (agent) {
            props.form.setFieldsValue({
                user: x
            })
            setSelectedAgent({
                ...selectedAgent,
                companyName: agent.companyName,
                stateName: agent.stateName,
                cityName: agent.cityName,
                name: agent.name,
                countryName: agent.countryName,
                email: agent.email,
                contactNo: agent.mobile,
                agentId: agent._id
            })
        }
    }

    let formFields = [
        /*{
            key: 'workedToday',
            label: 'Worked Today',
            required: true,
            type: 'select',
            showSearch: true,
            span: 6,
            options: ['Yes', 'No'],
            keyAccessor: x => x,
            valueAccessor: x => `${x}`,
            onChange: x => {
                props.form.setFieldsValue({
                    workedToday: x,
                    date: moment()
                })
            }
        },*/
        {
            key: 'topic',
            label: 'Topic',
            required: true,
            placeholder: 'Enter Topic',
            span: 24,
            hidden: !(workedToday == 'Yes')
        },
        {
            key: 'marketingCountry',
            label: 'Marketing for Country',
            required: true,
            type: 'select',
            showSearch: true,
            span: 6,
            options: ['All Country', 'UK', 'Canada', 'Australia'],
            keyAccessor: x => x,
            valueAccessor: x => `${x}`,
            hidden: !(workedToday == 'Yes'),
            onChange: x => {
                props.form.setFieldsValue({
                    marketingCountry: x
                })
            }
        },
        {
            key: 'type',
            label: 'Type',
            required: true,
            type: 'select',
            showSearch: true,
            span: 6,
            options: MeetingTypeList,
            keyAccessor: x => x,
            valueAccessor: x => `${x}`,
            hidden: !(workedToday == 'Yes'),
            onChange: x => {
                props.form.setFieldsValue({
                    type: x
                })
            }
        },
        {
            key: 'agentType',
            label: 'Agent Type',
            required: true,
            type: 'select',
            showSearch: true,
            span: 6,
            options: AgentTypeList,
            keyAccessor: x => x,
            valueAccessor: x => `${x}`,
            hidden: !(workedToday == 'Yes'),
            onChange: x => {
                props.form.setFieldsValue({
                    agentType: x
                })
                setSelectedAgent({
                    ...selectedAgent,
                    agentFields
                })
            }
        },
        {
            key: 'user',
            label: 'Agent',
            required: true,
            type: 'select',
            showSearch: true,
            span: 6,
            options: agentList,
            keyAccessor: x => x._id,
            valueAccessor: x => `${x.name}(${x.companyName})`,
            hidden: !(getFieldValue('agentType') == 'Existing'),
            onChange: x => {
                chooseAgent(x)
            }
        },

        {
            key: 'companyName',
            label: 'Company Name',
            required: true,
            placeholder: 'Enter Company Name',
            span: 6,
            hidden: !(getFieldValue('agentType') == 'New')
        },
        {
            key: 'name',
            label: 'Agent Name',
            required: true,
            placeholder: 'Enter Agent Name',
            span: 6,
            hidden: !(getFieldValue('agentType') == 'New')
        },
        {
            key: 'email',
            label: 'Email',
            required: true,
            placeholder: 'Enter Email',
            span: 6,
            type: "email",
            hidden: !(getFieldValue('agentType') == 'New')
        },
        {
            key: 'contactNo',
            label: 'Agent Contact No',
            required: true,
            placeholder: 'Enter Agent Contact No',
            span: 6,
            hidden: !(getFieldValue('agentType') == 'New')
        },
        {
            key: 'countryName',
            label: 'Country Name',
            hidden: !(getFieldValue('agentType') == 'New'),
            // required: true,
            type: 'select',
            span: 6,
            showSearch: true,
            options: allCountries,
            keyAccessor: x => x.name,
            valueAccessor: x => `${x.name}`,
            onChange: x => {
                events.chooseCountry(x)
                setFieldsValue({
                    countryName: x
                })
            }
        },

        {
            key: 'stateName',
            label: 'State Name',
            hidden: !(getFieldValue('agentType') == 'New'),
            // required: true,
            span: 6,
            type: 'select',
            showSearch: true,
            options: allStates,
            keyAccessor: x => x.name,
            valueAccessor: x => `${x.name}`,
            onChange: x => {
                events.chooseState(x)
                setFieldsValue({
                    stateName: x
                })
            }
        },

        {
            key: 'cityName',
            label: 'City Name',
            span: 6,
            hidden: !(getFieldValue('agentType') == 'New'),
            // required: true,
            type: 'select',
            showSearch: true,
            options: allCities,
            keyAccessor: x => x.name,
            valueAccessor: x => `${x.name}`,
            onChange: x => {
                setFieldsValue({
                    cityName: x
                })
            }
        },
        /* {
             key: 'date',
             label: 'Date',
             required: true, type: 'date',
             placeholder: 'Choose Date', span: 6,
             // disabled: true,
             hidden: !(workedToday == 'Yes')
         },*/
        {
            key: 'fromTime',
            label: 'From Time',
            required: true,
            type: 'time',
            use12Hours: true,
            placeholder: 'Choose From Time', span: 6,
            minuteStep: 15,
            format: format,
            hidden: !(workedToday == 'Yes')
        },
        {
            key: 'photos',
            label: 'Photos',
            required: true,
            span: 6,
            hidden: !(workedToday == 'Yes'),
            customField: (
                <InputBox title={'Photos'}>
                    <Input type={'file'} key={uploadKey} className={'form-control'}
                           name={'photos'} multiple={true} id={'photos'} onChange={(e) => {
                        chooseDocument(e)
                    }}/>
                </InputBox>
            )
        },

        {
            key: 'notes',
            label: 'Reason',
            required: true,
            type: "textArea",
            placeholder: 'Enter Reason',
            span: 24,
            hidden: !(workedToday == 'No')
        },
        {
            key: 'meetingDiscussion',
            label: 'Minutes of meeting',
            type: "textArea",
            required: true,
            placeholder: 'Enter Minutes of meeting',
            span: 24,
            hidden: !(workedToday == 'Yes')
        },

    ]

    const columns = [
        {
            title: "Worked Today",
            dataIndex: "workedToday",
            key: "workedToday",
            render: (val) => {
                return (
                    <>
                        {val == 'No' && 'Didn\'t worked today'}
                        {val == 'Yes' && 'Worked today'}
                    </>
                )
            }
        },
        {
            title: "Reason",
            dataIndex: "notes",
            key: "notes",
        },
        {
            title: "Minutes of meeting",
            dataIndex: "meetingDiscussion",
            key: "meetingDiscussion",
        },
        {
            title: "Agent",
            dataIndex: "agent",
            key: "agent",
            render: (item, record) => {
                return (
                    item && item.companyName ? <>
                        <strong>Name: </strong>{item.name} ({item.companyName})<br/>
                        <strong>Mobile No: </strong> {item.contactNo}<br/>
                        <strong>Email: </strong>{item.email}<br/>
                        {item.countryName && <div>
                            <strong>Country: </strong>{item.countryName}
                        </div>}
                        {item.stateName && <div>
                            <strong>State: </strong>{item.stateName}
                        </div>}
                        {item.cityName && <div>
                            <strong>City: </strong>{item.cityName}
                        </div>}
                    </> : null
                )
            }
        },
        {
            title: "Date/Time",
            dataIndex: "fromTime",
            key: "fromTime",
            render: (item, record) => {
                return (
                    <>
                        {record && record.date ? displayDate(record.date) : null}<br/>
                        {item ? `Time : ${displayTime(item)}` : null}
                    </>
                )
            }
        },
        {
            title: "Photos",
            dataIndex: "photos",
            key: "photos",
            render: (value) => {
                return (
                    <>
                        {value && value.length ? <>
                            <ul>
                                {value.map((item, key) => {
                                    return (
                                        <li key={key}>
                                            <Tag color={'#ddd'} className={'photoShortName'}>
                                                <a href={item.url} target={'_blank'}>{item.name}</a>
                                            </Tag>
                                        </li>
                                    )
                                })}
                            </ul>
                        </> : null}
                    </>
                )
            }
        },

    ]

    const formItemLayout = {
        labelCol: {
            xs: {span: 0},
            sm: {span: 0},
            md: {span: 0}
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 24},
            md: {span: 24}
        }
    }

    const handleSubmit = e => {
        const {form} = props
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                valData.workedToday = workedToday;

                if (valData.workedToday == 'Yes') {
                    if (selectedAgent && selectedAgent.companyName) {
                        valData.agent = selectedAgent
                    } else {
                        valData.agent = {
                            companyName: valData.companyName,
                            stateName: valData.stateName,
                            cityName: valData.cityName,
                            name: valData.name,
                            countryName: valData.countryName,
                            email: valData.email,
                            contactNo: valData.contactNo,
                        }
                    }
                    if (!photos || (photos && !photos.length)) {
                        notification.warning({
                            message: 'Choose photos.'
                        })
                        return
                    }
                }

                valData.date = moment();

                let fd = new FormData()
                fd.append('obj', JSON.stringify(valData))
                _.each(photos, (item) => {
                    fd.append('photos', item)
                })

                let data = await dispatch(NewAddScheduleMeeting(fd))
                if (data && !data.error) {
                    form.resetFields()
                    setSelectedAgent({
                        ...selectedAgent,
                        agentFields
                    })
                    setTime(moment())
                    setUploadKey(moment())
                    LoadMeeting()
                    setPhotos([])
                }
            } else {
                notification.warning({
                    message: 'Fill All Required Fields'
                })
            }
        })
    }
    return (
        <div className='row'>
            <div className='col-lg-12'>
                <div className='form-box mt-4'>

                    <div className='d-flex align-items-center mb-3 heading-form'>
                        <h5>Add Meeting Report</h5>
                    </div>

                    <div className="form-box">
                        <div className="card unizportal">
                            <Row>
                                <Col span={6}>
                                    <InputBox title={'*Worked Today'}>
                                        <Select name='Status' onChange={(item) => setWorkedToday(item)}
                                                filterOption={filterOption}
                                                placeholder={'Status'}
                                                allowClear={true}
                                                value={workedToday || undefined}>
                                            {optionsList && optionsList.length ? optionsList.map((item, key) => {
                                                return (
                                                    <Option value={item} key={key}>{item}</Option>
                                                )
                                            }) : null}
                                        </Select>
                                    </InputBox>
                                </Col>
                            </Row>
                            <Form style={{marginTop: 8, textAlign: 'left'}}>
                                <Row gutter={16} className={'rowWrap'}>
                                    {formFields.map((item, key) => {
                                        return (
                                            <React.Fragment>
                                                {!item.hidden && item.customField ?
                                                    <Col span={item.span} style={{marginTop: 5}}>
                                                        {item.customField}
                                                    </Col> : !item.hidden &&
                                                    <Col span={item.span ? item.span : 8} key={key} className={'mb10'}>
                                                        <GetEachFormFields
                                                            item={item}
                                                            getFieldDecorator={getFieldDecorator}
                                                            formItemLayout={formItemLayout}/>
                                                    </Col>}
                                            </React.Fragment>
                                        )
                                    })}
                                    <Col span={24}>
                                        <Form.Item className={'mt20'}>
                                            <Button type="success" onClick={handleSubmit} className={'btn'}>
                                                SUBMIT
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>

                            </Form>
                        </div>
                        <div className="card unizportal">
                            <TableComp dataSource={meetingList} columns={columns} size={'small'} bordered={true}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const WrappedCountry = Form.create()(AddMeetingComponent)
export default WrappedCountry
