import React, {useEffect, useRef, useState} from "react"
import {singleReceivedCommissionFxn} from "../actions";
import {Avatar, Card, Drawer, Table} from "antd";
import {useDispatch} from "react-redux";
import {
    CapText,
    defaultLogoList,
    displayDate,
    longStringDate,
} from "../../../components/_utils/appUtils";

const CommissionListDrawer = (props) => {
    let dispatch = useDispatch()
    let {visible, onClose, record} = props;
    let [commissionData, setCommissionData] = useState({})
    let [studentList, setStudentList] = useState([])
    let columns = [
        {
            title: 'Student',
            dataIndex: 'name',
            key: 'name',
            width: 150,
            render: (item, record) => {
                let {studentId, commissionDetails, appId, commissionId} = record;
                return (
                    <div style={{width: 150}}>
                        <CapText>{item}</CapText>

                        {commissionId && commissionId.commissionDetails && commissionId.commissionDetails.offerLetterStudentId ?
                            <div>
                                Offer S. ID : {commissionId.commissionDetails.offerLetterStudentId}
                            </div> : null}
                        {appId ? <div>
                            App ID : {appId}
                        </div> : null}
                    </div>
                )
            }
        },
        {
            title: 'Semester',
            dataIndex: 'semester',
            key: 'semester',
            width: 100,
            render: (item, record) => {
                let {currency} = commissionData;
                let {semesterFee} = record;
                return (
                    <div style={{width: 100}}>
                        {item}
                        <div>
                            ({currency} {semesterFee})
                        </div>
                    </div>
                )
            }
        },

        {
            title: 'Claim Date',
            dataIndex: 'claimDate',
            key: 'claimDate',
            width: 100,
            render: (item, record) => {
                let {commissionId} = record
                return (
                    <div style={{width: 100}}>
                        {commissionId && commissionId.claimDate ? displayDate(commissionId.claimDate) : ""}
                    </div>
                )
            }
        },
        {
            title: 'Course Date',
            dataIndex: 'courseStartDate',
            key: 'courseStartDate',
            width: 100,
            render: (item, record) => {
                let {commissionId} = record
                return (
                    <div style={{width: 100}}>
                        {commissionId && commissionId.commissionDetails && commissionId.commissionDetails.courseStartDate ? displayDate(commissionId.commissionDetails.courseStartDate) : ""}
                    </div>
                )
            }
        },

        {
            title: 'Intake',
            dataIndex: 'intake',
            key: 'intake',
            width: 100,
            render: (item, record) => {
                let {commissionId} = record
                return (
                    commissionId && commissionId.intake && commissionId.intake.month ? <div style={{width: 100}}>
                        {commissionId.intake.month}-{commissionId.intake.year}
                    </div> : null
                )
            }
        },
        {
            title: 'Rev. Comm.',
            dataIndex: 'studentCommission',
            key: 'studentCommission',
            width: 100,
            render: (item, record) => {
                let {currency, currencyRate} = commissionData;
                return (
                    <div style={{width: 100}}>
                        {currency == 'INR' ?
                            <>
                                {currency} {item}
                            </> :
                            <>
                                {currency} {item}
                                <div>(Rate : {currencyRate})</div>
                            </>
                        }
                    </div>
                )
            }
        },

        {
            title: 'University',
            key: 'universityId',
            dataIndex: 'universityId',
            width: 150,
            render: (item, record) => {
                return (
                    <div style={{width: 150}}>
                        {item && item.logo && item.logo.url ?
                            <Avatar src={item.logo.url} size={20}/>
                            : <Avatar src={defaultLogoList.defaultUniversityLogo} size={20}/>}
                        {item.universityName ? `  ${item.universityName}` : ''}
                    </div>
                )
            }
        },
        {
            title: 'Program',
            dataIndex: 'courseName',
            key: 'courseName',
        },


        {
            title: 'Agent',
            key: 'agentId',
            dataIndex: 'agentId',
            width: 220,
            render: (item, record) => {
                return (
                    <div style={{width: 180}}>
                        {item && item.name ? item.name : null}<br/>
                        {item && item.companyName ? `(${item.companyName})` : null}
                    </div>
                )
            }
        },
        {
            title: 'Added By',
            key: 'addedByUser',
            dataIndex: 'addedByUser',
            width: 100,
            render: (item, record) => {
                return (
                    <div style={{width: 100}}>
                        <div className={'colorPrimary font12 textCap'}>
                            {item && item.name ? item.name : null}
                        </div>
                    </div>
                )
            }
        },

    ]
    useEffect(() => {
        loadData()
    }, [])
    let loadData = async () => {
        let resp = await dispatch(singleReceivedCommissionFxn({commissionId: record.receivedCommissionId}));
        if (resp && resp.success) {
            setStudentList(resp.data.commissionList)
            setCommissionData(resp.data)
        }
    }

    return (
        <>
            <Drawer
                title={(
                    <>
                        Received Commission Student
                        List {commissionData.claimReceivedDate ? ` - (Claim Received Date : ${displayDate(commissionData.claimReceivedDate)})` : ""}
                    </>
                )}
                visible={visible}
                width={'90%'}
                onClose={onClose}>
                <div className="form-box commissionBox">
                    <div className="card unizportal">
                        <div className='card-body table-responsive'>
                            <Table className={'table table-bordered'}
                                   dataSource={studentList}
                                   columns={columns}
                                   pagination={false}
                                   extraProps={{scroll: {x: 1800}}}
                                   bordered={true}/>
                        </div>
                    </div>
                </div>
            </Drawer>
        </>
    )
}
export default CommissionListDrawer
