import React, {useEffect, useRef, useState} from 'react'
import {
    Select,
    Button,
    Tooltip,
    Icon,
    Popconfirm,
    Row,
    Col, notification, Form, Tag, Avatar
} from 'antd'
import {connect, useDispatch, useSelector} from 'react-redux'
import {
    newFormatDisplayDate, longDisplayDate, filterOption, departmentObj, Intakes, DefaultTablePagination
} from '../../../components/_utils/appUtils'
import {getPushPathWrapper, getUrlPushWrapper} from '../../../routes'
import {TableComp} from 'sz-react-utils-lite'
import {AllowComponentRightsWise, CheckManagerWiseRight} from '../../WebComponent/allowComponentRightsWise'
import ApplyCourse from '../../student/views/applyForApplicationDrawer'
import {
    applicationCloseDirect,
    reCalculateCommission, updateApplicationTuitionFees
} from '../../student/actions/student'
import {listAllUsers} from '../../users/actions/user'
import {showEnrollmentAction, countryIds} from '../../../components/_utils/appUtils'
import {directUniversityReportingApplicationFxn} from "../../reporting/actions";
import {listAllCampus} from "../../campus/actions/campus";
import {StudentMarketingUserComponent} from "../../../components/_utils/AgentBranchManagerComponent";
import DocumentDrawer from "../../applications/drawers/studentDocumentDrawer";
import StudentOtherApp from "../../applications/views/StudentOtherApplication";
import AddNote from "../../applications/drawers/createNoteComponent";
import {universityListForMarketing} from "../../../components/_utils/appUtils";
import _ from "lodash"
import {exportDirectUniversityRecordsCountFxn} from "../../applications/actions/chooseStudent";

const statusList = [
    'Offer Received',
    'Rqst LOA',
    'LOA Generated',
    'File pending for submission',
    'File Submitted',
    'Visa Approved',
    'Visa Rejected'
]
const {Option} = Select
const initialState = {
    applicationList: [],
    applicationObj: {}
}
const commissionObj = {
    commissionAmount: 0,
    tuitionFee: 0,
    studentId: '',
    applicationId: ''
}
const OfferActionComponent = (props) => {
    let {file, reCreateOfferLetter, record, events} = props
    let {applications} = record
    return (
        <div>
            {file && file.path ?
                <Tooltip title={'Download Offer Letter'}>
                    <a download={file.fileName} href={file.path}>
                        <img src={'../assets/icons/cloud-computing-download.png'}/>
                    </a></Tooltip> : ''}


        </div>
    )
}
const LoaRequestActionComponent = (props) => {
    let {record, val, events} = props
    return (
        <div>
            {record.applications && record.applications.status == 'Offer Received' ?
                <Tooltip title={'Rqst LOA'}>
                    <a onClick={() => events.openLoaDrawer(record, false)}>
                        <img src={'/assets/icons/cloud-computing-upload.png'}/>
                    </a></Tooltip> : null}

            {val ?
                <Tooltip title={val}><a>
                    <img src={`${val == 'Pending' ? '/assets/icons/pendingCheck.png' : '/assets/icons/doneCheck.png'}`}
                         className={'sm'}/>
                </a></Tooltip> : null}


            {/*  {record.applications && record.applications.status !== 'Offer Received' ?
                <div className={'mt10'}>
                    <a className={'bs-link bs-xs'} onClick={() => events.openLoaDrawer(record, true)}>Re-Upload
                        LOA</a>
                </div>
                : ''}*/}
        </div>
    )
}

const LoaActionComponent = (props) => {
    let {record, val, events} = props
    let {applications} = record
    return (
        val && val.path ?
            <>
                <Tooltip title={'Download LOA'}>
                    <a download={val.fileName} href={val.path}>
                        <img src={'/assets/icons/cloud-computing-download.png'}/>
                    </a></Tooltip>

            </> : ''
    )
}
const ReceiptActionComponent = (props) => {
    let {file, record, val, events} = props
    let {applications} = record

    return (
        <div>
            {file && file.path ?
                <Tooltip title={'Download Receipt'}>
                    <a download={file.fileName} href={file.path}>
                        <img src={'../assets/icons/cloud-computing-download.png'}/>
                    </a></Tooltip> : ''}
        </div>
    )
}

const RenderComment = (props) => {
    let {item} = props
    let [hideV, setHideV] = useState(true)
    return (
        item && <div>
            {item.addedByUserId && item.addedByUserId.name ?
                <div className={'alignRight colorPrimary font12'}>
                    (Added By : {item.addedByUserId.name} )
                </div> : null}
            <div className={hideV ? 'appCommentDotsReplace' : ''}>
                {item && item.text ? item.text : ''}
            </div>
            {item && item.text.length && item.text.length > 50 ? <div className={'alignRight'}>
                {hideV ? <a onClick={() => {
                    setHideV(false)
                }}>Show More</a> : <a onClick={() => {
                    setHideV(true)
                }}>Show Less</a>}
            </div> : ''}

        </div>
    )
}
const DirectUniversityApplication = (props) => {
    let {onClose, studentObj = {}, reloadTable} = props

    const [state, setState] = useState(initialState)
    const [transferAppVisible, setTransferAppVisible] = useState(false)
    const [studentData, setStudentData] = useState({})
    const [visibleAddCourseDrawer, setVisibleAddCourseDrawer] = useState(false)
    const [name, setName] = useState('')
    const [dateOfBirth, setDateOfBirth] = useState('')
    const [allAgent, setAllAgent] = useState([])
    const [agentId, setAgentId] = useState('')
    const [visibleOtherAppDrawer, setVisibleOtherAppDrawer] = useState(false)
    const [visibleAddCommentDrawer, setVisibleAddCommentDrawer] = useState(false)
    const [totalApplication, setTotalApplication] = useState(0)
    const [visibleFeesModal, setVisibleFeesModal] = useState(false)
    const [feesObj, setFeesObj] = useState(commissionObj)
    const [currentStatus, setCurrentStatus] = useState('')
    const [status, setStatus] = useState('')
    let {applicationObj} = state
    let [tableSearch, setTableSearch] = useState(false)
    let [filters, setFilters] = useState({})
    let [visibleLoaReq, setVisibleLoaReq] = useState(false)
    let [visibleFcmtStatus, setVisibleFcmtStatus] = useState(false)
    let [visibleApprovalDrawer, setVisibleApprovalDrawer] = useState(false)
    let [isEditAble, setIsEditAble] = useState(false)
    let [campusList, setCampusList] = useState([])
    const [campusId, setCampusId] = useState('')
    const [branchUserId, setBranchUserId] = useState('')
    const [appUniversityId, setAppUniversityId] = useState('')
    let [marketingUserList, setMarketingUserList] = useState([])
    const [intake, setIntake] = useState('')
    let [courseName, setCourseName] = useState('')
    let [studentShore, setStudentShore] = useState('')
    let [universityName, setUniversityName] = useState('')

    let [selectedApplication, setSelectedApplication] = useState({
        visible: false,
        studentId: '',
        application: '',
        studentData: {}
    })
    let [enrollmentObj, setEnrollmentObj] = useState({
        visible: false,
        studentId: '',
        application: '',
        studentData: {}
    })
    let [changeUniversity, setChangeUniversity] = useState({
        visible: false,
        studentId: '',
        application: '',
        studentData: {}
    })
    let [visibleDocumentDrawer, setVisibleDocumentDrawer] = useState(false)

    const dispatch = useDispatch()
    let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
    const tableAppRef = useRef()

    let apiRequest = (params) => {
        let searchParams = new URLSearchParams(window.location.search)
        let appUniversityId = searchParams.get('appUniversityId')
        return new Promise(async (resolve) => {
            let resp = await getParamsForApplicationList()
            params = {...params, ...resp}
            if (!params.results) {
                params.results = 50
                params.count = 50
            }
            if (tableSearch) {
                params.page = 1
            }
            try {
                setFilters(params)
                let {data} = await dispatch(directUniversityReportingApplicationFxn({
                    ...params,
                    regExFilters: ['name']
                }))
                setTotalApplication(data.total)
                setTableSearch(false)
                resolve(data)
            } catch (e) {
            }
        })
    }


    useEffect(() => {
        loadAgentData()
        loadCampusList()
        setFieldByParams()
        loadMarketingUsers()
        loadUniName()
    }, [])

    const loadUniName = async () => {
        let resp = await getParamsForApplicationList()
        let foundUni = _.find(universityListForMarketing, (item) => {
            return item.id == resp.appUniversityId;
        })
        if (foundUni) {
            setUniversityName(foundUni.name)
        }
    }

    const setFieldByParams = async () => {
        let resp = await getParamsForApplicationList()
        // setCurrentStatus(resp.status)

        if (resp.campusId) {
            setCampusId(resp.campusId)
        }
        if (resp.status) {
            setStatus(resp.status)
        }
        if (resp.agentId) {
            setAgentId(resp.agentId)
        }
        if (resp.intake) {
            setIntake(resp.intake)
        }
        if (resp.courseName) {
            setCourseName(resp.courseName)
        }
        if (resp.studentShore) {
            setStudentShore(resp.studentShore)
        }
        if (resp.branchUserId) {
            setBranchUserId(resp.branchUserId)
        }
        if (resp.name) {
            setName(resp.name)
        }
        if (resp.currentStatus) {
            setCurrentStatus(resp.currentStatus)
        }
    }

    const loadAgentData = async () => {
        let params = {
            results: 12000,
            userType: 'agent',
            sortField: 'companyName',
            sortOrder: 'ascend',
            select: ['name', 'companyName']
        }
        let {data} = await dispatch(listAllUsers(params))
        if (data && data.length) {
            setAllAgent(data)
        }
    }
    const loadCampusList = async () => {
        let searchParams = new URLSearchParams(window.location.search)
        let appUniversityId = searchParams.get('appUniversityId')
        let params = {}
        params.sortField = 'campusName'
        params.sortOrder = 'ascend'
        params.campusUniversity = appUniversityId
        params.results = 50
        params.count = 50
        params.select = ['campusName']
        let {data} = await dispatch(listAllCampus({...params, regExFilters: ['campusAddress', 'campusName']}))
        setCampusList(data)
    }


    const events = {
        reloadFxn: () => {
            tableAppRef.current.reload()
        },
        showTransferApp: () => {
            setTransferAppVisible(true)
        },
        hideTransferApp: () => {
            setTransferAppVisible(false)
            tableAppRef.current.reload()
        },
        showAddCourseDrawer: () => {
            setVisibleAddCourseDrawer(true)
        },
        hideAddCourseDrawer: () => {
            setVisibleAddCourseDrawer(false)
            tableAppRef.current.reload()
        },
        refreshApplication: () => {
            tableAppRef.current.reload()
        },

        closeDirectApp: async (record) => {
            let obj = {
                studentId: record._id,
                applicationId: record.applications._id
            }
            let resp = await dispatch(applicationCloseDirect(obj))
            tableAppRef.current.reload()
        },

        enterName: (value) => {
            setName(value)

        },
        openOtherAppDrawer: (value) => {
            setState({
                ...state,
                applicationObj: value
            })
            setVisibleOtherAppDrawer(true)
        },
        closeOtherAppDrawer: () => {
            setState({
                ...state,
                applicationObj: {}
            })
            setVisibleOtherAppDrawer(false)
        },
        openAddCommentDrawer: (value) => {
            setState({
                ...state,
                applicationObj: value
            })
            setVisibleAddCommentDrawer(true)
        },
        closeAddCommentDrawer: () => {
            setState({
                ...state,
                applicationObj: {}
            })
            setVisibleAddCommentDrawer(false)
        },
        hideVisibleFeesModal: () => {
            setVisibleFeesModal(false)
        },
        setCommissionLocal: (data) => {
            setFeesObj({
                ...feesObj,
                ...data
            })
        },
        reCalculateCommissionFxn: async () => {
            let {commissionAmount} = await dispatch(reCalculateCommission(feesObj))
            if (commissionAmount) {
                events.setCommissionLocal({commissionAmount})
            }

        },
        updateApplicationTuitionFeesFxn: async () => {
            let {success, message} = await dispatch(updateApplicationTuitionFees(feesObj))
            if (success) {
                setFeesObj(commissionObj)
                setVisibleFeesModal(false)
                events.reloadFxn()
            }
        },

        openLoaDrawer: (record, editVal) => {
            setStudentData(record)
            setVisibleLoaReq(true)
            setIsEditAble(editVal)
        },
        closeLoaDrawer: () => {
            setStudentData({})
            setVisibleLoaReq(false)
        },
        approveRequest: async (record) => {
            setStudentData(record)
            setVisibleApprovalDrawer(true)
        },
        closeApproveRequest: async (record) => {
            setStudentData({})
            setVisibleApprovalDrawer(false)
        },
        openFcmtStatusDrawer: (record) => {
            setStudentData(record)
            setVisibleFcmtStatus(true)
        },
        closeFcmtStatusDrawer: () => {
            setStudentData({})
            setVisibleFcmtStatus(false)
        },
        openDocumentDrawer: async (record) => {
            setStudentData(record)
            setVisibleDocumentDrawer(true)
        },
        closeDocumentDrawer: async (record) => {
            setStudentData({})
            setVisibleDocumentDrawer(false)
        },
    }
    const reActivateApp = (record) => {
        setSelectedApplication({
            visible: true,
            studentId: record._id,
            studentData: record,
            application: record.applications
        })
    }

    const enrollmentApp = (record) => {
        setEnrollmentObj({
            visible: true,
            studentId: record._id,
            studentData: record,
            application: record.applications
        })
    }

    const changeUniversityFxn = (record) => {
        setChangeUniversity({
            visible: true,
            studentId: record._id,
            studentData: record,
            application: record.applications
        })
    }

    const enrollmentBtn = (record) => {
        /* let application = record.applications
         return (
             !application.enrollment ? showEnrollmentAction(application.statusList) ?
                 <button className='btn' onClick={() => enrollmentApp(record)}>
                     <img src={'/assets/enrollment.png'}/>
                 </button> : null : null
         )*/
        return null
    }

    const getParamsForApplicationList = () => {
        return new Promise((resolve) => {
            let searchParams = new URLSearchParams(window.location.search)
            let appUniversityId = searchParams.get('appUniversityId')
            let userId = searchParams.get('userId')
            let name = searchParams.get('name')
            let campusId = searchParams.get('campusId')
            let branchUserId = searchParams.get('branchUserId')

            let countryId = searchParams.get('countryId')
            let status = searchParams.get('status')
            let currentStatus = searchParams.get('currentStatus')
            let agentId = searchParams.get('agentId')
            let courseId = searchParams.get('courseId')
            let note = searchParams.get('note')
            let intake = searchParams.get('intake')
            let toDate = searchParams.get('toDate')
            let fromDate = searchParams.get('fromDate')
            let department = searchParams.get('department')
            let userType = searchParams.get('userType')

            let courseName = searchParams.get('courseName')
            let studentShore = searchParams.get('studentShore')

            let obj = {}
            if (agentId) {
                obj.agentId = agentId
            }
            if (countryId) {
                obj.countryId = countryId
            }
            if (status) {
                obj.status = status
            }
            if (currentStatus) {
                obj.currentStatus = currentStatus
            }
            if (courseId) {
                obj.courseId = courseId
            }
            if (appUniversityId) {
                obj.appUniversityId = appUniversityId
            }
            if (name) {
                obj.name = name
            }
            if (intake) {
                obj.intake = intake
            }
            if (branchUserId) {
                obj.branchUserId = branchUserId
            }

            if (note) {
                obj.note = note
            }
            if (userId) {
                obj.userId = userId
            }
            if (toDate) {
                obj.toDate = toDate
            }
            if (fromDate) {
                obj.fromDate = fromDate
            }
            if (department) {
                obj.department = department
            }
            if (userType) {
                obj.userType = userType
            }


            if (appUniversityId) {
                obj.appUniversityId = appUniversityId
            }
            if (name) {
                obj.name = name
            }

            if (campusId) {
                obj.campusId = campusId
            }
            if (status) {
                obj.status = status
            }
            if (agentId) {
                obj.agentId = agentId
            }

            if (branchUserId) {
                obj.branchUserId = branchUserId
            }
            if (courseName) {
                obj.courseName = courseName
            }
            if (studentShore) {
                obj.studentShore = studentShore
            }

            resolve(obj)
        })
    }

    const searchFxn = async () => {
        let resp = await getParamsForApplicationList()
        let searchParams = new URLSearchParams(window.location.search)
        let obj = {
            appUniversityId: resp.appUniversityId,
            userId: resp.userId,
            toDate: resp.toDate,
            fromDate: resp.fromDate,
            department: resp.department,
            userType: resp.userType,
        }
        if (name) {
            obj.name = name
        }

        if (campusId) {
            obj.campusId = campusId
        }
        if (status) {
            obj.status = status
        }
        if (agentId) {
            obj.agentId = agentId
        }
        if (branchUserId) {
            obj.branchUserId = branchUserId
        }
        if (intake) {
            obj.intake = intake
        }
        if (courseName) {
            obj.courseName = courseName
        }
        if (studentShore) {
            obj.studentShore = studentShore
        }
        if (currentStatus) {
            obj.currentStatus = currentStatus
        }
        dispatch(
            getUrlPushWrapper('directUniversityMarketingReport', {
                ...obj
            }))
        setTableSearch(true)
        setTimeout(() => {
            if (tableAppRef && tableAppRef.current) {
                tableAppRef.current.reload()
            }
        }, 200)
    }
    const clearFxn = async () => {
        let resp = await getParamsForApplicationList()
        let obj = {}
        setName('')
        setAppUniversityId('')
        setCampusId('')
        setStatus('')
        setAgentId('')
        setBranchUserId('')
        setCurrentStatus('')
        dispatch(
            getUrlPushWrapper('application.kasaApplicationList', {
                ...obj
            }))
        setTableSearch(true)
        setTimeout(() => {
            tableAppRef.current.reload()
        }, 200)
    }

    const extra = (
        <div>
            <Row gutter={12}>
                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className='search-box-table round'>
                        <input className='form-control form-control' type='search'
                               placeholder='Search by name...' value={name} onChange={(e) => {
                            events.enterName(e.target.value)
                        }}/>
                        <img src='/dist/img/search.png' alt=''/>
                    </div>
                </Col>
                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className='sort-box-table custom-sort-box-new'>
                        <Select name='Status' onChange={(item) => setCurrentStatus(item)}
                                filterOption={filterOption}
                                className={'antSelect'}
                                placeholder={'Current Status'}
                                allowClear={true}
                                value={currentStatus || undefined}>
                            {statusList && statusList.length ? statusList.map((item, key) => {
                                return (
                                    <Option value={item} key={key}>{item}</Option>
                                )
                            }) : null}
                        </Select>
                    </div>
                </Col>

                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className='sort-box-table custom-sort-box-new'>
                        <Select name='Status' onChange={(item) => setStatus(item)}
                                filterOption={filterOption}
                                className={'antSelect'}
                                placeholder={'Status'}
                                allowClear={true}
                                value={status || undefined}>
                            {statusList && statusList.length ? statusList.map((item, key) => {
                                return (
                                    <Option value={item} key={key}>{item}</Option>
                                )
                            }) : null}
                        </Select>
                    </div>
                </Col>

                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className='sort-box-table custom-sort-box-new'>
                        <Select name='Status' onChange={(item) => setCampusId(item)}
                                className={'antSelect'}
                                placeholder={'Campus'}
                                allowClear={true}
                                value={campusId || undefined}>
                            {campusList && campusList.length ? campusList.map((item, key) => {
                                return (
                                    <Option value={item._id} key={key}>{item.campusName}</Option>
                                )
                            }) : null}
                        </Select>
                    </div>
                </Col>

                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className='sort-box-table custom-sort-box-new'>
                        <Select name='Search By Agent'
                                filterOption={filterOption}
                                className={'antSelect'}
                                allowClear={true}
                                placeholder={'Agent'}
                                showSearch={true}
                                onChange={(item) => {
                                    setAgentId(item)
                                }}
                                value={agentId || undefined}>
                            {allAgent && allAgent.length ? allAgent.map((item, key) => {
                                return (
                                    <Option value={item._id} key={key}>{item.companyName}</Option>
                                )
                            }) : null}
                        </Select>
                    </div>
                </Col>
                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className='sort-box-table custom-sort-box-new'>
                        <Select name='Intake' onChange={(item) => setIntake(item)}
                                filterOption={filterOption}
                                className={'antSelect'}
                                allowClear={true}
                                showSearch={true}
                                placeholder={'Intake'}
                                value={intake || undefined}>
                            {Intakes && Intakes.length ? Intakes.map((item, key) => {
                                return (
                                    <Option value={item} key={key}>{item}</Option>
                                )
                            }) : null}
                        </Select>
                    </div>
                </Col>
                <Col span={5}>
                    <div className='sort-box-table custom-sort-box-new'>
                        <div className='search-box-table round'>
                            <input className='form-control form-control' type='search'
                                   placeholder='course name...' value={courseName} onChange={(e) => {
                                setCourseName(e.target.value)
                            }}/>
                            <img src='/dist/img/search.png' alt=''/>
                        </div>
                    </div>
                </Col>

                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className='sort-box-table custom-sort-box-new'>
                        <Select name='Search By Student Shore'
                                filterOption={filterOption}
                                className={'antSelect'}
                                allowClear={true}
                                placeholder={'Student Shore'}
                                onChange={(item) => setStudentShore(item)}
                                value={studentShore || undefined}>
                            <Option value={'OnShore'}>OnShore</Option>
                            <Option value={'OffShore'}>OffShore</Option>
                        </Select>
                    </div>
                </Col>


                <Col md={4} sm={4} xs={12} lg={4}>
                    <Button onClick={() => searchFxn()} className={'roundBtn'}>Search</Button>
                    {/*<Button onClick={() => clearFxn()} className={'roundBtn'}>
                        Clear
                    </Button>*/}
                </Col>


            </Row>

        </div>
    )
    const columns = [

        {
            title: '#',
            key: 'id',
            dataIndex: 'id',
            searchTextName: 'id',
            width: 60,
            render: (item, record, index) => {
                return (
                    <div style={{width: 60}}>
                        {index + 1}
                    </div>
                )
            }
        },
        {
            title: 'App#',
            key: 'applications.id',
            dataIndex: 'applications.id',
            searchTextName: 'applications.id',
            width: 60,
            render: (item) => {
                return (
                    <div style={{width: 60}}>
                        {item}
                    </div>
                )
            }
        },
        {
            title: 'Name',
            key: 'name',
            dataIndex: 'name',
            width: 120,
            render: (item, record) => {
                return (
                    <div style={{width: 120}}>
                        <Tooltip title={'Edit Student'}>
                            <a className={'linkAA'}
                               onClick={() => dispatch(
                                   getPushPathWrapper('student.editStudent', {id: record._id}))
                               }>
                                {item} <Icon type={'edit'}/>
                            </a>
                        </Tooltip>
                        <br/>
                        {newFormatDisplayDate(record.dateOfBirth)}
                        <label className={'label label-tag'}>
                            {record.studentShore ? `(${record.studentShore})` : null}
                        </label>
                    </div>
                )
            }
        },
        {
            title: 'Program',
            key: 'applications.courseName',
            dataIndex: 'applications.courseName',
            width: 150,
            render: (item) => {
                return (
                    <div style={{width: 150}}>
                        {item}
                    </div>
                )
            }
        },
        {
            title: 'Intake',
            key: 'applications.intake',
            dataIndex: 'applications.intake',
            width: 80,
            render: (item, record) => {
                return (
                    <div style={{width: 80}}>
                        {item && item.month && item.year ? `${item.month}, ${item.year}` : ''}
                    </div>
                )
            }
        },
        {
            title: 'Campus',
            key: 'applications.campusId',
            dataIndex: 'applications.campusId',
            width: 85,
            render: (item, record) => {
                return (
                    <div style={{width: 85}}>
                        {item && item.campusName ? item.campusName : ''}
                    </div>
                )
            }
        },
        {
            title: 'Current User',
            key: 'applications.assignedUserId',
            dataIndex: 'applications.assignedUserId',
            width: 100,
            render: (val, record) => {
                let {agentId, statusList, status} = record.applications
                return (
                    <div style={{width: 100}}>
                        {val && val.name ? val.name : ''}<br/>
                        <div>
                            Agent : {agentId && agentId.companyName ? agentId.companyName : ''}
                            <br/>
                            {agentId && agentId.mobile ? <> <Icon type={'phone'} style={{
                                position: 'relative',
                                bottom: 3
                            }}/> {agentId.mobile}</> : ''}
                        </div>
                        <div>{longDisplayDate(statusList.date)}</div>
                    </div>
                )
            }
        },
        {
            title: 'Family / Given Name',
            key: 'applications.familyName',
            dataIndex: 'applications.familyName',
            width: 100,
            render: (item, record) => {
                return (
                    <div style={{width: 100}}>
                        {item && item ? <p>Family Name : <p style={{fontWeight: 'bold'}}>{item}</p></p> : ''}
                        {record && record.applications && record.applications.givenName ?
                            <p>Given Name : <p style={{fontWeight: 'bold'}}>{record.applications.givenName}</p>
                            </p> : ''}
                        {record && record.applications && record.applications.loaRequested === 'Approved' ?
                            <Icon type={'edit'} onClick={() => events.changeNameOpen(record)}/> : null}
                    </div>
                )
            }
        },

        {
            title: 'Conditional Offer Letter',
            key: 'applications.offerReceiveLetter',
            dataIndex: 'applications.offerReceiveLetter',
            width: 100,
            render: (val, record) => {
                return (
                    <div style={{width: 100}} className={'fcmtDiv'}>

                        <OfferActionComponent file={val} record={record} events={events}/>
                    </div>
                )
            }
        },
        {
            title: 'Rqst LOA',
            key: 'applications.loaRequested',
            dataIndex: 'applications.loaRequested',
            width: 100,
            render: (val, record) => {
                return (
                    <div style={{width: 100}} className={'fcmtDiv'}>
                        <LoaRequestActionComponent val={val} record={record} events={events}/>
                    </div>
                )
            }
        },
        {
            title: 'Download LOA',
            key: 'applications.loaLetter',
            dataIndex: 'applications.loaLetter',
            width: 100,
            render: (val, record) => {
                return (
                    <div style={{width: 100}} className={'fcmtDiv'}>
                        <LoaActionComponent val={val} record={record} events={events}/>
                    </div>
                )
            }
        },

        /*  {
              title: 'Receipt',
              key: 'applications.receipt',
              dataIndex: 'applications.receipt',
              width: 80,
              render: (val, record) => {
                  return (
                      <div style={{width: 80}} className={'fcmtDiv'}>
                          <ReceiptActionComponent val={val} record={record} events={events}/>

                      </div>
                  )
              }
          },*/
        {
            title: 'Status',
            key: 'applications.status',
            dataIndex: 'applications.status',
            width: 120,
            render: (item, record) => {
                let {applications} = record
                return (
                    <div className={'fcmtDiv'} style={{width: 120}}>
                        {item.includes('File') || item.includes('Visa') ? <div>
                            {item}<br/>
                            {applications.statusList && applications.statusList.date ?
                                <div>
                                    Date : {newFormatDisplayDate(applications.statusList.date)}<br/>
                                </div> : null}
                        </div> : null}

                        {applications && applications.loaRequested && applications.loaRequested == 'Approved' ?
                            <Tooltip title={'Update Status'}>
                                <a onClick={() => events.openFcmtStatusDrawer(record)}>
                                    <img src={'/assets/icons/updated.png'} className={'sm'}/>
                                </a>
                            </Tooltip>
                            : null}
                    </div>
                )
            }
        },

        {
            title: 'Marketing',
            dataIndex: 'marketingUser',
            width: 120,
            key: 'marketingUser',
            render: (item, record) => {
                let {agentId, universityCountry} = record.applications
                return (
                    <React.Fragment>
                        {marketingUserList && marketingUserList.length ?
                            <StudentMarketingUserComponent
                                userId={agentId._id}
                                countryId={universityCountry._id}
                                branchManagers={marketingUserList}/> : null}
                    </React.Fragment>
                )
            }
        },
        {
            title: 'Latest Note',
            key: 'applications.latestComment',
            dataIndex: 'applications.latestComment',
            width: 150,
            render: (val) => {
                return (
                    <div style={{width: 150}}>
                        {val && val.text ? <RenderComment item={val}/> : null}
                    </div>
                )
            }
        },
        /*  {
              title: 'FCMT Note',
              key: 'applications.latestFcmtNote',
              dataIndex: 'applications.latestFcmtNote',
              hidden: showFcmtNoteField,
              width: 200,
              render: (val, record) => {
                  let {applications, _id} = record;
                  return (
                      <div style={{width: 200}}>
                          {val && val.text ? <RenderComment item={val}/> : null}
                          <br/>
                          <FcmtNotesComponent application={applications} studentId={_id} user={user}
                                              reloadTable={events.reloadFxn}/>
                      </div>
                  )
              }
          },*/
        {
            title: 'Action',
            key: 'action',
            dataIndex: 'action',
            width: 100,
            render: (val, record) => {
                return (
                    <div className={'actionBtnGroup'}>

                        <div>
                            <a className='btn'
                               onClick={() => events.openDocumentDrawer(record)}>
                                <img src='../dist/img/view.svg' alt=''/>
                            </a>


                            <Tooltip title={'View Other Application'}>
                                <button className='btn' onClick={() => {
                                    events.openOtherAppDrawer(record)
                                }}><Icon type={'ordered-list'}/></button>

                            </Tooltip>

                            <Tooltip title={'Add Note'}>
                                <button className='btn' onClick={() => {
                                    events.openAddCommentDrawer(record)
                                }}><Icon type={'plus'}/></button>
                            </Tooltip>
                            <CheckManagerWiseRight>
                                {record.applications.status !== 'Case Close' &&
                                <Tooltip title={'Case Closed'}>
                                    <Popconfirm title={'Are your sure, you want to Close Case?'}
                                                onConfirm={() => {
                                                    events.closeDirectApp(record)
                                                }}
                                                okText='Yes' cancelText='No'>
                                        <button className='btn'><img src='../dist/img/cross.svg' alt=''
                                                                     className={'crossBtn'}/></button>
                                    </Popconfirm>
                                </Tooltip>}
                            </CheckManagerWiseRight>

                        </div>


                        <AllowComponentRightsWise rightUserType={['admin', 'branchManager']}>
                            {
                                record.applications.status == 'Case Close' ?
                                    <Tooltip title={'Re-Open Case'}>
                                        <button className='btn' onClick={() => reActivateApp(record)}>
                                            <img src={'/assets/power-button.png'}/>
                                        </button>
                                    </Tooltip> : null
                            }
                        </AllowComponentRightsWise>
                        <AllowComponentRightsWise rightUserType={['admin', 'branchManager']}>
                            {
                                record.applications.status !== 'Case Close' ?
                                    <Tooltip title={'Change University'}>
                                        <button className='btn' onClick={() => changeUniversityFxn(record)}>
                                            <img src={'/assets/shuffle-arrows.png'}/>
                                        </button>
                                    </Tooltip> : null
                            }
                        </AllowComponentRightsWise>

                        <AllowComponentRightsWise rightUserType={['admin']}>
                            {enrollmentBtn(record)}
                        </AllowComponentRightsWise>


                    </div>
                )
            }
        }

    ]

    const resp = useSelector(state => {
        let {loadNoteRedux} = state.chooseApplication
        if (loadNoteRedux) {
            if (tableAppRef && tableAppRef.current) {
                tableAppRef.current.reload()
            }
            dispatch({type: 'END_LOAD_NOTE'})
        }
    })
    const loadMarketingUsers = async () => {
        let obj = {
            userType: 'branchUser',
            department: departmentObj.marketing,
            results: 100,
            select: ['name', 'email', 'mobile', 'agents', 'assignStateCountry']
        }
        let {data} = await dispatch(listAllUsers(obj))
        setMarketingUserList(data)
    }

    let exportExcel = async () => {
        let params = {}
        let resp = await getParamsForApplicationList()
        params = {...params, ...resp}
        params.results = totalApplication
        params.count = totalApplication
        let {success, filePath, message} = await dispatch(exportDirectUniversityRecordsCountFxn(params))
        if (success) {
            notification.success({message: message})
            // window.open(`${apiUrl}${filePath}`, 'Download')
        } else {
            notification.error({message: message})
        }
    }

    return (
        <React.Fragment>
            <div>
                <div className='row mt-4'>
                    <div className='col-lg-12'>
                        <div className='card'>
                            <div className='table-head d-flex align-items-center all-student-search'
                                 style={{height: 60}}>
                                <Button onClick={() => dispatch(getUrlPushWrapper('dashboard'))}
                                        className={'roundGoBackBtn'} icon={'arrow-left'}>
                                    Back
                                </Button>
                                <h5>All {universityName} Application </h5>
                                <div className='search-box-table' style={{backgroundColor: 'transparent'}}>
                                    (Total - {totalApplication})
                                </div>
                                <div className='sort-box-table mark-btn'>
                                    <span className={'sort-box-table-right'}>
                                            <Tooltip title={'Export Excel'}>
                                              {' '} <Button onClick={() => exportExcel()} className={'roundBtn'}>Export Excel</Button>
                                            </Tooltip>
                                    </span>
                                </div>
                            </div>
                            <div className='card-body table-responsive'>
                                {extra}
                                <TableComp columns={columns}
                                           rowKey={(item, index) => {
                                               return index
                                           }}
                                           apiRequest={apiRequest}
                                           pagination={DefaultTablePagination()}
                                           ref={tableAppRef}
                                />
                            </div>
                        </div>
                    </div>
                </div>


            </div>

            {visibleAddCourseDrawer ? <ApplyCourse
                visible={visibleAddCourseDrawer}
                onClose={() => events.hideAddCourseDrawer()}
                refreshApplication={() => events.refreshApplication()}
                studentId={studentObj._id}/> : null}


            {visibleOtherAppDrawer ?
                <StudentOtherApp visible={visibleOtherAppDrawer}
                                 applicationObj={state.applicationObj}
                                 onStudentOtherAppClose={() => events.closeOtherAppDrawer()}
                                 reloadFxn={events.reloadFxn}
                /> : null}

            {visibleAddCommentDrawer && applicationObj && applicationObj._id ?
                <AddNote visible={visibleAddCommentDrawer} studentData={applicationObj}
                         addedType={'direct'}
                         onClose={() => events.closeAddCommentDrawer()}
                         applicationId={applicationObj.applications._id}/> : null}

            {visibleDocumentDrawer && studentData.applications && studentData.applications._id ?
                <DocumentDrawer onClose={events.closeDocumentDrawer}
                                reloadTable={events.reloadFxn}
                                studentData={studentData}
                                applicationId={studentData.applications._id}
                                studentId={studentData._id}
                                visible={visibleDocumentDrawer}/> : null}
        </React.Fragment>
    )
}
const mapStateToProps = ({global, router, chooseApplication}) => ({})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

const WrappedComponent = Form.create()(DirectUniversityApplication)
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WrappedComponent)
