import { Select } from 'antd'
import lodash from 'lodash'
import React from 'react'
import CountryJson from '../../assets/jsonFiles/countries'
import CountryCodeJson from '../../assets/jsonFiles/countryCode'
import CountryCodeFlagJson from '../../assets/jsonFiles/countryCodeFlag.json'
import CityJson from '../../assets/jsonFiles/indiaCities.json'
import CityJsonWithPinCode from '../../assets/jsonFiles/indianCitesWithPinCode.json'
import StateJson from '../../assets/jsonFiles/states'
import InputBox from './InputBox'
import { filterOption } from './appUtils'

const { Option } = Select
const LoadAllCountry = () => {
  return CountryJson
}

export const Cities = [...CityJson]
export const CityJsonWithPinCodes = [...CityJsonWithPinCode]
const LoadState = countryId => {
  if (countryId) {
    let filterArr = lodash.filter(StateJson, item => {
      return item.country_id == countryId
    })
    return filterArr
  } else {
    return StateJson
  }
}

const LoadCity = stateId => {
  if (stateId) {
    let filterArr = lodash.filter(CityJson, item => {
      return item.state_id == stateId
    })
    return filterArr
  } else {
    return CityJson
  }
}
const CountryList = props => {
  let { chooseCode, countryCode = '', customStyle = { marginTop: 5 } } = props
  // console.log(countryCode,'countryCode')
  return (
    <div style={customStyle}>
      <select
        className={'form-control'}
        placeholder="Country Code"
        value={countryCode || undefined}
        onChange={e => {
          chooseCode(e)
        }}>
        <option value={''}>Choose Nationality</option>
        {CountryCodeFlagJson.map(items => {
          return (
            <option value={items.countryCode} key={items.countryCode}>
              {items.name}
            </option>
          )
        })}
      </select>
    </div>
  )
}

const CountryCodeWithFlag = props => {
  let { chooseCode, countryCode = '', customStyle = { marginTop: 5 } } = props
  return (
    <div style={customStyle}>
      <InputBox title={'* Country Code'}>
        <Select
          className={'countryCode'}
          showSearch={true}
          placeholder="Country Code"
          optionFilterProp={'label'}
          value={countryCode || undefined}
          filterOption={(input, options) => {
            return (
              options.props.label.toLowerCase().indexOf(input.toLowerCase()) >=
              0
            )
          }}
          onChange={e => {
            chooseCode(e)
          }}>
          {CountryCodeFlagJson.length &&
            CountryCodeFlagJson.map(item => {
              return (
                <Option
                  value={item.countryCode}
                  label={`${item.dialCode} ${item.name}`}
                  key={item.countryCode}>
                  <span>
                    <img src={item.flag} height={18} width={20} /> (
                    {item.dialCode})
                  </span>
                </Option>
              )
            })}
        </Select>
      </InputBox>
    </div>
  )
}

export {
  CountryCodeFlagJson,
  CountryCodeJson,
  CountryCodeWithFlag,
  CountryJson,
  CountryList,
  LoadAllCountry,
  LoadCity,
  LoadState
}
