import React, {PureComponent} from 'react'
import {
    Form,
    Input,
    Button,
    Icon, Col, Row, Tooltip, Drawer, notification
} from 'antd'
import _ from 'lodash'
import moment from 'moment'
import {hideLoader, hidePageLoad} from '../../../modules/actions'
import {connect} from 'react-redux'
import {listAllUniversities} from '../../university/actions/university'
import {listAllCountries} from '../../countries/actions/countries'
import {
    InputBox,
    UniversityOptions,
    departmentObj
} from '../../../components/_utils/appUtils'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import {updateUser, listAllUsers, getUser} from '../actions/user'
import {CountryCodeWithFlag} from '../../../components/_utils/countryUtil'


const {TextArea} = Input

@Form.create()
class EditMarketingManager extends PureComponent {

    events = {
        selectAllUni: () => {
            const {form: {setFieldsValue}} = this.props
            let {universityList} = this.state
            let universities = []
            _.each(universityList, (item) => {
                if (item && item._id) {
                    universities.push(item._id)
                }
            })
            setFieldsValue({
                universities: universities
            })
        },
        deSelectAllUni: () => {
            const {form: {setFieldsValue}} = this.props
            let universities = []
            setFieldsValue({
                universities: universities
            })
        },
    }

    constructor(props) {
        super(props)
        this.state = {
            universityList: [],
            countryList: [],
            logo: {},
            uploadKey: moment(),
            oldLogo: {},
            userData: {},
            countryCode: '',
            marketingUserList: []
        }
    }

    componentDidMount() {

        this.loadCountry()
        this.getUserData()
        this.loadUserData()
        this.loadMarketingUsers()
        let {dispatch} = this.props
        dispatch({type: 'USER_HIDELOADER'})
        dispatch(hideLoader())
        dispatch(hidePageLoad())
    }

    async loadUserData() {
        let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
        this.setState({userData: user})
    }

    handleSubmit = e => {
        const {dispatch, form, onClose, reloadTable} = this.props
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                let {
                    address,
                    logo,
                    countryCode
                } = this.state
                if (!countryCode) {
                    notification.error({
                        message: 'Please choose County Code'
                    })
                    return
                }
                valData.address = address
                valData.countryISOCode = countryCode
                let fd = new FormData()
                fd.append('obj', JSON.stringify(valData))
                if (logo && logo.name) {
                    fd.append('logo', logo)
                }

                let data = await dispatch(updateUser(fd, this.state.userId))
                if (data && !data.error) {
                    this.setState({
                        address: '',
                        uploadKey: moment(),
                        logo: {}
                    })
                    onClose()
                    if (reloadTable) {
                        reloadTable()
                    }
                }
            }
        })
    }

    async loadCountry() {
        let {dispatch} = this.props
        let countryFilter = {
            results: 10,
            sortField: 'countryName',
            sortOrder: 'ascend',
            active: [true, false]
        }
        let {data} = await dispatch(listAllCountries(countryFilter))
        this.setState({
            countryList: data
        })
    }


    async loadCountryUniversity(countryId) {
        let {dispatch} = this.props
        let {data} = await dispatch(listAllUniversities({
            results: 1000000,
            universityCountry: countryId,
            sortField: 'universityName',
            sortOrder: 'ascend'
        }))
        this.setState({
            universityList: data
        })
    }

    async getUserData() {
        let {dispatch, form: {setFieldsValue}, userData} = this.props
        if (userData && userData._id) {
            let resp = await dispatch(getUser(userData._id))
            if (resp) {
                this.setState({
                    name: resp.name,
                    userId: resp._id,
                    oldLogo: resp.logo,
                    address: resp.address,
                    email: resp.email,
                    countryCode: resp.countryISOCode ? resp.countryISOCode : '',
                })

                if (resp.countryId && resp.countryId._id) {
                    this.loadCountryUniversity(resp.countryId._id)
                }
                setFieldsValue({
                    name: resp.name,
                    mobile: resp.mobile,
                    email: resp.email,
                    address: resp.address,
                    assignedMarketingUsers: resp.assignedMarketingUsers,
                    postName: resp.postName,
                    showAllUniversity: resp.showAllUniversity,
                    countryId: resp.countryId ? resp.countryId._id : undefined,

                })
                setTimeout(() => {
                    setFieldsValue({
                        universities: resp.universities,
                    })
                }, 100)
            }
        }
    }

    loadMarketingUsers = async () => {
        let {dispatch} = this.props;
        let obj = {
            userType: 'branchUser',
            department: departmentObj.marketing,
            results: 100,
            select: ['name', 'email', 'mobile']
        }
        let {data} = await dispatch(listAllUsers(obj))
        this.setState({
            marketingUserList: data
        })
    }

    render() {
        const {visible, onClose} = this.props
        const {
            form: {getFieldDecorator, getFieldValue, setFieldsValue}
        } = this.props

        const {oldLogo, agents, userData, marketingUserList} = this.state

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 24},
                md: {span: 24}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 24},
                md: {span: 24}
            }
        }

        let inputTypes = {
            fields: [
                {
                    label: 'Contact Person Name',
                    key: 'name',
                    required: true
                },
                {
                    key: 'countryCode',
                    span: 3,
                    customField: (
                        <div style={{marginTop: 5}}>
                            <CountryCodeWithFlag countryCode={this.state.countryCode} chooseCode={(val) => {
                                this.setState({
                                    countryCode: val
                                })
                            }}/>
                        </div>
                    )
                },
                {
                    span: 5,
                    label: 'Mobile no',
                    key: 'mobile',
                    type: "number",
                    required: true
                },
                {
                    label: 'Email',
                    key: 'email',
                    required: true,
                    hidden: userData && (userData.userType === 'admin' || userData.userType === 'userManager' || userData.userType === 'branchUser') ? false : true
                },


                {
                    label: 'Post Name',
                    key: 'postName',
                },


                {
                    label: 'Country',
                    key: 'countryId',
                    required: true,
                    type: 'select',
                    showSearch: true,
                    options: this.state.countryList,
                    valueAccessor: x => x.countryName,
                    keyAccessor: x => x._id,
                    onChange: async (v) => {
                        setFieldsValue({
                            countryId: v,
                            universities: [],
                            branchMangerId: ''
                        })

                        this.loadCountryUniversity(v)
                    }
                },


                {
                    key: 'logo',
                    customField: (
                        <InputBox title={'Logo'} className={'logoCls rowFlex'}>
                            <Input type={'file'} className={'form-control'}
                                   key={this.state.uploadKey} name={'logo'} id={'logo'}
                                   onChange={(e) => {
                                       this.setState({
                                           logo: e.target.files && e.target.files.length ? e.target.files[0] : null
                                       })
                                   }}/>
                            {oldLogo && oldLogo.url ?
                                <Tooltip title={oldLogo.name}> <a className={'linkBtn'} target={'_blank'}
                                                                  href={oldLogo.url}>
                                    <Icon type={'eye'}></Icon>
                                </a> </Tooltip> : ''}
                        </InputBox>
                    )
                },
                {
                    label: 'Assign Marketing Users',
                    key: 'assignedMarketingUsers',
                    type: 'select',
                    span: 8,
                    showSearch: true,
                    mode: "multiple",
                    required: true,
                    options: marketingUserList,
                    valueAccessor: x => `${x.name}`,
                    keyAccessor: x => x._id,
                    onChange: async (v) => {
                        setFieldsValue({
                            assignedMarketingUsers: v
                        })
                    }
                },
                {
                    key: 'showAllUniversity',
                    label: 'University',
                    type: 'select',
                    required: true,
                    keyAccessor: x => x.value,
                    valueAccessor: x => `${x.name}`,
                    options: UniversityOptions,
                    onChange: x => {
                        setFieldsValue({
                            showAllUniversity: x
                        })
                    }
                },
                {
                    key: 'universities',
                    label: '',
                    label1: '* Select University',
                    type: 'select',
                    span: 24,
                    extraBox: (
                        <React.Fragment>
                            <a onClick={() => this.events.selectAllUni()}>Select All</a>{' '}
                            <a onClick={() => this.events.deSelectAllUni()}>De-select All</a>
                            {getFieldValue('universities') && getFieldValue('universities').length ?
                                <a className={'selectedUni'}>{getFieldValue('universities').length}</a>
                                : null}
                        </React.Fragment>
                    ),
                    required: true,
                    showSearch: true,
                    options: this.state.universityList,
                    hidden: !(getFieldValue('showAllUniversity') == 'Selected'),
                    mode: 'multiple',
                    keyAccessor: x => x._id,
                    valueAccessor: x => `${x.universityName}`,
                    onChange: x => {
                        setFieldsValue({
                            universities: x
                        })
                    }
                },

                {
                    key: 'address',
                    span: 24,
                    customField: (
                        <InputBox title={'Address'}>
                            <TextArea rows={4} className={'form-control'} value={this.state.address} onChange={(e) => {
                                this.setState({address: e.target.value})
                            }}>
                            </TextArea>
                        </InputBox>
                    )
                },
            ]
        }


        return (
            <Drawer
                title={`Update - ${this.state.name}`}
                visible={visible}
                placement='right'
                closable={true}
                onClose={onClose}
                width={'80%'}>
                <div className='form-box'>
                    <div className='card unizportal'>
                        <Form onSubmit={this.handleSubmit}>
                            <Row gutter={16} className={'wrapBox'}>
                                {inputTypes.fields.map((item, key) => {
                                    return (
                                        <React.Fragment key={key}>
                                            {item.customField ? !item.hidden && <Col span={item.span ? item.span : 8}>
                                                {item.customField}
                                            </Col> : !item.hidden &&
                                                <Col span={item.span ? item.span : 8} key={key}
                                                     style={item.extraBox ? {paddingBottom: 1} : {}}>
                                                    <InputBox title={item.label1 ? item.label1 : ''}
                                                              extra={item.extraBox}>
                                                        <GetEachFormFields
                                                            item={item}
                                                            getFieldDecorator={getFieldDecorator}
                                                            formItemLayout={formItemLayout}/>
                                                    </InputBox>
                                                </Col>}
                                        </React.Fragment>
                                    )
                                })}

                            </Row>

                            <Form.Item>
                                <Button type='primary' htmlType='submit' loading={this.props.loading} className={'btn'}>
                                    UPDATE
                                </Button>
                            </Form.Item>

                        </Form>
                    </div>
                </div>

            </Drawer>
        )
    }
}

const mapStateToProps = ({global, router}) => ({
    loading: global.buttonLoading,
    pathname: router.location.pathname,
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditMarketingManager)
