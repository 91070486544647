import {Spin} from 'antd'
import _ from 'lodash'
import React, {Component, Suspense} from 'react'
import {Helmet} from 'react-helmet'
import {connect} from 'react-redux'
import {Link, Redirect, Route, Switch} from 'react-router-dom'
import Exception from '../../components/Exception'
import FrontLayout from '../../frontLayout'
import '../../index.css'
import AuthLayout from '../../layouts/AuthLayout'
import BasicLayout from '../../layouts/BasicLayout'
import {hidePageLoad} from '../../modules/actions'
import menuData from '../../routes'
import {pageTitle} from '../../settings'
import {Socket, SocketComponent} from '../../socket'
import UnizHomeLayout from '../../unizHome/layout'
import AuthRouteList from './authRoute'
import FrontRoutesList from './frontRoute'
import UnizHomeRoutesList from './unizHomeRoute'
import {departmentObj} from '../../components/_utils/appUtils'
import {getUserRights} from '../login/action'

const ChatDrawer = React.lazy(() =>
    import('../../containers/chatBox/views/chatDrawer')
)
const IdleTimer = React.lazy(() => import('../../IdleTimer'))
const Exp = () => (
    <Exception
        type="404"
        desc={'You Seems lost !!'}
        linkElement={Link}
        redirect={'/dashboard'}
        backText={'Go To Homepage?'}
    />
)
const Exp403 = () => (
    <Exception
        type="403"
        desc={"Sorry You Don't have access to this area !!"}
        linkElement={Link}
        redirect={'/dashboard'}
        backText={'Go To Homepage?'}
    />
)

const FrontRoute = props => {
    let {component: Component, path} = props
    return (
        <Route
            exact
            path={path}
            render={route => {
                return (
                    <FrontLayout>
                        <Component/>
                    </FrontLayout>
                )
            }}
        />
    )
}
const AuthRoute = props => {
    let {component: Component, path} = props
    return (
        <Route
            exact
            path={path}
            render={route => {
                return (
                    <AuthLayout>
                        <Component/>
                    </AuthLayout>
                )
            }}
        />
    )
}
const UnizHomeRoute = props => {
    let {component: Component, path} = props;
    return (
        <Route
            exact
            path={path}
            render={route => {
                return (
                    <UnizHomeLayout>
                        <Component/>
                    </UnizHomeLayout>
                )
            }}
        />
    )
}

class BasicLayoutWrapper extends Component {
    render() {
        const {menuData, component, path} = this.props
        let user =
            localStorage.getItem('user') != 'undefined'
                ? JSON.parse(localStorage.getItem('user'))
                : null
        if (!user || (user && !user.userType)) {
            return window.location.pathname !== '/login' ? (
                <Redirect to="/login"/>
            ) : (
                ''
            )
        }

        let menuItem = _(menuData)
            .thru(function (coll) {
                return _.union(coll, _.map(coll, 'children'))
            })
            .flatten()
            .find({path: path})

        if (
            menuItem.authority !== undefined &&
            menuItem.authority.indexOf(user.userType) === -1
        ) {
            console.log('this user should not be here ', path)
            return <Exp403/>
        }

        if (!menuItem.title) {
            menuItem.title = 'Uniz Portal'
        }
        // console.log(menuItem)

        return (
            <React.Fragment>
                <Helmet>
                    <link href="../../dist/css/style.css" rel="stylesheet"/>
                    <link href="../../dist/css/customCss.css" rel="stylesheet"/>
                    <link
                        href="../../plugins/fontawesome-free/css/all.min.css"
                        rel="stylesheet"
                    />
                    <link
                        href="../../plugins/overlayScrollbars/css/OverlayScrollbars.min.css"
                        rel="stylesheet"
                    />
                    <link href="../../dist/css/adminlte.css" rel="stylesheet"/>
                    <link href="https://fonts.gstatic.com" rel="preload"/>
                    <link
                        href="https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;600;700&display=swap"
                        rel="preload"
                    />
                </Helmet>
                <BasicLayout
                    location={window.location}
                    title={pageTitle}
                    pageTitle={`${menuItem.title}`}
                    menuData={menuData}>
                    {!!component ? <this.props.component/> : <Exp/>}
                </BasicLayout>
            </React.Fragment>
        )
    }
}

class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            token: localStorage.getItem('token'),
            user:
                localStorage.getItem('user') != 'undefined'
                    ? JSON.parse(localStorage.getItem('user'))
                    : null
            // user: null
        }
        this.loadCurrentUserRight()
        this.setDefaultUserData()
    }

    setDefaultUserData = () => {
        let {currentUser, dispatch} = this.props;
        let token = localStorage.getItem('token');
        let user = localStorage.getItem('user') != 'undefined'
            ? JSON.parse(localStorage.getItem('user'))
            : null
        this.setState({
            token,
            user
        })
        if ((!currentUser || (currentUser && !currentUser._id)) && user && user._id) {
            dispatch({
                type: 'SET_CURRENT_USER',
                user: user
            })
        }
    }


    componentDidMount() {
        this.props.dispatch(hidePageLoad())
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.joinUserEmit()
    }

    logoutFxn() {
        // not a main logout button
        let {dispatch} = this.props
        let user = localStorage.getItem('user')
            ? JSON.parse(localStorage.getItem('user'))
            : null
        dispatch({
            type: 'LEAVE_SOCKET',
            leaveRoom: true,
            userId: user._id
        })
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        localStorage.removeItem('chatId')

        document.body.className = ''
        setTimeout(() => {
            dispatch({type: 'LOGOUT'})
        }, 500)
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps && nextProps.loadCurrentUser) {
            this.loadCurrentUserRight()
            this.props.dispatch({type: 'STOP_LOAD_CURRENT_USER'})
        }
    }

    loadCurrentUserRight = async () => {
        let user = this.props.currentUser
        if (user && user._id) {
            let {data} = await getUserRights({userId: user._id})
            this.props.dispatch({
                type: 'SET_CURRENT_USER_RIGHT',
                rights: data
            })
        }
    }

    joinUserEmit() {
        // let user = (localStorage.getItem('user') != 'undefined') ? JSON.parse(localStorage.getItem('user')) : null
        let user = this.props.currentUser
        if (user && user._id) {
            this.props.dispatch({
                type: 'JOIN_SOCKET',
                value: true,
                userId: user._id
            })
        }
    }

    render() {
        const {user} = this.state
        const {pageLoading, currentUser, currentUserRights} = this.props
        let userType = ''
        if (currentUser && currentUser.userType) {
            userType = currentUser.userType
        } else {
            userType = currentUser && currentUser.userType ? currentUser.userType : ''
        }

        const checkRightInner = (data, item) => {
            let {
                userType,
                allowAddingUser,
                showAgent,
                allowLeads,
                department,
                branchManagerType,
                countryRight,
                assignIntakesRight,
                showAllUniversities,
                showAllFcmtList,
                showAllCambieList,
                approveFCMTLoa,
                showOnShoreAustralia,
                approvedAgent,
                showApprovedCommission,
                approveOxfordDraft,
                showAllLesterList,
                showCommissionStructure,
                countryId,
                showCanadaLoa,
                showUnizHomeEnquiry,
                oxfordInterviewDepartment,
                approveCambieLoa
            } = data
            let condition = true
            if (
                userType == 'agent' &&
                item.key == 'allSubAgent' &&
                !allowAddingUser
            ) {
                condition = false
            }
            if (item.key == 'dailyLeads') {
                condition = false
            }
            if (item.key == 'assessmentDepartment') {
                if (department) {
                    if (!department == departmentObj.assDepartment) {
                        condition = false
                    }
                } else {
                    condition = false
                }
            }
            if (item.key == 'allAgents' || item.key == 'allSubAgents') {
                if (!showAgent) {
                    condition = false
                }
            }
            if (item.key == 'user') {
                if (userType !== 'admin' && userType !== 'branchManager') {
                    if (!showAgent) {
                        condition = false
                    }
                }
            }
            if (
                item.key == 'assessmentsWarnings' ||
                item.key == 'waitingToApplyWarnings'
            ) {
                // check warning department
                if (department) {
                    if (department !== departmentObj.warningDepartment) {
                        condition = false
                    }
                } else {
                    condition = false
                }
            }
            if (item.key == 'marketingAgent') {
                condition = false
                if (
                    (branchManagerType && branchManagerType == 'agentWise') ||
                    branchManagerType == 'marketingManager' ||
                    department == departmentObj.marketing
                ) {
                    condition = true
                }
            }

            if (item.key == 'sendMailToAgent' || item.key == 'sendMailToAgent') {
                condition = false
                if (
                    userType == 'branchManager' &&
                    ((branchManagerType && branchManagerType !== 'universityWise') ||
                        !branchManagerType)
                ) {
                    condition = true
                } else if (
                    userType == 'branchUser' &&
                    department == departmentObj.marketing
                ) {
                    condition = true
                } else if (userType == 'admin' || userType == 'hr') {
                    condition = true
                }
            }
            if (
                item.key == 'searchCourse' ||
                item.key == 'searchCourse' ||
                item.key == 'addStudent'
            ) {
                if (
                    userType == 'branchManager' &&
                    branchManagerType &&
                    branchManagerType == 'universityWise'
                ) {
                    condition = false
                }
            }
            if (item.key == 'searchCourse') {
                if (department && department == departmentObj.warningDepartment) {
                    condition = false
                } else {
                    condition = true
                }
            }

            if (item.key == 'allApplicationsList') {
                if (userType == 'branchManager' && branchManagerType == 'marketingManager') {
                    condition = false
                }
            }
            if (item.key == 'dailyExpenseListForMarketingUser' || item.key == 'addExpense') {
                condition = false
                if ((userType == 'branchUser' && department == departmentObj.marketing) ||
                    (userType == 'marketingManager') ||
                    (userType == 'branchManager' && !branchManagerType && branchManagerType !== null)
                ) {
                    condition = true
                }
            }
            if (item.key == 'canadaApplicationList') {
                condition = false
                if (showCanadaLoa) {
                    condition = true
                }
            }
            if (item.key == 'allCountries') {
                condition = false
                if (
                    userType == 'admin' ||
                    (userType == 'master' && countryRight == 'All')
                ) {
                    condition = true
                }
            }
            if (item.key == 'allUniversities' || item.key == 'editUniversity') {
                condition = false
                if (
                    userType == 'admin' ||
                    userType == 'userManager' ||
                    userType == 'master' ||
                    (userType == 'branchUser' && showAllUniversities) ||
                    (userType == 'branchManager' && !branchManagerType)
                ) {
                    condition = true
                }
            }

            if (item.key == 'allFcmtApplicationsList') {
                condition = false
                if (showAllFcmtList && showAllFcmtList == true) {
                    condition = true
                }
            }
            if (item.key == 'allLesterApplicationsList') {
                condition = false
                if (showAllLesterList && showAllLesterList == true) {
                    condition = true
                }
            }

            if (item.key == 'allCambieApplicationsList') {
                condition = false
                if (showAllCambieList && showAllCambieList == true) {
                    condition = true
                }
            }

            if (item.key == 'allUniversitiesForRyanAccounts') {
                condition = false
                if (userType == 'branchUser' && showAllUniversities) {
                    condition = true
                }
            }
            if (item.key == 'agentCommission') {
                condition = false
                if (
                    userType == 'admin' ||
                    (currentUser &&
                        currentUser.email &&
                        currentUser.email == 'accounts@ryanconsultants.com')
                ) {
                    condition = true
                }
            }
            if (item.key == 'approvedWithdrawals') {
                condition = false
                if (
                    currentUser &&
                    currentUser.email &&
                    currentUser.email == 'accounts@ryanconsultants.com'
                ) {
                    condition = true
                }
            }
            if ((item.key == 'allAgentCommissionList' || item.key == 'commissionWithdrawal') && department == departmentObj.accountDepartment) {
                condition = false
                if (showApprovedCommission) {
                    condition = true
                }
            }

            if (item.key == 'allAgentForMarketingUsers') {
                condition = false
                if (branchManagerType) {
                    if (branchManagerType == 'allAgentForMarketingUsers') {
                        condition = true
                    }
                } else if (
                    department == departmentObj.marketing ||
                    userType == 'marketingManager'
                ) {
                    condition = true
                }
            }

            if (
                item.key == 'commission' ||
                item.key == 'universityCommission' ||
                item.key == 'receivedCommissionList' ||
                item.key == 'agentCommissionList'
            ) {
                condition = false
                // (userType == 'branchManager' && !branchManagerType && branchManagerType !== null) ||
                if (
                    userType == 'admin' ||
                    (userType == 'branchManager' &&
                        !branchManagerType &&
                        branchManagerType !== null) ||
                    (currentUser &&
                        currentUser.email &&
                        currentUser.email == 'accounts@ryanconsultants.com')
                ) {
                    condition = true
                }
            }

            if (item.key == 'referredAgentCommission') {
                condition = false
                if (
                    userType == 'branchManager' &&
                    !branchManagerType &&
                    branchManagerType !== null
                ) {
                    condition = true
                }
            }


            if (item.key == 'commissionStructure') {
                condition = false
                if (
                    userType == 'agent' ||
                    userType == 'admin' ||
                    showCommissionStructure == true ||
                    (userType == 'branchUser' && department == departmentObj.marketing)
                ) {
                    condition = true
                    if (userType == 'branchUser') {
                        item.dontShowOnMenu = false
                    }
                }
            }
            if (item.key == 'australiaOnShoreList') {
                if (showOnShoreAustralia) {
                    item.dontShowOnMenu = false
                }
            }

            if (item.key == 'assignIntake') {
                condition = false
                if (assignIntakesRight) {
                    condition = true
                }
            }

            if (item.key == 'downloadReport') {
                // department == departmentObj.marketing && userType == 'branchUser'
                condition = false
                if (
                    userType == 'admin' ||
                    userType == 'branchManager' ||
                    userType == 'branchUser' ||
                    userType == 'marketingManager'
                ) {
                    condition = true
                }
            }


            if (item.key == 'allStudent') {
                if (department && (department == departmentObj.interviewer || department == departmentObj.warningDepartment)) {
                    condition = false
                } else {
                    if (approveFCMTLoa || approveOxfordDraft || oxfordInterviewDepartment) {
                        condition = false
                    } else {
                        condition = true
                    }
                }
            }

            if (item.key == 'oxfordPendingApplicationsList' || item.key == 'oxfordPendingInterviewList') {
                if (approveOxfordDraft && approveOxfordDraft == true) {
                    condition = true
                } else {
                    condition = false
                }
            }
            if (item.key == 'oxfordInterviewApplications') {
                if (oxfordInterviewDepartment && oxfordInterviewDepartment == true) {
                    condition = true
                } else {
                    condition = false
                }
            }
            if (item.key == 'cambiePendingApplicationsList') {
                if (approveCambieLoa && approveCambieLoa == true) {
                    condition = true
                } else {
                    condition = false
                }
            }
            /*if (item.key == 'approvedAgents') {
                            condition = false
                            if (userType == 'admin' || (userType == 'branchUser' && department !== departmentObj.counselling)) {
                                condition = true
                            }
                        }*/

            if (item.key == 'approvedAgents') {
                condition = false
                if (userType == 'admin' || userType == 'userManager' || approvedAgent) {
                    condition = true
                }
            }

            if (item.key == 'counsellingList') {
                condition = false
                if (
                    userType == 'admin' ||
                    (userType == 'branchUser' && department == departmentObj.counselling)
                ) {
                    condition = true
                }
            }

            if (item.key == 'selfReport') {
                condition = false
                if (userType == 'branchUser' && department == departmentObj.marketing) {
                    condition = true
                }
            }

            if (item.key == 'dailyAgentReport') {
                condition = false
                if (
                    (userType == 'branchManager' &&
                        branchManagerType &&
                        branchManagerType == 'agentWise') ||
                    userType == 'admin'
                ) {
                    condition = true
                }
            }

            if (item.key == 'searchCourse' || item.key == 'addStudent' || item.key == 'allStudent') {
                condition = true
                if ((userType == 'branchUser' && department == departmentObj.counselling) || oxfordInterviewDepartment || approveOxfordDraft) {
                    condition = false
                }
            }


            if (item.key == 'unizHomeEnquiry') {
                condition = false
                if (showUnizHomeEnquiry) {
                    condition = true
                }
            }


            if (item.key == 'expense' || item.key == 'dailyExpenseList' || item.key == 'expenseWithDrawList') {
                condition = false
                if (userType == 'admin' || (currentUser && currentUser.email && currentUser.email == 'accounts@ryanconsultants.com')) {
                    condition = true
                }
            }


            return condition
        }
        const checkRightsFxn = item => {
            if (currentUserRights && currentUserRights._id) {
                return checkRightInner(currentUserRights, item)
            } else {
                if (user && user._id) {
                    return checkRightInner(currentUserRights, user)
                }
            }
        }

        return (
            <div key={user}>
                {pageLoading && (
                    <div className={'outerLoader'}>
                        <Spin style={{fontSize: 100}}/>
                        Loading...
                    </div>
                )}
                <Switch>
                    {menuData.map((item, key) => {
                        if (!item.children) {
                            let routeBox = (
                                <Route
                                    exact
                                    path={item.path}
                                    key={item.path}
                                    render={route => {
                                        return (
                                            <React.Fragment>
                                                {item.authority && item.authority.length ? (
                                                    item.authority.includes(userType) ? (
                                                        <BasicLayoutWrapper
                                                            component={item.component}
                                                            path={item.path}
                                                            user={user}
                                                            menuData={menuData}
                                                        />
                                                    ) : (
                                                        <Route component={Exp}/>
                                                    )
                                                ) : (
                                                    <BasicLayoutWrapper
                                                        component={item.component}
                                                        path={item.path}
                                                        user={user}
                                                        menuData={menuData}
                                                    />
                                                )}
                                            </React.Fragment>
                                        )
                                    }}
                                />
                            )
                            return item.restrict
                                ? checkRightsFxn(item)
                                    ? routeBox
                                    : null
                                : routeBox
                        }
                    })}
                    {menuData.map((item, key) => {
                        if (item.children) {
                            return item.children.map((child, k) => {
                                let routeBox = (
                                    <Route
                                        exact
                                        path={child.path}
                                        key={child.path}
                                        render={route => {
                                            return (
                                                <React.Fragment>
                                                    {child.authority && child.authority.length ? (
                                                        child.authority.includes(userType) ? (
                                                            <BasicLayoutWrapper
                                                                component={child.component}
                                                                path={child.path}
                                                                user={user}
                                                                menuData={menuData}
                                                            />
                                                        ) : (
                                                            <Route component={Exp}/>
                                                        )
                                                    ) : (
                                                        <BasicLayoutWrapper
                                                            component={child.component}
                                                            path={child.path}
                                                            user={user}
                                                            menuData={menuData}
                                                        />
                                                    )}
                                                </React.Fragment>
                                            )
                                        }}
                                    />
                                )
                                return child.restrict
                                    ? checkRightsFxn(child)
                                        ? routeBox
                                        : null
                                    : routeBox
                            })
                        }
                    })}
                </Switch>

                {AuthRouteList.map(item => {
                    return (
                        <AuthRoute
                            path={item.path}
                            component={item.component}
                            key={item.key}
                        />
                    )
                })}

                {FrontRoutesList.map((item, key) => {
                    return (
                        <FrontRoute
                            path={item.path}
                            component={item.component}
                            key={item.key}
                        />
                    )
                })}

                {UnizHomeRoutesList.map((item, key) => {
                    return (
                        <UnizHomeRoute
                            path={item.path}
                            component={item.component}
                            key={item.key}
                        />
                    )
                })}

                <Route
                    exact
                    path="/home"
                    render={route => {
                        return <Redirect to="/"/>
                    }}
                />

                <SocketComponent/>
                {currentUser && currentUser._id ? (
                    <>
                        <Suspense fallback={<></>}>
                            <IdleTimer/>
                            <ChatDrawer/>
                        </Suspense>
                    </>
                ) : null}
            </div>
        )
    }
}

const mapStateToProps = ({global, router}) => ({
    pageLoading: global.pageLoading,
    currentUser: global.currentUser,
    loadCurrentUser: global.loadCurrentUser,
    currentUserRights: global.currentUserRights,
    soundStatus: global.soundStatus
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App)
