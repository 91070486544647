import Home from "../FrontendComponents/home";
import AboutUs from "../FrontendComponents/aboutUs";
import ContactUs from "../FrontendComponents/contactUs";
// import Webinar from "../FrontendComponents/webinar";
import Gallery from "../FrontendComponents/gallery";
import TermsCondition from "../FrontendComponents/termsCondition";
import TermsUse from "../FrontendComponents/termsUse";
import RefundPolicy from "../FrontendComponents/refundPolicy";
import PrivacyPolicy from "../FrontendComponents/privacyPolicy";
import EnquiryFeedback from "../FrontendComponents/enquiryFeedback";
import ShareYourExperience from "../FrontendComponents/shareYourExperience";
import VerifyOfferLetter from "../FrontendComponents/VerifyOfferLetter";
import VerifyLoaLetter from "../FrontendComponents/VerifyLoaLetter";
import Broadcasting from "../FrontendComponents/broadcasting";
import singleWebinar from "../FrontendComponents/singleWebinar";
import webinar from "../FrontendComponents/Webinar/webinar";
import Welcome from "../FrontendComponents/Welcome";

const FrontRoutesList = [
    {name: 'Home', path: '/', component: Home, key: 'home'},
    {name: 'AboutUs', path: '/about-us', component: AboutUs, key: 'about-us'},
    {
        name: 'ContactUs',
        path: '/contact-us',
        component: ContactUs,
        key: 'contact-us'
    },
    {name: 'Webinar', path: '/webinars', component: webinar, key: 'webinars'},
    {name: 'Welcome', path: '/welcome/:id', component: Welcome, key: 'welcome'},
    {name: 'Webinar', path: '/singleWebinar', component: singleWebinar, key: 'singleWebinar'},

    // {name: 'Gallery', path: '/gallery', component: Gallery, key: 'gallery'},

    {
        name: 'Terms Condition',
        path: '/terms-condition',
        component: TermsCondition,
        key: 'termsCondition'
    },
    {
        name: 'Terms Use',
        path: '/terms-use',
        component: TermsUse,
        key: 'termsUse'
    },
    {
        name: 'Refund Policy',
        path: '/refund-policy',
        component: RefundPolicy,
        key: 'refundPolicy'
    },

    {
        name: 'Privacy Policy',
        path: '/privacy-policy',
        component: PrivacyPolicy,
        key: 'privacyPolicy'
    },
    // { name: 'Enquiry', path: '/enquiry', component: EnquiryForm, key: 'enquiry' },
    {
        name: 'Enquiry Feedback',
        path: '/feedback/:studentId',
        component: EnquiryFeedback,
        key: 'enquiryFeedback'
    },
    {
        name: 'Share Your Experience',
        path: '/share-your-experience',
        component: ShareYourExperience,
        key: 'shareYourExperience'
    },
    {
        name: 'Verify Offer Letter',
        path: '/verifyOfferLetter',
        component: VerifyOfferLetter,
        key: 'verifyOfferLetter'
    },
    {
        name: 'Verify LOA Letter',
        path: '/verifyLoaLetter',
        component: VerifyLoaLetter,
        key: 'verifyLoaLetter'
    },
    {
        name: 'Broadcasting',
        path: '/broadcasting/:token',
        component: Broadcasting,
        key: 'broadcasting'
    },

]

export default FrontRoutesList
