import React, {Component} from 'react'
import {TableComp} from 'sz-react-utils-lite'
import {
    Tooltip,
    Button, Icon, Row, Col, Select, DatePicker
} from 'antd'
import {connect} from 'react-redux'
import {listAllUsers} from '../actions/user'
import {
    DefaultTablePagination,
    filterOption
} from '../../../components/_utils/appUtils'
import {listAllCountries} from '../../countries/actions/countries'
import EditUser from './edit'
import {ColumnWidth} from '../../WebComponent/columnWidth'
import {LoadAllCountry, LoadCity, LoadState} from '../../../components/_utils/countryUtil'
import _ from 'lodash'
import {getUrlPushWrapper} from '../../../routes'
import {displayDate} from '../../../components/_utils/appUtils'
import ApprovedAgentManagerList from '../drawer/approvedAgentManagerList'
import moment from 'moment'

const userTypeOptions = [
    {value: 'agent', text: 'Agent'},
    {value: 'subAgent', text: 'Sub Agent'}
]
const commissionType = [
    {text: 'Silver', value: 'silver'},
    {text: 'Gold', value: 'gold'},
    {text: 'Platinum', value: 'platinum'}
]

const {Option} = Select
const {RangePicker} = DatePicker
const defaultValue = [moment().startOf('day'), moment().endOf('day')]
const dateFormat = 'YYYY-MM-DD'

class ApprovedAgentsList extends Component {

    events = {
        chooseCountry: (name) => {
            let countryName = _.find(this.state.allCountries, (item) => {
                return item.name == name
            })
            if (countryName) {
                this.setState({
                    countryName: countryName.name ? countryName.name : '',
                    stateName: undefined,
                    cityName: undefined,
                    pincode: undefined,
                    allStates: countryName.id ? LoadState(countryName.id) : []
                })
            }
        },
        chooseState: (name) => {
            let stateName = _.find(this.state.allStates, (item) => {
                return item.name == name
            })
            if (stateName) {
                this.setState({
                    stateName: stateName.name ? stateName.name : '',
                    cityName: undefined,
                    pincode: undefined,
                    allCities: stateName.id ? LoadCity(stateName.id) : []
                })
            }
        },
        chooseCity: (name) => {
            this.setState({
                cityName: name ? name : ''
            })
        }
    }

    constructor(props) {
        super(props)
        this.state = {
            viewInfoDrawer: true,
            selectedRow: {},
            allAgents: [],
            allBranchManager: [],
            allCountry: [],
            selectedUser: {},
            visibleUpdatePassword: false,
            visibleUpdateUser: false,
            visibleManagersList: false,
            allCountries: LoadAllCountry(),
            allStates: [],
            allCities: [],
            managerList: [],
            time: {
                key: 'date',
                from: moment().startOf('day'),
                to: moment().endOf('day')
            }
        }
        this.tableRef = React.createRef()
    }

    componentDidMount() {
        this.loadAllAgent()
        this.loadAllBranchManager()
        this.loadAllCountry()
    }

    apiRequest = (params) => {
        let {dispatch} = this.props
        return new Promise(async (resolve) => {
            params.sortField = 'createdAt'
            params.sortOrder = 'decent'
            let {time} = this.state

            let resp1 = await this.getParamsForApplicationList()

            if (resp1.countryName) {
                params.countryName = resp1.countryName
            }
            if (resp1.stateName) {
                params.stateName = resp1.stateName
            }
            if (resp1.cityName) {
                params.cityName = resp1.cityName
            }
            if (resp1.pincode) {
                params.pincode = resp1.pincode
            }
            params.createdAt = {$gte: time.from ? time.from._d : time.from, $lt: time.to ? time.to._d : time.to}

            console.log('time', time)
            if (!params.userType || (params.userType && params.userType.length == 0)) {
                params.userType = ['agent']
            }
            resolve(dispatch(listAllUsers({
                ...params,
                regExFilters: ['name', 'address', 'mobile', 'email']
            })))
        })
    }

    async loadAllAgent() {
        let {dispatch} = this.props
        let {data} = await dispatch(listAllUsers({userType: 'agent'}))
        this.setState({allAgents: data})
    }

    async loadAllBranchManager() {
        let {dispatch} = this.props
        let {data} = await dispatch(listAllUsers({userType: 'branchManager'}))
        this.setState({allBranchManager: data})
    }

    async loadAllCountry() {
        let {dispatch} = this.props
        let {data} = await dispatch(listAllCountries())
        this.setState({allCountry: data})
    }


    viewInfo(record) {
        this.setState({
            selectedRow: record,
            viewInfoDrawer: true
        })
    }

    onClose = () => {
        this.setState({
            selectedRow: {},
            viewInfoDrawer: false
        })
    }

    closeUpdatePassword = () => {
        this.setState({
            selectedUser: {},
            visibleUpdatePassword: false
        }, () => {
            this.tableRef.current.reload()
        })
    }

    closeEditUser = () => {
        this.setState({
            selectedUser: {},
            visibleUpdateUser: false
        }, () => {
            this.tableRef.current.reload()
        })
    }

    getParamsForApplicationList = () => {
        return new Promise((resolve) => {
            let searchParams = new URLSearchParams(window.location.search)
            let countryName = searchParams.get('countryName')
            let stateName = searchParams.get('stateName')
            let cityName = searchParams.get('cityName')
            let pincode = searchParams.get('pincode')

            let obj = {}
            if (countryName) {
                obj.countryName = countryName
            }
            if (stateName) {
                obj.stateName = stateName
            }
            if (cityName) {
                obj.cityName = cityName
            }
            if (pincode) {
                obj.pincode = pincode
            }
            resolve(obj)
        })
    }

    openManagerList = (data) => {
        this.setState({
            selectedUser: data,
            visibleManagersList: true
        })
    }

    closeManagerList = () => {
        this.setState({
            selectedUser: {},
            visibleManagersList: false
        }, () => {
            this.tableRef.current.reload()
        })
    }

    searchFxn = async () => {
        let {dispatch} = this.props
        // let resp = await this.getParamsForApplicationList()
        let obj = {}
        if (this.state.countryName) {
            obj.countryName = this.state.countryName
        }
        if (this.state.stateName) {
            obj.stateName = this.state.stateName
        }
        if (this.state.cityName) {
            obj.cityName = this.state.cityName
        }
        if (this.state.pincode) {
            obj.pincode = this.state.pincode
        }
        dispatch(
            getUrlPushWrapper('approvedAgents', {
                ...obj
            }))
        // setTableSearch(true)
        setTimeout(() => {
            if (this.tableRef && this.tableRef.current) {
                this.tableRef.current.reload()
            }
        }, 200)
    }

    clearFxn = async () => {
        let {dispatch} = this.props
        // let resp = await getParamsForApplicationList()
        let obj = {}
        this.setState({
            countryName: '',
            stateName: '',
            cityName: '',
            pincode: ''
        })
        dispatch(
            getUrlPushWrapper('approvedAgents', {
                ...obj
            }))
        setTimeout(() => {
            this.tableRef.current.reload()
        }, 200)
    }

    render() {
        let {
            selectedUser,
            visibleUpdateUser,
            visibleManagersList,
            countryName
        } = this.state
        const columns = [
            {
                title: '#',
                key: '_id',
                dataIndex: '_id',
                width: 50,
                render: (ite, record, index) => {
                    return (
                        <React.Fragment>
                            {index + 1}
                        </React.Fragment>
                    )
                }
            },
            {
                title: 'Created At',
                key: 'createdAt',
                // searchDateName: 'createdAt',
                width: 100,
                render: (item, record) => {
                    return (
                        <ColumnWidth width={100}>
                            {displayDate(item)}
                        </ColumnWidth>
                    )
                }
            },
            {
                title: 'Name',
                key: 'name',
                sorter: true,
                dataIndex: 'name',
                searchTextName: 'name',
                width: 110,
                render: (item, record) => {
                    return (
                        <ColumnWidth width={110}>
                            {item}
                        </ColumnWidth>
                    )
                }
            },
            {
                title: 'Email',
                dataIndex: 'email',
                key: 'email',
                searchTextName: 'email',
                width: 120,
                render: (item) => {
                    return (
                        <ColumnWidth width={120}>{item}</ColumnWidth>
                    )
                }
            },
            {
                title: 'Mobile No',
                dataIndex: 'mobile',
                key: 'mobile',
                searchTextName: 'mobile',
                width: 110,
                render: (item) => {
                    return (
                        <ColumnWidth width={80}>{item}</ColumnWidth>
                    )
                }
            },
            {
                title: 'Created By',
                key: 'createdByUser',
                searchTextName: 'createdByUser',
                width: 120,
                render: (item, record) => {
                    return (
                        <ColumnWidth width={80}>{item && item.name ? item.name : null}</ColumnWidth>
                    )
                }
            },
            {
                title: 'Address',
                dataIndex: 'address',
                key: 'address',
                searchTextName: 'address',
                width: 150,
                render: (item) => {
                    return (
                        <ColumnWidth width={150}>{item}</ColumnWidth>
                    )
                }
            },
            {
                title: 'State',
                dataIndex: 'stateName',
                key: 'stateName',
                width: 100,
                render: (item) => {
                    return (
                        <ColumnWidth width={100}>{item}</ColumnWidth>
                    )
                }
            },
            {
                title: 'City',
                dataIndex: 'cityName',
                key: 'cityName',
                width: 100,
                render: (item) => {
                    return (
                        <ColumnWidth width={100}>{item}</ColumnWidth>
                    )
                }

            },
            {
                title: 'Country',
                key: 'countryName',
                // filters: allCountry.map(x => ({ value: x._id, text: x.countryName })),
                width: 100,
                render: (item) => {
                    return (
                        <ColumnWidth width={100}>{item}</ColumnWidth>
                    )
                }
            },
            {
                title: 'Commission',
                dataIndex: 'commissionType',
                key: 'commissionType',
                filters: commissionType,
                width: 110,
                render: (item) => {
                    return (
                        <ColumnWidth width={110}>{item}</ColumnWidth>
                    )
                }
            },

            // {
            //   title: 'Logo',
            //   dataIndex: 'logo',
            //   key: 'logo',
            //   width: 100,
            //   render: (val) => {
            //     return (
            //       <ColumnWidth width={100}>  {val && val.url ? <img src={val.url} className={'imgSm'} /> : null}</ColumnWidth>
            //     )
            //   }
            // },
            // {
            //   title: 'Block',
            //   dataIndex: 'block',
            //   width: 80,
            //   key: 'block',
            //   filters: ConditionOptions.map(x => ({ value: x.value, text: x.name })),
            //   render: (item, record) => {
            //     return (
            //       <React.Fragment>
            //         {record && record.userType && (record.userType !== 'admin') ?
            //           <div>
            //             <Popconfirm title={`Are your sure, you want to ${item ? 'Un-Block' : 'Block'} this User?`}
            //                         onConfirm={() => {
            //                           this.updateAgentBlockStatus(record._id)
            //                         }}
            //                         okText='Yes' cancelText='No'>
            //               {item ? <div className={'label label-success labelNew'}>Yes</div> :
            //                 <div className={'label label-warning labelNew'}>No</div>}
            //             </Popconfirm>
            //           </div> : null}
            //       </React.Fragment>
            //     )
            //   }
            // },
            {
                title: 'Action',
                key: '_id',
                dataIndex: '_id',
                width: 120,
                render: (val, record) => {
                    return (
                        <React.Fragment>
                            <Tooltip title='View Managers'>
                                <button
                                    className={'btn'}
                                    style={{marginRight: 6}}
                                    onClick={() => {
                                        this.openManagerList(record)
                                        // this.setState({
                                        //   visibleManagersList: true,
                                        //   selectedUser: record
                                        // })
                                    }}>
                                    <Icon type={'eye'}/>
                                </button>
                            </Tooltip>
                            <Tooltip title='Edit'>
                                <button
                                    className={'btn'}
                                    style={{marginRight: 6}}
                                    onClick={() => {
                                        this.setState({
                                            visibleUpdateUser: true,
                                            selectedUser: record
                                        })
                                    }}>
                                    <Icon type={'edit'}/>
                                </button>
                            </Tooltip>
                        </React.Fragment>
                    )
                }
            }
        ]

        const filterBlock = (
            <Row gutter={16}>
                {/*<AllowComponentRightsWise rightUserType={['admin']}>*/}
                <Col md={6} sm={6} xs={10} lg={6}>
                    <div className='sort-box-table custom-sort-box-new'>
                        <RangePicker
                            // showTime={{ format: 'HH:mm' }}
                            format={dateFormat}
                            defaultValue={defaultValue}
                            // placeholder={['Start Time', 'End Time']}
                            onChange={(val) => {
                                this.setState({
                                    time: {
                                        key: 'date',
                                        from: val[0],
                                        to: val[1]
                                    }
                                })
                            }}
                            onOk={this.onOk}
                        />
                    </div>
                </Col>
                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className='sort-box-table custom-sort-box-new'>
                        <Select name='Search By Country'
                                onChange={(item) => this.events.chooseCountry(item)}
                                filterOption={filterOption}
                                className={'antSelect'}
                                placeholder='Country'
                                allowClear={true}
                                showSearch={true}
                                value={this.state.countryName || undefined}>
                            {this.state.allCountries && this.state.allCountries.length ? this.state.allCountries.map((item, key) => {
                                return (
                                    <Option value={item.name} key={key}>{item.name}</Option>
                                )
                            }) : null}
                        </Select>
                    </div>
                </Col>
                {/*</AllowComponentRightsWise>*/}
                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className='sort-box-table custom-sort-box-new'>
                        <Select name='Search By State'
                                onChange={(item) => this.events.chooseState(item)}
                                filterOption={filterOption}
                                className={'antSelect'}
                                placeholder='State'
                                allowClear={true}
                                showSearch={true}
                                value={this.state.stateName || undefined}>
                            {this.state.allStates && this.state.allStates.length ? this.state.allStates.map((item, key) => {
                                return (
                                    <Option value={item.name} key={key}>{item.name}</Option>
                                )
                            }) : null}
                        </Select>
                    </div>
                </Col>
                <Col md={4} sm={4} xs={8} lg={4}>


                    {!countryName || countryName == 'India' ?
                        <div className='sort-box-table custom-sort-box-new'>
                            <Select name='Search By City'
                                    onChange={(item) => this.events.chooseCity(item)}
                                    filterOption={filterOption}
                                    className={'antSelect'}
                                    placeholder='City'
                                    allowClear={true}
                                    showSearch={true}
                                    value={this.state.cityName || undefined}>
                                {this.state.allCities && this.state.allCities.length ? this.state.allCities.map((item, key) => {
                                    return (
                                        <Option value={item.name} key={key}>{item.name}</Option>
                                    )
                                }) : null}
                            </Select>
                        </div> :
                        <div className='search-box-table round ml0'>
                            <input name='Search By City' className='form-control'
                                   onChange={(e) => {
                                       this.setState({cityName: e.target.value})
                                   }}
                                   placeholder='City'
                                   value={this.state.cityName}/>
                            <img src='/dist/img/search.png' alt=''/>

                        </div>}


                </Col>


                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className='search-box-table round ml0'>
                        <input className='form-control form-control' type='search'
                               placeholder='Pincode' value={this.state.pincode} onChange={(e) => {
                            this.setState({pincode: e.target.value})
                        }}/>
                        <img src='/dist/img/search.png' alt=''/>
                    </div>
                </Col>


                <Col md={8} sm={8} xs={12} lg={6}>
                    <Button onClick={() => this.searchFxn()} className={'roundBtn'}>Search</Button>
                    <Button onClick={() => this.clearFxn()} className={'roundBtn'}>
                        Clear
                    </Button>
                </Col>


            </Row>
        )

        return (
            <div>

                <div className='row  mt-4'>
                    <div className='col-lg-12'>
                        <div className='card'>
                            <div className='table-head d-flex align-items-center'>
                                <h5>All Agents</h5>
                                <div className='search-box-table'>
                                </div>
                            </div>
                            <div className='card-body table-responsive'>
                                {filterBlock}
                                <TableComp columns={columns}
                                           ref={this.tableRef}
                                           pagination={DefaultTablePagination()}
                                           apiRequest={this.apiRequest}
                                           extraProps={{scroll: {x: 1000}}}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {visibleUpdateUser ? <EditUser
                    visible={visibleUpdateUser}
                    reloadTable={() => {
                        setTimeout(() => {
                            this.tableRef.current.reload()
                            this.setState({keyMoment: moment()})
                        }, 2000)
                    }}
                    onClose={() => this.closeEditUser()}
                    userData={selectedUser}/> : ''}

                {visibleManagersList ? <ApprovedAgentManagerList
                    visible={visibleManagersList}
                    onClose={() => this.closeManagerList()}
                    agentId={selectedUser._id}/> : ''}
            </div>)

    }
}


const mapStateToProps = ({global}) => ({
    categories: global.categories,
    activeUser: global.activeUser
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ApprovedAgentsList)
