import React, { Component } from 'react'
import { TableComp } from 'sz-react-utils-lite'
import {
    Tooltip, Icon
} from 'antd'
import { connect } from 'react-redux'
import { listAllUsers } from '../actions/user'
import EditSubAgent from './editSubAgent'
import { CheckMyRights } from '../../WebComponent/CheckUserRights'

class AllUsers extends Component {

  constructor(props) {
    super(props)
    this.state = {
      selectedUser: {},
      visibleUpdateSubAgent: false
    }
    this.tableRef = React.createRef()
  }

  componentDidMount() {
  }

  apiRequest = (params) => {
    let { dispatch } = this.props
    return new Promise(async (resolve) => {
      params.userType = 'subAgent'
      params.sortField = 'name'
      params.sortOrder = 'ascend'
      resolve(dispatch(listAllUsers({
        ...params,
        regExFilters: ['name', 'address', 'mobile', 'email']
      })))
    })
  }

  closeEditSubAgent = () => {
    this.setState({
      selectedUser: {},
      visibleUpdateSubAgent: false
    }, () => {
      this.tableRef.current.reload()
    })
  }

  render() {
    let { selectedUser, visibleUpdateSubAgent } = this.state
    const columns = [
      {
        title: 'Name',
        key: 'name',
        sorter: true,
        dataIndex: 'name',
        searchTextName: 'name'
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        searchTextName: 'email'
      },
      {
        title: 'Mobile No',
        dataIndex: 'mobile',
        key: 'mobile',
        searchTextName: 'mobile'
      },
      {
        title: 'Address',
        dataIndex: 'address',
        key: 'address',
        searchTextName: 'address'
      },
      {
        title: 'City/State/Country',
        dataIndex: 'cityName',
        key: 'cityName',
        render: (item, record) => {
          return (
            <React.Fragment>
              {item ? item : ''}
              {record && record.stateName ? <span>, {record.stateName}</span> : ''}
              {record && record.countryName ? <span>, {record.countryName}</span> : ''}
            </React.Fragment>
          )
        }
      },
      {
        title: 'Logo',
        dataIndex: 'logo',
        key: 'logo',
        render: (val) => {
          return (
            <React.Fragment>
              {val && val.url ? <img src={val.url} className={'imgSm'}/> : null}
            </React.Fragment>
          )
        }
      },
      {
        title: 'Action',
        key: '_id',
        dataIndex: '_id',
        render: (val, record) => {
          return (
            <React.Fragment>
              <Tooltip title="Edit">
                <button
                  className={'btn'}
                  onClick={() => {
                    this.setState({
                      visibleUpdateSubAgent: true,
                      selectedUser: record
                    })
                  }}>
                  <Icon type={'edit'}/>
                </button>
              </Tooltip>
            </React.Fragment>
          )
        }
      }
    ]
    return (
      <div>

        <div className="row mt-4">
          <div className="col-lg-12">
            <div className="card">
              <div className="table-head d-flex align-items-center">
                <h5>All Users</h5>
                <div className="search-box-table">
                  {/*<input className="form-control form-control" type="search" placeholder="Search User"/>
                  <img src="/dist/img/search.png" alt=""/>*/}
                </div>
                <CheckMyRights rightList={['agent']}>
                  <div className="sort-box-table mark-btn">
                    <a className="btn" href={'/subAgent/add'}>
                      <img src={'../dist/icons/plus.png'} className={'plus'}/>
                      Add User</a>
                  </div>
                </CheckMyRights>
              </div>
              <div className="card-body table-responsive">
                <TableComp columns={columns}
                           ref={this.tableRef}
                           apiRequest={this.apiRequest}/>
              </div>
            </div>
          </div>
        </div>


        {visibleUpdateSubAgent ? <EditSubAgent
          visible={visibleUpdateSubAgent}
          onClose={() => this.closeEditSubAgent()}
          userData={selectedUser}/> : ''}
      </div>)

  }
}


const mapStateToProps = ({ global }) => ({
  categories: global.categories
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllUsers)
