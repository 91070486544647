import React, { useEffect, useRef, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { checkBranchUserExists, getStudent, getStudentDetails, uploadProfileImage } from '../../student/actions/student'
import moment from 'moment'
import _ from 'lodash'
import {
  Avatar,
  Button,
  Card,
  Col,
  Form,
  Icon,
  Input,
  notification,
  Row,
  Select,
  Table,
  Tag,
  Tooltip,
  Popconfirm
} from 'antd'
import {
  DefaultTablePagination,
  displayDate,
  InputBox,
  nameAvatar,
  statusColors,
  ProgramLevel,
  fcmtUniversity,
  DocumentTypes, DocumentTypesForRegister, DocumentTypesRequiredForCanada, DocumentRequiredForOtherCountry, countryIds
} from '../../../components/_utils/appUtils'
import {
  addCourseInStudent,
  studentSelectedApplications,
  updatePayment
} from '../../applications/actions/chooseStudent'
import { getPendingPendency } from '../../../components/_utils/utils'
import { AllowComponentRightsWise } from '../../WebComponent/allowComponentRightsWise'
import { getUrlPushWrapper } from '../../../routes'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import defaultUserImage from '../../../assets/profile-user.png'
import { TableComp } from 'sz-react-utils-lite'
import ApplicationStatusComponent from '../../applications/views/singleApplication'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import { CountryCodeWithFlag } from '../../../components/_utils/countryUtil'
import {
  registerStudentUniversityCourse,
  registerStudentIntake,
  registerStudentUniversity,
  uploadDocumentByStudent,
  StudentWithoutAuthSendOtp, registerNewStudent
} from '../../login/action'
import { listCourseCampus } from '../../campus/actions/campus'
import localStorage from 'redux-persist/es/storage'
import { hidePageLoad } from '../../../modules/actions'
import { goBack, push } from 'connected-react-router'
import { updateStudent } from '../../student/actions/student'

let initialState = {
  countryId: '',
  universityId: '',
  courseId: null,
  name: '',
  email: '',
  referralCode: '',
  dateOfBirth: '',
  branchManagerId: '',
  mobile: '',
  profile: {},
  documentsList: [],
  applications: [],
  eductionLevel: '',
  campusId: '',
  countryISOCode: '',
  intake: {},
  loading: false
}

const StudentAddNewApplication = (props) => {
  const dispatch = useDispatch()
  let [state, setState] = useState(initialState)
  let [student, setStudent] = useState({})
  let [applications, setApplications] = useState([])
  let [firstPaymentPending, setFirstPaymentPending] = useState(false)
  let [applicationData, setApplicationData] = useState({})
  let [intakesList, setIntakesList] = useState([])
  let [courseList, setCourseList] = useState([])
  let [intakeId, setIntakeId] = useState('')
  let [selectedIntake, setSelectedIntake] = useState([])
  let [newIntakes, setNewIntakes] = useState([])
  let [universityList, setUniversityList] = useState('')
  let [campusList, setCampusList] = useState([])


  const tableRef = useRef()
  useEffect(() => {
    let user = props.currentUser
    loadstudent(user)
    loadStudentApplication(user)

    _.each(DocumentTypesForRegister, (item) => {
      initialState[item.name] = []
    })
  }, [])


  const { getFieldDecorator, getFieldValue, setFieldsValue } = props.form
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }

  const setLocalState = (data) => {
    setState({
      ...state,
      ...data
    })
  }

  const loadstudent = async (user) => {
    if (user && user.studentId) {
      // let data = await dispatch(getStudent(user.studentId))
      let data = await dispatch(getStudentDetails({ studentId: user.studentId }))

      setTimeout(() => {
        let obj = {}
        _.each(data, (item, key) => {
          obj[key] = item
        })
        if (obj.dateOfBirth) {
          obj.dateOfBirth = moment(obj.dateOfBirth)
        }
        setStudent({ ...obj })
      }, 1000)
      setLocalState({
        countryId: data.registerCountry,
        countryISOCode: data.countryISOCode
      })
      if (data && data.countryId) {
        getIntakeByCountry(data.registerCountry, data.countryCode)
      }
      if (data && data.applications) {
        let { applications } = data
        if (applications && applications.length) {
          let { paymentPending } = applications[0]
          // setApplicationData(applications[0])
          setFirstPaymentPending(paymentPending)
        }
      }
    }
  }


  let events = {
    removeApplication: () => {

    }
  }

  const loadStudentApplication = async (user) => {
    let { studentId } = user
    let { data } = await dispatch(studentSelectedApplications({ studentId }))
    setApplications(data)
  }


  /* const apiRequest = (params) => {
     let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
     params['studentId'] = user.studentId
     return new Promise(async (resolve) => {
       resolve(dispatch(studentSelectedApplications({ ...params })))
     })
   }
 */


  // const getStudentData = async () => {
  //
  //   let user = await AsyncStorage.getUserData();
  //   let {studentId} = user
  //
  //   let studentData = await dispatch(studentDetails({studentId}));
  //   setLocalState({
  //     countryId: studentData.registerCountry,
  //     countryISOCode: studentData.countryISOCode,
  //   })
  //   if (studentData && studentData.countryId) {
  //     getIntakeByCountry(studentData.registerCountry, studentData.countryCode)
  //   }
  // }

  const loadCampus = async (item) => {
    const { getFieldValue, setFieldsValue } = props.form
    let obj = {
      results: 1000000,
      courseId: item
    }
    let { data } = await dispatch(listCourseCampus(obj))
    setLocalState({
      campusList: data
    })
    setCampusList(data)
    if (getFieldValue('universityId') == fcmtUniversity && data && data.length) {
      setFieldsValue({
        campusId: data[0]._id
      })
    }
  }


  const columns = [
    {
      title: 'App #',
      key: 'applications.id',
      dataIndex: 'applications.id'
    },

    {
      title: 'Status',
      key: 'applications.status',
      dataIndex: 'applications.status',
      render: (item, record) => {
        return (
          <React.Fragment>
            <label className={'label'} style={{ background: statusColors(item) }}>{item}</label>
          </React.Fragment>
        )
      }
    },
    {
      title: 'University',
      key: 'applications.courseUniversity',
      dataIndex: 'applications.courseUniversity',
      render: (item, record) => {
        return (
          <React.Fragment>
            {item && item.logo && item.logo.url ?
              <Avatar src={item.logo.url} />
              : null}
            {item.universityName ? `  ${item.universityName}` : ''}
          </React.Fragment>
        )
      }
    },
    {
      title: 'Program',
      key: 'applications.courseName',
      dataIndex: 'applications.courseName'
    },
    {
      title: 'Intake',
      key: 'applications.intake',
      dataIndex: 'applications.intake',
      render: (item, record) => {
        return (
          <React.Fragment>
            {item && item.month && item.year ? `${item.month}, ${item.year}` : ''}
          </React.Fragment>
        )
      }
    },


    {
      title: 'Pendency',
      key: 'applications.pendencyArr',
      dataIndex: 'applications.pendencyArr',
      render: (item, record) => {
        return (
          <React.Fragment>
            <Avatar style={{ backgroundColor: '#1890ff', verticalAlign: 'middle' }}>
              {item && item.length ? getPendingPendency(item) : 0}
            </Avatar>
          </React.Fragment>
        )
      }
    },
    {
      title: 'Date',
      key: 'applications.addedOn',
      dataIndex: 'applications.addedOn',
      render: (val) => {
        return (
          <div>{displayDate(val)}</div>
        )
      }
    },
    {
      title: 'Action',
      key: '_id',
      dataIndex: '_id',
      render: (val, record) => {
        return (
          <React.Fragment>
            <Tooltip title={'View Application'}>
              <Button icon={'eye'} type={'circle'}
                      onClick={() => {
                        dispatch(
                          getUrlPushWrapper('application.singleApplication', {
                            studentId: record._id,
                            appId: record.applications._id
                          }))
                      }} />
            </Tooltip>
          </React.Fragment>
        )
      }
    }
  ]

  const inputFields = [
    {
      key: 'eductionLevel',
      label: 'Education Level',
      placeholder: 'Select Level',
      type: 'select',
      options: ProgramLevel,
      keyAccessor: x => x,
      valueAccessor: x => x,
      onChange: x => {
        setFieldsValue({
          eductionLevel: x
        })
      },
      span: 6
    },
    {
      key: 'intakeTemp',
      label: 'Intake',
      type: 'select',
      required: true,
      placeholder: 'Select Intake',
      options: newIntakes.length ? newIntakes : [],
      keyAccessor: x => x.value,
      valueAccessor: x => x.label,
      onChange: x => {
        setFieldsValue({
          intakeTemp: x
        }, () => {
          if (x) {
            // let intakeVal = x.split('-')
            // let intake = {
            //   month: intakeVal[0],
            //   year: intakeVal[1]
            // }
            // setLocalState({ intake:{} })
            setFieldsValue({
              universityId: '',
              courseId: '',
              campusId: undefined
            }, () => {
              getUniversityByIntake(x)
              setIntakeId(x)
            })

          } else {
            setLocalState({ intake: {} })
          }
        })
      },
      span: 6
    },
    {
      key: 'universityId',
      label: 'University',
      placeholder: 'Select University',
      required: true,
      showSearch: true,
      type: 'select',
      options: universityList.length ? universityList : [],
      keyAccessor: x => x._id,
      valueAccessor: x => {
        return (
          <>
            <span style={{ fontSize: 12 }}>{x.universityName}</span>
            {x.starUniversity && student.applyForStarUniversity == true ? <>
              <img src={'../dist/img/freeIcon.png'}
                   style={{ height: 15, marginRight: 5, position: 'absolute', right: '3%', top: '29%' }} />
            </> : ''}
          </>
        )
      },
      onChange: x => {
        setFieldsValue({
          universityId: x,
          courseId: '',
          campusId: undefined
        }, () => {
          getCourseByUniversity(x)
          setLocalState({ intake: {}, universityId: x })
        })
      },
      span: 6
    },
    {
      key: 'courseId',
      label: 'Course',
      placeholder: 'Select Course',
      required: true,
      showSearch: true,
      type: 'select',
      options: courseList.length ? courseList : [],
      keyAccessor: x => x._id,
      valueAccessor: x => x.courseName,
      onChange: x => {
        setFieldsValue({
          courseId: x,
          campusId: undefined
        }, () => {
          setLocalState({ intake: {} })
          getUniversityData(state.universityId, x)
          loadCampus(x)
        })
      },
      span: 6
    },
    {
      key: 'campusId',
      label: 'Campus',
      placeholder: 'Select Campus',
      required: getFieldValue('universityId') == fcmtUniversity,
      type: 'select',
      options: campusList.length ? campusList : [],
      keyAccessor: x => x._id,
      valueAccessor: x => x.campusName,
      onChange: x => {
        setFieldsValue({
          campusId: x
        })
      },
      span: 6
    }
  ]

  let getIntakeByCountry = async (id, countryCode) => {
    if (id) {
      let data = await dispatch(registerStudentIntake({
        results: 1000000,
        countryId: id,
        countryCode: countryCode ? countryCode : 'IN_+91'
      }))
      setIntakesList(data)
      let temp = []
      data.map((val) => {
        temp.push({ label: `${val.month},${val.year}`, value: val._id })
      })
      setNewIntakes(temp)
      // setCourseList(temp)
    }
  }

  let getUniversityByIntake = async (id) => {
    let intake = _.find(intakesList, (item) => {
      return item._id == id
    })
    setSelectedIntake(intake)
    let data = await dispatch(registerStudentUniversity({
      results: 1000000,
      intake: intake,
      countryId: state.countryId,
      countryCode: state.countryISOCode ? state.countryISOCode : 'IN_+91'
    }))
    data = _.sortBy(data, (item) => {
      return item.disable
    })
    setUniversityList(data)
  }

  let getCourseByUniversity = async (universityId) => {
    setCourseList([])
    let intake = _.find(intakesList, (item) => {
      return item._id == intakeId
    })
    if (universityId) {
      // let {data} = await getCourse({
      //     results: 1000000, courseUniversity: universityId, sortField: 'courseName',
      //     sortOrder: 'ascend'
      // });

      let data = await dispatch(registerStudentUniversityCourse({
        results: 1000000,
        universityId: universityId,
        intake: intake,
        countryId: state.countryId,
        countryCode: state.countryISOCode ? state.countryISOCode : 'IN_+91',
        sortField: 'courseName',
        sortOrder: 'ascend'
      }))

      data = _.sortBy(data, (item) => {
        return item.disable
      })
      setCourseList(data)
    }
  }

  const checkApplication = async (data) => {
    return new Promise(async (resolve, reject) => {
      let valData = {
        courseId: data.courseId,
        countryId: state.countryId,
        universityId: data.universityId,
        intake: {
          month: selectedIntake.month,
          year: parseInt(selectedIntake.year)
        }
      }

      valData.courseUniversity = valData.universityId
      valData.universityCountry = state.countryId

      let course = _.find(courseList, (item) => {
        return item._id === valData.courseId
      })

      if (course && course.courseName) {
        valData.tuitionFee = course.tuitionFee
        valData.courseName = course.courseName
      }

      let university = _.find(universityList, (item) => {
        return item._id === valData.universityId
      })

      if (university && university.universityName) {
        valData.applicationFee = university.applicationFee
        // valData.universityState = university.universityState && university.universityState._id ? university.universityState._id : ''
        // valData.universityCity = university.universityCity && university.universityCity._id ? university.universityCity._id : ''
      }

      let { success, message } = await dispatch(checkBranchUserExists(valData))
      if (success) {
        resolve({ type: true, application: valData })
      } else {
        notification.warning({ message: message })
        resolve({ type: false })
      }
    })

  }

  const submit = async (e) => {
    const { dispatch, form } = props
    e.preventDefault()
    props.form.validateFields(async (err, values) => {
      if (!err) {
        checkApplication(values).then(async (appResp) => {
          if (appResp && appResp.type) {
            let application = appResp.application
            try {
              application.paymentPending = false
              application.studentId = student._id
              let resp = await dispatch(addCourseInStudent({ ...application, showNotification: true }))
              let { success, message } = resp
              if (success) {
                // window.location.reload()
                dispatch(goBack())
              }
            } catch (e) {
              if (e) {
                notification.error({ message: JSON.stringify(e) })
              }
              dispatch(hidePageLoad())
            }
          } else {
            dispatch(hidePageLoad())
          }
        })
      }
    })
  }

  const documentSubmit = (e) => {
    const { dispatch, form } = props
    e.preventDefault()
    let validate = true
    if (state.countryISOCode && state.countryISOCode == 'CA_+1') {
      _.each(DocumentTypesRequiredForCanada, (item) => {
        if (state[item] && !state[item].name) {
          notification.error({ message: `Upload ${item} Document` })
          validate = false
          return false
        }
      })
    } else {
      _.each(DocumentRequiredForOtherCountry, (item) => {
        if (state[item] && !state[item].name) {
          notification.error({ message: `Upload ${item} Document` })
          validate = false
          return false
        }
      })
    }
    props.form.validateFields(async (err, values) => {
      if (!err && validate == true) {
        let fd = new FormData()
        let newObj = {
          _id: student._id
        }
        fd.append('obj', JSON.stringify(newObj))
        _.each(DocumentTypesForRegister, (item) => {
          if (state[item.name]) {
            fd.append([item.name], state[item.name])
          }
        })
        try {
          let resp = await dispatch(uploadDocumentByStudent(fd))
          let { success, data, message, token } = resp
          if (success) {
            if (state.countryISOCode != 'CA_+1') {
              await paymentSubmit()
              window.location.reload()
            } else {
              window.location.reload()
            }
          }
        } catch (e) {
          if (e) {
            notification.error({ message: JSON.stringify(e) })
          }
          dispatch(hidePageLoad())
        }
      }
    })
  }

  const paymentSubmit = (e) => {
    const { dispatch, form } = props
    e.preventDefault()
    let validate = true
    props.form.validateFields(async (err, values) => {
      if (!err && validate == true) {
        let fd = new FormData()
        let valData = {
          email: student.email,
          studentId: student._id,
          applicationId: applicationData._id
        }
        try {
          // let resp = await dispatch(uploadDocumentByStudent(fd))
          let resp = await dispatch(updatePayment(valData))
          let { success } = resp
          if (success) {
            window.location.reload()
          }
        } catch (e) {
          if (e) {
            notification.error({ message: JSON.stringify(e) })
          }
          dispatch(hidePageLoad())
        }
      }
    })
  }

  const chooseDocument = (e) => {
    let { name, files } = e.target
    if (files && files.length) {
      setLocalState({
        [name]: files[0]
      })
      return true
    }
  }

  const getUniversityData = (uniId, courseId) => {
    let uni = _.find(universityList, (item) => {
      return item.value === uniId
    })
    let courseData = _.find(courseList, (item) => {
      return item.value === courseId
    })
    let data = {
      courseUniversity: {
        applicationFee: uni.applicationFee && uni.applicationFee != null ? uni.applicationFee : '0',
        starUniversity: uni.starUniversity
      },
      courName: courseData.label,
      paymentPending: true
    }
    setApplicationData(data)
  }

  useEffect(() => {
    UpdateProfilePhoto()
  }, [state.profile])

  const UpdateProfilePhoto = async (e) => {
    let { profile } = state
    if (profile && profile.name) {
      let fd = new FormData()
      let obj = {
        studentId: student._id
      }
      fd.append('obj', JSON.stringify(obj))

      if (state.profile && state.profile.name) {
        fd.append('profile', state.profile)
      }
      try {
        let resp = await dispatch(uploadProfileImage(fd))
        let { success, profile } = resp
        if (success) {
          setStudent({ ...student, profile })
        }
      } catch (e) {
        if (e) {
          notification.error({ message: JSON.stringify(e) })
        }
        dispatch(hidePageLoad())
      }
    }
  }


  return (
    <div>
      <div className='student-application-detail student-application-new addCourseForm'>
        <div className='row' style={{ marginTop: 10 }}>
          <div className='col-lg-6 col-6'>
            <div className='card unizportal regStu'>
              <div>
                <h5>Select Course</h5>
                <Form onSubmit={submit}>
                  <div className={'row'}>
                    {inputFields.map((item, key) => {
                      return (
                        <React.Fragment key={key}>
                          {item.customField ? !item.hidden && <Col className={`form-group col-lg-${item.span}`}>
                            {item.customField}
                          </Col> :
                            !item.hidden && <div className={`form-group col-lg-${item.span}`} key={key}>
                              <GetEachFormFields
                                item={item}
                                getFieldDecorator={getFieldDecorator}
                                formItemLayout={formItemLayout} />
                            </div>
                          }
                        </React.Fragment>
                      )
                    })}
                  </div>
                  <div className={'row documents'}>

                    <div className={'col-lg-12'}>
                      <div className='align-items-center alignRight'>
                        <Button className='btn ml-auto btnSub' type='button' loading={state.loading}
                                htmlType='submit'>Submit</Button>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
          <div className='col-lg-6 col-6 form-box'>
            <div className='card unizportal regStu'>
              <div className='inner-form'>
                <div>
                  <h5>Checkout</h5>
                  <Form>
                    <div className={'row'}>
                      <div className={'col-12'}>
                        <div className={'manageBox'}>
                          <div className={'managerHeader'}>
                            <span style={{ fontSize: 14 }}>Assigned Manager</span>
                          </div>
                          <div className={'managerBoxOuter'}>
                            <div className={'center'} style={{ paddingLeft: 5 }}>
                              <Avatar size={50} style={{ border: `3px solid`, marginLeft: 10 }}
                                      src='../dist/img/demoUser1.png' />
                            </div>
                            <div className={'row'}>
                              <div className={'manageBoxInfo'}>
                                <div><span style={{ fontWeight: 'bold' }}>
                                    <Icon type={'user'} /> {student.branchManagerId ? student.branchManagerId.name : ''}</span><br />
                                  <Icon
                                    type={'mail'} /> {student.branchManagerId ? student.branchManagerId.email : ''}<br />
                                  <Icon
                                    type={'phone'} /> {student.branchManagerId ? student.branchManagerId.mobile : ''}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {applicationData && applicationData.courseUniversity && student && student.registerCountry == countryIds.canada ?
                        <div className={'col-12'}>
                          <div style={{ position: 'relative', textAlign: 'center' }}>
                            <span style={{ position: 'absolute', top: '10%', left: '35%', color: 'white' }}>
                              <img width={'25%'} src='/dist/img/wallet.png' alt='' />
                            </span>
                            <span style={{ position: 'absolute', top: '50%', left: '23%', color: 'white' }}>Please pay your Application Fee</span>
                            <img width='100%' height='80px' src='/dist/img/paymentBG.png' alt='' />
                          </div>
                          <div className='detail-list w-auto card mt20'>
                            {applicationData && applicationData.courseUniversity && student && student.registerCountry == countryIds.canada ?
                              <ul>
                                <li>
                                  <strong>Billed To:</strong>
                                  <div>{student && student.name ? student.name : ''}</div>
                                </li>
                                <li>
                                  <strong>Date:</strong>
                                  <div>{displayDate(Date())}</div>
                                </li>
                                <li>
                                  <strong>Invoice No.:</strong>
                                  <div>{student && student.name ? student.name : ''}</div>
                                </li>
                                <li style={{ width: 'auto' }}>
                                  <strong>Application Fees:</strong>
                                  <div>{applicationData && applicationData.courseUniversity && applicationData.universityCountry && applicationData.courseUniversity.applicationFee != null ?
                                    ` $${applicationData.courseUniversity.applicationFee}` : '$0'}</div>
                                </li>
                                <li>
                                  <strong>Discount:</strong>
                                  <div>{applicationData.universityCountry && applicationData.courseUniversity.starUniversity && applicationData.courseUniversity.applicationFee != null ?
                                    `$${applicationData.courseUniversity.applicationFee}` : '$0'}</div>
                                </li>
                                <li style={{ width: 'auto' }}>
                                  <strong>Net-Payable:</strong>
                                  <div>{applicationData.universityCountry && (applicationData.courseUniversity.starUniversity == true || applicationData.courseUniversity.applicationFee == null) ? '$0' :
                                    `$${applicationData.courseUniversity.applicationFee}`} </div>
                                </li>
                              </ul> : null}
                          </div>
                        </div> : null}
                    </div>
                    <div className={'row documents'}>
                      {/*<div className={'col-lg-12'}>*/}
                      {/*  <div className='align-items-center alignRight'>*/}
                      {/*    <Button className='btn ml-auto btnSub' type='button' loading={state.loading}*/}
                      {/*            htmlType='submit'>Submit</Button>*/}
                      {/*  </div>*/}
                      {/*</div>*/}
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  )
}


const WrappedStudentDashboard = Form.create()(StudentAddNewApplication)


const mapStateToProps = ({ counter, global }) => ({
  count: counter.count,
  loading: global.buttonLoading,
  currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedStudentDashboard)


// export default StudentDashboard
