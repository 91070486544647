import _ from 'lodash'
import React, {useEffect, useState} from 'react'
import EnquiryModal from '../SIngleProperties/enquiryModal'
import renderHTML from 'react-render-html'
import {log} from '@craco/craco/lib/logger'
import {LazyLoadImage} from 'react-lazy-load-image-component'
import {useDispatch} from "react-redux";
import {push} from "connected-react-router";

const $ = window.$;
const RoomTabs = ['All Rooms', 'Studio', 'Penthouse', 'Shared Flat']

const SingleRoomComponent = props => {
    let dispatch = useDispatch()
    let {room, buildingInfo, roomId, currentUser} = props
    let {images, weeks} = room
    const [visible, setVisible] = useState(false)
    const [selectedWeek, setSelectedWeek] = useState({})
    const openModal = singleWeek => {
        if (!currentUser || (currentUser && !currentUser._id)) {
            // dispatch(push(`/login?return=student-accommodation/${buildingInfo.property_slug}?roomId=${singleWeek.room_id}`))
            dispatch(push(`/login?return=${buildingInfo.property_slug}`))
        } else {
            setVisible(true)
            setSelectedWeek(singleWeek)
        }
    }
    const onClose = () => {
        setVisible(false)
        setSelectedWeek({})
    }
    useEffect(() => {
        if (roomId) {
            $("html, body").animate({
                    scrollTop: $(`#${roomId}`).offset().top - 200,
                }, 500
            );
        }
    }, [roomId])

    return (
        <div id={room.build_id.toString()}>
            <div className="property row g-0 fp2 clearfix wow fadeInUp delay-03s">
                <div className="col-lg-3 col-md-3 col-sm-12">
                    <div className="property-img property-img2">
                        {images && images.length ? (
                            <LazyLoadImage
                                src={images[0].image_path}
                                style={{height: 180}}
                                alt="fp-list"
                                className="img-responsive hp-1"
                            />
                        ) : null}
                    </div>
                </div>
                <div className="col-lg-7 col-md-7 col-sm-12 property-content">
                    <div className="info custom-info">
                        <h1 className="title">{room.room_name}</h1>
                        {renderHTML(room.desc)}
                    </div>
                </div>

                <div className={'col-md-12'} style={{overflowY: 'auto'}}>
                    {weeks && weeks.length ? (
                        <>
                            <table className={'table roomTable'} style={{width: '100%'}}>
                                <thead>
                                <tr>
                                    <th>Move - In</th>
                                    <th>Move - Out</th>
                                    <th>Duration</th>
                                    <th>Rent</th>
                                    <th>Total</th>
                                    <th/>
                                </tr>
                                </thead>
                                <tbody>
                                {weeks.map((singleWeek, key2) => {
                                    return (
                                        <tr key={singleWeek} id={singleWeek.room_id}
                                            className={`${roomId == singleWeek.room_id ? "selected" : ""}`}>
                                            <td>
                                                {singleWeek.start_date ? (
                                                    <>{singleWeek.start_date}</>
                                                ) : (
                                                    <>
                                                        <small>set date</small>
                                                    </>
                                                )}
                                            </td>
                                            <td>
                                                {singleWeek.start_date ? (
                                                    <>{singleWeek.end_date}</>
                                                ) : (
                                                    <>
                                                        <small>set date</small>
                                                    </>
                                                )}
                                            </td>
                                            <td>{singleWeek.weeks} / week</td>
                                            <td>{singleWeek.price} / week</td>
                                            <td>
                                                {singleWeek.start_date ? (
                                                    <>{singleWeek.total_price}</>
                                                ) : (
                                                    <>
                                                        <small>set date</small>
                                                    </>
                                                )}
                                            </td>
                                            <td className={'align-center'} style={{width: 150}}>
                                                {singleWeek.start_date ? (
                                                    <>
                                                        <a
                                                            className={'enquiryBtn'}
                                                            onClick={() => {
                                                                openModal(singleWeek)
                                                            }}>
                                                            ENQUIRE
                                                        </a>
                                                    </>
                                                ) : (
                                                    <>
                                                        <a className={'disabledBtn'}>SOLD OUT</a>
                                                    </>
                                                )}
                                            </td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </>
                    ) : null}
                </div>
            </div>
            {visible && selectedWeek ? <EnquiryModal
                visible={visible}
                roomDetails={selectedWeek}
                room={room}
                buildingInfo={buildingInfo}
                title={'Enquiry Now'} setVisible={setVisible} onClose={onClose}/> : null}
        </div>
    )
}

const RoomComponent = props => {
    let {building, property_slug, roomId} = props
    let {rooms} = building
    let [selectedRoomType, setSelectedRoomType] = useState('All Rooms')
    let [roomList, setRoomList] = useState([])
    let [roomTabsList, setRoomTabsList] = useState([]);
    let buildingInfo = {
        roomId: roomId,
        property_slug: property_slug,
        address: building.address,
        distance: building.distance,
        buildingId: building.id.buildingId,
        city_id: building.city_id,
        city_lat: building.city_lat,
        city_long: building.city_long,
        city_name: building.city_name,
        city_slug: building.city_slug,
        country_id: building.country_id,
        country_lat: building.country_lat,
        country_long: building.country_long,
        country_name: building.country_name,
        country_slug: building.country_slug,
        min_price: building.min_price,
        minimum_short_term_week: building.minimum_short_term_week,
        name: building.name,
        name_th: building.name_th,
        name_zh: building.name_zh,
        phone: building.phone,
        place_id: building.place_id,
        portal_id: building.portal_id,
        post_code: building.post_code,
        price_structure: building.price_structure,
        provider_name: building.provider_name,
        provider_type: building.provider_type,
        slug: building.slug,
        status_id: building.status_id,
        totalRooms: building.totalRooms,
        building_flow: building.building_flow,
    }

    let listOfRoomTypes = () => {
        let roomList = ['All Rooms']
        _.each(rooms, item => {
            roomList.push(item.room_type)
        })
        let uniqRoom = _.uniq(roomList)
        setRoomTabsList(uniqRoom)
    }

    useEffect(
        () => {
            listOfRoomTypes()
        },
        [rooms]
    )

    useEffect(
        () => {
            filterRooms()
        },
        [selectedRoomType]
    )

    let filterRooms = () => {
        if (selectedRoomType == 'All Rooms') {
            setRoomList(rooms)
        } else {
            let filteredRoom = _.filter(rooms, item => {
                return item.room_type == selectedRoomType
            })
            if (filteredRoom) {
                setRoomList(filteredRoom)
            } else {
                setRoomList([])
            }
        }
    }

    return (
        <>
            <>
                <div className="container">
                    <div className="main-title-4">
                        <div className="styles_header__EJma3">
                            <h2 className="styles_title__4yCT0">
                                <strong>Choose Your Room</strong>
                            </h2>
                        </div>

                        <div className="property-description tabbing tabbing-box tb2">
                            <ul className="nav nav-tabs">
                                {roomTabsList.map((singleRoom, key) => {
                                    return (
                                        <li key={singleRoom} className="nav-item">
                                            <button
                                                onClick={() => {
                                                    setSelectedRoomType(singleRoom)
                                                }}
                                                className={`nav-link ${
                                                    singleRoom == selectedRoomType ? 'active' : ''
                                                }`}>
                                                {singleRoom}
                                            </button>
                                        </li>
                                    )
                                })}
                            </ul>
                            <br/>
                            {roomList && roomList.length ? (
                                <div className="tab-content">
                                    <div
                                        className="accordion accordion-flush"
                                        id="accordionFlushExample7">
                                        {roomList.map((eachRoom, key2) => {
                                            return (
                                                <SingleRoomComponent
                                                    buildingInfo={buildingInfo}
                                                    room={eachRoom}
                                                    roomId={roomId}
                                                    currentUser={props.currentUser}
                                                />
                                            )
                                        })}
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </>
        </>
    )
}

export default RoomComponent
