import {
    Button,
    Col, DatePicker,
    Form, notification,
    Row, Select, Tooltip,
} from 'antd'
import React, {useEffect, useRef, useState} from 'react'
import {connect, useDispatch} from 'react-redux'
import {TableComp} from 'sz-react-utils-lite'
import {
    newFormatDisplayDate,
    oxfordUniversity, filterOption, oxfordInterviewStatusList
} from '../../../../components/_utils/appUtils'
import {getUrlPushWrapper} from '../../../../routes'
import {exportOxfordInterviewListFxn, oxfordInterviewApplicationsFxn} from "../../actions/oxfordAction";
import moment from "moment";
import UpdateInterviewStatusDrawer from "./drawers/updateScheduleStatus";
import StudentInfoDrawer from "./drawers/studentInfoDrawer";
import DocumentDrawer from "../../drawers/studentDocumentDrawer";

const {RangePicker} = DatePicker

const {Option} = Select

const CheckUpdateStatusComponent = (props) => {
    let {children, date} = props;
    // let currentDate = moment().format('DD-MMMM-YYYY')
    let currentDate = moment()
    let checkDate = moment(date).isSameOrBefore(currentDate) ? true : false

    return (
        <>
            {checkDate ? children : null}
        </>
    )
}


const OxfordPendingInterviewList = props => {
    const [name, setName] = useState('')
    const [agentId, setAgentId] = useState('')
    const [totalApplication, setTotalApplication] = useState(0)
    const [currentStatus, setCurrentStatus] = useState('')
    const [status, setStatus] = useState('')
    const [oxfordInterviewStatus, setOxfordInterviewStatus] = useState('')
    let [tableSearch, setTableSearch] = useState(false)
    let [filters, setFilters] = useState({})
    let [courseName, setCourseName] = useState('')
    const [toDate, setToDate] = useState('')
    const [fromDate, setFromDate] = useState('')
    let [interviewState, setInterviewState] = useState({
        visible: false,
        studentData: {}
    })
    let [studentState, setStudentState] = useState({
        visible: false,
        student: {}
    })
    let [documentState, setDocumentState] = useState({
        visible: false,
        studentData: {}
    })


    const dispatch = useDispatch()
    let user = localStorage.getItem('user')
        ? JSON.parse(localStorage.getItem('user'))
        : null
    const tableAppRef = useRef()
    let apiRequest = params => {
        return new Promise(async resolve => {
            let resp = await getParamsForApplicationList()
            params = {...params, ...resp}
            params.appUniversityId = oxfordUniversity
            params.universityLogin = true
            if (!params.results) {
                params.results = 50
                params.count = 50
            }
            if (tableSearch) {
                params.page = 1
            }
            try {
                setFilters(params)
                let {data: resp} = await dispatch(oxfordInterviewApplicationsFxn({...params}))
                setTotalApplication(resp.total)
                setTableSearch(false)
                resolve(resp)
            } catch (e) {
            }
        })
    }
    useEffect(() => {
        setFieldByParams()
    }, [])

    const setFieldByParams = async () => {
        let resp = await getParamsForApplicationList()

        if (resp.status) {
            setStatus(resp.status)
        }
        if (resp.oxfordInterviewStatus) {
            setOxfordInterviewStatus(resp.oxfordInterviewStatus)
        }
        if (resp.agentId) {
            setAgentId(resp.agentId)
        }
        if (resp.courseName) {
            setCourseName(resp.courseName)
        }
    }

    const events = {
        reloadFxn: () => {
            tableAppRef.current.reload()
        },
        openInterviewStatusDrawer: (record) => {
            setInterviewState({
                visible: true,
                studentData: record
            })
        },
        closeInterviewStatusDrawer: () => {
            setInterviewState({
                visible: false,
                studentData: {}
            })
        },
        enterName: value => {
            setName(value)
        },
        openStudentState: (record) => {
            setStudentState({
                visible: true,
                student: record
            })
        },
        closeStudentState: () => {
            setStudentState({
                visible: false,
                student: {}
            })
        },
        openDocumentDrawer: (record) => {
            setDocumentState({
                visible: true,
                studentData: record
            })
        },
        closeDocumentDrawer: () => {
            setDocumentState({
                visible: false,
                studentData: {}
            })
        },
    }

    let exportExcel = async () => {
        let params = {}
        let resp = await getParamsForApplicationList()
        params = {...params, ...resp}
        params.count = totalApplication;
        params.courseUniversity = oxfordUniversity;
        let {success, filePath, message} = await dispatch(exportOxfordInterviewListFxn(params))
        if (success) {
            notification.success({message: message})
        } else {
            notification.error({message: message})
        }
    }


    const getParamsForApplicationList = () => {
        return new Promise(resolve => {
            let searchParams = new URLSearchParams(window.location.search)
            let appUniversityId = searchParams.get('appUniversityId')
            let name = searchParams.get('name')
            let status = searchParams.get('status')
            let oxfordInterviewStatus = searchParams.get('oxfordInterviewStatus')
            let courseName = searchParams.get('courseName')
            let fromDate = searchParams.get('fromDate')
            let toDate = searchParams.get('toDate')
            let obj = {}

            if (appUniversityId) {
                obj.appUniversityId = appUniversityId
            }
            if (name) {
                obj.name = name
            }
            if (status) {
                obj.status = status
            }
            if (oxfordInterviewStatus) {
                obj.oxfordInterviewStatus = oxfordInterviewStatus
            }
            if (agentId) {
                obj.agentId = agentId
            }
            if (courseName) {
                obj.courseName = courseName
            }
            if (fromDate) {
                obj.fromOxfordInterviewDate = fromDate
            }
            if (toDate) {
                obj.toOxfordInterviewDate = toDate
            }
            resolve(obj)
        })
    }

    const searchFxn = async () => {
        let resp = await getParamsForApplicationList()
        let obj = {}
        if (name) {
            obj.name = name
        }
        if (status) {
            obj.status = status
        }
        if (oxfordInterviewStatus) {
            obj.oxfordInterviewStatus = oxfordInterviewStatus
        }
        if (agentId) {
            obj.agentId = agentId
        }
        if (courseName) {
            obj.courseName = courseName
        }
        if (toDate) {
            obj.toDate = moment(toDate).format('MM-DD-YYYY')
        }
        if (fromDate) {
            obj.fromDate = moment(fromDate).format('MM-DD-YYYY')
        }
        dispatch(
            getUrlPushWrapper('oxfordPendingInterviewList', {
                ...obj
            })
        )
        setTableSearch(true)
        setTimeout(() => {
            if (tableAppRef && tableAppRef.current) {
                tableAppRef.current.reload()
            }
        }, 200)
    }
    const clearFxn = async () => {
        let resp = await getParamsForApplicationList()
        let obj = {}
        setName('')
        setStatus('')
        setOxfordInterviewStatus('')
        setAgentId('')
        setCourseName('')
        setToDate('')
        setFromDate('')
        dispatch(
            getUrlPushWrapper('oxfordPendingInterviewList', {
                ...obj
            })
        )
        setTableSearch(true)
        setTimeout(() => {
            tableAppRef.current.reload()
        }, 200)
    }

    const columns = [
        {
            title: 'App#',
            key: 'applications.id',
            dataIndex: 'applications.id',
            width: 60,
            render: item => {
                return <div style={{width: 60}}>{item}</div>
            }
        },
        {
            title: 'Name',
            key: 'name',
            dataIndex: 'name',
            width: 120,
            render: (item, record) => {
                return (
                    <div style={{width: 120}}>
                        {item}
                    </div>
                )
            }
        },
        {
            title: 'Date of Birth',
            key: 'dateOfBirth',
            dataIndex: 'dateOfBirth',
            width: 120,
            render: (item, record) => {
                return (
                    <div style={{width: 120}}>
                        {newFormatDisplayDate(item)}
                    </div>
                )
            }
        },
        {
            title: 'Course',
            key: 'applications.courseName',
            dataIndex: 'applications.courseName',
            width: 150,
            render: item => {
                return <div style={{width: 150}}>{item}</div>
            }
        },

        {
            title: 'Email',
            key: 'email',
            dataIndex: 'email',
            width: 120,
            render: (item) => {
                return (
                    <div style={{width: 120}}>
                        {item}
                    </div>
                )
            }
        },
        {
            title: 'Phone No',
            key: 'studentPhoneNumber',
            dataIndex: 'studentPhoneNumber',
            width: 120,
            render: (item) => {
                return (
                    <div style={{width: 120}}>
                        {item}
                    </div>
                )
            }
        },
        {
            title: 'Student Id',
            key: 'applications.studentIdNo',
            dataIndex: 'applications.studentIdNo',
            width: 120,
            render: (item) => {
                return (
                    <div style={{width: 120}}>
                        {item}
                    </div>
                )
            }
        },
        {
            title: 'Interview Status',
            key: 'applications.oxfordInterviewStatus',
            dataIndex: 'applications.oxfordInterviewStatus',
            width: 120,
            render: (val) => {
                return (
                    <div style={{width: 120}}>
                        {val == 'Pending' ? <label className={'label label-warning'}>{val}</label> : ""}
                        {val == 'Pass' ? <label className={'label label-success'}>{val}</label> : ""}
                        {val == 'Fail' ? <label className={'label label-danger'}>{val}</label> : ""}
                        {val == 'Reappear' ? <label className={'label label-black'}>{val}</label> : ""}
                    </div>
                )
            }
        },
        {
            title: 'Interview Date',
            key: 'applications.oxfordInterviewDate',
            dataIndex: 'applications.oxfordInterviewDate',
            width: 120,
            render: (val) => {
                return (
                    val ? <div style={{width: 120}}>
                        {moment(val).format('dddd, DD MMMM YYYY')}
                    </div> : null
                )
            }
        },
        {
            title: 'Action',
            key: 'action',
            dataIndex: 'action',
            width: 100,
            render: (val, record) => {
                let {oxfordInterviewStatus, oxfordInterviewDate} = record.applications;
                return (
                    <>
                        <CheckUpdateStatusComponent date={oxfordInterviewDate}>
                            {oxfordInterviewStatus == 'Pending' ? <div className={'actionBtnGroup'}>
                                <Tooltip title={'Update Status'}>
                                    <a className="btn btn-default btn-xs" onClick={() => {
                                        events.openInterviewStatusDrawer(record)
                                    }}>
                                        Update Status
                                    </a>
                                </Tooltip>
                            </div> : null}
                        </CheckUpdateStatusComponent>

                        <div className={'mt10'}>
                            <Tooltip title={'View Student'}>
                                <a className="btn btn-default btn-xs" onClick={() => {
                                    events.openStudentState(record);
                                }}>
                                    <img src="/dist/img/view.svg" alt=""/> View
                                </a>
                            </Tooltip>
                        </div>
                        <div className={'mt10'}>
                            <Tooltip title={'View Student'}>
                                <a className={`btn btn-default btn-xs`}
                                   onClick={() => events.openDocumentDrawer(record)}>
                                    View Documents
                                </a>
                            </Tooltip>
                        </div>
                    </>
                )
            }
        }

    ]

    const extra = (
        <div className={'filterBox'}>
            <Row gutter={12}>
                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className="search-box-table round">
                        <input
                            className="form-control form-control"
                            type="search"
                            placeholder="Search by name..."
                            value={name}
                            onChange={e => {
                                events.enterName(e.target.value)
                            }}
                        />
                        <img src="/dist/img/search.png" alt=""/>
                    </div>
                </Col>
                <Col span={4}>
                    <div className="sort-box-table custom-sort-box-new">
                        <div className="search-box-table round">
                            <input
                                className="form-control form-control"
                                type="search"
                                placeholder="course name..."
                                value={courseName}
                                onChange={e => {
                                    setCourseName(e.target.value)
                                }}
                            />
                            <img src="/dist/img/search.png" alt=""/>
                        </div>
                    </div>
                </Col>
                <Col span={4}>
                    <div className="sort-box-table custom-sort-box-new">
                        <Select
                            name="Interview Status"
                            onChange={item => setOxfordInterviewStatus(item)}
                            filterOption={filterOption}
                            className={'antSelect'}
                            placeholder={'Interview Status'}
                            allowClear={true}
                            value={oxfordInterviewStatus || undefined}>
                            {oxfordInterviewStatusList && oxfordInterviewStatusList.length
                                ? oxfordInterviewStatusList.map((item, key) => {
                                    return (
                                        <Option value={item} key={key}>
                                            {item}
                                        </Option>
                                    )
                                })
                                : null}
                        </Select>
                    </div>
                </Col>
                <Col md={4} sm={4} xs={12} lg={4} key={fromDate}>
                    <RangePicker
                        defaultValue={[fromDate, toDate]}
                        onChange={val => {
                            setFromDate(val[0])
                            setToDate(val[1])
                        }}
                    />
                </Col>
                <Col md={4} sm={4} xs={12} lg={4}>
                    <Button onClick={() => searchFxn()} className={'roundBtn'}>
                        Search
                    </Button>
                    <Button onClick={() => clearFxn()} className={'roundBtn'}>
                        Clear
                    </Button>
                </Col>
            </Row>
        </div>
    )

    return (
        <React.Fragment>
            <div>
                <div className="row mt-4">
                    <div className="col-lg-12">
                        <div className="card">
                            <div
                                className="table-head d-flex align-items-center all-student-search"
                                style={{height: 60}}>
                                <Button
                                    onClick={() => dispatch(getUrlPushWrapper('dashboard'))}
                                    className={'roundGoBackBtn'}
                                    icon={'arrow-left'}>
                                    Back
                                </Button>
                                <h5>Oxford All Interview List</h5>
                                <div
                                    className="search-box-table"
                                    style={{backgroundColor: 'transparent'}}>
                                    {currentStatus} (Total - {totalApplication})
                                </div>
                                <div className="sort-box-table mark-btn">
                                    <span className={'sort-box-table-right'}>
                                          <Tooltip title={'Export Excel'}>
                                           <Button onClick={() => exportExcel()}
                                                   className={'roundBtn'}>
                                                    Export Excel
                                                    </Button>
                                                    </Tooltip>
                                    </span>
                                </div>
                            </div>
                            <div className="card-body table-responsive mt10">
                                {extra}
                                <TableComp
                                    columns={columns}
                                    rowKey={(item, index) => {
                                        return index
                                    }}
                                    apiRequest={apiRequest}
                                    pagination={{
                                        position: 'top',
                                        showSizeChanger: true,
                                        pageSizeOptions: ['50', '100', '200', '500'],
                                        defaultPageSize: 50,
                                        current: filters.page ? filters.page : 1
                                    }}
                                    ref={tableAppRef}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {interviewState.visible ?
                <UpdateInterviewStatusDrawer
                    {...interviewState}
                    onSubmit={() => {
                        events.closeInterviewStatusDrawer()
                        events.reloadFxn()
                    }}
                    onClose={events.closeInterviewStatusDrawer}/>
                : null}

            {studentState.visible ?
                <StudentInfoDrawer
                    {...studentState}
                    onSubmit={() => {
                        events.closeStudentState()
                        events.reloadFxn()
                    }}
                    onClose={events.closeStudentState}/>
                : null}

            {documentState.visible ?
                <DocumentDrawer onClose={events.closeDocumentDrawer}
                                reloadTable={events.reloadFxn}
                                studentData={documentState.studentData}
                                applicationId={documentState.studentData.applications._id}
                                studentId={documentState.studentData._id}
                                visible={documentState.visible}/> : null}

        </React.Fragment>
    )
}
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}
const WrappedComponent = Form.create()(OxfordPendingInterviewList)
export default connect(
    null,
    mapDispatchToProps
)(WrappedComponent)
