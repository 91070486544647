import React, {useState, useEffect} from "react"
import {LazyLoadImage} from "react-lazy-load-image-component";
import loadable from "@loadable/component";
import {singleCountryFxn} from "../actions";
import BannerComponent from "./bannerComponent";

const TopCityComponent = loadable(() => import('./topCityComponent'))
const PopularBuilding = loadable(() => import('./popularBuilding'))
const DirectoryOfCity = loadable(() => import('./directoryOfCity'))

const Home = () => {
    let [countryData, setCountryData] = useState({})
    let loadData = async () => {
        let {data} = await singleCountryFxn()
        setCountryData(data)
    }
    useEffect(() => {
        loadData()
    }, [])
    return (
        <>
            <BannerComponent/>
            <TopCityComponent topCities={countryData.topCities}/>
            <PopularBuilding/>

            {countryData.cities && countryData.cities.length ?
                <DirectoryOfCity cities={countryData.cities}/> : null}
        </>
    )
}
export default Home
