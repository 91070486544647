import React, {useState, useEffect} from 'react'
import {Pagination, Modal} from 'antd'
import {
    BroadcastingFxn,
    checkBroadcastingStatusFxn,
} from '../../users/actions/user'
import {useDispatch} from 'react-redux'
import {addTrainingRequest} from "./actions";

const {confirm} = Modal;

const fields = {
    name: '',
    country: '',
    university: '',
    shareYourExperience: '',
    rating: null
}
const countryList = [
    'Canada',
    'United States',
    'Australia',
    'United Kingdom'
]
const TrainingRequestComponent = (props) => {
    const dispatch = useDispatch()
    let [message, setMessage] = useState('')

    useEffect(() => {
        // checkFeedbackExists()
    }, [])


    const submitHandleFxn = async () => {
        let {pathname} = window.location
        let agentId = pathname.split('/').pop()
        let resp = await dispatch(addTrainingRequest({agentId}));
        if (resp && resp.success) {
            setMessage(resp.message)
        } else {
            setMessage(resp.message)
        }
    }

    const confirmMeetingFxn = async (data) => {
        confirm({
            width: "35%",
            title: 'Are you sure, you want to confirm your Online Portal Training ?',
            // content: 'Confirm Online Portal Meeting',
            okText: "Confirm",
            onOk() {
                submitHandleFxn()
            },
            onCancel() {

            },
        });
    }

    return (
        <React.Fragment>
            <div className="sub-banner-training">
                <div className="overlay">

                </div>
            </div>
            <div className="properties-section-body content-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-xs-12">

                            <div className={'trainingBox'}>
                                <img src={'../newImages/zoom-logo.png'}/>

                                {message ?
                                    <div className={'textBox normal'}>
                                        <h3>{message}</h3>
                                    </div> :
                                    <div className={'textBox'}>
                                        <h3>Confirm your online portal training</h3>
                                        <a className={'confirmLink'} onClick={() => {
                                            confirmMeetingFxn()
                                        }}>
                                            Click to confirm
                                        </a>
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}
export default TrainingRequestComponent
