import React from "react"

const CustomInputComponent = (props) => {
    let {leftContent, rightContent, value, title, onChange, disabled = false, type = 'number'} = props;
    return (
        <div className={'customInput'}>
            <label>{title}</label>
            <div className="input-group inputGroup">
                {leftContent ? <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            {leftContent}
                                        </span>
                </div> : null}
                <input type={type} className="form-control" placeholder={title}
                       value={value}
                       disabled={disabled}
                       onChange={({target}) => {
                           onChange(target.value)
                       }}
                       required={true}/>
                {rightContent ? <div className="input-group-append">
                    <span className="input-group-text">{rightContent}</span>
                </div> : null}
            </div>
        </div>
    )
}
export default CustomInputComponent
