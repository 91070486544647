import {apiUrl} from '../../../settings'

export const getStudentCambieCourseUrl = () => {
    return apiUrl + '/getStudentCambieCourse'
}
export const getPendingCambieApplicationsUrl = () => {
    return apiUrl + '/pendingLOACambieApplications'
}
export const getStudentRoyalArtsCourseUrl = () => {
    return apiUrl + '/getStudentRoyalArtsCourse'
}
export const getPendingRoyalArtsApplicationsUrl = () => {
    return apiUrl + '/pendingLOARoyalArtsApplications'
}
export const exportStudentRoyalArtsCourseUrl = () => {
    return apiUrl + '/exportStudentRoyalArtsCourse'
}
