import React, { PureComponent, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Icon, Tabs, Badge, Spin, Card } from 'antd'
import classNames from 'classnames';
import HeaderDropdown from '../HeaderDropdown';
import List from './NoticeList';
import styles from './index.less';

const { TabPane } = Tabs;

export default class NoticeIcon extends PureComponent {
  static Tab = TabPane;

  static defaultProps = {
    onItemClick: () => {},
    onPopupVisibleChange: () => {},
    onTabChange: () => {},
    onClear: () => {},
    loading: false,
    clearClose: false,
    /*locale: {
      emptyText: 'No notifications',
      clear: 'Clear',
    },*/
  };

  state = {
    visible: false,
  };

  onItemClick = (item, tabProps) => {
    const { onItemClick } = this.props;
    const { clickClose } = item;
    onItemClick(item, tabProps);
    if (clickClose) {
      this.popover.click();
    }
  };

  onClear = name => {
    const { onClear, clearClose } = this.props;
    onClear(name);
    if (clearClose) {
      this.popover.click();
    }
  };


  viewAll = () => {
    const { viewAll, clearClose } = this.props
    viewAll()
    if (clearClose) {
      this.popover.click()
    }
  }

  onTabChange = tabType => {
    const { onTabChange } = this.props;
    onTabChange(tabType);
  };

  /* getNotificationBox() {
     const { children, loading, locale } = this.props;
     if (!children) {
       return null;
     }
     const panes = React.Children.map(children, child => {
       const { list, title, name, count } = child.props;
       const len = list && list.length ? list.length : 0;
       const msgCount = count || count === 0 ? count : len;
       const tabTitle = msgCount > 0 ? `${title} (${msgCount})` : title;
       return (
         <TabPane tab={tabTitle} key={name}>
           <List
             {...child.props}
             data={list}
             onClick={item => this.onItemClick(item, child.props)}
             onClear={() => this.onClear(name)}
             title={title}
             locale={locale}
           />
         </TabPane>
       );
     });
     return (
       <Fragment>
         <Spin spinning={loading} delay={0}>
           <Tabs className={styles.tabs} onChange={this.onTabChange}>
             {panes}
           </Tabs>
         </Spin>
       </Fragment>
     );
   }*/


  getNotificationBox() {
    const { children, loading, locale, list, title, name, count } = this.props
    const len = list && list.length ? list.length : 0
    const msgCount = count || count === 0 ? count : len
    const tabTitle = msgCount > 0 ? `${title} (${msgCount})` : title


    return (
      <Fragment>
        <Spin spinning={loading} delay={0}>
          <Card title={title} className={'privateMessageBox'}>
            <List
              data={list}
              onClick={item => this.onItemClick(item)}
              viewAll={() => this.viewAll()}
              title={title}
              locale={locale}
            />
          </Card>
        </Spin>
      </Fragment>
    )
  }

  handleVisibleChange = visible => {
    const { onPopupVisibleChange } = this.props;
    this.setState({ visible });
    onPopupVisibleChange(visible);
  };

  render() {
    const { className, count, popupVisible, bell } = this.props;
    const { visible } = this.state;
    const noticeButtonClass = classNames(className, styles.noticeButton);
    const notificationBox = this.getNotificationBox();
    // const NoticeBellIcon = bell || <Icon type="bell" className={styles.icon} />;
    const NoticeBellIcon = bell || <img src={'/dist/img/notification.png'} className={styles.icon}/>
    const trigger = (
      <span className={classNames(noticeButtonClass, { opened: visible })}>
        <Badge count={count} style={{ boxShadow: 'none' }} className={styles.badge}>
          {NoticeBellIcon}
        </Badge>
      </span>
    );
    if (!notificationBox) {
      return trigger;
    }
    const popoverProps = {};
    if ('popupVisible' in this.props) {
      popoverProps.visible = popupVisible;
    }
    return (
      <HeaderDropdown
        placement="bottomRight"
        overlay={notificationBox}
        overlayClassName={styles.popover}
        trigger={['click']}
        visible={visible}
        onVisibleChange={this.handleVisibleChange}
        {...popoverProps}
        ref={node => (this.popover = ReactDOM.findDOMNode(node))} // eslint-disable-line
      >
        {trigger}
      </HeaderDropdown>
    );
  }
}
