import {log} from '@craco/craco/lib/logger'
import {
    Button,
    Col,
    Drawer,
    Form,
    Icon,
    Input,
    Modal,
    Row,
    notification
} from 'antd'
import _ from 'lodash'
import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {InputBox} from '../../../components/_utils/appUtils'
import {
    listAllAccount
} from '../../bankAccount/actions/bankAccount'
import {
    addExpenseWithdrawalFxn,
    sendExpenseWithdrawalOtp
} from '../../withdrawal/actions/withdrawalAction'
import AddBankAccount from './addBankAccounts'
import {getUser} from "../../users/actions/user";

let initState = {
    bank: '',
    balance: 0,
    amount: 0
}
const WithdrawalComponent = props => {
    const {onClose, visible, currentBalance, onSubmit, user} = props
    let [state, setState] = useState(initState)
    let [bankList, setBankList] = useState([])
    let [visibleBankDrawer, setVisibleBankDrawer] = useState(false)
    let [visibleOtpScreen, setVisibleOtpScreen] = useState(false)
    let [otp, setOtp] = useState('')
    let dispatch = useDispatch()
    const loadBankList = async () => {
        let resp = await dispatch(listAllAccount({}))
        setBankList(resp.data)
    }
    useEffect(() => {
        loadBankList()
        loadCurrentBalance()
    }, [])


    const loadCurrentBalance = async () => {
        let data = await dispatch(getUser(user._id));
        if (data && data.wallet) {
            updateState({balance: data.wallet})
        }
    }

    const updateState = data => {
        setState(prevState => {
            return {
                ...prevState,
                ...data
            }
        })
    }

    const events = {
        showBankDrawer: () => {
            setVisibleBankDrawer(true)
        },
        hideBankDrawer: () => {
            setVisibleBankDrawer(false)
            loadBankList()
        }
    }

    const handleChange = data => {
        setState(prevData => {
            return {
                ...prevData,
                ...data
            }
        })
    }

    const handleSubmit = async e => {
        e.preventDefault()
        if (!state.bank) {
            notification.warning({message: 'Please choose account.'})
            return
        }
        if (!state.amount) {
            notification.warning({message: 'Enter enter amount'})
            return
        }
        if (state.amount > state.balance) {
            notification.warning({message: 'Enter valid amount'})
            return
        }
        let obj = _.clone(state)
        let findBank = _.find(bankList, item => {
            return item.accounts._id == obj.bank
        })
        obj = {
            ...obj,
            ...findBank.accounts
        }
        let resp = await dispatch(sendExpenseWithdrawalOtp(obj))
        if (resp && resp.success) {
            setVisibleOtpScreen(true)
        }
    }

    const handleSubmitAddWithdrawal = async e => {
        e.preventDefault()
        if (!state.bank) {
            notification.warning({message: 'Please choose account.'})
            return
        }
        if (!state.amount) {
            notification.warning({message: 'Enter enter amount'})
            return
        }
        if (state.amount > state.balance) {
            notification.warning({message: 'Enter valid amount'})
            return
        }
        if (!otp) {
            notification.warning({message: 'Please enter otp'})
            return
        }
        let obj = _.clone(state)
        let findBank = _.find(bankList, item => {
            return item.accounts._id == obj.bank
        })
        obj = {
            ...obj,
            ...findBank.accounts,
            otp
        }
        let resp = await dispatch(addExpenseWithdrawalFxn(obj))
        if (resp && resp.success) {
            handleChange({
                bank: '',
                amount: null
            })
            setVisibleOtpScreen(false)
            onSubmit()
            setOtp('')
            loadCurrentBalance()
        }
    }
    return (
        <>
            <Drawer
                visible={visible}
                title={'Withdrawal'}
                onClose={onClose}
                width={'45%'}>
                <div className="card unizportal">
                    <Form
                        className={'vertical-form'}
                        autoComplete="off"
                        onSubmit={handleSubmit}>
                        <Row gutter={24} className={'rowWrap'}>
                            <Col span={24}>
                                <Form.Item>
                                    <InputBox title={'Balance'}>
                                        <select
                                            value={state.bank}
                                            className={'form-control'}
                                            name="bankName"
                                            onChange={({target}) => {
                                                updateState({bank: target.value})
                                            }}
                                            placeholder="Total Amount">
                                            <option value={''}>Choose Account</option>
                                            {bankList && bankList.length
                                                ? bankList.map(item => {
                                                    let {accounts} = item
                                                    return (
                                                        <option key={accounts._id} value={accounts._id}>
                                                            {accounts.bankName} ({accounts.accountNo})
                                                        </option>
                                                    )
                                                })
                                                : null}
                                        </select>
                                        <a
                                            className={'linkBtn'}
                                            onClick={() => {
                                                events.showBankDrawer()
                                            }}
                                            target={'_blank'}>
                                            <Icon type={'plus'}/>
                                        </a>
                                    </InputBox>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item>
                                    <InputBox title={'Balance'}>
                                        <Input
                                            disabled={true}
                                            value={state.balance}
                                            className={'form-control'}
                                            name="bankName"
                                            placeholder="Total Amount"
                                        />
                                    </InputBox>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item>
                                    <InputBox title={'Amount'}>
                                        <Input
                                            type={'number'}
                                            value={state.amount}
                                            className={'form-control'}
                                            name="bankName"
                                            onChange={({target}) => {
                                                updateState({amount: target.value})
                                            }}
                                            placeholder="Total Amount"
                                        />
                                    </InputBox>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="btn">
                                SAVE
                            </Button>
                        </Form.Item>
                    </Form>
                </div>

                {visibleBankDrawer ? (
                    <AddBankAccount
                        onClose={events.hideBankDrawer}
                        visible={visibleBankDrawer}
                    />
                ) : null}

                <Modal
                    visible={visibleOtpScreen}
                    closable={true}
                    onCancel={onClose}
                    title={'Enter OTP'}
                    footer={false}>
                    <div style={{textAlign: 'center', marginBottom: 10}}>
                        <h6>
                            {' '}
                            A text message with a 4-digit verification code has been sent to
                            your phone number.
                        </h6>
                        <InputBox title={'Enter OTP'}>
                            <input
                                className={'form-control mt10'}
                                type={'password'}
                                minLength={4}
                                maxLength={4}
                                value={otp}
                                onChange={({target}) => {
                                    setOtp(target.value)
                                }}
                            />
                        </InputBox>
                        <Button
                            className={'btn btn-success mt10'}
                            onClick={handleSubmitAddWithdrawal}>
                            Submit OTP
                        </Button>
                    </div>
                </Modal>
            </Drawer>
        </>
    )
}
const WrappedComponent = Form.create()(WithdrawalComponent)
export default WrappedComponent
