import React, {useEffect, useState} from "react"
import {useDispatch} from "react-redux";
import {marketingUserForAgentsFxn} from "../actions/dashboardAction";
import _ from "lodash"
import {Avatar, Icon, Row, Col} from "antd";

let countryColors = {
    UK: '#ff0066',
    USA: '#ff0066',
    Australia: '#003399',
    Canada: '#ad2125',
    Germany: '#ad2125',
    " Cyprus": '#ad2125',
}
const HelpComponent = (props) => {
    let dispatch = useDispatch()
    let [marketingUsers, setMarketingUsers] = useState({})
    useEffect(() => {
        loadMarketingUsers()
    }, [])
    let loadMarketingUsers = async () => {
        let {data} = await dispatch(marketingUserForAgentsFxn({}))
        setMarketingUsers(data);
    }
    return (
        <>
            <div className="row  mt-4">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="table-head d-flex align-items-center">
                            <h5>Supporting Team</h5>
                        </div>
                        <div>
                            <div className="card-pane-right" style={{padding: 20}}>
                                <div className="nav flex-column nav-pills w-100">
                                    <Row gutter={100}>
                                        {!_.isEqual(marketingUsers) ? <>
                                            {Object.keys(marketingUsers).map((countryName, key1) => {
                                                let listData = marketingUsers[countryName];
                                                return (
                                                    listData && listData.length ? <>
                                                        {listData.map((eachUser, key) => {
                                                            return (
                                                                <Col span={8}>
                                                                    <div className={'marketingUserBox'}>
                                                                        <div className={'managerHead'}
                                                                             style={{
                                                                                 backgroundColor: countryColors[countryName],
                                                                             }}>
                                                                              <span style={{
                                                                                  marginLeft: 'auto',
                                                                                  fontSize: 14
                                                                              }}>
                                                                                DESIGNATED MANAGER
                                                                              </span>
                                                                        </div>
                                                                        <div className={'managerBody'}>
                                                                            <div className={'center'}
                                                                                 style={{paddingRight: 10}}>
                                                                                <Avatar
                                                                                    size={50}
                                                                                    style={{
                                                                                        border: `3px solid ${countryColors[countryName]}`,
                                                                                        marginLeft: 10,
                                                                                        marginRight: 10,
                                                                                    }}
                                                                                    src={
                                                                                        eachUser &&
                                                                                        eachUser.logo &&
                                                                                        eachUser.logo.url
                                                                                            ? eachUser.logo.url
                                                                                            : '../dist/img/user.png'
                                                                                    }
                                                                                />
                                                                            </div>
                                                                            <div className={'row'}>
                                                                                <div
                                                                                    style={{
                                                                                        textAlign: 'left',
                                                                                        padding: 5,
                                                                                        fontSize: 12,
                                                                                        color: '#585858'
                                                                                    }}>
                                                                                    <div>
                                                                                        <span
                                                                                            style={{fontWeight: 'bold'}}>
                                                                                        <Icon
                                                                                            type={'user'}/> {countryName}
                                                                                    </span>
                                                                                        <br/>
                                                                                        <Icon
                                                                                            type={'mail'}/> {eachUser.email}
                                                                                        <br/>
                                                                                        <Icon type={'phone'}/>
                                                                                        {eachUser.mobile}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </Col>
                                                            )
                                                        })}
                                                    </> : null
                                                )
                                            })}

                                        </> : null}

                                    </Row>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default HelpComponent
