import React, {useEffect, useState} from 'react'
import {Drawer, Card, Input, Select, Button, notification, Tooltip, Col, Row, Form, Table, Icon} from 'antd'
import {departmentObj} from '../../../components/_utils/appUtils'
import {useDispatch} from 'react-redux'
import {transferApplicationToOtherDepartment} from '../actions/chooseStudent'
import {RowTable} from '../../../components/_utils/RowTable'
import {listAllUniversities} from '../../university/actions/university'
import {listAllCountries} from '../../countries/actions/countries'
import {listAllCourse} from '../../course/actions/courseAction'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import _ from 'lodash'

const {Option} = Select
const {TextArea} = Input
const initialState = {
    description: '',
    userId: '',
    userList: []
}

const TransferToOtherDepartmentBtn = (props) => {
    let {universityCountry, status, actionBtn} = props
    let {countryName} = universityCountry
    let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
    return (
        user && user.department == departmentObj.assDepartment && countryName == 'UK' && status == 'Waiting To Apply' ? <>
            <Tooltip title={'Transfer To Other Department'}>
                <button className="btn" onClick={() => {
                    actionBtn()
                }}>
                    <img src={'../dist/icons/transfer-other.png'}/>
                </button>
            </Tooltip>
        </> : null
    )
}

const TransferToOtherDepartmentAustBtn = (props) => {
    let {universityCountry, status, actionBtn} = props
    let {countryName} = universityCountry
    let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
    return (
        user && user.department == departmentObj.assDepartment && (countryName == 'Australia' || countryName == 'Australia_L1') && status == 'Waiting To Apply' ? <>
            <Tooltip title={'Transfer To Other Department'}>
                <button className="btn" onClick={() => {
                    actionBtn()
                }}>
                    <img src={'../dist/icons/transfer-other.png'}/>
                </button>
            </Tooltip>
        </> : null
    )
}
const StudentVerificationCertificateBtn = (props) => {
    let {universityCountry, status, actionBtn} = props
    let {countryName} = universityCountry
    let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
    return (
        user && user.department == departmentObj.assDepartment && (countryName == 'Australia') && status == 'Waiting To Apply' ? <>
            <Tooltip title={'Student Verification'}>
                <button className="btn" onClick={() => {
                    actionBtn()
                }}>
                    <img src={'../dist/icons/verification.png'}/>
                </button>
            </Tooltip>
        </> : null
    )
}


const TransferApplicationToOtherDepartmentComponent = (props) => {
    let {
        application,
        visible,
        closeTransferDrawer,
        studentId,
        studentData,
        form: {getFieldDecorator, setFieldsValue, getFieldValue, resetFields}
    } = props
    let [state, setState] = useState(initialState)
    let [universityList, setUniversityList] = useState([])
    let [university1Course, setUniversity1Course] = useState([])
    let [university2Course, setUniversity2Course] = useState([])
    let [applications, setApplications] = useState([])
    const dispatch = useDispatch()
    const actionEvents = {
        loadUniversity: async () => {
            let countryFilter = {
                results: 1,
                select: ['countryName'],
                countryName: 'uk',
                regExFilters: ['countryName']
            }
            let countryResp = await dispatch(listAllCountries(countryFilter))
            if (countryResp && countryResp.data && countryResp.data.length) {
                let obj = {
                    results: 1000,
                    select: ['universityName', 'applicationFee'],
                    universityCountry: countryResp.data[0]._id,
                    regExFilters: ['universityName']
                }
                let {data} = await dispatch(listAllUniversities(obj))
                setUniversityList(data)
            }
        },
        loadUniversity1Course: async (universityId) => {
            let obj = {
                results: 1000,
                courseUniversity: universityId
            }
            let {data} = await dispatch(listAllCourse(obj))
            setUniversity1Course(data)
        },
        loadUniversity2Course: async (universityId) => {
            let obj = {
                results: 1000,
                courseUniversity: universityId
            }
            let {data} = await dispatch(listAllCourse(obj))
            setUniversity2Course(data)
        }
    }

    const formItemLayout = {
        labelCol: {
            xs: {span: 0},
            sm: {span: 0},
            md: {span: 0}
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 24},
            md: {span: 24}
        }
    }

    const events = {
        setDescription: (e) => {
            let {value, name} = e.target
            setState({...state, [name]: value})
        },
        setUserId: (value) => {
            setState({...state, userId: value})
        },
        transferApplication: async () => {
            let obj = {
                studentId,
                applicationId: application._id,
                courseUniversity: application.courseUniversity._id,
                application: applications
            }
            let {data, success, message} = await dispatch(transferApplicationToOtherDepartment(obj))
            if (success) {
                closeTransferDrawer()
            }
        },
        addApplication: async (e) => {
            const {form} = props
            e.preventDefault()
            form.validateFieldsAndScroll(async (err, valData) => {
                if (!err) {
                    if (valData.course1Id) {
                        let selectedCourse = _.find(university1Course, (item) => {
                            return item._id == valData.course1Id
                        })
                        if (selectedCourse) {
                            let findUniversity = _.find(universityList, (item) => {
                                return item._id == selectedCourse.courseUniversity._id
                            })
                            if (findUniversity) {
                                selectedCourse.universityName = findUniversity.universityName
                                selectedCourse.applicationFee = findUniversity.applicationFee
                            }
                            selectedCourse.courseUniversity = selectedCourse.courseUniversity._id
                            selectedCourse.countryName = selectedCourse.universityCountry.countryName
                            selectedCourse.universityCountry = selectedCourse.universityCountry._id
                            selectedCourse.courseId = selectedCourse._id
                            selectedCourse.description = valData.description

                            let cloneApplications = _.clone(applications)
                            let findApp = _.find(cloneApplications, (item) => {
                                return item.courseId == selectedCourse.courseId || selectedCourse.courseId == application.courseId._id
                            })
                            if (findApp) {
                                notification.warning({
                                    message: 'Course already exists.'
                                })
                            } else {
                                cloneApplications.push(selectedCourse)
                                setApplications(cloneApplications)
                                setFieldsValue({
                                    university1Id: '',
                                    course1Id: '',
                                    description: ''
                                })
                            }
                        }
                    } else {
                        notification.warning({
                            message: 'Please choose course'
                        })
                    }
                } else {
                    notification.warning({
                        message: 'Fill All Required Fields'
                    })
                }
            })

        },
        removeCourse: (courseId) => {
            let cloneApplications = _.clone(applications)
            cloneApplications = _.reject(cloneApplications, (item) => {
                return item.courseId == courseId
            })
            setApplications(cloneApplications)
        }
    }

    useEffect(() => {
        actionEvents.loadUniversity()
    }, [])

    let inputTypes = {
        fields: [
            {
                key: 'university1Id',
                label: 'Select University',
                type: 'select',
                showSearch: true,
                options: universityList,
                keyAccessor: x => x._id,
                valueAccessor: x => `${x.universityName}`,
                onChange: x => {
                    props.form.setFieldsValue({
                        university1Id: x,
                        course1Id: ''
                    })
                    actionEvents.loadUniversity1Course(x)
                },
                span: 12
            },
            {
                key: 'course1Id',
                label: 'Select Course',
                required: getFieldValue('university1Id'),
                type: 'select',
                showSearch: true,
                options: university1Course,
                keyAccessor: x => x._id,
                valueAccessor: x => `${x.courseName}`,
                onChange: x => {
                    props.form.setFieldsValue({
                        course1Id: x
                    })
                },
                span: 12
            },
            {
                key: 'description',
                label: 'Enter Note',
                placeholder: 'Enter Note',
                type: 'textarea',
                span: 22
            },
            {
                type: 'addBtn',
                span: 2,
                customField: (
                    <Button type="primary" htmlType="submit" className={'bs_btn bs-success'} style={{marginTop: 28}}>
                        Add
                    </Button>
                )
            }

            /*  {
                key: 'university2Id',
                label: 'Select University',
                type: 'select',
                showSearch: true,
                options: universityList,
                keyAccessor: x => x._id,
                valueAccessor: x => `${x.universityName}`,
                onChange: x => {
                  props.form.setFieldsValue({
                    university2Id: x,
                    course2Id: ''
                  })
                  actionEvents.loadUniversity2Course(x)
                },
                span: 12
              },
              {
                key: 'course2Id',
                label: 'Select Course',
                required: getFieldValue('university2Id'),
                type: 'select',
                showSearch: true,
                options: university2Course,
                keyAccessor: x => x._id,
                valueAccessor: x => `${x.courseName}`,
                onChange: x => {
                  props.form.setFieldsValue({
                    course2Id: x
                  })
                },
                span: 12
              }*/
        ]
    }
    let columns = [
        {
            title: 'University Name',
            key: 'universityName',
            dataIndex: 'universityName'
        },
        {
            title: 'Course Name',
            key: 'courseName',
            dataIndex: 'courseName'
        },

        {
            title: 'Note',
            key: 'description',
            dataIndex: 'description'
        },
        {
            title: 'Action',
            key: 'courseId',
            dataIndex: 'courseId',
            render: (item) => {
                return (
                    <a onClick={() => events.removeCourse(item)}><Icon type={'delete'}/></a>
                )
            }
        }
    ]

    return (
        <Drawer
            title={`Move student to Waiting to Apply Department`}
            visible={visible}
            onClose={closeTransferDrawer}
            width={'75%'}>
            <Card>
                <div className={'tableBox striped'}>
                    <Row>
                        <Col span={12}>
                            <RowTable title={'Student Name'} value={studentData.name}/>
                        </Col>
                        <Col span={12}>
                            <RowTable title={'Student Id'} value={studentData.studentId}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            {application.courseUniversity && application.courseUniversity.universityName ?
                                <RowTable title={'University Name'}
                                          value={application.courseUniversity.universityName}/> : null}
                        </Col>
                        <Col span={12}>
                            <RowTable title={'Course Name'} value={application.courseName}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            {application.assignedUserId && application.assignedUserId.name ?
                                <RowTable title={'Current User'} value={application.assignedUserId.name}/> : null}
                        </Col>
                        <Col span={12}>
                            <RowTable title={''} value={null}/>
                        </Col>
                    </Row>
                </div>
                <Form className={'vertical-form'} autoComplete="off" onSubmit={events.addApplication}>
                    <Row gutter={24}>
                        {inputTypes.fields.map((item, key) => {
                            return (
                                item.customField ?
                                    <Col span={item.span ? item.span : 24} md={item.span ? item.span : 24} sm={24}
                                         xs={24}>{item.customField} </Col> :
                                    <Col span={item.span ? item.span : 24} md={item.span ? item.span : 24} sm={24}
                                         xs={24}
                                         key={key}
                                         className={'mb10'}>
                                        <GetEachFormFields
                                            item={item}
                                            getFieldDecorator={getFieldDecorator}
                                            formItemLayout={formItemLayout}/>
                                    </Col>
                            )
                        })}
                        {/* <Col span={24}>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Transfer
                </Button>
              </Form.Item>
            </Col>*/}
                    </Row>
                </Form>
                <Table dataSource={applications} columns={columns} size={'small'} pagination={false}/>
            </Card>
            <div className={'drawerFooter alignRight'}>
                <Button type="primary" onClick={() => events.transferApplication()}>
                    Transfer
                </Button>
            </div>
        </Drawer>
    )

}
const TransferApplicationToOtherDepartment = Form.create()(TransferApplicationToOtherDepartmentComponent)

export {
    TransferApplicationToOtherDepartment,
    TransferToOtherDepartmentBtn,
    TransferToOtherDepartmentAustBtn,
    StudentVerificationCertificateBtn
}
