import React from 'react'
import {connect} from 'react-redux'
import {push} from 'connected-react-router'
import {LazyLoadImage} from 'react-lazy-load-image-component'

class FrontHeader extends React.Component {
    constructor() {
        super()
        this.state = {
            user: {},
            currentPath: ''
        }
    }

    openPage = (path) => {
        let {dispatch} = this.props
        if (path == 'login') {
            dispatch(push('/login'))
        } else {
            window.scrollTo(0, 0)
            dispatch(push(`/${path}`))
            // window.location.href = `/${path}`
            // dispatch(push(`/${path}`))
        }
    }

    componentDidMount() {
        let {pathname} = window.location
        let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
        let currentPath = pathname.replace('/', '')
        this.setState({user, currentPath})

    }

    render() {
        let {user, currentPath} = this.state
        return (
            <header>
                <nav className="navbar navbar-expand-md navbar-dark">
                    <div className="container">
                        <a className="navbar-brand" onClick={() => this.openPage('home')}><LazyLoadImage
                            src="../assets/image/logo.png" alt=""/></a>
                        <button className="navbar-toggler" type="button" data-toggle="collapse"
                                data-target="#navbarCollapse"
                                aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarCollapse">
                            <ul className="navbar-nav ml-auto exo-menu">
                                <li className={`nav-item ${currentPath == 'home' && 'active'}`}>
                                    <a className="nav-link" onClick={() => this.openPage('home')}>home</a>
                                </li>
                                <li className={`nav-item ${currentPath == 'unizhome' && 'active'}`}>
                                    <a className="nav-link" onClick={() => this.openPage('unizhome')}>UnizHome</a>
                                </li>
                                {/*<li className="nav-item dropdown">
                  <a className="nav-link" href="#">about us</a>
                </li>*/}
                                <li className={`nav-item ${currentPath == 'about-us' && 'active'}`}>
                                    <a className="nav-link" onClick={() => this.openPage('about-us')}>about us</a>
                                </li>
                                {/*<li className={`nav-item ${currentPath == 'gallery' && 'active'}`}>
                                    <a className="nav-link" onClick={() => this.openPage('gallery')}>gallery</a>
                                </li>*/}
                                <li className={`nav-item ${currentPath == 'webinars' && 'active'}`}>
                                    <a className="nav-link" onClick={() => this.openPage('webinars')}>webinars</a>
                                </li>
                                {/*
                                <li className={`nav-item ${currentPath == 'blogs' && 'active'}`}>
                                  <a className="nav-link" onClick={() => this.openPage('blogs')}>blog</a>
                                </li>*/}
                                <li className={`nav-item ${currentPath == 'contact-us' && 'active'}`}>
                                    <a className="nav-link" onClick={() => this.openPage('contact-us')}>contact</a>
                                </li>

                                <li className="nav-item">
                                    {user && user._id ?
                                        <button className="btn"
                                                onClick={() => this.openPage('dashboard')}>Welcome {user.name}</button> :
                                        <button className="btn" type="button"
                                                onClick={() => this.openPage('login')}>login</button>
                                    }

                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>

        )
    }
}


const mapStateToProps = ({global, router}) => ({
    loading: global.buttonLoading,
    pathname: router.location.pathname,
    router
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FrontHeader)
