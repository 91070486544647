import {Button, Col, DatePicker, Icon, Popconfirm, Row} from 'antd'
import moment from 'moment'
import React, {Suspense, useEffect, useRef, useState} from 'react'
import {useDispatch} from 'react-redux'
import {TableComp} from 'sz-react-utils-lite'
import {
    InputBox,
    newFormatDisplayDate
} from '../../../components/_utils/appUtils'
import {listAllTrainingRequest, updateStatusFxn} from './actions'
import AddNoteReqstTraing from './addNote'

const RequestForTrainingList = props => {
    const {RangePicker} = DatePicker
    let tableRef = useRef()
    const dispatch = useDispatch()
    const [visibleAddNote, setVisbleAddNote] = useState(false)
    const [id, setID] = useState('')

    const [fromDate, setFromDate] = useState(moment().startOf('day'))
    const [toDate, setToDate] = useState(moment().endOf('day'))
    const ranges = {
        Today: [moment().startOf('day'), moment().endOf('day')],
        'Last Week': [
            moment()
                .subtract(1, 'week')
                .startOf('week'),
            moment()
                .subtract(1, 'week')
                .endOf('week')
        ],
        'Last 15 Days': [moment().subtract(14, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')]
    }
    const updateStatus = async (_id, status) => {
        let resp = await dispatch(
            updateStatusFxn({trainingRequestId: _id, status: status})
        )
        if (resp && resp.success) {
            tableRef.current.reload()
        }
    }
    useEffect(
        () => {
            tableRef.current.reload()
        },
        [fromDate]
    )
    const apiRequest = async params => {
        return new Promise(async resolve => {
            if (fromDate) {
                let date = {}
                date.$gte = new Date(fromDate)
                date.$lte = new Date(toDate)
                params.date = date
            }

            let resp = await
                dispatch(
                    listAllTrainingRequest({
                        ...params
                        })
      )
                    resolve(resp)
                })
            }
  const events = {
    onCloseAddNote: () => {
      setVisbleAddNote(false)
      setID('')
      tableRef.current.reload()
    },
    openAddNote: record => {
      setVisbleAddNote(true)
      setID(record._id)
        }
    }
    const filterBlock = (
        <Row gutter={16}>
            <Col md={4} sm={4} xs={12} lg={4} key={fromDate}>
                <RangePicker
                    defaultValue={[fromDate, toDate]}
                    onChange={val => {
                        setFromDate(val[0])
                        setToDate(val[1])
                    }}
                    ranges={ranges}
                />
            </Col>
        </Row>
    )
    const columns = [
        {
            title: 'Sr .No',
            key: '_id',
            dataIndex: '_id',
            width: 80,
            render: (item, record, index) => {
                return <div style={{width: 50}}>{index + 1}</div>
            }
        },
        {
            title: 'Name',
            dataIndex: 'agentId',
            key: 'agentId',
            searchTextName: 'name',
            render: (val, record) => {
                return <p>{val.name}</p>
            }
        },
        {
            title: 'Company Name',
            dataIndex: 'companyName',
            key: 'companyName',
            searchTextName: 'companyName'
        },
        // {
        //   title: 'Agent Name',
        //   dataIndex: 'agentName',
        //   key: 'agentName',
        //   searchTextName: 'agentName'
        // },
        {
            title: 'Generated on',
            dataIndex: 'date',
            key: 'date',
            searchDateName: 'date',
            render: val => {
                return (
                    <div
                        // style={{ width: 90 }}
                    >
                        {newFormatDisplayDate(val)}
                    </div>
                )
            }
        },
        {
            title: 'Training Completion Date',
            dataIndex: 'trainingCompletionDate',
            key: 'trainingCompletionDate',

            render: val => {
                return (
                    <div
                        // style={{ width: 90 }}
                    >
                        {newFormatDisplayDate(val)}
                    </div>
                )
            }
        },

        {
            title: 'Status',
            key: 'status',
            dataIndex: 'status',
            width: 150,
            render: (val, record) => {
                return (
                    <React.Fragment>
                        <label
                            className={
                                val == 'Cancel'
                                    ? 'label label-warning label-sm'
                                    : val == 'Pending'
                                        ? 'label label-info label-sm'
                                        : val == 'Done'
                                            ? 'label label-success label-sm'
                                            : 'label'
                            }>
                            {val}
                        </label>
                    </React.Fragment>
                )
            }
        },
        {
            title: 'Action',
            dataIndex: 'status',
            key: 'status',
            render: (item, record) => {
                return (
                    <>
                        {item == 'Pending' ? (
                            <>
                               {/* <Popconfirm
                                    title={'Are you sure, you want to Cancel ?'}
                                    onConfirm={() => {
                                        updateStatus(record._id, 'Cancel')
                                    }}>
                                    <Button size={'small'}>Cancel</Button>
                                </Popconfirm>*/}
                                <Popconfirm
                                    title={'Are you sure, you want to update the Status?'}
                                    onConfirm={() => {
                                        updateStatus(record._id, 'Done')
                                    }}>
                                    <Button size={'small'} className={'ml10'}>
                                        Done
                                    </Button>
                                </Popconfirm>
                            </>
                        ) : null}<Button
                  key="3"
                  className={'roundSmallBtn2 ml10'}
                  onClick={() => events.openAddNote(record)}>
                  <Icon type={'plus'}/>
                  Notes ({record.notes ? record.notes.length : 0})
              </Button>
                    </>
                )
            }
        }
        // {
        //     title: 'Mobile No.',
        //     dataIndex: 'mobileNo',
        //     key: 'mobileNo',
        //     searchTextName: 'mobileNo'
        // },
        // {
        //     title: 'Notes',
        //     dataIndex: 'note',
        //     key: 'note',
        //     searchTextName: 'note'
        // }
    ]

    return (
        <>
            <div className="row mt-4">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="table-head d-flex align-items-center">
                            <h5>Request For Training List</h5>
                            <div className="search-box-table"/>
                            {/* <div className="sort-box-table mark-btn">
                                <button
                                    className="btn"
                                    onClick={() => addAgentReportDrawer(true)}>
                                    <img src={'../dist/icons/plus.png'} className={'plus'}/>
                                    Add Agent Report
                                </button>
                            </div> */}
                        </div>

                        <div className="card-body table-responsive ">

                            <Row gutter={16}>
                                <Col md={4} sm={4} xs={12} lg={6} key={fromDate}>
                                    <RangePicker
                                        defaultValue={[fromDate, toDate]}
                                        onChange={val => {
                                            setFromDate(val[0])
                                            setToDate(val[1])
                                        }}
                                        ranges={ranges}
                                    />
                                </Col>
                            </Row>
                            <TableComp
                                columns={columns}
                                apiRequest={apiRequest}
                                ref={tableRef}
                                />
                            </div>
                        {visibleAddNote && id ? (
              <AddNoteReqstTraing
                visible={visibleAddNote}
                onClose={() => events.onCloseAddNote()}
                trainingRequestId={id}
              />
            ) : null}
                    </div>
                </div>
            </div>
        </>
    )
}

export default RequestForTrainingList
