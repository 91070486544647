import React, {Suspense} from 'react'
import {connect} from 'react-redux'
import PageLoading from '../../../components/PageLoading'
import {departmentObj} from '../../../components/_utils/appUtils'
import {useDispatch} from "react-redux";

const WeeklyReport = React.lazy(() => import('../weeklyReport'))
const StudentDashboard = React.lazy(() => import('./studentDashboard'))
const MasterDashboard = React.lazy(() => import('./masterDashboard'))
const UserManagerDashboard = React.lazy(() => import('./userManagerDashboard'))
const UserDashboard = React.lazy(() => import('./userDashboard'))
const FcmtDashboard = React.lazy(() => import('./fcmtDashboard'))
const InterviewerDashboard = React.lazy(() => import('./interviewerDashboard'))
const WarningDashboard = React.lazy(() => import('./warningDashboard'))
const Fcmt2Dashboard = React.lazy(() => import('./fcmt2Dashboard'))
const OxfordDashboard = React.lazy(() => import('./oxford2Dashboard'))
const OxfordInterviewDashboard = React.lazy(() => import('./oxfordInterviewDashboard'))
const CambieLoaDashboard = React.lazy(() => import('./cambieLoaDashboard'))
const OnShoreDashboard = React.lazy(() => import('./onShoreDashboard'))
const Counsellor = React.lazy(() => import('../../counsellorDashboard/view'))
const Enrolled = React.lazy(() => import('../../enrolledDashboard'))
const ReceptionDashboard = React.lazy(() =>
    import('../../ReceptionDashboard/view/index')
)

const OnlineCountForAgentComponent = React.lazy(() =>
    import('../components/onlineCountForAgentComponent.js')
)
const MarketingDashboard = React.lazy(() => import('../marketingDashboard'))
// const AdminDashboard = React.lazy(() => import('../../UserDashboards/views/adminDashboard'))

const initialState = {
    ukData: {},
    canadaData: {}
}

const Dashboard = props => {
    let dispatch = useDispatch()
    let user = props.currentUser
    let {
        approveFCMTLoa,
        approveCambieLoa,
        showOnShoreAustralia,
        approveOxfordDraft,
        oxfordInterviewDepartment
    } = user
    let userList = [
        'master',
        'university',
        'student',
        'branchUser',
        'reception',
        'marketingManager'
    ]

    let counsellingFilter = (
        <>
            {user.counsellorType == 'Counsellor' ? (
                <Suspense fallback={<PageLoading/>}>
                    <Counsellor/>
                </Suspense>
            ) : (
                <Suspense fallback={<PageLoading/>}>
                    <Enrolled/>
                </Suspense>
            )}
        </>
    )

    let warningFilter = (
        <>
            {user.userType == 'branchUser' ? (
                user.department == departmentObj.warningDepartment ? (
                    <Suspense fallback={<PageLoading/>}>
                        <WarningDashboard/>
                    </Suspense>
                ) : user.department == departmentObj.counselling ? (
                    <Suspense fallback={<PageLoading/>}>{counsellingFilter}</Suspense>
                ) : (
                    <Suspense fallback={<PageLoading/>}>
                        <UserDashboard/>
                    </Suspense>
                )
            ) : null}
        </>
    )

    let interviewFilter = (
        <>
            {user.userType == 'branchUser' ? (
                approveFCMTLoa ? (
                    <Suspense fallback={<PageLoading/>}>
                        <Fcmt2Dashboard/>
                    </Suspense>
                ) : approveCambieLoa ? (
                    <Suspense fallback={<PageLoading/>}>
                        <CambieLoaDashboard/>
                    </Suspense>
                ) : showOnShoreAustralia ? (
                    <Suspense fallback={<PageLoading/>}>
                        <OnShoreDashboard/>
                    </Suspense>
                ) : approveOxfordDraft ? (
                    <Suspense fallback={<PageLoading/>}>
                        <OxfordDashboard/>
                    </Suspense>
                ) : oxfordInterviewDepartment ? (
                    <Suspense fallback={<PageLoading/>}>
                        <OxfordInterviewDashboard/>
                    </Suspense>
                ) : user.department == departmentObj.interviewer ? (
                    <Suspense fallback={<PageLoading/>}>
                        <InterviewerDashboard/>
                    </Suspense>
                ) : (
                    warningFilter
                )
            ) : null}
        </>
    )

    let dashboardFilter = (
        <div>

        {user.userType == 'student' && (
                <Suspense fallback={<PageLoading/>}>
                    <StudentDashboard/>
                </Suspense>
            )}
            {user.userType == 'master' && (
                <Suspense fallback={<PageLoading/>}>
                    <MasterDashboard/>
                </Suspense>
            )}
            {/*{user.userType == 'userManager' && <Suspense fallback={<PageLoading />}><UserManagerDashboard /></Suspense>}*/}
            {user.userType == 'university' && (
                <Suspense fallback={<PageLoading/>}>
                    <FcmtDashboard/>
                </Suspense>
            )}
            {interviewFilter}
            {user.userType == 'reception' && (
                <Suspense fallback={<PageLoading/>}>
                    <ReceptionDashboard/>
                </Suspense>
            )}
            {user.userType == 'marketingManager' && user.email == "varun@unizportal.com" ? (
                <Suspense fallback={<PageLoading/>}>
                    <WeeklyReport/>
                </Suspense>
            ) : null}
            {user.userType == 'marketingManager' && (
                <Suspense fallback={<PageLoading/>}>
                    <MarketingDashboard/>
                </Suspense>
            )}
            {userList.includes(user.userType) ? null : (
                <Suspense fallback={<PageLoading/>}>
                    <UserDashboard/>
                </Suspense>
            )}
            {/* adding codes */}
        </div>
    )

    return <>
        {user && (user.userType !== 'admin' && user.userType !== 'student') ? (
            <Suspense fallback={<></>}>
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <OnlineCountForAgentComponent dispatch={dispatch}/>
                    </div>
                </div>
            </Suspense>
        ) : null}
        {dashboardFilter}
    </>
}

const mapStateToProps = ({counter, global}) => ({
    count: counter.count,
    loading: global.buttonLoading,
    currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Dashboard)
