import React, {useEffect, useRef, useState} from 'react'
import {
    Drawer,
    Select,
    Button,
    Tooltip,
    Avatar,
    Icon,
    Popconfirm,
    Row,
    Col, notification, Form
} from 'antd'
import {connect, useDispatch, useSelector} from 'react-redux'
import {
    getSelectedCountryApplicationsApi,
    exportApplicationsList,
    exportAllApplicationsList
} from '../actions/chooseStudent'
import {
    statusColors, filterOption, newFormatDisplayDate, defaultLogoList, longDisplayDate, departmentObj, displayDate
} from '../../../components/_utils/appUtils'
import {getPushPathWrapper, getUrlPushWrapper} from '../../../routes'
import {TableComp} from 'sz-react-utils-lite'
import SelectPendency from '../views/selectPendency'
import lodash from 'lodash'
import {AllowComponentRightsWise, CheckBranchUserFxn} from '../../WebComponent/allowComponentRightsWise'
import {getPendingPendency} from '../../../components/_utils/utils'
import {CheckUserRight} from '../../dashboard/views/DashboardUserWise'
import {
    applicationCloseDirect,
    reCalculateCommission, updateApplicationTuitionFees
} from '../../student/actions/student'
import moment from 'moment'
import StudentOtherApp from '../views/StudentOtherApplication'
import {exportUniversityList, listAllUniversities} from '../../university/actions/university'
import {listAllUsers} from '../../users/actions/user'
import ChangeUniversityComponent from '../drawers/changeUniversity'
import AddNote from '../drawers/applicationAddNoteComponent'
import {listAllCountries} from '../../countries/actions/countries'
import {
    TransferToOtherDepartmentBtn,
    TransferToOtherDepartmentAustBtn,
    TransferApplicationToOtherDepartment,
    StudentVerificationCertificateBtn
} from './transferApplicationToOtherDepartment'
import TransferAustraliaApplication from "../drawers/transferAustraliaApplication";
import StudentVerificationDrawerAust from "../drawers/StudentVerificationDrawerAust";

const {Option} = Select
const initialState = {
    applicationList: [],
    applicationObj: {}
}

const commissionObj = {
    commissionAmount: 0,
    tuitionFee: 0,
    studentId: '',
    applicationId: ''
}

const RenderComment = (props) => {
    let {item} = props
    let [hideV, setHideV] = useState(true)
    return (
        item && <div style={{marginBottom: 10}}>
            {item.addedByUserId && item.addedByUserId.name ?
                <div className={'alignRight colorPrimary font12'}>
                    (Added By : {item.addedByUserId.name} )
                </div> : null}
            <div className={hideV ? 'appCommentDotsReplace' : ''}>
                {item && item.text ? item.text : ''}
            </div>
            {item && item.text.length && item.text.length > 50 ? <div className={'alignRight'}>
                {hideV ? <a onClick={() => {
                    setHideV(false)
                }}>Show More</a> : <a onClick={() => {
                    setHideV(true)
                }}>Show Less</a>}
            </div> : ''}

        </div>
    )
}
const AssessmentApplications = (props) => {
    let {onClose, studentObj = {}, reloadTable} = props
    const [state, setState] = useState(initialState)
    const [transferAppVisible, setTransferAppVisible] = useState(false)
    const [studentData, setStudentData] = useState({})
    const [visibleAddCourseDrawer, setVisibleAddCourseDrawer] = useState(false)
    const [addPendencyDrawer, setAddPendencyDrawer] = useState(false)
    const [name, setName] = useState('')
    const [dateOfBirth, setDateOfBirth] = useState('')
    const [allAgent, setAllAgent] = useState([])
    const [agentId, setAgentId] = useState('')
    const [universityList, setUniversityList] = useState([])
    const [appUniversityId, setAppUniversityId] = useState('')
    const [visibleOtherAppDrawer, setVisibleOtherAppDrawer] = useState(false)
    const [visibleAddCommentDrawer, setVisibleAddCommentDrawer] = useState(false)
    const [totalApplication, setTotalApplication] = useState(0)
    const [applicationList, setApplicationList] = useState([])
    const [visibleFeesModal, setVisibleFeesModal] = useState(false)
    const [feesObj, setFeesObj] = useState(commissionObj)
    let {applicationObj} = state
    let [tableSearch, setTableSearch] = useState(false)
    let [filters, setFilters] = useState({})
    let [universityCountry, setUniversityCountry] = useState('')

    let [changeUniversity, setChangeUniversity] = useState({
        visible: false,
        studentId: '',
        application: '',
        studentData: {}
    })
    const [transferToOtherDepartmentVisible, setTransferToOtherDepartmentVisible] = useState(false)
    const [transferToOtherDepartmentAustraliaVisible, setTransferToOtherDepartmentAustraliaVisible] = useState(false)


    let [verificationState, setVerificationState] = useState({
        visible: false,
        studentId: '',
        application: {},
    })

    const dispatch = useDispatch()
    let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;

    const tableAppRef = useRef()
    let viewOnlyRight = CheckBranchUserFxn()
    let apiRequest = (params) => {
        return new Promise(async (resolve) => {
            let resp = await getParamsForApplicationList()
            params = {...params, ...resp}
            params.status = 'Waiting To Apply'
            if (!params.results) {
                params.results = 50
                params.count = 50
            }
            if (tableSearch) {
                params.page = 1
            }
            try {
                setFilters(params)
                let resp = await dispatch(getSelectedCountryApplicationsApi({...params, regExFilters: ['name']}))

                setTotalApplication(resp.total)
                setApplicationList(resp.data)
                setTableSearch(false)
                resolve(resp)
            } catch (e) {
            }

        })
    }
    let exportExcel = async () => {
        let params = {}
        let resp = await getParamsForApplicationList()
        params = {...params, ...resp}
        if (name) {
            params.name = name
        }
        if (dateOfBirth) {
            params.dateOfBirth = moment(dateOfBirth)
        }

        params.count = totalApplication
        let {success, filePath, message} = await dispatch(exportApplicationsList(params))
        if (success) {
            notification.success({message: message})
            // window.open(`${apiUrl}${filePath}`, 'Download')
        } else {
            notification.error({message: message})
        }
    }

    // const exportUniversityFxn = async () => {
    //   let params = { ...filters }
    //   params.regExFilters = ['universityType', 'universityName']
    //   params.results = totalUniversities
    //   params.count = totalUniversities
    //   let respE = await dispatch(exportUniversityList(params))
    //   if (respE && respE.filePath) {
    //     window.open(respE.filePath, '_blank')
    //   } else {
    //     notification.warning({ message: 'Error, please try again' })
    //     return
    //   }
    // }

    // const { totalUniversities } = useSelector((state) => ({
    //   totalUniversities: state.universityReducers.totalUniversities
    // }))

    useEffect(() => {
        loadAgentData()
        loadUniversityNameList()
        setFieldByParams()
    }, [])

    const setFieldByParams = async () => {
        let resp = await getParamsForApplicationList()
        if (resp.name) {
            setName(resp.name)
        }
        if (resp.agentId) {
            setAgentId(resp.agentId)
        }
        if (resp.appUniversityId) {
            setAppUniversityId(resp.appUniversityId)
        }
    }

    const loadAgentData = async () => {
        let params = {
            results: 15000,
            userType: 'agent',
            sortField: 'companyName',
            sortOrder: 'ascend',
            select: ['name', 'companyName']
        }
        let {data} = await dispatch(listAllUsers(params))
        if (data && data.length) {
            setAllAgent(data)
        }
    }
    const loadUniversityNameList = async () => {
        let params = {
            results: 5000,
            select: ['universityName'],
            sortField: 'universityName',
            sortOrder: 'ascend'
        }
        let resp = await getParamsForApplicationList()

        params.universityCountry = resp.countryId

        let {data} = await dispatch(listAllUniversities(params))
        if (data && data.length) {
            setUniversityList(data)
        }
    }

    const events = {
        openAddPendencyDrawer: (value) => {
            setState({
                ...state,
                applicationObj: value
            })
            setAddPendencyDrawer(true)
        },
        closeAddPendencyDrawer: () => {
            setState({
                ...state,
                applicationObj: {}
            })
            setAddPendencyDrawer(false)
        },
        reloadFxn: () => {
            tableAppRef.current.reload()
        },
        showAddCourseDrawer: () => {
            setVisibleAddCourseDrawer(true)
        },
        hideAddCourseDrawer: () => {
            setVisibleAddCourseDrawer(false)
            tableAppRef.current.reload()
        },
        refreshApplication: () => {
            tableAppRef.current.reload()
        },

        closeDirectApp: async (record) => {
            let obj = {
                studentId: record._id,
                applicationId: record.applications._id
            }
            let resp = await dispatch(applicationCloseDirect(obj))
            tableAppRef.current.reload()
        },

        enterName: (value) => {
            setName(value)
            /*if(!value){
              setTimeout(()=>{
                tableAppRef.current.reload()
              },500)
            }*/
        },
        openOtherAppDrawer: (value) => {
            setState({
                ...state,
                applicationObj: value
            })
            setVisibleOtherAppDrawer(true)
        },
        closeOtherAppDrawer: () => {
            setState({
                ...state,
                applicationObj: {}
            })
            setVisibleOtherAppDrawer(false)
        },
        openAddCommentDrawer: (value) => {
            setState({
                ...state,
                applicationObj: value
            })
            setVisibleAddCommentDrawer(true)
        },
        closeAddCommentDrawer: () => {
            setState({
                ...state,
                applicationObj: {}
            })
            setVisibleAddCommentDrawer(false)
        },
        hideVisibleFeesModal: () => {
            setVisibleFeesModal(false)
        },
        setCommissionLocal: (data) => {
            setFeesObj({
                ...feesObj,
                ...data
            })
        },
        reCalculateCommissionFxn: async () => {
            let {commissionAmount} = await dispatch(reCalculateCommission(feesObj))
            if (commissionAmount) {
                events.setCommissionLocal({commissionAmount})
            }

        },
        updateApplicationTuitionFeesFxn: async () => {
            let {success, message} = await dispatch(updateApplicationTuitionFees(feesObj))
            if (success) {
                setFeesObj(commissionObj)
                setVisibleFeesModal(false)
                events.reloadFxn()
            }
        },
        showTransferToOtherDepartment: () => {
            setTransferToOtherDepartmentVisible(true)
        },
        hideTransferToOtherDepartment: () => {
            setTransferToOtherDepartmentVisible(false)
            tableAppRef.current.reload()
        },
        showTransferToOtherDepartmentForAustralia: () => {
            setTransferToOtherDepartmentAustraliaVisible(true)
        },
        hideTransferToOtherDepartmentForAustralia: () => {
            setTransferToOtherDepartmentAustraliaVisible(false)
            tableAppRef.current.reload()
        },
        openApplication: (record) => {
            if (record.applications && record.applications.openApplicationMailSend) {

            } else {

            }
        }
    }

    const changeUniversityFxn = (record) => {
        setChangeUniversity({
            visible: true,
            studentId: record._id,
            studentData: record,
            application: record.applications
        })
    }
    const onCloseChangeUniversityFxn = () => {
        setChangeUniversity({
            visible: false,
            studentId: '',
            studentData: {},
            application: {}
        })
        tableAppRef.current.reload()
    }
    const getParamsForApplicationList = () => {
        return new Promise(async (resolve) => {
            let searchParams = new URLSearchParams(window.location.search)
            let agentId = searchParams.get('agentId')
            let name = searchParams.get('name')
            let appUniversityId = searchParams.get('appUniversityId')

            let obj = {}
            let {data} = await dispatch(listAllCountries({
                results: 1,
                regExFilters: ['countryName'],
                // countryName: 'uk',
                _id: user.countryId,
                select: ['countryName']
            }))
            if (data && data.length) {
                obj.countryId = data[0]._id
            }
            if (appUniversityId) {
                obj.appUniversityId = appUniversityId
            }
            if (agentId) {
                obj.agentId = agentId
            }
            if (name) {
                obj.name = name
            }
            resolve(obj)
        })
    }
    const searchFxn = async () => {
        let resp = await getParamsForApplicationList()
        let obj = {}
        if (name) {
            obj.name = name
        }
        if (agentId) {
            obj.agentId = agentId
        }
        if (appUniversityId) {
            obj.appUniversityId = appUniversityId
        }
        dispatch(
            getUrlPushWrapper('assessmentDepartment', {
                ...obj
            }))
        setTableSearch(true)
        setTimeout(() => {
            tableAppRef.current.reload()
        }, 300)
    }
    const clearFxn = async () => {
        let resp = await getParamsForApplicationList()
        let obj = {}
        setAgentId('')
        setName('')
        setAppUniversityId('')
        dispatch(
            getUrlPushWrapper('assessmentDepartment', {
                ...obj
            }))
        setTableSearch(true)
        setTimeout(() => {
            tableAppRef.current.reload()
        }, 200)
    }

    const columns = [

        {
            title: 'App#',
            key: 'applications.id',
            dataIndex: 'applications.id',
            width: 60,
            render: (item) => {
                return (
                    <div style={{width: 60}}>
                        {item}
                    </div>
                )
            }
        },
        {
            title: 'Name',
            key: 'name',
            dataIndex: 'name',
            render: (item, record) => {
                return (
                    <>
                        <Tooltip title={'Edit Student'}>
                            <a className={'linkAA'}
                               onClick={() => dispatch(
                                   getPushPathWrapper('student.editStudent', {id: record._id}))
                               }>
                                {item} <Icon type={'edit'}/>
                            </a><br/>
                        </Tooltip>
                        ({record.mobile})
                    </>
                )
            }
        },
        {
            title: 'DOB',
            dataIndex: 'dateOfBirth',
            key: 'dateOfBirth',
            width: 90,
            render: (val) => {
                return (
                    <div style={{width: 90}}>
                        {newFormatDisplayDate(val)}
                    </div>
                )
            }
        },
        {
            title: 'Status',
            key: 'applications.status',
            dataIndex: 'applications.status',
            width: 80,
            render: (item, record) => {
                return (
                    <React.Fragment>
                        <div className={'label label1'}
                             style={{background: statusColors(item), width: 80, padding: 3}}>{item}</div>
                    </React.Fragment>
                )
            }
        },
        {
            title: 'University',
            key: 'applications.courseUniversity',
            dataIndex: 'applications.courseUniversity',
            render: (item, record) => {
                return (
                    <React.Fragment>
                        {item && item.logo && item.logo.url ?
                            <Avatar src={item.logo.url} size={20}/>
                            : <Avatar src={defaultLogoList.defaultUniversityLogo} size={20}/>}
                        {item.universityName ? `  ${item.universityName}` : ''}
                    </React.Fragment>
                )
            }
        },
        {
            title: 'Country',
            key: 'applications.universityCountry',
            dataIndex: 'applications.universityCountry',
            render: (item, record) => {
                return (
                    <React.Fragment>
                        {item && item.countryName ? item.countryName : null}
                    </React.Fragment>
                )
            }
        },
        {
            title: 'Program',
            key: 'applications.courseName',
            dataIndex: 'applications.courseName',
            width: 150,
            render: (item) => {
                return (
                    <div style={{width: 150}}>
                        {item}
                    </div>
                )
            }
        },
        {
            title: 'Intake',
            key: 'applications.intake',
            dataIndex: 'applications.intake',
            render: (item, record) => {
                return (
                    <React.Fragment>
                        {item && item.month && item.year ? `${item.month}, ${item.year}` : ''}
                    </React.Fragment>
                )
            }
        },
        {
            title: 'Pendency',
            key: 'applications.pendencyArr',
            dataIndex: 'applications.pendencyArr',
            // width: 100,
            render: (item, record) => {
                return (
                    <React.Fragment>
                        <Tooltip title={'Add Pendency'}>
              <span onClick={() => events.openAddPendencyDrawer(record)}
                    className='badge'>{item && item.length ? getPendingPendency(item) : 0}</span>


                            {/*   <Avatar style={{ backgroundColor: '#1890ff', verticalAlign: 'middle' }} className={'pointerCls'}
                      onClick={() => events.openAddPendencyDrawer(record)}>
                {item && item.length ? getPendingPendency(item) : 0}
              </Avatar>*/}
                        </Tooltip>{' '}


                    </React.Fragment>
                )
            }
        },
        {
            title: 'Current User',
            key: 'applications.assignedUserId',
            dataIndex: 'applications.assignedUserId',
            width: 180,
            render: (val, record) => {
                let {agentId, statusList, status} = record.applications
                return (
                    <React.Fragment>
                        {val && val.name ? val.name : ''}<br/>
                        {user.department == departmentObj.assDepartment && user.managerViewOnly ? null :
                            <div>Agent : {agentId && agentId.companyName ? agentId.companyName : ''}</div>}
                        <div>{longDisplayDate(statusList.date)}</div>

                    </React.Fragment>
                )
            }
        },
        {
            title: 'Latest Note',
            key: 'applications.latestComment',
            dataIndex: 'applications.latestComment',
            width: 150,
            render: (val) => {
                return (
                    <div style={{width: 150}}>
                        {val && val.text ? <RenderComment item={val}/> : null}
                        {val && val.followUpDate ? <span className={'followUp'} style={{fontSize: 12}}><span
                            style={{fontWeight: 700}}>Follow Up</span>: {displayDate(val.followUpDate)}</span> : null}

                    </div>
                )
            }
        },

        {
            title: 'Verification',
            key: 'applications.verificationCertificate',
            dataIndex: 'applications.verificationCertificate',
            render: (item, record) => {
                return (
                    <React.Fragment>
                        {item ? <label className={'label label-warning label-sm'}>{item}</label> : ""}
                    </React.Fragment>
                )
            }
        },
        {
            title: 'Action',
            key: 'action',
            dataIndex: 'action',
            width: 100,
            render: (val, record) => {
                let {applications} = record
                let {assignedUserId} = applications
                return (
                    <div className={'actionBtnGroup'}>
                        {viewOnlyRight ? <>
                            <div>

                                <AllowComponentRightsWise rightUserType={['admin', 'branchUser', 'branchManager']}>
                                    {!record.applications.directClosed && <Tooltip title={'View Application'}>
                                        <a className='btn'
                                           href={`/student/application?studentId=${record._id}&appId=${record.applications._id}`}
                                        ><img src='/dist/img/view.svg' alt=''/></a>
                                    </Tooltip>}

                                    {record.applications.status !== 'Case Close' &&
                                    <Tooltip title={'Case Closed'}>
                                        <Popconfirm title={'Are your sure, you want to Close Case?'}
                                                    onConfirm={() => {
                                                        events.closeDirectApp(record)
                                                    }}
                                                    okText='Yes' cancelText='No'>
                                            <button className='btn'><img src='dist/img/cross.svg' alt=''
                                                                         className={'crossBtn'}/></button>
                                        </Popconfirm>
                                    </Tooltip>}


                                    <Tooltip title={'View Other Application'}>
                                        <button className='btn' onClick={() => {
                                            events.openOtherAppDrawer(record)
                                        }}><Icon type={'ordered-list'}/></button>
                                    </Tooltip>
                                </AllowComponentRightsWise>

                            </div>

                            <TransferToOtherDepartmentBtn
                                universityCountry={applications.universityCountry}
                                status={applications.status}
                                actionBtn={() => {
                                    setStudentData(record)
                                    events.showTransferToOtherDepartment()
                                }}/>

                            <StudentVerificationCertificateBtn
                                universityCountry={applications.universityCountry}
                                status={applications.status}
                                actionBtn={() => {
                                    verificationEvents.showDrawer(record)
                                }}/>

                            <TransferToOtherDepartmentAustBtn
                                universityCountry={applications.universityCountry}
                                status={applications.status}
                                actionBtn={() => {
                                    setStudentData(record)
                                    events.showTransferToOtherDepartmentForAustralia()
                                }}/>

                            <Tooltip title={'Change University'}>
                                <button className='btn' onClick={() => changeUniversityFxn(record)}>
                                    <img src={'/assets/shuffle-arrows.png'}/>
                                </button>
                            </Tooltip>
                        </> : null}

                        <Tooltip title={'Add Note'}>
                            <button className='btn' onClick={() => {
                                events.openAddCommentDrawer(record)
                            }}><Icon type={'plus'}/></button>
                        </Tooltip>


                        {assignedUserId && assignedUserId._id != user._id ?
                            <Tooltip title={'View Application'}>
                                <a className='btn'
                                   href={`/application/view?appId=${record.applications._id}&studentId=${record._id}`}
                                ><img src='/dist/img/view.svg' alt='' className={'eyeBtn'}/></a>
                            </Tooltip> : null
                        }

                    </div>
                )
            }
        }

    ]

    const resp = useSelector(state => {
        let {loadNoteRedux} = state.chooseApplication
        if (loadNoteRedux) {
            if (tableAppRef && tableAppRef.current) {
                tableAppRef.current.reload()
            }
            dispatch({type: 'END_LOAD_NOTE'})
        }
    })

    const loadColumns = () => {
        let columnArr = []
        lodash.each(columns, (item) => {
            if (item.hidden == undefined) {
                columnArr.push(item)
            } else {
                if (!item.hidden) {
                    columnArr.push(item)
                }
            }
        })
        return columnArr
    }

    const extra = (
        <Row gutter={16}>
            <Row gutter={12}>
                <Col md={8} sm={6} xs={8} lg={6}>
                    <div className='search-box-table round'>
                        <input className='form-control form-control' type='search'
                               placeholder='Search by name...' value={name} onChange={(e) => {
                            events.enterName(e.target.value)
                        }}/>
                        <img src='/dist/img/search.png' alt=''/>
                    </div>

                </Col>
                <CheckUserRight user={user} rightUserType={['admin', 'branchManager', 'branchUser']}>
                    <Col md={8} sm={6} xs={8} lg={6}>
                        <div className='sort-box-table custom-sort-box-new'>
                            <Select name='Search By Agent' onChange={(item) => setAgentId(item)} value={agentId}
                                    filterOption={filterOption}
                                    className={'antSelect'}
                                    allowClear={true}
                                    showSearch={true}>
                                <Option value={''}>Search By Agent</Option>
                                {allAgent && allAgent.length ? allAgent.map((item, key) => {
                                    return (
                                        <Option value={item._id} key={key}>{item.companyName}</Option>
                                    )
                                }) : null}
                            </Select>
                        </div>
                    </Col>
                </CheckUserRight>
                <Col md={8} sm={6} xs={8} lg={6}>
                    <div className='sort-box-table custom-sort-box-new'>
                        <Select name='Search By University' onChange={(item) => setAppUniversityId(item)}
                                filterOption={filterOption}
                                className={'antSelect'}
                                allowClear={true}
                                showSearch={true}
                                value={appUniversityId}>
                            <Option value={''}>Search By University</Option>
                            {universityList && universityList.length ? universityList.map((item, key) => {
                                return (
                                    <Option value={item._id} key={key}>{item.universityName}</Option>
                                )
                            }) : null}
                        </Select>
                    </div>
                </Col>
                <Col md={12} sm={12} xs={12} lg={6}>
                    <Button onClick={() => searchFxn()} className={'roundBtn'}>Search</Button>
                    <Button onClick={() => clearFxn()} className={'roundBtn'}>
                        Clear
                    </Button>
                </Col>
            </Row>

        </Row>
    )

    const verificationEvents = {
        showDrawer: (record) => {
            setVerificationState({
                visible: true,
                studentId: record._id,
                application: record.applications
            })
        },
        hideDrawer: () => {
            setVerificationState({
                visible: false,
                studentId: "",
                application: {}
            })
        },

    }

    return (
        <React.Fragment>
            <div>
                <div className='row mt-4'>
                    <div className='col-lg-12'>
                        <div className='card'>
                            <div className='table-head d-flex align-items-center all-student-search'
                                 style={{height: 60}}>
                                <Button onClick={() => dispatch(getUrlPushWrapper('dashboard'))}
                                        className={'roundGoBackBtn'} icon={'arrow-left'}>
                                    Back
                                </Button>
                                <h5>All Application </h5>
                                <div className='search-box-table' style={{backgroundColor: 'transparent'}}>
                                    (Total - {totalApplication})
                                    {' '}
                                </div>
                                <div className='sort-box-table mark-btn'>
                                    <a className='btn' onClick={() => exportExcel()}> Export</a>
                                </div>
                            </div>
                            <div className='card-body table-responsive'>
                                {extra}
                                <TableComp columns={loadColumns()}
                                           rowKey={(item, index) => {
                                               return index
                                           }}
                                           apiRequest={apiRequest}
                                           pagination={{
                                               showSizeChanger: true,
                                               pageSizeOptions: ['50', '100', '200', '500'],
                                               defaultPageSize: 50,
                                               current: filters.page ? filters.page : 1
                                           }}
                                           ref={tableAppRef}
                                />
                            </div>
                        </div>
                    </div>
                </div>


                {addPendencyDrawer ?
                    <SelectPendency visible={addPendencyDrawer}
                                    applicationObj={state.applicationObj}
                                    onAddPendencyClose={events.closeAddPendencyDrawer}
                                    reloadFxn={events.reloadFxn}
                    /> : null}
            </div>


            {visibleOtherAppDrawer ?
                <StudentOtherApp visible={visibleOtherAppDrawer}
                                 applicationObj={state.applicationObj}
                                 onStudentOtherAppClose={() => events.closeOtherAppDrawer()}
                                 reloadFxn={events.reloadFxn}
                /> : null}

            {changeUniversity.visible ?
                <ChangeUniversityComponent {...changeUniversity} onClose={onCloseChangeUniversityFxn}/> : null}

            {visibleAddCommentDrawer && applicationObj && applicationObj._id ?
                <AddNote visible={visibleAddCommentDrawer} studentData={applicationObj}
                         addedType={'direct'}
                         reloadFxn={events.reloadFxn}
                         onClose={() => events.closeAddCommentDrawer()}
                         applicationId={applicationObj.applications._id}/> : null}

            {transferToOtherDepartmentVisible ?
                <TransferApplicationToOtherDepartment visible={transferToOtherDepartmentVisible}
                                                      closeTransferDrawer={() => events.hideTransferToOtherDepartment()}
                                                      studentId={studentData._id}
                                                      departmentWise={true}
                                                      studentData={studentData}
                                                      application={studentData.applications}
                /> : null}

            {transferToOtherDepartmentAustraliaVisible ?
                <TransferAustraliaApplication visible={transferToOtherDepartmentAustraliaVisible}
                                              closeTransferDrawer={() => events.hideTransferToOtherDepartmentForAustralia()}
                                              studentId={studentData._id}
                                              departmentWise={true}
                                              studentData={studentData}
                                              application={studentData.applications}
                /> : null}

            {verificationState.visible ?
                <StudentVerificationDrawerAust
                    {...verificationState}
                    onClose={() => {
                        verificationEvents.hideDrawer()
                    }}
                    reloadApp={() => {
                        events.reloadFxn()
                    }}
                />
                : null}


        </React.Fragment>
    )
}
const mapStateToProps = ({global, router, chooseApplication}) => ({})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}
const WrappedComponent = Form.create()(AssessmentApplications)
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WrappedComponent)

