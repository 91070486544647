import React, {useEffect, useState} from 'react'
import {Button, Card, Drawer, Form, Input, Row, Col, Select, notification, DatePicker} from 'antd'
import {RowTable} from '../../../components/_utils/RowTable'
import {FormUtils as GetAllFormFields} from 'sz-react-utils-lite'
import {InputBox, displayDate} from '../../../components/_utils/appUtils'
import {RequestLoaApproved} from '../actions/chooseStudent'
import {useDispatch} from 'react-redux'

const royalArtsApprovedLoaRequest = (props) => {
    let dispatch = useDispatch()
    let {visible, onClose, studentId, applicationId, reloadTable, studentData} = props
    let [familyName, setFamilyName] = useState('')
    let [givenName, setGivenName] = useState('')
    let [scholarship, setScholarship] = useState('No')
    let [scholarshipInfo, setScholarshipInfo] = useState('')
    let [invoiceNumber, setInvoiceNumber] = useState('')
    let [invoiceDate, setInvoiceDate] = useState('')
    let [internship, setInternship] = useState('No')
    let [internshipInfo, setInternshipInfo] = useState('')
    // let [studentIdNo, setStudentIdNo] = useState('')

    useEffect(() => {
        if (studentData && studentData.applications && studentData.applications.familyName) {
            setFamilyName(studentData.applications.familyName)
        }
    }, [])
    let handleSubmit = async () => {
        // studentIdNo = studentIdNo.trim()
        familyName = familyName.trim()
        givenName = givenName.trim()
         if (!givenName) {
             return notification.warn({message: 'Enter given name'})
         }
        if (scholarship == 'Yes' && !scholarshipInfo) {
            return notification.warn({message: 'Enter Scholar ship info.'})
        }
        if (internship == 'Yes' && !internshipInfo) {
            return notification.warn({message: 'Enter inters ship info.'})
        }
        /*  if (!invoiceNumber) {
              return notification.warn({message: 'Enter invoice number'})
          }

          if (!invoiceDate) {
              return notification.warn({message: 'Choose invoice date'})
          }*/


        let obj = {
            studentId, familyName, applicationId, givenName, scholarship, internship, invoiceNumber, invoiceDate,
            internshipInfo, scholarshipInfo
        }

        let resp = await dispatch(RequestLoaApproved(obj))
        if (resp && resp.success) {
            onClose()
            reloadTable()
            // setStudentIdNo('')
            setFamilyName('')
            setGivenName('')
            setScholarship('')
            setInvoiceNumber('')
            setInvoiceDate('')
            setInternship('')
            setScholarshipInfo('')
            setInternshipInfo('')
        }
    }
    return (
        <Drawer visible={visible}
                title={` Royal Arts Approved LOA Rqst`}
                width={'60%'}
                closable={true}
                maskClosable={true}
                onClose={onClose}>
            <Card>
                {studentData && studentData.applications && studentData.applications.id ?
                    <div className={'tableBox striped'}>
                        <RowTable title={'Name (ID)'}
                                  value={<span>{studentData.name} ({studentData.studentId})</span>}/>
                        <RowTable title={'Date Of Birth'} value={<span>{displayDate(studentData.dateOfBirth)}</span>}/>
                        <RowTable title={'Phone'} value={<span>{studentData.countryCode} {studentData.mobile}</span>}/>
                        <RowTable title={'Address'} value={<span>{studentData.address}</span>}/>
                        <RowTable title={'Country'} value={<span>{studentData.countryName}</span>}/>
                        <RowTable title={'State'} value={<span>{studentData.stateName}</span>}/>
                        <RowTable title={'City'} value={<span>{studentData.cityName}</span>}/>
                        <RowTable title={'Postal Code'} value={<span>{studentData.postalCode}</span>}/>
                        <RowTable title={'Email'} value={<span>{studentData.email}</span>}/>
                        <RowTable title={'Gender'} value={<span>{studentData.gender}</span>}/>
                        <RowTable title={'Application ID'} value={<span>{studentData.applications.id}</span>}/>
                        <RowTable title={'Application'} value={<span>{studentData.applications.courseName}</span>}/>
                    </div> : null}
                <br/>
                <Form className={'vertical-form'} autoComplete='off'>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item>
                                <InputBox title={'Family name'}>
                                    <Input onChange={(e) => setFamilyName(e.target.value)}
                                           value={familyName} className={'form-control'}/>
                                </InputBox>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item>
                                <InputBox title={'Given name'}>
                                    <Input onChange={(e) => setGivenName(e.target.value)}
                                           value={givenName} className={'form-control'}/>
                                </InputBox>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            {/* { JSON.stringify(paymentMode)} */}
                            <Form.Item>
                                <InputBox title={'Scholarship'}>
                                    <select
                                        onChange={(e) => {
                                            setScholarship(e.target.value)
                                            setScholarshipInfo("")
                                        }}
                                        value={scholarship} className={'form-control'}>
                                        <option value="No">No</option>
                                        <option value="Yes">Yes</option>
                                    </select>
                                </InputBox>
                            </Form.Item>
                        </Col>
                        {scholarship === 'Yes' ?
                            <Col span={12}>
                                <Form.Item>
                                    <InputBox title={'Scholarship Info'}>
                                        <Input
                                            onChange={(e) => setScholarshipInfo(e.target.value)}
                                            value={scholarshipInfo} className={'form-control'}/>
                                    </InputBox>
                                </Form.Item>
                            </Col> : null}
                        <Col span={12}>
                            <Form.Item>
                                <InputBox title={'Internship'}>
                                    <select
                                        onChange={(e) => {
                                            setInternship(e.target.value)
                                            setInternshipInfo("")
                                        }}
                                        value={internship}
                                        className={'form-control'}>
                                        <option value="No">No</option>
                                        <option value="Yes">Yes</option>
                                    </select>
                                </InputBox>
                            </Form.Item>
                        </Col>
                        {internship === 'Yes' ?
                            <Col span={12}>
                                <Form.Item>
                                    <InputBox title={'Internship Info'}>
                                        <Input onChange={(e) => setInternshipInfo(e.target.value)}
                                               value={internshipInfo} className={'form-control'}/>
                                    </InputBox>
                                </Form.Item>
                            </Col> : null}
                        {/*    <Col span={12}>
                            <Form.Item>
                                <InputBox title={'Invoice Number'}>
                                    <Input onChange={(e) => setInvoiceNumber(e.target.value)}
                                           value={invoiceNumber} className={'form-control'}/>
                                </InputBox>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item>
                                <InputBox title={'Invoice Date'}>
                                    <DatePicker
                                        defaultValue={invoiceDate} format={'DD/MM/YYYY'} placeholder={'Invoice Date'}
                                        onChange={(value) => {
                                            setInvoiceDate(value)
                                        }}/>

                                </InputBox>
                            </Form.Item>
                        </Col>*/}
                        <Col span={24}>

                            <Form.Item style={{marginTop: 25}}>
                                <Button type='primary' onClick={handleSubmit}>
                                    Submit
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </Drawer>
    )
}
export default royalArtsApprovedLoaRequest
