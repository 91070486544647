import React, {useState, useEffect, useRef} from 'react'
import {
    Button,
    Popconfirm,
    Tooltip, Tag, Avatar, Icon, Row, Col, Select, Switch
} from 'antd'
import _ from 'lodash'
import {useDispatch, useSelector, connect} from 'react-redux'
import {TableComp} from 'sz-react-utils-lite'
import {getUrlPushWrapper} from '../../../routes'
import {
    UniversityRefArr,
} from '../../../components/_utils/appUtils'
import {listAllCountries} from '../../countries/actions/countries'
import {allCounsellingListFxn} from "../../counselling/actions/counselling";

const {Option} = Select

const ListAllUniversities = (props) => {
    let {currentUser} = props
    const tableRef = useRef()
    const dispatch = useDispatch()
    let [filters, setFilters] = useState({})
    let [total, setTotal] = useState(0)
    useEffect(() => {
        loadCountry()
    }, [])
    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            params.sortField = '_id'
            params.sortOrder = 'ascend'
            /* let resp = await getParamsForFilter()

             if (resp.countryId) {
                 params.universityCountry = resp.countryId
             }
             if (resp.stateId) {
                 params.universityState = resp.stateId
             }
             if (resp.cityId) {
                 params.universityCity = resp.cityId
             }
             if (resp.universityName) {
                 params.universityName = resp.universityName
             }
             if (resp.branchUserId) {
                 params.branchUserId = resp.branchUserId
             }*/

            let page = params.page

            if (!params.results) {
                params.results = 50
                params.count = 50
            }
            let data = await dispatch(allCounsellingListFxn({
                ...params,
                page: page,
                regExFilters: ['universityType', 'universityName']
            }))
            setTotal(data.total)
            resolve(data)
        })
    }

    const loadCountry = async () => {
        let params = {
            results: 10,
            active: [true, false]
        }
        let resp = await dispatch(listAllCountries(params))
    }

    const getParamsForFilter = () => {
        return new Promise((resolve) => {
            let searchParams = new URLSearchParams(window.location.search)
            let countryId = searchParams.get('countryId')
            let stateId = searchParams.get('stateId')
            let cityId = searchParams.get('cityId')
            let universityName = searchParams.get('universityName')
            let branchUserId = searchParams.get('branchUserId')
            let obj = {}

            if (universityName) {
                obj.universityName = universityName
            }
            if (branchUserId) {
                obj.branchUserId = branchUserId
            }
            resolve(obj)
        })
    }

    const events = {
        searchData: async () => {
            let obj = {}

            dispatch(
                getUrlPushWrapper('masters.allUniversities', {
                    ...obj
                }))
            setTimeout(() => {
                tableRef.current.reload()
            }, 200)
        },
        setFieldByParams: async () => {
            let resp = await getParamsForFilter()

        },
        goBack: () => {
            dispatch(getUrlPushWrapper('dashboard'))
        },
        clearFxn: () => {

            ;
        },

    }

    const columns = [

        {
            title: 'S.No',
            dataIndex: 'sno',
            width: 50,
            key: 'sno',
            render: (item, record, index) => {
                return (
                    <a className={'linkPointer'}>
                        {index + 1}
                    </a>
                )
            }
        },

        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (item, record) => {
                return (
                    <>
                        {item}
                    </>
                )
            }
        },
        {
            title: 'Student Type',
            dataIndex: 'studentType',
            width: 150,
            key: 'studentType',
            render: (item, record) => {
                return (
                    <>
                        {item}
                    </>
                )
            }
        },
        {
            title: 'Mobile no',
            dataIndex: 'mobile',
            key: 'mobile',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'IELTS/PTE',
            dataIndex: 'ielts',
            key: 'ielts',
        },
        {
            title: 'Year of Apply',
            dataIndex: 'yearOfApply',
            key: 'yearOfApply',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },


    ]


    return (
        <div className='row mt-4'>
            <div className='col-lg-12'>
                <div className='card'>
                    <div className='table-head d-flex align-items-center'>
                        <Button onClick={() => events.goBack()}
                                className={'roundGoBackBtn'} icon={'arrow-left'}>
                            Back
                        </Button>

                        <h5>All Counsellings: {total}</h5>


                        <div className='sort-box-table mark-btn mark-btn40'>
                            <div style={{width: '100%'}}>

                            </div>
                        </div>
                    </div>
                    <div className='card-body table-responsive'>

                        {/*<Row gutter={16}>

                            <Col md={4} sm={4} xs={8} lg={4}>
                                <div className='sort-box-table custom-sort-box-new'>

                                </div>
                            </Col>

                            <Col span={6}>
                                <Button onClick={() => events.searchData()} className={'roundBtn'}>Search</Button>
                                <Button onClick={() => events.clearFxn()} className={'roundBtn'}>
                                    Clear
                                </Button>
                            </Col>
                        </Row>*/}
                        <TableComp columns={columns}
                                   apiRequest={apiRequest}
                                   pagination={{
                                       position: 'top',
                                       pageSizeOptions: ['50', '100', '200', '500'],
                                       defaultPageSize: 50,
                                       current: filters.page ? filters.page : 1
                                   }}
                                   ref={tableRef}
                                   extraProps={{scroll: {x: 600}}}
                        />
                    </div>

                </div>
            </div>

        </div>
    )
}

const mapStateToProps = ({global, router}) => ({
    currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ListAllUniversities)
