import React, { Component, PureComponent } from 'react'

import {
  Form,
  Button,
  Card,
  Icon,
  Tabs,
  Tooltip,
  Row,
  Steps,
  message,
  notification,
  Col,
  Select,
  Input,
  InputNumber,
  Table,
  Divider
} from 'antd'
import _ from 'lodash'

import { connect } from 'react-redux'
import styles from './style.less'
import { InputBox, qualificationOptions } from '../_utils/appUtils'

const Step = Steps.Step
const Option = Select.Option

@Form.create()
class Qualification extends PureComponent {
  state = {
    qualification: null,
    passingYear: null,
    percentage: null,
    maths: null,
    english: null,
    current: 0
  }

  constructor(props) {
    super(props)
  }

  onSubmit = () => {
    let y = []
    let x = this.state


    y.push({
      qualification: x.qualification,
      passingYear: x.passingYear,
      percentage: x.percentage,
      maths: x.maths,
      english: x.english
    })


    this.props.handleSubmit(y)

    this.setState({
      passingYear: null,
      percentage: null,
      maths: null,
      english: null
    })
  }

  componentDidMount() {
  }

  render() {
    const {
      form: { getFieldDecorator, getFieldValue }
    } = this.props

    const { hide } = this.props

    const submitFormLayout = {
      labelCol: {},
      wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 24, offset: 0 },
        md: { span: 24, offset: 0 }
      }
    }

    let { qualification } = this.state

    return (
      <div>
        <Row style={{ marginBottom: 10 }}>
          <Col span={24}>
            <InputBox title={'Qualification'}>
              <Select
                showSearch={true}
                showAction={['focus', 'click']}
                onChange={qualification => {
                  this.setState({
                    qualification,
                    passingYear: '',
                    maths: '',
                    english: '',
                    percentage: '',
                    degreeType: '',
                    major: [],
                    stream: [],
                    backlogs: ''
                  })
                }}>
                {qualificationOptions.map((item, index) => {
                  return (
                    <Option value={item} key={index}>{item}</Option>
                  )
                })}

              </Select>
            </InputBox>

          </Col>
          <Col span={24}>
            {qualification ?
              <div>
                <Row gutter={16}>
                  <Col span={12}>
                    <InputBox title={'Passing year'}>
                      <InputNumber
                        value={this.state.passingYear}
                        maxLength={4}
                        allowClear
                        className={styles.input}
                        onChange={py => {
                          this.setState({ passingYear: py })
                        }}
                      />
                    </InputBox>
                  </Col>
                  <Col span={12}>
                    <InputBox title={'Percentage'}>
                      <InputNumber
                        value={this.state.percentage}
                        maxLength={2}
                        allowClear
                        onChange={py => {
                          this.setState({ percentage: py })
                        }}
                      />
                    </InputBox>
                  </Col>
                  {qualification == 'XII' ? <React.Fragment>
                    <Col span={12}>
                      <InputBox title={'Math Score'}>
                        <InputNumber
                          value={this.state.maths}
                          maxLength={2}
                          allowClear
                          onChange={py => {

                            this.setState({ maths: py })
                          }}
                        />
                      </InputBox>
                    </Col>
                    <Col span={12}>
                      <InputBox title={'English Score'}>
                        <InputNumber
                          value={this.state.english}
                          allowClear
                          maxLength={2}
                          onChange={py => {

                            this.setState({ english: py })
                          }}
                        />
                      </InputBox>
                    </Col>

                  </React.Fragment> : null}

                  <Col span={24}>
                    <Button
                      style={{
                        marginBottom: '9px',
                        marginTop: '16px',
                        height: '30px',
                        marginLeft: '10px'
                      }}
                      size={'small'}
                      type="primary"
                      onClick={this.onSubmit}> ADD </Button>
                  </Col>

                </Row>
              </div> : null}

          </Col>
        </Row>
        <Divider/>

      </div>
    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading,
  categories: global.categories,
  search: router.location.search
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Qualification)
