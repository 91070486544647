import {notification} from 'antd'
import {
    addRegistraionUrl,
    listAllWebinarUrl,
    referenceMarketingUrl,
    singleEventUrl,
    verifyLeadUrl,
    webEventsListUrls
} from './api';
import {hidePageLoad, showPageLoad} from '../../../modules/actions';
import {customAxios as axios, getToken} from "../../../request";

export const listAllWebinars = async (filters) => {
    let config = {
        params: {...filters},
        ...await getToken()
    }
    let {data} = await axios.get(listAllWebinarUrl(), config);
    return data;

}

export const SingleEventsFxn = (id) => async (dispatch) => {
    let config = {
        headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')},
    }
    let {data} = await axios.get(singleEventUrl(id), config);
    if (data.error) {
        notification.error({
            message: data.message || 'Error',
        })
    }
    return data.data;

}

export const referenceMarketingFxn = (id) => async (dispatch) => {
    let config = {
        headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')},
    }
    let {data} = await axios.get(referenceMarketingUrl(id), config);
    if (data.error) {
        notification.error({
            message: data.message || 'Error',
        })
    }
    return data.data;

}


export const addRegistraionFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(addRegistraionUrl(), valData, getToken())
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }
    dispatch(hidePageLoad())
    return data
}

export const verifyLeadFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(verifyLeadUrl(), valData, getToken())
    if (!data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }
    dispatch(hidePageLoad())
    return data
}


export const webEventsListUrlFxn = async (filters) => {
    let config = {
        params: {...filters}
    }
    let {data} = await axios.get(webEventsListUrls(), config);
    return data.data;

}
