import React, { Fragment } from 'react'
import { Icon } from 'antd'
import styles from './AuthLayout.less'
import logo from '../assets/Unizportal-Logo.png'
import { Link } from 'react-router-dom'
import GlobalFooter from '../components/GlobalFooter'
import { subTitle, pageTitle } from '../settings'
import { Helmet } from 'react-helmet'


const links = [
  {
    key: 'help',
    title: 'HELP',
    href: ''
  }
]

const copyright = (
  <Fragment>
    {/*Copyright <Icon type="copyright"/> 2018*/}
  </Fragment>
)

class UserLayout extends React.PureComponent {

  render() {
    const { children } = this.props
    return (
      <React.Fragment>
        <Helmet>
          <link href="../dist/css/customCss.css" rel="stylesheet"/>
          <link href="../dist/css/adminlte.css" rel="stylesheet"/>
        </Helmet>
        {children}
      </React.Fragment>
    )
  }
}

export default UserLayout
