import { apiUrl } from '../../../settings'

export const addCourseUrl = () => {
  return apiUrl + '/course'
}

export const listCourse = () => {
  return apiUrl + '/course'
}
export const listCourseUrl = (id) => {
  return apiUrl + '/course/' + id
}
export const quickSearchCourse = () => {
  return apiUrl + '/quickSearch/courses'
}

export const quickSearchLoadMoreCourse = () => {
  return apiUrl + '/quickSearch/loadMoreCourse'
}


export const loadCourseIntakesUrl = () => {
  return apiUrl + '/api/loadCourseIntakes'
}


export const exportCourseListUrl = () => {
  return apiUrl + '/api/exportCourseList'
}

export const courseIsSearchAbleUrl = () => {
    return apiUrl + '/api/courseIsSearchAble'
}

export const courseIsFeaturedUrl = () => {
    return apiUrl + '/api/courseIsFeatured'
}

export const topCourseUpdateUrl = () => {
    return apiUrl + '/api/topCourse'
}
