import {apiUrl} from '../../../settings'

export const eventListUrl = () => {
    return apiUrl + '/event/list'
}

export const eventListExportUrl = () => {
    return apiUrl + '/event/listExport'
}
export const approveLeadUrl = () => {
    return apiUrl + '/event/approvedLead'
}