import React, { useState, useEffect, useRef } from 'react'
import { CustomRowTable } from '../../../components/_utils/RowTable'
import { Drawer, Card, Skeleton, Tooltip, Icon, Table, Avatar, Button } from 'antd'
import { useDispatch } from 'react-redux'
import { countryIds, displayDate, statusColors } from '../../../components/_utils/appUtils'
import { getTransactionList } from '../actions'
import lodash from 'lodash'
import { getPendingPendency } from '../../../components/_utils/utils'
import { getUrlPushWrapper } from '../../../routes'

const TransactionsDrawer = (props) => {
  let dispatch = useDispatch()
  let tableRef = useRef()
  let { universityId, visible, onClose } = props
  let [universityData, setUniversityData] = useState({})
  let [countryId, setCountryId] = useState('')
  let [loading, setLoading] = useState(true)
  let [campusList, setCampusList] = useState([])
  let [total, setTotal] = useState([])
  let [listData, setListData] = useState([])


  useEffect(() => {
    apiRequest()
  }, [])

  const apiRequest = async () => {
    let total = 0
    let params = {
      // ...pageCount(100, listData)
    }
    let { data } = await dispatch(getTransactionList())
    console.log('trancaions', data)
    lodash.each(data, (item) => {
      total = total + parseFloat(item.amount)
    })
    setTotal(total)
    setListData(data)
    setLoading(false)
  }

  const columns = [
    {
      title: 'Name',
      key: 'studentId',
      render: (val) => {
        return (
          <div>{val.studentId ? val.studentId.name : ''}</div>
        )
      }
    },
    {
      title: 'Date',
      key: 'time',
      render: (val) => {
        return (
          <div>{displayDate(val)}</div>
        )
      }
    },
    {
      title: 'Amount',
      key: 'amount',
      render: (item) => {
        console.log('val name', item.amount)
        return (
          <React.Fragment>
            <span>${item.amount}</span>
            {/*<label className={'label sm'} style={{ background: statusColors(item) }}>{item}</label>*/}
            {/*<label className={'label sm'}>${item.amount}</label>*/}
          </React.Fragment>
        )
      }
    }
  ]

  return (
    <Drawer visible={visible}
            title={'Transactions'} width={'50%'}
            onClose={onClose}>
      <Card>
        {loading ? <Skeleton loading={loading} /> :
          <div className={'infoUni tableBox striped'}>
            <Table columns={columns}
                   bordered={true}
                   dataSource={listData}
                   pagination={false}
                   ref={tableRef}
            />
          </div>}
      </Card>
    </Drawer>
  )
}


export { TransactionsDrawer }
