import React, {Suspense, useEffect, useState} from 'react'
// import {GetCountryCountRight} from './DashboardUserWise'
import {Avatar, Icon, Select} from 'antd'
import _ from 'lodash'
import {LazyLoadImage} from 'react-lazy-load-image-component'
import {useDispatch} from 'react-redux'
import styles from '../../../components/GlobalHeader/index.less'
import {
    australiaApplicationStatusSteps,
    australia_onshore_Steps,
    canadaApplicationStatusSteps,
    germanyApplicationSteps,
    singaporeApplicationSteps,
    ukApplicationStatusSteps,
    usaApplicationStatusSteps,
    australiaApplicationL1StatusSteps,
    canada_onshore_Steps
} from '../../../components/_utils/ApplicationStatusSteps'
import {
    cambieUniversity,
    countryIds,
    fcmtUniversity
} from '../../../components/_utils/appUtils'
import {getUrlPushWrapper} from '../../../routes'
import {
    getSelectedCountryApplicationsApi,
    getStudentFcmtCourseFxn,
    oshcApplicationsFxn,
    ukTuitionFeeApplicationFxn
} from '../../applications/actions/chooseStudent'
import {dashboardAustraliaOnShoreFxn, dashboardCanadaOnShoreFxn} from '../actions/dashboardAction'
import {
    AustraliaCountryCount,
    AustraliaInnerBlock,
    AustraliaOnShoreInnerBlock,
    CanadaCountryCount,
    CanadaInnerBlock,
    InnerBlockNew,
    UkCountryCount,
    UkInnerBlock,
    CanadaOnShoreInnerBlock
} from './countryCount'

const GetCountryCountRight = React.lazy(() =>
    import('./DashboardUserWise').then(module => ({
        default: module.GetCountryCountRight
    }))
)
const ShowCountryOnDashboard = React.lazy(() =>
    import('./DashboardUserWise').then(module => ({
        default: module.ShowCountryOnDashboard
    }))
)
const ShowOnShoreApplicationDashboard = React.lazy(() =>
    import('./DashboardUserWise').then(module => ({
        default: module.ShowOnShoreApplicationDashboard
    }))
)
const ShowCanadaOnShoreApplication = React.lazy(() =>
    import('./DashboardUserWise').then(module => ({
        default: module.ShowCanadaOnShoreApplication
    }))
)
const {Option} = Select

const AdminDashboard = props => {
    let {events, userCountryName, userData, state} = props
    return (
        <div className="d-md-flex master-admin">
            <Suspense fallback={<></>}>
                <GetCountryCountRight
                    user={userData}
                    userCountryName={userCountryName}
                    countryName={'uk'}>
                    <UkCountryCount
                        data={state.ukData}
                        callback={(countryId, status) => {
                            events.loadCountryApplications({countryId, status})
                        }}
                    />
                </GetCountryCountRight>
                <GetCountryCountRight
                    user={userData}
                    userCountryName={userCountryName}
                    countryName={'australia'}>
                    <AustraliaCountryCount
                        data={state.australiaData}
                        callback={(countryId, status) => {
                            events.loadCountryApplications({countryId, status})
                        }}
                    />
                </GetCountryCountRight>
                <GetCountryCountRight
                    user={userData}
                    userCountryName={userCountryName}
                    countryName={'canada'}>
                    <CanadaCountryCount
                        data={state.canadaData}
                        userCountryName={userCountryName}
                        userData={userData}
                        callback={(countryId, status) => {
                            events.loadCountryApplications({countryId, status})
                        }}
                    />
                </GetCountryCountRight>
            </Suspense>
        </div>
    )
}
const BranchDashboard = props => {
    let {events, userCountryName, userData, state} = props
    return (
        <div className="d-md-flex">
            <div className="p-1 flex-fill map-section" style={{overflow: 'hidden'}}>
                <LazyLoadImage src={`/dist/img/${userCountryName}.png`} alt=""/>
            </div>
            <Suspense fallback={<></>}>
                <GetCountryCountRight
                    user={userData}
                    userCountryName={userCountryName}
                    countryName={'uk'}>
                    <UkCountryCount
                        data={state.ukData}
                        callback={(countryId, status) => {
                            events.loadCountryApplications({countryId, status})
                        }}
                    />
                </GetCountryCountRight>
                <GetCountryCountRight
                    user={userData}
                    userCountryName={userCountryName}
                    countryName={'canada'}>
                    <CanadaCountryCount
                        data={state.canadaData}
                        callback={(countryId, status) => {
                            events.loadCountryApplications({countryId, status})
                        }}
                    />
                </GetCountryCountRight>
                <GetCountryCountRight
                    user={userData}
                    userCountryName={userCountryName}
                    countryName={'australia'}>
                    <AustraliaCountryCount
                        data={state.australiaData}
                        callback={(countryId, status) => {
                            events.loadCountryApplications({countryId, status})
                        }}
                    />
                </GetCountryCountRight>
            </Suspense>
        </div>
    )
}

const AgentDashboard = props => {
    let {events, userCountryName, userData, state, countryManager} = props
    let [onshoreAustraliaApplication, setOnshoreAustraliaApplication] = useState({})
    let [onshoreCanadaApplication, setOnshoreCanadaApplication] = useState({})

    let [showMenu, setSHowMenu] = useState(false)
    let [country, setCountry] = useState('Canada')
    let countryName = country.toLowerCase()
    let mapCountryName = country.toLowerCase()
    let dispatch = useDispatch()
    let countryColors = {
        UK: '#ff0066',
        USA: '#ff0066',
        Australia: '#003399',
        Canada: '#ad2125',
        Germany: '#ad2125'
    }
    let chooseCountry = value => {
        setCountry(value)
        setSHowMenu(false)
    }
    useEffect(
        () => {
            if (userData.userType == 'branchManager' &&
                userData.branchManagerType !== 'marketingManager' &&
                userData.branchManagerType !== 'studentWise') {
                if (userData.countryId && userData.countryId.countryName) {
                    chooseCountry(userData.countryId.countryName)
                }
            } else if (userData.userType == 'branchUser') {
                if (userData.department !== 'Visa Approved Department') {
                    if (userData.showOnShoreAustralia) {
                        chooseCountry('Australia_onShore')
                    } else if (userData.showOnShoreCanada) {
                        chooseCountry('Canada_onShore')
                    } else {
                        if (userData.countryId && userData.countryId.countryName) {
                            chooseCountry(userData.countryId.countryName)
                        }
                    }
                }
            }
        },
        [userData.countryId]
    )

    useEffect(() => {
        loadOnshoreData()
        loadCanadaOnshoreData()
    }, [])

    const loadOnshoreData = async () => {
        let resp = await dashboardAustraliaOnShoreFxn()
        setOnshoreAustraliaApplication(resp)
    }
    const loadCanadaOnshoreData = async () => {
        let resp = await dashboardCanadaOnShoreFxn()
        setOnshoreCanadaApplication(resp)
    }

    let openApplicationList = data => {
        let newObj = {
            countryId: data.countryId,
            status:
                data.status == 'Draft Applications' ? 'Waiting To Apply' : data.status,
            draftApplication: data.status == 'Draft Applications' ? 'Yes' : 'No'
        }
        if (
            data.status == 'Visa Approved' &&
            (userData.userType !== 'agent' && userData.userType !== 'subAgent')
        ) {
            newObj.udaan = 'udaan3'
        }
        if (data.countryId == countryIds.uk) {
            dispatch(
                getUrlPushWrapper('application.applicationsStudentList', {
                    ...newObj
                })
            )
        } else if (data.countryId == countryIds.australia) {
            dispatch(
                getUrlPushWrapper('application.applicationsList', {
                    ...newObj
                })
            )
        } else {
            dispatch(
                getUrlPushWrapper('application.applicationsList', {
                    ...newObj
                })
            )
        }
    }
    let openOnShoreApplicationList = data => {
        dispatch(
            getUrlPushWrapper('australiaOnShoreList', {
                status: data.status
            })
        )
    }
    let openOnShoreCanadaApplicationList = data => {
        dispatch(
            getUrlPushWrapper('canadaOnShoreList', {
                status: data.status
            })
        )
    }
    if (countryName == 'uk') {
        countryName = 'kingdom'
        mapCountryName = 'kingdom'
    } else if (
        countryName == 'australia_l1' ||
        countryName == 'australia_onshore'
    ) {
        mapCountryName = 'australia'
    }
    let userTypeList = ['hr']
    return (
        <React.Fragment>
            <div className="d-md-flex pattern-img align-items-center">
                <div className="flex-fill map-section" style={{overflow: 'hidden'}}>
                    <div className="tab-content" id="v-pills-tabContent">
                        <div
                            className="tab-pane fade show active"
                            id="v-pills-canada"
                            role="tabpanel"
                            aria-labelledby="v-pills-canada-tab">
                            <div
                                className="p-1 flex-fill map-section"
                                style={{overflow: 'hidden'}}>
                                <LazyLoadImage
                                    src={`/dist/img/${mapCountryName}-map.png`}
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="card-pane-right listing-section"
                    style={{width: '35%'}}>
                    <div
                        className="nav flex-column nav-pills w-100"
                        id="v-pills-tab"
                        role="tablist"
                        aria-orientation="vertical">
                        {countryManager && countryManager[country] &&
                        userData &&
                        userData.userType == 'agent' ? (
                            <>
                                <div
                                    style={{
                                        marginLeft: 'auto',
                                        backgroundColor: countryColors[country],
                                        color: 'white',
                                        paddingLeft: 10,
                                        paddingRight: 10,
                                        marginBottom: 20
                                    }}>
                                      <span style={{marginLeft: 'auto', fontSize: 14}}>
                                        DESIGNATED MANAGER
                                      </span>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'flex-end'
                                    }}>
                                    <div className={'row'}>
                                        <div
                                            style={{
                                                textAlign: 'right',
                                                padding: 5,
                                                fontSize: 12,
                                                color: '#585858'
                                            }}>
                                            <div>
                        <span style={{fontWeight: 'bold'}}>
                          {countryManager[country].name} <Icon type={'user'}/>
                        </span>
                                                <br/>
                                                {countryManager[country].email} <Icon type={'mail'}/>
                                                <br/>
                                                {countryManager[country].mobile} <Icon type={'phone'}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'center'} style={{paddingLeft: 5}}>
                                        <Avatar
                                            size={50}
                                            style={{
                                                border: `3px solid ${countryColors[country]}`,
                                                marginLeft: 10
                                            }}
                                            src={
                                                countryManager[country] &&
                                                countryManager[country].logo &&
                                                countryManager[country].logo.url
                                                    ? countryManager[country].logo.url
                                                    : '../dist/img/user.png'
                                            }
                                        />
                                    </div>
                                </div>
                            </>
                        ) : null}
                        {/*</div>*/}
                        <a
                            className={`nav-link ${countryName == 'canada' ? 'active' : ''}`}
                            id="v-pills-canada-tab"
                            data-toggle="pill"
                            href="#v-pills-canada"
                            role="tab"
                            onClick={() => chooseCountry('Canada')}
                            aria-controls="v-pills-canada"
                            aria-selected="true">
                            canada
                        </a>

                        <ShowCountryOnDashboard
                            user={userData}
                            country={country}
                            userCountryName={userCountryName}
                            countryName={'australia'}>
                            <a
                                className={`nav-link ${
                                    countryName == 'australia' ? 'active' : ''
                                }`}
                                id="v-pills-australia-tab"
                                data-toggle="pill"
                                href="#v-pills-australia"
                                role="tab"
                                onClick={() => chooseCountry('Australia')}
                                aria-controls="v-pills-australia"
                                aria-selected="false">
                                australia
                            </a>
                        </ShowCountryOnDashboard>

                        <ShowCountryOnDashboard user={userData} country={country}
                                                userCountryName={userCountryName}
                                                countryName={'australia_l1'}>
                            <a className={`nav-link ${countryName == 'australia_l1' ? 'active' : ''}`}
                               id='v-pills-australiaL1-tab'
                               data-toggle='pill' href='#v-pills-australiaL1' role='tab'
                               onClick={() => chooseCountry('Australia_L1')}
                               aria-controls='v-pills-australiaL1' aria-selected='false'>Australia L1</a>
                        </ShowCountryOnDashboard>

                        <a
                            className={`nav-link ${countryName == 'kingdom' ? 'active' : ''}`}
                            id="v-pills-kingdom-tab"
                            data-toggle="pill"
                            href="#v-pills-kingdom"
                            role="tab"
                            onClick={() => chooseCountry('UK')}
                            aria-controls="v-pills-kingdom"
                            aria-selected="false">
                            united kingdom
                        </a>

                        <a
                            className={`nav-link ${countryName == 'usa' ? 'active' : ''}`}
                            id="v-pills-kingdom-tab"
                            data-toggle="pill"
                            href="#v-pills-kingdom"
                            role="tab"
                            onClick={() => chooseCountry('USA')}
                            aria-controls="v-pills-kingdom"
                            aria-selected="false">
                            USA
                        </a>

                        <a
                            className={`nav-link ${countryName == 'germany' ? 'active' : ''}`}
                            id="v-pills-germany-tab"
                            data-toggle="pill"
                            href="#v-pills-germany"
                            role="tab"
                            onClick={() => chooseCountry('Germany')}
                            aria-controls="v-pills-germany"
                            aria-selected="false">
                            Germany
                        </a>

                        <ShowOnShoreApplicationDashboard user={userData}>
                            <a
                                className={`nav-link ${
                                    countryName == 'australia_onshore' ? 'active' : ''
                                }`}
                                id="v-pills-australiaL1-tab"
                                data-toggle="pill"
                                href="#v-pills-australiaOnshore"
                                role="tab"
                                onClick={() => chooseCountry('Australia_onShore')}
                                aria-controls="v-pills-australiaOnShore"
                                aria-selected="false">
                                Australia Onshore
                            </a>
                        </ShowOnShoreApplicationDashboard>
                        <ShowCanadaOnShoreApplication user={userData}>
                            <a
                                className={`nav-link ${
                                    countryName == 'canada_onshore' ? 'active' : ''
                                }`}
                                id="v-pills-australiaL1-tab"
                                data-toggle="pill"
                                href="#v-pills-canadaOnShore"
                                role="tab"
                                onClick={() => chooseCountry('Canada_onShore')}
                                aria-controls="v-pills-canadaOnShore"
                                aria-selected="false">
                                Canada Onshore
                            </a>
                        </ShowCanadaOnShoreApplication>

                        <a
                            className={`nav-link ${
                                countryName == 'singapore' ? 'active' : ''
                            }`}
                            id="v-pills-germany-tab"
                            data-toggle="pill"
                            href="#v-pills-germany"
                            role="tab"
                            onClick={() => chooseCountry('Singapore')}
                            aria-controls="v-pills-germany"
                            aria-selected="false">
                            Singapore
                        </a>
                    </div>
                </div>
            </div>
            {/*state && state.ukData && state.ukData.countryId*/}
            {!_.isEmpty(state) ? (
                <div>
                    {country == 'UK' ? (
                        <Suspense fallback={<></>}>
                            <GetCountryCountRight
                                user={userData}
                                country={country}
                                userCountryName={userCountryName}
                                countryName={'uk'}>
                                <UkInnerBlock
                                    data={state.ukData}
                                    statusList={ukApplicationStatusSteps}
                                    userData={userData}
                                    userCountryName={country}
                                    callback={(countryId, status) => {
                                        openApplicationList({
                                            countryId: state.ukData.countryId,
                                            status
                                        })
                                    }}
                                />
                            </GetCountryCountRight>
                        </Suspense>
                    ) : (
                        ''
                    )}

                    {country == 'USA' ? (
                        <Suspense fallback={<></>}>
                            <GetCountryCountRight
                                user={userData}
                                country={country}
                                userCountryName={userCountryName}
                                countryName={'usa'}>
                                <InnerBlockNew
                                    data={state.usaData}
                                    statusList={usaApplicationStatusSteps}
                                    callback={(countryId, status) => {
                                        openApplicationList({
                                            countryId: state.usaData.countryId,
                                            status
                                        })
                                    }}
                                />
                            </GetCountryCountRight>
                        </Suspense>
                    ) : (
                        ''
                    )}

                    {country == 'Singapore' ? (
                        <Suspense fallback={<></>}>
                            <GetCountryCountRight
                                user={userData}
                                country={country}
                                userCountryName={userCountryName}
                                countryName={'singapore'}>
                                <InnerBlockNew
                                    data={state.singaporeData}
                                    statusList={singaporeApplicationSteps}
                                    callback={(countryId, status) => {
                                        openApplicationList({
                                            countryId: state.singaporeData.countryId,
                                            status
                                        })
                                    }}
                                />
                            </GetCountryCountRight>
                        </Suspense>
                    ) : (
                        ''
                    )}

                    {country == 'Canada' ? (
                        <Suspense fallback={<></>}>
                            <GetCountryCountRight
                                user={userData}
                                country={country}
                                userCountryName={userCountryName}
                                countryName={'canada'}>
                                <CanadaInnerBlock
                                    data={state.canadaData}
                                    statusList={canadaApplicationStatusSteps}
                                    userData={userData}
                                    userCountryName={country}
                                    callback={(countryId, status) => {
                                        openApplicationList({
                                            countryId: state.canadaData.countryId,
                                            status
                                        })
                                        // events.loadCountryApplications({ countryId: state.canadaData.countryId, status })
                                    }}
                                />
                            </GetCountryCountRight>
                        </Suspense>
                    ) : (
                        ''
                    )}

                    {country == 'Australia' ? (
                        <Suspense fallback={<></>}>
                            <GetCountryCountRight
                                user={userData}
                                country={country}
                                userCountryName={userCountryName}
                                countryName={'australia'}>
                                <AustraliaInnerBlock
                                    data={state.australiaData}
                                    statusList={australiaApplicationStatusSteps}
                                    userData={userData}
                                    callback={(countryId, status) => {
                                        openApplicationList({
                                            countryId: state.australiaData.countryId,
                                            status
                                        })
                                        // events.loadCountryApplications({ countryId: state.australiaData.countryId, status })
                                    }}
                                />
                            </GetCountryCountRight>
                        </Suspense>
                    ) : (
                        ''
                    )}


                    {country == 'Australia_L1' ?
                        <Suspense fallback={<></>}>
                            <GetCountryCountRight user={userData} country={country}
                                                  userCountryName={userCountryName}
                                                  countryName={'australia_l1'}>
                                <AustraliaInnerBlock data={state.australiaL1Data}
                                                     statusList={australiaApplicationL1StatusSteps}
                                                     userData={userData}
                                                     callback={(countryId, status) => {
                                                         openApplicationList({
                                                             countryId: state.australiaL1Data.countryId,
                                                             status
                                                         })
                                                     }}/>
                            </GetCountryCountRight>
                        </Suspense> : ''}


                    {country == 'Germany' ? (
                        <Suspense fallback={<></>}>
                            <GetCountryCountRight
                                user={userData}
                                country={country}
                                userCountryName={userCountryName}
                                countryName={'germany'}>
                                <InnerBlockNew
                                    data={state.germanyData}
                                    statusList={germanyApplicationSteps}
                                    callback={(countryId, status) => {
                                        openApplicationList({
                                            countryId: state.germanyData.countryId,
                                            status
                                        })
                                    }}
                                />
                            </GetCountryCountRight>
                        </Suspense>
                    ) : (
                        ''
                    )}

                    {country == 'Australia_onShore' ? (
                        <Suspense fallback={<></>}>
                            <AustraliaOnShoreInnerBlock
                                data={onshoreAustraliaApplication}
                                statusList={australia_onshore_Steps}
                                userData={userData}
                                callback={(countryId, status) => {
                                    openOnShoreApplicationList({
                                        status
                                    })
                                }}
                            />
                        </Suspense>
                    ) : null}
                    {country == 'Canada_onShore' ? (
                        <Suspense fallback={<></>}>
                            <CanadaOnShoreInnerBlock
                                data={onshoreCanadaApplication}
                                statusList={canada_onshore_Steps}
                                userData={userData}
                                callback={(countryId, status) => {
                                    openOnShoreCanadaApplicationList({
                                        status
                                    })
                                }}
                            />
                        </Suspense>
                    ) : null}
                </div>
            ) : (
                ''
            )}
        </React.Fragment>
    )
}

const FcmtUniversity = props => {
    let {userCountryName, userData} = props
    let [totalApplication, setTotalApplication] = useState(0)
    let dispatch = useDispatch()
    useEffect(() => {
        loadCount()
    }, [])
    let loadCount = async () => {
        let resp = await dispatch(
            getStudentFcmtCourseFxn({
                appUniversityId: fcmtUniversity,
                count: 0,
                results: 0
            })
        )
        setTotalApplication(resp.total)
    }

    let searchData = () => {
        if (userData && userData.showAllFcmtList) {
            dispatch(
                getUrlPushWrapper('allFcmtApplicationsList', {
                    appUniversityId: fcmtUniversity
                })
            )
        } else {
            dispatch(
                getUrlPushWrapper('application.fcmtApplicationsList', {
                    appUniversityId: fcmtUniversity
                })
            )
        }
    }

    let btnA = (
        <>
            <li onClick={() => searchData()} className={'pointerCls pointerCls1'}>
                <LazyLoadImage
                    src={'/assets/flairCollage.png'}
                    style={{height: 30}}
                />
                <h5>{totalApplication}</h5>
            </li>
        </>
    )

    let branchUserCheck = () => {
        let checkUniversityExists = _.find(userData.universities, item => {
            return item.toString() == fcmtUniversity
        })
        return userData.department == 'Visa Approved Department'
            ? btnA
            : checkUniversityExists
                ? btnA
                : null
    }
    return (
        <>
            {userCountryName == 'Canada' ? (
                <>
                    {userData.userType == 'admin' ||
                    userData.userType == 'agent' ||
                    userData.userType == 'subAgent'
                        ? btnA
                        : null}
                    {userData.userType == 'branchManager' && userCountryName == 'Canada'
                        ? btnA
                        : null}
                    {userData.userType == 'branchUser' ? branchUserCheck() : null}
                </>
            ) : null}
        </>
    )
}

const CambieUniversity = props => {
    let {userCountryName, userData} = props
    let [totalApplication, setTotalApplication] = useState(0)
    let dispatch = useDispatch()
    useEffect(() => {
        loadCount()
    }, [])
    let loadCount = async () => {
        let resp = await dispatch(
            getStudentFcmtCourseFxn({
                appUniversityId: cambieUniversity,
                count: 0,
                results: 0
            })
        )
        setTotalApplication(resp.total)
    }

    let searchData = () => {
        if (userData && userData.showAllFcmtList) {
            dispatch(
                getUrlPushWrapper('allFcmtApplicationsList', {
                    appUniversityId: cambieUniversity
                })
            )
        } else {
            dispatch(
                getUrlPushWrapper('application.fcmtApplicationsList', {
                    appUniversityId: cambieUniversity
                })
            )
        }
    }

    let btnA = (
        <>
            <li onClick={() => searchData()} className={'pointerCls pointerCls1'}>
                <LazyLoadImage
                    src={'/assets/flairCollage.png'}
                    style={{height: 30}}
                />
                <h5>{totalApplication}</h5>
            </li>
        </>
    )

    let branchUserCheck = () => {
        let checkUniversityExists = _.find(userData.universities, item => {
            return item.toString() == cambieUniversity
        })
        return userData.department == 'Visa Approved Department'
            ? btnA
            : checkUniversityExists
                ? btnA
                : null
    }
    return (
        <>
            {userCountryName == 'Canada' ? (
                <>
                    {userData.userType == 'admin' ||
                    userData.userType == 'agent' ||
                    userData.userType == 'subAgent'
                        ? btnA
                        : null}
                    {userData.userType == 'branchManager' && userCountryName == 'Canada'
                        ? btnA
                        : null}
                    {userData.userType == 'branchUser' ? branchUserCheck() : null}
                </>
            ) : null}
        </>
    )
}

const OSHCInsuranceApplicationsBtn = props => {
    let {userCountryName, userData} = props
    let [totalApplication, setTotalApplication] = useState(0)
    let dispatch = useDispatch()
    useEffect(() => {
        loadCount()
    }, [])
    let loadCount = async () => {
        let resp = await dispatch(
            oshcApplicationsFxn({
                count: 0,
                results: 0
            })
        )
        setTotalApplication(resp.total)
    }

    let searchData = () => {
        dispatch(getUrlPushWrapper('oshcApplications'))
    }

    let btnA = (
        <>
            <li onClick={() => searchData()} className={'pointerCls pointerCls1'}>
                OSHC
                <h5>{totalApplication}</h5>
            </li>
        </>
    )

    return (
        <>
            {userData.userType == 'admin'
                ? btnA
                : userData.email == 'accounts@ryanconsultants.com'
                    ? btnA
                    : null}
        </>
    )
}
const UkTuitionFeesApplicationBtn = props => {
    let {userCountryName, userData} = props
    let [totalApplication, setTotalApplication] = useState(0)
    let dispatch = useDispatch()
    useEffect(() => {
        loadCount()
    }, [])
    let loadCount = async () => {
        let resp = await dispatch(
            ukTuitionFeeApplicationFxn({
                count: 0,
                results: 0
            })
        )
        setTotalApplication(resp.total)
    }

    let searchData = () => {
        dispatch(getUrlPushWrapper('ukTuitionFeeApplications'))
    }
    let btnA = (
        <>
            <li onClick={() => searchData()} className={'pointerCls pointerCls1'}>
                Tuition Fees
                <h5>{totalApplication}</h5>
            </li>
        </>
    )

    return (
        <>
            <li onClick={() => searchData()} className={'pointerCls pointerCls1'}>
                Tuition Fees
                <h5>{totalApplication}</h5>
            </li>
        </>
    )
}

export {
    BranchDashboard,
    AdminDashboard,
    AgentDashboard,
    FcmtUniversity,
    CambieUniversity,
    OSHCInsuranceApplicationsBtn,
    UkTuitionFeesApplicationBtn
}
