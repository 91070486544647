import {apiUrl} from '../../../settings'

export const addBankAccountUrl = () => {
    return apiUrl + '/addBankAccount';
}

export const addAccountSendOtpUrl = () => {
    return apiUrl + '/addAccountSendOtp';
}

export const listAccountDetailsUrl = () => {
    return apiUrl + '/bankAccount';
}
