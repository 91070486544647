import React, {PureComponent} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
    Form,
    Input,
    DatePicker,
    Select,
    Button,
    Card,
    Icon, Col, Row
} from 'antd'
import _ from 'lodash'
import moment from 'moment'
import {notification} from 'antd/lib/index'
import {hideLoader, showLoader, showPageLoad, hidePageLoad} from '../../../modules/actions'
import {connect} from 'react-redux'
import {listAllBranch} from '../../branch/actions/branchAction'
import {DocumentTypes, InputBox} from '../../../components/_utils/appUtils'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import {addUser} from '../actions/user'
import {LoadAllCountry, LoadState, LoadCity, CountryCodeWithFlag} from '../../../components/_utils/countryUtil'

const FormItem = Form.Item
const {Option} = Select
const {TextArea} = Input

@Form.create()
class AddUser extends PureComponent {

    events = {
        chooseCountry: (name) => {
            let countryName = _.find(this.state.allCountries, (item) => {
                return item.name == name
            })
            if (countryName) {
                this.setState({
                    countryName: countryName.name ? countryName.name : '',
                    allStates: countryName.id ? LoadState(countryName.id) : []
                })
            }
        },
        chooseState: (name) => {
            let stateName = _.find(this.state.allStates, (item) => {
                return item.name == name
            })
            if (stateName) {
                this.setState({
                    stateName: stateName.name ? stateName.name : '',
                    allCities: stateName.id ? LoadCity(stateName.id) : []
                })
            }
        },
        chooseCity: (name) => {
            this.setState({
                cityName: name ? name : ''
            })
        }
    }

    constructor(props) {
        super(props)
        this.state = {
            allBranch: [],
            logo: {},
            uploadKey: moment(),
            allCountries: LoadAllCountry(),
            allStates: [],
            allCities: [],
            countryName: '',
            stateName: '',
            cityName: '',
            countryCode: ''
        }

    }

    handleSubmit = e => {
        const {dispatch, form} = this.props
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                let {countryCode} = this.state
                if (!countryCode) {
                    notification.error({
                        message: 'Please choose Country Code'
                    })
                    return
                }
                let agentId = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null

                valData.agentId = agentId._id
                valData.userType = 'subAgent'
                valData.address = this.state.address
                valData.countryISOCode = this.state.countryCode
                let fd = new FormData()
                fd.append('obj', JSON.stringify(valData))
                if (this.state.logo && this.state.logo.name) {
                    fd.append('logo', this.state.logo)
                }

                let data = await dispatch(addUser(fd))
                if (data && !data.error) {
                    form.resetFields()
                    this.setState({
                        address: '',
                        uploadKey: moment(),
                        logo: {},
                        countryCode: ''
                    })
                }
            }
        })
    }

    componentDidMount() {
        // this.loadBranch()
        let {dispatch, form} = this.props
        dispatch({type: 'USER_HIDELOADER'})
        // form.setFieldsValue({ enableChanelPartner: true })
        // form.setFieldsValue({ showAustraliaUniversity: false })
        dispatch(hideLoader())
        dispatch(hidePageLoad())
    }

    async loadBranch() {
        let {dispatch} = this.props
        let {data} = await listAllBranch({})(dispatch)
        this.setState({
            allBranch: data
        })
    }

    render() {

        const {submitting, dispatch} = this.props
        const {
            form: {getFieldDecorator, getFieldValue, setFieldsValue}
        } = this.props

        const {editorState, allBranch} = this.state

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 24},
                md: {span: 24}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 24},
                md: {span: 24}
            }
        }

        const submitFormLayout = {
            wrapperCol: {
                xs: {span: 24, offset: 0},
                sm: {span: 10, offset: 7},
                md: {span: 12, offset: 8}
            }
        }

        let inputTypes = {
            fields: [
                {
                    label: 'Contact Person Name',
                    key: 'name',
                    required: true
                },
                {
                    key: 'countryCode',
                    customField: (
                        <div style={{marginTop: 0}}>
                            <CountryCodeWithFlag
                                countryCode={this.state.countryCode}
                                chooseCode={(val) => {
                                    this.setState({
                                        countryCode: val
                                    })
                                }}/>
                        </div>
                    )
                },
                {
                    label: 'Mobile no',
                    key: 'mobile',
                    type: "number",
                    required: true
                },
                {
                    label: 'Email',
                    key: 'email',
                    required: true
                },
                {
                    label: 'Password',
                    key: 'password',
                    type: 'password',
                    required: true
                },

                /* {
                   label: 'Branch',
                   key: 'branchId',
                   required: true,
                   type: 'select',
                   valueAccessor: x => x.branchName,
                   keyAccessor: x => x._id,
                   options: this.state.allBranch,
                   onChange: v => {
                     setFieldsValue({
                       branchId: v
                     })
                   }
                 },*/
                {
                    key: 'countryName',
                    label: 'Country',
                    type: 'select',
                    showSearch: true,
                    options: this.state.allCountries,
                    keyAccessor: x => x.name,
                    valueAccessor: x => `${x.name}`,
                    onChange: x => {
                        this.props.form.setFieldsValue({
                            countryName: x
                        })
                        this.events.chooseCountry(x)
                    }
                },
                {
                    key: 'stateName',
                    label: 'Province/State',
                    type: 'select',
                    showSearch: true,
                    options: this.state.allStates,
                    keyAccessor: x => x.name,
                    valueAccessor: x => `${x.name}`,
                    onChange: x => {
                        this.props.form.setFieldsValue({
                            stateName: x
                        })
                        this.events.chooseState(x)
                    }
                },
                {
                    key: 'cityName',
                    label: 'City/Town',
                    type: 'select',
                    showSearch: true,
                    hidden: getFieldValue('countryName') !== 'India',
                    options: this.state.allCities,
                    keyAccessor: x => x.name,
                    valueAccessor: x => `${x.name}`,
                    onChange: x => {
                        this.props.form.setFieldsValue({
                            cityName: x
                        })
                        this.events.chooseCity(x)
                    }
                },
                {
                    key: 'cityName',
                    label: 'City/Town',
                    hidden: getFieldValue('countryName') == 'India',
                    onChange: (e) => {
                        let {value} = e.target
                        this.props.form.setFieldsValue({
                            cityName: value
                        })
                        this.events.chooseCity(value)
                    }
                },

                {
                    key: 'address',
                    label: 'City/Town',
                    span: 16,
                    customField: (
                        <InputBox title={'Address'}>
                            <TextArea rows={4} className={'form-control'} value={this.state.address} onChange={(e) => {
                                this.setState({address: e.target.value})
                            }}>
                            </TextArea>
                        </InputBox>
                    )
                }

                // {
                //   key: 'enableChanelPartner',
                //   label: 'Show Channel Partner',
                //   type: 'select',
                //   required: true,
                //   keyAccessor: x => x.value,
                //   valueAccessor: x => `${x.name}`,
                //   options: [{ name: 'Enable', value: true }, { name: 'Disable', value: false }],
                //   onChange: x => {
                //     setFieldsValue({
                //       enableChanelPartner: x
                //     })
                //   }
                // },
                // {
                //   key: 'showAustraliaUniversity',
                //   label: 'Show Australia University',
                //   type: 'select',
                //   required: true,
                //   keyAccessor: x => x.value,
                //   valueAccessor: x => `${x.name}`,
                //   options: [{ name: 'Enable', value: true }, { name: 'Disable', value: false }],
                //   onChange: x => {
                //     setFieldsValue({
                //       showAustraliaUniversity: x
                //     })
                //   }
                // }
            ]
        }


        return (
            <div className="row">
                <div className="col-lg-12">
                    <Form onSubmit={this.handleSubmit}>
                        <div className="form-box mt-4">

                            <div className="d-flex align-items-center mb-3 heading-form">
                                <h5>Add User</h5>
                            </div>

                            <div className="card unizportal">
                                <Row gutter={16} className={'rowWrap'}>
                                    {inputTypes.fields.map((item, key) => {
                                        return (
                                            <React.Fragment key={key}>
                                                {item.customField ? <Col span={item.span ? item.span : 8}
                                                                         key={key}>{item.customField}</Col> :
                                                    !item.hidden && <Col span={item.span ? item.span : 8} key={key}>
                                                        <GetEachFormFields
                                                            item={item}
                                                            getFieldDecorator={getFieldDecorator}
                                                            formItemLayout={formItemLayout}/>
                                                    </Col>}
                                            </React.Fragment>
                                        )
                                    })}
                                    {/* <Col span={8}>
                    <InputBox title={'Logo'}>
                      <Input type={'file'} className={'form-control'} key={this.state.uploadKey} name={'logo'}
                             id={'logo'}
                             onChange={(e) => {
                               this.setState({
                                 logo: e.target.files && e.target.files.length ? e.target.files[0] : null
                               })
                             }}/>
                    </InputBox>
                  </Col>*/}
                                    {/* <Col span={24}>
                    <InputBox title={'Address'}>
                      <TextArea rows={4} className={'form-control'} value={this.state.address} onChange={(e) => {
                        this.setState({ address: e.target.value })
                      }}>
                      </TextArea>
                    </InputBox>
                  </Col>*/}
                                </Row>


                                <Form.Item>
                                    <Button type="primary" htmlType="submit" loading={this.props.loading}
                                            className={'btn'}>
                                        SAVE
                                    </Button>
                                </Form.Item>

                            </div>
                        </div>

                    </Form>
                </div>
            </div>
        )
    }

}

const mapStateToProps = ({global, router}) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    search: router.location.search
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddUser)
