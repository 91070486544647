import React from 'react'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import {LazyLoadImage} from 'react-lazy-load-image-component'

const RelatedBuildings = props => {
    const options = {
        autoplay: true,
        loop: true,
        nav: true,
        dots: false,
        dotClass: 'owl-dot dotNone',
        margin: 25,
        autoplayTimeout: 1500,
        // autoplaySpeed:2000,
        // navSpeed:2000,
        responsive: {
            0: {
                items: 4,
                nav: true
            },
            600: {
                items: 4,
                nav: false
            },
            1000: {
                items: 4,
                nav: true,
                loop: false
            }
        }
    }
    let {building} = props
    let {buildingRelated} = building
    let openPage = propertyUrl => {
        window.open(`/student-accommodation/${propertyUrl}`, '_blank')
    }

    return (

        <>
            {buildingRelated && buildingRelated.length ? (

                <div className="container">
                    <div className="main-title-4">
                        <h1>
                            <span>Related Properties</span>
                        </h1>
                    </div>

                    <OwlCarousel className="owl-theme"  {...options} >
                        {buildingRelated.map((item, key) => {
                            return (
                                <div className="property-2" key={key}>
                                    <div className="property-inner">
                                        <div className="property-overflow">
                                            <div className="property-photo">
                                                <LazyLoadImage
                                                    src={item.image_path_related}
                                                    alt="rp"
                                                    className="img-fluid"
                                                />

                                                <div className="price-ratings">
                                                    <div
                                                        className="price">${item.min_price} / {item.price_structure}</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="content">
                                            <h4 className="title">
                                                <a onClick={() => {
                                                    openPage(item.building_uri)
                                                }}>
                                                    {item.name}
                                                </a>
                                            </h4>

                                            <h3 className="property-address">
                                                <a>
                                                    <i className="fa fa-map-marker"></i>
                                                    &nbsp; {building.city_name}, {building.country_name}
                                                </a>
                                            </h3>
                                        </div>

                                        <ul className="facilities-list clearfix">
                                            <li>
                                                <span>Penthouse</span>
                                            </li>
                                            <li>
                                                <span>Shared Flat</span>
                                            </li>
                                            <li>
                                                <span>Studio</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            )
                        })}
                    </OwlCarousel>
                </div>

            ) : null}
        </>
    )
}
export default RelatedBuildings
