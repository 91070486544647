import loadable from '@loadable/component'
import React, {Suspense, useEffect} from 'react'
import {Helmet} from 'react-helmet'
import Footer from './footer'
import Header from './header'
import {useDispatch} from "react-redux";
import {push} from "connected-react-router";

const InnerContent = React.lazy(() => import('./innerContent'))

const Home = props => {
    let dispatch = useDispatch()
    let {children} = props;

  /*  useEffect(() => {
        checkIsLoggedIn()
    }, [])
*/
    const checkIsLoggedIn = () => {
        let resp = localStorage.getItem('token') != 'undefined'
            ? localStorage.getItem('token')
            : null
        if (!resp) {
            dispatch(push('/login'))
        }
    }

    return (
        <React.Fragment>
            <Helmet>
                {/*  <link
                    rel="stylesheet"
                    href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
                />*/}
                <link
                    rel="stylesheet"
                    href="../unizHome/css/font-awesome.min.css"
                />
                <link rel="stylesheet" href="../../assets/css/responsive.css"/>
                <link rel="stylesheet" href="../../unizHome/css/home.css"/>
                {/*<link rel="preconnect" href="https://fonts.gstatic.com"/>*/}
                {/* <link
                    href="https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;600;700&display=swap"
                    rel="stylesheet"
                />*/}
            </Helmet>
            <Header/>
            <Suspense fallback={<div/>}>
                <InnerContent {...props} />
            </Suspense>
            <Footer/>
        </React.Fragment>
    )
}

export default Home
