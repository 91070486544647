import React, { useEffect, useState, useRef } from 'react'
import { Icon, Progress, Carousel } from 'antd'
import { getAchievedTargetsFxn } from '../../addtarget/actions'
import { useDispatch } from 'react-redux'
import { countryIds } from '../../../components/_utils/appUtils'
import moment from 'moment'

const settingsUni = {
    dots: false,
    infinite: true,
    speed: 1000,
    lazyLoad: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: true,
    autoplaySpeed: 4000
}

const TargetBanner = ({ userId }) => {
    console.log(userId, '===')
    const [targetData, setTargetData] = useState()
    const [remainingDay, setRemainingDay] = useState()
    const [user, setuser] = useState({})
    const dispatch = useDispatch()
    let carousel = useRef()
    useEffect(() => {
        targetManager()
        let user = (localStorage.getItem('user') != 'undefined') ? JSON.parse(localStorage.getItem('user')) : null
        setuser(user)
    }, [])


    const targetManager = async () => {
        let resp = await dispatch(getAchievedTargetsFxn({ userId }))
        if (resp && resp.success) {
            let { data } = resp
            if (data.countryId === countryIds.canada) {

                data.data = data.data.map((item, key) => {
                    item.completeTarget = 0;
                    let achieveTarget
                    if (key == 0) {
                        item.completeTarget = resp.fcmt;
                        achieveTarget = (resp.fcmt * 100) / item.target
                    } else if (key == 1) {
                        item.completeTarget = resp.cambie;
                        achieveTarget = (resp.cambie * 100) / item.target
                    } else {
                        item.completeTarget = resp.achievedTarget;
                        achieveTarget = (resp.achievedTarget * 100) / item.target
                    }
                    return { ...item, achievedTarget: Math.round(achieveTarget) }
                })
            } else {
                data.data = data.data.map((item, key) => {
                    item.completeTarget = resp.achievedTarget?resp.achievedTarget:0;
                    let achieveTarget = resp.achievedTarget?(resp.achievedTarget * 100) / item.target:0
                    return { ...item, achievedTarget: Math.round(achieveTarget) }
                })
            }
            setTargetData(data)
            let end = moment(data.toDate);
            let current = moment().startOf('day');
            let rmDate = moment.duration(end.diff(current)).asDays();
            setRemainingDay(parseInt(rmDate))
        }
    }
    return (
        <>

            {user && user.userType === 'admin' ?
                <div className='row pt-5'>
                    {targetData && targetData.data.map((item) => {
                        return <>
                            <div className='col-lg-6'>
                                <div className='targetBan rounded mb-2'>
                                    <div className='d-flex rounded bg-white pl-2 py-2 align-items-center'>
                                        <div className='mr-1'><Icon className="bg-success rounded-circle p-1 text-white"
                                            type="check" /></div>
                                        <div className='t-text'>Target Moment is just a few steps Away!</div>
                                    </div>
                                    <div className='d-flex justify-content-center mt-4 text-white align-items-center'>
                                        <div className='bigText mr-4 text-right'>Target<br /> Achieved</div>
                                        <div className='t-text position-relative'><span
                                            className='val'>{item.achievedTarget}</span><sup>%</sup></div>
                                    </div>
                                    <div className='d-flex justify-content-center mt-4'>
                                        <h6 className='rounded-pill bg-white py-1 w-75 text-center p-2'>Remaining Days
                                            : {remainingDay}</h6>
                                    </div>

                                    <div className='text-center mt-4 p-3 rounded text-white'
                                        style={{ background: '#165d9a9e' }}>
                                        <Progress
                                            className='text-white'
                                            strokeColor={{
                                                from: '#108ee9',
                                                to: '#87d068',
                                            }}
                                            percent={item.achievedTarget}
                                            status="active"
                                        />
                                        <div className='d-flex justify-content-between align-content-center pt-2 t-text'>
                                            <span>{item.completeTarget} </span>
                                            <span>{item.target} ({item.name})</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    })}
                </div>
                :
                targetData && targetData.data && targetData.data.length ?
                    <div className={'flexBox1 flairUniversityBlockOuter'}>
                        <Carousel ref={carousel} arrows={true} autoplay {...settingsUni}>
                            {targetData && targetData.data.map((item) => {
                                return <>
                                    <div className='targetBan rounded mb-2'>
                                        <div className='d-flex rounded bg-white pl-2 py-2 align-items-center'>
                                            <div className='mr-1'><Icon
                                                className="bg-success rounded-circle p-1 text-white"
                                                type="check"/></div>
                                            <div className='t-text'>Target Moment is just a few steps Away!</div>
                                        </div>
                                <div className='d-flex justify-content-center mt-4 text-white align-items-center'>
                                    <div className='bigText mr-4 text-right'>Target<br /> Achieved</div>
                                    <div className='t-text position-relative'><span
                                        className='val'>{item.achievedTarget}</span><sup>%</sup></div>
                                </div>
                                <div className='d-flex justify-content-center mt-4'>
                                    <h6 className='rounded-pill bg-white py-1 w-75 text-center p-2'>Remaining Days
                                        : {remainingDay}</h6>
                                </div>

                                <div className='text-center mt-4 p-3 rounded text-white'
                                    style={{ background: '#165d9a9e' }}>
                                    <Progress
                                        className='text-white'
                                        strokeColor={{
                                            from: '#108ee9',
                                            to: '#87d068',
                                        }}
                                        percent={item.achievedTarget}
                                        status="active"
                                    />
                                    <div className='d-flex justify-content-between align-content-center pt-2 t-text'>
                                        <span>{item.completeTarget} </span>
                                        <span>{item.target} ({item.name})</span>
                                    </div>
                                </div>
                            </div>
                        </>
                    })}
                </Carousel>
            </div> : ""
            }
        </>
    )
}

export default TargetBanner



