import React, {useEffect, useState, Suspense} from 'react'
import {Form, Modal} from 'antd'
import {connect, useDispatch} from 'react-redux'
import moment from 'moment'
import {push} from 'connected-react-router'
import {
  getEnquiryByUser,
  counsellingHistory,
  updateCounsellorAvailability,
  stopCounsellorWaitingFxn
} from '../counselling/actions/counselling'
import {getUser} from '../users/actions/user'
import {getStudentDetails} from '../student/actions/student'
import {studentSelectedApplications} from '../applications/actions/chooseStudent'

const DocumentBlock = React.lazy(() => import('./documentBlock'))
const NotesBlock = React.lazy(() => import('./notesBlock'))
const InfoBlock = React.lazy(() => import('./infoBlock'))

const initialState = {
  studentData: {},
  applicationId: '',
  momentKey: moment(),
  noteDrawerVisible: false,
  document: {},
  studentKeyMoment: null
}

const StudentProfile = props => {
  const dispatch = useDispatch()
  let [state, setState] = useState(initialState)
  const [enroll, setEnroll] = useState([])
  const [userData, setUserData] = useState([])
  const [activeStudentData, setActiveStudentData] = useState({})

  useEffect(() => {
    document.body.className = 'applicationProfile'
    StudentData()
    getHistory()
    getUserData()
  }, [])

  let {studentData} = state
  let {applications} = studentData

  // Get all enroll student list
  const StudentData = async () => {
    let {data} = await dispatch(getEnquiryByUser())
    console.log(data, '2356')
    setEnroll(data)
    if (data && data[0]) {
      loadStudent(data[0])
    }
  }

  // Get User History
  const getHistory = async () => {
    let data = await dispatch(counsellingHistory(enroll[0]))
    console.log(data, '1')
  }

  //get branch user details
  const getUserData = async () => {
    let Data = JSON.parse(localStorage.getItem('user'))
    let resp = await dispatch(getUser(Data._id))
    console.log(resp, 'kk')
    setUserData(resp)
  }

  const counsellorEvents = {
    changeCounsellorStatus: async value => {
      let result = await dispatch(updateCounsellorAvailability())
      getUserData()
    },
    stopWaiting: async () => {
      let resp = await dispatch(stopCounsellorWaitingFxn())
      refreshList()
    }
  }

  const refreshList = () => {
    StudentData()
    getUserData()
    // getUserHistoryData()   //comment out once i'll fetch history function
  }

  const events = {
    showDrawer: () => {
      setState({...state, noteDrawerVisible: true})
    },
    hideDrawer: () => {
      setState({...state, noteDrawerVisible: false})
    },
    setLocalState: e => {
      let {name, value} = e.target
      setState({
        ...state,
        [name]: value
      })
    },
    chooseDocument: e => {
      let {name, files} = e.target
      if (files && files.length) {
        setState({
          ...state,
          document: files[0]
        })
      }
    },

    handleSubmit: async () => {
      let obj = {
        studentId: studentData._id,
        note: state.note,
        applicationId: state.applicationId
      }
      let fd = new FormData()
      fd.append('obj', JSON.stringify(obj))
      fd.append('document', state.document)
      /*let data = await dispatch(submitNote(fd))
                  if (data && !data.error) {
                      dispatch({type: 'START_PUSH_NOTE_BY_REDUX', noteId: data.noteId})
                      setState({
                          ...state,
                          note: '',
                          document: '',
                          documentKey: moment(),
                          noteDrawerVisible: false
                      })
                  }*/
    }
  }

  // get student details
  let loadStudent = async data => {
    let result = await dispatch(getStudentDetails(data))
    console.log(result, 'result')
    setActiveStudentData(result)
  }


  const reloadStudent = () => {
  }
  return (
      <React.Fragment>
        <div className={'appProfile'} key={state.studentKeyMoment}>
          <div className={'align-self-stretch'}>
            <Suspense fallback={<></>}>
              <InfoBlock
                  updateStatus={counsellorEvents.changeCounsellorStatus}
                  enroll={enroll} //send all enroll students
                  userData={userData} //send branch user
              />
            </Suspense>
            <div className="student-dashboard">
              <div className="row m-0">
                <div className="col-lg-8 p-0">
                  <Suspense fallback={<></>}>
                    <NotesBlock
                        enroll={enroll} //send all enroll students
                        activeStudentData={activeStudentData}
                        userData={userData} //send branch user
                        StudentData={StudentData}
                    />
                  </Suspense>
                </div>
                <Suspense fallback={<></>}>
                  <div className="col-lg-4 p-0">
                    <DocumentBlock/>
                  </div>
                </Suspense>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
  )
}

const mapStateToProps = ({global, router}) => ({
  search: router.location.search,
  pathname: router.location.pathname
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
const WrappedComponent = Form.create()(StudentProfile)
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WrappedComponent)
