import React, { useState, useEffect, useRef } from 'react'
import {
  Tabs, Drawer, Card, Col, Select, Row, notification, Popconfirm, Button
} from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import { getUrlPushWrapper } from '../../../routes'
import { DefaultTablePagination, filterOption } from '../../../components/_utils/appUtils'
// import { listAllCommission } from '../actions'
import { displayDate, MonthsName, YearList } from '../../../components/_utils/appUtils'
import { RowTable } from '../../../components/_utils/RowTable'
import { getRatingLogsById } from '../actions/user'
import _ from 'lodash'

const { TabPane } = Tabs
const { Option } = Select

const RatingLogsDrawer = (props) => {
  let { visible, onClose, agentId } = props
  let [user, setUser] = useState({})
  let [allCommission, setAllCommission] = useState([])
  const [itemNo, setItemNo] = useState(1)
  const [selectedRow, setSelectedRow] = useState({})
  const [visibleStu, setVisibleStu] = useState(false)
  const [year, setYear] = useState('')
  const [month, setMonth] = useState({})
  const [filterType, setFilterType] = useState('')
  const tableRef = useRef()
  const dispatch = useDispatch()


  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      let { userData } = props
      let obj = {
        userId: userData._id,
        filterType: filterType
      }
      if (filterType == 'month') {
        if (!month) {
          notification.error({ message: 'Please Choose month' })
        } else if (!year) {
          notification.error({ message: 'Please Choose year' })
        } else {
          obj.month = month.key
          obj.year = year
        }
      } else if (filterType == 'year') {
        if (!year) {
          notification.error({ message: 'Please Choose year' })
        } else {
          obj.year = year
        }
      }
      console.log('rating obj', obj)

      let resp = await dispatch(getRatingLogsById(obj))
      console.log('rating resp', resp)
      resolve(resp)
    })
  }

  const reloadApiRequest = (params) => {
    return new Promise(async (resolve) => {
      let { userData } = props
      let obj = {
        userId: userData._id,
        filterType: filterType
      }
      if (filterType == 'month') {
        if (!month) {
          notification.error({ message: 'Please Choose month' })
          return
        } else if (!year) {
          notification.error({ message: 'Please Choose year' })
          return
        } else {
          obj.month = month.key
          obj.year = year
        }
      } else if (filterType == 'year') {
        if (!year) {
          notification.error({ message: 'Please Choose year' })
        } else {
          obj.year = year
        }
      }
      console.log('rating obj', obj)

      let resp = await dispatch(getRatingLogsById(obj))
      console.log('rating resp', resp)
      if (resp) {
        tableRef.current.reload()
      }
      resolve(resp)
    })

  }


  useEffect(() => {
    loadUser()
  }, [])

  const loadUser = async () => {
    let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
    setUser(user)
  }

  const openApp = (record) => {
    let studentId = record.studentId._id
    if (user && user.userType && user.userType == 'admin') {
      window.open(`/student/application?studentId=${studentId}&appId=${record.application_id}`, '_blank')
    } else {
      dispatch(
        getUrlPushWrapper('application.singleApplication', {
          appId: record.applications_id,
          studentId: studentId
        }))
    }
  }


  const callback = (key) => {
    //  console.log(key)
  }

  const events = {
    showStuDrawer: (row) => {
      if (row) {
        row.name = row.studentId.name
        row.id = row.studentId.studentId
        row.universityName = row.universityId.universityName
        row.courseName = row.courseId.courseName
      }
      setVisibleStu(true)
      setSelectedRow(row)
    },
    hideStuDrawer: (row) => {
      setVisibleStu(false)
      setSelectedRow({})
    }
  }

  const columns = [

    {
      title: 'S.No',
      dataIndex: 'sno',
      width: 50,
      key: 'sno',
      render: (value, item, index) => (itemNo - 1) * 10 + index + 1
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      width: 150,
      key: 'createdAt',
      render: (item) => {
        return item ? displayDate(item) : null
      }
    },
    {
      title: 'Added',
      dataIndex: 'add',
      key: 'add',
      render: (item, record) => {
        return (
          <div>
            {record.pointsType == 'add' ?
              <label className={'label label-success-green sm'}>
                {record && record.points ? record.points : ''}
              </label> : null}
          </div>
        )
      }
    },
    {
      title: 'Deducted',
      dataIndex: 'deduct',
      key: 'deduct',
      render: (item, record) => {
        return (
          <div>
            {record.pointsType == 'deduct' ?
              <label className={'label label-danger sm'}>
                {record && record.points ? record.points : ''}
              </label> : null}
          </div>
        )
      }
    },
    {
      title: 'Comments',
      dataIndex: 'comments',
      key: 'comments'
    }
    // {
    //   title: 'Action',
    //   dataIndex: 'action',
    //   width: 100,
    //   key: 'action',
    //   render: (item, record) => {
    //     return record && record.studentId ?
    //       <button className='btn' onClick={() => {
    //         events.showStuDrawer(record)
    //       }}><img src='/dist/img/view.svg' alt='' className={'eyeBtn'} /></button>
    //       : null
    //   }
    // }

  ]

  const StudentInfoComponent = (
    <Card>
      <div className={'tableBox striped'}>
        <RowTable title={'Student Name'} value={selectedRow && selectedRow.name ? selectedRow.name : ''} />
        <RowTable title={'Student ID'} value={selectedRow && selectedRow.id ? selectedRow.id : ''} />
        <RowTable title={'App ID'} value={<a className={'hyperLink'}
                                             onClick={() => openApp(selectedRow)}>{selectedRow && selectedRow.applicationId ? selectedRow.applicationId : ''}</a>} />
        <RowTable title={'University'}
                  value={selectedRow && selectedRow.universityName ? selectedRow.universityName : ''} />
        <RowTable title={'Course'} value={selectedRow && selectedRow.courseName ? selectedRow.courseName : ''} />
      </div>
    </Card>
  )

  return (

    <Drawer visible={visible} onClose={onClose} width={'60%'} title={'Ratings Logs'}>

      <Row gutter={12} style={{ padding: 10, marginTop: 10, backgroundColor: '#ddd' }}>

        <Col md={6} sm={6} xs={6} lg={4}>
          <div className='sort-box-table custom-sort-box-new'>
            <Select name='Choose Filter'
                    onChange={(item) => {
                      setFilterType(item)
                      if (item == 'year') {
                        setMonth('')
                      }
                    }}
                    filterOption={filterOption}
                    className={'antSelect'}
                    placeholder='choose filter'
                    allowClear={true}
                    showSearch={true}
                    value={filterType || undefined}>
              <Option value={'month'}>Month</Option>
              <Option value={'year'}>Year</Option>
            </Select>
          </div>
        </Col>

        {filterType == 'month' ? <Col md={6} sm={6} xs={6} lg={4}>
          <div className='sort-box-table custom-sort-box-new'>
            <Select name='Month'
                    onChange={(item) => {
                      setMonth(item)
                    }}
                    filterOption={filterOption}
                    className={'antSelect'}
                    placeholder='choose Month'
                    allowClear={true}
                    showSearch={true}
                    value={month.name || undefined}>
              {MonthsName ? _.map(MonthsName, (item, key) => {
                return (
                  <Option value={item} key={key}>{item.name}</Option>
                )
              }) : null}
            </Select>
          </div>
        </Col> : null}
        {filterType == 'year' || filterType == 'month' ? <Col md={6} sm={6} xs={6} lg={4}>
          <div className='sort-box-table custom-sort-box-new'>
            <Select name='Year'
                    onChange={(item) => setYear(item)}
                    filterOption={filterOption}
                    className={'antSelect'}
                    placeholder='choose Year'
                    allowClear={true}
                    showSearch={true}
                    value={year || undefined}>
              {YearList ? YearList.map((item, key) => {
                return (
                  <Option value={item} key={key}>{item}</Option>
                )
              }) : null}
            </Select>
          </div>
        </Col> : null}
        <Col span={6}>
          <Button className={'roundBtn'} onClick={() => {
            reloadApiRequest()
          }}>Search</Button>
        </Col>
      </Row>

      <div className='card-body table-responsive'>

        <TableComp columns={columns}
                   apiRequest={apiRequest}
                   pagination={DefaultTablePagination()}
                   ref={tableRef} />

      </div>
    </Drawer>
  )
}

export default RatingLogsDrawer
