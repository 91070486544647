import { Button, Checkbox, Col, Form, Icon, Input, notification } from 'antd'
import { push } from 'connected-react-router'
import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import GetEachFormFields from '../../components/_utils/appFormUtils'
import {
  DocumentTypes,
  InputBox,
  ProgramLevel,
  fcmtUniversity
} from '../../components/_utils/appUtils'
import {
  CountryCodeWithFlag,
  CountryJson
} from '../../components/_utils/countryUtil'
import {
  hideLoader,
  hidePageLoad,
  showLoader,
  showPageLoad
} from '../../modules/actions'
import { listCourseCampus } from '../campus/actions/campus'
import { loadCourseIntakesFxn } from '../course/actions/courseAction'
import { checkBranchUserExists } from '../student/actions/student'
import {
  StudentWithoutAuthSendOtp,
  addStudentWithoutAuth,
  countryList,
  getCourse,
  getUniversity,
  registerNewStudent
} from './action'
import { RegisterButton } from './registerButton'

class EducatorForm extends React.Component {
  events = {
    getUniversityByCountry: async countryId => {
      if (countryId) {
        let { data } = await getUniversity({
          results: 1000000,
          universityCountry: countryId,
          sortField: 'universityName',
          sortOrder: 'ascend',
          select: ['universityName', 'applicationFee']
        })
        this.setState({ universityList: data, courseList: [] })
      }
    },
    getCourseByUniversity: async universityId => {
      if (universityId) {
        let { data } = await getCourse({
          results: 1000000,
          courseUniversity: universityId,
          sortField: 'courseName',
          sortOrder: 'ascend',
          select: ['courseName', 'tuitionFee']
        })
        this.setState({ courseList: data })
      }
    },
    chooseDocument: e => {
      let { name, files } = e.target
      if (files && files.length) {
        this.setState({
          [name]: files[0]
        })
      }
    },
    loadCourseIntakesFxn: async courseId => {
      const { getFieldValue, setFieldsValue } = this.props.form
      let resp = await this.props.dispatch(loadCourseIntakesFxn({ courseId }))
      let data = []
      _.each(resp, item => {
        if (item) {
          item.value = `${item.intake}-${item.year}`
          item.label = `${item.intake}, ${item.year}`
          data.push(item)
        }
      })
      this.setState({ intakesList: data })
      if (
        getFieldValue('universityId') == fcmtUniversity &&
        resp &&
        resp.length
      ) {
        setFieldsValue({
          intakeTemp: data[0].value
        })
        let intake = {
          month: resp[0].intake,
          year: resp[0].year
        }
        this.setState({ intake })
      }
    },
    checkCourseBranchUser: data => {
      return new Promise(async (resolve, reject) => {
        let { universityList, courseList, intakesList } = this.state
        let valData = {
          courseId: data.courseId,
          countryId: data.countryId,
          universityId: data.universityId,
          intake: data.intake
        }
        valData.courseUniversity = valData.universityId
        valData.universityCountry = valData.countryId

        let course = _.find(courseList, item => {
          return item._id === valData.courseId
        })
        if (course && course.courseName) {
          valData.tuitionFee = course.tuitionFee
          valData.courseName = course.courseName
        }
        let university = _.find(universityList, item => {
          return item._id === valData.universityId
        })
        if (university && university.universityName) {
          valData.applicationFee = university.applicationFee
          valData.universityState =
            university.universityState && university.universityState._id
              ? university.universityState._id
              : ''
          valData.universityCity =
            university.universityCity && university.universityCity._id
              ? university.universityCity._id
              : ''
        }

        let { success, message } = await this.props.dispatch(
          checkBranchUserExists(valData)
        )
        if (success) {
          resolve({ type: true, application: valData })
        } else {
          notification.warning({ message: message })
          resolve({ type: false })
        }
      })
    },
    loadCampus: async item => {
      const { getFieldValue, setFieldsValue } = this.props.form
      let obj = {
        results: 1000000,
        courseId: item
      }
      let { data } = await this.props.dispatch(listCourseCampus(obj))
      this.setState({
        campusList: data
      })
      if (
        getFieldValue('universityId') == fcmtUniversity &&
        data &&
        data.length
      ) {
        setFieldsValue({
          campusId: data[0]._id
        })
      }
    }
  }
  weekList = [
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    29,
    30,
    31,
    32,
    33,
    34,
    35,
    36,
    37,
    38,
    39,
    40,
    41,
    42,
    43,
    44,
    45,
    46,
    47,
    48,
    49,
    50,
    51,
    52
  ]
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      countryList: [],
      universityList: [],
      courseList: [],
      intakesList: [],
      intake: {},
      finalObjForAddStudent: {},
      isOtpScreen: false,
      campusList: [],
      countryCode: '',
      alternateCountryCode: ''
    }
    props.dispatch(hidePageLoad())
    props.dispatch(hideLoader())
    this.checkIsLoggedIn()
    this.loadCountry()
  }

  checkIsLoggedIn() {
    let resp = localStorage.getItem('token')
    if (resp) {
      let { dispatch } = this.props
      dispatch(push('/dashboard'))
    }
  }

  async loadCountry() {
    let { data } = await countryList()
    this.setState({ countryList: data, universityList: [], courseList: [] })
  }

  submitForOtp = e => {
    const { dispatch, form } = this.props
    e.preventDefault()
    this.props.form.validateFields(async (err, values) => {
      if (!err) {

        if (!(this.state.countryCode && this.state.alternateCountryCode)) {
          notification.error({
            message: 'Please choose Country Code'
          })
          return
        }
        values.countryISOCode = this.state.countryCode
        values.alternateCountryCode = this.state.alternateCountryCode
        values.registerCountry = values.countryId
        try {
          let resp = await dispatch(StudentWithoutAuthSendOtp(values))
          let { success, message } = resp
          if (success) {
            this.setState({ finalObjForAddStudent: values, isOtpScreen: true })
          }
        } catch (e) {
          if (e) {
            notification.error({ message: JSON.stringify(e) })
          }
          dispatch(hidePageLoad())
        }
        /*this.events.checkCourseBranchUser(values).then(async appResp => {
          if (appResp && appResp.type) {
            values.application = [appResp.application]
            try {
              let resp = await dispatch(StudentWithoutAuthSendOtp(values))
              let { success, message } = resp
              if (success) {
                this.setState({
                  finalObjForAddStudent: values,
                  isOtpScreen: true
                })
              }
            } catch (e) {
              if (e) {
                notification.error({ message: JSON.stringify(e) })
              }
              dispatch(hidePageLoad())
            }
          } else {
            dispatch(hidePageLoad())
          }
        })*/

        /*let resp = await dispatch(StudentWithoutAuthSendOtp(values))
        let { success, message } = resp
        if (success) {
          this.setState({
            finalObjForAddStudent: values,
            isOtpScreen: true
          })
        }*/
      }
    })
  }

  handleSubmit = e => {
    const { dispatch, form } = this.props
    e.preventDefault()
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        let { finalObjForAddStudent, profile } = this.state
        let fd = new FormData()

        fd.append(
          'obj',
          JSON.stringify({ ...finalObjForAddStudent, ...values })
        )
        if (profile && profile.name) {
          fd.append('profile', profile)
        }
        _.each(DocumentTypes, item => {
          if (this.state[item]) {
            fd.append([item], this.state[item])
          }
        })

        try {
          // let resp = await dispatch(addStudentWithoutAuth(fd))
          let resp = await dispatch(registerNewStudent(fd))
          let { success, data, message, token } = resp
          if (success) {
            localStorage.setItem('token', token)
            localStorage.setItem('user', JSON.stringify(data))
            this.setState({
              finalObjForAddStudent: {},
              isOtpScreen: false
            })
            dispatch({
              type: 'SET_CURRENT_USER',
              user: data
            })
            dispatch({
              type: 'START_LOAD_CURRENT_USER'
            })
            setTimeout(() => {
              dispatch(hidePageLoad())
              dispatch(push('/dashboard'))
            }, 1000)
          }
        } catch (e) {
          if (e) {
            notification.error({ message: JSON.stringify(e) })
          }
          dispatch(hidePageLoad())
        }

        /*this.events
          .checkCourseBranchUser(finalObjForAddStudent.application[0])
          .then(async appResp => {
            if (appResp && appResp.type) {
            } else {
              dispatch(hidePageLoad())
            }
          })*/
      }
    })
  }

  render() {
    const { getFieldDecorator, getFieldValue, setFieldsValue } = this.props.form
    const { loading, dispatch } = this.props
    const {
      countryList,
      universityList,
      courseList,
      intakesList,
      isOtpScreen,
      campusList,
      countryCode,
      alternateCountryCode
    } = this.state
    let { events } = this

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 }
      }
    }

    const inputFields = [
      {
        key: 'firstName',
        label: 'First Name',
        required: true,
        placeholder: 'Enter First Name',
        span: 6
      },
      {
        key: 'lastName',
        label: 'Last Name',
        required: true,
        placeholder: 'Enter Last Name',
        span: 6
      },
      {
        key: 'dateOfBirth',
        type: 'date',
        label: 'Date Of Birth',
        required: true,
        placeholder: 'Enter Date of Birth',
        span: 6
      },
      {
        key: 'email',
        label: 'Email',
        required: true,
        type: 'email',
        placeholder: 'Enter Email',
        span: 6
      },

      {
        key: 'countryCode',
        span: 6,
        customField: (
          <div style={{ marginTop: -13 }}>
            <CountryCodeWithFlag
              countryCode={countryCode}
              chooseCode={val => {
                this.setState({
                  countryCode: val
                })
              }}
            />
          </div>
        )
      },
      {
        key: 'mobile',
        label: 'Phone Number',
        required: true,
        placeholder: 'Enter Phone No',
        span: 6
      },
      {
        key: 'alternateCountryCode',
        span: 6,
        customField: (
          <div style={{ marginTop: -13 }}>
            <CountryCodeWithFlag
              countryCode={alternateCountryCode}
              chooseCode={val => {
                this.setState({
                  alternateCountryCode: val
                })
              }}
            />
          </div>
        )
      },
      {
        key: 'alternatePhoneNumber',
        label: 'Alternate Phone Number',
        required: true,
        placeholder: 'Enter Phone No',
        span: 6
      },
      {
        key: 'expectedArrivalDate',
        type: 'date',
        label: 'Expected Arrival Date',
        required: true,
        placeholder: 'Expected Arrival Date',
        span: 6
      },
      {
        key: 'accommodation',
        label: 'Accommodation Per Week',
        required: true,
        placeholder: 'Select Accommodation Per Week',
        type: 'select',
        options: this.weekList,
        keyAccessor: x => x,
        valueAccessor: x => `${x}`,
        onChange: (x, y) => {
          setFieldsValue({
            accommodation: x
          })
        },
        span: 6
      },
      {
        key: 'university',
        label: 'University',
        type: 'text',
        required: true,
        placeholder: 'Enter University',
        span: 6
      },
      {
        key: 'studentBudget',
        label: 'Student Budget Per Week',
        required: true,
        placeholder: 'Student Budget Per Week',
        span: 6
      },
      {
        key: 'nationality',
        label: 'Nationality',
        required: true,
        type: 'select',
        options: CountryJson,
        keyAccessor: x => x.name,
        valueAccessor: x => `${x.name}`,
        onChange: x => {
          setFieldsValue({
            nationality: x
          })
          // setFieldsValue({ branchManagerId: '' })
        }
      },
      // {
      //   key: 'profile',
      //   label: 'profile',
      //   type: 'file',
      //   label: 'Profile',
      //   onChange: e => {
      //     events.chooseDocument(e)
      //   },
      //   span: 6
      // },
      {
        key: 'referBy',
        label: 'Referral Code',
        hidden: !(countryCode && countryCode == 'CA_+1'),
        required: countryCode == 'CA_+1',
        placeholder: 'Referral Code',
        span: 6
      } // {
      //   key: 'countryId',
      //   label: 'Country',
      //   required: true,
      //   placeholder: 'Select Country',
      //   type: 'select',
      //   options: countryList,
      //   keyAccessor: x => x._id,
      //   valueAccessor: x => `${x.countryName}`,
      //   onChange: x => {
      //     setFieldsValue({
      //       countryId: x,
      //       universityId: null,
      //       courseId: '',
      //       intakeTemp: '',
      //       campusId: undefined
      //     }, () => {
      //       this.setState({ intake: {} })
      //       events.getUniversityByCountry(x)
      //     })
      //   },
      //   span: 6
      // },
      // {
      //   key: 'eductionLevel',
      //   label: 'Education Level',
      //   placeholder: 'Select Level',
      //   type: 'select',
      //   options: ProgramLevel,
      //   keyAccessor: x => x,
      //   valueAccessor: x => x,
      //   onChange: x => {
      //     setFieldsValue({
      //       eductionLevel: x
      //     })
      //   },
      //   span: 6
      // },

      // {
      //   key: 'universityId',
      //   label: 'University',
      //   placeholder: 'Select University',
      //   required: true,
      //   showSearch: true,
      //   type: 'select',
      //   options: universityList,
      //   keyAccessor: x => x._id,
      //   valueAccessor: x => x.universityName,
      //   onChange: x => {
      //     setFieldsValue({
      //       universityId: x,
      //       courseId: '',
      //       intakeTemp: '',
      //       campusId: undefined
      //     }, () => {
      //       events.getCourseByUniversity(x)
      //       this.setState({ intake: {} })
      //     })
      //   },
      //   span: 12
      // },
      // {
      //   key: 'courseId',
      //   label: 'Course',
      //   placeholder: 'Select Course',
      //   required: true,
      //   showSearch: true,
      //   type: 'select',
      //   options: courseList,
      //   keyAccessor: x => x._id,
      //   valueAccessor: x => x.courseName,
      //   onChange: x => {
      //     setFieldsValue({
      //       courseId: x,
      //       intakeTemp: '',
      //       campusId: undefined
      //     }, () => {
      //       this.setState({ intake: {} })
      //       events.loadCourseIntakesFxn(x)
      //       events.loadCampus(x)
      //     })
      //   },
      //   span: 12
      // },
      // {
      //   key: 'campusId',
      //   label: 'Campus',
      //   placeholder: 'Select Campus',
      //   required: getFieldValue('universityId') == fcmtUniversity,
      //   type: 'select',
      //   options: campusList,
      //   keyAccessor: x => x._id,
      //   valueAccessor: x => x.campusName,
      //   onChange: x => {
      //     setFieldsValue({
      //       campusId: x
      //     })
      //   },
      //   span: 12
      // },
      //
      // {
      //   key: 'intakeTemp',
      //   label: 'Intake',
      //   type: 'select',
      //   required: getFieldValue('universityId') == fcmtUniversity,
      //   placeholder: 'Select Intake',
      //   options: intakesList,
      //   keyAccessor: x => x.value,
      //   valueAccessor: x => x.label,
      //   onChange: x => {
      //     setFieldsValue({
      //       intakeTemp: x
      //     }, () => {
      //       if (x) {
      //         let intakeVal = x.split('-')
      //         let intake = {
      //           month: intakeVal[0],
      //           year: intakeVal[1]
      //         }
      //         this.setState({ intake })
      //       } else {
      //         this.setState({ intake: {} })
      //       }
      //     })
      //   },
      //   span: 12
      // }
    ]

    return (
      <React.Fragment>
        <div id={'loginForm'} className={'hold-transition login-main'}>
          <header className=" w-100 navbar-fix">
            <div className="container">
              <div className="d-flex flex-column flex-md-row align-items-center pt-5">
                <h5 className="my-0 mr-md-auto font-weight-normal">
                  <a onClick={() => dispatch(push('/home'))}>
                    <img src="dist/img/AdminLTELogo.png" alt="" />
                  </a>
                </h5>
                <nav className="my-2 my-md-0 d-flex align-items-center">
                  <a
                    className="p-2 text-dark darkBtn"
                    onClick={() => dispatch(push('/login'))}>
                    <span>Login</span>
                  </a>
                  <RegisterButton />
                </nav>
              </div>
            </div>
          </header>
          <div className="login-form w-100">
            <div className="container containerStu">
              <div className={'inner-container'}>
                <div className="row">
                  <div className="col-lg-6 mr-auto">
                    {!isOtpScreen ? (
                      <div className="login-box w-100 studentRegForm">
                        <h5>Student Registration</h5>
                        <Form onSubmit={this.submitForOtp}>
                          <div className={'row'}>
                            {inputFields.map((item, key) => {
                              return (
                                <React.Fragment key={key}>
                                  {item.customField
                                    ? !item.hidden && (
                                        <Col
                                          className={`form-group col-lg-${
                                            item.span
                                          }`}>
                                          {item.customField}
                                        </Col>
                                      )
                                    : !item.hidden && (
                                        <div
                                          className={`form-group col-lg-${
                                            item.span
                                          }`}
                                          key={key}>
                                          <GetEachFormFields
                                            item={item}
                                            getFieldDecorator={
                                              getFieldDecorator
                                            }
                                            formItemLayout={formItemLayout}
                                          />
                                        </div>
                                      )}
                                </React.Fragment>
                              )
                            })}
                          </div>
                          <div className={'row documents'}>
                            <div className={`form-group col-lg-6`}>
                              <InputBox title={`Profile`}>
                                <Input
                                  type={'file'}
                                  name={'profile'}
                                  id={'profile'}
                                  className={'form-control'}
                                  onChange={e => {
                                    events.chooseDocument(e)
                                  }}
                                />
                              </InputBox>
                            </div>
                            {/*{DocumentTypes.map((item, key) => {*/}
                            {/*  return (*/}
                            {/*    <div className={`form-group col-lg-6`} key={key}>*/}
                            {/*      <InputBox title={`${item} Document`}>*/}
                            {/*        <Input type={'file'} name={item} id={item} className={'form-control'}*/}
                            {/*               title='your text'*/}
                            {/*               onChange={(e) => {*/}
                            {/*                 events.chooseDocument(e)*/}
                            {/*               }} />*/}
                            {/*      </InputBox>*/}
                            {/*    </div>*/}
                            {/*  )*/}
                            {/*})}*/}

                            <div className={'col-lg-9'}>
                              <div className="align-items-center">
                                <Button
                                  className="btn ml-auto btnSub"
                                  type="button"
                                  loading={loading}
                                  htmlType="submit">
                                  Submit
                                </Button>
                              </div>
                            </div>
                          </div>
                        </Form>
                      </div>
                    ) : null}

                    {isOtpScreen && (
                      <div className="login-box w-100 studentRegForm">
                        <h5>
                          {' '}
                          Email : {this.state.finalObjForAddStudent.email}
                        </h5>
                        <h6 style={{ marginBottom: 10 }}>
                          Please enter your verification code sent on your phone
                          & email.
                        </h6>
                        <Form onSubmit={this.handleSubmit}>
                          <div>
                            <div className="form-group">
                              <Form.Item>
                                {getFieldDecorator('otp', {
                                  rules: [
                                    {
                                      required: true,
                                      message: 'Please input your 4 digit otp!'
                                    }
                                  ]
                                })(
                                  <Input
                                    type="text"
                                    minLength={4}
                                    maxLength={4}
                                    autoComplete="otp"
                                    placeholder="OTP – Check your email address"
                                  />
                                )}
                              </Form.Item>
                            </div>

                            <div className="d-flex align-items-center">
                              <Button
                                className="btn"
                                type="button"
                                htmlType="submit">
                                Submit
                              </Button>
                            </div>
                          </div>
                        </Form>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const WrappedEducatorForm = Form.create()(EducatorForm)

const mapStateToProps = ({ counter, global }) => ({
  count: counter.count,
  loading: global.buttonLoading
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedEducatorForm)
