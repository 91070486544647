import React, { useEffect, useRef, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { checkBranchUserExists, getStudent, getStudentDetails, uploadProfileImage } from '../../student/actions/student'
import moment from 'moment'
import _ from 'lodash'
import {
  Avatar,
  Button,
  Form,
  notification,
  Icon
} from 'antd'
import {
  displayDate,
  statusColors,
  ProgramLevel,
  fcmtUniversity,
  DocumentTypes, DocumentTypesForRegister, DocumentTypesRequiredForCanada, DocumentRequiredForOtherCountry
} from '../../../components/_utils/appUtils'
import {
  addCourseInStudent,
  studentSelectedApplications,
  updatePayment
} from '../../applications/actions/chooseStudent'
import { getPendingPendency } from '../../../components/_utils/utils'
import { getPushPathWrapper, getUrlPushWrapper } from '../../../routes'

import {
  registerStudentUniversityCourse,
  registerStudentIntake,
  registerStudentUniversity,
  uploadDocumentByStudent,
  StudentWithoutAuthSendOtp, registerNewStudent
} from '../../login/action'
import { listCourseCampus } from '../../campus/actions/campus'
import localStorage from 'redux-persist/es/storage'
import { hidePageLoad } from '../../../modules/actions'
import { TransactionsDrawer } from './transactionDrawer'
import { ReferralsDrawer } from './referralsDrawer'
import { ConditionDrawer } from './referralConditionsDrawer'
import { getReferralSetting } from '../actions'

let initialState = {
  countryId: '',
  universityId: '',
  courseId: null,
  name: '',
  email: '',
  referralCode: '',
  dateOfBirth: '',
  branchManagerId: '',
  mobile: '',
  profile: {},
  documentsList: [],
  applications: [],
  eductionLevel: '',
  campusId: '',
  countryISOCode: '',
  intake: {},
  loading: false
}

const ReferenceComponent = (props) => {
  const dispatch = useDispatch()
  let [state, setState] = useState(initialState)
  let [student, setStudent] = useState({})
  let [applications, setApplications] = useState([])
  let [showTransactionDrawer, setShowTransactionDrawer] = useState(false)
  let [showReferralsDrawer, setShowReferralsDrawer] = useState(false)
  let [showConditionsDrawer, setShowConditionsDrawer] = useState(false)
  let [applicationData, setApplicationData] = useState({})
  let [intakesList, setIntakesList] = useState([])
  let [courseList, setCourseList] = useState([])
  let [intakeId, setIntakeId] = useState('')
  let [selectedIntake, setSelectedIntake] = useState([])
  let [newIntakes, setNewIntakes] = useState([])
  let [universityList, setUniversityList] = useState('')
  let [referralSetting, setReferralSetting] = useState([])
  let [user, setUser] = useState([])


  const tableRef = useRef()

  useEffect(() => {
    let user = props.currentUser
    console.log('user 5555', user)
    setUser(user)
    loadReferralSetting()
    // loadstudent(user)
  }, [])

  const loadReferralSetting = async () => {
    let { data } = await dispatch(getReferralSetting())
    setReferralSetting(data)
  }

  const setLocalState = (data) => {
    setState({
      ...state,
      ...data
    })
  }


  return (
    <div className={'container'}>
      <div className={'col-lg-12 referHeader'}>
        <h5 style={{ color: 'white' }}> Invite your friends</h5>
      </div>
      <div className={'col-lg-12 row'}>
        <div className={'col-lg-6'}>
          <img src='/dist/img/referFriend.png' width={'100%'} />
          {/*<img src='dist/img/earning.png' />*/}
        </div>
        <div className={'col-lg-6 referInfoBox'}>
          <p>Your Referral Code</p>
          <div className={' row col-lg-12'}>
            <div className={'col-lg-3'}></div>
            <div className={'referCodeBox col-lg-6'}>
              <span style={{ fontWeight: 'bold' }}>{user && user.referCode ? user.referCode : ''}</span>
            </div>
          </div>

          <p style={{ marginTop: 20, marginBottom: 0 }}>When your friends signup using the <br /> referral code You get
            CAD ${referralSetting ? referralSetting.amount : ''}</p>
          <span style={{ fontSize: 13 }}
                onClick={() => {
                  setShowConditionsDrawer(true)
                }}
          ><u>(Conditions Apply)</u></span>
          <br />
          <br />
          <Button style={{ backgroundColor: '#0f52ba', color: 'white' }}><Icon type={'share-alt'}
                                                                               style={{ fontSize: '21px' }} /> SEND
            INVITE</Button>
          <br />
          <br />
          <span onClick={() => {
            setShowReferralsDrawer(true)
          }}><u>Your Referrals</u></span>
          <br />
          <br />
          <span onClick={() => {
            setShowTransactionDrawer(true)
          }}><u>Transactions</u></span>
        </div>
      </div>

      {showConditionsDrawer ?
        <ConditionDrawer
          visible={showConditionsDrawer}
          onClose={() => {
            setShowConditionsDrawer(false)
          }}
        />
        : null}
      {showReferralsDrawer ?
        <ReferralsDrawer
          visible={showReferralsDrawer}
          onClose={() => {
            setShowReferralsDrawer(false)
          }}
        />
        : null}
      {showTransactionDrawer ?
        <TransactionsDrawer
          visible={showTransactionDrawer}
          onClose={() => {
            setShowTransactionDrawer(false)
          }}
        />
        : null}
    </div>
  )
}


const WrappedStudentDashboard = Form.create()(ReferenceComponent)

const mapStateToProps = ({ counter, global }) => ({
  count: counter.count,
  loading: global.buttonLoading,
  currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedStudentDashboard)


// export default StudentDashboard
