import React, {Component, useState, useEffect, useRef} from 'react'
import {TableComp} from 'sz-react-utils-lite'
import {
    Card, Tag,
    Tooltip, Icon, Drawer, List, Popconfirm, Checkbox, notification, Button, Row, Col, Select, Switch
} from 'antd'
import {connect} from 'react-redux'
import {listAllUsers, blockAgent, updateDefaultReferralApi, updateInsuranceRight} from '../actions/user'
import {
    ConditionOptions,
    DefaultTablePagination,
    BooleanOptions, filterOption, Intakes, departmentObj
} from '../../../components/_utils/appUtils'
import UpdatePassword from '../views/changePassword'
import EditUser from '../views/edit'
import EditSubAgent from '../views/editSubAgent'
import {ColumnWidth} from '../../WebComponent/columnWidth'
import ViewApplication from '../../applications/views/viewApplication'
import {CheckUserRight} from '../../dashboard/views/DashboardUserWise'

import {getExportAgent} from '../actions/user'
import {
    AgentListBranchManager
} from '../../../components/_utils/AgentBranchManagerComponent'

import {getUrlPushWrapper} from '../../../routes'
import {LoadAllCountry, LoadCity, LoadState} from '../../../components/_utils/countryUtil'
import _ from 'lodash'
import moment from 'moment'


const {Option} = Select

class AllAgentsForMarketingUsers extends Component {

    events = {
        hideApplicationDrawer: () => {
            this.setState({
                visibleApplicationDrawer: false
            })
        },
        showApplicationDrawer: (data) => {
            this.setState({
                visibleApplicationDrawer: true,
                queryData: {
                    agentId: data._id
                }
            })
        },
        showUserRightDrawer: (data) => {
            this.setState({
                visibleUserRightDrawer: true,
                selectedUser: data
            })
        },
        hideUserRightDrawer: () => {
            this.setState({
                visibleUserRightDrawer: false,
                selectedUser: {}
            })
        },
        closeAndUpdateUserRightDrawer: () => {
            this.setState({
                visibleUserRightDrawer: false,
                selectedUser: {}
            }, () => {
                this.tableRef.current.reload()
            })
        },
        chooseCountry: (name) => {
            let countryName = _.find(this.state.allCountries, (item) => {
                return item.name == name
            })
            if (countryName) {
                this.setState({
                    countryName: countryName.name ? countryName.name : '',
                    stateName: undefined,
                    cityName: undefined,
                    pincode: undefined,
                    allStates: countryName.id ? LoadState(countryName.id) : []
                })
            }
        },
        chooseState: (name) => {
            let stateName = _.find(this.state.allStates, (item) => {
                return item.name == name
            })
            if (stateName) {
                this.setState({
                    stateName: stateName.name ? stateName.name : '',
                    cityName: undefined,
                    pincode: undefined,
                    allCities: stateName.id ? LoadCity(stateName.id) : []
                })
            }
        },
        chooseCity: (name) => {
            this.setState({
                cityName: name ? name : ''
            })
        }
    }

    constructor(props) {
        super(props)
        this.state = {
            viewInfoDrawer: false,
            selectedRow: {},
            allAgents: [],
            branchManager: [],
            selectedUser: {},
            visibleUpdatePassword: false,
            visibleUpdateUser: false,
            visibleUpdateSubAgent: false,
            visibleApplicationDrawer: false,
            queryData: {},
            visibleUserRightDrawer: false,
            approvedAgentRightsUser: [],
            zeroRecordAgent: false,
            filters: {},
            total: 0,
            countryName: '',
            stateName: '',
            cityName: '',
            pincode: '',
            allCountries: LoadAllCountry(),
            allStates: [],
            allCities: [],
            allMarketingUser: [],
            keyMoment: moment(),
            agent: "",
            branchManagerList: []
        }
        this.tableRef = React.createRef()
    }

    componentDidMount() {
        this.loadAllAgent()
        this.loadRightUser()
        this.loadBranchManager()
        this.loadMarketingUser()
        this.loadAllBranchManager()
        this.setFieldByParams()
    }


    async loadBranchManager() {
        let {dispatch} = this.props
        let obj = {
            userType: 'branchManager',
            customQuery: {
                'agents.0': {$exists: true}
            },
            results: 100,
            select: ['name', 'email', 'mobile', 'agents']
        }
        let {data} = await dispatch(listAllUsers(obj))
        this.setState({branchManager: data})
    }


    async loadAllAgent() {
        let {dispatch} = this.props
        let {data} = await dispatch(listAllUsers({userType: 'agent'}))
        this.setState({allAgents: data})
    }

    async loadMarketingUser() {
        let {dispatch} = this.props
        let obj = {
            userType: 'branchUser',
            select: ['name', 'mobile', 'agents'],
            customQuery: {
                department: departmentObj.marketing
            }
        }
        let {data} = await dispatch(listAllUsers(obj))
        this.setState({allMarketingUser: data})
    }

    loadRightUser = async () => {
        let params = {
            results: 200,
            approvedAgent: true,
            select: ['name', 'userType']
        }
        let {data} = await this.props.dispatch(listAllUsers(params))
        this.setState({approvedAgentRightsUser: data})
    }

    viewInfo(record) {
        this.setState({
            selectedRow: record,
            viewInfoDrawer: true
        })
    }

    onClose = () => {
        this.setState({
            selectedRow: {},
            viewInfoDrawer: false
        })
    }

    closeUpdatePassword = () => {
        this.setState({
            selectedUser: {},
            visibleUpdatePassword: false
        }, () => {
            this.tableRef.current.reload()
        })
    }

    closeEditUser = () => {
        this.setState({
            selectedUser: {},
            visibleUpdateUser: false
        }, () => {
            this.tableRef.current.reload()
        })
    }

    closeEditSubAgent = () => {
        this.setState({
            selectedUser: {},
            visibleUpdateSubAgent: false
        }, () => {
            this.tableRef.current.reload()
        })
    }
    updateAgentBlockStatus = async (id) => {
        let {dispatch} = this.props
        let resp = await dispatch(blockAgent({agentId: id}))
        if (resp && resp.success) {
            this.tableRef.current.reload()
        }
    }

    apiRequest = (params) => {
        let {dispatch} = this.props
        return new Promise(async (resolve) => {
            params.sortField = 'name'
            params.sortOrder = 'ascend'
            params.userType = ['agent']


            let resp1 = await this.getParamsForApplicationList()

            if (resp1.countryName) {
                params.countryName = resp1.countryName
            }
            if (resp1.stateName) {
                params.stateName = resp1.stateName
            }
            if (resp1.cityName) {
                params.cityName = resp1.cityName
            }
            if (resp1.pincode) {
                params.pincode = resp1.pincode
            }
            if (resp1.agent) {
                params.name = resp1.agent
            }


            let finalFilter = {
                ...params,
                showAll: true,
                zeroRecordAgent: this.state.zeroRecordAgent,
                regExFilters: ['name', 'address', 'mobile', 'email']
            }
            this.setState({filters: finalFilter})
            let resp = await dispatch(listAllUsers({...finalFilter}))
            this.setState({total: resp.total})
            resolve(resp)
        })
    }

    exportAgentsFxn = async () => {
        let {filters, total} = this.state
        let {dispatch} = this.props
        let {success, message} = await dispatch(getExportAgent({...filters, results: total}))
        if (success) {
            notification.success({message: message})
        } else {
            notification.warning({message: 'Error, please try again'})
            return
        }
    }

    getParamsForApplicationList = () => {
        return new Promise((resolve) => {
            let searchParams = new URLSearchParams(window.location.search)
            let countryName = searchParams.get('countryName')
            let stateName = searchParams.get('stateName')
            let cityName = searchParams.get('cityName')
            let pincode = searchParams.get('pincode')
            let agent = searchParams.get('agent')

            let obj = {}
            if (countryName) {
                obj.countryName = countryName
            }
            if (stateName) {
                obj.stateName = stateName
            }
            if (cityName) {
                obj.cityName = cityName
            }
            if (pincode) {
                obj.pincode = pincode
            }
            if (agent) {
                obj.agent = agent
            }
            resolve(obj)
        })
    }

    searchFxn = async () => {
        let {dispatch} = this.props
        // let resp = await this.getParamsForApplicationList()
        let obj = {}
        if (this.state.countryName) {
            obj.countryName = this.state.countryName
        }
        if (this.state.stateName) {
            obj.stateName = this.state.stateName
        }
        if (this.state.cityName) {
            obj.cityName = this.state.cityName
        }
        if (this.state.pincode) {
            obj.pincode = this.state.pincode
        }
        if (this.state.agent) {
            obj.agent = this.state.agent
        }
        dispatch(
            getUrlPushWrapper('allAgentForMarketingUsers', {
                ...obj
            }))
        // setTableSearch(true)
        setTimeout(() => {
            if (this.tableRef && this.tableRef.current) {
                this.tableRef.current.reload()
            }
        }, 200)
    }
    clearFxn = async () => {
        let {dispatch} = this.props
        // let resp = await getParamsForApplicationList()
        let obj = {}
        this.setState({
            countryName: '',
            stateName: '',
            cityName: '',
            pincode: '',
            agent: ''
        })
        dispatch(
            getUrlPushWrapper('allAgentForMarketingUsers', {
                ...obj
            }))
        setTimeout(() => {
            this.tableRef.current.reload()
        }, 200)
    }

    UpdateInsuranceRight = async (id) => {
        let {dispatch} = this.props
        let resp = await dispatch(updateInsuranceRight({userId: id}))
        if (resp && resp.success) {
            this.tableRef.current.reload()
        }
    }
    loadAllBranchManager = async () => {
        let {dispatch} = this.props;
        let obj = {
            userType: 'branchManager',
            results: 100,
            select: ['name', 'email', 'mobile']
        }
        let {data} = await dispatch(listAllUsers(obj))
        this.setState({
            branchManagerList: data
        })
    }


    setFieldByParams = async () => {
        let {countryName, stateName, cityName, pincode, agent} = await this.getParamsForApplicationList()
        let obj = {}
        if (countryName) {
            obj.countryName = countryName
        }
        if (stateName) {
            obj.stateName = stateName
        }
        if (cityName) {
            obj.cityName = cityName
        }
        if (pincode) {
            obj.pincode = pincode
        }
        if (agent) {
            obj.agent = agent
        }
        this.setState(obj)
    }

    render() {
        let {
            selectedRow,
            allAgents,
            visibleUpdatePassword,
            selectedUser,
            visibleUpdateUser,
            visibleUpdateSubAgent,
            visibleApplicationDrawer,
            queryData,
            branchManager,
            allMarketingUser,
            countryName
        } = this.state
        let {dispatch} = this.props

        const columns = [
            {
                title: '#',
                key: '_id',
                dataIndex: '_id',
                width: 50,
                render: (ite, record, index) => {
                    return (
                        <React.Fragment>
                            {index + 1}
                        </React.Fragment>
                    )
                }
            },
            {
                title: 'Name',
                key: 'name',
                sorter: true,
                dataIndex: 'name',
                // searchTextName: 'name',
                width: 100,
                render: (item, record) => {
                    return (
                        <ColumnWidth width={100}>
                            {item}
                            {/*<CheckActiveUser userId={record._id} {...this.props} />*/}
                        </ColumnWidth>
                    )
                }
            },
            {
                title: 'Company Name',
                key: 'companyName',
                sorter: true,
                dataIndex: 'companyName',
                // searchTextName: 'companyName',
                width: 150
            },
            {
                title: 'Email',
                dataIndex: 'email',
                key: 'email',
                searchTextName: 'email',
                width: 120,
                render: (item) => {
                    return (
                        <ColumnWidth width={120}>{item}</ColumnWidth>
                    )
                }
            },
            {
                title: 'Mobile No',
                dataIndex: 'mobile',
                key: 'mobile',
                searchTextName: 'mobile',
                width: 80,
                render: (item) => {
                    return (
                        <ColumnWidth width={80}>{item}</ColumnWidth>
                    )
                }
            },
            {
                title: 'Address',
                dataIndex: 'address',
                key: 'address',
                searchTextName: 'address',
                width: 150,
                render: (item) => {
                    return (
                        <ColumnWidth width={150}>{item}</ColumnWidth>
                    )
                }
            },
            {
                title: 'City Name',
                dataIndex: 'cityName',
                key: 'cityName',
                // searchTextName: 'address',
                // filters: CityJson.map(x => ({ value: x.name, text: x.name })),
                width: 100,
                render: (item) => {
                    return (
                        <ColumnWidth width={80}>{item}</ColumnWidth>
                    )
                }
            },

            {
                title: 'State Name',
                dataIndex: 'stateName',
                key: 'stateName',
                width: 100,
                // filters: StateJson.map(x => ({ value: x.name, text: x.name })),
                render: (item) => {
                    return (
                        <ColumnWidth width={80}>{item}</ColumnWidth>
                    )
                }
            },
            {
                title: 'Country Name',
                dataIndex: 'countryName',
                key: 'countryName',
                width: 100,
                // filters: CountryJson.map(x => ({ value: x.name, text: x.name })),
                render: (item) => {
                    return (
                        <ColumnWidth width={80}>{item}</ColumnWidth>
                    )
                }
            },
            {
                title: 'Pincode',
                dataIndex: 'pincode',
                key: 'pincode',
                // searchTextName: 'address',
                width: 100,
                render: (item) => {
                    return (
                        <ColumnWidth width={80}>{item}</ColumnWidth>
                    )
                }
            },
            {
                title: 'Managers',
                dataIndex: 'managers',
                width: 120,
                key: 'managers',
                render: (item, record) => {
                    return (
                        <div key={this.state.keyMoment}>
                            {branchManager && branchManager.length ?
                                <AgentListBranchManager
                                    userId={record._id}
                                    branchManagers={this.state.branchManager}/> : null}
                        </div>
                    )
                }
            },
            {
                title: 'Marketing User',
                dataIndex: 'marketingUser',
                width: 120,
                key: 'marketingUser',
                render: (item, record) => {
                    return (
                        <div key={this.state.keyMoment}>
                            {allMarketingUser && allMarketingUser.length ?
                                <AgentListBranchManager
                                    userId={record._id}
                                    branchManagers={allMarketingUser}/> : null}
                        </div>
                    )
                }
            },
        ]

        const filterBlock = (
            <Row gutter={16} className={'mt10'}>
                {/*<AllowComponentRightsWise rightUserType={['admin']}>*/}
                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className='sort-box-table custom-sort-box-new'>
                        <Select name='Search By Country'
                                onChange={(item) => this.events.chooseCountry(item)}
                                filterOption={filterOption}
                                className={'antSelect'}
                                placeholder='Country'
                                allowClear={true}
                                showSearch={true}
                                value={this.state.countryName || undefined}>
                            {this.state.allCountries && this.state.allCountries.length ? this.state.allCountries.map((item, key) => {
                                return (
                                    <Option value={item.name} key={key}>{item.name}</Option>
                                )
                            }) : null}
                        </Select>
                    </div>
                </Col>
                {/*</AllowComponentRightsWise>*/}
                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className='sort-box-table custom-sort-box-new'>
                        <Select name='Search By State'
                                onChange={(item) => this.events.chooseState(item)}
                                filterOption={filterOption}
                                className={'antSelect'}
                                placeholder='State'
                                allowClear={true}
                                showSearch={true}
                                value={this.state.stateName || undefined}>
                            {this.state.allStates && this.state.allStates.length ? this.state.allStates.map((item, key) => {
                                return (
                                    <Option value={item.name} key={key}>{item.name}</Option>
                                )
                            }) : null}
                        </Select>
                    </div>
                </Col>
                <Col md={4} sm={4} xs={8} lg={4}>
                    {!countryName || countryName == 'India' ?
                        <div className='sort-box-table custom-sort-box-new'>
                            <Select name='Search By City'
                                    onChange={(item) => this.events.chooseCity(item)}
                                    filterOption={filterOption}
                                    className={'antSelect'}
                                    placeholder='City'
                                    allowClear={true}
                                    showSearch={true}
                                    value={this.state.cityName || undefined}>
                                {this.state.allCities && this.state.allCities.length ? this.state.allCities.map((item, key) => {
                                    return (
                                        <Option value={item.name} key={key}>{item.name}</Option>
                                    )
                                }) : null}
                            </Select>
                        </div> :
                        <div className='search-box-table round ml0'>
                            <input name='Search By City' className='form-control'
                                   onChange={(e) => {
                                       this.setState({cityName: e.target.value})
                                   }}
                                   placeholder='City'
                                   value={this.state.cityName}/>
                            <img src='/dist/img/search.png' alt=''/>

                        </div>}
                </Col>


                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className='search-box-table round ml0'>
                        <input className='form-control form-control' type='search'
                               placeholder='Pincode' value={this.state.pincode} onChange={(e) => {
                            this.setState({pincode: e.target.value})
                        }}/>
                        <img src='/dist/img/search.png' alt=''/>
                    </div>
                </Col>

                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className='search-box-table round ml0'>
                        <input className='form-control form-control' type='search'
                               placeholder='Name/Company Name' value={this.state.agent} onChange={(e) => {
                            this.setState({agent: e.target.value})
                        }}/>
                        <img src='/dist/img/search.png' alt=''/>
                    </div>
                </Col>


                <Col md={4} sm={4} xs={12} lg={4}>
                    <Button onClick={() => this.searchFxn()} className={'roundBtn'}>Search</Button>
                    <Button onClick={() => this.clearFxn()} className={'roundBtn'}>
                        Clear
                    </Button>
                </Col>


            </Row>
        )
        return (
            <div>
                <div className='row  mt-4'>
                    <div className='col-lg-12'>
                        <div className='card'>
                            <div className='table-head d-flex align-items-center'>
                                <h5>All Agents ({this.state.total})</h5>
                                <div className='search-box-table'>

                                </div>
                                <CheckUserRight user={this.props.currentUser}
                                                rightUserType={['admin', 'branchManager']}>
                      <span className={'sort-box-table-right'}>
                        <Tooltip title={'Export Excel'}>
                          {' '} <Button onClick={() => this.exportAgentsFxn()}
                                        className={'roundBtn'}>Export Excel</Button>
                        </Tooltip>
                      </span>
                                </CheckUserRight>
                            </div>
                            <div className='card-body table-responsive'>
                                {filterBlock}
                                <TableComp columns={columns}
                                           ref={this.tableRef}
                                           pagination={DefaultTablePagination()}
                                           apiRequest={this.apiRequest}
                                           extraProps={{scroll: {x: 1000}}}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <Drawer visible={this.state.viewInfoDrawer}
                        width={520}
                        onClose={this.onClose}>
                    <Card title={'Universities'}>
                        {selectedRow && selectedRow.universities && selectedRow.universities.length ?
                            <List
                                size='small'
                                bordered
                                dataSource={selectedRow.universities}
                                renderItem={item =>
                                    <List.Item>{item && item.universityName ? item.universityName : null}</List.Item>}
                            /> : null
                        }

                    </Card>
                </Drawer>
                {visibleUpdatePassword ? <UpdatePassword
                    visible={visibleUpdatePassword}
                    onClose={() => this.closeUpdatePassword()}
                    userData={selectedUser}/> : ''}

                {visibleUpdateUser ? <EditUser
                    visible={visibleUpdateUser}
                    reloadTable={() => {
                        this.loadBranchManager()
                        this.loadMarketingUser()
                        setTimeout(() => {
                            this.tableRef.current.reload()
                            this.setState({keyMoment: moment()})
                        }, 2000)
                    }}
                    onClose={() => this.closeEditUser()}
                    userData={selectedUser}/> : ''}
                {visibleUpdateSubAgent ? <EditSubAgent
                    visible={visibleUpdateSubAgent}
                    onClose={() => this.closeEditSubAgent()}
                    userData={selectedUser}/> : ''}

                {visibleApplicationDrawer ? <ViewApplication
                    visible={visibleApplicationDrawer}
                    pageType={'allApplication'}
                    onClose={() => this.events.hideApplicationDrawer()}
                    queryData={queryData}
                /> : null}

            </div>
        )

    }
}


const mapStateToProps = ({global}) => ({
    categories: global.categories,
    currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AllAgentsForMarketingUsers)
