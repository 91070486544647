import React, {useRef, useState} from 'react'
import {Form, Row, Col, Tooltip, Popconfirm, Icon, notification,} from 'antd'
import {useDispatch} from 'react-redux'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import {addIndianCity, listAllIndianCity, deleteIndianCity} from '../actions'
import {TableComp} from "sz-react-utils-lite";
import EditIndianCity from "../drawers/editIndianCity";

const IndianCityComponent = (props) => {
    let tableRef = useRef();
    let [pageState, setPageState] = useState({
        page: 1,
        results: 0
    })
    const {form: {getFieldDecorator}} = props
    const dispatch = useDispatch()
    let [cityObj, setCityObj] = useState({
        visible: false,
        data: {}
    })

    const formItemLayout = {
        labelCol: {
            xs: {span: 24},
            sm: {span: 24},
            md: {span: 24}
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 24},
            md: {span: 24}
        }
    }

    const submitIndianCity = (e) => {
        let {form} = props;
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                let data = await dispatch(addIndianCity(valData))
                if (data && !data.error) {
                    form.resetFields();
                    events.reload()
                }
            } else {
                notification.warning({
                    message: 'Fill All Required Fields'
                })
            }
        })
    }
    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            params.sortField = '_id'
            params.sortOrder = 'descend'
            params.results = 50
            params.count = 50
            let resp = await listAllIndianCity({...params, regExFilters: ['name', 'pinCode']})
            setPageState({
                page: params.page ? params.page : 1,
                results: params.results
            })
            resolve(resp)
        })
    }

    const confirmDelete = async (id) => {
        let data = await dispatch(deleteIndianCity(id))
        if (data) {
            events.reload()
        }
    }

    const events = {
        showEditDrawer: (data) => {
            setCityObj({
                visible: true, data
            })
        },
        hideEditDrawer: () => {
            setCityObj({
                visible: false,
                data: {}
            })
        },
        reload: () => {
            tableRef.current.reload()
        }
    }


    let inputField = [
        {
            key: 'name',
            label: 'City Name',
            span: 8,
            required: true
        },
        {
            key: 'pinCode',
            label: 'City Pin Code',
            span: 8,
            required: true
        }
    ]

    const columns = [
        {
            title: 'S.No',
            dataIndex: 'sno',
            width: 80,
            key: 'sno',
            render: (text, record, index) => {
                let currentIndex = parseInt(pageState.page - 1) * parseInt(pageState.results)
                return (
                    <>
                        {currentIndex + index + 1}
                    </>
                )
            }
        },
        {
            title: 'City Name',
            dataIndex: 'name',
            key: 'name',
            searchTextName: 'name'
        },
        {
            title: 'Pincode',
            dataIndex: 'pinCode',
            key: 'pinCode',
            searchTextName: 'pinCode',
        },


        {
            key: 'actions',
            title: 'Actions',
            height: 100,
            render: (text, record) => {
                return (
                    <div className={'btn_group'}>
                        <Tooltip title='Edit'>
                            <a onClick={() => {
                                events.showEditDrawer(record);
                            }}
                               className={'btn'}
                               style={{marginRight: 6}}>
                                <Icon type={'edit'}/>
                            </a>
                        </Tooltip>
                        <Tooltip title='Delete City'>
                            <Popconfirm title={'Are your sure, you want to delete this city?'}
                                        onConfirm={() => {
                                            confirmDelete(record._id)
                                        }}
                                        okText='Yes' cancelText='No'>
                                <button
                                    className={'btn'}
                                    style={{marginRight: 6}}>
                                    <Icon type={'delete'}/>
                                </button>
                            </Popconfirm>
                        </Tooltip>
                    </div>

                )
            }
        }
    ]

    return (
        <div className={'mt-4'}>
            <div className='row'>
                <div className='col-lg-12'>

                    <div className='form-box'>
                        <div className="d-flex align-items-center mb-3 heading-form">
                            <h5>Add Indian City</h5>
                        </div>
                        <div className='card unizportal'>
                            <Form className={'vertical-form'} autoComplete='off' onSubmit={submitIndianCity}>
                                <Row gutter={24} className={'rowWrap pt-3'}>
                                    {inputField.map((item, key) => {
                                        return (
                                            <React.Fragment key={key}>
                                                <Col span={item.span ? item.span : 8} key={key}>
                                                    <GetEachFormFields
                                                        item={item}
                                                        getFieldDecorator={getFieldDecorator}
                                                        formItemLayout={formItemLayout}/>
                                                </Col>
                                            </React.Fragment>
                                        )
                                    })}
                                    <Col span={8}>
                                        <button className='btn mt10' type='submit'>save
                                        </button>
                                    </Col>
                                </Row>
                            </Form>
                            <div className='card-body table-responsive pa-0'>
                                <TableComp columns={columns}
                                           className={'table table-striped table-valign-middle'}
                                           apiRequest={apiRequest}
                                           pagination={{
                                               position: 'top',
                                               pageSizeOptions: ['50', '100', '200', '500'],
                                               defaultPageSize: 50,
                                               current: pageState.page ? pageState.page : 1
                                           }}
                                           ref={tableRef}
                                />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {cityObj.visible ?
                <EditIndianCity
                    {...cityObj}
                    onClose={events.hideEditDrawer}
                    onSubmit={() => {
                        events.hideEditDrawer();
                        events.reload();
                    }}/> : null}
        </div>
    )
}


const WrappedAddTarget = Form.create()(IndianCityComponent);
export default WrappedAddTarget


