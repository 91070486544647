import {apiUrl} from '../../../settings'

export const addCounsellingUrl = () => {
    return apiUrl + '/addCounselling'
}

export const receptionDashboardUrl = () => {
    return apiUrl + '/receptionDashboard'
}

export const getDashboardCounsellorUrl = () => {
    return apiUrl + '/getDashboardCounsellor'
}

export const getEnquiryByUserUrl = () => {
    return apiUrl + '/getEnquiryByUser'
}

export const updateCounsellorAvailabilityUrl = () => {
    return apiUrl + '/updateCounsellorAvailability'
}

export const counsellingHistoryUrl = () => {
    return apiUrl + '/counsellingHistory'
}

export const updateEnquiryUrl = () => {
    return apiUrl + '/updateEnquiry'
}


export const stopCounsellorWaitingUrl = () => {
    return apiUrl + '/stopCounsellorWaiting'
}


export const counsellorCountryWiseUrl = () => {
    return apiUrl + '/counsellorCountryWise'
}

export const receptionDataCountryWiseUrl = () => {
    return apiUrl + '/receptionDataCountryWise'
}

export const counsellingListUrl = () => {
    return apiUrl + '/counsellingList'
}

export const allCounsellingListUrl = () => {
    return apiUrl + '/api/allCounsellingList'
}


