import React, {useEffect, useState} from "react"
import {DatePicker, Drawer, notification, Select} from "antd";
import {useDispatch} from "react-redux";
import {updateInterviewScheduleFxn, interviewScheduleDateFxn} from "../../../actions/oxfordAction";
import InputBox from "../../../../../components/_utils/InputBox";
import moment from "moment";

const UpdateScheduleChooseDate = (props) => {
    let {onClose, visible, studentData, onSubmit} = props;
    let [oxfordInterviewDate, setOxfordInterviewDate] = useState("")
    let [dateList, setDateList] = useState([])
    let dispatch = useDispatch()
    useEffect(() => {
        loadScheduleDays()
    }, [])

    let disabledDate = (current) => {
        // Can not select days before today and today
        return current && current > moment().startOf('day');
    }


    let loadScheduleDays = async () => {
        if (studentData && studentData.applications && studentData.applications.oxfordInterviewDate) {
            setOxfordInterviewDate(moment(studentData.applications.oxfordInterviewDate))
        }
        let resp = await dispatch(interviewScheduleDateFxn());
        if (resp && resp.success) {
            setDateList(resp.data)
        }
    }
    let updateScheduleTime = async () => {
        if (!oxfordInterviewDate) {
            notification.warning({
                message: "Please choose Interview date."
            })
            return
        }
        let obj = {
            studentId: studentData._id,
            applicationId: studentData.applications._id,
            oxfordInterviewDate: oxfordInterviewDate
        }
        let resp = await dispatch(updateInterviewScheduleFxn(obj));
        if (resp && resp.success) {
            onSubmit()
        }
    }
    return (
        <Drawer title={'Update Interview Schedule'} width={'35%'} onClose={onClose} visible={visible}>
            <div className="card unizportal">
                <InputBox title={'Update Interview Date'}>
                    <DatePicker
                        value={oxfordInterviewDate}
                        format={'dddd, DD MMMM YYYY'}
                        placeholder={'Update Interview Date'}
                        disabledDate={disabledDate}
                        onChange={(value) => {
                            setOxfordInterviewDate(value)
                        }}/>
                </InputBox>
                <button className='btn' type='submit' onClick={updateScheduleTime}>save
                </button>
            </div>
        </Drawer>
    )
}
export default UpdateScheduleChooseDate
