import { apiUrl } from '../../../settings'

export const addFeedbackUrl = () => {
  return apiUrl + '/feedback'
}

export const listFeedbackUrl = () => {
  return apiUrl + '/feedback'
}


export const singleFeedbackUrl = (id) => {
  return apiUrl + '/feedback/' + id
}
