import Login from "../login";
import AuthLogin from "../login/authLogin";
import UploadCertificate from "../login/uploadCertificate";
import ConfirmMeeting from "../scheduleMetting/views/confirmMeeting";
import AgentRegistration from "../login/agentRegistration";
import Educators from "../login/educators";
import StudentRegistration from "../login/studentRegistration";
import ForgotPassword from "../login/forgotPassword";
import ResetPassword from "../login/resetPassword";

const AuthRouteList = [
    {name: 'Login', path: '/login', component: Login, key: 'login'},
    {name: 'Auth Login', path: '/auth-login', component: AuthLogin, key: 'authLogin'},
    {
        name: 'Upload Certificate',
        path: '/upload-certificate/:token',
        component: UploadCertificate,
        key: 'uploadCertificate'
    },
    {
        name: 'Confirm Meeting',
        path: '/confirm-meeting/:token',
        component: ConfirmMeeting,
        key: 'confirmMeeting'
    },
    {
        name: 'Agent Registration',
        path: '/agent-registration',
        component: AgentRegistration,
        key: 'agentRegistration'
    },
    {
        name: 'Educators',
        path: '/educators',
        component: Educators,
        key: 'educators'
    },
    {
        name: 'Student Registration',
        path: '/student-registration',
        component: StudentRegistration,
        key: 'studentRegistration'
    },
    {
        name: 'Forgot Password',
        path: '/forgot-password',
        component: ForgotPassword,
        key: 'forgotPassword'
    },
    {
        name: 'Reset Password',
        path: '/reset-password/:token',
        component: ResetPassword,
        key: 'resetPassword'
    }
]

export default AuthRouteList
