import {
  Button,
  Card,
  Col,
  Drawer,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Table,
  Tabs,
  notification
} from 'antd'
import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
// import { FormUtils as GetAllFormFields } from 'sz-react-utils-lite'
import GetAllFormFields from '../../../components/_utils/formUtils'

import { connect, useDispatch, useSelector } from 'react-redux'
import Qualifications from '../../../components/Qualification'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import {
  CampBellUniversity,
  CasaHCollege,
  ConditionOptions,
  DirectUniversityCheck,
  Intakes,
  LesterCollege,
  ProgramLevel,
  RoyalArtsCollege,
  WavierYear,
  academicStatusList,
  cambieUniversity,
  displayDate,
  fcmtUniversity,
  oxfordUniversity,
  typeOfProgramList
} from '../../../components/_utils/appUtils'
import { getUrlParams } from '../../../routes'
import { listAllCampus } from '../../campus/actions/campus'
import { indiaAllStates } from '../../state/actions/state'
import { listAllUniversities } from '../../university/actions/university'
import { addCourse, getCourse, updateCourse } from '../actions/courseAction'

import { log } from '@craco/craco/lib/logger'
import moment from 'moment'
import { LoadState } from '../../../components/_utils/countryUtil'
import { listAllCourseCategory } from '../../CourseCategory/actions'
import EnglishRequirement from '../drawers/englishRequirement'
import InstallmentSchedule1 from '../drawers/installmentSchedule1'
import InstallmentSchedule2 from '../drawers/installmentSchedule2'
import Intake from '../drawers/intakeList'
import QualificationDrawer from '../drawers/qualification'
import WaiversDrawer from '../drawers/waivers'
import style from './styles.less'

const { TabPane } = Tabs
const { Option } = Select
const initialState = {
  univ: [],
  tabsAlert: [],
  ieltsRequirements: [],
  intakeList: [],
  qualifications: [],
  edit: false,
  rowKey: null,
  key: Math.random() * 100,
  universityCountry: '',
  universityState: '',
  universityCity: '',
  yearList: WavierYear(),
  waiverList: [],
  installmentSchedule1: [],
  installmentSchedule2: []
}
const InitialExamState = {
  examType: '',
  overall: '',
  listening: '',
  writing: '',
  speaking: '',
  reading: ''
}
const initialIntakeState = {
  month: '',
  year: '',
  startDate: '',
  endDate: '',
  active: false
}
const AddCourse = props => {
  const [state, setState] = useState(initialState)

  const [examState, setExamState] = useState(InitialExamState)
  const [courseId, setCourseId] = useState('')
  const {
    form: { getFieldDecorator, setFieldsValue, getFieldValue, resetFields }
  } = props
  const dispatch = useDispatch()
  const [indiaStates, setIndiaStates] = useState([])
  const [visibleEnglishRequirement, setVisibleEnglishRequirement] = useState(
    false
  )
  const [editAbleEnglish, setEditAbleEnglish] = useState({})
  const [visibleIntake, setVisibleIntake] = useState(false)
  const [editableIntake, setEditableIntake] = useState({})
  const [intakeState, setIntakeState] = useState(initialIntakeState)
  const [visibleQualification, setVisibleQualification] = useState(false)
  const [editAbleQualification, setEditAbleQualification] = useState({})
  const [editAbleWaiver, setEditAbleWaiver] = useState({})
  const [visibleWaiver, setVisibleWaiver] = useState(false)
  const [isDirectUniversity, setIsDirectUniversity] = useState(false)
  const [installmentSchedule1, setInstallmentSchedule1] = useState(false)
  const [installmentSchedule2, setInstallmentSchedule2] = useState(false)
  const [installmentData1, setInstallmentData1] = useState(false)
  const [installmentData2, setInstallmentData2] = useState(false)
  const [selectedUniversity, setSelectedUniversity] = useState('')
  const [pathway, setPathway] = useState(false)
  const [categoryList, setCategoryList] = useState([])

  const data = getUrlParams('masters.editCourse', props.pathname)

  // console.log(data)

  const {
    courseLoader,
    addedCourse,
    allUniversities,
    course,
    courseCardLoader,
    allCampus
  } = useSelector(state => ({
    courseLoader: state.addCourse.courseLoader,
    addedCourse: state.addCourse.addedCourse,
    allUniversities: state.universityReducers.allUniversities,
    courseCardLoader: state.addCourse.courseCardLoader,
    course: state.addCourse.course,
    allCampus: state.campusReducers.allCampus
      ? state.campusReducers.allCampus
      : []
  }))

  const IndiaStates = async () => {
    let listOfState = await LoadState(101)
    setIndiaStates(listOfState)
  }

  // console.log('cardloader', courseCardLoader, courseLoader)
  useEffect(() => {
    IndiaStates()
    loadCourseCategory()
  }, [])

  const loadCourseCategory = async () => {
    let obj = {
      results: 100,
      count: 100
    }
    let { data } = await dispatch(listAllCourseCategory(obj))
    setCategoryList(data)
  }

  useEffect(() => {
    dispatch({ type: 'COURSE_HIDELOADER' })
    if (data && data.id) {
      setCourseId(data.id)
      dispatch(getCourse(data.id))
    }
  }, [])
  useEffect(() => {
    // dispatch({type:'COURSE_HIDELOADER'})
    // dispatch({ type: 'EMPTY_CAMPUS_LIST' })
    dispatch(
      listAllUniversities({
        results: 500,
        sortField: 'universityName',
        sortOrder: 'ascend'
      })
    )
  }, [])
  useEffect(
    () => {
      const { form } = props
      if (course) {
        let campuses = []
        let intakes = []
        _.forEach(course.campusName, (val, key) => campuses.push(val._id))
        _.forEach(course.intakes, (val, key) => intakes.push(val))
        setTimeout(() => {
          let objMain = {
            courseName: course.courseName ? course.courseName : '',
            courseUniversity:
              course.courseUniversity && course.courseUniversity._id
                ? course.courseUniversity._id
                : '',
            campusName: course.campusName ? campuses : [],
            courseDuration: course.courseDuration ? course.courseDuration : '',
            courseLevel: course.courseLevel ? course.courseLevel : '',
            tuitionFee: course.tuitionFee ? course.tuitionFee : '',
            payable: course.payable ? course.payable : '',
            intakes: course.intakes ? intakes : [],
            paymentTerms: course.paymentTerms ? course.paymentTerms : '',
            ineligibleState: course.ineligibleState
              ? course.ineligibleState
              : '',
            additionalRequirments: course.additionalRequirments
              ? course.additionalRequirments
              : '',
            universityCountry: course.universityCountry
              ? course.universityCountry
              : '',
            universityState: course.universityState
              ? course.universityState
              : '',
            universityCity: course.universityCity ? course.universityCity : '',
            waiver: course.waiver ? course.waiver : false,
            scholarship: course.scholarship ? course.scholarship : 0,
            categoryId: course.categoryId ? course.categoryId : undefined,
            faculty: course.faculty ? course.faculty : '',
            session: course.session ? course.session : '',

            // waiverList: course.waiverList ? course.waiverList : [],
            isFeatured: course.isFeatured,
            isSearchAble: course.isSearchAble
          }
          form.setFieldsValue({...objMain})
          if (course.courseUniversity && course.courseUniversity._id) {
            setSelectedUniversity(course.courseUniversity._id)
          }
          setPathway(course.pathway ? course.pathway : false)
          setIsDirectUniversity(DirectUniversityCheck(objMain.courseUniversity))
          if (DirectUniversityCheck(objMain.courseUniversity)) {
            let obj = {}
            obj.levelOfStudy = course.levelOfStudy ? course.levelOfStudy : null
            obj.typeOfProgram = course.typeOfProgram
                ? course.typeOfProgram
                : null
            obj.academicStatus = course.academicStatus
                ? course.academicStatus
              : null
            obj.firstYearFees = course.firstYearFees ? course.firstYearFees : 0
            obj.secondYearFees = course.secondYearFees
              ? course.secondYearFees
              : 0
            obj.hoursOfInstructionPerWeek = course.hoursOfInstructionPerWeek
              ? course.hoursOfInstructionPerWeek
              : null
            obj.startDate = course.startDate ? moment(course.startDate) : null
            obj.endDate = course.endDate ? moment(course.endDate) : null
            obj.pathway = course.pathway ? course.pathway : false
            obj.secondYearStartDate = course.secondYearStartDate
              ? moment(course.secondYearStartDate)
              : null
            obj.secondYearEndDate = course.secondYearEndDate
              ? moment(course.secondYearEndDate)
              : null
            obj.scholarship = course.scholarship ? course.scholarship : 0
            obj.courseCode = course.courseCode ? course.courseCode : null
            obj.cricosCode = course.cricosCode ? course.cricosCode : null
            obj.courseCode2 = course.courseCode2 ? course.courseCode2 : null
            obj.cricosCode2 = course.cricosCode2 ? course.cricosCode2 : null
            obj.courseDuration2 = course.courseDuration2
              ? course.courseDuration2
              : null
            obj.courseName2 = course.courseName2 ? course.courseName2 : null
            obj.ectsCredit = course.ectsCredit ? course.ectsCredit : null
            obj.afterArrivalFees = course.afterArrivalFees
              ? course.afterArrivalFees
              : null
            obj.visaGuarantee = course.visaGuarantee
              ? course.visaGuarantee
              : null
            obj.annualLivingExpense = course.annualLivingExpense
              ? course.annualLivingExpense
              : null
            obj.responseDeadline = course.responseDeadline
              ? moment(course.responseDeadline)
              : null

            setTimeout(() => {
              form.setFieldsValue({ ...obj })
              if (course.pathway) {
                setTimeout(() => {
                  form.setFieldsValue({
                    secondYearStartDate: course.secondYearStartDate
                      ? moment(course.secondYearStartDate)
                      : null,
                    secondYearEndDate: course.secondYearEndDate
                      ? moment(course.secondYearEndDate)
                      : null
                  })
                }, 200)
              }
            }, 500)
          }

          handleCampusSelect(course.courseUniversity)
          if (course.englishRequirments)
            state.ieltsRequirements = course.englishRequirments
          {
            console.log(course, 'CCCCCCCCCCCRSEESE')
          }
          if (course.intakeList) state.intakeList = course.intakeList
          if (course.qualification) state.qualifications = course.qualification
          if (course.universityCountry)
            state.universityCountry = course.universityCountry
          if (course.universityState)
            state.universityState = course.universityState
          if (course.universityCity)
            state.universityCity = course.universityCity
          if (course.universityName)
            state.universityName = course.universityName
          if (course.installmentSchedule1)
            state.installmentSchedule1 = course.installmentSchedule1
          if (course.installmentSchedule2)
            state.installmentSchedule2 = course.installmentSchedule2
          setState({
            ...state,
            qualifications: course.qualification,
            waiverList: course.waiverList
          })
          /* setTimeout(() => {
                   form.setFieldsValue({
                     waiverPercent: course.waiverPercent ? course.waiverPercent : '',
                     waiverYear: course.waiverYear ? course.waiverYear : []
                   })
                 }, 500)*/
        }, 1000)
      }
    },
    [course]
  )
  const overall = useRef()
  const listening = useRef()
  const reading = useRef()
  const writing = useRef()
  const speaking = useRef()
  const chooseUniversity = data => {
    if (data && data._id) {
      setState(() => ({
        ...state,
        universityCountry:
          data.universityCountry && data.universityCountry._id
            ? data.universityCountry._id
            : data.universityCountry,
        universityState:
          data.universityState && data.universityState._id
            ? data.universityState._id
            : data.universityState,
        universityCity:
          data.universityCity && data.universityCity._id
            ? data.universityCity._id
            : data.universityCity,
        universityName: data.universityName
      }))
    } else {
      setState(() => ({
        ...state,
        universityCountry: '',
        universityState: '',
        universityCity: ''
      }))
    }
  }

  let inputTypes = {
    fields: [
      { key: 'courseName', label: 'Course Name', type: 'text', required: true },
      {
        key: 'courseUniversity',
        label: 'University Name',
        type: 'select',
        options: allUniversities,
        showSearch: true,
        onSearch: v => {
          dispatch(
            listAllUniversities({
              results: 20,
              universityName: v,
              regExFilters: ['universityName']
            })
          )
        },
        keyAccessor: x => x._id,
        required: true,
        valueAccessor: x => `${x.universityName}`,
        onChange: x => {
          let findUni = _.find(allUniversities, item => {
            return item._id == x
          })
          setSelectedUniversity(x)
          chooseUniversity(findUni)
          handleCampusSelect(x)
          setIsDirectUniversity(DirectUniversityCheck(x))
          props.form.setFieldsValue({
            courseUniversity: x,
            campusName: undefined
          })
        }
      },
      {
        key: 'campusName',
        label: 'Campus Name',
        showSearch: true,
        type: 'select',
        options: allCampus,
        keyAccessor: x => x._id,
        mode: 'multiple',
        valueAccessor: x => `${x.campusName}`,
        onChange: x => {
          props.form.setFieldsValue({
            campusName: x
          })
        }
      },
      { key: 'courseDuration', label: 'Course Duration', required: true },
      {
        key: 'courseLevel',
        label: 'Course Level',
        showSearch: true,
        required: true,
        type: 'select',
        options: ProgramLevel,
        onChange: courseLevel => {
          props.form.setFieldsValue({ courseLevel })
        }
      },
      {
        key: 'categoryId',
        label: 'Course Category',
        // required: true,
        type: 'select',
        options: categoryList,
        keyAccessor: x => x._id,
        valueAccessor: x => `${x.name}`,
        onChange: categoryId => {
          props.form.setFieldsValue({ categoryId })
        }
      },
      {
        key: 'tuitionFee',
        label: 'Tuition Fees',
        type: 'number',
        required: true
      },

      { key: 'payable', label: 'Payable' },
      {
        key: 'intakes',
        label: 'Intakes',
        required: true,
        type: 'select',
        mode: 'multiple',
        options: Intakes,
        onChange: intakes => {
          props.form.setFieldsValue({ intakes })
        }
      },
      /*{
              key: 'paymentTerms',
              label: 'Payment Terms',
              type: 'select',
              options: ['Semester Wise', 'Annual', 'Trimester'],
              onChange: (paymentTerms) => {
                props.form.setFieldsValue({ paymentTerms })
              }
            },*/
      {
        key: 'additionalRequirments',
        label: 'Additional Requirments',
        hidden:
          getFieldValue('courseUniversity') == CampBellUniversity
            ? true
            : false,
        type: 'textarea'
      },

      {
        key: 'ineligibleState',
        label: 'Ineligible State',
        type: 'select',
        mode: 'multiple',
        hidden:
          getFieldValue('courseUniversity') == CampBellUniversity
            ? true
            : false,
        options: indiaStates,
        keyAccessor: x => x.name,
        valueAccessor: x => `${x.name}`,
        onChange: x => {
          props.form.setFieldsValue({
            ineligibleState: x
          })
        }
      },
      {
        key: 'waiver',
        label: 'Waiver',
        type: 'select',
        hidden:
          getFieldValue('courseUniversity') == CampBellUniversity
            ? true
            : false,
        options: [{ name: 'Yes', value: true }, { name: 'No', value: false }],
        keyAccessor: x => x.value,
        valueAccessor: x => `${x.name}`,
        onChange: x => {
          props.form.setFieldsValue({
            waiver: x
          })
          setState({
            ...state,
            waiverList: []
          })
        }
      },
      {
        key: 'scholarship',
        label: 'Scholarship',
        hidden:
          getFieldValue('courseUniversity') == CampBellUniversity
            ? true
            : false,
        type: 'number'
      },

      {
        key: 'courseCode',
        label: 'Course Code',
        required:
          getFieldValue('courseUniversity') == CampBellUniversity
            ? true
            : false,
        hidden:
          getFieldValue('courseUniversity') == CampBellUniversity ? false : true
      },

      {
        key: 'cricosCode',
        label: 'Cricos Code',
        required:
          getFieldValue('courseUniversity') == CampBellUniversity
            ? true
            : false,
        hidden:
          getFieldValue('courseUniversity') == CampBellUniversity ? false : true
      },
      /*{
              key: 'waiverType',
              label: 'Type',
              type: 'select',
              hidden: props.form.getFieldValue('waiver') !== true,
              options: [{ name: 'Percent', value: 'percent' }, { name: 'Year', value: 'year' }, {
                name: 'Both',
                value: 'both'
              }],
              keyAccessor: x => x.value,
              valueAccessor: x => `${x.name}`,
              onChange: x => {
                props.form.setFieldsValue({
                  waiverType: x,
                  waiverYear: [],
                  waiverPercent: ''
                })
              }
            },
            {
              key: 'waiverPercent',
              label: 'Percent',
              type: 'number',
              placeholder: 'Percent',
              hidden: !(getFieldValue('waiverType') == 'percent' || getFieldValue('waiverType') == 'both')
            },
            {
              key: 'waiverYear',
              label: 'Year',
              type: 'select',
              options: state.yearList,
              mode: 'multiple',
              keyAccessor: x => x,
              valueAccessor: x => `${x}`,
              hidden: !(getFieldValue('waiverType') == 'year' || getFieldValue('waiverType') == 'both'),
              onChange: (waiverYear) => {
                props.form.setFieldsValue({ waiverYear })
              }
            },*/
      {
        key: 'isFeatured',
        span: 8,
        label: 'Featured Course',
        type: 'select',
        options: ConditionOptions,
        keyAccessor: x => x.value,
        valueAccessor: x => `${x.name}`,
        onChange: x => {
          props.form.setFieldsValue({
            isFeatured: x
          })
        }
      },
      /*  {
                key: 'isSearchAble',
                span: 8,
                label: 'Searchable',
                type: 'select',
                options: ConditionOptions,
                keyAccessor: x => x.value,
                valueAccessor: x => `${x.name}`,
                onChange: x => {
                    props.form.setFieldsValue({
                        isSearchAble: x
                    })
                }
            },*/
      {
        key: 'levelOfStudy',
        span: 8,
        label: 'Level Of Study',
        hidden: getFieldValue('courseUniversity') ? !isDirectUniversity : true,
        required: getFieldValue('courseUniversity') ? isDirectUniversity : false
      },
      {
        key: 'typeOfProgram',
        span: 8,
        label: 'Type Of Program',
        options: typeOfProgramList,
        type: 'select',
        keyAccessor: x => x.value,
        valueAccessor: x => `${x.name}`,
        hidden: getFieldValue('courseUniversity') ? !isDirectUniversity : true,
        required: getFieldValue('courseUniversity')
          ? isDirectUniversity
          : false,
        onChange: x => {
          props.form.setFieldsValue({
            typeOfProgram: x
          })
        }
      },
      {
        key: 'academicStatus',
        span: 8,
        label: 'Academic Status',
        options: academicStatusList,
        type: 'select',
        keyAccessor: x => x.value,
        valueAccessor: x => `${x.name}`,
        hidden: getFieldValue('courseUniversity') ? !isDirectUniversity : true,
        required: getFieldValue('courseUniversity')
          ? isDirectUniversity
          : false,
        onChange: x => {
          props.form.setFieldsValue({
            academicStatus: x
          })
        }
      },
      {
        key: 'hoursOfInstructionPerWeek',
        span: 8,
        label: 'Hours Of Instruction Per Week',
        hidden: getFieldValue('courseUniversity') ? !isDirectUniversity : true,
        required: getFieldValue('courseUniversity') ? isDirectUniversity : false
      },
      {
        key: 'startDate',
        span: 8,
        type: 'date',
        label: 'Start Date',
        hidden: getFieldValue('courseUniversity') ? !isDirectUniversity : true,
        required: getFieldValue('courseUniversity') ? isDirectUniversity : false
      },
      {
        key: 'endDate',
        span: 8,
        type: 'date',
        label: 'End Date',
        hidden: getFieldValue('courseUniversity') ? !isDirectUniversity : true,
        required: getFieldValue('courseUniversity') ? isDirectUniversity : false
      },
      {
        key: 'firstYearFees',
        span: 8,
        type: 'number',
        label: '1st Year Fees',
        hidden: getFieldValue('courseUniversity') ? !isDirectUniversity : true,
        required: getFieldValue('courseUniversity') ? isDirectUniversity : false
      },
      {
        key: 'secondYearFees',
        span: 8,
        type: 'number',
        label: '2nd Year Fees',
        hidden: getFieldValue('courseUniversity') ? !isDirectUniversity : true,
        required: getFieldValue('courseUniversity') ? isDirectUniversity : false
      },
      {
        key: 'pathway',
        span: 8,
        label: 'Pathway',
        type: 'select',
        options: ConditionOptions,
        hidden: getFieldValue('courseUniversity') ? !isDirectUniversity : true,
        required: getFieldValue('courseUniversity')
          ? isDirectUniversity
          : false,
        keyAccessor: x => x.value,
        valueAccessor: x => `${x.name}`,
        onChange: x => {
          setPathway(x)
          props.form.setFieldsValue({
            pathway: x
          })
          setInstallmentData2([])
          if (x == false) {
            setState({
              ...state,
              installmentSchedule2: []
            })
          }
        }
      },
      {
        key: 'secondYearStartDate',
        span: 8,
        type: 'date',
        label: '2nd Year Start Date',
        hidden: !getFieldValue('pathway'),
        required: getFieldValue('pathway')
      },
      {
        key: 'secondYearEndDate',
        span: 8,
        type: 'date',
        label: '2nd Year End Date',
        hidden: !getFieldValue('pathway'),
        required: getFieldValue('pathway')
      },
      {
        key: 'courseName2',
        label: 'Second Course Name',
        required:
          getFieldValue('courseUniversity') == CampBellUniversity &&
          getFieldValue('pathway') == true
            ? true
            : false,
        hidden:
          getFieldValue('courseUniversity') == CampBellUniversity &&
          getFieldValue('pathway') == true
            ? false
            : true
      },
      {
        key: 'courseCode2',
        label: 'Course Code for second Course',
        required:
          getFieldValue('courseUniversity') == CampBellUniversity &&
          getFieldValue('pathway') == true
            ? true
            : false,

        hidden:
          getFieldValue('courseUniversity') == CampBellUniversity &&
          getFieldValue('pathway') == true
            ? false
            : true
      },
      {
        key: 'cricosCode2',
        label: 'Cricos Code for Second Course',
        required:
          getFieldValue('courseUniversity') == CampBellUniversity &&
          getFieldValue('pathway') == true
            ? true
            : false,
        hidden:
          getFieldValue('courseUniversity') == CampBellUniversity &&
          getFieldValue('pathway') == true
            ? false
            : true
      },
      {
        key: 'courseDuration2',
        label: 'Course Duration for second Course',
        required:
          getFieldValue('courseUniversity') == CampBellUniversity &&
          getFieldValue('pathway') == true
            ? true
            : false,
        hidden:
          getFieldValue('courseUniversity') == CampBellUniversity &&
          getFieldValue('pathway') == true
            ? false
            : true
      },
      {
        key: 'ectsCredit',
        label: 'ECTS Credits',
        placeholder: 'ECTS Credits',
        type: 'number',
        hidden:
          getFieldValue('courseUniversity') == CasaHCollege ? false : true,
        required:
          getFieldValue('courseUniversity') == CasaHCollege ? true : false
      },
      {
        key: 'afterArrivalFees',
        label: 'After Arrival Fees',
        placeholder: 'After Arrival Fees',
        type: 'number',
        hidden:
          getFieldValue('courseUniversity') == CasaHCollege ? false : true,
        required:
          getFieldValue('courseUniversity') == CasaHCollege ? true : false
      },
      {
        key: 'visaGuarantee',
        label: 'Visa Guarantee',
        placeholder: 'Visa Guarantee',
        type: 'number',
        hidden:
          getFieldValue('courseUniversity') == CasaHCollege ? false : true,
        required:
          getFieldValue('courseUniversity') == CasaHCollege ? true : false
      },
      {
        key: 'annualLivingExpense',
        label: 'Annual Living Expenses',
        placeholder: 'Annual Living Expenses',
        type: 'number',
        hidden:
          getFieldValue('courseUniversity') == CasaHCollege ? false : true,
        required:
          getFieldValue('courseUniversity') == CasaHCollege ? true : false
      },
      {
        key: 'faculty',
        label: 'Faculty',
        // placeholder: 'Faculty',
        type: 'string',
        hidden:
          getFieldValue('courseUniversity') == RoyalArtsCollege ? false : true,
        required:
          getFieldValue('courseUniversity') == RoyalArtsCollege ? true : false
      },
      {
        key: 'session',
        label: 'Session',
        // placeholder: 'Session',
        type: 'string',
        hidden:
          getFieldValue('courseUniversity') == RoyalArtsCollege ? false : true,
        required:
          getFieldValue('courseUniversity') == RoyalArtsCollege ? true : false
      },
      {
        key: 'responseDeadline',
        label: 'Response Deadline',
        placeholder: 'Select Date',
        type: 'date',
        hidden:
          getFieldValue('courseUniversity') == RoyalArtsCollege ? false : true,
        required:
          getFieldValue('courseUniversity') == RoyalArtsCollege ? true : false
      }
    ]
  }
  const handleCampusSelect = x => {
    dispatch(
      listAllCampus({ results: 1000000, customQuery: { campusUniversity: x } })
    )
  }

  const addExam = data => {
    if (data.examType) {
      let findExists = _.find(state.ieltsRequirements, (item, key) => {
        if (data.edit) {
          return item.examType == data.examType && data.rowKey !== key
        } else {
          return item.examType == data.examType
        }
      })
      if (findExists) {
        notification.warning({
          message: 'English requirement already exists.'
        })
        return
      }

      if (data.edit) {
        const clone = _.cloneDeep(state.ieltsRequirements)
        clone[data.rowKey] = {
          examType: data.examType,
          // qualification: data.qualification,
          overall: data.overall,
          listening: data.listening,
          reading: data.reading,
          writing: data.writing,
          speaking: data.speaking
        }
        setState({ ...state, ieltsRequirements: clone })
        setExamState(InitialExamState)
        setVisibleEnglishRequirement(false)
      } else {
        let obj = {
          examType: data.examType,
          // qualification: data.qualification,
          overall: data.overall,
          listening: data.listening,
          reading: data.reading,
          writing: data.writing,
          speaking: data.speaking
        }
        setState({
          ...state,
          ieltsRequirements: [...state.ieltsRequirements, obj]
        })
        setExamState(InitialExamState)
        setVisibleEnglishRequirement(false)
      }
    }
  }

  const addIntake = data => {
    if (data.month && data.year) {
      let findExists = _.find(state.intakeList, (item, key) => {
        if (data.edit) {
          return (
            item.month === data.month &&
            item.year === data.year &&
            data.rowKey !== key
          )
        } else {
          return item.month === data.month && item.year === data.year
        }
      })

      if (findExists) {
        notification.warning({
          message: 'Intake already exists.'
        })
        return
      }

      if (data.edit) {
        const clone = _.cloneDeep(state.intakeList)
        clone[data.rowKey] = {
          month: data.month,
          year: data.year,
          startDate: data.startDate,
          endDate: data.endDate,
          active: data.active,
          secondYearStartDate: data.secondYearStartDate ? data.secondYearStartDate : null,
          secondYearEndDate: data.secondYearEndDate ? data.secondYearEndDate : null
        }
        setState({ ...state, intakeList: clone })
        setIntakeState(initialIntakeState)
        setVisibleIntake(false)
      } else {
        let obj = {
          month: data.month,
          year: data.year,
          startDate: data.startDate,
          endDate: data.endDate,
          active: data.active,
          secondYearStartDate: data.secondYearStartDate,
          secondYearEndDate: data.secondYearEndDate
        }
        setState({
          ...state,
          intakeList: [...state.intakeList, obj]
        })
        setIntakeState(initialIntakeState)
        setVisibleIntake(false)
      }
    }
  }
  const editIntake = (item, rowKey) => {
    setEditableIntake({ ...item, edit: true, rowKey })
    setVisibleIntake(true)
  }

  const editExamType = (item, rowKey) => {
    setEditAbleEnglish({ ...item, edit: true, rowKey })
    setVisibleEnglishRequirement(true)
  }
  const editQualification = (item, rowKey) => {
    setEditAbleQualification({ ...item, edit: true, rowKey })
    setVisibleQualification(true)
  }
  const deleteExamType = (item, rowKey) => {
    let clone = _.cloneDeep(state.ieltsRequirements)
    clone.splice(rowKey, 1)
    setState(() => ({
      ...state,
      ieltsRequirements: clone
    }))
  }
  const deleteIntakeEntry = (item, rowKey) => {
    let clone = _.cloneDeep(state.intakeList)
    clone.splice(rowKey, 1)
    setState(() => ({
      ...state,
      intakeList: clone
    }))
  }
  const deleteQualification = (item, rowKey) => {
    let clone = _.cloneDeep(state.qualifications)
    clone.splice(rowKey, 1)
    setState(() => ({
      ...state,
      qualifications: clone
    }))
  }
  const addQualification = values => {
    let findExists = _.find(state.qualifications, (item, key) => {
      if (values.edit) {
        return (
          item.qualification == values.qualification && values.rowKey !== key
        )
      } else {
        return item.qualification == values.qualification
      }
    })
    if (findExists) {
      notification.warning({
        message: 'Education already exists.'
      })
      return
    }

    if (values.edit) {
      const clone = _.cloneDeep(state.qualifications)
      clone[values.rowKey] = {
        qualification: values.qualification ? values.qualification : '',
        passingYear: values.passingYear ? values.passingYear : '',
        percentage: values.percentage ? values.percentage : '',
        maths: values.maths ? values.maths : '',
        english: values.english ? values.english : '',
        stream: values.stream ? values.stream : '',
        major: values.major ? values.major : '',
        backlogs: values.backlogs ? values.backlogs : '',
        degreeType: values.degreeType ? values.degreeType : ''
      }
      setState({ ...state, qualifications: clone })
      setVisibleQualification(false)
    } else {
      let obj = {
        qualification: values.qualification ? values.qualification : '',
        passingYear: values.passingYear ? values.passingYear : '',
        percentage: values.percentage ? values.percentage : '',
        maths: values.maths ? values.maths : '',
        english: values.english ? values.english : '',
        stream: values.stream ? values.stream : '',
        major: values.major ? values.major : '',
        backlogs: values.backlogs ? values.backlogs : '',
        degreeType: values.degreeType ? values.degreeType : ''
      }
      setState({
        ...state,
        qualifications: [...state.qualifications, obj]
      })
      setVisibleQualification(false)
    }
  }

  const editInstallment1 = (item, rowKey) => {
    setInstallmentData1({ ...item, edit: true, rowKey })
    setInstallmentSchedule1(true)
  }

  const editInstallment2 = (item, rowKey) => {
    setInstallmentData2({ ...item, edit: true, rowKey })
    setInstallmentSchedule2(true)
  }

  const deleteInstallment1 = (item, rowKey) => {
    let clone = _.cloneDeep(state.installmentSchedule1)
    clone.splice(rowKey, 1)
    setState(() => ({
      ...state,
      installmentSchedule1: clone
    }))
  }
  const deleteInstallment2 = (item, rowKey) => {
    let clone = _.cloneDeep(state.installmentSchedule2)
    clone.splice(rowKey, 1)
    setState(() => ({
      ...state,
      installmentSchedule2: clone
    }))
  }

  const addInstallments = data => {
    if (data.edit) {
      const clone = _.cloneDeep(state.installmentSchedule1)
      clone[data.rowKey] = {
        studyPeriod: data.studyPeriod,
        dueDate: data.dueDate,
        applicationFee: data.applicationFee,
        materialFee: data.materialFee,
        tuitionFee: data.tuitionFee
      }
      setState({ ...state, installmentSchedule1: clone })
      setInstallmentData1({})
      setInstallmentSchedule1(false)
    } else {
      let obj = {
        studyPeriod: data.studyPeriod,
        dueDate: data.dueDate,
        applicationFee: data.applicationFee,
        materialFee: data.materialFee,
        tuitionFee: data.tuitionFee
      }
      setState({
        ...state,
        installmentSchedule1: [...state.installmentSchedule1, obj]
      })
      setInstallmentData1({})
      setInstallmentSchedule1(false)
    }
  }

  const addInstallments2 = data => {
    if (data.edit) {
      const clone = _.cloneDeep(state.installmentSchedule2)
      clone[data.rowKey] = {
        studyPeriod: data.studyPeriod,
        dueDate: data.dueDate,
        applicationFee: data.applicationFee,
        materialFee: data.materialFee,
        tuitionFee: data.tuitionFee
      }
      setState({ ...state, installmentSchedule2: clone })
      setInstallmentData2({})
      setInstallmentSchedule2(false)
    } else {
      let obj = {
        studyPeriod: data.studyPeriod,
        dueDate: data.dueDate,
        applicationFee: data.applicationFee,
        materialFee: data.materialFee,
        tuitionFee: data.tuitionFee
      }
      setState({
        ...state,
        installmentSchedule2: [...state.installmentSchedule2, obj]
      })
      setInstallmentData2({})
      setInstallmentSchedule2(false)
    }
  }

  const ieltsColumn = [
    {
      title: 'Exam',
      dataIndex: 'examType',
      key: 'examType'
    },
    /*{
          title: 'Qualification',
          dataIndex: 'qualification',
          key: 'qualification'
        },*/
    {
      title: 'Overall',
      dataIndex: 'overall',
      key: 'overall'
    },
    {
      title: 'Listening',
      dataIndex: 'listening',
      key: 'listening'
    },
    {
      title: 'Reading',
      dataIndex: 'reading',
      key: 'reading'
    },
    {
      title: 'Writing',
      dataIndex: 'writing',
      key: 'writing'
    },
    {
      title: 'Speaking',
      dataIndex: 'speaking',
      key: 'speaking'
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'action',
      render: (key, val, rowKey) => {
        return (
          <div className={'pa-0'}>
            <Button
              shape="circle"
              size={'small'}
              icon="edit"
              id={'edit'}
              onClick={() => editExamType(val, rowKey)}
            />
            <Button
              style={{ marginLeft: 6 }}
              size={'small'}
              color={'red'}
              shape="circle"
              icon="delete"
              id={'delete'}
              onClick={() => deleteExamType(val, rowKey)}
            />
          </div>
        )
      }
    }
  ]

  const installmentColumn1 = [
    {
      title: 'Study Period',
      dataIndex: 'studyPeriod',
      key: 'studyPeriod'
    },
    {
      title: 'Due Date',
      dataIndex: 'dueDate',
      key: 'dueDate',
      required: true,
      render: item => {
        return displayDate(item)
      }
    },
    {
      title: 'Application Fee',
      dataIndex: 'applicationFee',
      key: 'applicationFee'
    },
    {
      title: 'Material Fee',
      dataIndex: 'materialFee',
      key: 'materialFee'
    },
    {
      title: 'Tuition Fee',
      dataIndex: 'tuitionFee',
      key: 'tuitionFee'
    },

    {
      title: 'Action',
      dataIndex: 'id',
      key: 'action',
      render: (key, val, rowKey) => {
        return (
          <div className={'pa-0'}>
            <Button
              shape="circle"
              size={'small'}
              icon="edit"
              id={'edit'}
              onClick={() => editInstallment1(val, rowKey)}
            />
            <Button
              style={{ marginLeft: 6 }}
              size={'small'}
              color={'red'}
              shape="circle"
              icon="delete"
              id={'delete'}
              onClick={() => deleteInstallment1(val, rowKey)}
            />
          </div>
        )
      }
    }
  ]

  const installmentColumn2 = [
    {
      title: 'Study Period',
      dataIndex: 'studyPeriod',
      key: 'studyPeriod'
    },
    {
      title: 'Due Date',
      dataIndex: 'dueDate',
      key: 'dueDate',
      required: true,
      render: item => {
        return displayDate(item)
      }
    },
    {
      title: 'Application Fee',
      dataIndex: 'applicationFee',
      key: 'applicationFee'
    },
    {
      title: 'Material Fee',
      dataIndex: 'materialFee',
      key: 'materialFee'
    },
    {
      title: 'Tuition Fee',
      dataIndex: 'tuitionFee',
      key: 'tuitionFee'
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'action',
      render: (key, val, rowKey) => {
        return (
          <div className={'pa-0'}>
            <Button
              shape="circle"
              size={'small'}
              icon="edit"
              id={'edit'}
              onClick={() => editInstallment2(val, rowKey)}
            />
            <Button
              style={{ marginLeft: 6 }}
              size={'small'}
              color={'red'}
              shape="circle"
              icon="delete"
              id={'delete'}
              onClick={() => deleteInstallment2(val, rowKey)}
            />
          </div>
        )
      }
    }
  ]

  const intakeColumns = [
    {
      title: 'Month',
      dataIndex: 'month',
      key: 'month'
    },
    {
      title: 'Year',
      dataIndex: 'year',
      key: 'year'
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
      render: (item, record) => {
        return displayDate(item)
      }
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
      render: (item, record) => {
        return displayDate(item)
      }
    },
    {
      title: '2nd Year StartDate',
      dataIndex: 'secondYearStartDate',
      key: 'secondYearStartDate',
      render: (item, record) => {
        return displayDate(item)
      }
    },
    {
      title: '2nd Year EndDate',
      dataIndex: 'secondYearEndDate',
      key: 'secondYearEndDate',
      render: (item, record) => {
        return displayDate(item)
      }
    },
    {
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
      // render: (item, record) => {
      //   console.log(record, item, 'tiititittii')
      //   return  <span>{active ? 'Active' : 'Inactive'}</span>
      // }
      render: active => <span>{active ? 'Active' : 'Inactive'}</span>
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'action',
      render: (key, val, rowKey) => (
        <div className={'pa-0'}>
          <Button
            shape="circle"
            size={'small'}
            icon="edit"
            id={'edit'}
            onClick={() => editIntake(val, rowKey)}
          />
          <Button
            style={{ marginLeft: 6 }}
            size={'small'}
            color={'red'}
            shape="circle"
            icon="delete"
            id={'delete'}
            onClick={() => deleteIntakeEntry(val, rowKey)}
          />
        </div>
      )
    }
  ]

  const qualificationsCoulmn = [
    {
      title: 'Qualification',
      dataIndex: 'qualification',
      key: 'qualification'
    },
    {
      title: 'Passing Year',
      dataIndex: 'passingYear',
      key: 'passingYear'
    },
    {
      title: 'Percentage',
      dataIndex: 'percentage',
      key: 'percentage'
    },
    {
      title: 'Maths',
      dataIndex: 'maths',
      key: 'maths'
    },
    {
      title: 'English',
      dataIndex: 'english',
      key: 'english'
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'action',
      render: (key, val, rowKey) => {
        return (
          <div className={'pa-0'}>
            <Button
              shape="circle"
              size={'small'}
              icon="edit"
              id={'edit'}
              onClick={() => editQualification(val, rowKey)}
            />
            <Button
              style={{ marginLeft: 6 }}
              size={'small'}
              color={'red'}
              shape="circle"
              icon="delete"
              id={'delete'}
              onClick={() => deleteQualification(val, rowKey)}
            />
          </div>
        )
      }
    }
  ]
  const onClose = () => {
    setState(() => ({ ...state, edit: false, examType: '' }))
    setExamState(() => InitialExamState)
  }
  const handleSubmit = e => {
    console.log('call', state.intakeList)
    const { form } = props
    const { tabsAlert } = state
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (state.qualifications)
        valData.qualification = [...state.qualifications]
      if (state.ieltsRequirements)
        valData.englishRequirments = [...state.ieltsRequirements]
      if (state.intakeList) valData.intakeList = [...state.intakeList]
      if (state.universityCountry)
        valData.universityCountry = state.universityCountry
      if (state.universityState) valData.universityState = state.universityState
      if (state.universityCity) valData.universityCity = state.universityCity
      if (state.universityName) valData.universityName = state.universityName
      if (state.waiverList) valData.waiverList = [...state.waiverList]
      if (state.installmentSchedule1)
        valData.installmentSchedule1 = state.installmentSchedule1
      if (state.installmentSchedule2)
        valData.installmentSchedule2 = state.installmentSchedule2
      if (pathway == false) {
        valData.courseName2 = ''
        valData.courseCode2 = ''
        valData.cricosCode2 = ''
        valData.courseDuration2 = ''
        valData.installmentSchedule2 = []
      }
      // valData.courseLevel = { levelName: valData.courseLevel }

      if (!err) {
        let data1 = await dispatch(updateCourse(valData, courseId))
        if (data1 && !data1.error) {
          // form.resetFields();
          // setState(() => ({ ...initialState, key: Math.random() * 100 }))
          // setExamState(() => (InitialExamState))
        }
      } else {
        // console.log(err)
        notification.warning({
          message: 'Fill All Required Fields'
        })
      }
    })
  }
  const submitFormLayout = {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 10, offset: 7 },
      md: { span: 12, offset: 8 }
    }
  }
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }

  const addWaiver = values => {
    let findExists = _.find(state.waiverList, (item, key) => {
      if (values.edit) {
        return item.board == values.board && values.rowKey !== key
      } else {
        return item.board == values.board
      }
    })
    if (findExists) {
      notification.warning({
        message: 'Board already exists.'
      })
      return
    }

    if (values.edit) {
      const clone = _.cloneDeep(state.waiverList)
      clone[values.rowKey] = {
        board: values.board ? values.board : '',
        year: values.year ? values.year : '',
        percentage: values.percentage ? values.percentage : ''
      }
      setState({ ...state, waiverList: clone })
      setVisibleWaiver(false)
      setEditAbleWaiver({})
    } else {
      let obj = {
        board: values.board ? values.board : '',
        year: values.year ? values.year : '',
        percentage: values.percentage ? values.percentage : ''
      }
      setState({
        ...state,
        waiverList: [...state.waiverList, obj]
      })
      setVisibleWaiver(false)
      setEditAbleWaiver({})
    }
  }

  const editWavier = (item, rowKey) => {
    setEditAbleWaiver({ ...item, edit: true, rowKey })
    setVisibleWaiver(true)
  }
  const closeWavier = (item, rowKey) => {
    setVisibleWaiver(false)
    setEditAbleWaiver({})
  }
  const deleteWaiver = (item, rowKey) => {
    let clone = _.cloneDeep(state.waiverList)
    clone.splice(rowKey, 1)
    setState(() => ({
      ...state,
      waiverList: clone
    }))
  }

  const waiverColumn = [
    {
      title: 'Board',
      dataIndex: 'board',
      key: 'board'
    },
    {
      title: 'Year',
      dataIndex: 'year',
      key: 'year'
    },
    {
      title: 'Percentage',
      dataIndex: 'percentage',
      key: 'percentage'
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'action',
      render: (key, val, rowKey) => {
        return (
          <div className={'pa-0'}>
            <Button
              shape="circle"
              size={'small'}
              icon="edit"
              id={'edit'}
              onClick={() => editWavier(val, rowKey)}
            />
            <Button
              style={{ marginLeft: 6 }}
              size={'small'}
              color={'red'}
              shape="circle"
              icon="delete"
              id={'delete'}
              onClick={() => deleteWaiver(val, rowKey)}
            />
          </div>
        )
      }
    }
  ]
  return (
    <div>
      <div className="row">
        <div className="col-lg-12">
          <Form
            onSubmit={handleSubmit}
            style={{ marginTop: 8, textAlign: 'left' }}
            noValidate>
            <div className="form-box mt-4">
              <div className="d-flex align-items-center mb-3 heading-form">
                <h5>course general details</h5>
              </div>

              <div className="card unizportal">
                <Row gutter={24} className={'rowWrap'}>
                  {inputTypes.fields.map((item, key) => {
                    return item.hidden == undefined ||
                      (item.hidden !== undefined && !item.hidden) ? (
                      <Col span={8} md={8} sm={12} xs={24} key={key}>
                        <GetEachFormFields
                          item={item}
                          getFieldDecorator={getFieldDecorator}
                          formItemLayout={formItemLayout}
                        />
                      </Col>
                    ) : null
                  })}
                </Row>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="btn"
                    loading={courseLoader}>
                    SAVE
                  </Button>
                </Form.Item>
              </div>
            </div>

            {getFieldValue('waiver') ? (
              <div className="form-box mt-4">
                <div className="d-flex align-items-center mb-3 heading-form">
                  <h5>Waivers</h5>
                  <a
                    className="btn ml-auto manage"
                    onClick={() => {
                      setVisibleWaiver(true)
                      setEditAbleEnglish({})
                    }}>
                    manage
                  </a>
                </div>

                <div className="card unizportal">
                  <div className="card-body table-responsive p-0">
                    <Table
                      pagination={false}
                      columns={waiverColumn}
                      dataSource={state.waiverList}
                      rowKey="key"
                    />
                  </div>
                </div>
              </div>
            ) : null}

            <div className="form-box mt-4">
              <div className="d-flex align-items-center mb-3 heading-form">
                <h5>English Requirements</h5>
                <a
                  className="btn ml-auto manage"
                  onClick={() => {
                    setVisibleEnglishRequirement(true)
                    setEditAbleEnglish({})
                  }}>
                  manage
                </a>
              </div>

              <div className="card unizportal">
                <div className="card-body table-responsive p-0">
                  <Table
                    pagination={false}
                    columns={ieltsColumn}
                    dataSource={state.ieltsRequirements}
                    rowKey="key"
                  />
                </div>
              </div>
            </div>

            <div className="form-box mt-4">
              <div className="d-flex align-items-center mb-3 heading-form">
                <h5>Qualification</h5>
                <a
                  className="btn ml-auto manage"
                  onClick={() => {
                    setVisibleQualification(true)
                    setEditAbleQualification({})
                  }}>
                  manage
                </a>
              </div>

              <div className="card unizportal">
                <div className="card-body table-responsive p-0">
                  <Table
                    pagination={false}
                    columns={qualificationsCoulmn}
                    dataSource={state.qualifications}
                    rowKey="key"
                  />
                </div>
              </div>
            </div>

            {selectedUniversity == CampBellUniversity ? (
              <div className="form-box mt-4">
                <div className="d-flex align-items-center mb-3 heading-form">
                  <h5>Installments Schedule for Course 1</h5>
                  <a
                    className="btn ml-auto manage"
                    onClick={() => {
                      setInstallmentSchedule1(true)
                    }}>
                    manage
                  </a>
                </div>

                <div className="card unizportal">
                  <div className="card-body table-responsive p-0">
                    <Table
                      pagination={false}
                      columns={installmentColumn1}
                      dataSource={state.installmentSchedule1}
                      rowKey="key"
                    />
                  </div>
                </div>
              </div>
            ) : null}

            {selectedUniversity == CampBellUniversity && pathway ? (
              <div className="form-box mt-4">
                <div className="d-flex align-items-center mb-3 heading-form">
                  <h5>Installments Schedule for Course 2</h5>
                  <a
                    className="btn ml-auto manage"
                    onClick={() => {
                      setInstallmentSchedule2(true)
                    }}>
                    manage
                  </a>
                </div>

                <div className="card unizportal">
                  <div className="card-body table-responsive p-0">
                    <Table
                        pagination={false}
                        columns={installmentColumn2}
                        dataSource={state.installmentSchedule2}
                        rowKey="key"
                    />
                  </div>
                </div>
              </div>
            ) : null}
            {selectedUniversity && DirectUniversityCheck(selectedUniversity) ? (
                <div className="form-box mt-4">
                  <div className="d-flex align-items-center mb-3 heading-form">
                    <h5>Intake List</h5>
                    <a
                        className="btn ml-auto manage"
                        onClick={() => {
                          setVisibleIntake(true)
                          setEditableIntake({})
                        }}>
                      manage
                  </a>
                </div>

                <div className="card unizportal">
                  <div className="card-body table-responsive p-0">
                    <Table
                      pagination={false}
                      columns={intakeColumns}
                      dataSource={state.intakeList}
                      rowKey="key"
                    />
                  </div>
                </div>
              </div>
            ) : null}
          </Form>
        </div>
      </div>

      {visibleEnglishRequirement && (
        <EnglishRequirement
          visible={visibleEnglishRequirement}
          data={editAbleEnglish}
          addEnglishReq={data => addExam(data)}
          onClose={data => setVisibleEnglishRequirement(false)}
        />
      )}
      {visibleIntake && (
        <Intake
          pathway={pathway}
          visible={visibleIntake}
          data={editableIntake}
          addIntakeFxn={data => addIntake(data)}
          onClose={data => setVisibleIntake(false)}
        />
      )}

      {visibleQualification && (
        <QualificationDrawer
          visible={visibleQualification}
          data={editAbleQualification}
          addQualificationReq={data => addQualification(data)}
          onClose={data => setVisibleQualification(false)}
        />
      )}

      {visibleWaiver && (
        <WaiversDrawer
          visible={visibleWaiver}
          data={editAbleWaiver}
          addWaiverReq={data => addWaiver(data)}
          onClose={data => closeWavier()}
        />
      )}

      {installmentSchedule1 && (
        <InstallmentSchedule1
          visible={installmentSchedule1}
          data={installmentData1}
          addInstallment1Data={data => addInstallments(data)}
          onClose={data => setInstallmentSchedule1(false)}
        />
      )}

      {installmentSchedule2 && (
        <InstallmentSchedule2
          visible={installmentSchedule2}
          data={installmentData2}
          addInstallment2Data={data => addInstallments2(data)}
          onClose={data => setInstallmentSchedule2(false)}
        />
      )}

      {/*<Qualifications handleSubmit={addQualification}/>*/}
    </div>
  )
}
const mapStateToProps = ({ global, router }) => ({
  search: router.location.search,
  pathname: router.location.pathname
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
const WrappedComponent = Form.create()(AddCourse)
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedComponent)
// const WrappedComponent = Form.create()(AddCourse)
// export default WrappedComponent
