import { Button, Col, Drawer, Form, Input, notification, Row } from 'antd'
import _ from 'lodash'
import moment from 'moment'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import { DocumentTypes, InputBox } from '../../../components/_utils/appUtils'
import { RowTable } from '../../../components/_utils/RowTable'
import { uploadLesterDocumentFxn } from '../../student/actions/student'
import { RequestLoa } from '../actions/chooseStudent'

const DocumentList = ['loaLetter']
const LoaLetter = props => {
  let dispatch = useDispatch()
  let {
    visible,
    onClose,
    applicationId,
    studentId,
    reloadTable,
    editAble,
    studentData
  } = props
  let [state, setState] = useState([])
  const events = {
    chooseDocument: e => {
      let { name, files } = e.target
      if (files && files.length) {
        setState({
          ...state,
          [name]: files[0]
        })
      }
    },
    handleSubmit: async () => {
      let fd = new FormData()
      let obj = {
        studentId,
        applicationId
      }

      if (
        !state['loaLetter'] ||
        (state['loaLetter'] && !state['loaLetter'].name)
      ) {
        return notification.warn({ message: 'Please choose Loa Letter' })
      }

      fd.append('obj', JSON.stringify(obj))

      if (state['loaLetter']) {
        fd.append('loaLetter', state['loaLetter'])
      }

      let data = await dispatch(uploadLesterDocumentFxn(fd))
      if (data && data.success) {
        onClose()
        reloadTable()
      }
    }
  }
  return (
    <Drawer
      visible={visible}
      title={'LOA Letter'}
      onClose={onClose}
      width={'40%'}>
      <div className="row">
        <div className="col-lg-12">
          <Form className={'vertical-form'} autoComplete="off">
            <div className="form-box">
              <div className="card unizportal">
                {studentData &&
                studentData.applications &&
                studentData.applications.id ? (
                  <div className={'tableBox striped'}>
                    <RowTable
                      title={'Name (ID)'}
                      value={
                        <span>
                          {studentData.name} ({studentData.studentId})
                        </span>
                      }
                    />
                    <RowTable
                      title={'Application ID'}
                      value={<span>{studentData.applications.id}</span>}
                    />
                    <RowTable
                      title={'Application'}
                      value={<span>{studentData.applications.courseName}</span>}
                    />
                  </div>
                ) : null}
                <br />

                <div className="inner-form">
                  <Row gutter={24} key={state.uploadKey}>
                    {DocumentList.map((item, key) => {
                      return (
                        <Col span={24} key={key}>
                          <InputBox title={`${item} Document`}>
                            <Input
                              type={'file'}
                              name={item}
                              id={item}
                              className={'form-control'}
                              onChange={e => {
                                events.chooseDocument(e)
                              }}
                            />
                          </InputBox>
                        </Col>
                      )
                    })}
                  </Row>

                  <button
                    className="btn"
                    type="submit"
                    onClick={events.handleSubmit}>
                    save
                  </button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </Drawer>
  )
}
export default LoaLetter
