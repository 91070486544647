import React, {useState, useEffect} from 'react'
import user from '../users/reducers/user'
import {departmentObj, countryIds} from '../../components/_utils/appUtils'

const AllowComponentRightsWise = props => {
    let {rightUserType} = props
    let [userType, setUserType] = useState({})
    useEffect(() => {
        let user = localStorage.getItem('user')
            ? JSON.parse(localStorage.getItem('user'))
            : null
        if (user && user.userType) {
            setUserType(user.userType)
        }
    }, [])

    return (
        <React.Fragment>
            {rightUserType.includes(userType) ? props.children : null}
        </React.Fragment>
    )
}

const AllowComponentUserWise = props => {
    let {rightUserType} = props
    let [userType, setUserType] = useState({})
    let user = localStorage.getItem('user')
        ? JSON.parse(localStorage.getItem('user'))
        : null
    useEffect(() => {
        if (user && user.userType) {
            setUserType(user.userType)
        }
    }, [])

    let checkManagementViewOnly = () => {
        if (
            user &&
            (user.userType == 'branchManager' || user.userType == 'branchUser')
        ) {
            if (user.managerViewOnly) {
                return null // check for branch manager but have only view rights
            } else {
                return props.children // check for branch manager
            }
        } else {
            return rightUserType.includes(userType) ? props.children : null // check for admin, branch user and extra
        }
    }

    return <React.Fragment>{checkManagementViewOnly()}</React.Fragment>
}

const CheckReOpenRight = props => {
    let {applications} = props
    let user = localStorage.getItem('user')
        ? JSON.parse(localStorage.getItem('user'))
        : null

    let checkManagementViewOnly = () => {
        if (user && user.userType == 'agent') {
            if (applications.universityCountry._id == countryIds.uk) {
                return props.children
            } else {
                return null
            }
        } else {
            return null
        }
    }

    return <React.Fragment>{checkManagementViewOnly()}</React.Fragment>
}

const CheckBranchUserRight = props => {
    let user = localStorage.getItem('user')
        ? JSON.parse(localStorage.getItem('user'))
        : null
    let checkManagementViewOnly = () => {
        if (
            user &&
            (user.userType == 'branchManager' || user.userType == 'branchUser')
        ) {
            if (user.managerViewOnly) {
                return null // check for branch manager but have only view rights
            } else {
                return props.children // check for branch manager
            }
        } else {
            return props.children // check for admin, branch user and extra
        }
    }
    return <React.Fragment>{checkManagementViewOnly()}</React.Fragment>
}

const CheckManagerWiseRight = props => {
    let user = localStorage.getItem('user')
        ? JSON.parse(localStorage.getItem('user'))
        : null
    let checkManagementViewOnly = () => {
        if (
            user &&
            (user.userType == 'branchManager' || user.userType == 'admin')
        ) {
            if (user.managerViewOnly) {
                return null // check for branch manager but have only view rights
            } else if (
                user.branchManagerType &&
                user.branchManagerType != 'universityWise'
            ) {
                return props.children // check for branch manager
            } else {
                return props.children // check for admin
            }
        } else {
            return null // check for admin, branch user and extra
        }
    }
    return <React.Fragment>{checkManagementViewOnly()}</React.Fragment>
}

const CheckCaseCloseRight = props => {
    let user = localStorage.getItem('user')
        ? JSON.parse(localStorage.getItem('user'))
        : null
    let checkManagementViewOnly = () => {
        if (
            user &&
            (user.userType == 'branchManager' || user.userType == 'admin')
        ) {
            if (user.managerViewOnly) {
                return null // check for branch manager but have only view rights
            } else if (
                user.branchManagerType &&
                user.branchManagerType != 'universityWise'
            ) {
                return props.children // check for branch manager
            } else {
                return props.children // check for admin
            }
        } else if (
            user &&
            (user.userType == 'branchUser' &&
                user.department == departmentObj.marketing)
        ) {
            return props.children // check for marketing user
        } else {
            return null // check for admin, branch user and extra
        }
    }
    return <React.Fragment>{checkManagementViewOnly()}</React.Fragment>
}

const ValidateUserRight = props => {
    let {
        user: {userType, department, branchManagerType},
        rightUserType,
        branchManagerTypeWise,
        departmentWise,
        children
    } = props
    let showComponent = false
    if (rightUserType && rightUserType.length && userType) {
        if (rightUserType.includes(userType)) {
            showComponent = true
        } else {
            showComponent = false
        }
    }
    if (
        branchManagerTypeWise &&
        branchManagerTypeWise.length &&
        branchManagerType
    ) {
        if (branchManagerTypeWise.includes(branchManagerType)) {
            showComponent = true
        } else {
            showComponent = false
        }
    }
    if (departmentWise && departmentWise.length && department) {
        if (departmentWise.includes(department)) {
            showComponent = true
        } else {
            showComponent = false
        }
    }
    return showComponent === true ? children : null
}

const CheckBranchUserFxn = () => {
    let user = localStorage.getItem('user')
        ? JSON.parse(localStorage.getItem('user'))
        : null
    if (user) {
        if (
            user &&
            (user.userType == 'branchManager' || user.userType == 'branchUser')
        ) {
            if (user.managerViewOnly) {
                return false // check for branch manager but have only view rights
            } else {
                return true // check for branch manager
            }
        } else if (user && user.userType == 'branchUser') {
            if (user.department == departmentObj.interviewer) {
                return false
            } else {
                return true
            }
        } else if (user.userType == 'agent' || user.userType == 'subAgent') {
            return true // check for agent and sub agents
        } else {
            return true // check for admin, branch user and extra
        }
    } else {
        return false
    }
}

const ShowEnrollmentComponent = props => {
    let {rightUserType} = props
    let [userType, setUserType] = useState({})
    let user = localStorage.getItem('user')
        ? JSON.parse(localStorage.getItem('user'))
        : null
    useEffect(() => {
        if (user && user.userType) {
            setUserType(user.userType)
        }
    }, [])

    const checkVisaApp = () => {
        if (user && user.userType == 'branchUser') {
            if (
                user.department == departmentObj.visaApproved ||
                user.email == 'onshoreaustralia@unizportal.com'
            ) {
                return true
            } else {
                return false
            }
        } else {
            return true
        }
    }

    return (
        <React.Fragment>
            {rightUserType.includes(userType)
                ? checkVisaApp()
                    ? props.children
                    : null
                : null}
        </React.Fragment>
    )
}
export {
    AllowComponentRightsWise,
    AllowComponentUserWise,
    CheckBranchUserRight,
    CheckBranchUserFxn,
    ShowEnrollmentComponent,
    CheckManagerWiseRight,
    ValidateUserRight,
    CheckCaseCloseRight,
    CheckReOpenRight
}
