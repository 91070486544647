import { Button, Col, DatePicker, Row, Select, Table, notification } from 'antd'
import _ from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { connect, useDispatch } from 'react-redux'
import {
  departmentObj,
  filterOption,
  universityListForMarketing
} from '../../../components/_utils/appUtils'
import { getUrlPushWrapper } from '../../../routes'
import { listAllUsers } from '../../users/actions/user'

import { getCountry } from '../../countries/actions/countries'
import {
  directUniversityMarketingManagerReportsFxn,
  directUniversityRecordsCountFxn,
  getMeetingsCount,
  marketingManagerReportsFxn
} from '../../reporting/actions'

const { RangePicker } = DatePicker

const { Option } = Select
const WeeklyReports = props => {
  let { currentUser } = props
  let [countryId, setCountryId] = useState('')
  let [countryName, setCountryName] = useState('')
  const [selectUser, setSelectUser] = useState('')
  const [userId, setUserId] = useState('')
  const [userList, setUserList] = useState([])
  const [userData, setUserData] = useState({})
  const [department, setDepartment] = useState('')
  const [managerType, setManagerType] = useState('')
  const [directUniversityData, setDirectUniversityData] = useState({})
  const [directUniversityCount, setDirectUniversityCount] = useState({})
  const [dateRange, setDateRange] = useState([])

  const [fromDate, setFromDate] = useState(
    moment()
      .subtract(1, 'week')
      .startOf('week')
  )
  const [toDate, setToDate] = useState(
    moment()
      .subtract(1, 'week')
      .endOf('week')
  )

  const dispatch = useDispatch()

  const loadCountry = async () => {
    let { countryId } = currentUser
    let data = await dispatch(getCountry(countryId))
    if (data && data.countryName) {
      setCountryName(data.countryName)
    }
  }

  const loadUserList = async () => {
    let userType = 'branchUser'
    let department = departmentObj.marketing
    if (userType) {
      let params = {
        results: 1000,
        count: 1000,
        userType,
        department: department,
        sortField: 'name',
        sortOrder: 'ascend',
        select: ['name', 'userType', 'mobile', 'email']
      }
      let { data } = await dispatch(listAllUsers(params))
      if (data && data.length) {
        setUserList(data)
        getParamsForApplicationList()
      }
    } else {
      setUserList([])
    }
  }

  useEffect(() => {
    reloadData()
  }, [])

  let reloadData = async () => {
    loadUserReport(null)
  }
  let openDirectUniversity = universityId => {
    let params = {
      userId: userId,
      department: departmentObj.marketing,
      userType: currentUser.userType,
      appUniversityId: universityId
    }
    if (fromDate) {
      params.fromDate = moment(fromDate).format('MM-DD-YYYY')
      params.toDate = moment(toDate).format('MM-DD-YYYY')
    }
  }
  const loadUserReport = async (userId = '') => {
    let obj = {
      userId: userId ? userId : ''
    }
    if (managerType) {
      obj.managerType = managerType
    }
    if (department) {
      obj.department = department
    }

    let params = {
      userId: userId ? userId : ''
    }
    if (fromDate) {
      params.fromDate = fromDate
      params.toDate = toDate
    }
    console.log(params, 'prprprprprpr')

    let directUniversityCountData = await dispatch(
      directUniversityRecordsCountFxn(params)
    )
    setDirectUniversityCount(directUniversityCountData)
  }

  const getParamsForApplicationList = () => {
    return new Promise(resolve => {
      let searchParams = new URLSearchParams(window.location.search)

      let userId = searchParams.get('userId')
      let toDate = searchParams.get('toDate')
      let fromDate = searchParams.get('fromDate')
      let userType = searchParams.get('userType')
      let obj = {}
      if (userId) {
        setSelectUser(userId ? userId : '')
        obj.userId = userId
      }
      if (toDate) {
        obj.toDate = toDate
      }
      if (fromDate) {
        obj.fromDate = fromDate
      }
      if (userType) {
        obj.userType = userType
      }
      resolve(obj)
    })
  }

  const ranges = {
    Today: [moment(), moment()],
    'Last Week': [
      moment()
        .subtract(1, 'week')
        .startOf('week'),
      moment()
        .subtract(1, 'week')
        .endOf('week')
    ],
    'Last 15 Days': [moment().subtract(14, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last 6 Months': [
      moment()
        .subtract(6, 'months')
        .startOf('month'),
      moment().endOf('month')
    ],
    'This Year': [moment().startOf('year'), moment().endOf('year')]
  }
  return (
    <div className="row  mt-4">
      <div className="col-lg-12">
        <div className="card">
          <div className="table-head d-flex align-items-center">
            <h5>Weekly Report</h5>
          </div>
          <Row style={{ paddingLeft: 10, paddingTop: 10 }}>
            <Col md={6} sm={6} xs={6} lg={6} offset={1}>
              {/* <RangePicker
                defaultValue={[fromDate, toDate]}
                mode="month"
                onChange={val => {
                  setFromDate(val[0])
                  setToDate(val[1])
                }}
              /> */}
              <RangePicker
                // onChange={handleDateRangeChange}
                // value={dateRange}
                defaultValue={[fromDate, toDate]}
                onChange={val => {
                  setFromDate(val[0])
                  setToDate(val[1])
                }}
                ranges={ranges}
              />
            </Col>

            <Col span={6} offset={1}>
              <Button onClick={() => loadUserReport()} className={'roundBtn'}>
                Search
              </Button>
            </Col>
          </Row>
          <div
            className="card-pane-right card-pane-new listing-section w-100"
            style={{ border: 'none' }}>
            <ul className={'count'}>
              {universityListForMarketing.map(item => {
                return (
                  <li
                    key={item.id}
                    className={'directUniCount'}
                    onClick={() => {
                      openDirectUniversity(item.id)
                    }}>
                    <div className={'uniCountBox'}>
                      <LazyLoadImage src={item.image} />
                      <h5>
                        {directUniversityCount[item.id]
                          ? directUniversityCount[item.id]
                          : 0}
                      </h5>
                    </div>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ global }) => ({
  currentUser: global.currentUser,
  activeUser: global.activeUser
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WeeklyReports)
