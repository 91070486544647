import React, {useState, useEffect, useRef} from 'react'
import {
    Button,
    Icon,
    Popconfirm, Row, Select,
    Tooltip
} from 'antd'
import {useDispatch, useSelector} from 'react-redux'
import {TableComp} from 'sz-react-utils-lite'
import {listWithdrawalFxn} from "../../withdrawal/actions/withdrawalAction";
import AddCallDrawer from "../drawers/addCallDrawer";
import GenerateInvoice from "../drawers/generateInvoice";
import UploadReceiptDrawer from "../drawers/uploadReceiptDrawer";

const {Option} = Select
const initState = {
    withdrawalId: "",
    visible: false,
    status: ""
}
const initReceiptState = {
    withdrawalId: "",
    visible: false
}
const initInvoiceState = {
    withdrawalId: "",
    visible: false
}
const ListAllWithdrawal = () => {
    let [total, setTotal] = useState(0);
    let [state, setState] = useState(initState)
    let [receiptState, setReceiptState] = useState(initReceiptState)
    let [invoiceState, setInvoiceState] = useState(initInvoiceState)
    const tableRef = useRef()
    const dispatch = useDispatch()
    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            let resp = await dispatch(listWithdrawalFxn({
                ...params,
                requestForCall: true,
                // regExFilters: ['status']
            }));
            setTotal(resp.total)
            resolve(resp)
        })
    }

    const updateState = (data) => {
        setState((prevState) => {
            return {
                ...prevState,
                ...data
            }
        })
    }

    const events = {
        showDrawer: (withdrawalId, status) => {
            updateState({
                withdrawalId,
                visible: true,
                status: status,
            })
        },
        hideDrawer: () => {
            updateState({
                withdrawalId: "",
                visible: false,
                status: ""
            })
            tableRef.current.reload();
        },
        showReceiptDrawer: (withdrawalId) => {
            setReceiptState({
                withdrawalId,
                visible: true
            })
        },
        hideReceiptDrawer: () => {
            setReceiptState({
                withdrawalId: "",
                visible: false
            })
            tableRef.current.reload();
        },
        showInvoiceDrawer: (withdrawalId) => {
            setInvoiceState({
                withdrawalId,
                visible: true
            })
        },
        hideInvoiceDrawer: () => {
            setInvoiceState({
                withdrawalId: "",
                visible: false
            })
            tableRef.current.reload();
        },

    }

    const columns = [

        {
            title: 'S.No',
            dataIndex: 'sno',
            width: 50,
            key: 'sno',
            render: (item, record, index) => {
                return <a className={'linkPointer'}>
                    {index + 1}
                </a>
            }
        },
        {
            title: 'Agent',
            dataIndex: 'agentId',
            width: 150,
            key: 'agentId',
            render: (item, record) => {
                return (
                    <div>
                        {item && item.name ? item.name : ""}
                        {item && item.companyName ? `(${item.companyName})` : ""}
                    </div>
                )
            }
        },
        {
            title: 'Country',
            dataIndex: 'countryId',
            width: 80,
            key: 'countryId',
            render: (item, record) => {
                return (
                    <div>
                        {item && item.countryName ? item.countryName : ""}
                    </div>
                )
            }
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            width: 80,
            key: 'amount',
            render: (item, record) => {
                let {countryId} = record;
                return (
                    <div>
                        {countryId && countryId.currency ? countryId.currency : ""} {item}
                    </div>
                )
            }
        },
        {
            title: 'Details',
            dataIndex: 'account',
            width: 150,
            key: 'account',
            render: (item, record) => {
                return (
                    <div>
                        Acc no : {item.accountNo} ({item.bankName}) <br/>
                        IFSC: {item.ifsc} <br/>
                        Swift: {item.swiftCode} <br/>
                        Address: {item.address}
                    </div>
                )
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 100,
            render: (item, record) => {
                return (
                    <div>
                        {item == 'Approved' ? <label className={'label label-success'}>Approved</label> : null}
                        {item == 'Pending' ? <label className={'label label-default'}>Pending</label> : null}
                        {item == 'Cancel' ? <label className={'label label-danger'}>Cancel</label> : null}
                    </div>
                )
            }
        },
        {
            title: 'Call Verified',
            dataIndex: 'verified',
            key: 'verified',
            width: 100,
            render: (item, record) => {
                let {requestForCall} = record;
                return (
                    <div>
                        {requestForCall ?
                            <>
                                <div>
                                    {item == 'Verified' ?
                                        <label className={'label label-success-green'}>Verified</label> : null}
                                    {item == 'Pending' ?
                                        <label className={'label label-default'}>Pending</label> : null}
                                    {item == 'Not Verified' ?
                                        <label className={'label label-danger'}>Not Verified</label> : null}
                                </div>
                            </>
                            : null}
                    </div>
                )
            }
        },
        {
            title: 'Invoice',
            dataIndex: 'invoice',
            key: 'invoice',
            width: 100,
            render: (item, record) => {
                let {currencyRate} = record;
                return (
                    <>
                        {item && item.path ?
                            <>
                                Currency Rate : {currencyRate}<br/>
                                <a href={item.path} target={'_blank'}>Download Invoice</a>
                            </>
                            : null}
                    </>
                )
            }
        },
        {
            title: 'Receipt',
            dataIndex: 'receipt',
            key: 'receipt',
            width: 100,
            render: (item, record) => {
                let {invoice} = record;
                return (
                    <>
                        <div>
                            {item && item.url ?
                                <>
                                    <a href={item.url} target={'_blank'}>Download Receipt</a>
                                </>
                                : null}
                        </div>
                    </>
                )
            }
        },
        {
            key: 'actions',
            title: 'Actions',
            width: 80,
            render: (text, record) => {
                let {verified, status, currencyRate, receipt} = record;
                return (
                    <>
                        {verified == 'Pending' ?
                            <div className={'view-btn-box ml10'}>
                                <Tooltip title="Approve">
                                    <a className={'btn btn-success btn-sm mr10'}
                                       onClick={() => {
                                           events.showDrawer(record._id, 'Approved')
                                       }}>
                                        Add Call
                                    </a>
                                </Tooltip>
                            </div> : null}

                        {(status == 'Approved' && !currencyRate) ?
                            <div className={'view-btn-box ml10'}>
                                <Tooltip title="Approve">
                                    <a className={'btn btn-success btn-sm mr10'}
                                       onClick={() => {
                                           events.showInvoiceDrawer(record._id)
                                       }}>
                                        Generate Invoice
                                    </a>
                                </Tooltip>
                            </div> : null}

                        {(status == 'Approved' && currencyRate && (!receipt || (receipt && !receipt.url))) ?
                            <div className={'view-btn-box ml10'}>
                                <Tooltip title="Approve">
                                    <a className={'btn btn-success btn-sm mr10'}
                                       onClick={() => {
                                           events.showReceiptDrawer(record._id)
                                       }}>
                                        Upload Receipt
                                    </a>
                                </Tooltip>
                            </div> : null}

                    </>
                )
            }
        }
    ]


    return (
        <div className="row mt-4">
            <div className="col-lg-12">
                <div className="card">
                    <div className="table-head d-flex align-items-center">
                        <Button onClick={() => events.goBack()}
                                className={'roundGoBackBtn'} icon={'arrow-left'}>
                            Back
                        </Button>
                        <h5>All Withdrawal: {total}</h5>
                        <div className="search-box-table">
                        </div>
                        <div className="sort-box-table mark-btn">
                        </div>
                    </div>
                    <div className="card-body table-responsive">
                        <TableComp columns={columns}
                                   apiRequest={apiRequest}
                                   ref={tableRef}
                        />
                    </div>
                </div>
            </div>
            {state.visible ?
                <AddCallDrawer
                    visible={state.visible}
                    withdrawalId={state.withdrawalId}
                    onClose={events.hideDrawer}
                />
                : null}

            {invoiceState.visible ?
                <GenerateInvoice
                    visible={invoiceState.visible}
                    withdrawalId={invoiceState.withdrawalId}
                    onClose={events.hideInvoiceDrawer}
                />
                : null}
            {receiptState.visible ?
                <UploadReceiptDrawer
                    visible={receiptState.visible}
                    withdrawalId={receiptState.withdrawalId}
                    onClose={events.hideReceiptDrawer}
                />
                : null}
        </div>
    )
}

export default ListAllWithdrawal
