import React, { Component } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { TableComp } from 'sz-react-utils-lite'
import {
  Table,
  Badge,
  Card,
  Tooltip,
  notification,
  Switch,
  Input, Button, Icon, Drawer, List, Popconfirm
} from 'antd'
import { connect } from 'react-redux'
import { listAllUsers, blockAgent } from '../actions/user'
import {
  ConditionOptions,
  DefaultTablePagination,
  CountryOptions
} from '../../../components/_utils/appUtils'
import { getPushPathWrapper } from '../../../routes'
import { listAllCountries } from '../../countries/actions/countries'
import UpdatePassword from '../views/changePassword'
import EditUser from '../masters/editMaster'
import { CheckMyRights } from '../../WebComponent/CheckUserRights'
import { ColumnWidth } from '../../WebComponent/columnWidth'
import { push } from 'connected-react-router'
import { CheckUserRight } from '../../dashboard/views/DashboardUserWise'
import LogsComponents from '../drawer/logsComponents'

const userTypeOptions = [
  { value: 'branchManager', text: 'Branch Manager' },
  { value: 'branchUser', text: 'Branch User' },
  { value: 'master', text: 'Master' }
]
const commissionType = [
  { text: 'Silver', value: 'silver' },
  { text: 'Gold', value: 'gold' },
  { text: 'Platinum', value: 'platinum' }
]

class AllUsers extends Component {

  events = {
    showUserRightDrawer: (data) => {
      this.setState({
        visibleUserRightDrawer: true,
        selectedUser: data
      })
    },
    hideUserRightDrawer: () => {
      this.setState({
        visibleUserRightDrawer: false,
        selectedUser: {}
      })
    },
    closeAndUpdateUserRightDrawer: () => {
      this.setState({
        visibleUserRightDrawer: false,
        selectedUser: {}
      }, () => {
        this.tableRef.current.reload()
      })
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      viewInfoDrawer: false,
      selectedRow: {},
      allAgents: [],
      allBranchManager: [],
      allCountry: [],
      selectedUser: {},
      visibleUpdatePassword: false,
      visibleUpdateUser: false,
      visibleUpdateSubAgent: false,
      visibleUserRightDrawer: false
    }
    this.tableRef = React.createRef()
  }

  componentDidMount() {
    this.loadAllCountry()
  }

  apiRequest = (params) => {
    let { dispatch } = this.props
    return new Promise(async (resolve) => {
      params.sortField = 'name'
      params.sortOrder = 'ascend'
      params.userType = ['master']
      let resp = await dispatch(listAllUsers({
        ...params,
        regExFilters: ['name', 'address', 'mobile', 'email']
      }))
      this.setState({ totalBranchUser: resp.total })
      resolve(resp)
    })
  }

  async loadAllCountry() {
    let { dispatch } = this.props
    let { data } = await dispatch(listAllCountries())
    this.setState({ allCountry: data })
  }

  viewInfo(record) {
    this.setState({
      selectedRow: record,
      viewInfoDrawer: true
    })
  }

  onClose = () => {
    this.setState({
      selectedRow: {},
      viewInfoDrawer: false
    })
  }

  closeUpdatePassword = () => {
    this.setState({
      selectedUser: {},
      visibleUpdatePassword: false
    }, () => {
      this.tableRef.current.reload()
    })
  }

  closeEditUser = () => {
    this.setState({
      selectedUser: {},
      visibleUpdateUser: false
    }, () => {
      this.tableRef.current.reload()
    })
  }

  closeEditSubAgent = () => {
    this.setState({
      selectedUser: {},
      visibleUpdateSubAgent: false
    }, () => {
      this.tableRef.current.reload()
    })
  }

  updateAgentBlockStatus = async (id) => {
    let { dispatch } = this.props
    let resp = await dispatch(blockAgent({ agentId: id }))
    if (resp && resp.success) {
      this.tableRef.current.reload()
    }
  }

  render() {
    let {
      selectedRow,
      allAgents,
      allBranchManager,
      allCountry,
      visibleUpdatePassword,
      selectedUser,
      visibleUpdateUser,
      visibleUpdateSubAgent,
      visibleUserRightDrawer,
      totalBranchUser
    } = this.state
    let { dispatch } = this.props
    const columns = [
      {
        title: '#',
        key: '_id',
        dataIndex: '_id',
        width: 50,
        render: (ite, record, index) => {
          return (
            <React.Fragment>
              {index + 1}
            </React.Fragment>
          )
        }
      },
      {
        title: 'Name',
        key: 'name',
        sorter: true,
        dataIndex: 'name',
        searchTextName: 'name',
        width: 100,
        render: (item, record) => {
          return (
            <ColumnWidth width={100}>
              {item}
              {record.postName ? <div>(Post Name : {record.postName})</div> : null}
            </ColumnWidth>
          )
        }
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        searchTextName: 'email',
        width: 120,
        render: (item) => {
          return (
            <ColumnWidth width={120}>{item}</ColumnWidth>
          )
        }
      },
      {
        title: 'Mobile No',
        dataIndex: 'mobile',
        key: 'mobile',
        searchTextName: 'mobile',
        width: 80,
        render: (item) => {
          return (
            <ColumnWidth width={80}>{item}</ColumnWidth>
          )
        }
      },

      {
        title: 'Country',
        dataIndex: 'countryRight',
        key: 'countryRight',
        filters: CountryOptions.map(x => ({ value: x.value, text: x.name })),
        width: 100,
        render: (item, record) => {
          return (
            <ColumnWidth width={100}>
              <div className={'mt10'}>
                {item == 'All' ? 'All Country' :
                  <a className={'bs-link'} onClick={() => {
                    this.viewInfo(record)
                  }}>Selected Country</a>
                }
              </div>

            </ColumnWidth>
          )
        }

      },


      {
        title: 'Block',
        dataIndex: 'block',
        width: 80,
        key: 'block',
        filters: ConditionOptions.map(x => ({ value: x.value, text: x.name })),
        render: (item, record) => {
          return (
            <React.Fragment>
              {record && record.userType && (record.userType !== 'admin') ?
                <div>
                  <Popconfirm title={`Are your sure, you want to ${item ? 'Un-Block' : 'Block'} this User?`}
                              onConfirm={() => {
                                this.updateAgentBlockStatus(record._id)
                              }}
                              okText='Yes' cancelText='No'>
                    {item ? <div className={'label label-success labelNew'}>Yes</div> :
                      <div className={'label label-warning labelNew'}>No</div>}
                  </Popconfirm>
                </div> : null}
            </React.Fragment>
          )
        }
      },
      {
        title: 'Action',
        key: '_id',
        dataIndex: '_id',
        width: 150,
        render: (val, record) => {
          return (
            <React.Fragment>
              <Tooltip title='Edit'>
                <button
                  className={'btn'}
                  style={{ marginRight: 6 }}
                  onClick={() => {
                    let { dispatch } = this.props
                    this.setState({
                      visibleUpdateUser: true,
                      selectedUser: record
                    })
                  }}>
                  <Icon type={'edit'} />
                </button>
              </Tooltip>
              <Tooltip title='Change Password'>
                <button
                  className={'btn'}
                  style={{ marginRight: 6 }}
                  onClick={() => {
                    this.setState({
                      selectedUser: record,
                      visibleUpdatePassword: true
                    })
                  }}>
                  <Icon type={'key'} />
                </button>
              </Tooltip>


              <LogsComponents user={record} {...this.props} currentUser={this.props.currentUser} />
            </React.Fragment>
          )
        }
      }
    ]
    return (
      <div>

        <div className='row  mt-4'>
          <div className='col-lg-12'>
            <div className='card'>
              <div className='table-head d-flex align-items-center'>
                <h5>All Masters ({this.state.totalBranchUser})</h5>
                <div className='search-box-table'>

                </div>

                <CheckMyRights rightList={['admin']}>
                  <div className='sort-box-table mark-btn'>
                    <div>
                      <a className='btn' onClick={() => dispatch(push('/user/add-master'))}>
                        <img src={'../dist/icons/plus.png'} className={'plus'} /> Add Master</a>

                    </div>
                  </div>
                </CheckMyRights>
              </div>
              <div className='card-body table-responsive'>
                <TableComp columns={columns}
                           ref={this.tableRef}
                           pagination={DefaultTablePagination()}
                           apiRequest={this.apiRequest}
                           extraProps={{ scroll: { x: 1000 } }}
                />
              </div>
            </div>
          </div>
        </div>


        <Drawer visible={this.state.viewInfoDrawer}
                width={520}
                onClose={this.onClose}>
          <Card title={'Countries'}>
            {selectedRow && selectedRow.countryArr && selectedRow.countryArr.length ?
              <List
                size='small'
                bordered
                dataSource={selectedRow.countryArr}
                renderItem={item => <List.Item>{item && item.countryName ? item.countryName : null}</List.Item>}
              /> : null
            }

          </Card>
        </Drawer>
        {visibleUpdatePassword ? <UpdatePassword
          visible={visibleUpdatePassword}
          onClose={() => this.closeUpdatePassword()}
          userData={selectedUser} /> : ''}

        {visibleUpdateUser ? <EditUser
          visible={visibleUpdateUser}
          onClose={() => this.closeEditUser()}
          userData={selectedUser} /> : ''}

      </div>)

  }
}


const mapStateToProps = ({ global }) => ({
  categories: global.categories,
  currentUser: global.currentUser

})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllUsers)
