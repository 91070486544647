import React, {useState} from "react"
import {Button, Drawer, notification} from "antd";
import InputBox from "../../../components/_utils/InputBox";
import {useDispatch} from "react-redux";
import {addCallInWithdrawalFxn, addReceiptInWithdrawalFxn, generateInvoiceFxn} from "../actions";

let verifiedOptions = ['Verified', "Not Verified"]

let initState = {
    currencyRate: "",
    receipt: ""
}
const GenerateInvoiceDrawer = (props) => {
    let dispatch = useDispatch()
    let [state, setState] = useState(initState);
    let {visible, onClose, withdrawalId} = props;

    let events = {
        updateState: (data) => {
            setState((prevState) => {
                return {
                    ...prevState,
                    ...data
                }
            })
        },
        submitCurrencyRate: async () => {
            let {currencyRate, receipt} = state;
            if (!currencyRate) {
                notification.warning({message: "Please enter currency rate."})
                return
            }
            let {data, success} = await dispatch(generateInvoiceFxn({currencyRate, withdrawalId}))
            if (success) {
                events.updateState(initState);
                onClose()
            }
        }
    }


    return (
        <Drawer
            visible={visible}
            title={`Generate Invoice`}
            onClose={onClose}
            width={'50%'}
        >
            <div className="form-box commissionBox">
                <div className="card unizportal">
                    <InputBox title={'Currency Rate'}>
                        <input
                            type={'number'}
                            className={'form-control'}
                            placeholder={'Enter Currency Rate'}
                            onChange={({target}) => {
                                events.updateState({currencyRate: target.value})
                            }}
                            value={state.currencyRate}>
                        </input>
                    </InputBox>
                    <Button
                        onClick={events.submitCurrencyRate}
                        type="primary"
                        className={`btn btn-default mt10`}>
                        Generate Invoice
                    </Button>
                </div>
            </div>
        </Drawer>
    )
}
export default GenerateInvoiceDrawer
