import React, {useEffect, useState} from "react"
import {Col, Drawer, Form, notification, Row} from "antd";
import GetEachFormFields from "../../../components/_utils/appFormUtils";
import {useDispatch} from "react-redux";
import {updateIndianCity} from "../actions";

const EditIndianCity = (props) => {
    let {visible, data, onClose, onSubmit} = props;
    const {form: {getFieldDecorator, setFieldsValue}} = props;
    let [cityId, setCityId] = useState("")
    const dispatch = useDispatch()
    useEffect(() => {
        setFormData()
    }, [])
    const setFormData = () => {
        setTimeout(() => {
            setFieldsValue({
                name: data.name,
                pinCode: data.pinCode
            })
            setCityId(data._id)
        }, 500)
    }

    const formItemLayout = {
        labelCol: {
            xs: {span: 24},
            sm: {span: 24},
            md: {span: 24}
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 24},
            md: {span: 24}
        }
    }

    let inputField = [
        {
            key: 'name',
            label: 'City Name',
            span: 8,
            required: true
        },
        {
            key: 'pinCode',
            label: 'City Pin Code',
            span: 8,
            required: true
        }
    ]
    const submitIndianCity = (e) => {
        let {form} = props;
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                valData.cityId = cityId;
                let data = await dispatch(updateIndianCity(valData))
                if (data && !data.error) {
                    onSubmit()
                }
            } else {
                notification.warning({
                    message: 'Fill All Required Fields'
                })
            }
        })
    }
    return (
        <>
            <Drawer width={'35%'} visible={visible} title={`Edit City - ${data.name}`} onClose={onClose}>
                <div className='card unizportal'>
                    <Form className={'vertical-form'} autoComplete='off' onSubmit={submitIndianCity}>
                        <Row gutter={24} className={'rowWrap pt-3'}>
                            {inputField.map((item, key) => {
                                return (
                                    <React.Fragment key={key}>
                                        <Col span={24} key={key}>
                                            <GetEachFormFields
                                                item={item}
                                                getFieldDecorator={getFieldDecorator}
                                                formItemLayout={formItemLayout}/>
                                        </Col>
                                    </React.Fragment>
                                )
                            })}
                            <Col span={24}>
                                <button className='btn mt10' type='submit'>Update
                                </button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Drawer>
        </>
    )
}
const WrappedEditIndianCity = Form.create()(EditIndianCity);
export default WrappedEditIndianCity
