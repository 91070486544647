import {notification} from 'antd'
import {getToken, customAxios as axios} from '../../../request'
import {addBankAccountUrl, listAccountDetailsUrl, addAccountSendOtpUrl} from '../api/bankAccount'
import {showPageLoad, hidePageLoad} from '../../../modules/actions'


export const addAccountSendOtpFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad());
    let {data} = await axios.post(addAccountSendOtpUrl(), valData, getToken());
    dispatch(hidePageLoad());
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error',
        })
    }
    return data;
}
export const addBankAccount = (valData) => async (dispatch) => {
    dispatch(showPageLoad());
    let {data} = await axios.post(addBankAccountUrl(), valData, getToken());
    dispatch(hidePageLoad());
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error',
        })
    }
    return data;
}
export const listAllAccount = (filters) => async (dispatch) => {
    dispatch(showPageLoad());
    let config = {
        params: {...filters},
        ...await getToken(),
    }
    let {data} = await axios.get(listAccountDetailsUrl(), config);
    dispatch(hidePageLoad());
    if (data.error) {
        notification.error({
            message: data.message || 'Error',
        })
    }
    return data;

}
