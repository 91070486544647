import React from 'react'

const SliderComponent = props => {
    let {accommodation} = props
    return (
        <>
            <div className="product-slider-box cds-2 clearfix mb-2">
                {accommodation && accommodation.length ?
                    <div className="product-img-slide">
                        <div className="slider-for2" style={{height: 580}}>
                            {accommodation.map((item, key) => {
                                return (
                                    <img key={key}
                                         src={item.image_path_related}
                                         style={{height: 580}}
                                         className="img-fluid w-100"
                                         alt="slider-photo"/>
                                )
                            })}
                        </div>
                        <div className="slider-nav2">
                            {accommodation.map((item, key) => {
                                return (
                                    <div className="thumb-slide" key={key}><img
                                        src={item.image_path_related}
                                        alt="small-photo"/></div>
                                )
                            })}
                        </div>
                    </div> : null}
            </div>


        </>
    )
}
export default SliderComponent
