import React, {useEffect, useRef, useState} from 'react'
import {
    Drawer,
    Select,
    Button,
    Tooltip,
    Avatar,
    Icon,
    Modal,
    Popconfirm,
    Row,
    Col, notification, Form, Tag
} from 'antd'
import {connect, useDispatch, useSelector} from 'react-redux'
import {studentSelectedApplications, exportApplicationsList} from '../actions/chooseStudent'
import {
    statusColors,
    filterOption,
    newFormatDisplayDate,
    defaultLogoList,
    longDisplayDate,
    fcmtUniversity,
    ukApplicationSteps,
    canadaApplicationSteps,
    australiaApplicationSteps,
    usaApplicationSteps, Intakes, ConditionOptions
} from '../../../components/_utils/appUtils'
import {getPushPathWrapper} from '../../../routes'
import {TableComp} from 'sz-react-utils-lite'
import SelectPendency from './selectPendency'
import lodash from 'lodash'
import {
    AllowComponentRightsWise,
    CheckBranchUserFxn,
    AllowComponentUserWise,
    CheckBranchUserRight,
    ShowEnrollmentComponent, CheckManagerWiseRight, CheckReOpenRight, CheckCaseCloseRight
} from '../../WebComponent/allowComponentRightsWise'
import TransferApplicationDrawer from '../views/transferApplication'
import {getPendingPendency} from '../../../components/_utils/utils'
import ApplyCourse from '../../student/views/applyForApplicationDrawer'
import {CheckUserRight} from '../../dashboard/views/DashboardUserWise'
import {
    withdrawalStudent,
    applicationCloseDirect,
    reCalculateCommission, updateApplicationTuitionFees
} from '../../student/actions/student'
import moment from 'moment'
import StudentOtherApp from './StudentOtherApplication'
import {listAllUniversities} from '../../university/actions/university'
import {listAllUsers} from '../../users/actions/user'
import ReActivateApplication from '../drawers/reActivateApplicationDrawer'
import ReActivateAppByAgent from '../drawers/reActivateAppByAgent'
import ChangeUniversityComponent from '../drawers/changeUniversity'
import {showEnrollmentAction} from '../../../components/_utils/appUtils'
import Enrollment from '../drawers/enrollment'
import AddNote from '../drawers/applicationAddNoteComponent'
import UpdateUniversityComponent from '../drawers/updateUniversity'
import {AgentBranchManagerComponent} from '../../../components/_utils/AgentBranchManagerComponent'
import {listAllCountries} from '../../countries/actions/countries'
import DraftButtonComponent from './draftButtonComponent'
import {ShowTuitionFeesCondition, AddTuitionFeeDrawer} from "../drawers/AddTuitionFeeDrawer";
import {OshcDrawer, ShowOshcDrawer, OshcFieldLabel} from "../OSHC/oshcDrawer";
import AddCommissionDrawer from "../../universityCommission/drawers/addCommission";

const managerViewRight = ['admin', 'branchUser']

const {Option} = Select
const initialState = {
    applicationList: [],
    applicationObj: {}
}
const commissionObj = {
    commissionAmount: 0,
    tuitionFee: 0,
    studentId: '',
    applicationId: ''
}
const RenderComment = (props) => {
    let {item} = props
    let [hideV, setHideV] = useState(true)
    return (
        item && <div>
            {item.addedByUserId && item.addedByUserId.name ?
                <div className={'alignRight colorPrimary font12'}>
                    (Added By : {item.addedByUserId.name} )
                </div> : null}
            <div>{longDisplayDate(item.time)}</div>

            <div className={hideV ? 'appCommentDotsReplace' : ''}>
                {item && item.text ? item.text : ''}
            </div>
            {item && item.text.length && item.text.length > 50 ? <div className={'alignRight'}>
                {hideV ? <a onClick={() => {
                    setHideV(false)
                }}>Show More</a> : <a onClick={() => {
                    setHideV(true)
                }}>Show Less</a>}
            </div> : ''}

        </div>
    )
}
const ViewStudentApplication = (props) => {
    let {visible, onClose, studentObj = {}, pageType = 'student', queryData, reloadTable, currentUser} = props
    const [state, setState] = useState(initialState)
    const [transferAppVisible, setTransferAppVisible] = useState(false)
    const [studentData, setStudentData] = useState({})
    const [visibleAddCourseDrawer, setVisibleAddCourseDrawer] = useState(false)
    const [addPendencyDrawer, setAddPendencyDrawer] = useState(false)
    const [itemNo, setItemNo] = useState(1)
    const [name, setName] = useState('')
    const [dateOfBirth, setDateOfBirth] = useState('')
    const [searchData, setSearchData] = useState(false)
    const [allAgent, setAllAgent] = useState([])
    const [agentId, setAgentId] = useState('')
    const [universityList, setUniversityList] = useState([])
    const [appUniversityId, setAppUniversityId] = useState('')
    const [visibleOtherAppDrawer, setVisibleOtherAppDrawer] = useState(false)
    const [visibleAddCommentDrawer, setVisibleAddCommentDrawer] = useState(false)
    const [totalApplication, setTotalApplication] = useState(0)
    const [visibleFeesModal, setVisibleFeesModal] = useState(false)
    const [transferToOtherDepartmentVisible, setTransferToOtherDepartmentVisible] = useState(false)
    const [branchManagerId, setBranchManagerId] = useState('')
    const [studentManagerId, setStudentManagerId] = useState('')
    const [feesObj, setFeesObj] = useState(commissionObj)
    let {applicationObj} = state
    let [countryList, setCountryList] = useState([])
    const [countryId, setCountryId] = useState('')
    let [statusList, setStatusList] = useState([])
    let [status, setStatus] = useState([])
    let viewOnlyRight = CheckBranchUserFxn() // check branch user right
    const [branchUserList, setBranchUserList] = useState([])
    const [branchUserId, setBranchUserId] = useState('')
    let [intake, setIntake] = useState('')
    let [draftApplication, setDraftApplication] = useState('')
    const [visibleTuitionFee, setVisibleTuitionFee] = useState(false)
    const [visibleOshcDrawer, setVisibleOshcDrawer] = useState(false)
    const [visibleUniversityCommission, setVisibleUniversityCommission] = useState(false)


    let [selectedApplication, setSelectedApplication] = useState({
        visible: false,
        studentId: '',
        application: '',
        studentData: {}
    })
    let [enrollmentObj, setEnrollmentObj] = useState({
        visible: false,
        studentId: '',
        application: '',
        studentData: {}
    })
    let [changeUniversity, setChangeUniversity] = useState({
        visible: false,
        studentId: '',
        application: '',
        studentData: {}
    })
    let [updateUniversity, setUpdateUniversity] = useState({
        visible: false,
        studentId: '',
        application: '',
        studentData: {}
    })
    let [branchManagerList, setBranchManagerList] = useState([])
    let [studentManagerList, setStudentManagerList] = useState([])

    let [agentApp, setAgentApp] = useState({
        visible: false,
        studentId: '',
        application: '',
        studentData: {}
    })

    const dispatch = useDispatch()
    let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null

    const tableAppRef = useRef()

    let apiRequest = (params) => {
        return new Promise(async (resolve) => {
            if (pageType == 'student') {
                params['studentId'] = studentObj._id;

                let {
                    appAgentId,
                    branchManagerId,
                    studentManagerId,
                    appUniversityId,
                    countryId,
                    branchUserId,
                    intake,
                    appStatus
                } = studentObj;
                if (appAgentId) {
                    params.agentId = appAgentId
                }
                if (branchManagerId) {
                    params.branchManagerId = branchManagerId
                }
                if (studentManagerId) {
                    params.studentManagerId = studentManagerId
                }
                if (appUniversityId) {
                    params.appUniversityId = appUniversityId
                }
                if (countryId) {
                    params.countryId = countryId
                }
                if (branchUserId) {
                    params.branchUserId = branchUserId
                }
                if (intake) {
                    params.intake = intake
                }
                if (appStatus) {
                    params.status = appStatus
                }

            } else {
                params = {...params, ...queryData}
            }
            if (name) {
                params.name = name
            }
            if (dateOfBirth) {
                params.dateOfBirth = moment(dateOfBirth)
            }

            if (agentId) {
                params.agentId = agentId
            }
            if (branchManagerId) {
                params.branchManagerId = branchManagerId
            }
            if (studentManagerId) {
                params.studentManagerId = studentManagerId
            }

            if (appUniversityId) {
                params.appUniversityId = appUniversityId
            }
            if (countryId) {
                params.countryId = countryId
            }
            if (branchUserId) {
                params.branchUserId = branchUserId
            }
            if (intake) {
                params.intake = intake
            }
            if (draftApplication) {
                params.draftApplication = draftApplication
            }
            let page = params.page

            // console.log(params)
            if (searchData) {
                setSearchData(false)
                if (name || dateOfBirth || agentId || appUniversityId || countryId || branchUserId || intake || draftApplication) {
                    page = 1
                }
            } else {
            }
            // console.log(params)
            try {
                let resp = await dispatch(studentSelectedApplications({...params, regExFilters: ['name'], page: page}))
                setTotalApplication(resp.total)
                // console.log(resp)
                resolve(resp)
            } catch (e) {
                // console.log(e)
            }

        })
    }
    let exportExcel = async () => {
        let params = {}
        if (pageType == 'student') {
            params['studentId'] = studentObj._id
        } else {
            params = {...params, ...queryData}
        }

        if (name) {
            params.name = name
        }
        if (dateOfBirth) {
            params.dateOfBirth = moment(dateOfBirth)
        }

        if (agentId) {
            params.agentId = agentId
        }
        if (branchManagerId) {
            params.branchManagerId = branchManagerId
        }

        if (studentManagerId) {
            params.studentManagerId = studentManagerId
        }

        if (appUniversityId) {
            params.appUniversityId = appUniversityId
        }
        params.results = totalApplication
        let {success, filePath, message} = await dispatch(exportApplicationsList(params))
        if (success) {
            notification.success({message: message})
            // window.open(`${apiUrl}${filePath}`, 'Download')
        } else {
            notification.error({message: message})
        }
    }


    useEffect(() => {
        loadBranchManager()
        loadStudentManager()
        loadCountryList()
        if (queryData && queryData.openFrom !== 'university') {
            loadUniversityNameList()
        }
        if (queryData && queryData.openFrom !== 'agent' && currentUser.userType !== 'agent' && currentUser.userType !== 'subAgent') {
            loadAgentData()
        }
        if (queryData && queryData.openFrom !== 'branchUser' && currentUser.userType !== 'branchUser') {
            loadCurrentUserData()
        }
    }, [])


    const loadAgentData = async () => {
        let params = {
            results: 15000,
            userType: 'agent',
            sortField: 'companyName',
            sortOrder: 'ascend',
            select: ['name', 'companyName']
        }
        let {data} = await dispatch(listAllUsers(params))
        if (data && data.length) {
            setAllAgent(data)
        }
    }
    const loadUniversityNameList = async () => {
        let params = {
            results: 5000,
            select: ['universityName'],
            sortField: 'universityName',
            sortOrder: 'ascend',
            showUniversity: [true, false]
        }
        if (queryData.countryId) {
            params.universityCountry = queryData.countryId
        }
        let {data} = await dispatch(listAllUniversities(params))
        if (data && data.length) {
            setUniversityList(data)
        }
    }
    const loadCurrentUserData = async () => {
        let params = {
            results: 1000,
            userType: 'branchUser',
            sortField: 'name',
            sortOrder: 'ascend',
            select: ['name', 'userType', 'mobile', 'email']
        }
        let {data} = await dispatch(listAllUsers(params))
        if (data && data.length) {
            setBranchUserList(data)
        }
    }
    const events = {
        openAddPendencyDrawer: (value) => {
            setState({
                ...state,
                applicationObj: value
            })
            setAddPendencyDrawer(true)
        },
        closeAddPendencyDrawer: () => {
            setState({
                ...state,
                applicationObj: {}
            })
            setAddPendencyDrawer(false)
        },
        reloadFxn: () => {

            tableAppRef.current.reload()
        },
        reloadAndClose: () => {
            tableAppRef.current.reload()
            setAddPendencyDrawer(false)
        },
        showTransferApp: () => {
            setTransferAppVisible(true)
        },
        hideTransferApp: () => {
            setTransferAppVisible(false)
            tableAppRef.current.reload()
        },
        showAddCourseDrawer: () => {
            setVisibleAddCourseDrawer(true)
        },
        hideAddCourseDrawer: () => {
            setVisibleAddCourseDrawer(false)
            tableAppRef.current.reload()
        },
        refreshApplication: () => {
            tableAppRef.current.reload()
        },
        withdrawalStudent: () => {
            Modal.confirm({
                title: `Are you sure, you want to withdrawal student?`,
                content: (
                    <div style={{textAlign: 'center'}}>

                    </div>
                ),
                onOk: async () => {
                    let resp = await dispatch(withdrawalStudent({studentId: studentObj._id}))
                    reloadTable()
                    onClose()
                },
                onCancel: () => {

                },
                okText: 'Yes',
                cancelText: 'No'
            })
        },
        closeDirectApp: async (record) => {
            let obj = {
                studentId: record._id,
                applicationId: record.applications._id
            }
            let resp = await dispatch(applicationCloseDirect(obj))
            tableAppRef.current.reload()
        },
        chooseDate: (date, stringDate) => {
            setDateOfBirth(date)
            /*if(!date){
              setTimeout(()=>{
                tableAppRef.current.reload()
              },500)
            }*/
        },
        searchData: () => {
            // apiRequest()
            setSearchData(true)
            setTimeout(() => {
                tableAppRef.current.reload()
            }, 300)

        },
        clearData: () => {
            // apiRequest()
            setSearchData(true)
            setAgentId('')
            setBranchManagerId('')
            setBranchUserId('')
            setStudentManagerId('')
            setAppUniversityId('')
            setIntake('')
            setName('')
            setDraftApplication('')

            setTimeout(() => {
                tableAppRef.current.reload()
            }, 300)

        },
        enterName: (value) => {
            setName(value)
            /*if(!value){
              setTimeout(()=>{
                tableAppRef.current.reload()
              },500)
            }*/
        },
        openOtherAppDrawer: (value) => {
            setState({
                ...state,
                applicationObj: value
            })
            setVisibleOtherAppDrawer(true)
        },
        closeOtherAppDrawer: () => {
            setState({
                ...state,
                applicationObj: {}
            })
            setVisibleOtherAppDrawer(false)
        },
        openAddCommentDrawer: (value) => {
            setState({
                ...state,
                applicationObj: value
            })
            setVisibleAddCommentDrawer(true)
        },
        closeAddCommentDrawer: () => {
            setState({
                ...state,
                applicationObj: {}
            })
            setVisibleAddCommentDrawer(false)
        },
        hideVisibleFeesModal: () => {
            setVisibleFeesModal(false)
        },
        setCommissionLocal: (data) => {
            setFeesObj({
                ...feesObj,
                ...data
            })
        },
        reCalculateCommissionFxn: async () => {
            let {commissionAmount} = await dispatch(reCalculateCommission(feesObj))
            if (commissionAmount) {
                events.setCommissionLocal({commissionAmount})
            }

        },
        updateApplicationTuitionFeesFxn: async () => {
            let {success, message} = await dispatch(updateApplicationTuitionFees(feesObj))
            if (success) {
                setFeesObj(commissionObj)
                setVisibleFeesModal(false)
                events.reloadFxn()
            }
        },
        showTransferToOtherDepartment: () => {
            setTransferToOtherDepartmentVisible(true)
        },
        hideTransferToOtherDepartment: () => {
            setTransferToOtherDepartmentVisible(false)
            tableAppRef.current.reload()
        },
        showTuitionFeeDrawer: () => {
            setVisibleTuitionFee(true)
        },
        hideTuitionFeeDrawer: () => {
            setVisibleTuitionFee(false)
            tableAppRef.current.reload()
        },
        showOshcDrawer: () => {
            setVisibleOshcDrawer(true)
        },
        hideOshcDrawer: () => {
            setVisibleOshcDrawer(false)
            tableAppRef.current.reload()
        },

        showUniversityCommission: () => {
            setVisibleUniversityCommission(true)
        },
        hideUniversityCommission: () => {
            setVisibleUniversityCommission(false)
            tableAppRef.current.reload()
        },
    }
    const loadBranchManager = async () => {
        let obj = {
            userType: 'branchManager',
            customQuery: {
                'agents.0': {$exists: true}
            },
            results: 100,
            select: ['name', 'email', 'mobile', 'agents', 'countryId']
        }
        let {data} = await dispatch(listAllUsers(obj))
        setBranchManagerList(data)
    }
    const loadStudentManager = async () => {
        let obj = {
            userType: 'branchManager',
            branchManagerType: 'studentWise',
            results: 100,
            select: ['name', 'email', 'mobile', 'agents', 'countryId']
        }
        let {data} = await dispatch(listAllUsers(obj))
        setStudentManagerList(data)
    }

    const reActivateApp = (record) => {
        setSelectedApplication({
            visible: true,
            studentId: record._id,
            studentData: record,
            application: record.applications
        })
    }
    const onCloseReActivate = () => {
        setSelectedApplication({
            visible: false,
            studentId: '',
            studentData: {},
            application: {}
        })
        tableAppRef.current.reload()
    }

    const agentReactiveAppEvents = {
        openDrawer: (record) => {
            setAgentApp({
                visible: true,
                studentId: record._id,
                studentData: record,
                application: record.applications
            })
        },
        hideDrawer: () => {
            setAgentApp({
                visible: false,
                studentId: '',
                studentData: {},
                application: {}
            })
            tableAppRef.current.reload()
        }
    }

    const enrollmentApp = (record) => {
        setEnrollmentObj({
            visible: true,
            studentId: record._id,
            studentData: record,
            application: record.applications
        })
    }
    const onCloseEnrollmentApp = () => {
        setEnrollmentObj({
            visible: false,
            studentId: '',
            studentData: {},
            application: {}
        })
        tableAppRef.current.reload()
    }

    const changeUniversityFxn = (record) => {
        setChangeUniversity({
            visible: true,
            studentId: record._id,
            studentData: record,
            application: record.applications
        })
    }
    const onCloseChangeUniversityFxn = () => {
        setChangeUniversity({
            visible: false,
            studentId: '',
            studentData: {},
            application: {}
        })
        tableAppRef.current.reload()
    }

    const enrollmentBtn = (record) => {
        let application = record.applications;
        return (
            !application.enrollment ? showEnrollmentAction(application.statusList) ?
                <button className='btn' onClick={() => enrollmentApp(record)}>
                    <img src={'/assets/enrollment.png'}/>
                </button> : null : null
        )
    }


    const updateUniversityEvent = {
        openDrawer: (record) => {
            setUpdateUniversity({
                visible: true,
                studentId: record._id,
                studentData: record,
                application: record.applications
            })
        },
        closeDrawer: () => {
            setUpdateUniversity({
                visible: false,
                studentId: '',
                studentData: {},
                application: {}
            })
            tableAppRef.current.reload()
        }
    }

    const showEnrollmentBtn = (record) => {
         let application = record.applications
         return (showEnrollmentAction(application.statusList) ?
             true : false)
    }


    const columns = [
        {
            title: 'App#',
            key: 'applications.id',
            dataIndex: 'applications.id',
            searchTextName: 'applications.id',
            width: 60,
            render: (item) => {
                return (
                    <div style={{width: 60}}>
                        {item}
                    </div>
                )
            }
        },
        {
            title: 'Name',
            key: 'name',
            dataIndex: 'name',
            hidden: pageType == 'student',
            render: (item, record) => {
                return (
                    viewOnlyRight ? <Tooltip title={'Edit Student'}>
                        <a className={'linkAA'}
                           onClick={() => dispatch(
                               getPushPathWrapper('student.editStudent', {id: record._id}))
                           }>
                            {item} <Icon type={'edit'}/>
                        </a>
                    </Tooltip> : item
                )
            }
        },
        {
            title: 'DOB',
            dataIndex: 'dateOfBirth',
            key: 'dateOfBirth',
            hidden: pageType == 'student',
            width: 90,
            render: (val) => {
                return (
                    <div style={{width: 90}}>
                        {newFormatDisplayDate(val)}
                    </div>
                )
            }
        },
        {
            title: 'Status',
            key: 'applications.status',
            dataIndex: 'applications.status',
            width: 80,
            render: (item, record) => {
                return (
                    <React.Fragment>
                        <div className={'label label1'}
                             style={{background: statusColors(item), width: 80, padding: 3}}>{item}</div>
                    </React.Fragment>
                )
            }
        },
        {
            title: 'Draft',
            key: 'applications.draftApplication',
            dataIndex: 'applications.draftApplication',
            render: (item, record) => {
                return (
                    item ? <Tag color='#f50'>Draft</Tag> : null
                )
            }
        },
        {
            title: 'University',
            key: 'applications.courseUniversity',
            dataIndex: 'applications.courseUniversity',
            render: (item, record) => {
                return (
                    <React.Fragment>
                        {item && item.smallLogo && item.smallLogo.url ?
                            <Avatar src={item.smallLogo.url} size={20}/>
                            : <Avatar src={defaultLogoList.defaultUniversityLogo} size={20}/>}
                        {item.universityName ? `  ${item.universityName}` : ''}
                    </React.Fragment>
                )
            }
        },
        {
            title: 'Country',
            key: 'applications.universityCountry',
            dataIndex: 'applications.universityCountry',
            render: (item, record) => {
                let {applications} = record;
                return (
                    <React.Fragment>
                        {item && item.countryName ? item.countryName : null}, {' '}
                        {record && record.stateName ? record.stateName : null}
                        <OshcFieldLabel countryId={item._id} applications={applications}/>
                    </React.Fragment>
                )
            }
        },
        {
            title: 'Program',
            key: 'applications.courseName',
            dataIndex: 'applications.courseName',
            width: 150,
            render: (item, record) => {
                return (
                    <div style={{width: 150}}>
                        {item}
                    </div>
                )
            }
        },
        {
            title: 'Intake',
            key: 'applications.intake',
            dataIndex: 'applications.intake',
            render: (item, record) => {
                return (
                    <React.Fragment>
                        {item && item.month && item.year ? `${item.month}, ${item.year}` : ''}
                    </React.Fragment>
                )
            }
        },


        {
            title: 'Pendency',
            key: 'applications.pendencyArr',
            dataIndex: 'applications.pendencyArr',
            width: 100,
            render: (item, record) => {
                return (
                    <React.Fragment>
                        <Tooltip title={'Add Pendency'}>
              <span onClick={() => viewOnlyRight ? events.openAddPendencyDrawer(record) : ''}
                    className='badge'>{item && item.length ? getPendingPendency(item) : 0}</span>

                        </Tooltip>{' '}


                    </React.Fragment>
                )
            }
        },

        {
            title: 'Verification',
            key: 'applications.verificationCertificate',
            hidden: (user.userType == 'agent' || user.userType == 'subAgent'),
            dataIndex: 'applications.verificationCertificate',
            width: 150,
            render: (item, record) => {
                return (
                    <React.Fragment>
                        {item ? <label className={'label label-warning label-sm'}>{item}</label> : ""}
                    </React.Fragment>
                )
            }
        },
        {
            title: 'Current User',
            key: 'applications.assignedUserId',
            dataIndex: 'applications.assignedUserId',
            width: 150,
            render: (val, record) => {
                let {agentId, statusList, status, addByUserId} = record.applications
                return (
                    <React.Fragment>
                        {val && val.name ? val.name : ''}<br/>
                        <div>Agent : {agentId && agentId.companyName ? agentId.companyName : ''}</div>
                        {addByUserId && addByUserId.name ?
                            <div className={'mt5 colorPrimary font12'}>(Added By
                                : {addByUserId && addByUserId.name ? addByUserId.name : ''})</div> : ''}

                        <CheckUserRight user={user} rightUserType={['admin', 'branchManager', 'branchUser']}>
                            {agentId && agentId.mobile ? <div>Mob : {agentId.mobile}</div> : ''}
                        </CheckUserRight>
                        <div>{longDisplayDate(statusList.date)}</div>
                    </React.Fragment>
                )
            }
        },
        {
            title: 'Student Manager',
            dataIndex: 'branchManagerId',
            hidden: !managerViewRight.includes(user.userType),
            width: 125,
            key: 'branchManagerId',
            render: (item, record) => {
                // console.log(record)
                let {branchManagerId} = record
                return (
                    <React.Fragment>
                        {branchManagerId ? branchManagerId.name : null}
                    </React.Fragment>
                )
            }
        },
        {
            title: 'Managers',
            dataIndex: 'managers',
            hidden: !managerViewRight.includes(user.userType),
            width: 120,
            key: 'managers',
            render: (item, record) => {
                let {agentId, universityCountry} = record.applications
                return (
                    <React.Fragment>
                        {branchManagerList && branchManagerList.length ?
                            <AgentBranchManagerComponent
                                userId={agentId._id}
                                countryId={universityCountry._id}
                                branchManagers={branchManagerList}
                            /> : null}
                    </React.Fragment>
                )
            }
        },
        {
            title: 'Latest Note',
            key: 'applications.latestComment',
            dataIndex: 'applications.latestComment',
            width: 160,
            render: (val) => {
                return (
                    <div style={{width: 160}}>
                        {val && val.text ? <RenderComment item={val}/> : null}
                    </div>
                )
            }
        },
        {
            title: 'Action',
            key: 'action',
            dataIndex: 'action',
            width: 100,
            render: (val, record) => {
                let {applications} = record
                return (
                    <div className={'actionBtnGroup'}>
                        {!record.applications.directClosed &&

                        <AllowComponentRightsWise rightUserType={['agent', 'subAgent']}>
                            <Tooltip title={'View Application'}>
                                <a className='btn'
                                   href={`/application/view?appId=${record.applications._id}&studentId=${record._id}`}
                                ><img src='/dist/img/view.svg' alt='' className={'eyeBtn'}/></a>

                            </Tooltip>{' '}
                        </AllowComponentRightsWise>}


                        <div>
                            <AllowComponentRightsWise rightUserType={['admin', 'branchUser', 'branchManager']}>
                                <Tooltip title={'View Application'}>
                                    <a className='btn'
                                       href={`/student/application?studentId=${record._id}&appId=${record.applications._id}`}
                                    ><img src='/dist/img/view.svg' alt=''/></a>
                                </Tooltip>

                                <DraftButtonComponent record={record} callback={() => tableAppRef.current.reload()}/>
                                <ShowOshcDrawer record={record} callback={() => tableAppRef.current.reload()}>
                                    <Tooltip title={'OSHC'}>
                                        <button className='btn'
                                                onClick={() => {
                                                    events.showOshcDrawer()
                                                    setStudentData(record)
                                                }}><img src='../dist/img/healthcare_dark.png' alt=''/></button>
                                    </Tooltip>
                                </ShowOshcDrawer>


                                {/*<ShowTuitionFeesCondition applications={applications}>
                                    <Tooltip title={'Update Tuition Fees'}>
                                        <button className='btn' onClick={() => {
                                            events.showTuitionFeeDrawer()
                                            setStudentData(record)
                                        }}>
                                            <img src={'../dist/img/tuition.png'}/>
                                        </button>
                                    </Tooltip>
                                </ShowTuitionFeesCondition>*/}
                            </AllowComponentRightsWise>

                            <CheckCaseCloseRight>
                                {record.applications.status !== 'Case Close' &&
                                <Tooltip title={'Case Closed'}>
                                    <Popconfirm title={'Are your sure, you want to Close Case?'}
                                                onConfirm={() => {
                                                    events.closeDirectApp(record)
                                                }}
                                                okText='Yes' cancelText='No'>
                                        <button className='btn'><img src='dist/img/cross.svg' alt=''
                                                                     className={'crossBtn'}/></button>
                                    </Popconfirm>

                                </Tooltip>}
                            </CheckCaseCloseRight>

                            <AllowComponentRightsWise rightUserType={['admin', 'branchUser', 'branchManager']}>
                                <Tooltip title={'View Other Application'}>
                                    <button className='btn' onClick={() => {
                                        events.openOtherAppDrawer(record)
                                    }}><Icon type={'ordered-list'}/></button>

                                </Tooltip>
                                <Tooltip title={'Add Note'}>
                                    <button className='btn' onClick={() => {
                                        events.openAddCommentDrawer(record)
                                    }}><Icon type={'plus'}/></button>
                                </Tooltip>
                            </AllowComponentRightsWise>

                        </div>

                        <AllowComponentUserWise rightUserType={['admin', 'branchManager']}>
                            {
                                record.applications.status == 'Case Close' ?
                                    <Tooltip title={'Re-Open Case'}>
                                        <button className='btn' onClick={() => reActivateApp(record)}>
                                            <img src={'/assets/power-button.png'}/>
                                        </button>
                                    </Tooltip> : null
                            }
                        </AllowComponentUserWise>

                        <CheckReOpenRight applications={applications}>
                            {
                                record.applications.status == 'Case Close' ?
                                    <Tooltip title={'Re-Open Case'}>
                                        <button className='btn'
                                                onClick={() => agentReactiveAppEvents.openDrawer(record)}>
                                            <img src={'/assets/power.png'} style={{height: 20}}/>
                                        </button>
                                    </Tooltip> : null
                            }
                        </CheckReOpenRight>

                        <AllowComponentUserWise rightUserType={['admin', 'branchManager']}>
                            {
                                record.applications.status !== 'Case Close' ?
                                    <Tooltip title={'Change University'}>
                                        <button className='btn' onClick={() => changeUniversityFxn(record)}>
                                            <img src={'/assets/shuffle-arrows.png'}/>
                                        </button>
                                    </Tooltip> : null
                            }
                        </AllowComponentUserWise>

                        {pageType == 'student' && applications.courseUniversity &&
                        applications.courseUniversity._id && applications.courseUniversity._id.toString() !== fcmtUniversity ?
                            <>

                                <AllowComponentUserWise rightUserType={['admin', 'branchUser', 'branchManager']}>
                                    <Tooltip title={'Transfer'}>
                                        <button className='btn' onClick={() => {
                                            setStudentData(record)
                                            events.showTransferApp()
                                        }}>
                                            <img src={'../dist/icons/forwardArrow.png'}/>
                                        </button>
                                    </Tooltip>{' '}
                                </AllowComponentUserWise>

                                {/* <ShowEnrollmentComponent rightUserType={['admin', 'branchUser']}>
                                    {enrollmentBtn(record)}
                                </ShowEnrollmentComponent>*/}

                                <ShowEnrollmentComponent rightUserType={['admin', 'branchUser', 'userManager']}>
                                    {showEnrollmentBtn(record) ? <Tooltip title={'Create Commission'}>
                                        <button className='btn' onClick={() => {
                                            setStudentData(record)
                                            events.showUniversityCommission()
                                        }}>
                                            <img src={'../btnImgs/percentage.png'}/>
                                        </button>
                                    </Tooltip> : null}
                                </ShowEnrollmentComponent>


                            </>
                            : ''}
                    </div>
                )
            }
        }

    ]

    const loadCountryList = async () => {
        let params = {
            results: 5000,
            select: ['countryName'],
            sortField: 'countryName',
            sortOrder: 'ascend'
        }
        let {data} = await dispatch(listAllCountries(params))
        if (data && data.length) {
            setCountryList(data)
            // console.log('google.com', data)
        }
    }

    useEffect(() => {
        loadCountryStatus()
    }, [countryId, countryList])


    const loadCountryStatus = async () => {
        let findCountry = lodash.find(countryList, (item) => {
            return item._id == countryId
        })
        if (findCountry) {
            if (findCountry.countryName.toLowerCase() == 'uk') {
                let temp = lodash.filter(ukApplicationSteps, (item) => {
                    return item.name !== 'Case Close' && item.name !== 'New Student'
                })
                setStatusList(temp)
            }
            if (findCountry.countryName.toLowerCase() == 'canada') {
                let temp = lodash.filter(canadaApplicationSteps, (item) => {
                    return item.name !== 'Case Close' && item.name !== 'New Student'
                })
                setStatusList(temp)
            }
            if (findCountry.countryName.toLowerCase() == 'australia') {
                let temp = lodash.filter(australiaApplicationSteps, (item) => {
                    return item.name !== 'Case Close' && item.name !== 'New Student'
                })
                setStatusList(temp)
            }
            if (findCountry.countryName.toLowerCase() == 'usa') {
                let temp = lodash.filter(usaApplicationSteps, (item) => {
                    return item.name !== 'Case Close' && item.name !== 'New Student'
                })
                setStatusList(temp)
            }
        } else {
            setStatusList([])
        }
    }


    const resp = useSelector(state => {
        let {loadNoteRedux} = state.chooseApplication
        if (loadNoteRedux) {
            if (tableAppRef && tableAppRef.current) {
                tableAppRef.current.reload()
            }
            dispatch({type: 'END_LOAD_NOTE'})
        }
    })


    const loadColumns = () => {
        let columnArr = []
        lodash.each(columns, (item) => {
            if (item.hidden == undefined) {
                columnArr.push(item)
            } else {
                if (!item.hidden) {
                    columnArr.push(item)
                }
            }
        })
        return columnArr
    }

    const extra = (
        <Row gutter={16}>

            {pageType == 'student' ?
                <Col span={pageType == 'student' ? 20 : 0}>
                    <AllowComponentUserWise user={user} rightUserType={['admin', 'branchManager', 'branchUser']}>
                        <Tooltip title={'Withdrawal Student'}>
                            <Button style={{marginRight: 10}} type={'danger'} icon={'cross'}
                                    onClick={() => events.withdrawalStudent()}>
                                Withdrawal Student
                            </Button>
                        </Tooltip>
                    </AllowComponentUserWise>
                    <CheckBranchUserRight>
                        <Tooltip title={'Add New Application'}>
                            <Button style={{marginRight: 10}} icon={'plus'}
                                    onClick={() => events.showAddCourseDrawer()}>
                                Add New Application
                            </Button>
                        </Tooltip>
                    </CheckBranchUserRight>
                </Col> : ''}

            {pageType == 'student' ?
                <AllowComponentUserWise rightUserType={['admin', 'branchManager', 'branchUser', 'agent']}><Tooltip
                    title={'Edit Student'}>
                    <Button style={{marginRight: 10}} icon={'edit'}
                            onClick={() => dispatch(
                                getPushPathWrapper('student.editStudent', {id: studentObj._id}))
                            }>
                        Edit Student
                    </Button>
                </Tooltip></AllowComponentUserWise> : null}


            {pageType !== 'student' ? <Col span={pageType !== 'student' ? 24 : 20}>
                <Row gutter={12}>
                    <CheckUserRight user={user} rightUserType={['admin', 'branchManager', 'branchUser']}>
                        {queryData.openFrom !== 'branchUser' && currentUser.userType !== 'branchUser' &&
                        <Col md={4} sm={4} xs={8} lg={4}>
                            <div className='sort-box-table custom-sort-box-new'>
                                <Select name='Current User' onChange={(item) => setBranchUserId(item)}
                                        filterOption={filterOption}
                                        className={'antSelect'}
                                        allowClear={true}
                                        showSearch={true}
                                        placeholder={'Current User'}
                                        value={branchUserId || undefined}>
                                    {branchUserList && branchUserList.length ? branchUserList.map((item, key) => {
                                        return (
                                            <Option value={item._id} key={key}>{item.name}</Option>
                                        )
                                    }) : null}
                                </Select>
                            </div>
                        </Col>}
                    </CheckUserRight>

                    <Col md={4} sm={4} xs={8} lg={4}>
                        <div className='sort-box-table custom-sort-box-new'>
                            <Select name='Search By Country'
                                    onChange={(item) => {
                                        setCountryId(item)
                                        setStatus('')
                                    }}
                                    filterOption={filterOption}
                                    className={'antSelect'}
                                    placeholder='Country'
                                    allowClear={true}
                                    showSearch={true}
                                    value={countryId || undefined}>
                                {countryList && countryList.length ? countryList.map((item, key) => {
                                    return (
                                        <Option value={item._id} key={key}>{item.countryName}</Option>
                                    )
                                }) : null}
                            </Select>
                        </div>
                    </Col>
                    {user.branchManagerType === 'marketingManager' ?
                        <>
                            <Col md={4} sm={4} xs={8} lg={4}>
                                <div className='sort-box-table custom-sort-box-new'>
                                    <Select name='Status' onChange={(item) => setStatus(item)}
                                            filterOption={filterOption}
                                            className={'antSelect'}
                                            placeholder={'Status'}
                                            allowClear={true}
                                            showSearch={true}
                                            value={status || undefined}>
                                        {statusList && statusList.length ? statusList.map((item, key) => {
                                            return (
                                                <Option value={item.name} key={key}>{item.name}</Option>
                                            )
                                        }) : null}
                                    </Select>
                                </div>
                            </Col>
                        </>
                        : null}
                    {queryData && queryData.openFrom !== 'university' &&
                    <Col md={4} sm={4} xs={8} lg={4}>
                        <div className='sort-box-table custom-sort-box-new'>
                            <Select name='Search By University' onChange={(item) => setAppUniversityId(item)}
                                    filterOption={filterOption}
                                    className={'antSelect'}
                                    allowClear={true}
                                    showSearch={true}
                                    placeholder={'Search By University'}
                                    value={appUniversityId || undefined}>
                                {/*<Option value={''}>Search By University</Option>*/}
                                {universityList && universityList.length ? universityList.map((item, key) => {
                                    return (
                                        <Option value={item._id} key={key}>{item.universityName}</Option>
                                    )
                                }) : null}
                            </Select>
                        </div>
                    </Col>}
                    <Col md={4} sm={4} xs={8} lg={4}>
                        <div className='sort-box-table custom-sort-box-new'>
                            <Select name='Intake' onChange={(item) => setIntake(item)}
                                    filterOption={filterOption}
                                    className={'antSelect'}
                                    allowClear={true}
                                    showSearch={true}
                                    placeholder={'Intake'}
                                    value={intake || undefined}>
                                {Intakes && Intakes.length ? Intakes.map((item, key) => {
                                    return (
                                        <Option value={item} key={key}>{item}</Option>
                                    )
                                }) : null}
                            </Select>
                        </div>
                    </Col>

                    {user.branchManagerType === 'marketingManager' ? null :
                        <CheckUserRight user={user} rightUserType={['admin', 'branchManager', 'branchUser']}>
                            {queryData.openFrom !== 'agent' && currentUser.userType !== 'agent' && currentUser.userType !== 'subAgent' &&
                            <Col md={4} sm={4} xs={8} lg={4}>
                                <div className='sort-box-table custom-sort-box-new'>
                                    <Select name='Search By Agent' onChange={(item) => setAgentId(item)}
                                            value={agentId || undefined}
                                            filterOption={filterOption}
                                            className={'antSelect'}
                                            allowClear={true}
                                            placeholder={'Search By Agent'}
                                            showSearch={true}>
                                        {/*<Option value={''}>Search By Agent</Option>*/}
                                        {allAgent && allAgent.length ? allAgent.map((item, key) => {
                                            return (
                                                <Option value={item._id} key={key}>{item.companyName}</Option>
                                            )
                                        }) : null}
                                    </Select>
                                </div>
                            </Col>}
                        </CheckUserRight>}
                    <CheckUserRight user={user} rightUserType={['admin', 'branchUser']}>
                        <Col md={4} sm={4} xs={8} lg={4}>
                            <div className='sort-box-table custom-sort-box-new'>
                                <Select name='Search By Branch Manager'
                                        filterOption={filterOption}
                                        className={'antSelect'}
                                        allowClear={true}
                                        placeholder={'Search by Branch Manager'}
                                        showSearch={true}
                                        onChange={(item) => setBranchManagerId(item)}
                                        value={branchManagerId || undefined}>
                                    {branchManagerList && branchManagerList.length ? branchManagerList.map((item, key) => {
                                        return (
                                            <Option value={item._id} key={key}>{item.name}</Option>
                                        )
                                    }) : null}
                                </Select>
                            </div>
                        </Col>
                    </CheckUserRight>
                    <CheckUserRight user={user} rightUserType={['admin', 'branchUser']}>
                        <Col md={4} sm={4} xs={8} lg={4}>
                            <div className='sort-box-table custom-sort-box-new'>
                                <Select name='Search By Student Manager'
                                        filterOption={filterOption}
                                        className={'antSelect'}
                                        allowClear={true}
                                        placeholder={'Search by Student Manager'}
                                        showSearch={true}
                                        onChange={(item) => setStudentManagerId(item)}
                                        value={studentManagerId || undefined}>
                                    {studentManagerList && studentManagerList.length ? studentManagerList.map((item, key) => {
                                        return (
                                            <Option value={item._id} key={key}>{item.name}</Option>
                                        )
                                    }) : null}
                                </Select>
                            </div>
                        </Col>
                    </CheckUserRight>

                    <Col md={4} sm={4} xs={8} lg={4}>
                        <div className='sort-box-table custom-sort-box-new'>
                            <Select name='Search By Draft'
                                    filterOption={filterOption}
                                    className={'antSelect'}
                                    allowClear={true}
                                    placeholder={'Draft Application'}
                                    onChange={(item) => setDraftApplication(item)}
                                    value={draftApplication || undefined}>
                                {ConditionOptions && ConditionOptions.length ? ConditionOptions.map((item, key) => {
                                    return (
                                        <Option value={item.name} key={key}>{item.name}</Option>
                                    )
                                }) : null}
                            </Select>
                        </div>
                    </Col>
                    <Col md={4} sm={4} xs={8} lg={4}>
                        <div className='search-box-table round ml0'>
                            <input className='form-control form-control' type='search'
                                   placeholder='Search by name...' value={name} onChange={(e) => {
                                events.enterName(e.target.value)
                            }}/>
                            <img src='/dist/img/search.png' alt=''/>
                        </div>

                    </Col>
                    <Col md={8} sm={8} xs={8} lg={8}>
                        <Button onClick={() => events.searchData()} className={'roundBtn'}>Search</Button>
                        <Button onClick={() => events.clearData()} className={'roundBtn'}>Clear</Button>

                        <CheckUserRight user={user} rightUserType={['admin', 'branchManager']}>
              <span className={'sort-box-table-right'}>
                <Tooltip title={'Export Excel'}>
                  {' '} <Button onClick={() => exportExcel()} className={'roundBtn'}>Export Excel</Button>
                </Tooltip>
              </span>
                        </CheckUserRight>
                    </Col>

                </Row>
            </Col> : null}
            {pageType == 'student' ?
                <AllowComponentUserWise user={user} rightUserType={['admin', 'branchManager']}>
                    <Col span={4}>
                        <Tooltip title={'Export Excel'}>
                            <Button onClick={() => exportExcel()} className={'roundBtn'}>Export Excel</Button>
                        </Tooltip>
                    </Col>
                </AllowComponentUserWise> : ''}
        </Row>
    )

    return (
        <React.Fragment>

            <Drawer visible={visible}
                    width={'95%'}
                    closable={true}
                    maskClosable={true}
                    onClose={onClose}>

                <div className='col-lg-12 pd-0'>
                    <div className='card'>
                        <div className='table-head d-flex align-items-center'>
                            <h5>{studentObj.name ? studentObj.name : 'All'} Applications ({totalApplication})</h5>


                            <div className='sort-box-table'>

                            </div>
                        </div>
                        <div className='card-body table-responsive top_P10'>
                            {extra}


                            <TableComp columns={loadColumns()}
                                       rowKey={(item, index) => {
                                           return index
                                       }}
                                       apiRequest={apiRequest}
                                       pagination={{
                                           showSizeChanger: true,
                                           defaultPageSize: 100,
                                           pageSizeOptions: ['50', '100', '1000']
                                       }}
                                       ref={tableAppRef}
                            />

                        </div>
                    </div>
                </div>


                {addPendencyDrawer ?
                    <SelectPendency visible={addPendencyDrawer}
                                    applicationObj={state.applicationObj}
                                    onClose={events.reloadAndClose}
                                    onAddPendencyClose={events.closeAddPendencyDrawer}
                                    reloadFxn={events.reloadFxn}
                    /> : null}
            </Drawer>

            {transferAppVisible ?
                <TransferApplicationDrawer visible={transferAppVisible}
                                           closeTransferDrawer={() => events.hideTransferApp()}
                                           studentId={studentData._id}
                                           studentData={studentData}
                                           application={studentData.applications}
                /> : null}

            {visibleAddCourseDrawer ? <ApplyCourse
                visible={visibleAddCourseDrawer}
                onClose={() => events.hideAddCourseDrawer()}
                refreshApplication={() => events.refreshApplication()}
                studentId={studentObj._id}/> : null}


            {visibleOtherAppDrawer ?
                <StudentOtherApp visible={visibleOtherAppDrawer}
                                 applicationObj={state.applicationObj}
                                 onStudentOtherAppClose={() => events.closeOtherAppDrawer()}
                                 reloadFxn={events.reloadFxn}
                /> : null}


            {selectedApplication.visible ?
                <ReActivateApplication {...selectedApplication} onClose={onCloseReActivate}/> : null}

            {agentApp.visible ?
                <ReActivateAppByAgent {...agentApp} onClose={agentReactiveAppEvents.hideDrawer}/> : null}

            {enrollmentObj.visible ?
                <Enrollment {...enrollmentObj} onClose={onCloseEnrollmentApp}/> : null}


            {changeUniversity.visible ?
                <ChangeUniversityComponent {...changeUniversity} onClose={onCloseChangeUniversityFxn}/> : null}

            {visibleAddCommentDrawer && applicationObj && applicationObj._id ?
                <AddNote visible={visibleAddCommentDrawer} studentData={applicationObj}
                         addedType={'direct'}
                         reloadFxn={events.reloadFxn}
                         onClose={() => events.closeAddCommentDrawer()}
                         applicationId={applicationObj.applications._id}/> : null}


            {transferToOtherDepartmentVisible ?
                <TransferApplicationDrawer visible={transferToOtherDepartmentVisible}
                                           closeTransferDrawer={() => events.hideTransferToOtherDepartment()}
                                           studentId={studentData._id}
                                           departmentWise={true}
                                           studentData={studentData}
                                           application={studentData.applications}
                /> : null}

            {updateUniversity.visible ?
                <UpdateUniversityComponent {...updateUniversity} onClose={updateUniversityEvent.closeDrawer}/> : null}

            {visibleTuitionFee ? <AddTuitionFeeDrawer
                visible={visibleTuitionFee}
                studentId={studentData._id}
                studentData={studentData}
                application={studentData.applications}
                reloadTable={() => {
                    setTimeout(() => {
                        tableAppRef.current.reload()
                    }, 1000)
                }}
                onClose={() => events.hideTuitionFeeDrawer()}/> : ''}


            {visibleOshcDrawer ? <OshcDrawer
                visible={visibleOshcDrawer}
                studentData={studentData}
                onClose={() => events.hideOshcDrawer()}/> : ''}


            {visibleUniversityCommission ? <AddCommissionDrawer
                visible={visibleUniversityCommission}
                studentData={studentData}
                onClose={() => events.hideUniversityCommission()}/> : ''}


        </React.Fragment>
    )
}

const mapStateToProps = ({global, router}) => ({
    currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}
const WrappedComponent = Form.create()(ViewStudentApplication)
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WrappedComponent)
