import React, {Suspense} from 'react'
import {Helmet} from 'react-helmet'
import {Opportunity} from './opportunityComponent'
import {LazyLoadImage} from 'react-lazy-load-image-component'

const HomeSlider = React.lazy(() => import('./homeSlider'))
const AboutUsPartners = React.lazy(() => import('./aboutUsPartners'))


const AboutUs = (props) => {
    const goToLogin = () => {
        window.location.href = '/login'
    }
    return (
        <React.Fragment>

            <section className="banner blog-banner about-banner">

                <div className="container">
                    <div className="col-lg-6 mr-auto">
                        <div className="content text-left">
                            <h1>We're Unizportal</h1>
                            <p>We work towards actualizing your aspirations</p>

                            <button className="btn" type="button" onClick={goToLogin}>
                                apply online <LazyLoadImage src="/assets/image/right-arrow.svg" alt=""/>
                            </button>
                        </div>
                    </div>

                    <div className="img">
                        <LazyLoadImage src="/assets/image/about-banner.png" alt=""/>
                    </div>
                </div>

            </section>

            <Suspense fallback={<div></div>}>
                <HomeSlider/>
            </Suspense>
            <section className="about gap">
                <div className="container">

                    <div className="row align-items-center">

                        <div className="col-lg-8">
                            <div className="box">
                                <p>Unizportal is a virtual portal that has been conceptualized to connect students
                                    aspiring to enhance
                                    their education abroad with the most prestigious universities in Australia, Canada,
                                    and UK with
                                    immigration consultants that are best suited to cater to their specific needs. Our
                                    tailored Mobile App
                                    has been designed to simplify and customized the process of searching relevant
                                    information and apply
                                    to several varsities with a distinctive Unizportal profile.</p>

                                <p>Ever since its genesis in 2017, Unizportal has been instrumental in materializing the
                                    dreams of
                                    numerous students and guiding them onto the right path to achieve their academic and
                                    career goals. We
                                    epitomize the essence of hassle-free availability of information regarding top-notch
                                    international
                                    post-secondary institutions; and stream online application. Our immigration partners
                                    have over the
                                    years chosen to work even more closely with us as we have assisted them in
                                    optimizing their
                                    performance by establishing ties with students having the most ideal
                                    credentials. </p>

                                <p>We actually make a difference in building and fortifying students’ profiles by
                                    providing the most
                                    intricate and objective assessment. Students are continuously updated about
                                    top-ranked universities,
                                    courses offered, fee structures and other essential information. Our dedication
                                    stems from the
                                    hallmarks of integrity, innovation and pervasive availability of customized
                                    information for our
                                    clientele. Since our inception, our clientele has grown because of our optimum
                                    delivery of value-based
                                    services. We have been every step of the way with our students and have helped in
                                    converting 2000+
                                    students’ dreams into reality. Over the years, we have been successful in forging
                                    links with 1500+
                                    global institutes and 1000 + immigration agencies. We feature among the top-most
                                    virtual platforms
                                    that have revolutionized the idea of uninterrupted linkage among students,
                                    immigration partners and;
                                    premium educational institutes seeking the most eligible candidates. </p>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="img">
                                <LazyLoadImage src="/assets/image/about-img.png" alt=""/>
                                <LazyLoadImage src="/assets/image/about-pattern.png" alt="" className="pattern"/>
                            </div>
                        </div>

                    </div>

                </div>
            </section>


            <Suspense fallback={<></>}>
                <AboutUsPartners/>
            </Suspense>
            <Opportunity/>
        </React.Fragment>
    )
}
export default AboutUs
