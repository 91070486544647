import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { WaitingToApplyWarningList } from '../actions'
import { TableComp } from 'sz-react-utils-lite'
import { getUrlPushWrapper } from '../../../routes'
import { Avatar, Row, Col, Button, Card, Select, Empty, Icon } from 'antd'
import {
  countryIds,
  defaultLogoList, filterOption, Intakes,
  longStringDate,
  newFormatDisplayDate,
  statusColors
} from '../../../components/_utils/appUtils'
import moment from 'moment-timezone'
import { getBranchManagerAgentList, listAllUsers } from '../../users/actions/user'
import { listAllUniversities } from '../../university/actions/university'
import WarningEmail from '../drawers/warningEmail'

moment.tz.setDefault('Asia/Calcutta')

const timePeriod = {
  first: 24,
  second: 24 * 4,
  third: 24 * 6
}

const { Option } = Select
const WarningComponent = (props) => {
  let { data, events } = props
  let { applications } = data
  let newObj = {
    firstStartDate: longStringDate(moment(data.applications.addedOn)),
    firstEndDate: longStringDate(moment(data.applications.addedOn).add(timePeriod.first, 'hours')),
    secondStartDate: longStringDate(moment(data.applications.addedOn).add(timePeriod.first, 'hours')),
    secondEndDate: longStringDate(moment(data.applications.addedOn).add(timePeriod.second, 'hours')),
    thirdStartDate: longStringDate(moment(data.applications.addedOn).add(timePeriod.second, 'hours')),
    thirdEndDate: longStringDate(moment(data.applications.addedOn).add(timePeriod.third, 'hours'))
  }

  const checkSecondAfter = () => {
    let date = moment().subtract(timePeriod.second, 'hours')
    if (moment(data.applications.addedOn).isBefore(date)) {
      return true
    } else {
      return false
    }

  }
  const checkThirdAfter = () => {
    let date = moment().subtract(timePeriod.third, 'hours')
    if (moment(data.applications.addedOn).isBefore(date)) {
      return true
    } else {
      return false
    }
  }
  const secondWarning = () => {
    let value = checkSecondAfter()
    return (
      <div>
        Second :<br/>
        {value ?
          <label className={'label label-danger xs'}>Limit Cross</label> :
          <label className={'label label-warning xs'}>Process</label>}

        <div className={'smFont'}>
          <label className={'dLab'}>Start : {newObj.secondStartDate} </label>
          <label className={'dLab'}>End : {newObj.secondEndDate}</label>
        </div>
        {value && <div className={'mt10'}>
          {applications.secondWarningDate ?
            <label className={'dLab'}>Mail : {longStringDate(applications.secondWarningDate)}</label> :
            <button className={'bs_btn bs-xxs bs-default'} onClick={() => sendMail('secondWarning')}>Send Mail
              <img className={'smImg'} src={'/assets/icons/sendMail.png'}/>
            </button>
          }
        </div>
        }
      </div>
    )
  }
  const thirdWarning = () => {
    let value = checkSecondAfter()
    let thirdValue = checkThirdAfter()
    return (
      <div>
        {value ? <div>
          Third : <br/>
          {
            thirdValue ?
              <label className={'label label-danger xs'}>Limit Cross</label> :
              <label className={'label label-warning xs'}>Process</label>
          }
          <div className={'smFont'}>
            <label className={'dLab'}>Start : {newObj.thirdStartDate}</label>
            <label className={'dLab'}>End : {newObj.thirdEndDate}</label>
          </div>
        </div> : null}

        {thirdValue ? <div className={'mt10'}>
          {applications.thirdWarningDate ?
            <label className={'dLab'}>Mail : {longStringDate(applications.thirdWarningDate)}</label> :
            <button className={'bs_btn bs-xxs bs-default'} onClick={() => sendMail('thirdWarning')}>Send Mail
              <img className={'smImg'} src={'/assets/icons/sendMail.png'}/>
            </button>
          }
        </div> : null}
      </div>
    )
  }

  const sendMail = (type) => {
    events.showEmailDrawer(data, type)
  }

  return (
    <div className={'cardSm'}>
      <Row gutter={5}>
        <Col span={8}>
          <div>
            First : <br/>
            <label className={'label label-danger xs'}>Limit Cross</label>
            <div className={'smFont'}>
              <label className={'dLab'}>Start : {newObj.firstStartDate}</label>
              <label className={'dLab'}>End : {newObj.firstEndDate}</label>
            </div>
            <div className={'mt10'}>
              {applications.firstWarningDate ?
                <label className={'dLab'}>Mail : {longStringDate(applications.firstWarningDate)}</label> :
                <button className={'bs_btn bs-xxs bs-default'} onClick={() => sendMail('firstWarning')}>Send Mail
                  <img className={'smImg'} src={'/assets/icons/sendMail.png'}/>
                </button>
              }
            </div>
          </div>
        </Col>
        <Col span={8}>
          {secondWarning()}
        </Col>
        <Col span={8}>
          {thirdWarning()}
        </Col>
      </Row>
    </div>
  )
}
const emailStateInitial = {
  visible: false,
  applicationId: '',
  studentId: '',
  warningType: ''
}
const Assessments = (props) => {
  let dispatch = useDispatch()
  const tableRef = useRef()
  let [total, setTotal] = useState(0)
  let [branchUserId, setBranchUserId] = useState('')
  let [appUniversityId, setAppUniversityId] = useState('')
  let [agentId, setAgentId] = useState('')
  let [branchManagerAgentList, setBranchManagerAgentList] = useState([])
  let [universityList, setUniversityList] = useState([])
  let [branchUserList, setBranchUserList] = useState([])
  let [branchManagerList, setBranchManagerList] = useState([])
  let [intake, setIntake] = useState('')
  let [name, setName] = useState('')
  let [emails, setEmails] = useState('')
  let [emailState, setEmailState] = useState(emailStateInitial)
  const loadCurrentUserData = async () => {
    let params = {
      results: 1000,
      userType: 'branchUser',
      sortField: 'name',
      sortOrder: 'ascend',
      select: ['name', 'userType', 'mobile', 'email'],
      customQuery: {
        $or: [{ countryId: countryIds.uk }, { 'department': 'Visa Approved Department' }]
      }
    }
    let { data } = await dispatch(listAllUsers(params))
    if (data && data.length) {
      setBranchUserList(data)
    }
  }
  const loadBranchManagerAgentList = async () => {
    let { data } = await dispatch(getBranchManagerAgentList())
    if (data && data.length) {
      setBranchManagerAgentList(data)
    } else {
      setBranchManagerAgentList([])
    }
  }
  const loadUniversityNameList = async () => {
    let params = {
      results: 5000,
      select: ['universityName'],
      sortField: 'universityName',
      sortOrder: 'ascend',
      regExFilters: ['universityName']
    }
    params.universityCountry = countryIds.uk
    let { data } = await dispatch(listAllUniversities(params))
    if (data && data.length) {
      setUniversityList(data)
    }
  }
  const loadBranchManager = async () => {
    let obj = {
      userType: 'branchManager',
      customQuery: {
        'agents.0': { $exists: true }
      },
      results: 100,
      select: ['name', 'email', 'mobile', 'agents']
    }
    let { data } = await dispatch(listAllUsers(obj))
    setBranchManagerList(data)
  }

  useEffect(() => {
    loadCurrentUserData()
    loadBranchManagerAgentList()
    loadUniversityNameList()
    loadBranchManager()
    filterEvents.setFieldByParams()
  }, [])

  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      let resp = await filterEvents.getParamsForApplicationList()
      params = { ...params, ...resp }
      let respData = await dispatch(WaitingToApplyWarningList({
        ...params
      }))
      console.log(respData)
      setTotal(respData.total)
      resolve(respData)
    })
  }

  const events = {
    goBack: () => {
      dispatch(getUrlPushWrapper('dashboard'))
    },
    showEmailDrawer: (data, type) => {
      if (data.applications && data.applications.assignedUserId && data.applications.assignedUserId.email) {
        setEmails(data.applications.assignedUserId.email)
      }
      setTimeout(() => {
        setEmailState({
          visible: true,
          applicationId: data.applications._id,
          studentId: data._id,
          warningType: type
        })
      }, 500)
    },
    hideEmailDrawer: (data, type) => {
      setEmailState({
        visible: false,
        applicationId: '',
        studentId: '',
        warningType: ''
      })
    },
    onCloseRefresh: (data, type) => {
      setEmailState({
        visible: false,
        applicationId: '',
        studentId: '',
        warningType: ''
      })
      setTimeout(() => {
        if (tableRef && tableRef.current) {
          tableRef.current.reload()
        }
      }, 200)
    }
  }

  const columns = [
    {
      title: 'App#',
      key: 'applications.id',
      dataIndex: 'applications.id',
      width: 60,
      render: (item) => {
        return (
          <div style={{ width: 60 }}>
            {item}
          </div>
        )
      }
    },
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      render: (item, record) => {
        return (
          <span>
           {item}
         </span>
        )
      }
    },
    {
      title: 'Add on',
      dataIndex: 'applications.addedOn',
      key: 'applications.addedOn',
      width: 90,
      render: (val) => {
        return (
          <div style={{ width: 90 }}>
            {newFormatDisplayDate(val)}
          </div>
        )
      }
    },
    {
      title: 'Warning',
      key: 'time',
      dataIndex: 'time',
      width: 600,
      render: (item, record) => {
        return (
          <WarningComponent data={record} events={events}/>
        )
      }
    },
    {
      title: 'DOB',
      dataIndex: 'dateOfBirth',
      key: 'dateOfBirth',
      width: 90,
      render: (val) => {
        return (
          <div style={{ width: 90 }}>
            {newFormatDisplayDate(val)}
          </div>
        )
      }
    },
    /* {
       title: 'Status',
       key: 'applications.status',
       dataIndex: 'applications.status',
       width: 120,
       render: (item, record) => {
         return (
           <React.Fragment>
             <div className={'label label1'}
                  style={{ background: statusColors(item), width: 80, padding: 3 }}>{item}</div>
           </React.Fragment>
         )
       }
     },*/
    {
      title: 'University',
      key: 'applications.courseUniversity',
      dataIndex: 'applications.courseUniversity',
      render: (item, record) => {
        return (
          <React.Fragment>
            {item && item.logo && item.logo.url ?
              <Avatar src={item.logo.url} size={20}/>
              : <Avatar src={defaultLogoList.defaultUniversityLogo} size={20}/>}
            {item.universityName ? `  ${item.universityName}` : ''}
          </React.Fragment>
        )
      }
    },
    {
      title: 'Country',
      key: 'applications.universityCountry',
      dataIndex: 'applications.universityCountry',
      width: 120,
      render: (item, record) => {
        return (
          <React.Fragment>
            {item && item.countryName ? item.countryName : null}
          </React.Fragment>
        )
      }
    },
    {
      title: 'Program',
      key: 'applications.courseName',
      dataIndex: 'applications.courseName',
      width: 150,
      render: (item) => {
        return (
          <div style={{ width: 150 }}>
            {item}
          </div>
        )
      }
    },
    {
      title: 'Intake',
      key: 'applications.intake',
      dataIndex: 'applications.intake',
      render: (item, record) => {
        return (
          <React.Fragment>
            {item && item.month && item.year ? `${item.month}, ${item.year}` : ''}
          </React.Fragment>
        )
      }
    },
    {
      title: 'Current User',
      key: 'applications.assignedUserId',
      dataIndex: 'applications.assignedUserId',
      width: 180,
      render: (val, record) => {
        let { agentId, statusList, status } = record.applications
        return (
          <React.Fragment>
            {val && val.name ? val.name : ''}<br/>
            <div>Agent : {agentId && agentId.companyName ? agentId.companyName : ''}</div>
            <div>{longStringDate(statusList.date)}</div>
          </React.Fragment>
        )
      }
    }
  ]

  const filterEvents = {
    setFieldByParams: async () => {
      let resp = await filterEvents.getParamsForApplicationList()
      if (resp.name) {
        setName(resp.name)
      }
      if (resp.agentId) {
        setAgentId(resp.agentId)
      }
      if (resp.appUniversityId) {
        setAppUniversityId(resp.appUniversityId)
      }
      if (resp.intake) {
        setIntake(resp.intake)
      }
      if (resp.branchUserId) {
        setBranchUserId(resp.branchUserId)
      }
    },
    getParamsForApplicationList: () => {
      return new Promise((resolve) => {
        let searchParams = new URLSearchParams(window.location.search)
        let agentId = searchParams.get('agentId')
        let appUniversityId = searchParams.get('appUniversityId')
        let name = searchParams.get('name')
        let branchUserId = searchParams.get('branchUserId')
        let intake = searchParams.get('intake')
        let obj = {}
        if (agentId) {
          obj.agentId = agentId
        }
        if (appUniversityId) {
          obj.appUniversityId = appUniversityId
        }
        if (name) {
          obj.name = name
        }
        if (intake) {
          obj.intake = intake
        }
        if (branchUserId) {
          obj.branchUserId = branchUserId
        }
        resolve(obj)
      })
    },
    searchFxn: async () => {
      let resp = await filterEvents.getParamsForApplicationList()
      let obj = {}
      if (name) {
        obj.name = name
      }
      if (agentId) {
        obj.agentId = agentId
      }
      if (appUniversityId) {
        obj.appUniversityId = appUniversityId
      }

      if (branchUserId) {
        obj.branchUserId = branchUserId
      }
      if (intake) {
        obj.intake = intake
      }
      dispatch(
        getUrlPushWrapper('waitingToApplyWarnings', {
          ...obj
        }))

      setTimeout(() => {
        if (tableRef && tableRef.current) {
          tableRef.current.reload()
        }
      }, 200)
    },
    clearFxn: async () => {
      let obj = {}
      setAppUniversityId('')
      setBranchUserId('')
      setAgentId('')
      setName('')
      dispatch(
        getUrlPushWrapper('waitingToApplyWarnings', {
          ...obj
        }))
      setTimeout(() => {
        tableRef.current.reload()
      }, 200)
    }
  }

  const filterBlock = (
    <Row gutter={16}>

      <Col md={4} sm={4} xs={8} lg={4}>
        <div className="sort-box-table custom-sort-box-new">
          <Select name="Current User" onChange={(item) => setBranchUserId(item)}
                  filterOption={filterOption}
                  className={'antSelect'}
                  allowClear={true}
                  showSearch={true}
                  placeholder={'Current User'}
                  value={branchUserId || undefined}>
            {branchUserList && branchUserList.length ? branchUserList.map((item, key) => {
              return (
                <Option value={item._id} key={key}>{item.name}</Option>
              )
            }) : null}
          </Select>
        </div>
      </Col>

      <Col md={4} sm={4} xs={8} lg={4}>
        <div className="sort-box-table custom-sort-box-new">
          <Select name="Search By University" onChange={(item) => setAppUniversityId(item)}
                  filterOption={filterOption}
                  className={'antSelect'}
                  allowClear={true}
                  showSearch={true}
                  placeholder={'University'}
                  value={appUniversityId || undefined}>
            {universityList && universityList.length ? universityList.map((item, key) => {
              return (
                <Option value={item._id} key={key}>{item.universityName}</Option>
              )
            }) : null}
          </Select>
        </div>
      </Col>
      <Col md={4} sm={4} xs={8} lg={4}>
        <div className="sort-box-table custom-sort-box-new">
          <Select name="Intake" onChange={(item) => setIntake(item)}
                  filterOption={filterOption}
                  className={'antSelect'}
                  allowClear={true}
                  showSearch={true}
                  placeholder={'Intake'}
                  value={intake || undefined}>
            {Intakes && Intakes.length ? Intakes.map((item, key) => {
              return (
                <Option value={item} key={key}>{item}</Option>
              )
            }) : null}
          </Select>
        </div>
      </Col>
      <Col md={4} sm={4} xs={8} lg={4}>
        <div className="sort-box-table custom-sort-box-new">
          <Select name="Search By Agent"
                  filterOption={filterOption}
                  className={'antSelect'}
                  allowClear={true}
                  placeholder={'Agent'}
                  showSearch={true}
                  onChange={(item) => setAgentId(item)} value={agentId || undefined}>
            {branchManagerAgentList && branchManagerAgentList.length ? branchManagerAgentList.map((item, key) => {
              return (
                <Option value={item._id} key={key}>{item.companyName}</Option>
              )
            }) : null}
          </Select>
        </div>
      </Col>
      <Col md={4} sm={4} xs={8} lg={4}>
        <div className="search-box-table round ml0">
          <input className="form-control form-control" type="search"
                 placeholder="Name..." value={name} onChange={(e) => {
            events.enterName(e.target.value)
          }}/>
          <img src="/dist/img/search.png" alt=""/>
        </div>
      </Col>


      <Col md={4} sm={4} xs={8} lg={4}>
        <Button onClick={() => filterEvents.searchFxn()} className={'roundBtn'}>Search</Button>
        <Button onClick={() => filterEvents.clearFxn()} className={'roundBtn'}>
          Clear
        </Button>


      </Col>


    </Row>
  )

  return (
    <div>
      <div className="row mt-4">
        <div className="col-lg-12">
          <div className="card">
            <div className="table-head d-flex align-items-center">
              <Button onClick={() => events.goBack()}
                      className={'roundGoBackBtn'} icon={'arrow-left'}>
                Back
              </Button>
              <h5>All Waiting To Apply: {total || 0}</h5>
            </div>
            <div className="card-body table-responsive">
              {filterBlock}
              <TableComp columns={columns}
                         rowKey={(item, index) => {
                           return index
                         }}
                         apiRequest={apiRequest}
                         pagination={{
                           pageSizeOptions: ['50', '100', '200', '500'],
                           defaultPageSize: 50
                         }}
                         ref={tableRef}
              />
            </div>
            {emailState.visible ?
              <WarningEmail
                type={'Waiting To Apply'}
                emails={emails}
                data={emailState}
                onClose={events.hideEmailDrawer}
                onCloseRefresh={events.onCloseRefresh}
              /> : null}

          </div>
        </div>
      </div>
    </div>
  )
}
export default Assessments