import React, {useEffect, useState, Suspense} from 'react'
import {useDispatch} from 'react-redux'
import {
    Select,
    Drawer,
    Button,
    Table,
    Form,
    Row,
    Col,
    Input, notification
} from 'antd'
import {TableComp} from 'sz-react-utils-lite'
import {InputBox} from '../../../components/_utils/appUtils'
import {addBankAccount, listAllAccount} from '../actions/bankAccount'

const {Option} = Select
let initState = {
    bankName: '',
    accountNo: '',
    ifscCode: '',
    swiftCode: '',
    address: '',
}
const bankAccount = (props) => {
    const {visible, events} = props
    const [open, setOpen] = useState(false)
    const [formValues, setFormValues] = useState(initState)
    const [bankList, setBankList] = useState([])
    const handleChange = (data) => {
        setFormValues(prevData => {
            return {
                ...prevData,
                ...data
            }
        })
    }
    useEffect(() => {
        loadBankList()
    }, [])
    const addAccount = () => {
        setOpen(true)
    }
    const onClose = () => {
        setOpen(false)
    }
    const dispatch = useDispatch()
    const columns = [
        {
            title: 'Bank Name',
            dataIndex: 'accounts.bankName',
            key: 'accounts.bankName'
        },
        {
            title: 'Account Number',
            dataIndex: 'accounts.accountNo',
            key: 'accounts.accountNo'
        },
        {
            title: 'IFSC',
            dataIndex: 'accounts.ifscCode',
            key: 'accounts.ifscCode'
        },
        {
            title: 'Swift Code',
            dataIndex: 'accounts.swiftCode',
            key: 'accounts.swiftCode'
        },
        {
            title: 'Address',
            dataIndex: 'accounts.address',
            key: 'accounts.address'
        }
    ]

    const loadBankList = async () => {
        let resp = await dispatch(listAllAccount({}));
        setBankList(resp.data)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (!formValues.bankName) {
            notification.warning({message: "Enter bank name"})
            return
        }
        if (!formValues.accountNo) {
            notification.warning({message: "Enter account number"})
            return
        }
        if (!formValues.ifscCode) {
            notification.warning({message: "Enter IFSC code"})
            return
        }
        if (!formValues.swiftCode) {
            notification.warning({message: "Enter Swift code"})
            return
        }
        if (!formValues.address) {
            notification.warning({message: "Enter bank address"})
            return
        }
        let resp = await dispatch(addBankAccount(formValues));
        if (resp && resp.success) {
            handleChange(initState);
            onClose()
            loadBankList()
        }
    }


    return (
        <>
            <div className="row mt-4">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="table-head d-flex align-items-center">
                            <h5>Accounts List</h5>
                            <div className="search-box-table">
                            </div>
                            <div className="sort-box-table mark-btn">
                                <button className="btn" onClick={() => addAccount(true)}>
                                    <img src={'../dist/icons/plus.png'} className={'plus'}/>
                                    Add Account
                                </button>
                            </div>
                        </div>

                        <div className="card-body table-responsive ">
                            <Table pagination={false} columns={columns} dataSource={bankList}/>
                        </div>

                    </div>
                </div>
            </div>
            <Drawer
                title={'Add Bank Account'}
                onClose={onClose}
                visible={open} width={'45%'}>
                <div className="card unizportal">
                    <Form className={'vertical-form'} autoComplete='off' onSubmit={handleSubmit}>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item>
                                    <InputBox title={'Bank Name'}>
                                        <Input onChange={({target}) => {
                                            handleChange({bankName: target.value})
                                        }} value={formValues.bankName} className={'form-control'} name="bankName"
                                               placeholder="Please enter bank name"/>
                                    </InputBox>

                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item>
                                    <InputBox title={'Account Number'}>
                                        <Input onChange={({target}) => {
                                            handleChange({accountNo: target.value})
                                        }} value={formValues.accountNo} className={'form-control'}
                                               name="accountNo" placeholder="Please enter Account Number"/>
                                    </InputBox>

                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item>
                                    <InputBox title={'IFSC Code'}>
                                        <Input onChange={({target}) => {
                                            handleChange({ifscCode: target.value})
                                        }} value={formValues.ifscCode} placeholder="Please enter IFSC Code"
                                               name="ifscCode" className={'form-control'}/>
                                    </InputBox>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item>
                                    <InputBox title={'Swift Code'}>
                                        <Input onChange={({target}) => {
                                            handleChange({swiftCode: target.value})
                                        }} value={formValues.swiftCode} placeholder="Please enter Swift Code"
                                               name="swiftCode" className={'form-control'}/>
                                    </InputBox>
                                </Form.Item>

                            </Col>
                            <Col span={24}>
                                <Form.Item
                                ><InputBox title={'Address'}>
                                    <Input.TextArea rows={4} onChange={({target}) => {
                                        handleChange({address: target.value})
                                    }} value={formValues.address} placeholder="please enter address" name="address"
                                                    className={'form-control'}/>
                                </InputBox>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Button type="primary" htmlType="submit" className={'btn'}>
                            Add
                        </Button>
                    </Form>
                </div>
            </Drawer>
        </>
    )
}

export default bankAccount
